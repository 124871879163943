// THis is for Create tactic redirection for specific tactic update
export const UPDATE_TACTIC_DETAILS = "UPDATE_TACTIC_DETAILS"
export const UPDATE_TACTIC_DETAILS_SUCCESS = "UPDATE_TACTIC_DETAILS_SUCCESS"
export const UPDATE_TACTIC_DETAILS_FAIL = "UPDATE_TACTIC_DETAILS_FAIL"
export const CLEAR_TACTIC_DETAILS = "CLEAR_TACTIC_DETAILS"

//This is for Sidebar Edit tactic

export const EDIT_TACTIC_DETAIL = "EDIT_TACTIC_DETAIL"
export const EDIT_TACTIC_DETAIL_SUCCESS = "EDIT_TACTIC_DETAIL_SUCCESS"
export const EDIT_TACTIC_DETAIL_FAIL = "EDIT_TACTIC_DETAIL_FAIL"
export const CLEAR_EDIT_TACTIC_DETAIL = "CLEAR_EDIT_TACTIC_DETAIL"