import {
  SEND_EMAIL_NOTIFICATION_SUCCESS,
  SEND_EMAIL_NOTIFICATION_FAIL,
  CLEAR_SEND_EMAIL_NOTIFICATION,

  SEND_SCORECARD_CREATION_REMINDER_SUCCESS,
  SEND_SCORECARD_CREATION_REMINDER_FAIL,
  CLEAR_SEND_SCORECARD_CREATION_REMINDER
} from "./actionTypes"

const INIT_STATE = {
  emailNotificationState: {},
  scorecardCreationReminderState: {}
}

const sendEmailNotificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_EMAIL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        emailNotificationState: action.payload,
      };

    case SEND_EMAIL_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_SEND_EMAIL_NOTIFICATION:
      return {
        ...state,
        emailNotificationState: {},
      };

    //////////////////////////////////////////////////////////////

    case SEND_SCORECARD_CREATION_REMINDER_SUCCESS:
      return {
        ...state,
        scorecardCreationReminderState: action.payload
      }

    case SEND_SCORECARD_CREATION_REMINDER_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case CLEAR_SEND_SCORECARD_CREATION_REMINDER:
      return {
        ...state,
        scorecardCreationReminderState: {}
      }

    default:
      return state;
  }
};

export default sendEmailNotificationReducer;