

import {
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS_SUCCESS,
    UPDATE_ACCOUNT_DETAILS_FAIL,
    CLEAR_ACCOUNT,

    UPDATE_EDITOR_DETAILS,
    UPDATE_EDITOR_DETAILS_SUCCESS,
    UPDATE_EDITOR_DETAILS_FAIL,
    CLEAR_EDITOR

    // DELETE_ACCOUNT_HOLDER,
    // DELETE_ACCOUNT_HOLDER_SUCCESS,
    // DELETE_ACCOUNT_HOLDER_FAIL,

    
} from "./actionTypes"

export const updateAccountData = (id, data) => {
    return {
      type: UPDATE_ACCOUNT_DETAILS,
      id,
      payload: data
      
    }
  }
  
  export const updateAccountSuccess = (data) => {
    return {
      type: UPDATE_ACCOUNT_DETAILS_SUCCESS,
      payload: data
    }
  }
  
  export const updateAccountFail = (error) => {
    return {
      type: UPDATE_ACCOUNT_DETAILS_FAIL,
      payload: error
    }
  }
  export const clearUpdateAccount = () => {
    return {
      type: CLEAR_ACCOUNT,
    }
  }

  export const updateEditorData = (id, data) => {
    return {
      type: UPDATE_EDITOR_DETAILS,
      id,
      payload: data
      
    }
  }
  
  export const updateEditorSuccess = (data) => {
    return {
      type: UPDATE_EDITOR_DETAILS_SUCCESS,
      payload: data
    }
  }
  
  export const updateEditorFail = (error) => {
    return {
      type: UPDATE_EDITOR_DETAILS_FAIL,
      payload: error
    }
  }
  export const clearUpdateEditor = () => {
    return {
      type: CLEAR_EDITOR,
    }
  }