import React from "react"
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const ContactUs = () => {
    return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Mambo - Contact Us</title>
            </MetaTags>
            <div className="container-fluid">
              
              <Row>
              <Col style= {{margin: "auto"}}>

                <Card>
                <CardBody>
                  <div className="table-responsive my-2">
                    <h3>Mambo Media Inc.</h3>
                    {/* <h5>Get answer to your questions</h5> */}
                    
                    <h5>To obtain a license for use of the Modern Marketing Scorecard tool, please contact <a href="mailto: scorecard@mambomedia.com">scorecard@mambomedia.com</a></h5>
                   
                    </div>
                    
                    </CardBody>
                    </Card>
                    
              </Col>

              </Row>


              </div>
              </div>
              </React.Fragment>
    )

}
export default ContactUs