
import { GET_CONTENT_EDITOR_TABLE_SUCCESS, GET_CONTENT_EDITOR_TABLE_FAIL, UPDATE_CONTENT_EDITOR_STATE, CLEAR_CONTENT_EDITOR_TABLE } from "./actionTypes"

const INIT_STATE = {
    editorData: [],
};

const EditorListReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CONTENT_EDITOR_TABLE_SUCCESS:
            return {
                ...state,
                editorData: action.payload
            };

        case GET_CONTENT_EDITOR_TABLE_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_CONTENT_EDITOR_STATE:
            return {
                ...state,
                editorData: action.payload
            };

        case CLEAR_CONTENT_EDITOR_TABLE:
            return {
                ...state,
                editorData: []
            };

        default:
            return state;
    }

};

export default EditorListReducer

