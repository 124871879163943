
import {
    ADD_CONTENT_EDITOR,
    ADD_CONTENT_EDITOR_SUCCESS,
    ADD_CONTENT_EDITOR_FAIL,
    CLEAR_CONTENT_EDITOR
} from "./actionTypes"

export const addContentEditor = (data) => {
    return {
      type: ADD_CONTENT_EDITOR,
      payload: data
      
    }
  }
  
  export const addContentEditorSuccess = (data) => {
    return {
      type: ADD_CONTENT_EDITOR_SUCCESS,
      payload: data
    }
  }
  
  export const addContentEditorFail = error => {
    return {
      type: ADD_CONTENT_EDITOR_FAIL,
      payload: error
    }
  }

  export const clearContentEditor = () => {
    return{
      type:CLEAR_CONTENT_EDITOR
    }
  }