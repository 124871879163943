import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
import contacts from "./contacts/reducer"

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//account user
import AccountUser from "./Account-User/reducer"

//SuperAdmin Account List
import SuperAdmin from "./SuperAdminTable/reducer"

//Channel
import GetChannelData from "./ContentEditor/reducer"

//Add channel
import AddChannelReducer from "./AddChannel/reducer"
// create report template:
import createReportReducer from "./ReportTemplate/reducer"

// Create Account Holder
import createAccountReducer from "./CreateAccountHolder/reducer"

//Create Content Editor
import createContentEditorReducer from "./CreateContentEditor/reducer"

//SuperAdmin Content Editor List
import EditorListReducer from "./ListContentEditors/reducer"

// Account Holder - Create users:
import AddUserReducer from "./CreateAccountUser/reducer"
// Account holder - get account users:
import AccountHolderUsersReducer from "./GetAccountUser/reducer"
// Update Account users :
import UpdateAccountHolderUsersReducer from "./UpdateAccountUser/reducer"
// scorecards list:
import GetScorecardsListReducer from "./Scorecards/reducer"

//Get Editor info
import EditorDetails from "./ContentEditorDetails/reducer"


//Update Account
import UpdateAccountReducer from "./UpdateAccount/reducer"

//Get Userlist Of Specific Account



import UserReducer from "./Accountuser1/reducer"

import addEditorReducer from "./CKEditor/reducer"

///ADD Tactic
import AddTacticReducer from "./AddTactic/reducer"

//Get Individual Channel 
import ChannelDetailsReducer from "./UpdateChannel/reducer"

//Update Tactic Details
import UpdateTacticDetailsReducer from "./UpdateTactic/reducer"


// Scorecard Report:
import ScorecardReport_Reducer from "./ScorecardReport/reducer"

// Unsaved Changes | Report

//*//*//*//*//*//*//*//*//*//*//*//*//*

//Account Owner - Buy Credits
import GetProductReducer from "./BuyCreditsTable/reducer"
// Stripe payments:
import PaymentSuccessReducer from "./Stripe/reducer"

//Account Owner Transaction table
import TransactionReducer from "./AccountTransactionTable/reducer"

// ContentEditor instruction panel i-button POC
import instructionPanelReducer from "./InstructionPanelForContentEditor/reducer" 

//Arrange Channel Sequence | Content Editor
import ArrangeChannelSequenceReducer from "./ArrangeChannelSequence/reducer"

//New Dashbaord Scorecount
import NewDashbordScorecountReducer from "./NewDashbaordScorecount/reducer"

//Update Instruction -Content Editor
import UpdateInstructionReducer from "./UpdateInstruction/reducer"

// For Coaching Page Reducer
import CoachingPageReducer from "./CoachingPage/reducer"

// To send Email Notification for Account Owner
import sendEmailNotificationReducer from "./SendEmailNotification/reducer"

// To handle Buy Credit menu toggle Functionality
import BuyCreditMenuReducer from "./BuyCreditsMenuToggleState/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  AccountUser,
  SuperAdmin,
  GetChannelData,

  createAccountReducer,
  createContentEditorReducer,


  AddChannelReducer,
  createAccountReducer,
  EditorListReducer,
  AddUserReducer,
  AccountHolderUsersReducer,
  UpdateAccountHolderUsersReducer,

  EditorDetails,


  GetScorecardsListReducer,

  UpdateAccountReducer,

  createReportReducer,



  UserReducer,

  addEditorReducer,
  AddTacticReducer,

  ChannelDetailsReducer,
  UpdateTacticDetailsReducer,


  PaymentSuccessReducer,
  // Scorecard Report
  ScorecardReport_Reducer,

  // //Unsaved Changes Alert
  // Unsaved_Chnages_Reducer

  GetProductReducer,

  //Account Owner Transaction table
  TransactionReducer,

  // ContentEditor instruction panel i-button POC 
  instructionPanelReducer,
  ArrangeChannelSequenceReducer,

  NewDashbordScorecountReducer,

  //Update Instruction -Content Editor
  UpdateInstructionReducer,

  // Coaching Page Reducer
  CoachingPageReducer,

  // To send Email Notification
  sendEmailNotificationReducer,

  // To handle Buy Credit menu toggle Functionality
  BuyCreditMenuReducer,
})

export default rootReducer
