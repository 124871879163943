import { takeEvery, put, call,all,fork, take  } from "redux-saga/effects";

import {TRANSACTION} from "./actionTypes"
import {getTransactionSuccess,getTransactionFail } from "./actions"
import {getTransaction} from "../../helpers/fakebackend_helper"

function* fetchTransaction({id}) {
    try{
        const response = yield call (getTransaction,id)
        yield put(getTransactionSuccess(response))
    }
    catch(error){
        yield put(getTransactionFail(error))
        
    }

}

export function* watchFetchTransaction (){
    yield takeEvery(TRANSACTION, fetchTransaction)
}

function* TransactionSaga (){
    yield all([fork(watchFetchTransaction)])

}

export default TransactionSaga