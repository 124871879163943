import {
  ADD_NEW_TACTIC,
  ADD_TACTIC_SUCCESS,
  ADD_TACTIC_FAIL,
  CLEAR_TACTIC_STATE,
  GET_TACTIC_INFO,
  GET_TACTIC_INFO_SUCCESS,
  GET_TACTIC_INFO_FAIL,
  GET_TACTIC_INFO_CLEAR,
  UPDATE_TACTIC_STATE,
  CLEAR_UPDATE_TACTIC_STATE
} from "./actionTypes"


export const AddNewTactic = (id,data) =>{
return {
  type: ADD_NEW_TACTIC,
  id,
  payload: data
}
}

export const AddTacticSuccess = (data) =>{
  return {
      type: ADD_TACTIC_SUCCESS,
      payload: data
  }
}

export const AddTacticFail = (error) =>{
  return {
      type: ADD_TACTIC_FAIL,
      payload: error
  }
}

export const ClearAddTactic = () => {
  return{
      type: CLEAR_TACTIC_STATE,
  }
}

export const updateTacticState = (data) => {
  return{
    type:UPDATE_TACTIC_STATE,
    payload:data
  }
}

export const ClearUpdateTacticState = () => {
  return{
    type:CLEAR_UPDATE_TACTIC_STATE
  }
}


//get Edit Tacitc

export const getTacticInfo = (channelId,tacticId) => {
  return {
      type: GET_TACTIC_INFO,
      channelId,tacticId,
      
  }
}
export const getTacticInfoSuccess = (data) => {
  return {
      type: GET_TACTIC_INFO_SUCCESS,
      payload: data
  }
}
export const getTacticInfoFail = (error) => {
  return {
      type: GET_TACTIC_INFO_FAIL,
      payload: error
  }
}
export const getTacticInfoClear = () => {
  return {
      type: GET_TACTIC_INFO_CLEAR,
  }
}

