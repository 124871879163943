import { color } from "@mui/system"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Grid } from "@mui/material"
import cap from "../../../../../assets/images/reportScorcard/Page3_ScMethodology.JPG"
import methodologyTitleImg from "../../../../../assets/images/reportScorcard/Methodology-image.PNG"
import icon1 from "../../../../../assets/images/reportScorcard/Scorecard methodology icon 1.png"
import icon2 from "../../../../../assets/images/reportScorcard/Scorecard methodology icon 2.png"
import icon3 from "../../../../../assets/images/reportScorcard/Scorecard methodology icon 3.png"
import icon4 from "../../../../../assets/images/reportScorcard/Scorecard methodology icon 4.png"
import icon5 from "../../../../../assets/images/reportScorcard/Scorecard methodology icon 5.png"
import joy from "../../../../../assets/images/reportScorcard/bow-removebg-preview.png"
import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
} from "reactstrap"
import { Opacity } from "@mui/icons-material"
import Footer from "components/VerticalLayout/Footer"
import { REPORT, REPORT_PAGE, IMAGE_SIZE } from "../config_v0_1/style.config"
import "./test.css"


const Methodology = () => {
  return (
    <>
            <Col xs="12" className="print-content p-0 m-0" >
              <div style={REPORT_PAGE}  >
                <Row className="g-0 p-0 m-0" style={REPORT_PAGE}>
                  <Col xs="12" >
                    <div
                      style={{
                        height: "528px",
                        width: "100%",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        // border: "1px solid grey",

                      }}
                    >
                      <img
                        src={cap}
                        style={{ height: "528px", width: "100%" }}
                      />
                    </div>

                    {/* For spacing above title added division */}
                    <div style={{height:"40px", backgroundColor:'#fff'}}>
                    </div>

                      {/* Added Division for image */}
                    <div
                      style={{
                        // height: "528px",
                        width: "100%",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        // border: "1px solid grey",

                      }}
                    >
                      <img
                        src={methodologyTitleImg}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div style={{height:"60px", backgroundColor:'#fff'}}>
                    </div>
                  </Col>

                  {/* <Col xs="12" >
                    <div style={{color:"#292929"}}>
                      <h2
                        style={{
                          padding: "8px",
                          fontFamily: REPORT.FONT_FACE.HEADING,
                          fontWeight: "bold",
                          color:"#292929",
                          textTransform:'uppercase',
                          fontSize: REPORT.FONT_SIZE.XLARGE,
                          // marginTop: 20,
                          marginLeft: 10,
                        }}
                      >
                        The Scorecard Process
                      </h2>
                      <div
                        className="row"
                        style={{
                          display: "inline-flex",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          className="column"
                          style={{
                            textAlign: "center",
                            marginTop: -10,
                            // border: "1px solid red",
                            marginLeft:10,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <img src={icon1}  />
                          <img src={icon2}  />
                          <img src={icon3}  />
                          <img src={icon4}  />
                          <img src={icon5}  />
                        </div>
                      </div>
                      <div
                        className="row g-0 m-0 p-0"
                        style={{ textAlign: "center" }}
                      >
                        {" "}
                        <img src={joy} />
                      </div>

                      <div
                        style={{
                          display: "inline-flex",
                          padding: "5px",
                          marginRight: "10px",
                        }}
                      >
                        <span
                          style={{ textAlign: "center", padding: "4px" }}
                        >
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              fontFamily:REPORT.FONT_FACE.PARAGRAPH
                            }}
                          >
                            Discovery <br></br>Workshop
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              padding: "5px",
                              marginTop: -7,
                              lineHeight:'1.15'
                            }}
                          >
                            &nbsp;Conduct Marketing Team workshop to understand
                            current state and practice of marketing
                            disciplines
                          </p>
                        </span>

                        <span
                          style={{ textAlign: "center", padding: "4px" }}
                        >
                          {" "}
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              fontFamily:REPORT.FONT_FACE.PARAGRAPH

                            }}
                          >
                            Supporting Documentation
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              marginTop: -7,
                              paddingTop: "5px",
                              lineHeight:'1.15'
                            }}
                          >
                            Review of provided supporting materials and
                            linked documents relevant to channels
                          </p>
                        </span>

                        <span
                          style={{ textAlign: "center", padding: "4px" }}
                        >
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              fontFamily:REPORT.FONT_FACE.PARAGRAPH

                            }}
                          >
                            Competitive & Original Research
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              marginTop: -7,
                              padding: "5px",
                              lineHeight:'1.15'
                            }}
                          >
                            Conduct competitive audit and industry
                            research to understand positioning and
                            competitive activities
                          </p>
                        </span>

                        <span
                          style={{ textAlign: "center", padding: "4px" }}
                        >
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              fontFamily:REPORT.FONT_FACE.PARAGRAPH

                            }}
                          >
                            Modern Marketing Evaluation
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              marginTop: -7,
                              padding: "5px",
                              lineHeight:'1.15'
                            }}
                          >
                            Evaluate each channel, &nbsp;&nbsp;reviewed &
                            cross-referenced with client provided resources
                            & competitive set
                          </p>
                        </span>

                        <span
                          style={{ textAlign: "center", padding: "4px" }}
                        >
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              fontFamily:REPORT.FONT_FACE.PARAGRAPH

                            }}
                          >
                            Key Observations & Recommendations
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              marginTop: -7,
                              padding: "3px 0",
                              lineHeight:'1.15'
                            }}
                          >
                            Score and assess each channel against best
                            practices & provide comprehensive
                            recommendations
                          </p>
                        </span>
                      </div>
                    </div>
                  </Col> */}

                    <Col
                      xs="12"
                      style={{
                        // border: '1px solid black'
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        zIndex: 100,
                        // width: "816px",

                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: REPORT.COLOR.FOOTER,
                          opacity: 0.9,
                          fontWeight: 600,
                          fontFamily:REPORT.FONT_FACE.HEADING,
                          letterSpacing:'2px',
                          color: "white",
                          fontSize: REPORT.FONT_SIZE.MEDIUM,
                          padding: '5px 5px 5px 40px',
                        }}
                      >
                        THE SCORECARD PROCESS
                      </div>
                    </Col>
                </Row>
              </div>
            </Col>
    </>
  )
}

export default Methodology
