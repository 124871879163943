import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';  // Added Meta Tag npm Package
import "../config/style.config.css"
import { REPORT, REPORT_PAGE } from '../config/style.config';
import ReactHtmlParser from "react-html-parser"

import {
    Table,
    Container, Row, Col,
    Alert,
    Card, CardBody, CardTitle, FormGroup, Spinner, Form, Input, Button
} from "reactstrap";
import { getChannelDetails, getScorecardReport } from "store/actions"
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom"



const ChannelFirstPage = () => {

    const [channelData, setChannelData] = useState([])
    // Routing hooks
    const params = useParams();


    const dispatch = useDispatch();
    const {
        scorecardReport_data,
        // channelDetails
    } = useSelector(state => ({
        scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
        // channelDetails: state.ChannelDetailsReducer.channelDetails,

    }));

    useEffect(() => {
        // dispatch(getScorecardReport(params.scorecardId))
        if (scorecardReport_data.id !== params.scorecardId) {
            dispatch(getScorecardReport(params.scorecardId))
        }
    }, [dispatch])

    // useEffect(() => {
    //     if (channelDetails.id !== params.channelId) {
    //         dispatch(getChannelDetails(params.channelId))
    //     }

    //     if (channelDetails.id) {
    //         setChannelData([channelDetails])
    //     }

    // }, [channelDetails, params.channelId])


    useEffect(() => {
        setChannelData([])
        if (scorecardReport_data && scorecardReport_data.selectedChannels) {
            var GetChannel = scorecardReport_data.selectedChannels
                .filter(channel => channel.channelId === params.channelId)
            setChannelData(GetChannel)
        }
    }, [params.channelId, scorecardReport_data])



    return (
        <>
            <div className="page-content" >
                <MetaTags>
                    <title> Mambo -Report</title>
                </MetaTags>
                <Container fluid={true} style={{
                    transform: "scale(.8) translate(-130px, -132px) ",
                    width: "1400px",
                }}>
                    <Row>
                        <Col className="col-12 m-auto "  >
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="12">
                                            <div style={{ ...REPORT_PAGE }}>

                                                <Row>
                                                    <Col md="12" >
                                                        <div style={{
                                                            width: "100%",
                                                            height: '528px',
                                                            // border: "1px solid grey",
                                                        }}>
                                                            <img src={channelData[0] && channelData[0].Image_URL && channelData[0].Image_URL} style={{
                                                                width: '100%',
                                                                height: '528px'
                                                            }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <h2 style={{
                                                            color: '#0D0F11',
                                                            width: '100%',
                                                            fontWeight: 700,
                                                            maxHeight: '60px',
                                                            margin: '10px 0 5px 15px',
                                                            // border:'1px solid black',
                                                            overflow: 'hidden'
                                                        }}><span>{channelData[0] && channelData[0].channelDescriptionTitle && channelData[0].channelDescriptionTitle}</span>
                                                        </h2>

                                                        <div style={{
                                                            width: '100%',
                                                            height: '410px',
                                                            overflow: 'hidden',
                                                            padding: '0 6px 6px 6px',
                                                            margin: '5px 0 2px 20px',
                                                            // border: "1px solid grey",  
                                                        }}
                                                        className="--channel-first-page"
                                                        >
                                                            <div style={{
                                                                height: '400px',
                                                                fontSize: REPORT.FONT_SIZE.MEDIUM,
                                                                fontFamily: REPORT.FONT_FACE.HEADING,
                                                                margin: 0,
                                                                // border: "1px solid grey", 
                                                                overflow: 'hidden',
                                                                listStyleType: 'none',
                                                                color:'#0D0F11'

                                                            }}
                                                            className="SavedDescription"
                                                            >
                                                                {channelData[0] && channelData[0].channelDescription && <li>{
                                                                    ReactHtmlParser(channelData[0].channelDescription)
                                                                }</li>}
                                                                
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="6" >
                                                        <div style={{
                                                            width: '100%',
                                                            height: '526px',
                                                            backgroundColor: REPORT.COLOR.PRIMARY_BG,
                                                            // border: '1px solid black',
                                                        }}>
                                                            <h3 style={{
                                                                fontSize: '1.42188rem',
                                                                fontWeight: 600,
                                                                color: '#0D0F11',
                                                                fontFamily: REPORT.FONT_FACE.PARAGRAPH,
                                                                fontStyle: 'italic',
                                                                lineHeight: 1.6,
                                                                padding: '125px 15px 0 10px',
                                                                textAlign: 'right'
                                                            }}>
                                                                {`${channelData[0] && channelData[0].channelQuote && channelData[0].channelQuote}`.toUpperCase()}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Col md="12" style={{
                                                    // border: '1px solid black'
                                                    position: "absolute",
                                                    bottom: 0,
                                                    zIndex: 100,
                                                }}>
                                                    <div style={{
                                                        width: '100%',
                                                        fontSize: REPORT.FONT_SIZE.MEDIUM,
                                                        padding: '5px 5px 5px 40px',
                                                        backgroundColor: REPORT.COLOR.FOOTER,
                                                        fontFamily: REPORT.FONT_FACE.HEADING,
                                                        letterSpacing: '2px',
                                                        opacity: 0.9,
                                                        fontWeight: 600,
                                                        color: 'white'
                                                    }}>{`${channelData[0] && channelData[0].channelName && channelData[0].channelName}`.toUpperCase()}</div>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div >
        </>
    );
}

export default ChannelFirstPage;