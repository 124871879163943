import React from "react";
import { ImagepreviewStyle } from "./ImagePreview.cssConfig";

const ImagePreview = (props) => {
  const PROPS = props
  const { ImageUrl, TogglePreview } = PROPS
  return (
    <div className="imagePreview-container">
      <div style={ImagepreviewStyle}
        onClick={TogglePreview}
      >
        <img
          src={ImageUrl}
          height="400px"
        />

      </div>
    </div>
  );
}

export default ImagePreview;