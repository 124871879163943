import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"
import { Country, State } from "country-state-city"
import { ACCOUNT_OWNER } from "config/AlertMessage.config"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
  UncontrolledAlert,
} from "reactstrap"

import * as Yup from "yup"
import { Formik, useFormik } from "formik"

import { accountUsersLimit } from "../../../config/UserCreationLimit_config"
import userCreatedIcon from "../../../assets/images/NewPopupIcons/UserCreated.png"
import failedToCreateUser from "../../../assets/images/NewPopupIcons/FailedIcon.png"
// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
// toast.configure();

import {
  AddNewUser,
  ClearAddUser,
  getAccountHolderUsers,
  updateAccountHolderUsersState,
  // changeAccountHolderUserList
} from "store/actions"

// console.log("Account Holder's Users", accountHolderUsers)

const CreateAccountUser = () => {
  const [countries, setCountries] = useState(null)

  const [success_msg, setsuccess_msg] = useState(false)
  const [HandleAccountUser, setHandleAccountUser] = useState({})
  //Additional States for handleing custom requests:
  const [isUserAdded, setIsUserAdded] = useState(false)
  const [limitExceed, setLimitExceed] = useState(false)
  const [isOkClickedAfterSuccess, setIsOkClickedAfterSuccess] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { addUserData, accountHolderUsers } = useSelector(state => ({
    addUserData: state.AddUserReducer.addUserData,
    accountHolderUsers: state.AccountHolderUsersReducer.accountHolderUsers,
  }))

  useEffect(() => {
    !accountHolderUsers[0] &&
      dispatch(
        getAccountHolderUsers(JSON.parse(localStorage.getItem("authUser")).uid)
      )
  }, [dispatch])

  const GetEnableUsersCount = () => {
    return accountHolderUsers[0]
      ? accountHolderUsers.filter(user => user.isDisabled === false).length
      : 0
  }

  // console.log("Account Holder Users... ",accountHolderUsers)            // All user's list

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      //   userName: "",
      contactName: "",
      countryCode: "1",
      contactNumber: "",
      title: "",
      contactEmail: "",
      //   password: "",
    },

    validationSchema: Yup.object({
      //   userName: Yup.string().required("Please enter user name"),
      contactName: Yup.string().required("Please enter name"),
      contactEmail: Yup.string()
        .email("Must be valid email")
        .required("Please enter contact email"),
      //   password: Yup.string().min(6).required("Please enter password"),
      // title: Yup.string().required("Please enter title"),
      // contactNumber: Yup.string()
      //   .max(10, "Contact number must be atleast 10 digits")
      //   .min(10, "Contact number must be atleast 10 digits")
      //   .required("Please enter contact number"),
    }),

    onSubmit: values => {
      // console.log("Enabled user count...", GetEnableUsersCount())
      if (GetEnableUsersCount() < accountUsersLimit) {
        var date = new Date()
        var user = JSON.parse(localStorage.getItem("authUser"))
        // console.log("owner", user)
        var myUserData = {
          // userName: values.userName,
          contactName: values.contactName,
          contactEmail: values.contactEmail,
          contactNumber: values.contactNumber ? values.contactNumber : "",
          countryCode: values.countryCode ? values.countryCode : "",
          address: values.title ? values.title : "",
          // password: values.password,
          createdOn: date,
          createdBy: user.email,
          creatorId: user.uid,
          creatorName: user.providerData[0].displayName || "",
          isDisabled: false,
        }

        // console.log("myUserData...",myUserData)
        setHandleAccountUser(myUserData)
        setIsUserAdded(true)
        dispatch(AddNewUser(myUserData))

        // console.log("Old User list", accountHolderUsers)
        // console.log("Before length",accountHolderUsers.length)

        // dispatch(changeAccountHolderUserList([...accountHolderUsers,myUserData]))

        // console.log("After length", accountHolderUsers.length)

        // console.log("New User list", accountHolderUsers)
      } else {
        setLimitExceed(true)
      }
    },
  })

  //   if(addUserData[0] && addUserData[0].status === 200){
  //     setTimeout(() => {
  //         // validation.values.userName = ""
  //         validation.values.contactName = ""
  //         validation.values.contactEmail = ""
  //         validation.values.contactNumber = ""
  //         validation.values.address = ""
  //         validation.values.password = ""

  //         dispatch(ClearAddUser())
  //         setIsUserAdded(false)

  //         setTimeout(() => {
  //             history.push("/")
  //         }, 2000)

  //      }, 4000)
  //   }
  //   if(addUserData[0] && addUserData[0].status === 403){
  //     setTimeout(() => {

  //         dispatch(ClearAddUser())
  //         setIsUserAdded(false)

  //      }, 4000)
  //   }
  useEffect(() => {
    var countries_ = [...Country.getAllCountries()].map(country => {
      if (country.isoCode === "CA") {
        return {
          ...country,
          phonecode: "001",
        }
      } else if (country.isoCode === "UM") {
        return {
          ...country,
          phonecode: "01",
        }
      } else {
        return country
      }
    })

    //  console.log(countries_)
    setCountries(countries_)
    // setCountries(Country.getAllCountries())
  }, [])

  const formMandatory = {
    color: "red",
    fontWeight: "bold",
  }

  // console.log("VALIDATION VALUES...", validation.values)
  
  const handleUserCreationDelay = () => {
    setTimeout(() => {
      setIsOkClickedAfterSuccess(false)
      setsuccess_msg(false)
      setIsUserAdded(false)
      dispatch(ClearAddUser())
      history.push("/users")
    }, 5500)
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Mambo - New User </title>
        </MetaTags>
        {/* <Row>
                    <Col xl = "10" style={{margin: "auto"}} >
                <Container fluid={true}>
                    <h4 className="card-title" style={{textTransform: "uppercase"}}>Create Account User </h4>
                </Container>
                    </Col>
                </Row> */}
        <Container fluid={true}>
          {/* <Breadcrumbs title="Content Editor" breadcrumbItem="Tactics Details" /> */}
          {/* <FormValidation /> */}
          {limitExceed && (
            <UncontrolledAlert
              color="danger"
              onClick={() => setLimitExceed(false)}
            >
              {
                <strong>
                  {ACCOUNT_OWNER.USER_CREATE_LIMIT_EXCEED.message_create}
                </strong>
              }
            </UncontrolledAlert>
          )}
          <Row>
            <Col xl="12" style={{ margin: "auto" }}>
              <Card>
                <CardBody>
                  {/* {addUserData[0] && addUserData[0].status === 200 && (
                    <SweetAlert
                      style={{
                        height: "255px",
                        width: "368px",
                      }}
                      title=""
                      success
                      confirmBtnBsStyle="primary"
                      onConfirm={() => {
                        // dispatch(changeAccountHolderUserList())
                        if (accountHolderUsers[0]) {
                          dispatch(
                            updateAccountHolderUsersState([
                              ...accountHolderUsers,
                              {
                                ...HandleAccountUser,
                                id: addUserData[0].id,
                              },
                            ])
                          )
                        } else {
                          dispatch(
                            updateAccountHolderUsersState([
                              {
                                ...HandleAccountUser,
                                id: addUserData[0].id,
                              },
                            ])
                          )
                        }
                        setsuccess_msg(false)
                        dispatch(ClearAddUser())
                        setIsUserAdded(false)

                        history.push("/")
                      }}
                    >
                      {ACCOUNT_OWNER.CREATE_USER.SUCCESS}
                    </SweetAlert>
                  )} */}

                  {addUserData[0] && addUserData[0].status === 200 && (
                    <SweetAlert
                      style={{ height: "270px", width: "270px" }}
                      custom
                      customIcon={
                        <Row>
                          <Col md="12" className="mb-4">
                            <img src={userCreatedIcon}
                              style={{
                                height: '65px',
                                width: '76px'
                              }} />
                          </Col>
                          <Col md="12" className="text-center container-fluid"
                            style={{
                              color: '#0D0F11',
                              fontWeight: 600,
                              fontSize: '24px',
                              lineHeight: '28px',
                            }}
                          >
                            {ACCOUNT_OWNER.CREATE_USER.SUCCESS}
                          </Col>
                        </Row>
                      }
                      customButtons={
                        <div className="container-fluid">
                          <Button block size="sm" className="font-size-14"
                            style={{
                              background: '#0D0F11',
                              border: '#0D0F11'
                            }}
                            disabled={isOkClickedAfterSuccess}
                            onClick={() => {
                              setIsOkClickedAfterSuccess(true)
                              if (accountHolderUsers[0]) {
                                dispatch(
                                  updateAccountHolderUsersState([
                                    ...accountHolderUsers,
                                    {
                                      ...HandleAccountUser,
                                      id: addUserData[0].id,
                                    },
                                  ])
                                )
                              } else {
                                dispatch(
                                  updateAccountHolderUsersState([
                                    {
                                      ...HandleAccountUser,
                                      id: addUserData[0].id,
                                    },
                                  ])
                                )
                              }
                              handleUserCreationDelay()
                            }}
                          >
                            {isOkClickedAfterSuccess ? (
                              <Spinner
                                type="border"
                                size="sm"
                              />
                            ) : "Ok"}
                          </Button>
                        </div>
                      }
                    >
                    </SweetAlert>
                  )}

                  {/* {addUserData[0] && addUserData[0].status === 409 && (
                    <SweetAlert
                      // title="Failed to update "
                      warning
                      confirmBtnBsStyle="primary"
                      style={{
                        height: "255px",
                        width: "368px",
                      }}
                      onConfirm={() => {
                        setsuccess_msg(false)
                        dispatch(ClearAddUser())
                        setIsUserAdded(false)
                      }}
                    >
                      {ACCOUNT_OWNER.CREATE_USER.CONFLICT}
                    </SweetAlert>
                  )} */}

                  {addUserData[0] && addUserData[0].status === 409 && (
                    <SweetAlert
                      style={{ height: "250px", width: "250px" }}
                      custom
                      customIcon={
                        <Row>
                          <Col md="12" className="">
                            <img src={failedToCreateUser}
                              style={{
                                height: '65px',
                                width: '65px'
                              }} />
                          </Col>
                          <Col md="12" className="text-center container-fluid mt-4"
                            style={{
                              color: '#0D0F11',
                              fontWeight: 600,
                              fontSize: '22px',
                              lineHeight: '28px',
                            }}
                          >
                            {ACCOUNT_OWNER.CREATE_USER.CONFLICT}
                          </Col>
                        </Row>
                      }
                      customButtons={
                        <div className="container-fluid mb-2">
                          <Button block size="sm" className="my-2"
                            style={{
                              background: '#0D0F11',
                              border: '#0D0F11'
                            }}
                            onClick={() => {
                              setsuccess_msg(false)
                              dispatch(ClearAddUser())
                              setIsUserAdded(false)
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      }
                    >
                    </SweetAlert>
                  )}

                  {/* {addUserData[0] && addUserData[0].status === 403 && (
                    <SweetAlert
                      // title="Failed to update "
                      error
                      confirmBtnBsStyle="primary"
                      style={{
                        height: "255px",
                        width: "368px",
                      }}
                      onConfirm={() => {
                        setsuccess_msg(false)
                        setIsUserAdded(false)
                        dispatch(ClearAddUser())
                      }}
                    >
                      {ACCOUNT_OWNER.CREATE_USER.FAILED}
                    </SweetAlert>
                  )} */}

                  {addUserData[0] && addUserData[0].status === 403 && (
                    <SweetAlert
                      style={{ height: "230px", width: "250px" }}
                      custom
                      customIcon={
                        <Row>
                          <Col md="12" className="mt-4">
                            <img src={failedToCreateUser}
                              style={{
                                height: '65px',
                                width: '65px'
                              }} />
                          </Col>
                          <Col md="12" className="text-center container-fluid mt-3"
                            style={{
                              color: '#0D0F11',
                              fontWeight: 600,
                              fontSize: '24px',
                              lineHeight: '28px',
                            }}
                          >
                            {ACCOUNT_OWNER.CREATE_USER.FAILED}
                          </Col>
                        </Row>
                      }
                      customButtons={
                        <div className="container-fluid mb-2" style={{ marginTop: '-10px' }}>
                          <Button block size="sm" className="my-2"
                            style={{
                              background: '#0D0F11',
                              border: '#0D0F11'
                            }}
                            onClick={() => {
                              setsuccess_msg(false)
                              setIsUserAdded(false)
                              dispatch(ClearAddUser())
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      }
                    >
                    </SweetAlert>
                  )}

                  <Form
                    className="needs-validation outer-repeater"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="12">
                        <h2 className="fw-bold" style={{ color: "#0D0F11" }}>
                          New user
                        </h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <span style={formMandatory}>*</span>
                          <Label htmlFor="validationCustom01"> Name</Label>
                          <Input
                            name="contactName"
                            autoComplete="off"
                            placeholder="name"
                            type="text"
                            className="form-control"
                            disabled={isUserAdded}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contactName || ""}
                            invalid={
                              validation.touched.contactName &&
                                validation.errors.contactName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.contactName &&
                            validation.errors.contactName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contactName}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {/* <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">User Name</Label>
                                                        <Input
                                                        name="userName"
                                                        placeholder="user name"
                                                        type="text"
                                                        
                                                        className="form-control"
                                                        disabled = { isUserAdded }
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.userName || ""}
                                                        invalid={
                                                        validation.touched.userName && validation.errors.userName ? true : false
                                                        }
                                                        />
                                                        {validation.touched.userName && validation.errors.userName ? (
                                                            <FormFeedback type="invalid">{validation.errors.userName}</FormFeedback>
                                                        ) : null}
                                                </FormGroup> */}
                      </Col>

                      <Col md="6">
                        <FormGroup className="mb-3">
                          <span style={formMandatory}>*</span>
                          <Label htmlFor="validationCustom01"> Email</Label>
                          <Input
                            name="contactEmail"
                            placeholder=" email"
                            type="email"
                            className="form-control"
                            autoComplete="off"
                            disabled={isUserAdded}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contactEmail || ""}
                            invalid={
                              validation.touched.contactEmail &&
                                validation.errors.contactEmail
                                ? true
                                : false
                            }
                          />
                          {validation.touched.contactEmail &&
                            validation.errors.contactEmail ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contactEmail}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="2">
                        <FormGroup className="mb-3">
                          {/* <span style={formMandatory}>*</span> */}
                          <Label htmlFor="validationCustom01">Phone</Label>
                          <Input
                            type="select"
                            className="form-control"
                            name="countryCode"
                            value={validation.values.countryCode}
                            onChange={validation.handleChange}
                          >
                            {/* {
                                                            countries && countries.map(country => (
                                                                <option key={country.name} value={country.phonecode} >
                                                                    {country.phonecode.includes("+") ? country.phonecode : "+" + country.phonecode}
                                                                </option>
                                                            ))
                                                        } */}
                            {countries &&
                              countries.map(country => (
                                <option
                                  key={country.name}
                                  value={country.phonecode}
                                >
                                  {country.flag}
                                  &nbsp;&nbsp;{country.isoCode}
                                  &nbsp;&nbsp;({country.phonecode})
                                </option>
                              ))}
                          </Input>
                          {/* <Input
                                                            name="userName"
                                                            placeholder="User Name"
                                                            type="text"
                                                            className="form-control"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.userName || ""}
                                                            invalid={
                                                            validation.touched.userName && validation.errors.userName ? true : false
                                                            }
                                                            /> */}
                          {validation.touched.countryCode &&
                            validation.errors.countryCode ? (
                            <FormFeedback type="invalid">
                              {validation.errors.countryCode}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">

                          <Label htmlFor="validationCustom01">&nbsp;</Label>
                          <Input
                            name="contactNumber"
                            placeholder="phone"
                            type="number"
                            mask="(999) 999-9999"
                            autoComplete="off"
                            min="1"
                            style={{
                              MozAppearance: "none",
                            }}
                            className="form-control"
                            disabled={isUserAdded}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contactNumber || ""}
                          // invalid={
                          //   validation.touched.contactNumber &&
                          //     validation.errors.contactNumber
                          //     ? true
                          //     : false
                          // }
                          />
                          {/* {validation.touched.contactNumber &&
                            validation.errors.contactNumber ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contactNumber}
                            </FormFeedback>
                          ) : null} */}
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup className="mb-3">
                          {/* <span style={formMandatory}>*</span> */}
                          <Label htmlFor="validationCustom01">Title</Label>

                          <Input
                            name="title"
                            autoComplete="off"
                            placeholder="designation"
                            type="text"
                            className="form-control"
                            disabled={isUserAdded}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                          // invalid={
                          //   validation.touched.title &&
                          //     validation.errors.title
                          //     ? true
                          //     : false
                          // }
                          />
                          {/* {validation.touched.title &&
                            validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null} */}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      {/* <Col md = "6" >
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">Password</Label>
                                                        <Input
                                                        name="password"
                                                        placeholder="password"
                                                        type="password"
                                                        // autoComplete = "new-password"
                                                        className="form-control"
                                                        disabled = { isUserAdded }
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                        validation.touched.password && validation.errors.password ? true : false
                                                        }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                </FormGroup>
                                            </Col> */}
                    </Row>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    {/* <br></br> */}

                    <Row>
                      <Col md="6">
                        <FormGroup >
                          <Button
                            className="fw-bold"
                            // style={{
                            //   backgroundColor: "#FEF3C7",
                            //   boxShadow: "none",
                            //   borderColor: "#FEF3C7",
                            // }}
                            style={{
                              backgroundColor: "#CB6928",
                              boxShadow: "none",
                              borderColor: "#CB6928",
                            }}
                            onClick={e => {
                              validation.handleReset()
                            }}
                            block
                          >
                            <div
                              className="d-flex justify-content-center"
                              // style={{ color: "#0D0F11" }}
                              style={{ color: "#ffffff" }}
                            >
                              <i className="bx bx-left-arrow-alt fw-bold mx-2 mt-1"></i>
                              <span className="font-size-14">
                                Reset Information
                              </span>
                            </div>
                          </Button>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Button
                            style={{ backgroundColor: "#0D0F11" }}
                            type="submit"
                            disabled={isUserAdded}
                            block
                          >
                            {isUserAdded ? "" : (
                              <div className="d-flex justify-content-center">
                                <span className="text-white font-size-14">
                                  Create User
                                </span>
                                <i className="bx bx-right-arrow-alt text-white fw-bold mx-2 mt-1"></i>
                              </div>
                            )}
                            {isUserAdded ? (
                              <Spinner
                                // style={{ width: "0.8rem", height: "0.8rem" }}
                                type="border"
                                size="sm"
                              />
                            ) : null}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default CreateAccountUser
