import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from "react-html-parser"
import {
  Table,
  Container, Row, Col,
  Alert,
} from "reactstrap";
import "../config_v0_1/style.config.css"
import { REPORT, REPORT_PAGE } from '../config_v0_1/style.config';

const ExecutiveSummary = (props) => {

  const PROPS = props;
  const { Executive_summary } = PROPS
  return (
    <>
      <Col xs="12" className="print-content p-0 m-0 ">
        <div style={{
          ...REPORT_PAGE
        }} >
          <Row className='g-0 m-0 p-0'>
            <Col xs="5" >
              <div
                className='ImageUploadContainer'
                style={{
                  height: REPORT_PAGE.height,
                  width: "100%",
                  backgroundImage: Executive_summary ? "url(" + Executive_summary.bgImage + ")" : " "  ,
                  // backgroundColor: "#f0f0f0",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",

                }} >
                <div className='container--toggle'
                  style={{
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "rgba(0, 0, 0, .3)"

                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      // border: "1px solid red",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "all 1s ease-in-out"
                    }}
                  >
                    <input
                      type="file"
                      id="upload-button"
                      name="Image_URL"
                      accept="image/*"
                      style={{ display: "none" }}
                      // onChange={handleChange}
                    />
                  </span>
                </div>
              </div>
            </Col>

            <Col xs="7" >
              <div style={{
                width: "100%",
                height: REPORT_PAGE.height,
                overflow:'hidden'
              }} >
                <Row className='g-0 m-0 p-0' >
                  <Col xs="12" >
                    <div style={{
                      margin: "auto",
                      width: "100%",
                      height: "100px",
                      padding: "30px 40px 0px 40px",
                      position: "relative",
                      textTransform: "uppercase",
                      marginBottom: '50px',
                    }} >
                      <h2 style={{
                        fontSize: REPORT.FONT_SIZE.XLARGE,
                        fontFamily: REPORT.FONT_FACE.HEADING,
                        fontWeight:600,
                        color:'#0D0F11',
                        letterSpacing:'2px',
                        paddingBottom: "10px",
                        // paddingBottom: "8px",
                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                      }} >
                        Executive 
                        <br/>Summary
                      </h2>
                    </div>
                  </Col>
                  <Col xs="12" >
                    <div style={{
                      marginTop: "-20px",
                      width: "100%",
                      height: "920px",
                      overflow: "hidden",
                      fontSize: REPORT.FONT_SIZE.XSMALL,
                      lineHeight:'1.15',
                      padding: "0 25px 0 40px"
                    }}
                      className="--executive-summary --container"
                    >
                      <div
                        style={{
                          width: "96%",
                          padding: "4px",
                          overflow: "hidden",
                          height: "860px",
                          // border:'1px solid red',
                          fontSize: REPORT.FONT_SIZE.XSMALL,
                          fontFamily: REPORT.FONT_FACE.PARAGRAPH,
                          marginTop: '10px',
                          color:'#0D0F11'
                        }}
                        className="SavedExecutiveSummary"
                      >
                        {ReactHtmlParser(Executive_summary && Executive_summary.contents)}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs="12" style={{
              position: "absolute",
              bottom: 0, 
              zIndex: 100,
            }}>
              <div style={{
                width: '100%',
                backgroundColor: REPORT.COLOR.FOOTER,
                opacity: 0.9,
                fontWeight: 600,
                fontFamily:REPORT.FONT_FACE.HEADING,
                letterSpacing:'2px',
                padding: '5px 5px 5px 40px',
                color: 'white',
                fontSize: REPORT.FONT_SIZE.MEDIUM,
              }}>
                EXECUTIVE SUMMARY</div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
}

export default ExecutiveSummary;