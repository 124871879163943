import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { useHistory, useParams, withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
// ------------------------------------------------------------------------
// Vendor imports:
import {
  getScorecardReport,
  HandleWindowInActivity,
  handleUnsavedChanges,
  GetRoutes,
  getScorecardDetail,
  handleCloseReportEditing,
  updateScorecardDetail,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

// ------------------------------------------------------------------------
const ScorecardReport = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  // --------------------------------------------------------------------
  // Vendor states & hooks (functionality):
  const [activityState, setActivityState] = useState(false)
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    scorecardReport_data,
    windowInActivity,
    isDataEdited,
    isThereChanges,
    getRoutes,
    scorecardDetail,
  } = useSelector(state => ({
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
    windowInActivity: state.ScorecardReport_Reducer.windowInActivity,
    isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    getRoutes: state.ScorecardReport_Reducer.getRoutes,
    scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
  }))
  useEffect(() => {
    dispatch(getScorecardDetail(params.scorecardId))

    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
  }, [dispatch])
  const logout = () => {
    dispatch(HandleWindowInActivity(true))
  }
  useEffect(() => {
    let time
    setActivityState(true)
    if (activityState) {
      const resetTimer = () => {
        clearTimeout(time)
        time = setTimeout(logout, 18 * 60000)
      }
      window.onload = resetTimer
      document.onmousemove = resetTimer
      document.onkeypress = resetTimer
    }

    return () => {
      clearTimeout(time)
      setActivityState(false)
      window.onload = () => {}
      document.onmousemove = () => {}
      document.onkeypress = () => {}
    }
  }, [activityState])

  // Restrict from back if user is not active.
  useEffect(() => {
    var active_userName =
      scorecardDetail.activeUser && scorecardDetail.activeUser.userName
    var current_userName = JSON.parse(
      localStorage.getItem("authUser")
    ).displayName

    if (scorecardDetail.id && !scorecardDetail.activeUser) {
      history.push(`/view/scorecard/${params.scorecardId}`)
    }
    //
    if (scorecardDetail.id && active_userName !== current_userName) {
      history.push(`/view/scorecard/${params.scorecardId}`)
    }
    // return () => dispatch(clearScore)
  }, [scorecardDetail])

  //
  // useEffect(() => {
  //     window.addEventListener('beforeunload', () => {
  //         window.confirm("hello")
  //     });
  // }, [])

  // const beforeUnloadListener = (event) => {
  //     event.preventDefault();
  //     return event.returnValue = "Are you sure you want to exit?";
  // };
  // window.addEventListener('beforeunload', beforeUnloadListener);
  // useEffect(() => {
  //     if (Object.keys(scorecardReport_data).includes("selectedChannels")) {
  //         window.localStorage.setItem("selectedChannelsForEditReport", JSON.stringify(scorecardReport_data.selectedChannels))
  //     }
  // }, [scorecardReport_data])

  // ---------------------------------------------------------------------
  // Don't touch, else you will have to pay $4000.00
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  // -------------------------------------------------------------------------
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* Edit Report Sidebar for Pro version of a Scorecard */}
            <li className="menu-title">{props.t("Edit report - menu")} </li>
            <li
              onClick={() => {
                if (isThereChanges) {
                  dispatch(handleUnsavedChanges(true))
                }
                dispatch(
                  GetRoutes(
                    `/edit/scorecard-report/${params.scorecardId}/cover-page`
                  )
                )
              }}
            >
              <Link
                to={
                  !isThereChanges &&
                  `/edit/scorecard-report/${params.scorecardId}/cover-page`
                }
              >
                <span>Cover Page</span>
              </Link>
            </li>
            <li
              onClick={() => {
                if (isThereChanges) {
                  dispatch(handleUnsavedChanges(true))
                }
                dispatch(
                  GetRoutes(
                    `/edit/scorecard-report/${params.scorecardId}/table-of-content`
                  )
                )
              }}
            >
              <Link
                to={
                  !isThereChanges &&
                  `/edit/scorecard-report/${params.scorecardId}/table-of-content`
                }
              >
                <span>Table Of Contents</span>
              </Link>
            </li>
            <li
              onClick={() => {
                if (isThereChanges) {
                  dispatch(handleUnsavedChanges(true))
                }
                dispatch(
                  GetRoutes(
                    `/edit/scorecard-report/${params.scorecardId}/scorecard-methodology`
                  )
                )
              }}
            >
              <Link
                to={
                  !isThereChanges &&
                  `/edit/scorecard-report/${params.scorecardId}/scorecard-methodology`
                }
              >
                <span>Scorecard Methodology</span>
              </Link>
            </li>
            <li
              onClick={() => {
                if (isThereChanges) {
                  dispatch(handleUnsavedChanges(true))
                }
                dispatch(
                  GetRoutes(
                    `/edit/scorecard-report/${params.scorecardId}/level-of-maturity-defined`
                  )
                )
              }}
            >
              <Link
                to={
                  !isThereChanges &&
                  `/edit/scorecard-report/${params.scorecardId}/level-of-maturity-defined`
                }
              >
                <span>Levels Of Maturity Defined</span>
              </Link>
            </li>
            <li
              onClick={() => {
                if (isThereChanges) {
                  dispatch(handleUnsavedChanges(true))
                }
                dispatch(
                  GetRoutes(
                    `/edit/scorecard-report/${params.scorecardId}/executive-summary`
                  )
                )
              }}
            >
              <Link
                to={
                  !isThereChanges &&
                  `/edit/scorecard-report/${params.scorecardId}/executive-summary`
                }
              >
                <span>Executive Summary</span>
              </Link>
            </li>
            <li
              onClick={() => {
                if (isThereChanges) {
                  dispatch(handleUnsavedChanges(true))
                }
                dispatch(
                  GetRoutes(
                    `/edit/scorecard-report/${params.scorecardId}/priority-area-of-focus`
                  )
                )
              }}
            >
              <Link
                to={
                  !isThereChanges &&
                  `/edit/scorecard-report/${params.scorecardId}/priority-area-of-focus`
                }
              >
                <span>Priority Areas Of Focus</span>
              </Link>
            </li>
            <li
              onClick={() => {
                if (isThereChanges) {
                  dispatch(handleUnsavedChanges(true))
                }
                dispatch(
                  GetRoutes(
                    `/edit/scorecard-report/${params.scorecardId}/score-at-glance`
                  )
                )
              }}
            >
              <Link
                to={
                  !isThereChanges &&
                  `/edit/scorecard-report/${params.scorecardId}/score-at-glance`
                }
              >
                <span>Scores At A Glance</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <span>{props.t("Selected Channels")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {Object.keys(scorecardReport_data).includes(
                  "selectedChannels"
                ) &&
                  scorecardReport_data.selectedChannels.map(
                    (CHANNEL, INDEX) => (
                      <li key={INDEX}>
                        <Link to={{}} className="has-arrow">
                          <span>{props.t(CHANNEL.channelName)}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                          <li
                            onClick={() => {
                              if (isThereChanges) {
                                dispatch(handleUnsavedChanges(true))
                              }
                              dispatch(
                                GetRoutes(
                                  `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}`
                                )
                              )
                            }}
                          >
                            <Link
                              to={
                                !isThereChanges &&
                                `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}`
                              }
                            >
                              <span>{props.t(CHANNEL.channelName)}</span>
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              if (isThereChanges) {
                                dispatch(handleUnsavedChanges(true))
                              }
                              dispatch(
                                GetRoutes(
                                  `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}/tactic-evaluated`
                                )
                              )
                            }}
                          >
                            <Link
                              to={
                                !isThereChanges &&
                                `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}/tactic-evaluated`
                              }
                            >
                              <span>Tactics Evaluated</span>
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              if (isThereChanges) {
                                dispatch(handleUnsavedChanges(true))
                              }
                              dispatch(
                                GetRoutes(
                                  `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}/key-observations`
                                )
                              )
                            }}
                          >
                            <Link
                              to={
                                !isThereChanges &&
                                `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}/key-observations`
                              }
                            >
                              <span>Key Observations</span>
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              if (isThereChanges) {
                                dispatch(handleUnsavedChanges(true))
                              }
                              dispatch(
                                GetRoutes(
                                  `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}/doing-well`
                                )
                              )
                            }}
                          >
                            <Link
                              to={
                                !isThereChanges &&
                                `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}/doing-well`
                              }
                            >
                              <span>What You Are Doing Well</span>
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              if (isThereChanges) {
                                dispatch(handleUnsavedChanges(true))
                              }
                              dispatch(
                                GetRoutes(
                                  `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}/quick-wins`
                                )
                              )
                            }}
                          >
                            <Link
                              to={
                                !isThereChanges &&
                                `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}/quick-wins`
                              }
                            >
                              <span>Quick Wins</span>
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              if (isThereChanges) {
                                dispatch(handleUnsavedChanges(true))
                              }
                              dispatch(
                                GetRoutes(
                                  `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}/recommendations`
                                )
                              )
                            }}
                          >
                            <Link
                              to={
                                !isThereChanges &&
                                `/edit/scorecard-report/${params.scorecardId}/channels/${CHANNEL.channelId}/${CHANNEL.channelId}/recommendations`
                              }
                            >
                              <span>Recommendations</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )
                  )}
              </ul>
            </li>
            <li
              onClick={() => {
                if (isThereChanges) {
                  dispatch(handleUnsavedChanges(true))
                }
                dispatch(
                  GetRoutes(
                    `/edit/scorecard-report/${params.scorecardId}/key-takeaways`
                  )
                )
              }}
            >
              <Link
                to={
                  !isThereChanges &&
                  `/edit/scorecard-report/${params.scorecardId}/key-takeaways`
                }
              >
                <span>Key Takeaways</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

ScorecardReport.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ScorecardReport))
