import React from "react"
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const Terms = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Terms of Service</title>
        </MetaTags>
        <div className="container-fluid">

          <Row>
            <Col md="12"style={{ margin: "auto" }}>

              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <h2>Mambo Media Inc.</h2>
                    {/* <h5>Terms of Use</h5> */}
                    <div id="policy" width="640" height="480"
                      data-policy-key="ZG1wdFdsRkJjazlRTTIxRU5VRTlQUT09"
                      data-extra="table-style=accordion">
                    </div>
                  </div>

                </CardBody>
              </Card>

            </Col>

          </Row>


        </div>
      </div>
    </React.Fragment>
  )

}
export default Terms