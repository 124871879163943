import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { UPDATE_ACCOUNT_HOLDER_USER } from "./actionTypes"
import {
    
  updateAccountHolderUserSuccess,
  updateAccountHolderUserFail
} from "./actions"

//Include Both Helper File with needed methods
import { updateAccountHolderUserDetails } from "helpers/fakebackend_helper"

function* updateAccountHolderUsers({ userId, payload: data }) {
  try {
    const response = yield call(updateAccountHolderUserDetails, userId, data)
    yield put(updateAccountHolderUserSuccess(response))
  } catch (error) {
    yield put(updateAccountHolderUserFail(error))
  }
}



function* updateAccountHolderUsersSaga() {
  yield takeEvery(UPDATE_ACCOUNT_HOLDER_USER, updateAccountHolderUsers)

}

export default updateAccountHolderUsersSaga
