import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import firebase from 'firebase/compat';
// Reactstrap

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import images



import logoLightSvg from "../../assets/images/logo.png";
import ScorecardLogo from "../../assets/images/MamboScorecardLogo/Logo.png";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  updateScorecardDetail,
  getAccountDetails,
  ClearAccountOwnerDetails,
  HandleSessionTimeOut,
  HandleIsUserExit,
  HandleWindowInActivity,
  getAllInstructionData,
  clearGetAllInstructionData
} from "../../store/actions";
import { Button, Col, Row, Spinner, Modal, Tooltip } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import TimerComponent from 'components/SessionTimeOut';
import UniversalTimerComponent from 'components/Universal_Session_Timeout';
import FailedIcon from "../../assets/images/NewPopupIcons/FailedIcon.png"

const Header = props => {
  const [refresh_credits, set_refresh_credits] = useState(false)
  const [is_editing_closed, set_is_editing_closed] = useState(false)
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [windowSize, setWindowSize] = useState(false)
  const [toolTipForCloseEditing, setToolTipForCloseEditing] = useState(false)
  const [toolTipForGeneratePDF, setToolTipForGeneratePDF] = useState(false)
  const [infoForCloseEditing, setInfoForCloseEditing] = useState("")
  const [infoForGeneratePDF, setInfoForGeneratePDF] = useState("")
  const [data, setData] = useState({})
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch();
  const { scorecardDetail, accountDetails, updatedScorecardDetail, sessionTimeOut, isUserExited, getInstructionData } = useSelector(state => ({
    scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
    updatedScorecardDetail: state.GetScorecardsListReducer.updatedScorecardDetail,
    accountDetails: state.SuperAdmin.accountDetails,
    sessionTimeOut: state.ScorecardReport_Reducer.sessionTimeOut,
    isUserExited: state.ScorecardReport_Reducer.isUserExited,
    windowInActivity: state.ScorecardReport_Reducer.windowInActivity,
    getInstructionData: state.instructionPanelReducer.getInstructionData,
  }));


  useEffect(() => {
    if (data.admin) {
      !accountDetails[0] && dispatch(getAccountDetails(JSON.parse(localStorage.getItem("authUser")).uid))
    }
    // console.log(accountDetails)

  }, [dispatch, data])
  // console.log(accountDetails)
  useEffect(() => {
    if (accountDetails.credit || accountDetails.credit >= 0) {
      set_refresh_credits(false)
    }
    
  }, [accountDetails])

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  useEffect(() => {
    handleScreenSize()
  }, [])


  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    }
    else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }


  const handleScreenSize = () => {
    if (window.innerWidth <= 998)
      setWindowSize(true)
    else
      setWindowSize(false)
  }
  window.addEventListener("resize", handleScreenSize)

  function tog_standard() {
    // setmodal_standard(!modal_standard);
    dispatch(HandleSessionTimeOut(!sessionTimeOut))
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }


  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdTokenResult().then(token => {
          setData(token.claims)
        }).catch(err => {

        })
      }
    })

    return () => {
      setData(null)
    }
  }, [])

  // close editing:
  useEffect(() => {
    if (is_editing_closed && updatedScorecardDetail.status === 200) {
      history.push("/")
    }
  }, [updatedScorecardDetail])

  const [sessionActive, setSessionActive] = useState(false)
  const timeout = () => {
    dispatch(HandleSessionTimeOut(true))
  }

  useEffect(() => {
    let time;
    setSessionActive(true)
    if (sessionActive) {
        const resetTimer = () => {
            clearTimeout(time);
            time = setTimeout(timeout, 18 * 60000)
        }
        window.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onkeypress = resetTimer;
        
    }

    return () => {
        clearTimeout(time)
        setSessionActive(false)
        window.onload = () => { }
        document.onmousemove = () => { }
        document.onkeypress = () => { }
    }
}, [sessionActive])

useEffect(() => {
  if (!Object.keys(getInstructionData).includes("infoForAccountUser")) {
    dispatch(getAllInstructionData())
  }
},[dispatch])

useEffect(() => {
  if (getInstructionData.infoForAccountUser !== undefined) {
    var data = {
      infoForCloseEditingBtn: getInstructionData.infoForAccountUser.coverPage.infoForCloseEditingBtn ? getInstructionData.infoForAccountUser.coverPage.infoForCloseEditingBtn : "",
      infoForGeneratePDFBtn: getInstructionData.infoForAccountUser.coverPage.infoForGeneratePDFBtn ? getInstructionData.infoForAccountUser.coverPage.infoForGeneratePDFBtn : "",
    }
    setInfoForCloseEditing(data.infoForCloseEditingBtn)
    setInfoForGeneratePDF(data.infoForGeneratePDFBtn)
  }
},[getInstructionData])  

  return (
    <React.Fragment>
      {/* Universal session timeout */}
      <header id="page-topbar"  style= {{background:'#f1f3f5'}}>
      {/* {
        sessionTimeOut &&
        <SweetAlert
          btnSize='xs'
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="primary"
          title="Are you still working on this?"
          onConfirm={() => {
            setSessionActive(true)
            dispatch(HandleSessionTimeOut(false))
            // history.push("/logout")
          }}
        >
         If not, please save and logout.
        </SweetAlert>
      } */}
      {
        sessionTimeOut && 
        <Modal
        isOpen={sessionTimeOut}
        toggle={() => {
          tog_standard();
        }}
        centered
        style={{width:'290px', height:'270px'}}
      >
        {/* <div className="modal-header" > */}
          {/* <div style={{
            display: "flex",
            flexDirection: "row"
          }} >
            <div>

              <h6 className="modal-title mt-0" id="myModalLabel">
                Session Time Out In:
              </h6>
            </div>
            <UniversalTimerComponent />
          </div> */}
          {/* <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        {/* </div> */}
        {/* <div
          className="modal-body">
          <h5
           
          >
            Are you still working on this?
            If not , 
            please save and logout.
          </h5>
        

        </div> */}
        {/* <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
              //dispatch(HandleSessionTimeOut(false))
            }}
            className="btn btn-primary "
            data-dismiss="modal"
          >
            Yes
          </button>
        </div> */}

        <Row className="mt-1 mb-1">
          <Col md="2"></Col>
          <Col md="8" className="text-center mt-4 font-size-18 fw-bold" 
            style={{
              color:'#0D0F11',
              borderBottom:'1px solid #0D0F11'  
            }}>
              Session Time Out In: 
            <p className="fw-bold font-size-14"><UniversalTimerComponent /></p>
          </Col>
          <Col md="2"></Col>
        </Row>
        <Row className="modal-body">
          <Col md="12" className="text-center mb-3"
            style={{
              color:'#0D0F11',
              fontWeight:600,
              fontSize:'20px',
              // lineHeight:'28px',
            }}
          >
              Are you still working on this?
            <p className="my-0 p-1 text-dark fw-normal font-size-14">
              If not, please save and logout.
            </p>
          </Col>
          <Col md="12">
            <Button block size="sm" className="my-2 font-size-14"
              type="button"
              style={{
                background:'#0D0F11',
                border:'#0D0F11'
              }}
              onClick={() => {
                tog_standard();
              }}
            >
              Ok
            </Button>
          </Col>
        </Row>
      </Modal>

      }
     

      {/* {
        isUserExited &&
        <SweetAlert
          btnSize='xs'
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="primary"
          title=""
          onConfirm={() => {
            dispatch(HandleIsUserExit(false))
            history.push("/logout")
          }}
        >
         Your session is timed out
        </SweetAlert>
      } */}

          {isUserExited && (
            <SweetAlert
              style={{height:"230px", width:"250px"}}
              custom
              customIcon={
                <Row>
                  <Col md="12" className="mb-3">
                    <img src={FailedIcon}
                      style={{
                        height:'65px', 
                        width:'65px'
                    }}/>
                  </Col>
                  <Col md="12" className="text-center container-fluid"
                    style={{
                      color:'#0D0F11',
                      fontWeight:600,
                      fontSize:'24px',
                      lineHeight:'27px',
                    }}
                  >
                    Your session is timed out.
                  </Col>
                </Row>
              }
              customButtons={
                <div className="container-fluid">
                    <Button block size="sm"
                      style={{
                        background:'#0D0F11',
                        border:'#0D0F11'
                      }}
                      onClick={() => {
                        dispatch(HandleIsUserExit(false))
                        history.push("/logout")
                      }}
                    >
                      Close
                    </Button>
                </div>
              }
            >
            </SweetAlert>
          )}
      
        <div className="navbar-header">

          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={ScorecardLogo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={ScorecardLogo} alt="" height="22" />
                </span>
              </Link>
            </div>
            {
              windowSize &&

              <button

                type="button"
                onClick={() => {
                  tToggle(


                  );

                }}
                className="btn btn-sm px-3 font-size-16 header-item "
                id="vertical-menu-btn"


              >


                <i className="fa fa-fw fa-bars" />
              </button>
            }
            {
              document.location.pathname.includes("/take-a-survey/") ?
                <div style={{ marginLeft: "30px" }}><h3 style = {{color:'#0D0F11'}}>
                  {
                    Object.keys(scorecardDetail).includes("id") ?
                      ``
                      :
                      ""
                  }
                </h3> </div>
                :
                
                  <div style={{ marginLeft: "30px" }}>
                    <h3>
                      {
                        (document.title).split("-")[1] === " Homepage" || 
                        (document.title).split("-")[1] === " Create New Scorecard" ||
                        (document.title).split("-")[1] === " Update Client Information" ||
                        (document.title).split("-")[1] === "Scoring Summary" ||
                        (document.title).split("-")[1] === " All Users" ||
                        (document.title).split("-")[1] === " Transactions" ||
                        (document.title).split("-")[1] === " New User" ||
                        (document.title).split("-")[1] === " My Scorecards" ||
                        (document.title).split("-")[1] === " Instructions For Account User" ||
                        (document.title).split("-")[1] === " All Scorecards" ?
                        ""
                        :
                        (document.title).split("-")[1]
                      } 
                    </h3>
                  </div>

            }


          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              {/* <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button> */}
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {
              document.location.pathname.includes("edit/scorecard-report/") &&
              <div style={{
                height: "60px",
                marginTop: "40px",
                marginRight: "20px",
                width: "200px"
              }}>
                <Row className='g-2'>
                  <Col md="6">
                    <Button
                      color='danger'
                      id="TooltipForCloseEditing"
                      size='sm'
                      block
                      disabled={is_editing_closed}
                      onClick={() => {
                        set_is_editing_closed(true)
                        dispatch(updateScorecardDetail(params.scorecardId, {
                          activeUser: null
                        }))
                        // history.push("/")
                      }}
                    >


                      {is_editing_closed ? "" : 
                        scorecardDetail && scorecardDetail.scorecardVersion === "Pro" ?
                        "Close Editing" : "Close Report"
                      }
                      {is_editing_closed ? (
                        <Spinner
                          // style={{ width: "0.8rem", height: "0.8rem" }}
                          type="border"
                          size="sm"
                        />
                      ) : null}
                    </Button>

                    {/* On hover tooltip functionality */}
                    <Tooltip
                      style={{backgroundColor:'#FEF3C7', color:'#0D0F11', zIndex: 1}}
                      placement="auto"
                      isOpen={toolTipForCloseEditing}
                      target="TooltipForCloseEditing"
                      toggle={() => {
                        setToolTipForCloseEditing(!toolTipForCloseEditing);
                      }}
                    >
                     {infoForCloseEditing} 
                    </Tooltip>

                  </Col>
                  <Col md="6">
                    <Button
                      color='primary'
                      id="ToolTipForGeneratePDF"
                      size='sm'
                      block
                      onClick={() => {
                        history.push(`/generate-pdf/${params.scorecardId}`)
                      }}
                    >
                      Generate PDF
                    </Button>

                      {/* On hover tooltip functionality */}
                    <Tooltip
                      style={{backgroundColor:'#FEF3C7', color:'#0D0F11', zIndex: 1}}
                      placement="auto"
                      className='font-size-18'
                      isOpen={toolTipForGeneratePDF}
                      target="ToolTipForGeneratePDF"
                      toggle={() => {
                        setToolTipForGeneratePDF(!toolTipForGeneratePDF);
                      }}
                    >
                      {infoForGeneratePDF}
                    </Tooltip>

                  </Col>
                </Row>
                {/* <Button
                  color='danger'
                  size='sm'
                  block
                  disabled={is_editing_closed}
                  onClick={() => {
                    set_is_editing_closed(true)

                    // dispatch(updateScorecardDetail(params.scorecardId, {
                    //   activeUser: null
                    // }))
                    // history.push("/")
                  }}
                >

                  {is_editing_closed ? "" : "Close Editing"}
                  {is_editing_closed ? (
                    <Spinner
                      // style={{ width: "0.8rem", height: "0.8rem" }}
                      type="border"
                      size="sm"
                    />
                  ) : null}
                </Button> */}
                &nbsp;&nbsp;
                {/* <Button
                  color='primary'
                  size='sm'
                  block
                  onClick={() => {
                    history.push(`/generate-pdf/${params.scorecardId}`)
                  }}
                >
                  Generate pdf
                </Button> */}
              </div>
            }

            {
              data.admin &&
              <div
                style={{
                  padding: "15px 20px"
                }}
              >
                <h6
                  style={{
                    lineHeight: "70px"
                  }}
                >Available credits: {accountDetails && accountDetails.credit || 0}
                  {
                    !refresh_credits ?

                      <i className='mdi mdi-reload'
                        style={{
                          color: "#556ee6",
                          fontSize: "14px",
                          marginLeft: "10px",
                          cursor: "pointer"
                        }}

                        onClick={() => {
                          set_refresh_credits(true)
                          dispatch(ClearAccountOwnerDetails())
                          dispatch(getAccountDetails(JSON.parse(localStorage.getItem("authUser")).uid))

                        }}
                      ></i>
                      :
                      <Spinner
                        size="sm"
                        color='primary'
                        style={{
                          width: "12px",
                          height: "12px",
                          marginLeft: "10px",
                        }}
                      />
                  }
                </h6>
              </div>
            }



            <ProfileMenu />


          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
