import {
    ADD_CHANNEL_SUCCESS,
    ADD_CHANNEL_FAIL,
    CLEAR_CHANNEL_STATE
} from "./actionTypes"

const INIT_STATE = {
    addChannelData : []
}

const AddChannelReducer = (state = INIT_STATE, action) => {

    switch(action.type) {
        case ADD_CHANNEL_SUCCESS : return {
            ...state,
            addChannelData: action.payload,
        }
        case ADD_CHANNEL_FAIL : return {
            ...state,
            addChannelData: action.payload,
        }
        case CLEAR_CHANNEL_STATE : return {
            ...state,
            addChannelData: []
        }
        default:
        return state
    }
    
}

export default AddChannelReducer;