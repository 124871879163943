import { takeEvery, put, call, all, fork } from "redux-saga/effects";

// Login Redux States
import {
  SEND_EMAIL_NOTIFICATION,
  SEND_SCORECARD_CREATION_REMINDER
} from "./actionTypes"
import {
  sendEmailNotificationToAccountOwnerSuccess,
  sendEmailNotificationToAccountOwnerFail,

  sendScorecardCreationReminderSuccess,
  sendScorecardCreationReminderFail
} from "./actions"

import { SendEmailNotification, SendScorecardCreationReminder } from "../../helpers/fakebackend_helper";

function* SendEmailNotificationFunction({ payload: data }) {
  try {
    const response = yield call(SendEmailNotification, data)
    yield put(sendEmailNotificationToAccountOwnerSuccess(response))
  } catch (error) {
    yield put(sendEmailNotificationToAccountOwnerFail(error))
  }
}

function* SendScorecardCreationReminderFunction({ payload: data }) {
  try {
    const response = yield call(SendScorecardCreationReminder, data)
    yield put(sendScorecardCreationReminderSuccess(response))
  } catch (error) {
    yield put(sendScorecardCreationReminderFail(error))
  }
}

export function* watchSendEmailNotificationFunction() {
  yield takeEvery(SEND_EMAIL_NOTIFICATION, SendEmailNotificationFunction);
  yield takeEvery(SEND_SCORECARD_CREATION_REMINDER, SendScorecardCreationReminderFunction)
}

function* sendEmailNotificationSaga() {
  yield all([fork(watchSendEmailNotificationFunction)]);
}

export default sendEmailNotificationSaga;