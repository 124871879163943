
import {
  ADD_CONTENT_EDITOR,
  ADD_CONTENT_EDITOR_SUCCESS,
  ADD_CONTENT_EDITOR_FAIL,
  CLEAR_CONTENT_EDITOR,
} from "./actionTypes"

const initialState = {

  addContentEditorUser: [],
}

const createContentEditorReducer = (state = initialState, action) => {
  switch (action.type) {

    case ADD_CONTENT_EDITOR_SUCCESS:
      return {
        ...state,
        addContentEditorUser: action.payload,

      }

    case ADD_CONTENT_EDITOR_FAIL:
      return {
        ...state,
        addContentEditorUser: [],

      }

    case CLEAR_CONTENT_EDITOR:
      return {
        ...state,
        addContentEditorUser: []
      }

    default:
      return state

  }

}

export default createContentEditorReducer
