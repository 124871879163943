export const getChannelScore = (Channel) => {
    if (Channel.tactics[0]) {
        var tacticsLength = Channel.tactics.length
        var tacticScores = Channel.tactics.map(tactic => {
            if (tactic.tacticScore !== undefined) {
                return parseInt(tactic.tacticScore)
            } else {
                return 0
            }
        })
        var tacticScoreCount = tacticScores.reduce((current, next) => current + next, 0)
        var tacticAvg = tacticScoreCount / tacticsLength
        return Math.round(tacticAvg)
    }
}