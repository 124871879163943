import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"
import mailsSaga from "./mails/saga"
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import accountUserSaga from "./Account-User/saga"
//Get Account List
import superAdmin from "./SuperAdminTable/saga"
import channelSaga from "./ContentEditor/saga"
//Add Account(SuperAdmin)
import addaccountholderSaga from "./CreateAccountHolder/saga"
//Add Content Editor(SuperAdmin)
import addcontenteditorSaga from "./CreateContentEditor/saga"
import UserReducer from "./Accountuser1/reducer"
//add channel
import AddNewChannelSaga from "./AddChannel/saga"

//Get Content Editor List
import EditorList from "./ListContentEditors/saga"
// create report template
import createReportTemplateSaga from "./ReportTemplate/saga"

// Account holder - create account user:
import AddNewUserSaga from "./CreateAccountUser/saga"
import accountHolderUsersSaga from "./GetAccountUser/saga"
import updateAccountHolderUsersSaga from "./UpdateAccountUser/saga"
// scorecards list
import scorecardsListSaga from "./Scorecards/saga"

//Super-Admin get editor info
import AccountUserSaga from "./Accountuser1/saga"


import ContentEditor from "./ContentEditorDetails/saga"

//Super-Admin Update Acocunt

import updateAccountSaga from "./UpdateAccount/saga"

//Super-Admin get userlist of specific account

//CKEditor

import addEditorDataSaga from "./CKEditor/saga"

////Add Tactic Data
import AddNewTacticSaga from "./AddTactic/saga"

//Get Individual Channel
import ChannelUpdateDetails from "./UpdateChannel/saga"

//Update tactic Details
import updateTacticDetailsSaga from "./UpdateTactic/saga"


// Scorecard Report:
import ScorecardReport_Saga from "./ScorecardReport/saga"

//Account Owner - Buy Credits
import GetProductSaga from "./BuyCreditsTable/saga"
import paymentSuccessSaga from "./Stripe/saga"

//Account Owner - Transaction table
import TransactionSaga from "./AccountTransactionTable/saga"

// Content Editor - Instruction Panel i-button POC
import instructionPanelSaga from "./InstructionPanelForContentEditor/saga"

import ArrangeChannelSequenceSaga from "./ArrangeChannelSequence/saga"

//New Dashbaord Scorecount 
import GetNewDashboardScorecountSaga from "./NewDashbaordScorecount/saga"

//Update Instruction -Content Editor 
import UpdateInstructionSaga from "./UpdateInstruction/saga"

// To send email notification
import sendEmailNotificationSaga from "./SendEmailNotification/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(accountUserSaga),
    fork(superAdmin),
    fork(channelSaga),

    fork(addaccountholderSaga),
    fork(addcontenteditorSaga),

    fork(AddNewChannelSaga),
    //fork(addaccountholderSaga),
    fork(EditorList),
    fork(AddNewUserSaga),
    fork(accountHolderUsersSaga),
    fork(updateAccountHolderUsersSaga),
    fork(ContentEditor),

    fork(scorecardsListSaga),

    fork(updateAccountSaga),


    fork(AccountUserSaga),
    fork(addEditorDataSaga),
    fork(createReportTemplateSaga),
    fork(AddNewTacticSaga),
    fork(ChannelUpdateDetails),
    fork(updateTacticDetailsSaga),

    // Scorecard Report
    fork(ScorecardReport_Saga),

    fork(GetProductSaga),
    fork(paymentSuccessSaga),

    //Account Owner - Transaction table
    fork (TransactionSaga),

    // Content Editor - instruction Panel - i-button POC
    fork(instructionPanelSaga),
    //Content Editor - Arrange channel sequence
    fork(ArrangeChannelSequenceSaga),
    
    //Account - owner - NewDashbaord - Scorecount
    fork(GetNewDashboardScorecountSaga),
    fork(UpdateInstructionSaga),

    // To send email notification
    fork(sendEmailNotificationSaga),
  ])
}
