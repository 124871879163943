import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Spinner,
    Table,
    Button,
    
  } from "reactstrap"
  import SweetAlert from "react-bootstrap-sweetalert"

const BuyButton = props => {

     const [showSpinner , setShowSpinner] = useState(false)
    const parameter = props
    const {HANDLE_PAY, stopSpinner , setStopSpinner , showAlert , setShowAlert, INDEX} = parameter

  useEffect(() => {
    if(showAlert){
      setShowSpinner(false)
    }
  }, [showAlert])

  return (


    <div>
       
         
             <Button
             onClick={() => {
                 // setStopSpinner(true)
                                
                 setShowSpinner(true)
                 HANDLE_PAY()
              
       
             }}
            style={{
              backgroundColor: "#0D0F11",
             fontSize: "15px",
             fontWeight: 600,
             letterSpacing:'1px'
    
            }}
            disabled={showSpinner ? true : false}
             block
           >
          {showSpinner ? "" : INDEX === 0 ? "Buy Credit": "Buy Credits" }
           {showSpinner ? (
              <Spinner type="border" size="sm" />
           )
             :
             
             null
           }
           </Button> 
            
            
           

        
       
      

      
        
    
        




    </div>
  )
}

export default BuyButton