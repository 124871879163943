import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

import { getChannelData, GetRoutes, handleCloseReportEditing } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";


// MetisMenu
import MetisMenu from "metismenujs"
import { useHistory, withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import { updateSelfReportScorecardTourSteps } from "config/TourSteps.config"
import { startCoachingForSelfReportUpdateScorecard } from "store/actions"


const SideContentForAccountuser = props => {
  const ref = useRef();
  const history = useHistory()
  const dispatch = useDispatch()

  const { isCoachingStartedForUpdateSelfReportScorecard, scorecardDetail } = useSelector(state => ({
    isCoachingStartedForUpdateSelfReportScorecard: state.CoachingPageReducer.isCoachingStartedForUpdateSelfReportScorecard,
    scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
  }))
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously



  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }


  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }


  const handleSidebarNavigation = (goto_path) => {
    // var is_report_editing_path = location.pathname.includes("edit/scorecard-report")
    var is_generate_pdf_path = location.pathname.includes("/generate-pdf")

    if (is_generate_pdf_path) {
      dispatch(handleCloseReportEditing(true))
    } else {
      history.push(goto_path)
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////


  // To start tour guide for update self report scorecard page
  const startTourGuideObj = driver({
    animate: true,
    showProgress: true,
    allowKeyboardControl: true,
    disableActiveInteraction: false,
    progressText: "{{current}} / {{total}}",
    steps: [
      ...updateSelfReportScorecardTourSteps.filter(e => e.element != ".tour-guide-sidebar-self-report-account-owner")
    ],
    onCloseClick: () => {
      dispatch(startCoachingForSelfReportUpdateScorecard(false))
    },
    onDestroyed: (element, steps, option) => {
      dispatch(startCoachingForSelfReportUpdateScorecard(false))
    },
  })

  const handleTourGuide = () => {
    dispatch(startCoachingForSelfReportUpdateScorecard(true))
  }

  useEffect(() => {
    if (isCoachingStartedForUpdateSelfReportScorecard) {
      startTourGuideObj.drive()
    } else {
      startTourGuideObj.destroy()
    }
  }, [isCoachingStartedForUpdateSelfReportScorecard])



  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            {/* (
          <div className="mb-1 mx-1 py-1 tour-guide-menu px-3"> */}
            {scorecardDetail && scorecardDetail.scorecardVersion === "Self Report" && location.pathname.includes("/self-report/update") &&
              (<div>
                <li className="py-1 rounded-3">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    onClick={handleTourGuide}
                  >
                    <button className="coach-page-btn">
                      <i className="bx bx-book mx-2"></i>
                      <span className="me-2">{props.t("Coaching Page")}</span>
                    </button>
                  </div>
                </li>
                <div className="mt-1" style={{ borderBottom: '1px solid #B8C0CC' }}></div>
              </div>)
            }
            {/* </div>    
        ) */}

            <li className="">
              <Link
                className=""
                to={location.pathname.includes("/generate-pdf") ? {} : "/dashboard"}
                onClick={() => {
                  dispatch(GetRoutes("/dashboard"))
                  handleSidebarNavigation("/dasboard")
                }}
              >
                <div className="tour-guide-sidebar-self-report">
                  <i className="bx bx-home" ></i>
                  <span >{props.t("Scorecard List")}</span>
                </div>
              </Link>
            </li>
            <li >
              {/* <Link to="/create/scorecard">
                  <i className="bx bx-file-blank" ></i>
                  <span >{props.t("Create New Scorecard")}</span>
                </Link> */}
            </li>


            {/* <li>
              <Link to="/generatePDF">
                  <i className="fas fa-file-pdf" ></i>
                  <span >{props.t("Generate PDF")}</span>
                </Link>
              </li> */}

            {/* <li>
                <Link to="/demoPDFPage">
                  <span >{props.t("Demo PDF Page")}</span>
                </Link>
              </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>


  )
}

SideContentForAccountuser.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SideContentForAccountuser))
