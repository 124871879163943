//Add new instruction .
export const ADD_NEW_INSTRUCTION =  "ADD_NEW_INSTRUCTION"
export const ADD_NEW_INSTRUCTION_SUCCESS =  "ADD_NEW_INSTRUCTION_SUCCESS"
export const ADD_NEW_INSTRUCTION_FAIL =  "ADD_NEW_INSTRUCTION_FAIL"
export const ADD_NEW_INSTRUCTION_CLEAR = "ADD_NEW_INSTRUCTION_CLEAR"

//Get all instruction data of contentEditor.
export const GET_ALL_INSTRUCTION_DATA =  "GET_ALL_INSTRUCTION_DATA"
export const GET_ALL_INSTRUCTION_DATA_SUCCESS =  "GET_ALL_INSTRUCTION_DATA_SUCCESS"
export const GET_ALL_INSTRUCTION_DATA_FAIL =  "GET_ALL_INSTRUCTION_DATA_FAIL"
export const GET_ALL_INSTRUCTION_DATA_CLEAR = "GET_ALL_INSTRUCTION_DATA_CLEAR"

// Update instruction
export const UPDATE_INSTRUCTION = "UPDATE_INSTRUCTION"
export const UPDATE_INSTRUCTION_SUCCESS = "UPDATE_INSTRUCTION_SUCCESS"
export const UPDATE_INSTRUCTION_FAIL = "UPDATE_INSTRUCTION_FAIL"
export const UPDATE_INSTRUCTION_CLEAR = "UPDATE_INSTRUCTION_CLEAR"