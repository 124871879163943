import React from "react"



import {
    Row,
    Col,
} from "reactstrap"

import ruff from "../../../../../../assets/images/reportScorcard/Capture-removebg-preview.png"
import { REPORT_PAGE, REPORT, MATURITY_LEVEL_IMAGES } from "../../../config/style.config"
import Knob from "../../../../chart/knob/knob"
import BubbleChart from "../BubbleChart/BubbleChart.componet"

import { getChannelScore } from 'commonFunctions/GetChannelScore/getChannelScore';
import ArrowChart from "../ArrowChart/ArrowChart.componet"

const TacticEvaluated = (props) => {

    const PROPS = props;
    const { ChannelData } = PROPS

    const GetMaturityImage = () => {
        const {
            Early,
            Beginner,
            Intermediate,
            Advanced,
            Mature
        } = MATURITY_LEVEL_IMAGES

        var ChannelScore = Math.round(getChannelScore(ChannelData))
        if (ChannelScore > 19 && ChannelScore <= 39)
            return Beginner
        else if (ChannelScore > 39 && ChannelScore <= 59)
            return Intermediate;
        else if (ChannelScore > 59 && ChannelScore <= 79)
            return Advanced;
        else if (ChannelScore > 79 && ChannelScore <= 100)
            return Mature;
        else
            return Early

    }


    return (
        <>



            <div style={REPORT_PAGE}>
                <Row className="g-0">
                    <Col md="12">
                        <div>
                            <Row>
                                <Col md="12">
                                    <div
                                        style={{
                                            width: "100%",
                                            // marginTop: 35,
                                            // marginLeft: 56,
                                            padding:'30px 40px 0 40px',
                                            height: '100px',
                                            // border:'1px solid black'
                                        }}
                                    >
                                        <h2
                                            style={{
                                                fontSize: REPORT.FONT_SIZE.XLARGE,
                                                fontFamily: REPORT.FONT_FACE.HEADING,
                                                fontWeight: 600,
                                                color: '#0D0F11',
                                                // paddingBottom: '5px',
                                                paddingBottom: '10px',
                                                letterSpacing: '2px',
                                                borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,

                                            }}
                                        >
                                            {ChannelData && ChannelData.channelName.toUpperCase()} TACTICS EVALUATED

                                        </h2>
                                    </div>
                                </Col>
                                <Col md="12" style={{ height: "645px" }}>
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "645px",
                                            paddingLeft: '60px',
                                            paddingTop: '15px',
                                            // border: '1px solid black'
                                            // border:'none'
                                        }}
                                    >
                                        <ArrowChart Length={
                                            ChannelData
                                            &&
                                            ChannelData.tactics.length
                                        }
                                            Tactics={
                                                ChannelData
                                                &&
                                                ChannelData.tactics

                                            }
                                            Id={ChannelData && ChannelData.channelId}
                                        />
                                        <BubbleChart Length={
                                            ChannelData
                                            &&
                                            ChannelData.tactics.length
                                        }
                                            Tactics={
                                                ChannelData
                                                &&
                                                ChannelData.tactics

                                            }
                                            Id={ChannelData && ChannelData.channelId}

                                        >
                                            <div className="text-center" dir="ltr" style={{
                                                margin: "0 auto",
                                                // border: "1px solid red",
                                                width: "fit-content",
                                                height: "fit-content",
                                                marginLeft: 80,
                                                marginTop: -720
                                            }} >
                                                <Knob

                                                    value={
                                                        ChannelData
                                                        && Math.round(getChannelScore(
                                                            ChannelData
                                                        )) || 0
                                                    }
                                                    fgColor="#000"
                                                    bgColor={REPORT.COLOR.FOOTER}
                                                    thickness={0.2}
                                                    readOnly={true}
                                                    height={200}
                                                    width={200}
                                                    onChange={e => {
                                                    }}
                                                />
                                            </div>
                                        </BubbleChart>


                                        {/* </Col>
                                    </Row> */}

                                    </div>
                                </Col>

                                <Col xs="12" style={{ marginTop: '47px' }}>
                                    <Row className="g-0 p-0 m-0" style={{
                                        backgroundColor: "#F4EBD3",
                                    }}>
                                        <Col xs="6">
                                            <div
                                                style={
                                                    {
                                                        margin: "0 auto",
                                                        textAlign: "center",


                                                    }
                                                }
                                            >
                                                <img
                                                    src={GetMaturityImage()}
                                                    style={{
                                                        height: "230px",
                                                        width: "230px",
                                                        // padding: "10px",
                                                        textAlign: 'center',
                                                        margin: "0 auto",
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="6">
                                            <div style={{
                                                margin: "0 auto",
                                                padding: "12px",
                                                textAlign: "center"


                                            }}>
                                                <Knob
                                                    value={
                                                        ChannelData
                                                        && Math.round(getChannelScore(
                                                            ChannelData
                                                        )) || 0
                                                    }
                                                    fgColor="#000"
                                                    bgColor={REPORT.COLOR.FOOTER}
                                                    thickness={0.2}
                                                    readOnly={true}
                                                    height={150}
                                                    width={150}
                                                    onChange={e => {
                                                    }}

                                                />
                                                <p style={{
                                                    fontWeight: 600,
                                                    fontSize: '18px',
                                                    fontFamily: REPORT.FONT_FACE.HEADING,
                                                    color: '#0D0F11',
                                                    marginTop: '-15px'
                                                }}>{ChannelData.channelName.toUpperCase()}<br />SCORE&nbsp;&nbsp;
                                                    {ChannelData
                                                        && Math.round(getChannelScore(
                                                            ChannelData
                                                        )) || 0
                                                    }%
                                                </p>
                                            </div>
                                        </Col>

                                    </Row>
                                </Col>

                            </Row>
                        </div>
                    </Col>
                </Row>
                <Col
                    md="12"
                    style={{
                        position: "absolute",
                        bottom: 0,
                        zIndex: 100,
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            backgroundColor: REPORT.COLOR.FOOTER,
                            fontFamily: REPORT.FONT_FACE.HEADING,
                            letterSpacing: '2px',
                            opacity: 0.9,
                            padding: '5px 5px 5px 40px',
                            fontWeight: 600,
                            color: "white",
                            fontSize: REPORT.FONT_SIZE.MEDIUM,
                        }}
                    >
                        {`${ChannelData && ChannelData.channelName.toUpperCase()} - TACTICS EVALUATED`}
                    </div>
                </Col>
            </div>
        </>
    )
}

export default TacticEvaluated
