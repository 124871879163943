import axios from "axios"
import { del, get, post, put } from "./api_helper"
// import authHeader from "./jwt-token-access/auth-token-header"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
// export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}


// ContentEditor
const AddChannel = (data) => post(url.ADD_CHANNEL, data).then(res => ({ ...res.data, status: 200 })).catch(err => ({
  status: 403
}))
// CreateReport.
const CreateReportTemplate = (data) => put(url.CREATE_REPORT_TEMPLATE, data).then(res => ({ status: 200 })).catch(err => ({
  status: 403
}))

// Get report template
const GetReportTemplate = () => get(url.GET_REPORT_TEMPLATE).then(res => res.data).catch(err => ({
  status: 403
}))









const getDemoData = () => get(url.GET_DEMO_DATA).then(res => res.data);
const getAccountUserData = () => get(url.GET_DEMO_DATA).then(res => res.data[0].AccountUsers).catch(err => {
  // location.reload()
});
const getChannelsTactics = () => get(url.GET_CHANNEL_DATA).then(res => res.data)

const getAccountData = () => get(url.GET_ACCOUNT_TABLE)
  .then(response => response.data)
  .catch(error => error.response ? ({ status: error.response.status }) : ({ status: 403 }))

const addAccountHolder = (data) => post(url.ADD_ACCOUNT_HOLDER, data)
  .then(res => ({
    ...res.data,
    status: 200
  }))
  .catch(error => error.response ? ({ status: error.response.status }) : ({ status: 403 }))

const updateAccountData = (id, data) => put(`${url.UPDATE_ACCOUNT_DETAILS}/${id}`, data).then(res => ([{ status: 200 }])).catch(error => ([{ status: 403 }]))

// const deleteAccountHolder = user =>
//   del(url.DELETE_ACCOUNT_HOLDER, { headers: { user } });


const getAccountDetails = id => get(`${url.GET_ACCOUNT_DETAILS}/${id}`).then(response => response.data)

const getEditorDetails = id => get(`${url.GET_EDITOR_DETAILS}/${id}`).then(response => response.data)

//const getAccountUsersList = id => get(`${url.GET_ACCOUNT_USERS_LIST}/${id}`).then(response => response.data)



//SUPER ADMIN - Content Editor
const addContentEditor = (data) => post(url.ADD_CONTENT_EDITOR, data)
  .then(res => ([{
    ...res.data,
    status: 200
  }]))
  .catch(error => error.response ? ([{ status: error.response.status }]) : ([{ status: 403 }]))
const getContentEditorData = () => get(url.GET_CONTENT_EDITOR_TABLE)
  .then(response => response.data)
  .catch(error => error.response ? ({ status: error.response.status }) : ({ status: 403 }))

const updateEditorData = (id, data) => put(`${url.UPDATE_EDITOR_DETAILS}/${id}`, data).then(res => ([{ status: 200 }])).catch(error => ([{ status: 403 }]))




//Account Holder: add account user:
const addAccountUser = (data) => post(url.ADD_ACCOUNT_USER, data)
  .then(response => ([{ ...response.data, status: 200 }]))
  .catch(error => error.response ? ([{ status: error.response.status }]) : ([{ status: 403 }]))

const getAccountHolderUsers = (id) => get(`${url.GET_ACCOUNT_HOLDER_USERS}/${id}/accountUsers`)
  .then(res => res.data)
  .catch(error => error.response ? ({ status: error.response.status }) : ({ status: 403 }))

const getAccountHolderUserDetails = (id) => get(`${url.GET_ACCOUNT_HOLDER_USER_DETAILS}/${id}`).then(res => res.data)
const updateAccountHolderUserDetails = (id, data) => put(`${url.UPDATE_ACCOUNT_HOLDER_USER_DETAILS}/${id}`, data).then(response => ([{ status: 200 }])).catch(error => ([{ status: 403 }]))
const createScorecard = (data) => post(`${url.CREATE_SCORECARD}`, data).then(res => res).catch(error => ({ status: 403 }))
const getScorecardDetail = (id) => get(`${url.GET_SCORECARD}/${id}`).then(res => res.data).catch(error => ({ status: 403 }))
const updateScorecardDetail = (id, data) => put(`${url.GET_SCORECARD}/${id}`, data).then(res => res).catch(error => ({ status: 403 }))

// display scorecard:
// const getScorecardList = () => get( url.GET_SCORECARDS_LIST ).then(res => res.data)
const getScorecardList = (id) => get(`${url.GET_SCORECARDS_LIST}/${id}/scorecards`)
  .then(res => res.data)
  .catch(error => error.response ? ({ status: error.response.status }) : ({ status: 403 }))

///Accountuser
const getAccountuserData = () => get(url.GET_ACCOUNTUSER_DATA).then(res => res.data)
const getScorecardListOfAccountUser = (id) => get(`${url.GET_SCORECARDS_LIST_OF_ACCOUNT_USER}/${id}/scorecards`)
  .then(res => res.data)
  .catch(error => error.response ? ({ status: error.response.status }) : ({ status: 403 }))


//CkEditor Testing add data
const addEditorData = (data) => post(url.ADD_EDITOR_DATA, data).then(res => ({ status: 200 })).catch(error => ({ status: 403 }))

//CkEditor getdata
const getEditorData = id => get(`${url.GET_EDITOR_DATA}/${id}`)
  .then(response => response)
  .catch(error => error.response ? ({ status: error.response.status }) : ({ status: 403 }))


//cotenteditor|CreateTactic

const AddTactic = (id, data) => post(`${url.ADD_TACTIC}/${id}/tactics`, data).then(res => res).catch(err => ({
  status: 403
}))
// const getChannelTactics = () => get(url.GET_CHANNELS_DATA).then(res => res.data)
/////Accountuser Survey
// const getSurveyData = () =>  get(url.GET_SURVEY_DATA);


// Stripe payment success:
const PaymentSuccess = (id) => get(`${url.PAYMENT_SUCCESS}?session_id=${id}`)
  .then(response => {
    return response.data
  }).catch(error => {
    return error
  })



//ContentEditor - Edit/View Channel | Get Individual Channel Details
const getChannelDetails = (channelId) => get(`${url.GET_CHANNEL_DETAILS}/${channelId}`).then(res => res.data)



//ContentEditor - Edit/View | Update Individual Channel Details
const updateChannelDetails = (channelId, data) => put(`${url.UPDATE_CHANNEL_DETAILS}/${channelId}`, data).then(res => ({ status: 200 })).catch(err => ({
  status: 403
}))

//ContentEditor - Edit/View | Get individual tactic details
const getTacticDetails = (channelId, tacticId) => get(`${url.GET_TACTIC_DETAILS}/${channelId}/tactics/${tacticId}`).then(res => res.data)


//ContentEditor - Create tactic | After creating tactic redirect to fill evaluation criteria
const updateTacticDetails = (channelId, tacticId, data) => put(`${url.UPDATE_TACTIC_DETAILS}/${channelId}/tactics/${tacticId}`, data).then(res => ({ status: 200 })).catch(err => ({ status: 403 }))

//ContentEditor - Edit/View | From sidebar fetching channels and selecting specific tactic for edit
const editTacticDetails = (channelId, tacticId, data) => put(`${url.EDIT_TACTIC_DETAIL}/${channelId}/tactics/${tacticId}`, data).then(res => ({ status: 200 })).catch(err => ({ status: 403 }))



// Scorecard Report:
// GET:
const GetScorecardReport = (scorecardId) => get(`/${url.GET_SCORECARD_REPORT.collection}/${scorecardId}/${url.GET_SCORECARD_REPORT.subCollection}`)
  .then(res => res.data)
// POST:
const CreateScorecardReport = (scorecardId, data) => post(`/${url.GET_SCORECARD_REPORT.collection}/${scorecardId}/${url.GET_SCORECARD_REPORT.subCollection}`, data)
  .then(res => res)
// PUT:
const UpdateScorecardReport = (scorecardId, data) => put(`/${url.UPDATE_SCORECARD_REPORT.collection}/${scorecardId}/${url.UPDATE_SCORECARD_REPORT.subCollection}`, data)
  .then(res => res).catch(error => ({ status: 403 }))

//*//*//*//*//*//*//*//*//*//*//*//*
//Account Owner ProductList
const getProduct = () => get(`/${url.GET_PRODUCT}`).then(res => res).catch(error => error)

//Account Owner Checkout Page Request
//*//*//*//*//*//*//*//*//*//*//*//*




const checkoutRequestData = (data) => post(`/${url.CHECKOUT_REQUEST}`, data).then(res => res).catch(err => ({ status: 403 }))

// const UpdateScorecardReport  = (scorecardId, data) => put(`/${url.UPDATE_SCORECARD_REPORT.collection}/${scorecardId}/${url.UPDATE_SCORECARD_REPORT.subCollection}`,data)
// .then(res => res)

//Account Owner Transaction Page
const getTransaction = (id) => get(`/${url.TRANSACTION}/${id}`).then(res => res.data)
  .catch(error => error.response ? ({ status: error.response.status }) : ({ status: 403 }))


// ContentEditor Instruction Panel:
// GET: 
const getAllInstructionData = () => get(url.GET_INSTRUCTIONS).then(res => res.data).catch(err => ({
  status: 403
}))
const addNewInstruction = (data) => post(url.ADD_NEW_INSTRUCTION, data).then(res => ({ status: 200 })).catch(err => ({
  status: 403
}))
const updateInstruction = (data) => put(url.UPDATE_INSTRUCTION, data).then(res => ({ status: 200 })).catch(err => ({
  status: 403
}))

const ArrangeChannelSequence = (data) => post(url.ARRANGE_CHANNEL_SEQUENCE, data).then(res => ({ status: 200 })).catch(err => ({ status: 403 }))

const NewDashboardScorecount = (data) => post(url.NEW_DASHBOAD_SCORECOUNT, data).then(res => res.data.tempArr).catch(err => ({ status: 403 }))

const GetThreeScorecards = (id) => get(`${url.GET_ONLY_THREE_SCORECARDS}/${id}/scorecards/getOnlyThreeScorecards`)
  .then(res => res.data)
  .catch(error => error.response ? ({ status: error.response.status }) : ({ status: 403 }))

// Send Email notification to Account Owner
const SendEmailNotification = (data) => post(url.SEND_EMAIL_NOTIFICATION, data).then(res => ({ status: 200 })).catch(err => ({
  status: 403
}))

const SendScorecardCreationReminder = (data) => post(url.SEND_SCORECARD_CREATION_REMINDER, data).then(res => ({ status: 200 })).catch(err => ({
  status: 403
}))

export {
  NewDashboardScorecount,
  getDemoData,
  getAccountUserData,
  getAccountData,
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,

  AddChannel,
  CreateReportTemplate,

  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
  getChannelsTactics,
  addAccountHolder,
  addContentEditor,
  getContentEditorData,
  getAccountDetails,
  getEditorDetails,
  updateAccountData,
  updateEditorData,

  GetReportTemplate,
  addAccountUser,
  getAccountHolderUsers,
  getAccountHolderUserDetails,
  updateAccountHolderUserDetails,
  createScorecard,
  getScorecardDetail,

  getScorecardList,
  getAccountuserData,
  //  getSurveyData,
  addEditorData,
  getEditorData,
  AddTactic,
  // getChannelTactics,
  getTacticDetails,


  getChannelDetails,
  updateChannelDetails,

  getScorecardListOfAccountUser,
  updateScorecardDetail,
  updateTacticDetails,
  editTacticDetails,

  // Get Scorecard Report
  GetScorecardReport,
  // Create Scorecard Report
  CreateScorecardReport,
  //Update Scorecard Report
  UpdateScorecardReport,

  //Account Owner - Dashboard Product List
  getProduct,
  // Account Owner - Checkout Request Data
  checkoutRequestData,

  //Buy Credits

  // Stripe payment success:
  PaymentSuccess,

  //Account Owner Transaction Page
  getTransaction,

  // ContentEditor i-button POC
  getAllInstructionData,
  addNewInstruction,
  updateInstruction,
  ArrangeChannelSequence,

  // To get latest three scorecards
  GetThreeScorecards,

  // Email Notification
  SendEmailNotification,
  SendScorecardCreationReminder
}
