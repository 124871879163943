import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState, useCallback, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"



import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import  OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from "react-bootstrap/Popover";
import { Toast } from "react-bootstrap"
import * as Yup from "yup"
import { setIn, useFormik } from "formik"
// import SweetAlert from "react-bootstrap-sweetalert"



// Essential import statement for POC
import img1 from "../../../../assets/images/ScreenshotsForInstructions/coverPageInstruction.png"
import { Link, withRouter, useParams, useHistory } from "react-router-dom"


import {
  getAllInstructionData,
  addNewInstruction,
  updateInstruction,
  handleChangesHappend,
  clearupdateInstruction
} from "store/actions"


const InstructionForCoverPage = () => {
  const params = useParams()
  const history = useHistory()
  const hiddenFile = useRef()
  const dispatch = useDispatch()

  const [infoForCloseEditing, setInfoForCloseEditing] = useState("")
  const [infoForGeneratePDF, setInfoForGeneratePDF] = useState("")
  const [infoForSaveBtn, setInfoForSaveBtn] = useState("")
  const [infoForUploadImageBtn, setInfoForUploadImageBtn] = useState("")
  const [infoForAddConsultantPencil, setInfoForAddConsultantPencil] = useState("")

  const [saveInfo, setSaveInfo] = useState(false)
  const [alldata, setAllData] = useState({})
  const [toastSuccess, setToastSuccess] = useState(false)
  const [toastFail, setToastFail] = useState(false)
  const [pageData, setPageData] = useState(true)
  const [accountUserInstructionData, setAccountUserInstructionData] = useState(null)

  const [openPopoverForCloseEditing, setOpenPopoverForCloseEditing] = useState(false)
  const [openPopoverForGeneratePDF, setOpenPopoverForGeneratePDF] = useState(false)
  const [openPopoverForSavePageBtn, setOpenPopoverForSavePageBtn] = useState(false)
  const [openPopoverForUploadImageBtn, setOpenPopoverForUploadImageBtn] = useState(false)
  const [openPopoverForAddConsultantPencil, setOpenPopoverForAddConsultantPencil] = useState(false)

  const { getInstructionData, addInstructionData, isThereChange , updateInstructionData} = useSelector(state => ({
    getInstructionData: state.instructionPanelReducer.getInstructionData,
    addInstructionData: state.instructionPanelReducer.addInstructionData,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    updateInstructionData: state.UpdateInstructionReducer.updateInstructionData
  }))

  useEffect(() => {
    if (!Object.keys(getInstructionData).includes("infoForAccountUser")) {
      dispatch(getAllInstructionData())
    }
  },[dispatch])

  useEffect(() => {
    if (getInstructionData.infoForAccountUser) {
        var ALL_DATA = {
            ...getInstructionData.infoForAccountUser
        }
        setAccountUserInstructionData(ALL_DATA)
    }
  },[getInstructionData])

  // console.log(getInstructionData)


  useEffect(() => {
    if (accountUserInstructionData) {
        // console.log(accountUserInstructionData.coverPage)
        setPageData(false)
        var data = {
          infoForCloseEditingBtn: accountUserInstructionData.coverPage.infoForCloseEditingBtn ? accountUserInstructionData.coverPage.infoForCloseEditingBtn : "",
          infoForGeneratePDFBtn: accountUserInstructionData.coverPage.infoForGeneratePDFBtn ? accountUserInstructionData.coverPage.infoForGeneratePDFBtn : "",
          infoForSaveBtn: accountUserInstructionData.coverPage.infoForSaveBtn ? accountUserInstructionData.coverPage.infoForSaveBtn : "",
          infoForUploadImageBtn: accountUserInstructionData.coverPage.infoForUploadImageBtn ? accountUserInstructionData.coverPage.infoForUploadImageBtn : "",
          infoForAddConsultantPencil: accountUserInstructionData.coverPage.infoForAddConsultantPencil ? accountUserInstructionData.coverPage.infoForAddConsultantPencil : "",
        }
        setAllData({...data})
        setInfoForCloseEditing(data.infoForCloseEditingBtn)
        setInfoForGeneratePDF(data.infoForGeneratePDFBtn)
        setInfoForSaveBtn(data.infoForSaveBtn)
        setInfoForUploadImageBtn(data.infoForUploadImageBtn)
        setInfoForAddConsultantPencil(data.infoForAddConsultantPencil)
    }
  }, [accountUserInstructionData])
  // ----- Saving all instruction to the backend ----
//   console.log(openPopoverForCloseEditing)
// console.log("AllData..",accountUserInstructionData)

// console.log("Updating instruction", updateInstructionData)


  // For Close Editing Button
  const closeEditing = (
    <Popover 
      id="popover-positioned-bottom" 
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }} 
      show={openPopoverForCloseEditing}
    >
      <Popover.Header>
        Close Editing
        <button 
          type="button" 
          className="close" 
          onClick={() => setOpenPopoverForCloseEditing(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form 
          onSubmit={(e) => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(updateInstruction({
              ...getInstructionData,
              infoForAccountUser: {
                ...accountUserInstructionData,
                coverPage: {
                    ...alldata,
                    infoForCloseEditingBtn: infoForCloseEditing
                }
              }
            }))
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea 
            className="form-control-sm form-control mb-1 font-size-13" 
            style={{height:'100px', width:'242px'}}
            onChange={(e) => {
              setInfoForCloseEditing(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForCloseEditing}
          >
          </textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1"
                  onClick={() => setOpenPopoverForCloseEditing(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary" 
                  className="mt-1" 
                  type="submit"  
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? (
                    <Spinner type="border" size="sm" />
                  ) : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  );


  // For GeneratePDF Button
  const generatePDF = (
    <Popover id="popover-positioned-auto" 
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForGeneratePDF}
    >
      <Popover.Header>
        Generate PDF
        <button 
          type="button" 
          className="close" 
          onClick={() => setOpenPopoverForGeneratePDF(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form 
          onSubmit={(e) => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(updateInstruction({
              ...getInstructionData,
                infoForAccountUser: {
                    ...accountUserInstructionData,
                    coverPage: {
                        ...alldata,
                        infoForGeneratePDFBtn: infoForGeneratePDF
                    }
                }
            }))
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea 
            className="form-control-sm form-control mb-1 font-size-13" 
            style={{height:'100px', width:'242px'}}
            onChange={(e) => {
              setInfoForGeneratePDF(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForGeneratePDF}
          >
          </textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForGeneratePDF(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary" 
                  className="mt-1" 
                  type="submit"  
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? (
                    <Spinner type="border" size="sm" />
                  ) : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  );

  // For Save Button
  const saveBtn = (
    <Popover 
      id="popover-positioned-auto" 
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForSavePageBtn}
    >
      <Popover.Header>
        Save Button
        <button 
          type="button" 
          className="close" 
          onClick={() => setOpenPopoverForSavePageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form 
          onSubmit={(e) => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(updateInstruction({
              ...getInstructionData,
                infoForAccountUser: {
                    ...accountUserInstructionData,
                    coverPage: {
                        ...alldata,
                        infoForSaveBtn: infoForSaveBtn
                    }
                }
            }))
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea 
            className="form-control-sm form-control mb-1 font-size-13" 
            style={{height:'100px', width:'242px'}}
            onChange={(e) => {
              setInfoForSaveBtn(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForSaveBtn}
          >
          </textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForSavePageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary" 
                  className="mt-1" 
                  type="submit"  
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? (
                    <Spinner type="border" size="sm" />
                  ) : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  );


//   // For upload image Button
  const uploadImageBtn = (
    <Popover 
      id="popover-positioned-auto" 
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForUploadImageBtn}
    >
      <Popover.Header>
        Upload Image
        <button 
          type="button" 
          className="close" 
          onClick={() => setOpenPopoverForUploadImageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form 
          onSubmit={(e) => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(updateInstruction({
              ...getInstructionData,
                infoForAccountUser: {
                    ...accountUserInstructionData,
                    coverPage: {
                        ...alldata,
                        infoForUploadImageBtn: infoForUploadImageBtn
                    }
                }
            }))
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea 
            className="form-control-sm form-control mb-1 font-size-13" 
            style={{height:'100px', width:'242px'}}
            onChange={(e) => {
              setInfoForUploadImageBtn(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForUploadImageBtn}
          >
          </textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForApplyPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary" 
                  className="mt-1" 
                  type="submit"  
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? (
                    <Spinner type="border" size="sm" />
                  ) : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  );

// For Add Consultant Button
  const addConsultantPencil = (
    <Popover 
      id="popover-positioned-auto" 
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForAddConsultantPencil}
    >
      <Popover.Header>
        Add Consultant
        <button 
          type="button" 
          className="close" 
          onClick={() => setOpenPopoverForAddConsultantPencil(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form 
          onSubmit={(e) => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(updateInstruction({
              ...getInstructionData,
                infoForAccountUser: {
                    ...accountUserInstructionData,
                    coverPage: {
                        ...alldata,
                        infoForAddConsultantPencil: infoForAddConsultantPencil
                    }
                }
            }))
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea 
            className="form-control-sm form-control mb-1 font-size-13" 
            style={{height:'100px', width:'242px'}}
            onChange={(e) => {
              setInfoForAddConsultantPencil(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForAddConsultantPencil}
          >
          </textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForAddPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary" 
                  className="mt-1" 
                  type="submit"  
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? (
                    <Spinner type="border" size="sm" />
                  ) : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  );


  useEffect(() => {
  
      if (updateInstructionData.status === 200) {
        setToastSuccess(true)
        setOpenPopoverForCloseEditing(false)
        setOpenPopoverForGeneratePDF(false)
        setOpenPopoverForSavePageBtn(false)
        setOpenPopoverForUploadImageBtn(false)
        setOpenPopoverForAddConsultantPencil(false)
        setSaveInfo(false)
        dispatch(getAllInstructionData())
         dispatch(clearupdateInstruction())
       
      }else if (updateInstructionData.status === 403) {
        setToastFail(true)
        setOpenPopoverForCloseEditing(false)
        setOpenPopoverForGeneratePDF(false)
        setOpenPopoverForSavePageBtn(false)
        setOpenPopoverForUploadImageBtn(false)
        setOpenPopoverForAddConsultantPencil(false)
        setSaveInfo(false)
         dispatch(clearupdateInstruction())
      

    }
   
  },[updateInstructionData])

  return (
    <>
      <div className="page-content">
        
      {toastSuccess && (
          <div
            className="position-fixed top-0 left-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast
              
              delay={3000}
              show={true}
              onClose={() => setToastSuccess(false)}
              autohide
            >
              <Toast.Body
                style={{ color: "#fff", height: "55px" }}
                className="font-size-18 bg-primary"
              >
                <i
                  className="mdi mdi-check-circle d-flex"
                  style={{ fontSize: 22 }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      fontStyle: "normal",
                      marginTop: "2px",
                    }}
                    className="mx-3"
                  >
                    Instructions saved successfully.
                  </p>
                </i>
              </Toast.Body>
            </Toast>
          </div>
        )}
        {toastFail && (
         <div className="position-fixed top-0 left-0 p-3" style={{ zIndex: "1005" }}>
        <Toast
               
                delay={3000}
                show={toastFail}
                onClose={() => setToastFail(false)}
                autohide
              >
                <Toast.Body
                  style={{ color: "#fff", height: "55px" }}
                  className="font-size-18 bg-danger"
                >
                  <i
                    className="mdi mdi-close-circle d-flex"
                    style={{ fontSize: 22 }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontStyle: "normal",
                        marginTop: "2px",
                      }}
                      className="mx-3"
                    >
                      Failed to save instructions.
                    </p>
                  </i>
                </Toast.Body>
              </Toast>
     </div>
          
              
        
        )}
        <MetaTags>
          <title>Mambo - Instructions For Account User</title>
        </MetaTags>
        {
          pageData ?
            <div style={
              {
                width: "100%",
                textAlign: "center",
                height: "400px"
              }
            } >
              <Spinner
                style={{
                  width: "60px", height: "60px",
                  margin: "calc(200px - 30px)",

                }}
                role="grid"
                type="border"
                color="primary"
              />
            </div>
          :
        <Container 
          fluid={true}
          className="my-3 justify-content-center"
          style={{
            transform: "scale(.8) translate(-130px, -132px) ",
            width: "1550px",
          }}
        >
        <Row>
         <Col>
          <Card>
            <CardBody>
             
              <div>
                <div className="my-3">
                  <h2 className="fw-bold" style={{color:"#0D0F11"}}>
                    Instruction For Cover Page
                  </h2>
                </div>
                <div
                  style={{
                    height: "700px",
                    width: "100%",
                    transform: "scale(1)",
                    backgroundImage: `url(${img1})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {/* First Row for i-button in Cover Page */}
                  <Row>
                    <Col xs="9"></Col>
                    <Col xs="1">
                      <div>
                        <label
                          style={{marginTop:'32px', marginLeft:'-149px'}}
                        >
                            <OverlayTrigger 
                              trigger="click" 
                              placement="bottom" 
                              overlay={closeEditing}
                              show={openPopoverForCloseEditing}
                            >
                              <span
                                id="close-editing"
                                className="mdi mdi-information"
                                onClick={() => setOpenPopoverForCloseEditing(!openPopoverForCloseEditing)}
                                style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "22px",
                                color: "#5015cf",
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                                backgroundColor: "#fff",
                                cursor:'pointer'
                                }}>
                              </span>
                            </OverlayTrigger>
                        </label>
                      </div>
                      </Col>
                      <Col xs="1">
                        <div>
                          <label
                            type="button"
                            style={{marginTop:'32px', marginLeft:'-28px'}}
                          >
                            
                            <OverlayTrigger 
                              trigger="click" 
                              placement="bottom" 
                              overlay={generatePDF}
                              show={openPopoverForGeneratePDF}
                            >
                              <span
                                id="close-editing"
                                className="mdi mdi-information"
                                onClick={() => setOpenPopoverForGeneratePDF(!openPopoverForGeneratePDF)}
                                style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "22px",
                                color: "#5015cf",
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                                backgroundColor: "#fff",
                                cursor:'pointer'
                              }}>
                              </span>
                            </OverlayTrigger>
                                
                          </label>
                        </div>
                      </Col>
                    </Row>

                    {/* second row in Cover Page*/}
                    <Row>
                      <Col md="8"></Col>
                      <Col md="1">
                      <div>
                          <label
                            type="button"
                            style={{marginTop:'85px', marginLeft:'-80px'}}
                          >
                            
                            <OverlayTrigger 
                              trigger="click" 
                              placement="bottom" 
                              overlay={saveBtn}
                              show={openPopoverForSavePageBtn}
                            >
                              <span
                                id="close-editing"
                                className="mdi mdi-information"
                                onClick={() => setOpenPopoverForSavePageBtn(!openPopoverForSavePageBtn)}
                                style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "22px",
                                color: "#5015cf",
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                                backgroundColor: "#fff",
                                cursor:'pointer'
                              }}>
                              </span>
                            </OverlayTrigger>
                                
                          </label>
                        </div>
                      </Col>
                      <Col md="1">
                      <div>
                        <label
                            type="button"
                            style={{marginTop:'85px', marginLeft:'-72px'}}
                        >
                            
                            <OverlayTrigger 
                              trigger="click" 
                              placement="bottom" 
                              overlay={uploadImageBtn}
                              show={openPopoverForUploadImageBtn}
                            >
                              <span
                                id="close-editing"
                                className="mdi mdi-information"
                                onClick={() => setOpenPopoverForUploadImageBtn(!openPopoverForUploadImageBtn)}
                                style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "22px",
                                color: "#5015cf",
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                                backgroundColor: "#fff",
                                cursor:'pointer'
                              }}>
                              </span>
                            </OverlayTrigger>
                                
                          </label>
                        </div>
                      </Col>
                      <Col md="2"></Col>
                    </Row>
                    
                    {/* Third Row in Cover Page */}
                    <Row>
                      <Col md="5"></Col>
                      <Col md="1">
                      <div>
                        <label
                            type="button"
                            style={{marginTop:'468px', marginLeft:'42px'}}
                        >
                            <OverlayTrigger 
                              trigger="click" 
                              placement="top" 
                              overlay={addConsultantPencil}
                              show={openPopoverForAddConsultantPencil}
                            >
                              <span
                                id="close-editing"
                                className="mdi mdi-information"
                                onClick={() => setOpenPopoverForAddConsultantPencil(!openPopoverForAddConsultantPencil)}
                                style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "22px",
                                color: "#5015cf",
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                                backgroundColor: "#fff",
                                cursor:'pointer'
                              }}>
                              </span>
                            </OverlayTrigger>
                                
                          </label>
                        </div>
                      </Col>
                    </Row>
                </div>
              </div>
            </CardBody>
          </Card>
          </Col>
          </Row> 
        </Container>
        }
      </div>
    </>
  )
}

export default InstructionForCoverPage
