import { takeEvery, put, call,all,fork  } from "redux-saga/effects";

import {GET_PRODUCT , CHECKOUT_REQUEST} from "./actionTypes"

import {getProductSuccess , getProductFail , checkoutRequestDataSuccess,checkoutRequestDataFail} from "./actions"

import {getProduct, checkoutRequestData} from "../../helpers/fakebackend_helper"

function* onGetProduct () {
    try{
        const response = yield call(getProduct)
        yield put(getProductSuccess(response));

    }
    catch (error){ 
        yield put(getProductFail(error));
        
    }

}

function* onCheckoutRequestData({payload:data}){
    try{
        const response = yield call(checkoutRequestData , data)
        yield put(checkoutRequestDataSuccess(response));
    }
    catch(error){
        yield put(checkoutRequestDataFail(error))

    }
}

function * GetProductSaga () {
    yield takeEvery(GET_PRODUCT, onGetProduct);
    yield takeEvery(CHECKOUT_REQUEST , onCheckoutRequestData )

}

export default GetProductSaga