import MetaTags from 'react-meta-tags';  // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  NavItem,
  NavLink,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import CropEasy from "../../Crop/CropEasy"
import { CLOUD_STORAGE } from "config/cloudStorage.config"
import ImageThumbnail from "components/Common/ImageThumbnail/ImageThumbnail.component"

import SweetAlert from "react-bootstrap-sweetalert"


import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom"
import classnames from "classnames"
// import { getDemoData } from "../../store/actions";
//Import Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import FormValidation from "../Form/index"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
import ImagePreview from "components/Common/ImagePreview/ImagePreview.component"


import {
  createReportTemplate,
  getReportTemplate,
  handleChangesHappend,
  clearReportTemplate
} from "store/actions"




const Report = () => {

  const dispatch = useDispatch()
  const [tableOfContents, setTableOfContents] = useState([
    "Scorecard Methodology",
    "Executive Summary",
    "Priority Areas of Focus",
    "Scores at a Glance",
    "Deep Dive into Your Channels",
    "Key Takeaways"
  ])
  const [reportTitle, setReportTitle] = useState("Modern Marketing Scorecard")
  const [isGetReportData, setIsGetReportData] = useState(true)

  const [file, setFile] = useState(null)
  const [image_url, setImage_Url] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
  const [isImageThere, setImageThere] = useState(false)
  const [HeightWidth, setHeightWidth] = useState({})
  const [iconThumbnail, setIconThumbnail] = useState(null)
  const [onSaveClick, setOnSaveClick] = useState(false)
  const [reportImage, setReportImage] = useState(null)
  const [previewImage, setPreviewImage] = useState({visible: false})


  const history = useHistory()

  const { getReport, createReport } = useSelector(state => ({
    getReport: state.createReportReducer.getReport,
    createReport: state.createReportReducer.createReport
  }));

  useEffect(() => {
    dispatch(getReportTemplate())
    setIsGetReportData(false)
  }, [dispatch])

  useEffect(() => {
    if (getReport.cover && getReport.cover.bgImage) {
      setIconThumbnail(getReport.cover.bgImage)
      setImageThere(true)
    }
  }, [getReport])

  useEffect(() => {
    if (image_url && image_url.includes("firebasestorage.googleapis.com")) {
      setReportImage(image_url)
    }
  }, [image_url])

  // console.log("GET REPORT...", getReport)

  const createReportHandler = () => {
    // const data = {
    //   lastUpdatedOn: new Date(),
    //   templateReport: "test_v0.04",
    //   cover: {
    //     title: reportTitle,
    //     bgImage: "https://images.unsplash.com/photo-1582808888044-d6106d8499da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXRodW1ibmFpbHx8NDg3NDMzM3x8ZW58MHx8fHw%3D&dpr=1&auto=format&fit=crop&w=294&q=60"
    //   },
    // tableOfContents: {
    //   contents: tableOfContents,
    //   bgImage: "https://images.unsplash.com/photo-1565721600016-4bc6d959ed3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXRodW1ibmFpbHx8ODI5MTIxN3x8ZW58MHx8fHw%3D&dpr=1&auto=format&fit=crop&w=294&q=60"
    // }
    // }

    let data = {
      lastUpdatedOn: new Date(),
      cover: {
        title: reportTitle,
        bgImage: reportImage
      },
      tableOfContents: {
        contents: tableOfContents,
        bgImage: "https://images.unsplash.com/photo-1565721600016-4bc6d959ed3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXRodW1ibmFpbHx8ODI5MTIxN3x8ZW58MHx8fHw%3D&dpr=1&auto=format&fit=crop&w=294&q=60"
      }
    }

    // console.log("Data to be save...", data)

    dispatch(createReportTemplate(data))
    dispatch(handleChangesHappend(false))
    // history.push("/summary")
  }

  // Cover page image upload
  const handleChange = e => {
    const file = e.target.files[0]

    if (file) {
      var u = URL.createObjectURL(file)
      var img = new Image()
      img.onload = function () {
        var IMAGE_HEIGHT = img.height
        var IMAGE_WIDTH = img.width
        // HEIGHT > WIDTH ? console.log('vertical Image') : console.log('horizontal image')

        // console.log("Width: "+ IMAGE_WIDTH + " " + "Height: " + IMAGE_HEIGHT);
        console.log(file)

        setHeightWidth({
          myheight: IMAGE_HEIGHT,
          mywidth: IMAGE_WIDTH,
        })
      }
      img.src = u

      setFile(file)
      setImage_Url(u)
      setOpenCrop(true)
      setImageThere(false)
      dispatch(handleChangesHappend(true))
      e.target.value = ""
    }
  }

  // console.log("REPORT IMAGE....", reportImage, "...image_url...", image_url)   // isImageThere && (!iconThumbnail || !image_url)
  // console.log("IS IMAGE THERE...", isImageThere, "...Icon thumbnail...", iconThumbnail)
  console.log("isImageThere...", isImageThere, "...iconThumbnail..", iconThumbnail, "...image_url...", image_url, "...Boolean...",isImageThere && !iconThumbnail && !image_url)

  //isImageThere && !iconThumbnail && !reportImage

  // console.log("isImageThere...", isImageThere, "...iconThumbnail...", iconThumbnail, "...reportImage...", reportImage)


  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Scorecard Report Template</title>
        </MetaTags>
        <Container fluid={true}>
          {
            isGetReportData ?
              <div style={
                {
                  width: "100%",
                  textAlign: "center",
                  height: "400px"
                }
              } >
                <Spinner
                  style={{
                    width: "60px", height: "60px",
                    margin: "calc(200px - 30px)",

                  }}
                  role="grid"
                  type="border"
                  color="primary"
                />
              </div>
              :
              <Row>
                <Col xl="12" style={{ margin: "auto" }}>
                  <Card>
                  {previewImage.visible && (
                    <ImagePreview
                      ImageUrl={image_url || iconThumbnail}
                      TogglePreview={() => {
                        setPreviewImage(e => ({
                          ...e,
                          visible: false,
                        }))
                      }}
                    />
                  )}
                    <CardBody>

                      {createReport.status === 200 && (
                        <SweetAlert
                          // title="Tactic Updated successfully"
                          success
                          confirmBtnBsStyle="primary"
                          style={{ height: "255px", width: "368px" }}
                          onConfirm={() => {
                            // dispatch(UpdateGetReportTemplate(ExecutiveSummaryUpdate))
                            setOnSaveClick(false)
                            // setsuccess_msg(false)
                            dispatch(clearReportTemplate())
                            // history.push("/")
                          }}

                        >
                          Data saved successfully.
                        </SweetAlert>
                      )}
                      {createReport.status === 403 && (
                        <SweetAlert
                          // title="Tactic Updated successfully"
                          error
                          confirmBtnBsStyle="primary"
                          style={{ height: "255px", width: "368px" }}
                          onConfirm={() => {
                            setOnSaveClick(false)
                            // setsuccess_msg(false)
                            dispatch(clearReportTemplate())
                            // history.push("/")
                          }}

                        >
                          Failed to saved data.
                        </SweetAlert>
                      )}

                      <Form onSubmit={(e) => {
                        e.preventDefault()
                        setOnSaveClick(true)
                        console.log("IN UPLOADING REPORT COVER PAGE IMAGE...")
                        createReportHandler()
                      }} >
                        <Row>
                          <h4 className="card-title" style={{ color: "" }}  >Scorecard Cover Page</h4>
                        </Row><br></br>

                        <Row>

                          <Col md="4">
                            <div className="mb-3">
                              <Label htmlFor="formrow-scorecard-Input">Scorecard Name</Label>
                              <Input
                                readOnly
                                type="text"
                                className="form-control"
                                id="formrow-scorecard-Input"
                                placeholder="Modern Marketing Scorecard"
                              />
                            </div>
                          </Col>

                          <Col md="4">
                            <label htmlFor="input">Upload Image</label>
                            <Row>
                              <Col md="8">
                                <input
                                  id="input"
                                  type="file"
                                  name="Image_URL"
                                  accept="image/*"
                                  className="form-control form-control-md inputFile"
                                  style={{ display: "none" }}
                                  onChange={handleChange}
                                />
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    var inputFile = document.querySelector("#input")
                                    inputFile.click()
                                    setReportImage(null)
                                  }}
                                  style={{
                                    position: "absolute",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <i
                                    className="mdi mdi-image"
                                    style={{ marginRight: "5px", fontSize: "15px" }}
                                  ></i>
                                  Choose Image
                                </Button>{" "}
                                <br />
                              </Col>

                              <Col md="2">
                                {isImageThere && !iconThumbnail && !reportImage ? (
                                  <Spinner />
                                ) : (
                                  <ImageThumbnail
                                    onClick={() => {
                                      setPreviewImage({
                                        visible: true,
                                      })
                                      // setImageThere(false)
                                    }}
                                    imgeUrl={iconThumbnail}
                                  />
                                )}
                              </Col>

                            </Row>
                          </Col>

                        </Row><br></br>
                        <Row>
                          <Col md="2"></Col>
                          <Col md="8">
                            {openCrop && (
                              <CropEasy
                                image_url={image_url}
                                imageName={file.name}
                                image_storagePath={CLOUD_STORAGE.REPORT.IMAGE}
                                thumbnail_storagePath={
                                  CLOUD_STORAGE.REPORT.THUMBNAIL
                                }
                                setOpenCrop={setOpenCrop}
                                setImageUrl={setImage_Url}
                                setFile={setFile}
                                setIconThumbnail={setIconThumbnail}
                                ImageHeightWidth={HeightWidth}
                                setImageThere={setImageThere}
                                ImageType="REPORT"
                              // setReportImage={setReportImage}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <h4 className="card-title" style={{ color: "" }}  >Table of Contents</h4>
                        </Row><br></br>
                        {
                          tableOfContents.map((content, i) => (
                            <Row key={i} ><label>{++i}{". "}{content}</label></Row>
                          ))
                        }
                        {/* <br></br> */}
                        <Row>
                          <Col md="10"></Col>
                          <Col md="2">
                            <Button
                              color="primary"
                              type="submit"
                              style={{ width: "100%" }}
                              disabled={reportImage ? onSaveClick ? true : false : true}
                              block
                            >
                              {onSaveClick ? "" : "Save"}
                              {onSaveClick ? (
                                <Spinner type="border" size="sm" />
                              ) : null}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
        </Container>
      </div>
    </>
  );
}

export default Report;
