import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory, useParams } from "react-router-dom"
import { CONTENT_EDITOR } from "config/AlertMessage.config"
import Switch from "react-switch"
import TacticPreview from "../TacticPreviewPage/TacticPreview"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  NavItem,
  NavLink,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledAlert
} from "reactstrap"

import * as Yup from "yup"
import { useFormik } from "formik"

import classnames from "classnames"

import {
  AddNewTactic,
  ClearAddTactic,
  ChannelsTactic,
  getChannelData,
  changeChannelData,
  handleUnsavedChanges,
  handleChangesHappend,
  getTacticInfoClear,
  updateChannelDataState,
  ClearUpdateChannelDataState
} from "../../../store/actions"
import { values } from "lodash"
import { channelTacticsLimit } from "../../../config/UserCreationLimit_config"

const CreateTactic = () => {
  const history = useHistory()

  const params = useParams()
  ///table
  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1, 2, 3, 4, 5])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const [successMsg, setSuccessMsg] = useState(false)

  //popup
  const [isTacticAdded, setIsTacticAdded] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [step1_txt, setstep1_txt] = useState("")
  const [step2_txt, setstep2_txt] = useState("")
  const [step3_txt, setstep3_txt] = useState("")
  // const current_ip = "219.91.239.22"
  // const [close_timer, setclose_timer] = useState(false);

  const [tacticsLimitExceed, settacticsLimitExceed] = useState(false)
  const [updateLocalStateOfTactic, setUpdateLocalStateOfTactic] = useState({})
  const [HandleTacticsList, setHandleTacticsList] = useState({})
  const [channelId, setChannelId] = useState("")
  const [showPreview, setShowPreview] = useState(false)
  
 
  function handleStep1Change(e) {
    setstep1_txt(e.target.value)
  }

  function handleStep2Change(e) {
    setstep2_txt(e.target.value)
  }

  function handleStep3Change(e) {
    setstep3_txt(e.target.value)
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 5) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

 

  const [isUnmounted, setIsUnmounted] = useState(true)
  
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsTacticAdded(false)
  //     dispatch(AddNewTactic())
  //   }, 6000)
  // }, [isTacticAdded])

  const dispatch = useDispatch()
  const { channelData, addTacticData, isDataEdited, isThereChanges } = useSelector(state => ({
    channelData: state.GetChannelData.channelData,
    addTacticData: state.AddTacticReducer.addTacticData,
    isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges
  }))

  useEffect(() => {
    !channelData[0] && dispatch(getChannelData())

  }, [dispatch])

  // const getEnabledTacticCount = () => {

  // }
  // console.log(channelData)


//   const GetEnableTacticCount = () => {
//     var data = channelData &&  channelData.filter(ch => ch.channelId === values.channelId)
//     var count = data[0] && data[0].tactics.filter(tactic => !tactic.isDisabled)
//     return count && count.length 
     
    
//  }

  

  

 
//console.log(GetEnableTacticCount())

  // useEffect(() => {
  //   setIsTatcicDisabled(!channelDetails.isDisabled)
  // }, [channelDetails])
  // console.log("channelData",channelData)

  // const getPresentTacticsCount = () => {
  //   return
  // }
  // console.log(channelData)
  // console.log(values.channelId)

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsTacticAdded(false)
  //     dispatch( ClearAddTactic())
  //     validation.values.TacticName = ""

  //     validation.values.Interrogation = ""

  //   }, 6000)
  // }, [isTacticAdded])
  // console.log(addTacticData)
  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      TacticName: "",
      channelId: "",
      Interrogation: "",
      Evaluation: "",
      Reccomendation: "",
      Score: "0",
      Evaluation1: "",
      Reccomendation1: "",
      Score1: "25",
      Evaluation2: "",
      Reccomendation2: "",
      Score2: "50",
      Evaluation3: "",
      Reccomendation3: "",
      Score3: "75",
      Evaluation4: "",
      Reccomendation4: "",
      Score4: "100",
    },

    validationSchema: Yup.object({
      channelId: Yup.string().required("Please select channel name"),
      TacticName: Yup.string().required("Please enter tactic Name"),
      Interrogation: Yup.string().required("Please enter interrogation"),
    }),
    onSubmit: values => {
      setIsTacticAdded(true)
      
      

      // console.log("channelData",channelData)

      // var currentChannel = channelData.filter(ch => ch.channelId === values.channelId)
      // console.log(currentChannel[0].tactics.filter(tactic => tactic.isDisabled === false).length)

      // if (currentChannel[0] && currentChannel[0].tactics.filter(tactic => tactic.isDisabled === false).length < channelTacticsLimit) {
        const tacticData = {
          
          tacticName: values.TacticName,
          channelId: values.channelId,
          channelName: GetChannelName(),
          interrogation: values.Interrogation,
          isDisabled:true
         
        }
       
        setsuccess_msg(true)

        dispatch(AddNewTactic(values.channelId, tacticData))
        dispatch(handleChangesHappend(false))


        // var newList = channelData.map(ch => {
        //   if (ch.channelId === values.channelId) {
        //     return ({
        //       ...ch,
        //       tactics: [...ch.tactics, tacticData]
        //     })
        //   }
        //   else {
        //     return ch
        //   }
        // })

        // console.log("New Tactic List...",newList)
        setHandleTacticsList(tacticData)
        // dispatch(changeChannelData(newList))
        // dispatch(changeChannelData([...currentChannel[0].tactics, tacticData]))
      // }
      // else {
      //   settacticsLimitExceed(true)
      // }



      // setUpdateLocalStateOfTactic({
      //   ...channelData,
      //   tactics: [...tacticData]
      // })


    },
    onReset: () => {
      dispatch(handleChangesHappend(false))
    }
  })

  function hideTimeAlert() {
    settimeralert(null)
  }

  const GetChannelName = () => {
    var channelName = channelData.filter(e => {
      return e.channelId === validation.values.channelId
    })
    return channelName[0].channelName
  }

 
 

  // useEffect(() => {
  //   GetEnableTacticCount()
  // }, [channelData])

  // const GetChannelID = () => {
  //   var CHANNELID = channelData.filter(e => {
  //     console.log(e)
  //     return e.channelId === validation.values.channelId
     
  //   })
  //   return CHANNELID
  // }
  // console.log(GetChannelID())

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Create Tactic</title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <Col sm="12" style={{ margin: "auto" }}>
              <Card>
                <CardBody>
                  {addTacticData &&
                    addTacticData.status === 201 &&
                    success_msg && 
                    (
                      <SweetAlert
                        // title="Tactic created successfully"

                        success
                        showCancel
                        confirmBtnText="Add another tactic"
                        cancelBtnText="Yes"
                        // style={{height:'255px',
                        //   width:'368px'}}
                        confirmBtnBsStyle="secondary"
                        cancelBtnBsStyle="primary"
                        onConfirm={() => {
                          var newList = channelData.map(ch => {
                            if (ch.channelId === validation.values.channelId) {
                              return ({
                                ...ch,
                                tactics: [...ch.tactics, {
                                  ...HandleTacticsList,
                                  tacticId: addTacticData.data.id
                                }]
                              })
                            }
                            else {
                              return ch
                            }
                          })
                          // console.log(newList)
                          dispatch(changeChannelData(newList))
                          window.localStorage.setItem("channelList", JSON.stringify(HandleTacticsList))
                          setsuccess_msg(false)
                          setIsTacticAdded(false)
                          validation.resetForm()
                          dispatch(ClearAddTactic())
                        }}
                        onCancel={() => {
                          var newList = channelData.map(ch => {
                            if (ch.channelId === validation.values.channelId) {
                              return ({
                                ...ch,
                                tactics: [...ch.tactics, {
                                  ...HandleTacticsList,
                                  tacticId: addTacticData.data.id
                                }]
                              })
                            }
                            else {
                              return ch
                            }
                          })
                          // window.localStorage.setItem("channelList", JSON.stringify(HandleTacticsList))
                          dispatch(changeChannelData(newList))
                          setsuccess_msg(false)
                          history.push(
                            `/tactic-details/${validation.values.channelId}/tactics/${addTacticData.data.id}`
                          )
                          dispatch(ClearAddTactic())
                        }}
                      >
                        <h4>Tactic created successfully.</h4>
                        <span>
                          Do you want to continue with evaluation criteria?
                        </span>{" "}
                      </SweetAlert>
                    )}
                  {addTacticData &&
                    addTacticData.status === 403 &&
                    (
                      <SweetAlert
                        // title="Failed to  create tactic!"
                        error
                        confirmBtnBsStyle="primary"
                        style={{ height: "255px", width: "368px" }}
                        onConfirm={() => {
                          
                          setIsTacticAdded(false)
                          getTacticInfoClear()
                          dispatch(ClearAddTactic())
                         
                        }}
                        
                      >
                        {CONTENT_EDITOR.CREATE_TATCTIC.FAILED}
                      </SweetAlert>
                    )}

                  {/* {
                    tacticsLimitExceed && 
                    <UncontrolledAlert color="danger" onClick={() => {
                      settacticsLimitExceed(false),setIsTacticAdded(false)

                    }}>{
                      <strong>{CONTENT_EDITOR.TACTIC_LIMIT_EXCEED.message}</strong>
                    }
                    </UncontrolledAlert>
                  } */}

                  <Form
                    className="needs-validation"
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    {/* <Row>  <h4 className="card-title" style={{textTransform: ""}}>Tactic Details </h4></Row>
                                        <br></br>    */}

                    <Row>
                      <Col sm="4">
                        <FormGroup className="mb-3">
                          <label>Channel Name</label>
                          <Input
                            name="channelId"
                            placeholder="channel name"
                            type="select"
                            className="form-select"
                            id="horizontal-channelname-Input"
                            onChange={(e) => {
                              validation.handleChange(e)
                              dispatch(handleChangesHappend(true))
                              // console.log(e)
                              
                            

                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.channelId || ""}
                            invalid={
                              validation.touched.channelId &&
                                validation.errors.channelId
                                ? true
                                : false
                            }
                          >
                            <option value="">channel name</option>
                            {channelData.map(channel => (
                              <option
                                key={channel.channelId}
                                value={channel.channelId}
                              >
                                {channel.channelName}
                              </option>
                            ))}
                          </Input>

                          {validation.touched.channelId &&
                            validation.errors.channelId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.channelId}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup className="mb-3">
                          <label>Tactic Name</label>
                          <Input
                            name="TacticName"
                            placeholder="tactic name"
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            onChange={(e) => {
                              validation.handleChange(e)
                              dispatch(handleChangesHappend(true))

                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.TacticName || ""}
                            invalid={
                              validation.touched.TacticName &&
                                validation.errors.TacticName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.TacticName &&
                            validation.errors.TacticName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.TacticName}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup className="mb-3">
                          <label>Interrogation</label>
                          <Input
                            name="Interrogation"
                            placeholder="interrogation"
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            onChange={(e) => {
                              validation.handleChange(e)
                              dispatch(handleChangesHappend(true))

                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.Interrogation || ""}
                            invalid={
                              validation.touched.Interrogation &&
                                validation.errors.Interrogation
                                ? true
                                : false
                            }
                          />
                          {validation.touched.Interrogation &&
                            validation.errors.Interrogation ? (
                            <FormFeedback type="invalid">
                              {validation.errors.Interrogation}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    
                    {/* {showPreview && ( */}
                            
                            
                                 
                                  <TacticPreview
                                 TACTIC_NAME = {validation.values.TacticName || "Tactic name"}
                                />
                                 

                                  
                                  
                               
                                
                           
                         
                      {/* )} */}


                    
                   
                  
                  
                   
                   

                    <Row>
                    
                     
                     
                   <Col md="6"></Col>
                   <Col md="2"></Col>
                   {/* <Col md = "2">
                   <FormGroup className="mb-3">
                   <Button
                                block
                                color="primary"
                               
                                onClick={() => {
                                  setShowPreview(e => !e)
                                }}
                              >
                                {showPreview ? "Back" : "Preview"}
                              </Button>
                        </FormGroup>

                   </Col> */}
                      <Col md="2">
                        <FormGroup className="mb-3">
                          <Button
                            color="danger"
                            onClick={e => {
                              validation.resetForm()
                            }}
                            block
                          >
                            Cancel
                          </Button>
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className="mb-3">
                          <Button
                            color="primary"
                            type="submit"
                            disabled={isTacticAdded ? true : false}
                            block
                          >
                            {isTacticAdded ? "" : "Create"}
                            {isTacticAdded ? (
                              <Spinner type="border" size="sm" />
                            ) : null}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default CreateTactic
