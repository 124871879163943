import { color, fontSize } from "@mui/system"
import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Grid } from "@mui/material"
import img from "../../../../assets/images/reportScorcard/executiveCardimg.PNG"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useSelector, useDispatch } from "react-redux"
import ReactHtmlParser from "react-html-parser"
import SweetAlert from "react-bootstrap-sweetalert"
import { useParams } from "react-router-dom"

import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
  Modal,
  Tooltip,
} from "reactstrap"
import { Opacity } from "@mui/icons-material"
import Footer from "components/VerticalLayout/Footer"
import { executiveSummary } from "../ExecutiveSummary/data"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"

import "../config/style.config.css"
import { REPORT, REPORT_PAGE } from "../config/style.config"
import CropEasy from "pages/Crop/CropEasy"
import {
  getReportTemplate,
  UpdateScorecardReport,
  getScorecardReport,
  ClearUpdateScorecardReport,
  handleUnsavedChanges,
  handleChangesHappend,
  updateGetScorecardReport,
  updateScorecardDetail,
  getAllInstructionData,
} from "store/actions"
import { ACCOUNT_USER } from "config/AlertMessage.config"
import { CLOUD_STORAGE } from "config/cloudStorage.config"
import updateSuccessIcon from "../../../../assets/images/NewPopupIcons/Scorecard created.png"
import updateFailedIcon from "../../../../assets/images/NewPopupIcons/FailedIcon.png"

const KeyTakeawaysPage = () => {
  const [saveKeyTakeaways, setsaveKeyTakeaways] = useState(null)
  const [image, setImage] = useState({ preview: "", raw: "" })
  const [keyData, setkeyData] = useState([])
  const [isApplied, setIsApplied] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [KeyTakeaways, setKeyTakeaways] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isImageThere, setImageThere] = useState(false)

  const [HeightWidth, setHeightWidth] = useState({})
  const [openCrop, setOpenCrop] = useState(false)

  const [file, setFile] = useState(null)
  const [image_url, setImage_Url] = useState(null)
  const [iconThumbnail, setIconThumbnail] = useState(null)

  const [CKdata, setCKData] = useState()
  const [isSave, setSave] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [KeyTakeAwaysUpdate, setKeyTakeAwaysUpdate] = useState([])
  const [IsImage, setIsImage] = useState(false)

  // state for tooltip functionality
  const [tooltip, setTooltip] = useState(false)

  const dispatch = useDispatch()
  const params = useParams()

  //Fetched Tooltip Data
  const [tooltipForSaveBtn, setTooltipForSaveBtn] = useState(false)
  const [tooltipForAddPageBtn, setTooltipForAddPageBtn] = useState(false)
  const [tooltipForInsertBtn, setTooltipForInsertBtn] = useState(false)

  const [tooltipForResetPageBtn, setTooltipForResetPageBtn] = useState(false)
  const [tooltipForImageUpload, setTooltipForImageUpload] = useState(false)
  const [tooltipForRemovePage, setTooltipForRemovePage] = useState(false)
  const [tooltipForEditBtn, setTooltipForEditBtn] = useState(false)
  const [tooltipData, setTooltipData] = useState({})

  const {
    getReport,
    scorecardReportUpdate,
    scorecardReport_data,
    isDataEdited,
    isThereChanges,
    getInstructionData,
  } = useSelector(state => ({
    getReport: state.createReportReducer.getReport,
    scorecardReportUpdate: state.ScorecardReport_Reducer.scorecardReportUpdate,
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
    isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    getInstructionData: state.instructionPanelReducer.getInstructionData,
  }))

  useEffect(() => {
    dispatch(getReportTemplate())
    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
    // dispatch(getScorecardReport("UufUpT9yB9k5x7nMSwh7"))
  }, [dispatch])

  useEffect(() => {
    if (scorecardReport_data && scorecardReport_data.keyTakeaways) {
      var data = scorecardReport_data.keyTakeaways

      //console.log(data)
      setsaveKeyTakeaways(data.contents && data.contents)
      setkeyData(data.contents && data.contents ? data.contents : [])
      setKeyTakeaways(data.contents && data.contents)
      setImage_Url(data.bgImage)
    }
  }, [scorecardReport_data])

  // console.log(keyData)

  const [test, setTest] = useState(null)

  const [modal_center, setmodal_center] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)

  useEffect(() => {
    if (IsImage) {
      setIsModalOpen(false)
      setImageThere(false)
      setIsImage(false)
    }
  }, [image_url])

  useEffect(() => {
    if (!Object.keys(getInstructionData).includes("infoForAccountUser")) {
      dispatch(getAllInstructionData())
    }
  }, [dispatch])

  useEffect(() => {
    if (getInstructionData.infoForAccountUser !== undefined) {
      var data = {
        infoForInsertBtn: getInstructionData.infoForAccountUser.Key_Takeaways
          .infoForInsertBtn
          ? getInstructionData.infoForAccountUser.Key_Takeaways.infoForInsertBtn
          : "",
        infoForEditBtn: getInstructionData.infoForAccountUser.Key_Takeaways
          .infoForEditBtn
          ? getInstructionData.infoForAccountUser.Key_Takeaways.infoForEditBtn
          : "",
        infoForUploadImageBtn: getInstructionData.infoForAccountUser
          .Key_Takeaways.infoForUploadImageBtn
          ? getInstructionData.infoForAccountUser.Key_Takeaways
              .infoForUploadImageBtn
          : "",
        infoForResetPageBtn: getInstructionData.infoForAccountUser.Key_Takeaways
          .infoForResetPageBtn
          ? getInstructionData.infoForAccountUser.Key_Takeaways
              .infoForResetPageBtn
          : "",
        infoForSaveBtn: getInstructionData.infoForAccountUser.Key_Takeaways
          .infoForSaveBtn
          ? getInstructionData.infoForAccountUser.Key_Takeaways.infoForSaveBtn
          : "",
      }
      setTooltipData({ ...data })
    }
  }, [getInstructionData])

  const handleChange = e => {
    const file = e.target.files[0]
    if (file) {
      var u = URL.createObjectURL(file)
      var img = new Image()
      img.onload = function () {
        var IMAGE_HEIGHT = img.height
        var IMAGE_WIDTH = img.width

        // console.log(
        //   "Image Width: " +
        //     " " +
        //     IMAGE_WIDTH +
        //     " " +
        //     "Image Height : " +
        //     IMAGE_HEIGHT
        // )

        setHeightWidth({
          myheight: IMAGE_HEIGHT,
          mywidth: IMAGE_WIDTH,
        })
      }
      img.src = u
      setFile(file)
      setImage_Url(u)
      setOpenCrop(true)
      setTimeout(() => setIsImage(true), 2000)
      dispatch(handleChangesHappend(true))
      e.target.value = ""
    }
    // console.log(file)
  }

  const handleUpload = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("image", image.raw)

    await fetch("YOUR_URL", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    })
  }

  const listToPara = e => {
    var temp = ""
    // setIsApplied(true)
    keyData.forEach(data => {
      temp += data
    })
    var keyTakeawaysContent = document.querySelectorAll(".keyTakeawaysContent")
    keyTakeawaysContent.forEach(checkBox => {
      checkBox.checked = false
    })
    setKeyTakeaways(temp)
    dispatch(handleChangesHappend(true))
  }

  // var removeEditor = () => {
  //   setTest(" ")
  // }

  // useEffect(() => {
  //   listToPara()
  // }, [keyData])

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
    handleChange(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  //   console.log(image_url)

  function handleAddRowNested() {
    if (rows1.length < 10) {
      const modifiedRows = [...rows1]
      modifiedRows.push({ id: modifiedRows.length + 1 })
      setrows1(modifiedRows)
    }
  }

  const handleSave = () => {
    setIsSaved(true)
    if (saveKeyTakeaways) {
      setsaveKeyTakeaways(null)
      setIsSaved(false)
    } else {
      onSave(KeyTakeaways)
    }
  }

  const onSave = KeyTakeaways => {
    setKeyTakeAwaysUpdate({
      contents: KeyTakeaways,
      bgImage: image_url,
      lastUpdatedOn: {
        _miliseconds: 0,
        _seconds: new Date().getTime() / 1000,
      },
    })
    dispatch(
      updateScorecardDetail(params.scorecardId, {
        lastUpdatedOn: {
          _miliseconds: 0,
          _seconds: new Date().getTime() / 1000,
        },
      })
    )

    dispatch(
      UpdateScorecardReport(params.scorecardId, {
        keyTakeaways: {
          contents: KeyTakeaways,
          bgImage: image_url,
          lastUpdatedOn: {
            _miliseconds: 0,
            _seconds: new Date().getTime() / 1000,
          },
        },
      })
    )
    dispatch(handleChangesHappend(false))
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Mambo -Report</title>
        </MetaTags>
        {/* {
          scorecardReportUpdate.status === 200 &&
          <SweetAlert
            style={{
              position: "absolute",
              top: 170,
              height: "255px", width: "368px"
            }}
            // title="Key Takeaways saved successfully!"
            success
            confirmBtnBsStyle="primary"

            onConfirm={() => {
              dispatch(updateGetScorecardReport({
                ...scorecardReport_data,
                keyTakeaways: KeyTakeAwaysUpdate
              }))

              setIsSaved(false)

              setsaveKeyTakeaways(KeyTakeaways || " ")

              dispatch(ClearUpdateScorecardReport())
            }
            }
          >{ACCOUNT_USER.EDIT_REPORT.SUCCESS}
          </SweetAlert>
        } */}

        {scorecardReportUpdate.status === 200 && (
          <SweetAlert
            style={{ height: "270px", width: "270px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img
                    src={updateSuccessIcon}
                    style={{
                      height: "80px",
                      width: "85px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid mb-2"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "25px",
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.SUCCESS}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                <Button
                  block
                  size="sm"
                  className="mt-2"
                  style={{
                    background: "#0D0F11",
                    border: "#0D0F11",
                  }}
                  onClick={() => {
                    dispatch(
                      updateGetScorecardReport({
                        ...scorecardReport_data,
                        keyTakeaways: KeyTakeAwaysUpdate,
                      })
                    )

                    setIsSaved(false)

                    setsaveKeyTakeaways(KeyTakeaways || " ")
                    setTooltipForSaveBtn(false)
                    setTooltipForInsertBtn(false)
                    setTooltipForResetPageBtn(false)
                    setTooltipForImageUpload(false)
                    setTooltipForEditBtn(false)

                    dispatch(ClearUpdateScorecardReport())
                  }}
                >
                  Close
                </Button>
              </div>
            }
          ></SweetAlert>
        )}

        {/* {
          scorecardReportUpdate.status === 403 &&

          <SweetAlert
            // title="Failed to save Key Takeaways!"
            style={{
              position: "absolute",
              top: 170,
              height: "255px", width: "368px"
            }}
            error
            confirmBtnBsStyle="primary"

            onConfirm={() => {

              setIsSaved(false)
              dispatch(ClearUpdateScorecardReport())

            }}
          >{ACCOUNT_USER.EDIT_REPORT.FAILED}
          </SweetAlert>

        } */}

        {scorecardReportUpdate.status === 403 && (
          <SweetAlert
            style={{ height: "270px", width: "270px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img
                    src={updateFailedIcon}
                    style={{
                      height: "80px",
                      width: "80px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "25px",
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.FAILED}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                <Button
                  block
                  size="sm"
                  className=""
                  style={{
                    background: "#0D0F11",
                    border: "#0D0F11",
                  }}
                  onClick={() => {
                    setIsSaved(false)
                    setTooltipForSaveBtn(false)
                    setTooltipForInsertBtn(false)
                    setTooltipForResetPageBtn(false)
                    setTooltipForImageUpload(false)
                    setTooltipForEditBtn(false)
                    dispatch(ClearUpdateScorecardReport())
                  }}
                >
                  Close
                </Button>
              </div>
            }
          ></SweetAlert>
        )}

        <Container
          fluid={true}
          style={{
            transform: "scale(.8) translate(-130px, -132px) ",
            width: "1400px",
          }}
        >
          <Row>
            <Col className="col-12 m-auto ">
              <Card>
                <CardBody>
                  <Row>
                    <Row>
                      <Col md="3" className="mb-2">
                        <Button
                          className="btn btn-primary"
                          id="TooltipReset"
                          color="primary"
                          onClick={() => {
                            setsaveKeyTakeaways(null)
                            setKeyTakeaways(null)
                            setkeyData([])
                            dispatch(handleChangesHappend(true))
                          }}
                        >
                          Reset Page
                        </Button>
                        <Tooltip
                          style={{
                            backgroundColor: "#FEF3C7",
                            color: "#0D0F11",
                            zIndex: 1,
                          }}
                          placement="right"
                          isOpen={tooltipForResetPageBtn}
                          target="TooltipReset"
                          toggle={() => {
                            setTooltipForResetPageBtn(!tooltipForResetPageBtn)
                          }}
                        >
                          {tooltipData.infoForResetPageBtn}
                        </Tooltip>
                      </Col>
                      <Col md="9"></Col>
                    </Row>
                    <Col md="8">
                      <div style={{ ...REPORT_PAGE }}>
                        <Row className="g-0">
                          <Modal
                            isOpen={isModalOpen}
                            onRequestClose={() => setIsModalOpen(false)}
                            style={{ marginTop: "250px", width: "650px" }}
                          >
                            {!isImageThere ? (
                              <>
                                {/* <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // width:'100%',
                                    marginLeft: "5px",
                                  }}
                                  >
                                    <h5 style={{ margin: '15px 0 10px 140px' }}>Upload background image</h5>
                                    <i className="bx bx-x" style={{ fontSize: '25px', cursor: 'pointer', width: '40px', margin: '14px 0 10px 80px' }}
                                      onClick={() => setIsModalOpen(false)}></i>

                                  </div>

                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      var inputFile = document.querySelector("#upload-button")
                                      inputFile.click()
                                    }}
                                    style={{

                                      alignItems: "center",
                                      margin: "0 0 20px 180px",
                                      width: '150px'
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-image"
                                      style={{ marginRight: "5px", fontSize: "15px" }}
                                    ></i>
                                    Choose Image
                                  </Button> */}

                                <Row className="modal-body">
                                  <Col
                                    md="12"
                                    className="text-center font-size-18 mb-3"
                                    style={{
                                      color: "#0D0F11",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Upload New Image
                                    <button
                                      style={{
                                        marginTop: "-10px",
                                        marginLeft: "-20px",
                                      }}
                                      type="button"
                                      onClick={() => {
                                        setIsModalOpen(false)
                                      }}
                                      className="close text-center font-size-14"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </Col>
                                  <Col md="4"></Col>
                                  <Col md="4">
                                    <Button
                                      size="sm"
                                      block
                                      onClick={() => {
                                        var inputFile =
                                          document.querySelector(
                                            "#upload-button"
                                          )
                                        inputFile.click()
                                      }}
                                      style={{
                                        background: "#0D0F11",
                                        border: "#0D0F11",
                                      }}
                                    >
                                      <div className="d-flex justify-content-center">
                                        <i className="mdi mdi-image fw-bold mx-2 font-size-14"></i>
                                        <span className="font-size-14">
                                          Choose Image
                                        </span>
                                      </div>
                                    </Button>
                                  </Col>
                                  <Col md="4"></Col>
                                </Row>

                                <div
                                  style={{
                                    justifyContent: "center",
                                    margin: "auto 10px auto 10px",
                                  }}
                                >
                                  {openCrop && (
                                    <CropEasy
                                      image_url={image_url}
                                      imageName={file.name}
                                      image_storagePath={
                                        CLOUD_STORAGE.EDIT_SCORECARD_REPORT
                                          .KEY_TAKEAWAYS.IMAGE
                                      }
                                      setOpenCrop={setOpenCrop}
                                      setImageUrl={setImage_Url}
                                      setFile={setFile}
                                      setImageThere={setImageThere}
                                      ImageHeightWidth={HeightWidth}
                                      ImageType="KEY_TAKEAWAYS"
                                    />
                                  )}
                                </div>
                              </>
                            ) : (
                              // <Col md="8" style={{
                              //   textAlign: 'center',
                              //   margin: '40px 0 40px 90px'
                              // }}>
                              //   <Spinner />
                              //   <br />
                              //   <br />
                              //   <p
                              //     style={{
                              //       color: '#0D0F11',
                              //       fontSize: REPORT.FONT_SIZE.LARGE
                              //     }}
                              //   >
                              //     Please wait.  Image is being uploaded.</p>
                              // </Col>
                              <Row className="modal-body text-center">
                                <Col
                                  md="12"
                                  className="my-3 fw-bold font-size-18"
                                >
                                  <p
                                    style={{
                                      color: "#0D0F11",
                                    }}
                                  >
                                    Please wait. Image is being uploaded.
                                  </p>
                                </Col>
                                <Col md="12">
                                  <Spinner style={{ color: "#0D0F11" }} />
                                </Col>
                              </Row>
                            )}
                          </Modal>

                          <Col md="7">
                            <div
                              style={{
                                width: "100%",
                                height: REPORT_PAGE.height,
                                overflow: "hidden",
                                // border: "1px solid grey"
                              }}
                            >
                              <Row>
                                <Col md="12">
                                  <div
                                    style={{
                                      // margin: "auto",
                                      width: "100%",
                                      height: "100px",
                                      // padding: "30px 0 25px 8px",
                                      padding: "30px 40px 0 40px",
                                      position: "relative",
                                      textTransform: "uppercase",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontSize: REPORT.FONT_SIZE.XLARGE,
                                        fontFamily: REPORT.FONT_FACE.HEADING,
                                        fontWeight: 600,
                                        color: "#0D0F11",
                                        letterSpacing: "2px",
                                        paddingBottom: "10px",
                                        // paddingBottom: "8px",
                                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                                      }}
                                    >
                                      KEY{" "}
                                      <span
                                        style={{
                                          fontFamily: REPORT.FONT_FACE.HEADING,
                                        }}
                                      >
                                        <br />
                                        TAKEAWAYS
                                      </span>
                                    </h2>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 10,
                                        padding: 6,
                                        width: "80px",
                                      }}
                                    >
                                      {!saveKeyTakeaways ? (
                                        <>
                                          <Button
                                            type="submit"
                                            color="primary"
                                            size="sm"
                                            id="TooltipSave"
                                            block
                                            onClick={handleSave}
                                            disabled={isSaved}
                                          >
                                            {isSaved ? "" : "Save"}
                                            {isSaved ? (
                                              <Spinner
                                                type="border"
                                                size="sm"
                                              />
                                            ) : null}
                                          </Button>
                                          <Tooltip
                                            style={{
                                              backgroundColor: "#FEF3C7",
                                              color: "#0D0F11",
                                              zIndex: 1,
                                            }}
                                            placement="right"
                                            isOpen={tooltipForSaveBtn}
                                            target="TooltipSave"
                                            toggle={() => {
                                              setTooltipForSaveBtn(
                                                !tooltipForSaveBtn
                                              )
                                            }}
                                          >
                                            {tooltipData.infoForSaveBtn}
                                          </Tooltip>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            color="primary"
                                            id="TooltipEdit"
                                            size="sm"
                                            block
                                            onClick={handleSave}
                                          >
                                            Edit
                                          </Button>
                                          <Tooltip
                                            style={{
                                              backgroundColor: "#FEF3C7",
                                              color: "#0D0F11",
                                              zIndex: 1,
                                            }}
                                            placement="left"
                                            isOpen={tooltipForEditBtn}
                                            target="TooltipEdit"
                                            toggle={() => {
                                              setTooltipForEditBtn(
                                                !tooltipForEditBtn
                                              )
                                            }}
                                          >
                                            {tooltipData.infoForEditBtn}
                                          </Tooltip>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "920px",
                                      marginTop: "-5px",
                                      // border: "1px solid grey",
                                      overflow: "hidden",
                                      // padding: "4px",
                                      padding: "0 25px 0 40px",
                                      lineHeight: "1.15",
                                      fontSize: REPORT.FONT_SIZE.XSMALL,
                                      color: "#0D0F11",
                                      // fontFamily: REPORT.FONT_FACE.HEADING,
                                    }}
                                    className="--key-takeaways --container"
                                  >
                                    {!saveKeyTakeaways ? (
                                      <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                          removePlugins: [
                                            "EasyImage",
                                            "ImageUpload",
                                            "MediaEmbed",
                                            "Essentials",
                                            "Table",
                                            "Table Toolbar",
                                            "CkFinderUploadAdapter",
                                            "CKFinder",
                                            "Autoformat",
                                            "Link",
                                            "BlockQuote",
                                          ],
                                          uiColor: "#9AB8F3",
                                        }}
                                        data={KeyTakeaways ? KeyTakeaways : ""}
                                        onReady={editor => {
                                          // listToPara()
                                          // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(e, data) => {
                                          setKeyTakeaways(data.getData())
                                          setkeyData(data.getData())
                                          dispatch(handleChangesHappend(true))
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          // width: "96%",
                                          // paddingBottom: "10px",
                                          // border: "1px solid red",
                                          marginTop: "20px",
                                          width: "96%",
                                          padding: "4px",
                                          overflow: "hidden",
                                          height: "860px",
                                          fontSize: REPORT.FONT_SIZE.XSMALL,
                                          fontFamily:
                                            REPORT.FONT_FACE.PARAGRAPH,
                                          // margin:'20px auto 4px 20px'
                                        }}
                                        className="SavedKeyTakeaways"
                                      >
                                        {ReactHtmlParser(saveKeyTakeaways)}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col md="5">
                            <div
                              className="ImageUploadContainer"
                              style={{
                                width: "100%",
                                height: REPORT_PAGE.height,
                                // border: "1px solid grey",
                                backgroundImage:
                                  !openCrop &&
                                  image_url &&
                                  "url(" + image_url + ")",
                                backgroundColor: "#f0f0f0",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                              }}
                            >
                              <div
                                className="container--toggle ContainerAnimation--FadeIn "
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(0, 0, 0, .3)",
                                }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                    // border: "1px solid red",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    transition: "all 1s ease-in-out",
                                  }}
                                >
                                  <input
                                    type="file"
                                    id="upload-button"
                                    name="Image_URL"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={handleChange}
                                  />
                                  <Button
                                    size="sm"
                                    id="TooltipUploadImage"
                                    color="primary"
                                    style={{
                                      transform: "scale(1)",
                                      width: "110px",
                                      height: "28px",
                                    }}
                                    onClick={() => {
                                      setIsModalOpen(true)
                                      setsaveKeyTakeaways(null)
                                    }}
                                  >
                                    Upload Image
                                  </Button>
                                  <Tooltip
                                    style={{
                                      backgroundColor: "#FEF3C7",
                                      color: "#0D0F11",
                                      zIndex: 1,
                                    }}
                                    placement="right"
                                    isOpen={tooltipForImageUpload}
                                    target="TooltipUploadImage"
                                    toggle={() => {
                                      setTooltipForImageUpload(
                                        !tooltipForImageUpload
                                      )
                                    }}
                                  >
                                    {tooltipData.infoForUploadImageBtn}
                                  </Tooltip>
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col
                            md="12"
                            style={{
                              // border: '1px solid black'
                              position: "absolute",
                              bottom: 0,
                              zIndex: 100,
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                backgroundColor: REPORT.COLOR.FOOTER,
                                opacity: 0.9,
                                fontWeight: 600,
                                letterSpacing: "2px",
                                fontFamily: REPORT.FONT_FACE.HEADING,
                                padding: "5px 5px 5px 40px",
                                color: "white",
                                fontSize: REPORT.FONT_SIZE.MEDIUM,
                              }}
                            >
                              KEY TAKEAWAYS
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    {/* Select list */}

                    {!saveKeyTakeaways && (
                      <Col md="4">
                        <div
                          style={{
                            width: "100%",
                            height: REPORT_PAGE.height,
                            // border: "1px solid grey",
                            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, .1)",
                          }}
                        >
                          <Row>
                            <Col md="12">
                              <div
                                style={{
                                  width: "100%",
                                  height: "36px",
                                  padding: "4px",
                                  // border: "1px solid grey",
                                  boxShadow:
                                    "0px 4px 10px -2px rgba(0, 0, 0, .1)",
                                }}
                              >
                                <Row className="g-0">
                                  <Col md="8"></Col>
                                  <Col md="4">
                                    <Button
                                      size="sm"
                                      id = "TooltipInsert"
                                      color="primary"
                                      block
                                      onClick={listToPara}
                                      // disabled={isApplied}
                                    >
                                      Insert Selected Content
                                    </Button>
                                    <Tooltip
                                      style={{
                                        backgroundColor: "#FEF3C7",
                                        color: "#0D0F11",
                                        zIndex: 1,
                                      }}
                                      placement="left"
                                      isOpen={tooltipForInsertBtn}
                                      target="TooltipInsert"
                                      toggle={() => {
                                        setTooltipForInsertBtn(!tooltipForInsertBtn)
                                      }}
                                    >
                                      {tooltipData.infoForInsertBtn}
                                    </Tooltip>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col md="12">
                              <div>
                                <ul
                                  style={{
                                    height: "1020px",
                                    fontSize: REPORT.FONT_SIZE.XSMALL,
                                    padding: "10px",
                                    overflow: "auto",
                                    listStyleType: "none",
                                  }}
                                >
                                  {getReport.keyTakeaways &&
                                    getReport.keyTakeaways.contents.map(
                                      (data, i) => (
                                        <li
                                          key={i}
                                          style={{
                                            marginBottom: "12px",
                                          }}
                                        >
                                          <Input
                                            // disabled={isApplied}
                                            className="keyTakeawaysContent"
                                            type="checkbox"
                                            value={data}
                                            style={{
                                              width: "17px",
                                              height: "16px",
                                              margin: "-1px 0 5px 0",
                                              textAlign: "left",
                                              color: "#0D0F11",
                                            }}
                                            onChange={e => {
                                              e.target.checked &&
                                                setkeyData(x => [
                                                  ...x,
                                                  e.target.value,
                                                ])
                                            }}
                                          />
                                          &nbsp;&nbsp;
                                          {
                                            // data.split("<p>")[1] ?

                                            //   data.split("<p>")[1].split("</p>")
                                            //   :
                                            //   data.split("<ul><li>")[1].split("</li></ul>")[0]
                                            data.replace(/<\/?[^>]+(>|$)/g, "")
                                          }
                                        </li>
                                      )
                                    )}
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default KeyTakeawaysPage
