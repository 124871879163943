// import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap"
import MetaTags from 'react-meta-tags'
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getScorecardsList, ScorecardRoutingHandler } from "store/actions";
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useHistory } from "react-router-dom";
import { ACCOUNT_OWNER, NETWORK_ERROR } from "config/AlertMessage.config";
import NotFound from "components/Common/NotFound/NotFound.component";
import { withRouter, Link } from "react-router-dom"
// CSS Import
import "./ViewScorecards.style.css"
// import "../../../config/customPagination.css"

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"
// import "./datatables.scss"


const AccountHolderScorecardList = () => {
  const [isDataAvail, setIsDataAvail] = useState(true)
  const [paginationReset, setPaginationReset] = useState(false)
  const [accountOwnerType, setAccountOwnerType] = useState(null)

  const history = useHistory();
  const dispatch = useDispatch();
  const { scorecardsList, scorecardDetail, accountDetails } = useSelector(state => ({
    scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
    scorecardsList: state.GetScorecardsListReducer.scorecardsList,
    accountDetails: state.SuperAdmin.accountDetails,
  }));

  useEffect(() => {
    dispatch(getScorecardsList(JSON.parse(localStorage.getItem("authUser")).uid));
  }, [dispatch]);

  useEffect(() => {
    if (scorecardsList.status === 404) {
      setIsDataAvail(false)
    }
    if (scorecardsList[0]) {
      var search_bar = document.querySelector("#search-bar-0")
      search_bar.setAttribute("autoComplete", "off")
      search_bar.addEventListener("focus", () => setPaginationReset(true))
    }
  }, [scorecardsList])

  // useEffect to find out the permission of scorecard type of a account owner
  useEffect(() => {
    if (accountDetails && accountDetails.scorecardVersions) {
      const { scorecardVersions } = accountDetails;

      if (scorecardVersions.proScorecard === true && scorecardVersions.selfReportScorecard === true) {
        setAccountOwnerType("Both")
      } else if (scorecardVersions.proScorecard === true && scorecardVersions.selfReportScorecard === false) {
        setAccountOwnerType("Pro")
      } else if (scorecardVersions.proScorecard === false && scorecardVersions.selfReportScorecard === true) {
        setAccountOwnerType("Self Report")
      }
    } else {
      setAccountOwnerType("Old")
    }
  }, [accountDetails])

  const columns = [{
    dataField: 'companyName',
    text: 'Company Name',
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <Link to="#" style={{ color: '#05727B', fontSize: '17px', fontWeight: 'bold' }}>
        {row.companyName}
      </Link>
    ),
  },
  {
    dataField: 'scorecardName',
    text: 'Scorecard Name',
    sort: true
  },
  {
    dataField: 'createdOn',
    text: 'Created On',
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <Link to="#" style={{ color: '#495057', textAlign: "center", width: "140px" }}>
        {row.createdOn}
      </Link>
    ),

  },
  {
    dataField: 'lastUpdatedOn',
    text: 'Last Updated On',
    sort: true,
  }, {
    dataField: "status",
    text: "Status"
  },
  {
    dataField: 'scorecardVersion',
    text: 'Scorecard Type',
    // sort: true,
  },
  {
    dataField: 'viewCard',
    text: '',
    sort: false,
  }

  ];

  const UserName = (name) => {
    return (
      <div style={{
        width: "100%",
        textAlign: "left",
        color: "#05727B",
        fontSize: "16px"

      }}>
        {name}

      </div>
    )
  }
  // Table Data
  //   const productData = [
  //     {
  //       "id": 1,
  //       "accounts": "pratik",
  //       "email": "pratik@example.com",
  //       "credits": 10
  //     }];

  const productData = !scorecardsList[0] ? [] : scorecardsList.map((user, i) => {
    return {
      id: ++i,
      companyName: user.companyName ? user.companyName : "---",
      scorecardName: user.scorecardName,
      createdOn: new Date(user.createdOn._seconds * 1000)
        .toDateString()
        .slice(3),
      lastUpdatedOn: new Date(user.lastUpdatedOn._seconds * 1000)
        .toDateString()
        .slice(3),
      status: user.status,
      scorecardVersion: user.scorecardVersion,
      viewCard: (
        <button
          className="view-card-btn-for-all-scorecard-list"
          onClick={() => {
            dispatch(ScorecardRoutingHandler(document.location.pathname)),
              user.status === "Not started"
                ? history.push("/view/scorecard/" + user.id)
                : history.push("/viewScoringSummary/" + user.id)
          }}
        >
          View Card
        </button>
      ),
    }
  })

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (productData).length }];

  const pageOptions = {
    // page: 1,
    // sizePerPage: 10,
    onPageChange: () => setPaginationReset(false),
    // totalSize: productData.length, // replace later with size(customers),
    custom: true,
    sizePerPageList,
  }

  // Custom Pagination Toggle

  const selectRow = {
    mode: 'checkbox'
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      dispatch(
        ScorecardRoutingHandler(document.location.pathname)
      )
      history.push("/view/scorecard/" + row.actions)
    }
  }

  // console.log("scorecardsList...", scorecardsList)
  // Select All Button operation
  //   const selectRow = {
  //     mode: 'checkbox'
  //   }

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - All Scorecards</title>
        </MetaTags>
        <div className="container-fluid">
          {
            !scorecardsList[0] ?
              <div style={
                {
                  width: "100%",
                  textAlign: "center",
                  height: "400px"
                }
              } >
                {
                  !isDataAvail ?
                    <>
                      {scorecardsList.status === 404 ?
                        <NotFound Message={ACCOUNT_OWNER.EMPTY_SCORECARDS} Icon={() => (
                          <i className="mdi mdi-book-edit"
                            style={{
                              borderBottom: "1px solid #000"
                            }}
                          ></i>
                        )}
                          IsButton
                          Url={
                            accountOwnerType && accountOwnerType === "Pro" ?
                              "/create/scorecard" :
                              accountOwnerType && accountOwnerType === "Self Report" ?
                                "/create/self-report/scorecard" :
                                accountOwnerType && accountOwnerType === "Both" ?
                                  "/create/scorecard" :
                                  "/create/scorecard"
                          }
                          btnText="Create New Scorecard"
                        />
                        :
                        <NotFound Message={NETWORK_ERROR} Icon={() => (
                          <i className="bx bx-error"
                            style={{ borderBottom: "1px solid #000", fontSize: "40px" }}
                          ></i>
                        )}
                          IsButton
                          btnText="Reload"
                          Reload
                        />
                      }
                    </>
                    :
                    <Spinner
                      style={{
                        width: "60px", height: "60px",
                        margin: "calc(200px - 30px)",
                        color: '#05727B'
                      }}
                      role="grid"
                      type="border"
                    // color="primary"
                    />
                }
              </div>
              :
              <>
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        {/* <CardTitle className="h4">Scorecards</CardTitle> */}
                        {/* <p className="card-title-desc">
                    react-bootstrap-table-next plugin has most features enabled by default,
                    so all you need to do to use it with your own tables is to
                    call the construction function:{" "}
                    <code>react-bootstrap-table-next </code>.
                  </p> */}
                        <PaginationProvider
                          pagination={
                            paginationReset ?
                              paginationFactory({
                                ...pageOptions,
                                page: 1
                              }
                              ) :
                              paginationFactory(pageOptions)
                          }
                          keyField='id'
                          columns={columns}
                          data={productData}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField='id'
                              columns={columns}
                              data={productData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>

                                  <Row className="mb-2">
                                    <Col md="9">
                                      <h2 className="fw-bold" style={{ color: "#0D0F11" }}>
                                        All Scorecards
                                      </h2>
                                    </Col>
                                    <Col md="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          // rowEvents={rowEvents}
                                          keyField={"id"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}

                                        />

                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )
                              }
                            </ToolkitProvider>
                          )
                          }</PaginationProvider>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default AccountHolderScorecardList
