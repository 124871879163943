import { call, put, takeEvery } from "redux-saga/effects"

import {
    AddTacticSuccess,
    AddTacticFail,
    getTacticInfoSuccess,
    getTacticInfoFail
} from "./actions"

import {
    ADD_NEW_TACTIC,
    GET_TACTIC_INFO,
} from "./actionTypes"

import {
    AddTactic,
    getTacticDetails,
} from "../../helpers/fakebackend_helper"


function* onAddNewTactic( {id, payload: data } ) {

    try{
        const response = yield call(AddTactic,id, data)
        yield put(AddTacticSuccess(response))
    }catch(error) {
        yield put(AddTacticFail(error))
    }

}

function* onGetTacticInfo({channelId,tacticId}) {

    try {
        const response = yield call(getTacticDetails,channelId,tacticId)
        
        yield put(getTacticInfoSuccess(response))
    } catch (error) {
        yield put(getTacticInfoFail(error))
    }

}

function* AddNewTacticSaga(){
    yield takeEvery(ADD_NEW_TACTIC, onAddNewTactic)
    yield takeEvery(GET_TACTIC_INFO, onGetTacticInfo)
}

export default AddNewTacticSaga