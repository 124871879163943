import React, { useEffect, useState } from "react"
import spyder from "../../../../../assets/images/reportScorcard/SpiderImageLevelsOfMaturity.png"
import {
  Row,
  Col,
} from "reactstrap"

import { REPORT_PAGE, REPORT, MATURITY } from "../config_v0_1/style.config"

const MaturityDefined = () => {
  return (
    <>
        <Col xs="12" className="print-content p-0 m-0 ">
          <div style={REPORT_PAGE}>
            <Row className="g-0 p-0 m-0" style={REPORT_PAGE}>
              <Col xs="12">
                <div
                  style={{
                    // textAlign: "center",
                    textAlign: "left",
                    // width: "90%",
                    width: "100%",
                    height: "80px",
                    // padding: "8px",
                    padding: "30px 40px 0 40px",
                    // marginLeft:'50px',
                    // marginTop:'50px'
                    marginBottom:'20px'
                  }}
                > 
                  <h2
                    style={{ 
                    fontWeight: 700,
                    letterSpacing:'2px', 
                    paddingBottom:'10px',
                    color:'#0D0F11',
                    fontSize: REPORT.FONT_SIZE.XLARGE, 
                    fontFamily: REPORT.FONT_FACE.HEADING,
                    textTransform: "uppercase", 
                    borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                    letterSpacing:'4px',
                  }}
                  >
                      LEVELS OF MATURITY DEFINED
                  </h2>
                </div>
              </Col>
              <Col xs="12">
                <div style={{ display: "flex", marginTop:'-30px' }}>
                  <div style={{width:'439px', height:'839.04px'}}>
                    <img
                      src={spyder}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                  <div style={{ width: "377px", padding: "30px 30px 30px 5px"}}>
                    <h4 style={MATURITY}>
                      100-80 Mature Modern Marketing{" "}
                    </h4>
                    <p>
                   
                      <span style={{ fontSize: "14px", lineHeight:'1.15', color: "#0D0F11" }}>
                        Sophisticated marketing strategy and
                        tactics. User needs and experience are the
                        primary drivers for all work. Team works in
                        a culture of testing and continuous
                        improvement. Staff are subject matter
                        experts in multiple digital disciplines or
                        have regular support from internal/vendor
                        resources
                      </span>
                    </p>
                    <br/>
                    <p>
                      <h4 style={MATURITY}>
                        60-79 Advanced Marketing
                      </h4>
                      <span style={{ fontSize: "14px", lineHeight:'1.15', color: "#0D0F11" }}>
                        Consistent Best Practice digital marketing
                        strategies and tactics. Uniform content
                        strategy is informed by clear user personas
                        and applied consistently. Regular
                        data-driven optimization lead by SMEs. Staff
                        have regular strategic and tactical training
                        and support
                      </span>
                    </p>
                    <br/>
                    <p>
                      <h4 style={MATURITY}>
                        40-59 Intermediate Marketing
                      </h4>
                      <span style={{ fontSize: "14px", lineHeight:'1.15', color: "#0D0F11" }}>
                        Transitioning to Best Practice strategies
                        and tactics. Base-line content strategy is
                        applied inconsistently. Staff have gaps in
                        skills and support
                      </span>
                    </p>
                    <br/>
                    <p>
                      {" "}
                      <h4 style={MATURITY}>
                        20-39 Beginning Marketing
                      </h4>
                      <span style={{ fontSize: "14px", lineHeight:'1.15', color: "#0D0F11" }}>
                        Marketing is defined by production and
                        throughput. Measurement is incomplete or
                        inconsistent. Strategy is in early
                        development stage. Staff need training in
                        digital marketing <br/>fundamentals
                      </span>
                    </p>
                    <br/>
                    <p>
                      {" "}
                      <h4 style={MATURITY}>0-19 Early Stage</h4>
                      <span style={{ fontSize: "14px", lineHeight:'1.15', color: "#0D0F11" }}>
                        Marketing teams and tactics are in place but
                        lack a consistent and strategic modern
                        application. There are notable gaps
                      </span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Col
              xs="12"
              style={{
                // border: '1px solid black'
                position: "absolute",
                bottom: 0,
                zIndex: 100,
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: REPORT.COLOR.FOOTER,
                  fontFamily:REPORT.FONT_SIZE.HEADING,
                  letterSpacing:'2px',
                  opacity: 0.9,
                  fontWeight: 600,
                  color: "white",
                  fontSize: REPORT.FONT_SIZE.MEDIUM,
                  padding: '5px 5px 5px 40px',
                }}
              >
                 LEVELS OF MATURITY DEFINED
              </div>
            </Col>
          </div>
        </Col>
    </>
  )
}

export default MaturityDefined
