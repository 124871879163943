import firebase from 'firebase/compat/app';
// import accessToken from "./jwt-token-access/accessToken"

// Add the Firebase products that you want to use
import "firebase/compat/auth";
export const generateFirebaseToken = () => {
    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            firebase.auth().currentUser.getIdToken()
                .then(token => {
                    localStorage.setItem("access_token", JSON.stringify({
                        access_token: token
                    }))
                })
        }
    })
}