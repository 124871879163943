import {
    GET_ACCOUNTUSER_DATA_SUCCESS,
    GET_ACCOUNTUSER_DATA_FAIL,
  } from "./actionTypes";
  
  const INIT_STATE = {
    AccountUserData: [],
  };
  
  const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ACCOUNTUSER_DATA_SUCCESS:
        return {
          ...state,
          AccountUserData: action.payload,
        };
  
      case GET_ACCOUNTUSER_DATA_FAIL:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default UserReducer;