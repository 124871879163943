import  {
    TRANSACTION , 
    TRANSACTION_SUCCESS,
    TRANSACTION_FAIL,
    CHANGE_TRANSACTION,
    CLEAR_TRANSACTION

} from "./actionTypes"

export const getTransaction = (id) => ({
    type:TRANSACTION,
    id

})

export const getTransactionSuccess = (data) => ({
    type:TRANSACTION_SUCCESS,
    payload:data

})

export const getTransactionFail = (error) => ({
    type:TRANSACTION_FAIL,
    payload:error
})

export const clearTransaction = () => ({
    type:CLEAR_TRANSACTION,

})

export const changeTransaction = (data) => ({
    type:CHANGE_TRANSACTION,
    payload:data
})



