import actions from "redux-form/lib/actions"
import { actionChannel } from "redux-saga/effects"
import {ARRANGE_CHANNEL_SEQUENCE_SUCCESS,
    ARRANGE_CHANNEL_SEQUENCE_FAIL,
    CLEAR_ARRANGE_CHANNEL_SEQUENCE} from "./actionTypes"

const INIT_STATE = {
   chnagedChannelData :[]

}

const ArrangeChannelSequenceReducer = (state = INIT_STATE , action) =>{
    switch(action.type){
        case ARRANGE_CHANNEL_SEQUENCE_SUCCESS : return{
            ...state,
            chnagedChannelData: action.payload

        }
        case ARRANGE_CHANNEL_SEQUENCE_FAIL: return{
            ...state,
            chnagedChannelData: action.payload

        }
        case CLEAR_ARRANGE_CHANNEL_SEQUENCE :return{
            ...state,
            chnagedChannelData:[]
        }
        default:{
            return state
        }

        

    }
    

}
export default ArrangeChannelSequenceReducer