// Network error message:
export const NETWORK_ERROR = {
    message: "No Internet Connection",
    subMessage: "Please check your connection and try again."
}


///Super Admin popup

export const SUPER_ADMIN = {
    CREATE_ACCOUNT_OWNER: {
        SUCCESS: "Changes updated successfully.",
        FAILED: "Failed to update changes."
    },
    CREATE_ACCOUNT: {
        SUCCESS: "Account created successfully.",
        FAILED: " Failed to create Account.",
        EXISTING_MAIL: "Email already exists."
    },
    CREATE_CONTENT_EDITOR: {
        SUCCESS: "Content Editor created successfully.",
        FAILED: "Failed to create Content Editor.",
        EXISTING_MAIL: "Email already exists."
    },
    UPDATE_CONTENT_EDITOR: {
        SUCCESS: "Changes updated successfully.",
        FAILED: "Failed to update changes.",
    },
    EMPTY_ACCOUNT_OWNERS: {
        message: "You haven't created any Accounts yet.",
        // subMessage: "Please, Create account owner."
    },
    EMPTY_CONTENT_EDITORS: {
        message: "You haven't created any Content Editors yet.",
        // subMessage: "Please, Create content editor."
    },
    EMPTY_PURCHASE_HISTORY: {
        message: "There is no purchase yet.",
        // subMessage: ""
    },
    EMPTY_MANAGE_PRICE: {
        // message: "Oops!",
        message: "Work in progress..."
    },
    EMPTY_COUPON: {
        message: " There is no coupon created yet.",
        // subMessage: "Please, Create coupon."
    },
    CONTENT_EDITOR_LIMIT_EXCEED: {
        message: "A maximum of 32 Content Editors is allowed. Disable any Content Editor to create new."
    }
}

///account owner

export const ACCOUNT_OWNER = {
    UPDATE_CLIENT_INFORMATION: {
        SUCCESS: "Changes updated successfully.",
        FAILED: "Failed to save data."
    },
    VIEW_USER_DETAILS: {
        SUCCESS: "Changes updated successfully.",
        FAILED: "Failed to update changes.",
    },
    CREATE_USER: {
        SUCCESS: "User created successfully.",
        FAILED: " Failed to create User.",
        CONFLICT: "Email already exists."

    },
    EMPTY_ACCOUNT_USERS: {
        message: "You haven't created any Users yet.",
        // subMessage: "Please, Create a user."
    },
    EMPTY_SCORECARDS: {
        message: "You haven't created any Scorecard yet.",
        // subMessage: "Please, Create a scorecard."
    },
    EMPTY_TRANSACTIONS: {
        message: "You haven't made any Transaction yet.",
        // subMessage: "Please, Make a transaction."
    },
    EMPTY_CREDITS: {
        message: "You don't have any Credit purchased yet.",
        subMessage: ""
    },
    USER_CREATE_LIMIT_EXCEED : {
        message_create: "A maximum of 64 active Users is allowed. Disable any User to create new.",
        message_update: "Cannot enable this user (Limit for active users is 64)."
    },
    ZERO_CREDITS:{
        message:"You don't have enough credit , you can't create scorecard"
    }
}

///Content Editor popup

export const CONTENT_EDITOR = {
    CREATE_CHANNEL: {
        SUCCESS: "Channel created successfully.",
        FAILED: "Failed to create Channel."
    },
    UPDATE_CHANNEL: {
        SUCCESS: "Channel updated successfully.",
        FAILED: "Failed to update changes."
    },
    CREATE_TATCTIC: {
        FAILED: "Failed to create Tactic."
    },
    UPDATE_TACTIC: {
        SUCCESS: "Tactic updated successfully.",
        FAILED: "Failed to update changes."
    },
    SCORECARD_REPORT_TEMPLATE: {
        SUCCESS: "Data saved successfully",
        FAILED: "Failed to saved data"
    },
    ARRANGE_CHANNEL_SEQUENCE:{
        message:"There is no any Channel created yet."

    },
    
    TACTIC_LIMIT_EXCEED: {
        message: "You cannot create more than 10 tactics under one channel, Disable one tactic to create new one"
    }
}

///edit report config

export const ACCOUNT_USER = {
    EDIT_REPORT: {
        SUCCESS: "Your page is saved.",
        FAILED: "Failed to save the page."
    },
    EMPTY_SCORECARDS: {
        message: "There are no Scorecards assigned yet.",
        // subMessage: "Please, Let your admin to create scorecard for you."
    },
    USER_WITHOUT_SCORECARD: {
        message: "Scorecards are not assigned to this User.",
        subMessage: "Please assign Scorecard for this User."
    }
}