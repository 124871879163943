import React from 'react'
import MetaTags from 'react-meta-tags';  // Added Meta Tag npm Package
import {
  Container, Row, Col,
  Alert,
  Card, CardBody, CardTitle, FormGroup, Spinner, Form, Input, Label, FormFeedback, Button, UncontrolledAlert
} from "reactstrap";
function FailedMessage() {
  return (
    <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>Mambo - Buy Credits</title>
      </MetaTags>
     
        <Card >
          <CardBody style = {{textAlign:'center'}}>
            <i className='fas fa-times-circle' style = {{fontSize:'32px' , color:'red'}}/>
            <CardTitle  >
              Your payment is cancelled
            </CardTitle>
          
         

          </CardBody>
        </Card>
     
        
       
    </div>


      
         
          </React.Fragment>


  
    
  )
}

export default FailedMessage
