import React from "react"
import { color, fontSize } from "@mui/system"
import { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Grid } from "@mui/material"
import img from "../../../../assets/images/reportScorcard/executiveCardimg.PNG"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// import {mainDivStyle} from './pageFifth.cssConfig'
import { REPORT_PAGE, REPORT, RECOMMENDATIONS } from "../config/style.config"
import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
  Tooltip,
} from "reactstrap"
import { NoEncryption, Opacity } from "@mui/icons-material"
import Footer from "components/VerticalLayout/Footer"
import {
  getScorecardReport,
  UpdateScorecardReport,
  ClearUpdateScorecardReport,
  handleUnsavedChanges,
  handleChangesHappend,
  getAllInstructionData,
} from "../../../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams, useHistory } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"

const ChildRecommendationPage = props => {
  const parameter = props
  const {
    AddPage,
    IsPageAdded,
    HandlePageAdded,
    Data_Length,
    Index,
    GetReport,
    RECOMMENDATION_DATA,
    SAVE_RECOMMENDATION,
    HANDLE_CHANGE_RECOMMENDATION,
    HandleSaveBtn,
    IS_SAVED,
    DATA_AVAIL,
    SCORECARD_REPORT_DATA,
    TacticRecommendation,
    SET_IS_SAVED,
    REPORT_UPDATE,
    SCORECARDREPORT_DATA,
  } = parameter

  const params = useParams()
  const dispatch = useDispatch()

  const [isPageAdded, setIsPageAdded] = useState(false)
  const [editTacticRecommendationData, setEditTacticRecommendationData] =
    useState([])

  const [editRecommendation, setEditRecommendation] = useState({})
  const [saveRecommendation, setSaveRecommendation] = useState(null)

  const [isRecommendtionEdit, setRecommendationEdit] = useState(true)

  const [textareabadge, settextareabadge] = useState(false)
  const [textcount, settextcount] = useState(0)
  // const [rowCount, setRowCount] = useState(null)

  // state for tooltip functionality
  const [tooltip, setTooltip] = useState(false)

  //Fetched Tooltip Data
  const [tooltipForSaveBtn, setTooltipForSaveBtn] = useState(false)
  const [tooltipForAddPageBtn, setTooltipForAddPageBtn] = useState(false)
  const [tooltipForInsertBtn, setTooltipForInsertBtn] = useState(false)

  const [tooltipForResetPageBtn, setTooltipForResetPageBtn] = useState(false)
  const [tooltipForImageUpload, setTooltipForImageUpload] = useState(false)
  const [tooltipForRemovePage, setTooltipForRemovePage] = useState(false)
  const [tooltipForEditBtn, setTooltipForEditBtn] = useState(false)
  const [tooltipData, setTooltipData] = useState({})

  const { isDataEdited, isThereChanges, getInstructionData } = useSelector(
    state => ({
      isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
      isRecommendtionEdit: state.ScorecardReport_Reducer.isThereChanges,
      getInstructionData: state.instructionPanelReducer.getInstructionData,
    })
  )

  useEffect(() => {
    if (RECOMMENDATION_DATA && RECOMMENDATION_DATA.tactics) {
      var Recommendation = RECOMMENDATION_DATA.tactics.map(tactic => tactic)

      setEditRecommendation(Recommendation)
      setSaveRecommendation(Recommendation)
      setEditTacticRecommendationData(Recommendation)
      // if(RECOMMENDATION_DATA.length / 5  > 2 && Index === 1){

      //   setIsPageAdded(true)
      // }
    }
  }, [DATA_AVAIL])

  useEffect(() => {
    if (!Object.keys(getInstructionData).includes("infoForAccountUser")) {
      dispatch(getAllInstructionData())
    }
  }, [dispatch])

  useEffect(() => {
    if (getInstructionData.infoForAccountUser !== undefined) {
      var data = {
        infoForEditBtn: getInstructionData.infoForAccountUser.recommendation
          .infoForEditBtn
          ? getInstructionData.infoForAccountUser.recommendation.infoForEditBtn
          : "",

        infoForSaveBtn: getInstructionData.infoForAccountUser.recommendation
          .infoForSaveBtn
          ? getInstructionData.infoForAccountUser.recommendation.infoForSaveBtn
          : "",
      }
      setTooltipData({ ...data })
    }
  }, [getInstructionData])

  useEffect(() => {
    if (REPORT_UPDATE.status === 200) {
      setTooltipForSaveBtn(false)
      setTooltipForEditBtn(false)
    } else if (REPORT_UPDATE.status === 403) {
      setTooltipForSaveBtn(false)
      setTooltipForEditBtn(false)
    }
  })

  // useEffect(() => {
  //   setRowCount(document.getElementsByTagName('textarea'))
  // }, [rowCount])

  const HandleSaveButton = () => {
    SET_IS_SAVED(true)
    if (saveRecommendation) {
      setSaveRecommendation(null)
      SET_IS_SAVED(false)
      dispatch(handleChangesHappend(false))
    } else {
      HandleSaveBtn()
    }
  }

  // function textareachange() {
  // const count = event.target.value.length
  //   if (count > 0) {
  //     settextareabadge(true)
  //   } else {
  //     settextareabadge(false)
  //   }
  // settextcount(event.target.value.length)
  // }

  // console.log(saveRecommendation)
  // console.log(e => HANDLE_CHANGE_RECOMMENDATION(e.target.value, 12))
  // console.log(ele[0].value.length);
  // console.log("data: ", RECOMMENDATION_DATA)
  return (
    <>
      {/* <Row>
           <Col className="col-12 m-auto ">
             <Card>
               <CardBody>
                 <Row>
                   <Col md="12"> */}
      <div style={{ ...REPORT_PAGE }}>
        <Row className="g-0">
          <Col md="12">
            <div>
              <Row>
                {/* <Col md="12"> */}
                <Col md="12">
                  <div
                    style={{
                      width: "100%",
                      // marginTop: "35px",
                      // marginLeft: "50px",
                      padding: "30px 40px 0 40px",
                      // marginLeft: "56px",
                    }}
                  >
                    <h2
                      style={{
                        fontSize: REPORT.FONT_SIZE.XLARGE,
                        fontWeight: 700,
                        fontFamily: REPORT.FONT_FACE.HEADING,
                        color: "#0D0F11",
                        paddingBottom: "10px",
                        letterSpacing: "2px",
                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                      }}
                    >
                      {`${
                        DATA_AVAIL &&
                        DATA_AVAIL.filter(
                          channel => channel.channelId === params.channelId
                        )[0].channelName
                      } RECOMMENDATIONS`.toUpperCase()}
                    </h2>
                    {
                      SCORECARDREPORT_DATA && 
                      SCORECARDREPORT_DATA.scorecardVersion === "Pro" ? (
                        <div
                          style={{
                            position: "absolute",
                            top: 85,
                            right: "160px",
                            padding: 6,
                            width: "190px",
                            // display: 'flex',
                            // border:'1px solid black'
                          }}
                        >
                          {!saveRecommendation ? (
                            <>
                              <Button
                                id="TooltipSave"
                                color="primary"
                                type="submit"
                                size="sm"
                                style={{
                                  fontSize: "13px",
                                  width: "70px",
                                  marginLeft: "250px",
                                  marginTop: "-160px",
                                }}
                                onClick={HandleSaveButton}
                                disabled={IS_SAVED}
                              >
                                {IS_SAVED ? "" : "Save"}
                                {IS_SAVED ? (
                                  <Spinner type="border" size="sm" />
                                ) : null}
                              </Button>
                              <Tooltip
                                style={{
                                  backgroundColor: "#FEF3C7",
                                  color: "#0D0F11",
                                  zIndex: 1,
                                }}
                                placement="left"
                                isOpen={tooltipForSaveBtn}
                                target="TooltipSave"
                                toggle={() => {
                                  setTooltipForSaveBtn(!tooltipForSaveBtn)
                                }}
                              >
                                {tooltipData.infoForSaveBtn}
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <Button
                                style={{
                                  fontSize: "13px",
                                  width: "70px",
                                  marginLeft: "250px",
                                  marginTop: "-160px",
                                }}
                                color="primary"
                                size="sm"
                                id="TooltipEdit"
                                onClick={() => {
                                  HandleSaveButton()
                                  settextareabadge(true)
                                }}
                              >
                                Edit
                              </Button>
                              <Tooltip
                                style={{
                                  backgroundColor: "#FEF3C7",
                                  color: "#0D0F11",
                                  zIndex: 1,
                                }}
                                placement="left"
                                isOpen={tooltipForEditBtn}
                                target="TooltipEdit"
                                toggle={() => {
                                  setTooltipForEditBtn(!tooltipForEditBtn)
                                }}
                              >
                                {tooltipData.infoForEditBtn}
                              </Tooltip>
                            </>
                          )}
                        </div>
                      ) : null
                    }
                  </div>
                </Col>
                {/* </Col> */}

                <Col md="12">
                  <div
                    style={{
                      width: "100%",

                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="table-responsive"
                      style={{
                        width: "100%",
                        padding: "15px 56px 56px 56px",
                      }}
                    >
                      {!saveRecommendation ? (
                        <Table className="table table-bordered mb-0">
                          <thead
                            style={{
                              backgroundColor: "#0D0F11",
                              color: "white",
                            }}
                          >
                            <tr>
                              <th
                                style={{
                                  fontSize: "15px",
                                  textAlign: "center",
                                  padding: "0",
                                  width: "20%",
                                }}
                              >
                                Tactic
                              </th>
                              <th
                                style={{
                                  fontSize: "15px",
                                  textAlign: "center",
                                  padding: "0",

                                  width: "80%",
                                }}
                              >
                                Recommendations
                              </th>
                            </tr>
                          </thead>
                          {RECOMMENDATION_DATA.tactics.map((tactic, i) => {
                            return (
                              <tbody key={i}>
                                <tr
                                  style={{
                                    height: "115px",
                                    verticalAlign: "middle",
                                    color: "#0D0F11",
                                  }}
                                >
                                  <th
                                    style={{
                                      ...RECOMMENDATIONS,
                                      textAlign: "center",
                                      padding: "2px 20px 20px 20px",
                                      color: "#0D0F11",
                                    }}
                                  >
                                    {tactic.tacticName}
                                  </th>
                                  <td style={RECOMMENDATIONS}>
                                    <textarea
                                      style={{
                                        width: "100%",
                                        height: "115px",
                                        resize: "none",
                                        color: "#0D0F11",

                                      }}
                                      maxLength={450}
                                      // minLength={0}
                                      value={
                                        tactic.tacticRecommendation
                                      }
                                      onChange={(e) => {
                                        // setEditRecommendation(false)
                                        var ROWS =
                                          document.getElementsByTagName(
                                            "textarea"
                                          )
                                        var GivenArray = Array.from(
                                          { length: ROWS.length },
                                          (x, y) => 0
                                        )
                                        // console.log("ROWS: ", ROWS)
                                        GivenArray[i] = e.target.value.length
                                        // console.log(GivenArray)
                                        settextcount(GivenArray)
                                        HANDLE_CHANGE_RECOMMENDATION(
                                          e.target.value,
                                          tactic.tacticId
                                        )
                                        // textareachange(e)
                                        dispatch(handleChangesHappend(true))
                                      }}
                                    />
                                    {textareabadge ? (
                                      <span
                                      className="badgecount badge bg-primary"
                                      style={{
                                        float: "right",
                                        marginTop: -5,
                                      }}
                                      >
                                        {textcount[i]
                                          ? textcount[i]
                                          : tactic.recommendation.length}{" "}
                                        / 450
                                      </span>
                                    ) : null}
                                  </td>
                                </tr>
                              </tbody>
                            )
                          })}
                        </Table>
                      ) : (
                        <Table className="table table-bordered mb-0">
                          <thead
                            style={{
                              backgroundColor: "#0D0F11",
                              color: "white",
                            }}
                          >
                            <tr>
                              <th
                                style={{
                                  fontSize: "15px",
                                  textAlign: "center",
                                  padding: "0",
                                  width: "20%",
                                }}
                              >
                                Tactic
                              </th>
                              <th
                                style={{
                                  fontSize: "15px",
                                  textAlign: "center",
                                  padding: "0",

                                  width: "80%",
                                }}
                              >
                                Recommendations
                              </th>
                            </tr>
                          </thead>
                          {RECOMMENDATION_DATA.tactics.map((tactic, i) => {
                            return (
                              <tbody key={i}>
                                <tr
                                  style={{
                                    height: "115px",
                                    verticalAlign: "middle",
                                    color: "#0D0F11",
                                  }}
                                >
                                  <th
                                    style={{
                                      ...RECOMMENDATIONS,
                                      textAlign: "center",
                                      padding: "2px 20px 20px 20px",
                                      color: "#0D0F11",
                                    }}
                                  >
                                    {tactic.tacticName}
                                  </th>
                                  <td style={RECOMMENDATIONS}>
                                    <textarea
                                      style={{
                                        width: "100%",
                                        height: "115px",
                                        resize: "none",
                                        color: "#0D0F11",
                                      }}
                                      maxLength={450}
                                      // minLength={0}
                                      value={
                                        //     TacticRecommendation[0] &&
                                        //     TacticRecommendation.filter(
                                        //     tactics =>
                                        //       tactics.tacticId ===
                                        //       tactic.tacticId
                                        //   )[0].recommendation
                                        tactic.recommendation
                                      }
                                    ></textarea>
                                  </td>
                                </tr>
                              </tbody>
                            )
                          })}
                        </Table>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Col
          md="12"
          style={{
            // border: '1px solid black'
            position: "absolute",
            bottom: 0,
            zIndex: 100,
          }}
        >
          <div
            style={{
              width: "100%",
              fontSize: REPORT.FONT_SIZE.MEDIUM,
              padding: "5px 5px 5px 40px",
              backgroundColor: REPORT.COLOR.FOOTER,
              fontFamily: REPORT.FONT_FACE.HEADING,
              letterSpacing: "2px",
              opacity: 0.9,
              fontWeight: 600,
              color: "white",
            }}
          >
            {`${
              DATA_AVAIL &&
              DATA_AVAIL.filter(
                channel => channel.channelId === params.channelId
              )[0].channelName
            }`.toUpperCase()}
            &nbsp; - RECOMMENDATIONS
          </div>
        </Col>
      </div>
      {/* </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
    </>
  )
}

export default ChildRecommendationPage
