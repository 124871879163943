import {
  UPDATE_INSTRUCTION_SUCCESS,
  UPDATE_INSTRUCTION_FAIL,
  UPDATE_INSTRUCTION_CLEAR,
} from "./actionTypes"


const INIT_STATE = {
    updateInstructionData : {}

}

const UpdateInstructionReducer = (state = INIT_STATE , action) => {
    switch(action.type){
        case UPDATE_INSTRUCTION_SUCCESS:
            return {
                ...state,
                updateInstructionData: action.payload
            }
        case UPDATE_INSTRUCTION_FAIL:
            return {
                ...state,
                updateInstructionData: action.payload
            }
        case UPDATE_INSTRUCTION_CLEAR:
            return {
                ...state,
                updateInstructionData: {}
            }

            default:
                return state
        

    }

}

export default UpdateInstructionReducer