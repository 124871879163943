import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
  Modal,
  Label,
  FormFeedback,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import { Link } from "react-router-dom"
import RadarChart from "pages/AccountUser/chart/chart"
import "./scrollBar.css"
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import { Toast } from "react-bootstrap"
import firebase from "firebase/compat"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import Select from "react-select"
import { Country, State } from "country-state-city"
import {
  ANNUAL_REVENUE,
  INDUSTRY,
  NUMBER_OF_EMPLOYEES,
  OWNERSHIP,
} from "../CreateScorecard/scorecardconfig/scorecardconfig"
import ScorecardCreatedIcon from "../../../assets/images/NewPopupIcons/Scorecard created.png"
import ScorecardCreationFaildIcon from "../../../assets/images/NewPopupIcons/FailedIcon.png"

import {
  getScorecardDetail,
  createScorecardReport,
  clearCreateScorecardReport,
  changeScorecardDetail,
  updateScorecardDetail,
  updateScorecardDetailClear,
  getScorecardDetailClear,
  ScorecardRoutingHandler,
  getTacticInfo,
  getScorecardReport,
  getReportTemplate,
  clearReportTemplate,
} from "store/actions"
import { getChannelScore } from "commonFunctions/GetChannelScore/getChannelScore"
import updateSuccessIcon from "../../../assets/images/NewPopupIcons/Scorecard created.png"
import updateFailedIcon from "../../../assets/images/NewPopupIcons/FailedIcon.png"
import ThankYouIcon from "../../../assets/images/NewPopupIcons/ThankYouIcon.png"

const viewScoringSummary = () => {
  const [channelAddedToList, setChannelAddedToList] = useState([])
  const [channelRemovedFromList, setChannelRemovedFromList] = useState([])
  const [channelListUpdate, setIsChannelListUpdate] = useState(false)
  const [isReportCreated, setIsReportCreated] = useState(false)
  const [is_user_exist, set_is_user_exist] = useState(false)
  const [is_report_create, set_is_report_create] = useState(false)

  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [is_take_a_survey, set_is_take_a_survey] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [channelProgress, setChannelProgess] = useState([])
  const [surveyByChannel, setSurveyByChannel] = useState(false)
  const [channelPath, setChannelPath] = useState("")
  const [disableSurveyByChannel, setDisableSurveyByChannel] = useState(false)
  const [someoneIsWorking, setSomeoneIsWorking] = useState(false)
  const [data, setData] = useState({})
  const params = useParams()
  const history = useHistory()

  // State for thank you message
  const [displayThankYouMessage, setDisplayThankYouMessage] = useState(false)

  // State to update client/company information
  const [isClientDetailsUpdate, setIsClientDetailsUpdate] = useState(false)
  // For Country and City Dropdown list
  const [countries, setCountries] = useState(null)
  // State to disable the save changes button when Edit client Details
  const [isSaveClickedForClientDetails, setIsSaveClickedForClientDetails] =
    useState(false)

  const style_1 = {
    borderRadius: "2px",
    boxShadow: "0 0.75rem 1.5rem rgb(18 38 63 / 3%)",
    overflow: "hidden",
    margin: "10px 0px",
  }

  const dispatch = useDispatch()
  const {
    scorecardDetail,
    scorecardReportCreateResponse,
    updatedScorecardDetail,
    updateTactic,
    GetTacticInfo,
    scorecardReport_data,
    getReport,
  } = useSelector(state => ({
    scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
    scorecardReportCreateResponse:
      state.ScorecardReport_Reducer.scorecardReportCreateResponse,
    updatedScorecardDetail:
      state.GetScorecardsListReducer.updatedScorecardDetail,
    updateTactic: state.UpdateTacticDetailsReducer.updateTactic,
    GetTacticInfo: state.AddTacticReducer.GetTacticInfo,
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
    getReport: state.createReportReducer.getReport,
  }))

  useEffect(() => {
    dispatch(getScorecardDetail(params.scorecardId))
    dispatch(getReportTemplate())
    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
    return () => {
      dispatch(getScorecardDetailClear())
      dispatch(clearReportTemplate())
    }
  }, [dispatch])

  useEffect(() => {
    if (Object.keys(scorecardDetail).includes("selectedChannels")) {
      window.localStorage.setItem(
        "selectedChannels",
        JSON.stringify(
          scorecardDetail.selectedChannels.map(channel => ({
            channelId: channel.channelId,
            channelName: channel.channelName,
            tactics: channel.tactics.map(tactic => ({
              ...tactic,
              tacticName: tactic.tacticName,
              tacticId: tactic.tacticId,
            })),
          }))
        )
      )
    }
  }, [scorecardDetail])

  useEffect(() => {
    if (
      Object.keys(scorecardReport_data).includes("selectedChannels") &&
      scorecardDetail.isReportCreated
    ) {
      // console.log(scorecardReport_data)
      window.localStorage.setItem(
        "selectedChannelsForEditReport",
        JSON.stringify(
          scorecardReport_data.selectedChannels.map(channel => ({
            channelId: channel.channelId,
            channelName: channel.channelName,
            tactics: channel.tactics.map(tactic => ({
              ...tactic,
              tacticName: tactic.tacticName,
              tacticId: tactic.tacticId,
            })),
          }))
        )
      )
    }
  }, [scorecardReport_data])

  useEffect(() => {
    dispatch(updateScorecardDetailClear())
  }, [])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user
          .getIdTokenResult()
          .then(token => {
            dispatch(IsAccountOwner(token.claims.admin))
          })
          .catch(err => { })
      }
    })
  }, [])

  const channelClassifier = (data, level) => {
    if (scorecardDetail.selectedChannels) {
      var channelName =
        (channelListUpdate &&
          channelAddedToList.map(channel => {
            var channelScore = getChannelScore(channel)

            if (channelScore <= data && channelScore > data - level) {
              return channel.channelName
            }
          })) ||
        scorecardDetail.selectedChannels.map(channel => {
          var channelScore = getChannelScore(channel)

          if (channelScore <= data && channelScore > data - level) {
            return channel.channelName
          }
        })
    }
    return (
      (channelName && channelName.filter(channel => channel !== undefined)) ||
      []
    )
  }

  const addChannelToList = data => {
    var channelList =
      scorecardDetail.selectedChannels &&
      scorecardDetail.selectedChannels.filter(
        channel => channel.channelId === data
      )

    setChannelAddedToList(e => [...e, ...channelList])

    if (channelRemovedFromList[0])
      setChannelRemovedFromList(
        channelRemovedFromList.filter(channel => channel.channelId !== data)
      )
  }

  const removeChannelFromList = data => {
    setIsChannelListUpdate(true)
    var channelList =
      scorecardDetail.selectedChannels &&
      scorecardDetail.selectedChannels.filter(
        channel => channel.channelId === data
      )

    setChannelRemovedFromList(e => [...e, ...channelList])

    if (channelAddedToList[0])
      setChannelAddedToList(
        channelAddedToList.filter(channel => channel.channelId !== data)
      )
    else
      setChannelAddedToList(
        scorecardDetail.selectedChannels &&
        scorecardDetail.selectedChannels.filter(
          channel => channel.channelId !== data
        )
      )
  }

  // console.log(JSON.parse(localStorage.getItem("authUser")))

  const handleCreateReport = () => {
    if (channelListUpdate) {
      var scorecardReportData = {
        ...scorecardDetail,
        selectedChannels: [...channelAddedToList],

        isReportCreated: true,
        status: "Report Only",
      }

      window.localStorage.setItem(
        "selectedChannelsForEditReport",
        JSON.stringify(
          channelAddedToList.map(channel => ({
            channelId: channel.channelId,
            channelName: channel.channelName,
            tactics: channel.tactics.map(tactic => ({
              tacticName: tactic.tacticName,
              tacticId: tactic.tacticId,
            })),
          }))
        )
      )

      dispatch(
        createScorecardReport(params.scorecardId, {
          ...scorecardDetail,
          selectedChannels: [...channelAddedToList],
          isReportCreated: true,
          reportGenerationDate: new Date().getTime() / 1000,
          cover: {
            bgImage:
              getReport && getReport.cover ? getReport.cover.bgImage : null,
          },
          status: "Report Only",
        })
      )
      dispatch(
        updateScorecardDetail(params.scorecardId, {
          ...scorecardReportData,
          activeUser: {
            userName: JSON.parse(localStorage.getItem("authUser")).displayName,
            userId: JSON.parse(localStorage.getItem("authUser")).uid,
            isActive: true,
          },
          lastUpdatedOn: {
            _miliseconds: 0,
            _seconds: new Date().getTime() / 1000,
          },
        })
      )
    } else {
      window.localStorage.setItem(
        "selectedChannelsForEditReport",
        JSON.stringify(
          scorecardDetail.selectedChannels.map(channel => ({
            channelId: channel.channelId,
            channelName: channel.channelName,
            tactics: channel.tactics.map(tactic => ({
              tacticName: tactic.tacticName,
              tacticId: tactic.tacticId,
            })),
          }))
        )
      )

      dispatch(
        createScorecardReport(params.scorecardId, {
          ...scorecardDetail,
          cover: {
            bgImage:
              getReport && getReport.cover ? getReport.cover.bgImage : null,
          },
          reportGenerationDate: new Date().getTime() / 1000,
          isReportCreated: true,
          status: "Report Only",
        })
      )

      dispatch(
        updateScorecardDetail(params.scorecardId, {
          ...scorecardDetail,
          isReportCreated: true,
          status: "Report Only",
          lastUpdatedOn: {
            _miliseconds: 0,
            _seconds: new Date().getTime() / 1000,
          },
          activeUser: {
            userName: JSON.parse(localStorage.getItem("authUser")).displayName,
            userId: JSON.parse(localStorage.getItem("authUser")).uid,
            isActive: true,
          },
        })
      )
    }
  }

  useEffect(() => {
    if (is_report_create && !scorecardDetail.activeUser) {
      dispatch(
        updateScorecardDetail(params.scorecardId, {
          activeUser: {
            userName: JSON.parse(localStorage.getItem("authUser")).displayName,
            userId: JSON.parse(localStorage.getItem("authUser")).uid,
            isActive: true,
          },
        })
      )

      handleCreateReport()
    } else if (is_report_create && scorecardDetail.activeUser) {
      set_is_report_create(false)
      // alert("user exist.")
      set_is_user_exist(true)
    }
  }, [scorecardDetail])

  useEffect(() => {
    if (isReportCreated && updatedScorecardDetail.status === 200) {
      if (
        scorecardDetail &&
        scorecardDetail.scorecardVersion === "Self Report"
      ) {
        setDisplayThankYouMessage(true)
      } else {
        history.push(`/edit/scorecard-report/${params.scorecardId}/cover-page`)
      }
    }
  }, [updatedScorecardDetail])

  // Functionality for Thank You Message
  useEffect(() => {
    if (displayThankYouMessage) {
      setTimeout(() => {
        setDisplayThankYouMessage(false)
        history.push(`/edit/scorecard-report/${params.scorecardId}/cover-page`)
      }, 3000)
    }
  }, [displayThankYouMessage])

  useEffect(() => {
    if (isEditMode && !scorecardDetail.activeUser) {
      dispatch(
        updateScorecardDetail(params.scorecardId, {
          activeUser: {
            userName: JSON.parse(localStorage.getItem("authUser")).displayName,
            userId: JSON.parse(localStorage.getItem("authUser")).uid,
            isActive: true,
          },
          lastUpdatedOn: {
            _miliseconds: 0,
            _seconds: new Date().getTime() / 1000,
          },
        })
      )
    } else if (isEditMode && scorecardDetail.activeUser) {
      setIsEditMode(false)
      // alert("user exist.")
      set_is_user_exist(true)
    }

    if (is_take_a_survey && !scorecardDetail.activeUser) {
      dispatch(
        updateScorecardDetail(params.scorecardId, {
          activeUser: {
            userName: JSON.parse(localStorage.getItem("authUser")).displayName,
            userId: JSON.parse(localStorage.getItem("authUser")).uid,
            isActive: true,
          },
          lastUpdatedOn: {
            _miliseconds: 0,
            _seconds: new Date().getTime() / 1000,
          },
          status: "In progress",
        })
      )
    } else if (is_take_a_survey && scorecardDetail.activeUser) {
      set_is_take_a_survey(false)
      // alert("user exist.")
      set_is_user_exist(true)
    }
    // ------------------------------
    // if (isEditMode) {
    //   setIsEditMode(false)
    //   alert("user exist.")
    // }
    // else {
    //     if (isEditMode && updatedScorecardDetail.status === 200) {
    //       history.push(
    //         `/edit/scorecard-report/${params.scorecardId}/cover-page`
    //       )
    //     }
    //   }
    // --------------------------------
  }, [scorecardDetail])

  useEffect(() => {
    if (isEditMode && updatedScorecardDetail.status === 200) {
      history.push(`/edit/scorecard-report/${params.scorecardId}/cover-page`)
    }
    if (
      is_take_a_survey &&
      updatedScorecardDetail.status === 200 &&
      !surveyByChannel
    ) {
      var channelId = scorecardDetail.selectedChannels[0].channelId
      var tacticId = scorecardDetail.selectedChannels[0].tactics[0].tacticId
      history.push(
        `/take-a-survey/${params.scorecardId}/${channelId}/${tacticId}/${tacticId}`
      )
    }
  }, [updatedScorecardDetail])

  useEffect(() => {
    if (surveyByChannel && !scorecardDetail.activeUser) {
      dispatch(
        updateScorecardDetail(params.scorecardId, {
          activeUser: {
            userName: JSON.parse(localStorage.getItem("authUser")).displayName,
            userId: JSON.parse(localStorage.getItem("authUser")).uid,
            isActive: true,
          },
          lastUpdatedOn: {
            _miliseconds: 0,
            _seconds: new Date().getTime() / 1000,
          },
          status: "In progress",
        })
      )
    }
  }, [scorecardDetail])

  useEffect(() => {
    if (surveyByChannel && updatedScorecardDetail.status === 200) {
      history.push(channelPath)
      setSurveyByChannel(false)
    }
  }, [updatedScorecardDetail, channelPath])
  // const takeASurveyByChannelName = (channelPath) => {
  //   if (updatedScorecardDetail.status === 200 && surveyByChannel) {
  //     history.push(channelPath)
  //   }
  // }
  // console.log(scorecardDetail)

  const getAttemptedTacticCount = channel => {
    if (channel.tactics[0]) {
      var tacticsLength = channel.tactics
        .filter(t => t.tacticScore && t.tacticScore !== undefined)
        .map((tact, i) => {
          return tact
        })
      return tacticsLength.length
    } else {
      return 0
    }
  }

  const getEnableTacticCount = channel => {
    if (channel.tactics[0]) {
      var tacticsLength = channel.tactics
        .filter(t => !t.isDisabled)
        .map((tact, i) => {
          return tact
        })
      // console.log(tacticsLength);
      return tacticsLength.length
    } else {
      return 0
    }
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user
          .getIdTokenResult()
          .then(token => {
            setData(token.claims)
            // console.log(token.claims)
          })
          .catch(err => { })
      }
    })

    return () => {
      setData(null)
    }
  }, [])

  // console.log("DATA...", data)
  // console.log("Scorecard Details...", scorecardDetail)
  // console.log("isSurveyCompleted...", scorecardDetail.isSurveyCompleted)

  // To set country dropdown
  useEffect(() => {
    var countries_ = [...Country.getAllCountries()].map(country => {
      if (country.isoCode === "CA") {
        return {
          ...country,
          phonecode: "001",
        }
      } else if (country.isoCode === "UM") {
        return {
          ...country,
          phonecode: "01",
        }
      } else {
        return country
      }
    })

    // console.log(countries_)
    setCountries(countries_)
  }, [])

  // To get the country code
  const GetCountryByCode = () => {
    if (countries) {
      var country = countries.filter(
        country => country.name === validation.values.country
      )
      return State.getStatesOfCountry(country[0].isoCode)
    }
  }

  // validations for useFormik
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      scorecardName:
        "" || (params.scorecardId && scorecardDetail.scorecardName),
      companyName: "" || (params.scorecardId && scorecardDetail.companyName),
      contactName: "" || (params.scorecardId && scorecardDetail.contactName),
      phone: "" || (params.scorecardId && scorecardDetail.phone),
      email: "" || (params.scorecardId && scorecardDetail.email),
      companyAddress1:
        "" || (params.scorecardId && scorecardDetail.companyAddress1),
      companyAddress2:
        "" || (params.scorecardId && scorecardDetail.companyAddress2),
      annualRevenue:
        "" || (params.scorecardId && scorecardDetail.annualRevenue),
      country:
        "" ||
        (params.scorecardId && scorecardDetail.country) ||
        "United States",
      state: "" || (params.scorecardId && scorecardDetail.state),
      numberOfEmployee:
        "" || (params.scorecardId && scorecardDetail.numberOfEmployee),
      industry: "" || (params.scorecardId && scorecardDetail.industry),
      websiteUrl: "" || (params.scorecardId && scorecardDetail.websiteUrl),
      ownership: "" || (params.scorecardId && scorecardDetail.ownership),
      clientAttribute1:
        "" || (params.scorecardId && scorecardDetail.clientAttribute1),
      clientAttribute2:
        "" || (params.scorecardId && scorecardDetail.clientAttribute2),
      clientAttribute3:
        "" || (params.scorecardId && scorecardDetail.clientAttribute3),
      clientAttribute4:
        "" || (params.scorecardId && scorecardDetail.clientAttribute4),
      countryCode:
        "" || (params.scorecardId && scorecardDetail.countryCode) || "1",
      assignedTo: "" || (params.scorecardId && scorecardDetail.assignedTo),
      selectedChannels:
        "" || (params.scorecardId && scorecardDetail.selectedChannels),
    },

    validationSchema: Yup.object({
      scorecardName: Yup.string().required("Please enter scorecard name"),

      email: Yup.string().email("Must be valid email"),
      phone: Yup.string()
        .max(10, "Phone number must be atleast 10 digits")
        .min(10, "Phone number must be atleast 10 digits"),
    }),

    onSubmit: values => {
      setIsSaveClickedForClientDetails(true)
      dispatch(
        updateScorecardDetail(params.scorecardId, {
          ...values,
          lastUpdatedOn: {
            _miliseconds: 0,
            _seconds: new Date().getTime() / 1000,
          },
        })
      )
    },
  })

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Mambo -Scoring Summary</title>
        </MetaTags>
        {/* {disableSurveyByChannel && (
                <SweetAlert
                  btnSize="lg"
                  title=""
                  warning
                  confirmBtnBsStyle="primary"
                  // style={{
                  //   height: "255px",
                  //   width: "368px",
                  // }}
                  onConfirm={() => {
                    setDisableSurveyByChannel(false)
                  }}
                >
                  <span className="fw-bold">You have already taken an assessment for this scorecard.!</span>
                </SweetAlert>
              )} */}

        {/* {disableSurveyByChannel && (
                <SweetAlert
                  style={{height:"270px", width:"270px"}}
                  custom
                  customIcon={
                    <Row>
                      <Col md="12" className="mt-2">
                        <img src={updateFailedIcon}
                          style={{
                            height:'80px', 
                            width:'80px'
                        }}/>
                      </Col>
                      <Col md="12" className="text-center container-fluid mt-3"
                        style={{
                          color:'#0D0F11',
                          fontWeight:600,
                          fontSize:'20px',
                          lineHeight:'28px',
                        }}
                      >
                       Assessment has been closed. You cannot attempt it again.
                      </Col>
                    </Row>
                  }
                  customButtons={
                    <div className="container-fluid mb-2" style={{marginTop:'-10px'}}>
                        <Button block size="sm" className="my-2"
                          style={{
                            background:'#0D0F11',
                            border:'#0D0F11'
                          }}
                          onClick={() => {
                            setDisableSurveyByChannel(false)
                          }}
                        >
                          Close
                        </Button>
                    </div>
                  }
                >
                </SweetAlert>
              )} */}

        {/* {someoneIsWorking && (
                <SweetAlert
                  btnSize="lg"
                  title=""
                  warning
                  confirmBtnBsStyle="primary"
                  onConfirm={() => {
                    setSomeoneIsWorking(false)
                  }}
                >
                  <span
                    className="fw-bold"
                  >
                    {scorecardDetail.activeUser && scorecardDetail.activeUser.userName} {" "} is working...!
                  </span>          
                </SweetAlert>
              )} */}

        {displayThankYouMessage && (
          <SweetAlert
            style={{ height: "270px", width: "270px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img
                    src={ThankYouIcon}
                    style={{
                      height: "80px",
                      width: "80px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: 1.2,
                  }}
                >
                  Thank You for Completing the Assessment!
                </Col>
              </Row>
            }
            customButtons={<div className="container-fluid"></div>}
          ></SweetAlert>
        )}

        {someoneIsWorking && (
          <SweetAlert
            style={{ height: "270px", width: "270px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mt-2">
                  <img
                    src={updateFailedIcon}
                    style={{
                      height: "80px",
                      width: "80px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid mt-3"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "22px",
                    lineHeight: "28px",
                  }}
                >
                  {scorecardDetail.activeUser &&
                    scorecardDetail.activeUser.userName}{" "}
                  is working.
                </Col>
              </Row>
            }
            customButtons={
              <div
                className="container-fluid mb-2"
                style={{ marginTop: "-10px" }}
              >
                <Button
                  block
                  size="sm"
                  className="my-2"
                  style={{
                    background: "#0D0F11",
                    border: "#0D0F11",
                  }}
                  onClick={() => {
                    setSomeoneIsWorking(false)
                  }}
                >
                  Close
                </Button>
              </div>
            }
          ></SweetAlert>
        )}

        {/* SweetAlert after company information updated successfully */}
        {updatedScorecardDetail.status === 200 && isSaveClickedForClientDetails && (
          <SweetAlert
            style={{ height: "270px", width: "250px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-3">
                  <img
                    src={ScorecardCreatedIcon}
                    style={{
                      height: "70px",
                      width: "75px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "28px",
                  }}
                >
                  Changes updated successfully.
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                <Button
                  block
                  size="sm"
                  style={{
                    background: "#0D0F11",
                    border: "#0D0F11",
                  }}
                  onClick={() => {
                    setIsClientDetailsUpdate(false)
                    setIsSaveClickedForClientDetails(false)
                    dispatch(getScorecardDetail(params.scorecardId))
                    dispatch(updateScorecardDetailClear())
                  }}
                >
                  Close
                </Button>
              </div>
            }
          ></SweetAlert>
        )}

        {/* SweetAlert if it failed to update company information  */}
        {updatedScorecardDetail.status === 403 && isSaveClickedForClientDetails && (
          <SweetAlert
            style={{ height: "230px", width: "250px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-3">
                  <img
                    src={ScorecardCreationFaildIcon}
                    style={{
                      height: "65px",
                      width: "65px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "25px",
                  }}
                >
                  Failed to save data.
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                <Button
                  block
                  size="sm"
                  style={{
                    background: "#0D0F11",
                    border: "#0D0F11",
                  }}
                  onClick={() => {
                    setIsSaveClickedForClientDetails(false)
                    dispatch(updateScorecardDetailClear())
                  }}
                >
                  Close
                </Button>
              </div>
            }
          ></SweetAlert>
        )}

        {!isReportCreated && (
          <Modal
            isOpen={modal_backdrop}
            // toggle={() => {
            //   tog_backdrop()
            // }}
            centered
            size="lg"
          >
            <div
              className="mt-2"
              style={{
                backgroundColor: "#FFD788",
                color: "#0D0F11",
                height: "40px",
              }}
            >
              <h5
                className="fw-bold mx-5 p-2"
                style={{
                  color: "#0D0F11",
                }}
              >
                Select Channels for Report
                <span className="font-size-14 mx-2 fw-normal">
                  (Note: You cannot modify once clicked on <b>Continue</b>)
                </span>
              </h5>
            </div>

            <div className="modal-body">
              <Row>
                <Col
                  md="12"
                  className="fw-bold"
                  style={{
                    fontSize: "22px",
                    color: "#0D0F11",
                    marginLeft: "37px",
                  }}
                >
                  {scorecardDetail && scorecardDetail.scorecardName}
                </Col>
              </Row>
              <Row
                className="mt-3 fw-bold font-size-16"
                style={{ color: "#0D0F11" }}
              >
                <Col md="4" className="mb-2" style={{ marginLeft: "37px" }}>
                  Channel Name
                </Col>
                <Col md="4" className="mb-2">
                  Levels Of Maturity
                </Col>
                <Col md="3" className="mb-2">
                  Select Channels <br />
                  <span className="fw-normal font-size-14">
                    (Included in Report)
                  </span>
                </Col>
                <span
                  className="border-bottom mt-2 border-dark"
                  style={{ width: "95%", marginLeft: "17px" }}
                ></span>
              </Row>

              <Row
                className="customScrollBar"
                style={{
                  height: "250px",
                  overflow: "auto",
                }}
              >
                {scorecardDetail.selectedChannels &&
                  [...scorecardDetail.selectedChannels]
                    .sort((a, b) => getChannelScore(b) - getChannelScore(a))
                    .map((channel, index) => (
                      <Row key={index}>
                        <Col
                          md="4"
                          className="mt-4"
                          style={{
                            color: "#05727B",
                            fontSize: "16px",
                            fontWeight: 600,
                            marginLeft: "37px",
                          }}
                        >
                          {channel.channelName}
                        </Col>
                        <Col
                          md="4"
                          className="mt-4 font-size-14"
                          style={{ color: "#0D0F11" }}
                        >
                          {getChannelScore(channel) >= 100 - 20 &&
                            getChannelScore(channel) <= 100 ? (
                            <p>Mature</p>
                          ) : getChannelScore(channel) > 79 - 20 &&
                            getChannelScore(channel) <= 79 ? (
                            <p>Advanced</p>
                          ) : getChannelScore(channel) > 59 - 20 &&
                            getChannelScore(channel) <= 59 ? (
                            <p>Intermediate</p>
                          ) : getChannelScore(channel) > 39 - 40 &&
                            getChannelScore(channel) <= 39 ? (
                            <p>Beginner & Early</p>
                          ) : (
                            <p>N.A</p>
                          )}
                        </Col>
                        <Col
                          md="3"
                          className="mt-4 text-center"
                          id="customCheckBox"
                        >
                          <Input
                            type="checkbox"
                            className="font-size-22"
                            value={channel.channelId}
                            onChange={e => {
                              if (e.target.checked) {
                                addChannelToList(e.target.value)
                              } else {
                                removeChannelFromList(e.target.value)
                              }
                            }}
                            defaultChecked
                          />
                        </Col>
                      </Row>
                    ))}
              </Row>
              <Row className="mt-2">
                <Col md="6">
                  <Button
                    type="button"
                    block
                    style={{
                      // backgroundColor: "#FEF3C7",
                      // borderColor: "#FEF3C7",
                      backgroundColor: "#CB6928",
                      borderColor: "#CB6928",
                    }}
                    onClick={() => {
                      setmodal_backdrop(false)
                    }}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ color: "#0D0F11" }}
                    >
                      <span className="font-size-14 fw-bold" style={{ color: "white" }}>Back</span>
                    </div>
                  </Button>
                </Col>
                <Col md="6">
                  <Button
                    type="button"
                    block
                    style={{
                      backgroundColor: "#0D0F11",
                      borderColor: "#0D0F11",
                    }}
                    onClick={() => {
                      setIsReportCreated(true)
                      set_is_report_create(true)
                      dispatch(getScorecardDetail(params.scorecardId))
                    }}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ color: "#ffffff" }}
                    >
                      <span className="font-size-14 fw-bold">Continue</span>
                      <i className="bx bx-right-arrow-alt text-white fw-bold mx-2 mt-1"></i>
                    </div>
                  </Button>
                </Col>
              </Row>
            </div>
          </Modal>
        )}

        {surveyByChannel && (
          <Modal
            size="lg"
            centered
            style={{ visibility: "hidden" }}
            isOpen={surveyByChannel}
            toggle={() => {
              tog_small()
            }}
          >
            <Row style={{ visibility: "visible" }}>
              <Col
                md="12"
                style={{
                  textAlign: "center",
                }}
              >
                <Spinner
                  className="mb-4"
                  style={{
                    color: "#ffffff",
                    width: "60px",
                    height: "60px",
                  }}
                />

                <p
                  className="font-size-20"
                  style={{
                    color: "#ffffff",
                    marginLeft: "10px",
                  }}
                >
                  Please Wait..
                </p>
              </Col>
            </Row>
          </Modal>
        )}

        {scorecardDetail && scorecardDetail.scorecardVersion === "Self Report" && (
          <Container fluid={true}>
            <Row className="m-auto">
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <CardTitle
                        style={{
                          fontSize: "24px",
                          color: "#0D0F11",
                          fontWeight: "normal",
                        }}
                      >
                        <div className="d-flex">
                          <div>
                            <b>Company Information</b>
                          </div>
                          <div className="ms-auto">
                            {!isClientDetailsUpdate ? (
                              <Button
                                size="sm"
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: "#05727B",
                                  borderRadius: "7px",
                                }}
                                onClick={() => {
                                  setIsClientDetailsUpdate(true)
                                }}
                              >
                                <div className="d-flex justify-content-center p-1">
                                  <i className="fas fa-pencil-alt fw-bold font-size-14" />
                                </div>
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: "#05727B",
                                  borderRadius: "7px",
                                }}
                                onClick={() => {
                                  setIsClientDetailsUpdate(false)
                                }}
                              >
                                <div className="d-flex justify-content-center p-1">
                                  <i className="fas fa-undo-alt fw-bold font-size-14" />
                                </div>
                              </Button>
                            )}

                            {/* <span
                              className="rounded-circle ms-3"
                              style={{
                                color: "#64748B",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (data.admin) {
                                  history.push("/view/scorecards")
                                } else {
                                  history.push("/dashboard")
                                }
                              }}
                            >
                              <i className="mdi mdi-close-circle font-size-24"></i>
                            </span> */}
                          </div>
                        </div>
                      </CardTitle>
                    </Row>

                    {/* First Row */}
                    <Form
                      className="needs-validation outer-repeater"
                      autoComplete="off"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row className="my-2">
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">
                                Company Name
                              </label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail &&
                                  scorecardDetail.companyName) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">Company Name</label>
                              <Input
                                name="companyName"
                                placeholder="company name"
                                type="text"
                                className="form-control"
                                id="txtcompanyName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.companyName || ""}
                              />
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">
                                Contact Name
                              </label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail &&
                                  scorecardDetail.contactName) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">Contact Name</label>
                              <Input
                                name="contactName"
                                placeholder="contact name"
                                type="text"
                                className="form-control"
                                id="txtcontactName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contactName || ""}
                              />
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">
                                Country Code
                              </label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {scorecardDetail &&
                                  scorecardDetail.countryCode.includes("+")
                                  ? scorecardDetail.countryCode
                                  : "+" + scorecardDetail.countryCode || "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">Country Code</label>
                              <select
                                className="form-select"
                                name="countryCode"
                                value={validation.values.countryCode}
                                onChange={validation.handleChange}
                              >
                                {countries &&
                                  countries.map(country => (
                                    <option
                                      key={country.name}
                                      value={country.phonecode}
                                    >
                                      {country.flag}
                                      &nbsp;&nbsp;{country.isoCode}
                                      &nbsp;&nbsp;({country.phonecode})
                                    </option>
                                  ))}
                              </select>
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">Phone</label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail && scorecardDetail.phone) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">Phone</label>
                              <Input
                                name="phone"
                                placeholder="phone"
                                type="number"
                                min="1"
                                style={{
                                  MozAppearance: "none",
                                }}
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone &&
                                    validation.errors.phone
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.phone &&
                                validation.errors.phone ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.phone}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          )}
                        </Col>
                      </Row>

                      {/* Second Row */}
                      <Row className="my-2">
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">Email</label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail && scorecardDetail.email) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">Email</label>
                              <Input
                                name="email"
                                placeholder="email"
                                type="text"
                                className="form-control"
                                id="txtemail"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">
                                Company Address
                              </label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail &&
                                  scorecardDetail.companyAddress1) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">Company Address</label>
                              <Input
                                name="companyAddress1"
                                placeholder="line 1"
                                type="text"
                                className="form-control"
                                id="txtcompanyAddress1"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.companyAddress1 || ""}
                              />
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">
                                Country
                              </label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail && scorecardDetail.country) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">Country</label>
                              <Input
                                type="select"
                                className="form-select"
                                name="country"
                                onChange={validation.handleChange}
                                value={validation.values.country || ""}
                              >
                                <option value="">choose</option>
                                {countries &&
                                  countries.map(country => (
                                    <option
                                      key={country.name}
                                      value={country.name}
                                    >
                                      {country.flag}&nbsp;&nbsp;{country.name}
                                    </option>
                                  ))}
                              </Input>
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">State</label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail && scorecardDetail.state) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">State</label>
                              <Input
                                type="select"
                                className="form-select"
                                name="state"
                                onChange={validation.handleChange}
                                value={validation.values.state || ""}
                              >
                                <option value="">choose</option>
                                {countries &&
                                  GetCountryByCode().map(state => (
                                    <option key={state.name} value={state.name}>
                                      {state.name}
                                    </option>
                                  ))}
                              </Input>
                            </FormGroup>
                          )}
                        </Col>
                      </Row>

                      {/* Third Row */}
                      <Row className="my-2">
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">
                                Annual Revenue
                              </label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail &&
                                  scorecardDetail.annualRevenue) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">Annual Revenue</label>
                              <Input
                                type="select"
                                className="form-select"
                                name="annualRevenue"
                                onChange={validation.handleChange}
                                value={validation.values.annualRevenue || ""}
                              >
                                <option value="">choose</option>
                                {ANNUAL_REVENUE.data.map((revenue, i) => (
                                  <option key={i}>{revenue}</option>
                                ))}
                              </Input>
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">
                                Number Of Employees
                              </label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail &&
                                  scorecardDetail.numberOfEmployee) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">
                                Number Of Employees
                              </label>
                              <Input
                                type="select"
                                className="form-select"
                                name="numberOfEmployee"
                                onChange={validation.handleChange}
                                value={validation.values.numberOfEmployee || ""}
                              >
                                <option value="">choose</option>
                                {NUMBER_OF_EMPLOYEES.data.map((Employee, i) => (
                                  <option key={i}>{Employee}</option>
                                ))}
                              </Input>
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">
                                Industry
                              </label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail &&
                                  scorecardDetail.industry) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">Industry</label>
                              <Input
                                type="select"
                                className="form-select"
                                name="industry"
                                onChange={validation.handleChange}
                                value={validation.values.industry || ""}
                              >
                                <option value="">choose</option>
                                {INDUSTRY.data.map((industry, i) => (
                                  <option key={i}>{industry}</option>
                                ))}
                              </Input>
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">
                                Website URL
                              </label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail &&
                                  scorecardDetail.websiteUrl) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">Website URL</label>
                              <Input
                                name="websiteUrl"
                                placeholder="website url"
                                type="text"
                                className="form-control"
                                id="txtwebsiteUrl"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.websiteUrl || ""}
                              />
                            </FormGroup>
                          )}
                        </Col>
                      </Row>

                      {/* Last Row */}
                      <Row className="my-2">
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">
                                Client Attribute One
                              </label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail &&
                                  scorecardDetail.clientAttribute1) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">
                                Client Attribute One
                              </label>
                              <Input
                                name="clientAttribute1"
                                placeholder="client attribute 1"
                                type="text"
                                className="form-control"
                                id="txtclientAttribute1"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.clientAttribute1 || ""}
                              />
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="3">
                          {!isClientDetailsUpdate ? (
                            <p style={{ fontSize: "14px" }}>
                              <label className="fw-bold text-dark">
                                Client Attribute Two
                              </label>
                              {/* <div className="fw-bold text-muted"> */}
                              <div className="fw-bold" style={{ color: '#05727B' }}>
                                {(scorecardDetail &&
                                  scorecardDetail.clientAttribute2) ||
                                  "N.A."}
                              </div>
                            </p>
                          ) : (
                            <FormGroup className="mb-3">
                              <label className="fw-bold">
                                Client Attribute Two
                              </label>
                              <Input
                                name="clientAttribute2"
                                placeholder="client attribute 2"
                                type="text"
                                className="form-control"
                                id="txtclientAttribute2"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.clientAttribute2 || ""}
                              />
                            </FormGroup>
                          )}
                        </Col>
                      </Row>

                      {
                        isClientDetailsUpdate && (
                          <Row className="my-2">
                            <Button
                              block
                              type="submit"
                              disabled={isSaveClickedForClientDetails}
                              style={{
                                backgroundColor: "#0D0F11",
                                borderColor: "#0D0F11",
                              }}
                            >
                              {isSaveClickedForClientDetails ? (
                                <Spinner type="border" size="sm" />
                              ) : (
                                "Save Changes"
                              )}
                            </Button>
                          </Row>
                        )
                      }
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}

        <Container fluid={true}>
          <Row>
            <Row className="m-auto">
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {!scorecardDetail.id ? (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          height: "450px",
                        }}
                      >
                        <Spinner
                          style={{
                            width: "60px",
                            height: "60px",
                            margin: "calc(200px - 30px)",
                            color: "#05727B",
                          }}
                          role="grid"
                          type="border"
                        // color="primary"
                        />
                      </div>
                    ) : (
                      <>
                        {/* {
                          scorecardDetail &&
                          scorecardDetail.scorecardVersion === "Pro" && (
                            <Row style={{ marginTop: "-20px" }}>
                              <Col md="12" style={{ float: "right" }}>
                                <span
                                  className="rounded-circle"
                                  style={{
                                    float: "right",
                                    color: "#64748B",
                                    marginRight: "-10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (data.admin) {
                                      history.push("/view/scorecards")
                                    } else {
                                      history.push("/dashboard")
                                    }
                                  }}
                                >
                                  <i className="mdi mdi-close-circle font-size-24"></i>
                                </span>
                              </Col>
                            </Row>
                          )
                        } */}

                        <Row>
                          <Col md="9" sm="9" xs="8">
                            <CardTitle
                              style={{
                                fontSize: "24px",
                                color: "#0D0F11",
                                fontWeight: "normal",
                              }}
                            >
                              <b>Scoring Summary:</b>{" "}
                              {scorecardDetail && scorecardDetail.scorecardName}
                            </CardTitle>
                          </Col>

                          {
                            scorecardDetail &&
                              scorecardDetail.scorecardVersion === "Self Report" &&
                              data.accountUser ? null : (
                              <Col md="3" sm="3" xs="4" className="text-center">
                                <Button
                                  size="md"
                                  style={{
                                    color: "#ffffff",
                                    backgroundColor: "#05727B",
                                    borderRadius: "7px",
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      ScorecardRoutingHandler(
                                        document.location.pathname
                                      )
                                    ),
                                      history.push(
                                        `/view/scorecard/${params.scorecardId}`
                                      )
                                  }}
                                >
                                  <div className="d-flex justify-content-center pb-1">
                                    <i className="fas fa-pencil-alt fw-bold mx-2 mt-1">
                                      <span className="font-size-14 fw-normal mx-2">
                                        Edit Scorecard Details
                                      </span>
                                    </i>
                                  </div>
                                </Button>
                              </Col>
                            )
                          }
                        </Row>
                        <Row
                          className="mt-3 fw-bold font-size-18"
                          style={{ color: "#0D0F11" }}
                        >
                          {scorecardDetail &&
                            scorecardDetail.scorecardVersion === "Pro" ? (
                            <>
                              <Col md="3" xs="3" sm="3" className="mb-2">
                                Channel Name
                              </Col>
                              <Col md="3" xs="3" sm="3" className="mb-2">
                                Assessment Progress
                              </Col>
                              <Col
                                md="2"
                                xs="2"
                                sm="2"
                                className="mb-2 text-center"
                              >
                                Scores
                              </Col>
                              <Col md="4" xs="4" sm="4" className="mb-2">
                                Levels Of Maturity
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col md="6" xs="6" sm="6" className="mb-2">
                                Channel Name
                              </Col>
                              <Col md="6" xs="6" sm="6" className="mb-2">
                                Assessment Progress
                              </Col>
                            </>
                          )}
                          {/* <Col md="1" xs="1" sm="1"></Col> */}
                          <span
                            className="border-bottom mt-2 border-dark"
                            style={{
                              width: "96%",
                              marginLeft: "13px",
                            }}
                          ></span>
                        </Row>
                        {scorecardDetail &&
                          scorecardDetail.scorecardVersion === "Pro" ? (
                          <Row
                            style={{
                              height: "400px",
                              overflow: "auto",
                            }}
                            className="customScrollBar"
                          >
                            {scorecardDetail.selectedChannels &&
                              [...scorecardDetail.selectedChannels]
                                .sort(
                                  (a, b) =>
                                    getChannelScore(b) - getChannelScore(a)
                                )
                                .map((channel, index) => (
                                  <Row key={index} className="mt-4">
                                    <Col
                                      md="3"
                                      style={{
                                        color: "#05727B",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        marginTop: "-5px",
                                      }}
                                    >
                                      <Link
                                        style={{ color: "#05727B" }}
                                        onClick={() => {
                                          if (
                                            scorecardDetail.isSurveyCompleted &&
                                            scorecardDetail.isSurveyCompleted
                                          ) {
                                            setDisableSurveyByChannel(false)
                                          } else if (
                                            scorecardDetail.activeUser &&
                                            scorecardDetail.activeUser
                                              .userId !==
                                            JSON.parse(
                                              localStorage.getItem("authUser")
                                            ).uid
                                          ) {
                                            setSomeoneIsWorking(true)
                                          } else if (
                                            scorecardDetail.activeUser &&
                                            scorecardDetail.activeUser
                                              .userId ===
                                            JSON.parse(
                                              localStorage.getItem("authUser")
                                            ).uid
                                          ) {
                                            history.push(
                                              `/take-a-survey/${params.scorecardId}/${channel.channelId}/${channel.tactics[0].tacticId}/${channel.tactics[0].tacticId}`
                                            )
                                          } else {
                                            setSurveyByChannel(true)
                                            dispatch(
                                              getScorecardDetail(
                                                params.scorecardId
                                              )
                                            )
                                            setChannelPath(
                                              `/take-a-survey/${params.scorecardId}/${channel.channelId}/${channel.tactics[0].tacticId}/${channel.tactics[0].tacticId}`
                                            )
                                          }
                                        }}
                                      >
                                        {channel.channelName}
                                      </Link>
                                    </Col>
                                    <Col md="3" className="d-flex">
                                      <div
                                        className="progress col-md-12 ms-4"
                                        style={{ borderRadius: "12px" }}
                                      >
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          aria-label="Basic example"
                                          style={{
                                            width: `${(getAttemptedTacticCount(
                                              channel
                                            ) /
                                              getEnableTacticCount(channel)) *
                                              100
                                              }%`,
                                            backgroundColor: "#05727B",
                                            borderRadius: "12px",
                                          }}
                                          // aria-valuenow={getChannelScore(channel).toString()}
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                      <p
                                        className="text-center font-size-14 mx-2"
                                        style={{
                                          color: "#0D0F11",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        {getAttemptedTacticCount(channel)}/
                                        {getEnableTacticCount(channel)}
                                      </p>
                                    </Col>
                                    <Col md="1"></Col>
                                    <Col
                                      md="1"
                                      className="font-size-14"
                                      style={{
                                        color: "#0D0F11",
                                        marginTop: "-5px",
                                      }}
                                    >
                                      {getAttemptedTacticCount(channel) ===
                                        getEnableTacticCount(channel)
                                        ? getChannelScore(channel) + "%"
                                        : "-"}
                                    </Col>
                                    <Col
                                      md="2"
                                      className="font-size-14 mx-4"
                                      style={{
                                        color: "#0D0F11",
                                        marginTop: "-5px",
                                      }}
                                    >
                                      {getAttemptedTacticCount(channel) ===
                                        getEnableTacticCount(channel) ? (
                                        getChannelScore(channel) >= 100 - 20 &&
                                          getChannelScore(channel) <= 100 ? (
                                          <p>Mature</p>
                                        ) : getChannelScore(channel) >
                                          79 - 20 &&
                                          getChannelScore(channel) <= 79 ? (
                                          <p>Advanced</p>
                                        ) : getChannelScore(channel) >
                                          59 - 20 &&
                                          getChannelScore(channel) <= 59 ? (
                                          <p>Intermediate</p>
                                        ) : getChannelScore(channel) >
                                          39 - 40 &&
                                          getChannelScore(channel) <= 39 ? (
                                          <p>Beginner & Early</p>
                                        ) : (
                                          <p>N.A</p>
                                        )
                                      ) : (
                                        <p> - </p>
                                      )}
                                    </Col>
                                  </Row>
                                ))}
                            <Row>
                              <Col md="12">
                                <h4
                                  className="mt-4 fw-bold font-size-18"
                                  style={{
                                    color: "#0D0F11",
                                  }}
                                >
                                  Channel Analysis
                                </h4>
                                <div
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "600px",
                                      height: "fit-content",
                                      margin: "15px auto 0 auto",
                                      // border:'1px solid black'
                                    }}
                                  >
                                    {channelListUpdate ? (
                                      <RadarChart
                                        DATA={
                                          channelAddedToList[0] && {
                                            selectedChannels: [
                                              ...channelAddedToList,
                                            ],
                                          }
                                        }
                                      />
                                    ) : (
                                      <RadarChart
                                        DATA={
                                          scorecardDetail.selectedChannels &&
                                          scorecardDetail
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        ) : (
                          <Row
                            style={{
                              maxHeight: "400px",
                              overflow: "auto",
                            }}
                            className="customScrollBar"
                          >
                            {scorecardDetail.selectedChannels &&
                              [...scorecardDetail.selectedChannels]
                                .sort(
                                  (a, b) =>
                                    getChannelScore(b) - getChannelScore(a)
                                )
                                .map((channel, index) => (
                                  <Row key={index} className="mt-4">
                                    <Col
                                      md="6"
                                      style={{
                                        color: "#05727B",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        marginTop: "-5px",
                                      }}
                                    >
                                      <Link
                                        style={{ color: "#05727B" }}
                                        onClick={() => {
                                          if (
                                            scorecardDetail.isSurveyCompleted &&
                                            scorecardDetail.isSurveyCompleted
                                          ) {
                                            setDisableSurveyByChannel(false)
                                          } else if (
                                            scorecardDetail.activeUser &&
                                            scorecardDetail.activeUser
                                              .userId !==
                                            JSON.parse(
                                              localStorage.getItem("authUser")
                                            ).uid
                                          ) {
                                            setSomeoneIsWorking(true)
                                          } else if (
                                            scorecardDetail.activeUser &&
                                            scorecardDetail.activeUser
                                              .userId ===
                                            JSON.parse(
                                              localStorage.getItem("authUser")
                                            ).uid
                                          ) {
                                            history.push(
                                              `/take-a-survey/${params.scorecardId}/${channel.channelId}/${channel.tactics[0].tacticId}/${channel.tactics[0].tacticId}`
                                            )
                                          } else {
                                            setSurveyByChannel(true)
                                            dispatch(
                                              getScorecardDetail(
                                                params.scorecardId
                                              )
                                            )
                                            setChannelPath(
                                              `/take-a-survey/${params.scorecardId}/${channel.channelId}/${channel.tactics[0].tacticId}/${channel.tactics[0].tacticId}`
                                            )
                                          }
                                        }}
                                      >
                                        {channel.channelName}
                                      </Link>
                                    </Col>
                                    <Col md="6" className="d-flex ps-4">
                                      <div
                                        className="progress col-md-7"
                                        style={{ borderRadius: "12px" }}
                                      >
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          aria-label="Basic example"
                                          style={{
                                            width: `${(getAttemptedTacticCount(
                                              channel
                                            ) /
                                              getEnableTacticCount(channel)) *
                                              100
                                              }%`,
                                            backgroundColor: "#05727B",
                                            borderRadius: "12px",
                                          }}
                                          // aria-valuenow={getChannelScore(channel).toString()}
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                      <p
                                        className="text-center font-size-14 mx-2"
                                        style={{
                                          color: "#0D0F11",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        {getAttemptedTacticCount(channel)}/
                                        {getEnableTacticCount(channel)}
                                      </p>
                                    </Col>
                                  </Row>
                                ))}
                          </Row>
                        )}

                        {data.accountUser &&
                          scorecardDetail.scorecardVersion === "Self Report" &&
                          scorecardDetail.isSurveyCompleted ? null : (
                          <Row className="mt-2">
                            <Col sm="6" className="mt-2 g-2">
                              <Button
                                block
                                disabled={
                                  is_take_a_survey ||
                                  (scorecardDetail.isSurveyCompleted &&
                                    scorecardDetail.isSurveyCompleted)
                                }
                                // size="md"
                                // style={{
                                //   backgroundColor: "#05727B",
                                //   borderColor: "#05727B",
                                //   height: "38px",
                                // }}
                                style={{
                                  backgroundColor: is_take_a_survey || (scorecardDetail.isSurveyCompleted && scorecardDetail.isSurveyCompleted) ? 'GrayText' : "#05727B",
                                  borderColor: is_take_a_survey || (scorecardDetail.isSurveyCompleted && scorecardDetail.isSurveyCompleted) ? 'GrayText' : "#05727B",
                                  height: "38px",
                                }}
                                onClick={() => {
                                  if (
                                    scorecardDetail.isSurveyCompleted &&
                                    scorecardDetail.isSurveyCompleted
                                  ) {
                                    setDisableSurveyByChannel(false)
                                    set_is_take_a_survey(false)
                                  } else if (
                                    scorecardDetail.activeUser &&
                                    scorecardDetail.activeUser.userId !==
                                    JSON.parse(
                                      localStorage.getItem("authUser")
                                    ).uid
                                  ) {
                                    setSomeoneIsWorking(true)
                                  } else if (
                                    scorecardDetail.activeUser &&
                                    scorecardDetail.activeUser.userId ===
                                    JSON.parse(
                                      localStorage.getItem("authUser")
                                    ).uid
                                  ) {
                                    var channelId =
                                      scorecardDetail.selectedChannels[0]
                                        .channelId
                                    var tacticId =
                                      scorecardDetail.selectedChannels[0]
                                        .tactics[0].tacticId
                                    history.push(
                                      `/take-a-survey/${params.scorecardId}/${channelId}/${tacticId}/${tacticId}`
                                    )
                                  } else {
                                    set_is_take_a_survey(true)
                                    dispatch(
                                      getScorecardDetail(params.scorecardId)
                                    )
                                  }
                                }}
                              >
                                {is_take_a_survey ? (
                                  ""
                                ) : (
                                  <div
                                    style={{ color: "#ffffff" }}
                                    className="d-flex justify-content-center"
                                  >
                                    <i className="fas fa-pencil-alt mx-2 mt-1">
                                      <span className="font-size-14 mx-2">
                                        Continue Assessment
                                      </span>
                                    </i>
                                  </div>
                                )}

                                {is_take_a_survey ? (
                                  <Spinner
                                    type="border"
                                    size="sm"
                                  // color="dark"
                                  />
                                ) : null}
                              </Button>
                              {
                                // is_take_a_survey ||
                                (scorecardDetail.isSurveyCompleted &&
                                  scorecardDetail.isSurveyCompleted) && <span className="mt-1" style={{ color: "#000000" }}><strong>Note: The assessment is completed for this scorecard.</strong></span>}
                            </Col>
                            <Col sm="6" className="mt-2 g-2">
                              {scorecardDetail.id && (
                                <FormGroup className="m-0">
                                  {scorecardDetail.isSurveyCompleted &&
                                    scorecardDetail.isSurveyCompleted &&
                                    scorecardDetail.isReportCreated ? (
                                    <Button
                                      block
                                      disabled={
                                        isEditMode ||
                                          (data.accountUser &&
                                            scorecardDetail.scorecardVersion ===
                                            "Self Report")
                                          ? true
                                          : false
                                      }
                                      // size="md"
                                      style={{
                                        backgroundColor: "#0D0F11",
                                        borderColor: "#0D0F11",
                                      }}
                                      onClick={() => {
                                        if (
                                          scorecardDetail.activeUser &&
                                          scorecardDetail.activeUser.userId !==
                                          JSON.parse(
                                            localStorage.getItem("authUser")
                                          ).uid
                                        ) {
                                          setSomeoneIsWorking(true)
                                        } else if (
                                          scorecardDetail.activeUser &&
                                          scorecardDetail.activeUser.userId ===
                                          JSON.parse(
                                            localStorage.getItem("authUser")
                                          ).uid
                                        ) {
                                          history.push(
                                            `/edit/scorecard-report/${params.scorecardId}/cover-page`
                                          )
                                        } else {
                                          setIsEditMode(true)
                                          dispatch(
                                            getScorecardDetail(
                                              params.scorecardId
                                            )
                                          )
                                        }
                                      }}
                                    >
                                      {isEditMode ? (
                                        ""
                                      ) : (
                                        <div
                                          className="d-flex justify-content-center"
                                          style={{ color: "#ffffff" }}
                                        >
                                          <span className="font-size-14 fw-bold">
                                            {scorecardDetail &&
                                              scorecardDetail.scorecardVersion ===
                                              "Pro"
                                              ? "Edit Report"
                                              : "View Report"}
                                          </span>
                                          <i className="bx bx-right-arrow-alt text-white fw-bold mx-2 mt-1"></i>
                                        </div>
                                      )}
                                      {isEditMode ? (
                                        <Spinner type="border" size="sm" />
                                      ) : null}
                                    </Button>
                                  ) : (
                                    <>
                                      <Button
                                        block
                                        disabled={
                                          isReportCreated ||
                                            !scorecardDetail.isSurveyCompleted ||
                                            (data.accountUser &&
                                              scorecardDetail.scorecardVersion ===
                                              "Self Report")
                                            ? true
                                            : false
                                        }
                                        // size="md"
                                        style={{
                                          backgroundColor: scorecardDetail &&
                                            scorecardDetail.scorecardVersion ===
                                            "Self Report" ? "#CB6928" : "#0D0F11",
                                          borderColor: scorecardDetail &&
                                            scorecardDetail.scorecardVersion ===
                                            "Self Report" ? "#CB6928" : "#0D0F11",
                                        }}
                                        onClick={() => {
                                          // handleCreateReport()
                                          setmodal_backdrop(true)
                                        }}
                                      >
                                        {isReportCreated ? (
                                          ""
                                        ) : (
                                          <div
                                            className="d-flex justify-content-center"
                                            style={{ color: "#ffffff" }}
                                          >
                                            <span className="font-size-14 fw-bold">
                                              {scorecardDetail &&
                                                scorecardDetail.scorecardVersion ===
                                                "Pro"
                                                ? "Generate Report"
                                                : "Close and Submit"}
                                            </span>
                                            <i className="bx bx-right-arrow-alt text-white fw-bold mx-2 mt-1"></i>
                                          </div>
                                        )}

                                        {isReportCreated ? (
                                          <Spinner type="border" size="sm" />
                                        ) : null}
                                      </Button>
                                      {!scorecardDetail.isSurveyCompleted && (
                                        <span
                                          style={{
                                            marginTop: "5px",
                                            // fontWeight: 600,
                                            textAlign: "center",
                                            color: "red",
                                            fontSize: "12px",
                                            padding: "0px 12px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            display: "flex",
                                          }}
                                        >
                                          {scorecardDetail &&
                                            scorecardDetail.scorecardVersion ===
                                            "Pro"
                                            ? "Generate Report"
                                            : "Close and Submit"}{" "}
                                          is enabled only after all channel
                                          assessment is completed
                                        </span>
                                      )}
                                    </>
                                  )}
                                </FormGroup>
                              )}
                            </Col>
                          </Row>
                        )}

                        {scorecardDetail.activeUser &&
                          scorecardDetail.activeUser.userId !==
                          JSON.parse(localStorage.getItem("authUser"))
                            .uid && (
                            <span
                              style={{
                                marginTop: "12px",
                                textAlign: "center",
                                color: "red",
                                fontSize: "12px",
                                // fontWeight: 600,
                                float: "right",
                                padding: "0px 12px",
                              }}
                            >
                              {scorecardDetail.activeUser &&
                                scorecardDetail.activeUser.userName}{" "}
                              is working...
                            </span>
                          )}

                        {data.accountUser &&
                          scorecardDetail.scorecardVersion === "Self Report" &&
                          scorecardDetail.isSurveyCompleted && (
                            <div className="mt-2">
                              <span
                                style={{
                                  marginTop: "13px",
                                  fontWeight: "bold",
                                  color: "black",
                                  fontSize: "12px",
                                  // padding: "0px 12px",
                                }}
                              >
                                Note: The assessment is completed for this
                                scorecard.
                              </span>
                            </div>
                          )}
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default viewScoringSummary
