export const CLOUD_STORAGE = {
    CREATE_REPORT_TEMPLATE: {
        PRIORITY_AREA_OF_FOCUS_IMAGE: "/create_report_template/priority_area_of_focus/images/",
        PRIORITY_AREA_OF_FOCUS_THUMBNAIL: "/create_report_template/priority_area_of_focus/thumbnail_images/"
    },
    EDIT_SCORECARD_REPORT: {
        COVER: {
            IMAGE: "/edit_scorecard_report/cover_page/images/",
            THUMBNAIL: "/edit_scorecard_report/cover_page/thumbnail_images/"
        },
        TABLE_OF_CONTENT: {
            IMAGE: "/edit_scorecard_report/table_of_content/images/",
            THUMBNAIL: "/edit_scorecard_report/table_of_content/thumbnail_images/"
        },
        EXECUTIVE_SUMMARY: {
            IMAGE: "/edit_scorecard_report/executive_summary/images/",
            THUMBNAIL: "/edit_scorecard_report/executive_summary/thumbnail_images/"
        },
        KEY_OBSERVATIONS: {
            IMAGE: "/edit_scorecard_report/key_observations/images/",
            THUMBNAIL: "/edit_scorecard_report/key_observations/thumbnail_images/"
        },
        DOING_WELL: {
            IMAGE: "/edit_scorecard_report/what_you_are_doing_well/images/",
            THUMBNAIL: "/edit_scorecard_report/what_you_are_doing_well/thumbnail_images/"
        },
        QUICK_WINS: {
            IMAGE: "/edit_scorecard_report/quick_wins/images/",
            THUMBNAIL: "/edit_scorecard_report/quick_wins/thumbnail_images/"
        },
        KEY_TAKEAWAYS: {
            IMAGE: "/edit_scorecard_report/key_takeaways/images/",
            THUMBNAIL: "/edit_scorecard_report/key_takeaways/thumbnail_images/"
        },
    },
    CHANNEL: {
        IMAGE: "/channels/images/",
        THUMBNAIL: "/channels/thumbnail_images/",
    },
    REPORT: {
        IMAGE: "/report/images/",
        THUMBNAIL: "/report/thumbnail_images/"
    }
}