import {
  // Global State to open Buy Credit Product Menu
  IS_BUY_CREDIT_MENU_TOGGLE
} from "./actionTypes.js"

// Action to open or close the Buy Credit Products menu
export const toggleBuyCreditMenu = data => ({
  type: IS_BUY_CREDIT_MENU_TOGGLE,
  payload: data,
})
