import { call, put, takeEvery } from "redux-saga/effects"

import {
    addNewInstructionSuccess,
    addNewInstructionFail,
    updateInstructionSuccess,
    updateInstructionFail,
    getAllInstructionDataSuccess,
    getAllInstructionDataFail
} from "./action"

import {
    ADD_NEW_INSTRUCTION,
    GET_ALL_INSTRUCTION_DATA,
    UPDATE_INSTRUCTION
} from "./actionTypes"

import {
    addNewInstruction,
    getAllInstructionData,
    updateInstruction
} from "../../helpers/fakebackend_helper"


function* onAddNewInstruction({ payload: data }) {
    try {
        const response = yield call(addNewInstruction, data)
        yield put(addNewInstructionSuccess(response))
    } catch (error) {
        yield put(addNewInstructionFail(error))
    }

}

function* onGetAllInstructionData() {
    try {
        const response = yield call(getAllInstructionData)
        yield put(getAllInstructionDataSuccess(response))
    } catch (error) {
        yield put(getAllInstructionDataFail(error))
    }

}

function* onUpdateInstruction({ payload: data }) {
    try {
        const response = yield call(updateInstruction, data)
        yield put(updateInstructionSuccess(response))
    } catch (error) {
        yield put(updateInstructionFail(error))
    }

}

function* instructionPanelSaga() {
    yield takeEvery(ADD_NEW_INSTRUCTION, onAddNewInstruction)
    yield takeEvery(GET_ALL_INSTRUCTION_DATA, onGetAllInstructionData)
    yield takeEvery(UPDATE_INSTRUCTION, onUpdateInstruction)

}

export default instructionPanelSaga