import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import SweetAlert from "react-bootstrap-sweetalert"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import "../../configCk/ck.style.css"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  NavItem,
  NavLink,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"

import { useHistory } from "react-router-dom"

import {
  createReportTemplate,
  getReportTemplate,
  clearReportTemplate,
  handleUnsavedChanges,
  handleChangesHappend,
  UpdateGetReportTemplate,
} from "store/actions"

const ContentQuick = () => {
  const dispatch = useDispatch()
  const [rows1, setrows1] = useState([])
  const [input, setInput] = useState([])
  const history = useHistory()
  const [isDataAdd, setIsDataAdd] = useState(false)
  // const [Test, setTest] = useState(null)
  const [ContentQuickUpdate, setContentQuickUpdate] = useState({})

  const { getReport, createReport, isDataEdited, isThereChange } = useSelector(
    state => ({
      getReport: state.createReportReducer.getReport,
      createReport: state.createReportReducer.createReport,
      isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
      isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    })
  )

  useEffect(() => {
    if (!Object.keys(getReport).includes("contentQuickWins")) {
      dispatch(getReportTemplate())
    }
  }, [dispatch])
  // console.log(createReport)

  useEffect(() => {
    if (getReport.contentQuickWins !== undefined) {
      // setInput(getReport.executiveSummary.contents)
      var x = getReport.contentQuickWins.contents.map((quickwins, i) => {
        return {
          id: Math.random(),
          content: quickwins,
        }
      })
      setrows1(x)
    }
  }, [getReport])

  const ContentQuickManager = () => {
    var getContentQuick = document.querySelectorAll(".ck-content")
    var myContentQuickList = [...getContentQuick]
    var myContentQuick = myContentQuickList.map(obs => obs.innerHTML)

    // var temp = rows1
    // var test = myContentQuick.map((quickwins, index) => ({
    //   ...temp[index],
    //   content: quickwins
    // }))

    return myContentQuick
  }

  function handleAddRowNested() {
    if (rows1.length < 10) {
      // const modifiedRows = [...rows1]
      // modifiedRows.push({ id: modifiedRows.length + 1 })
      setrows1(e => [...e, { id: Math.random(), content: "" }])
    }
  }
  // console.log(rows1)
  function handleRemoveRow(id) {
    if (rows1.length > 1) {
      // var modifiedRows = [...rows1]
      // modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      // setIcon(icon.filter((icon, i) => i !== id - 1 ))
      // setIconThumbnail(iconThumbnail.filter((icon, i) => i !== id - 1 ))
      setrows1(rows1.filter(row => row.id !== id))
    }
  }

  const submitHandler = () => {
    const ContentQuickWins = {
      contentQuickWins: {
        contents: ContentQuickManager(),
      },
    }
    setContentQuickUpdate({
      ...getReport,
      contentQuickWins: {
        contents: ContentQuickManager(),
      },
    })
    setIsDataAdd(true)
    dispatch(createReportTemplate(ContentQuickWins))
    dispatch(handleChangesHappend(false))
    // history.push("/KeyTakeaways")
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Scorecard Report Template</title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <Col xl="12" style={{ margin: "auto" }}>
              <Card>
                <CardBody>
                  {createReport.status === 200 && (
                    <SweetAlert
                      // title="Tactic Updated successfully"
                      success
                      confirmBtnBsStyle="primary"
                      style={{ height: "255px", width: "368px" }}
                      onConfirm={() => {
                        dispatch(UpdateGetReportTemplate(ContentQuickUpdate))
                        setIsDataAdd(false)
                        // setsuccess_msg(false)
                        dispatch(clearReportTemplate())
                        // history.push("/")
                      }}
                    >
                      Data saved successfully
                    </SweetAlert>
                  )}
                  {createReport.status === 403 && (
                    <SweetAlert
                      // title="Tactic Updated successfully"
                      error
                      confirmBtnBsStyle="primary"
                      style={{ height: "255px", width: "368px" }}
                      onConfirm={() => {
                        setIsDataAdd(false)
                        // setsuccess_msg(false)
                        dispatch(clearReportTemplate())
                        // history.push("/")
                      }}
                    >
                      Failed to saved data
                    </SweetAlert>
                  )}
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      submitHandler()
                    }}
                  >
                    <Row>
                      <h4
                        className="card-title"
                      // style={{ color: "Background" }}
                      >
                        Quick Wins
                      </h4>
                    </Row>
                    <br></br>
                    <Row>
                      <Col md="12">
                        {/* repeater */}
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="inner-repeater mb-4">
                              <table
                                style={{ width: "100%" }}
                                className="Pointer"
                              >
                                <tbody>
                                  {rows1.map((formRow, key) => (
                                    <tr key={key}>
                                      <td>
                                        <Row className="mb-2">
                                          <Label>Pointer {++key} </Label>

                                          <Col md="11">
                                            <CKEditor
                                              //  placeholder = {`key obervation - ${++key}`}
                                              id="Pointer"
                                              editor={ClassicEditor}
                                              data={
                                                formRow.content
                                                // getReport.contentQuickWins !==
                                                //   undefined
                                                //   ? getReport.contentQuickWins
                                                //     .contents[--key] || ""
                                                //   : ""
                                              }
                                              config={{
                                                removePlugins: [
                                                  "EasyImage",
                                                  "ImageUpload",
                                                  "MediaEmbed",
                                                  "Essentials",
                                                  "Table",
                                                  "Table Toolbar",
                                                  "CkFinderUploadAdapter",
                                                  "CKFinder",
                                                  "Autoformat",
                                                  "Link",
                                                  "BlockQuote",
                                                ],
                                              }}
                                              onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                              }}
                                              onChange={(e, data) => {
                                                ContentQuickManager()
                                                setrows1(e =>
                                                  e.map(rw => {
                                                    if (rw.id === formRow.id)
                                                      return {
                                                        id: formRow.id,
                                                        content:
                                                          formRow.content,
                                                      }
                                                    else return rw
                                                  })
                                                )
                                                dispatch(
                                                  handleChangesHappend(true)
                                                )
                                              }}
                                            />
                                          </Col>
                                          <Col md="1">
                                            <FormGroup>
                                              {rows1.length > 1 ? (
                                                <i
                                                  className="mdi mdi-trash-can d-block font-size-24"
                                                  style={{
                                                    color: "#FF6060",
                                                  }}
                                                  onClick={e => {
                                                    handleRemoveRow(formRow.id)
                                                    dispatch(
                                                      handleChangesHappend(true)
                                                    )
                                                  }}
                                                ></i>
                                              ) : (
                                                true
                                              )}
                                              {/* {
                                                formRow.id >= rows1.length ? <i className="mdi mdi-trash-can d-block font-size-24" style={{
                                                  color: "#FF6060",
                                                }}
                                                  onClick={e => {
                                                    handleRemoveRow(formRow.id)
                                                  }}

                                                ></i> : true
                                              } */}
                                            </FormGroup>
                                            {/* <Button
                                              color=""
                                              className="btn-block inner"
                                              id="unknown-btn"
                                              style={{ width: "100%", color: "blue" }}
                                              disabled={
                                                formRow.id > 1 ? false : true
                                              }
                                              onClick={e => {
                                                handleRemoveRow(formRow.id)
                                              }}
                                            >
                                              <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                                            </Button> */}
                                          </Col>
                                        </Row>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <Button
                                disabled={rows1.length < 10 ? false : true}
                                onClick={() => {
                                  handleAddRowNested()
                                }}
                                color="primary"
                                className="mt-1"
                              >
                                Add New
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col md="10"></Col>
                      <Col md="2">
                        <Button
                          color="primary"
                          type="submit"
                          style={{ width: "100%" }}
                          disabled={isDataAdd ? true : false}
                          block
                        >
                          {isDataAdd ? "" : "Save"}
                          {isDataAdd ? (
                            <Spinner type="border" size="sm" />
                          ) : null}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ContentQuick
