
import {
  Row, Col, Card, CardBody, CardTitle, DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown, DropdownItem, Spinner
} from "reactstrap"
import MetaTags from 'react-meta-tags'
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom"
import { getAccountDetails as onGetAccountDetails } from "store/actions"


//redux
import { useSelector, useDispatch } from "react-redux";

import { Offline, Online } from "react-detect-offline";

// actions
import { getAccountData } from "../../store/SuperAdminTable/actions"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import NotFound from "components/Common/NotFound/NotFound.component";
import { SUPER_ADMIN, NETWORK_ERROR } from "config/AlertMessage.config";


const SuperAdminTable = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [isDataAvail, setIsDataAvail] = useState(true)
  const [paginationReset, setPaginationReset] = useState(false)

  const { accountData } = useSelector(state => ({
    accountData: state.SuperAdmin.accountData,
  }));

  /*
  get data
  */
  useEffect(() => {

    !accountData[0] && dispatch(getAccountData());

    // var search_bar = document.querySelector("#search-bar-0")
    // search_bar.setAttribute("autoComplete", "off")
  }, [dispatch]);

  // console.log("Account Data is here..." , accountData)

  useEffect(() => {
    if (accountData.status === 404) {
      setIsDataAvail(false)
    }
    if (accountData[0]) {
      var search_bar = document.querySelector("#search-bar-0")
      search_bar.setAttribute("autoComplete", "off")
      search_bar.addEventListener("focus", () => setPaginationReset(true))

    }
  }, [accountData])

  const MyIcon = (isActive) => {
    return (
      <div style={{
        width: "60%",
        textAlign: "center"
      }} >
        {
          isActive ?
            <i className="bx bxs-user-check" style={{ color: "green", fontSize: "22px" }} ></i>
            :
            <i className="mdi mdi-account-cancel" style={{ color: "red", fontSize: "22px" }} ></i>
        }

      </div>
    )
  }

  const columns = [
    // {
    //   dataField: 'status',
    //   text: '',
    //   sort: false,

    // },
    {
      dataField: 'accounts',
      text: 'Account',
      sort: true
    }, {
      dataField: 'contact',
      text: 'Account Owner',
      sort: true,
    }, {
      dataField: 'scorecardType',
      text: "Scorecard Type",
      sort: false
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true
    }, {
      dataField: 'contactNumber',
      text: 'Phone',
      sort: true
    },
    // {
    // //   dataField: 'scorecardsCreated',
    // //   text: "Scorecard Created",
    // //   sort: true
    // // },
    {
      dataField: "address",
      text: "Address",
      sort: true
    },
    {
      dataField: 'AvailableCredits',
      text: 'Credits',
      sort: true
    },




  ];

  // Table Data
  { /*const AccountData = [
    {
      "id": 1,
      "accounts": "pratik",
      "email": "pratik@example.com",
      "credits": 10,
      "button": <button type = "button">Edit</button>
    }
    ];*/}

  // Function used to return scorecard type of a Account Owner
  const getScorecardType = (DATA) => {
    let scorecardType = "";
    if (DATA && DATA.scorecardVersions) {
      const { scorecardVersions } = DATA;
      if (scorecardVersions.proScorecard && scorecardVersions.selfReportScorecard) {
        scorecardType = "Both";
      } else if (scorecardVersions.proScorecard && !scorecardVersions.selfReportScorecard) {
        scorecardType = "Pro";
      } else if (!scorecardVersions.proScorecard && scorecardVersions.selfReportScorecard) {
        scorecardType = "Self Report";
      }
      return scorecardType;
    } else {
      scorecardType = "Not Available"
      return scorecardType;
    }
  }

  const AccountData = !accountData[0] ? [] : accountData.map((user, id) => {
    return {
      "id": id,
      // "status": MyIcon(!user.isDisabled),
      "accounts": user.accountName,
      "contact": user.contactName,
      "scorecardType": getScorecardType(user),
      "email": user.contactEmail,
      "contactNumber": user.phone,
      "address": user.city,
      // "scorecardsCreated": user.scorecardCreated,
      "AvailableCredits": user.credit,
      "action": user.id,
    }
  })
  // const defaultSorted = [{
  //   dataField: 'accounts',
  //   order: 'asc'
  // }];
  // const defaultSorted = [{
  //   dataField: 'id',
  //   order: 'asc'
  // }];
  const pageOptions = {
    // page:2,
    // sizePerPage: 10,
    onPageChange: () => setPaginationReset(false),
    // totalSize: AccountData.length, // replace later with size(customers),
    custom: true,
  }

  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     history.push("/users/" + row.actions)
  //   }
  // }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (AccountData).length }];


  // Select All Button operation

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/accountowner/${row.action}`)
    }
  }


  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Accounts</title>
        </MetaTags>
        <div className="container-fluid">
          {
            !accountData[0] ?
              <div style={
                {
                  width: "100%",
                  textAlign: "center",
                  height: "400px"
                }
              } >
                {
                  !isDataAvail ?
                    <>
                      {
                        accountData.status === 404 ?
                          <NotFound
                            Message={SUPER_ADMIN.EMPTY_ACCOUNT_OWNERS}
                            Icon={() => (
                              <i className="mdi mdi-account-group"
                                style={{ borderBottom: "1px solid #000" }}
                              ></i>
                            )}
                            Url="/create/account-owner"
                            IsButton
                          />
                          :
                          <NotFound Message={NETWORK_ERROR} Icon={() => (
                            <i className="bx bx-error"
                              style={{ borderBottom: "1px solid #000", fontSize: "40px" }}
                            ></i>
                          )}
                            IsButton
                            btnText="Reload"
                            Reload
                          />
                      }
                    </>
                    :
                    <Spinner
                      style={{
                        width: "60px", height: "60px",
                        margin: "calc(200px - 30px)",

                      }}
                      role="grid"
                      type="border"
                      color="primary"
                    />
                }
              </div>
              :
              <>



                <Row >
                  <Col className="col-12">
                    <Card>
                      <CardBody >
                        <PaginationProvider
                          pagination={
                            paginationReset ?
                              paginationFactory({
                                ...pageOptions,
                                page: 1
                              }
                              ) :
                              paginationFactory(pageOptions)
                          }
                          keyField='id'
                          columns={columns}
                          data={AccountData}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField='id'
                              columns={columns}
                              data={AccountData}
                              search

                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row  >
                                    <Col xl="9" ></Col>
                                    <Col xl="3" >
                                      <div className="search-box me-2 mb-2 d-inline-block" >
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                            onClear={() => setPaginationReset(false)}
                                          // ref={search_ref}
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive" >
                                        <BootstrapTable

                                          // key={"id"}
                                          rowEvents={rowEvents}
                                          keyField={"id"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          // defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap table-hover"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex" style={{ marginTop: 10 }}>
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )
                              }
                            </ToolkitProvider>
                          )
                          }</PaginationProvider>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>}
        </div>
      </div>
    </React.Fragment>
  )
}

export default SuperAdminTable
