

import {
    UPDATE_ACCOUNT_HOLDER_USER,
    UPDATE_ACCOUNT_HOLDER_USER_SUCCESS,
    UPDATE_ACCOUNT_HOLDER_USER_FAIL,
    CLEAR_ACCOUNT_HOLDER_USER,

    // DELETE_ACCOUNT_HOLDER,
    // DELETE_ACCOUNT_HOLDER_SUCCESS,
    // DELETE_ACCOUNT_HOLDER_FAIL,

    
} from "./actionTypes"

export const updateAccountHolderUser = (userId, data) => {
    return {
      type: UPDATE_ACCOUNT_HOLDER_USER,
      userId,
      payload: data
      
    }
  }
  
  export const updateAccountHolderUserSuccess = (data) => {
    return {
      type: UPDATE_ACCOUNT_HOLDER_USER_SUCCESS,
      payload: data
    }
  }
  
  export const updateAccountHolderUserFail = (error) => {
    return {
      type: UPDATE_ACCOUNT_HOLDER_USER_FAIL,
      payload: error
    }
  }
  export const clearUpdateAccountHolderUser = () => {
    return {
      type: CLEAR_ACCOUNT_HOLDER_USER,
    }
  }