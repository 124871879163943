import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import { Switch, BrowserRouter as Router, useParams } from "react-router-dom"
import { connect } from "react-redux"

// firebase
import firebase from 'firebase/compat'
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper"
import ViewChannel from 'pages/ContentEditor/viewChannel'
import Update_tactic from 'pages/ContentEditor/UpdateTactic/Update_tactic'
import TakeASurvey from 'pages/AccountUser/TakeASurvey'


import ChannelFirstPage from 'pages/AccountUser/Report/ChannelFirstPage/ChannelFirstPage.page'
import ChannelTacticEvaluated from 'pages/AccountUser/Report/ChannelTacticEvaluated/ChannelTacticEvaluated.page'
import KeyObservations from 'pages/AccountUser/Report/KeyObservation/KeyObservation.page'
import WhatYouAreDoingWell from 'pages/AccountUser/Report/WhatYouAreDoingWell/WhatYouAreDoingWell.page'
import ChannelRecommendation from 'pages/AccountUser/Report/ChannelRecommendation/ChannelRecommendation.page'
import ContentQuickWins from 'pages/AccountUser/Report/ContentQuickWins/ContentQuickWins.page'

import CompanyInformation from 'pages/AccountUser/Report/CompanyInformation/CompanyInformation.page'

// import fakeBackend from "./helpers/AuthType/fakeBackend"

// // Activating fake backend
// fakeBackend()

// ---------- For i-button POC ---------
// import { initializeApp } from 'firebase/app'
// import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  // databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// init firebase backend
initFirebaseBackend(firebaseConfig)

// ---------- For i-button POC ---------
// const app = initializeApp(firebaseConfig)
// export const db = getFirestore(app)

const App = props => {
  const [ChannelList, setChannelList] = useState([])
  const [TacticList, setTacticList] = useState([])
  const [SelectedChannels, setSelectedChannels] = useState([])
  const [SelectedChannelsForEditReport, setSelectedChannelsForEditReport] = useState([])
  const params = useParams()


  useEffect(() => {

    const channelList = JSON.parse(window.localStorage.getItem("channelList")) ?
      JSON.parse(window.localStorage.getItem("channelList"))
      :
      []
    const channelRoutes = channelList
      .map(channel => ({ path: `/channel-details/${channel.channelId}/:channelId`, component: ViewChannel }))
    setChannelList(channelRoutes)
    const tacticList = channelList
      .map(channel => {
        return channel.tactics
      }).reduce((acc, curVal) => {
        return acc.concat(curVal)
      }, [])

    var tacticRoutes = tacticList.map(tactic =>
      ({ path: `/tactic-details/:channelId/tactics/${tactic.tacticId}/:tacticId`, component: Update_tactic }))
    // const tacticList = tacticRoutes.map(tactics => [...tactics])
    setTacticList(tacticRoutes)
  }, [localStorage.getItem("channelList")])

  useEffect(() => {
    const selectedChannelList = JSON.parse(window.localStorage.getItem("selectedChannels")) ?
      JSON.parse(window.localStorage.getItem("selectedChannels"))
      :
      []
    const tacticList = selectedChannelList
      .map(channel => {
        return channel.tactics
      }).reduce((acc, curVal) => {
        return acc.concat(curVal)
      }, [])

    var selectedChannelRoutes = tacticList
      .map((TACTIC) => (
        { path: `/take-a-survey/:scorecardId/:channelId/:tacticId/${TACTIC.tacticId}`, component: TakeASurvey }
      ))
      
    setSelectedChannels(selectedChannelRoutes)
  }, [localStorage.getItem("selectedChannels")])

  useEffect(() => {
    const selectedChannelList = JSON.parse(window.localStorage.getItem("selectedChannelsForEditReport")) ?
      JSON.parse(window.localStorage.getItem("selectedChannelsForEditReport"))
      :
      []

    var collectedRoutes = selectedChannelList.map((channel) => ([
      { path: `/edit/scorecard-report/:scorecardId/channels/:channelId/${channel.channelId}`, component: ChannelFirstPage },
      { path: `/edit/scorecard-report/:scorecardId/channels/:channelId/${channel.channelId}/tactic-evaluated`, component: ChannelTacticEvaluated },
      { path: `/edit/scorecard-report/:scorecardId/channels/:channelId/${channel.channelId}/key-observations`, component: KeyObservations },
      { path: `/edit/scorecard-report/:scorecardId/channels/:channelId/${channel.channelId}/doing-well`, component: WhatYouAreDoingWell },
      { path: `/edit/scorecard-report/:scorecardId/channels/:channelId/${channel.channelId}/quick-wins`, component: ContentQuickWins },
      { path: `/edit/scorecard-report/:scorecardId/channels/:channelId/${channel.channelId}/recommendations`, component: ChannelRecommendation },
    ]))

    var selectedChannelRoutes = collectedRoutes.reduce((acc, curVal) => {
      return acc.concat(curVal)
    }, [])

    setSelectedChannelsForEditReport(selectedChannelRoutes)

  }, [localStorage.getItem("selectedChannelsForEditReport")])

  // console.log()

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {[...authProtectedRoutes,
          ...ChannelList,
          ...TacticList,
          ...SelectedChannels,
          ...SelectedChannelsForEditReport
          ].map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
