import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState, useCallback, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import { Toast } from "react-bootstrap"
import * as Yup from "yup"
import { setIn, useFormik } from "formik"
// import SweetAlert from "react-bootstrap-sweetalert"

// Essential import statement for POC
import img1 from "../../../../assets/images/ScreenshotsForInstructions/ExecutiveSummaryWithoutEditMode.png"
import img2 from "../../../../assets/images/ScreenshotsForInstructions/instructionForExecutiveSummaryPage.png"
import img3 from "../../../../assets/images/ScreenshotsForInstructions/ExecutiveSummaryPageWithRemovePageOption.png"

import { Link, withRouter, useParams, useHistory } from "react-router-dom"

import {
  getAllInstructionData,
  addNewInstruction,
  updateInstruction,
  handleChangesHappend,
  clearupdateInstruction,
} from "store/actions"

const InstructionForExecutiveSummary = () => {
  const params = useParams()
  const history = useHistory()
  const hiddenFile = useRef()
  const dispatch = useDispatch()

  const [infoForResetPage, setInfoForResetPage] = useState("")
  const [infoForAddPageBtn, setInfoForAddPageBtn] = useState("")
  const [infoForSaveBtn, setInfoForSaveBtn] = useState("")
  const [infoForApplyBtn, setInfoForApplyBtn] = useState("")
  const [infoForUploadImageBtn, setInfoForUploadImageBtn] = useState("")
  const [infoForEditBtn, setInfoForEditBtn] = useState("")
  const [infoForRemovePageBtn, setInfoForRemovePageBtn] = useState("")

  const [saveInfo, setSaveInfo] = useState(false)
  const [alldata, setAllData] = useState({})
  const [toastSuccess, setToastSuccess] = useState(false)
  const [toastFail, setToastFail] = useState(false)
  const [pageData, setPageData] = useState(true)
  const [accountUserInstructionData, setAccountUserInstructionData] =
    useState(null)

  const [openPopoverForResetPageBtn, setOpenPopoverForResetPageBtn] =
    useState(false)
  const [openPopoverForAddPageBtn, setOpenPopoverForAddPageBtn] =
    useState(false)
  const [openPopoverForSavePageBtn, setOpenPopoverForSavePageBtn] =
    useState(false)
  const [openPopoverForApplyPageBtn, setOpenPopoverForApplyPageBtn] =
    useState(false)
  const [openPopoverForUploadImageBtn, setOpenPopoverForUploadImageBtn] =
    useState(false)
  const [openPopoverForEditBtn, setOpenPopoverForEditBtn] = useState(false)
  const [openPopoverForRemovePageBtn, setOpenPopoverForRemovePageBtn] =
    useState(false)

  const {
    getInstructionData,
    addInstructionData,
    isThereChange,
    updateInstructionData,
  } = useSelector(state => ({
    getInstructionData: state.instructionPanelReducer.getInstructionData,
    addInstructionData: state.instructionPanelReducer.addInstructionData,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    updateInstructionData: state.UpdateInstructionReducer.updateInstructionData,
  }))

  useEffect(() => {
    if (!Object.keys(getInstructionData).includes("infoForAccountUser")) {
      dispatch(getAllInstructionData())
    }
  }, [dispatch])

  useEffect(() => {
    if (getInstructionData.infoForAccountUser) {
      var ALL_DATA = {
        ...getInstructionData.infoForAccountUser,
      }
      setAccountUserInstructionData(ALL_DATA)
    }
  }, [getInstructionData])

  useEffect(() => {
    if (accountUserInstructionData) {
      setPageData(false)
      var data = {
        infoForAddPageBtn: accountUserInstructionData.executiveSummary
          .infoForAddPageBtn
          ? accountUserInstructionData.executiveSummary.infoForAddPageBtn
          : "",
        infoForSaveBtn: accountUserInstructionData.executiveSummary
          .infoForSaveBtn
          ? accountUserInstructionData.executiveSummary.infoForSaveBtn
          : "",
        infoForApplyBtn: accountUserInstructionData.executiveSummary
          .infoForApplyBtn
          ? accountUserInstructionData.executiveSummary.infoForApplyBtn
          : "",
        infoForResetPageBtn: accountUserInstructionData.executiveSummary
          .infoForResetPageBtn
          ? accountUserInstructionData.executiveSummary.infoForResetPageBtn
          : "",
        infoForUploadImageBtn: accountUserInstructionData.executiveSummary
          .infoForUploadImageBtn
          ? accountUserInstructionData.executiveSummary.infoForUploadImageBtn
          : "",
        infoForEditBtn: accountUserInstructionData.executiveSummary
          .infoForEditBtn
          ? accountUserInstructionData.executiveSummary.infoForEditBtn
          : "",
        infoForRemovePageBtn: accountUserInstructionData.executiveSummary
          .infoForRemovePageBtn
          ? accountUserInstructionData.executiveSummary.infoForRemovePageBtn
          : "",
      }
      setAllData({ ...data })
      setInfoForResetPage(data.infoForResetPageBtn)
      setInfoForAddPageBtn(data.infoForAddPageBtn)
      setInfoForSaveBtn(data.infoForSaveBtn)
      setInfoForApplyBtn(data.infoForApplyBtn)
      setInfoForUploadImageBtn(data.infoForUploadImageBtn)
      setInfoForEditBtn(data.infoForEditBtn)
      setInfoForRemovePageBtn(data.infoForRemovePageBtn)
    }
  }, [accountUserInstructionData])

  // console.log(accountUserInstructionData)

  // ----- Saving all instruction to the backend ----

  // For Reset Page Button
  const resetPage = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForResetPageBtn}
    >
      <Popover.Header>
        Reset Page
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForResetPageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  executiveSummary: {
                    ...alldata,
                    infoForResetPageBtn: infoForResetPage,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForResetPage(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForResetPage}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForResetPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )

  // For Addpage Button
  const addPageBtn = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForAddPageBtn}
    >
      <Popover.Header>
        Add Page
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForAddPageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  executiveSummary: {
                    ...alldata,
                    infoForAddPageBtn: infoForAddPageBtn,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForAddPageBtn(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForAddPageBtn}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForAddPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )

  // For Save Button
  const saveBtn = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForSavePageBtn}
    >
      <Popover.Header>
        Save Button
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForSavePageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  executiveSummary: {
                    ...alldata,
                    infoForSaveBtn: infoForSaveBtn,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForSaveBtn(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForSaveBtn}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForSavePageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )

  // For Apply Button
  const applyBtn = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForApplyPageBtn}
    >
      <Popover.Header>
        Insert Selected Content
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForApplyPageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  executiveSummary: {
                    ...alldata,
                    infoForApplyBtn: infoForApplyBtn,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForApplyBtn(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForApplyBtn}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForApplyPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )

  // For Upload Image Btn
  const uploadImageBtn = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForUploadImageBtn}
    >
      <Popover.Header>
        Upload Image
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForUploadImageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  executiveSummary: {
                    ...alldata,
                    infoForUploadImageBtn: infoForUploadImageBtn,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForUploadImageBtn(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForUploadImageBtn}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForApplyPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )

  // For Edit Button
  const editBtn = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForEditBtn}
    >
      <Popover.Header>
        Edit Button
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForEditBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  executiveSummary: {
                    ...alldata,
                    infoForEditBtn: infoForEditBtn,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForEditBtn(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForEditBtn}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForApplyPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )

  // For Remove page
  const removePageBtn = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForRemovePageBtn}
    >
      <Popover.Header>
        Edit Button
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForRemovePageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  executiveSummary: {
                    ...alldata,
                    infoForRemovePageBtn: infoForRemovePageBtn,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForRemovePageBtn(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForRemovePageBtn}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForApplyPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )

  useEffect(() => {
    if (updateInstructionData.status === 200) {
      setToastSuccess(true)
      setOpenPopoverForResetPageBtn(false)
      setOpenPopoverForAddPageBtn(false)
      setOpenPopoverForSavePageBtn(false)
      setOpenPopoverForApplyPageBtn(false)
      setOpenPopoverForUploadImageBtn(false)
      setOpenPopoverForEditBtn(false)
      setOpenPopoverForRemovePageBtn(false)
      setSaveInfo(false)
      dispatch(getAllInstructionData())
      dispatch(clearupdateInstruction())
    } else if (updateInstructionData.status === 403) {
      setToastFail(true)
      setOpenPopoverForResetPageBtn(false)
      setOpenPopoverForAddPageBtn(false)
      setOpenPopoverForSavePageBtn(false)
      setOpenPopoverForApplyPageBtn(false)
      setOpenPopoverForUploadImageBtn(false)
      setOpenPopoverForEditBtn(false)
      setOpenPopoverForRemovePageBtn(false)
      setSaveInfo(false)
      dispatch(clearupdateInstruction())
    }
  }, [updateInstructionData])

  return (
    <>
      <div className="page-content">
        {toastSuccess && (
          <div
            className="position-fixed top-0 left-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast
              delay={3000}
              show={true}
              onClose={() => setToastSuccess(false)}
              autohide
            >
              <Toast.Body
                style={{ color: "#fff", height: "55px" }}
                className="font-size-18 bg-primary"
              >
                <i
                  className="mdi mdi-check-circle d-flex"
                  style={{ fontSize: 22 }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      fontStyle: "normal",
                      marginTop: "2px",
                    }}
                    className="mx-3"
                  >
                    Instructions saved successfully.
                  </p>
                </i>
              </Toast.Body>
            </Toast>
          </div>
        )}
        {toastFail && (
          <div
            className="position-fixed top-0 left-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast
              delay={3000}
              show={toastFail}
              onClose={() => setToastFail(false)}
              autohide
            >
              <Toast.Body
                style={{ color: "#fff", height: "55px" }}
                className="font-size-18 bg-danger"
              >
                <i
                  className="mdi mdi-close-circle d-flex"
                  style={{ fontSize: 22 }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      fontStyle: "normal",
                      marginTop: "2px",
                    }}
                    className="mx-3"
                  >
                    Failed to save instructions.
                  </p>
                </i>
              </Toast.Body>
            </Toast>
          </div>
        )}

        <MetaTags>
          <title>Mambo - Instructions For Account User</title>
        </MetaTags>
        {pageData ? (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              height: "400px",
            }}
          >
            <Spinner
              style={{
                width: "60px",
                height: "60px",
                margin: "calc(200px - 30px)",
              }}
              role="grid"
              type="border"
              color="primary"
            />
          </div>
        ) : (
          <Container
            fluid={true}
            className="my-3 justify-content-center"
            style={{
              transform: "scale(.8) translate(-130px, -132px) ",
              width: "1550px",
            }}
          >
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div>
                      <div className="my-3">
                        <h2 className="fw-bold" style={{ color: "#0D0F11" }}>
                          1. Instruction For Executive Summary Page (In Viewer
                          Mode)
                        </h2>
                      </div>
                      <div
                        style={{
                          height: "650px",
                          width: "100%",
                          transform: "scale(1)",
                          backgroundImage: `url(${img1})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <Row>
                          <Col md="8"></Col>
                          <Col md="1">
                            <div>
                              <label
                                type="button"
                                style={{
                                  marginTop: "123px",
                                  marginLeft: "13px",
                                }}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="bottom"
                                  overlay={editBtn}
                                  show={openPopoverForEditBtn}
                                >
                                  <span
                                    className="mdi mdi-information"
                                    onClick={() =>
                                      setOpenPopoverForEditBtn(
                                        !openPopoverForEditBtn
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "22px",
                                      color: "#5015cf",
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: "#fff",
                                    }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div>
                      <div className="mt-5 mb-2">
                        <h2 className="fw-bold" style={{ color: "#0D0F11" }}>
                          2. Instruction For Executive Summary Page (In Edit
                          Mode)
                        </h2>
                      </div>
                      <div
                        style={{
                          height: "650px",
                          width: "100%",
                          transform: "scale(1)",
                          backgroundImage: `url(${img2})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        {/* First Row for i-button */}
                        <Row>
                          <Col xs="3"></Col>
                          <Col xs="1">
                            <div>
                              <label
                                type="button"
                                style={{
                                  marginTop: "83px",
                                  marginLeft: "25px",
                                }}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="bottom"
                                  overlay={resetPage}
                                  show={openPopoverForResetPageBtn}
                                >
                                  <span
                                    className="mdi mdi-information"
                                    onClick={() =>
                                      setOpenPopoverForResetPageBtn(
                                        !openPopoverForResetPageBtn
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "22px",
                                      color: "#5015cf",
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: "#fff",
                                    }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                          </Col>
                          <Col xs="3"></Col>
                          <Col xs="1">
                            <div>
                              <label
                                type="button"
                                style={{
                                  marginTop: "127px",
                                  marginLeft: "-22px",
                                }}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="bottom"
                                  overlay={addPageBtn}
                                  show={openPopoverForAddPageBtn}
                                >
                                  <span
                                    className="mdi mdi-information"
                                    onClick={() =>
                                      setOpenPopoverForAddPageBtn(
                                        !openPopoverForAddPageBtn
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "22px",
                                      color: "#5015cf",
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: "#fff",
                                    }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                          </Col>
                          <Col xs="1">
                            <div>
                              <label
                                type="button"
                                style={{
                                  marginTop: "127px",
                                  marginLeft: "8px",
                                }}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="bottom"
                                  overlay={saveBtn}
                                  show={openPopoverForSavePageBtn}
                                >
                                  <span
                                    className="mdi mdi-information"
                                    onClick={() =>
                                      setOpenPopoverForSavePageBtn(
                                        !openPopoverForSavePageBtn
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "22px",
                                      color: "#5015cf",
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: "#fff",
                                    }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                          </Col>
                          <Col xs="1"></Col>
                          <Col xs="1"></Col>
                          <Col xs="1">
                            <div>
                              <label
                                type="button"
                                style={{
                                  marginTop: "121px",
                                  marginLeft: "-100px",
                                }}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="bottom"
                                  overlay={applyBtn}
                                  show={openPopoverForApplyPageBtn}
                                >
                                  <span
                                    className="mdi mdi-information"
                                    onClick={() =>
                                      setOpenPopoverForApplyPageBtn(
                                        !openPopoverForApplyPageBtn
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "22px",
                                      color: "#5015cf",
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: "#fff",
                                    }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4"></Col>
                          <Col md="1">
                            <div>
                              <label
                                type="button"
                                style={{
                                  marginTop: "414px",
                                  marginLeft: "10px",
                                }}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="top"
                                  overlay={uploadImageBtn}
                                  show={openPopoverForUploadImageBtn}
                                >
                                  <span
                                    className="mdi mdi-information"
                                    onClick={() =>
                                      setOpenPopoverForUploadImageBtn(
                                        !openPopoverForUploadImageBtn
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "22px",
                                      color: "#5015cf",
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: "#fff",
                                    }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    {/* For 3rd option */}
                    <div>
                      <div className="mt-5 mb-2">
                        <h2 className="fw-bold" style={{ color: "#0D0F11" }}>
                          3. Instruction For Executive Summary Page (With Remove
                          Page Option)
                        </h2>
                      </div>
                      <div
                        style={{
                          height: "650px",
                          width: "100%",
                          transform: "scale(1)",
                          backgroundImage: `url(${img3})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <Row>
                          <Col md="7"></Col>
                          <Col md="1">
                            <div>
                              <label
                                type="button"
                                style={{
                                  marginTop: "103px",
                                  marginLeft: "-35px",
                                }}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="bottom"
                                  overlay={removePageBtn}
                                  show={openPopoverForRemovePageBtn}
                                >
                                  <span
                                    className="mdi mdi-information"
                                    onClick={() =>
                                      setOpenPopoverForRemovePageBtn(
                                        !openPopoverForRemovePageBtn
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "22px",
                                      color: "#5015cf",
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: "#fff",
                                    }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  )
}

export default InstructionForExecutiveSummary
