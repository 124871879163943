export const GET_ACCOUNT_TABLE = "GET_ACCOUNT_TABLE"
export const GET_ACCOUNT_TABLE_SUCCESS = "GET_ACCOUNT_TABLE_SUCCESS"
export const GET_ACCOUNT_TABLE_FAIL = "GET_ACCOUNT_TABLE_FAIL"
export const UPDATE_ACCOUNT_TABLE = "UPDATE_ACCOUNT_TABLE"
export const CLEAR_ACCOUNT_DATA_STATE = "CLEAR_ACCOUNT_DATA_STATE"


export const GET_ACCOUNT_DETAILS = "GET_ACCOUNT_DETAILS"
export const GET_ACCOUNT_DETAILS_SUCCESS = "GET_ACCOUNT_DETAILS_SUCCESS"
export const GET_ACCOUNT_DETAILS_FAIL = "GET_ACCOUNT_DETAILS_FAIL"
export const CLEAR_ACCOUNT_OWNER_DETAILS = "CLEAR_ACCOUNT_OWNER_DETAILS"
export const UPDATE_ACCOUNT_DETAILS_STATE = "UPDATE_ACCOUNT_DETAILS_STATE"






