import {
    ADD_USER_SUCCESS,
    ADD_USER_FAIL,
    CLEAR_USER_STATE
} from "./actionTypes"

const INIT_STATE = {
    addUserData: []
}

const AddUserReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
        case ADD_USER_SUCCESS: return {
            ...state,
            addUserData: action.payload,
        }
        case ADD_USER_FAIL: return {
            ...state,
            addUserData: action.payload,
        }
        case CLEAR_USER_STATE: return {
            ...state,
            addUserData: []
        }
        default:
            return state
    }

}

export default AddUserReducer;