import {
    CREATE_REPORT_TEMPLATE_CLEAR, CREATE_REPORT_TEMPLATE_FAIL, CREATE_REPORT_TEMPLATE_SUCCESS,
    GET_REPORT_TEMPLATE_SUCCESS,
    GET_REPORT_TEMPLATE_FAIL,
    UPDATE_GET_REPORT_TEMPLATE
} from "./actionTypes";

const INIT_STATE = {
    createReport: [],
    getReport: {}
}

const createReportReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_REPORT_TEMPLATE_SUCCESS:
            return {
                ...state,
                createReport: action.payload
            }
        case CREATE_REPORT_TEMPLATE_FAIL:
            return {
                ...state,
                createReport: action.payload
            }
        case CREATE_REPORT_TEMPLATE_CLEAR:
            return {
                ...state,
                createReport: []
            }
        case GET_REPORT_TEMPLATE_SUCCESS:
            return {
                ...state,
                getReport: action.payload
            }
        case GET_REPORT_TEMPLATE_FAIL:
            return {
                ...state,
                getReport: action.payload
            }
        case UPDATE_GET_REPORT_TEMPLATE:
            return {
                ...state,
                getReport: action.payload
            }
        default:
            return state
    }
}
export default createReportReducer;