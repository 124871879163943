import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header"
// import accessToken from "./jwt-token-access/accessToken"
var obj = JSON.parse(localStorage.getItem("authUser"))


//pass new generated access token here
const token = !obj ? "" : `Bearer ${obj.stsTokenManager.accessToken}`

//apply base url for axios
const API_URL = `${process.env.REACT_APP_BACKEND_WEB_API_LINK}`

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

// axiosApi.interceptors.response.use((response) => {
//   return response
// }, async function (error) {
//   const originalRequest = error.config;
//   if (error.response.status === 403 && !originalRequest._retry) {
//     originalRequest._retry = true;
//     // const access_token = await refreshAccessToken();            
//     axiosApi.defaults.headers.common['Authorization'] = `Bearer ${obj.stsTokenManager.accessToken}`;
//     return axiosApi(originalRequest);
//   }
//   return Promise.reject(error);
// });


// axiosApi.interceptors.request.use( async (config) => {
//   const obj_token = await JSON.parse(localStorage.getItem("authUser"))

//   config.headers = {
//     Authorization : `Bearer ${obj_token.stsTokenManager.accessToken}`
//   }
//   // console.log(token)
//   return config
//  }, (error) => {
//   return Promise.reject(error);
//  } )


export async function get(url, config = {
  headers: authHeader()
}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {
  headers: authHeader()
}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)

}

export async function put(url, data, config = {
  headers: authHeader()
}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {
  headers: authHeader()
}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export const GET_SURVEY_DATA = "https://jsonplaceholder.typicode.com/posts";