import {
    GET_EDITOR_DETAILS,
    GET_EDITOR_DETAILS_SUCCESS,
    GET_EDITOR_DETAILS_FAIL,
    CLEAR_CONTENT_EDITOR_DETAILS,
    UPDATE_CONTENT_EDITOR_DETAILS_STATE
} from "./actionTypes"


export const getEditorDetails = (id) => ({
    type: GET_EDITOR_DETAILS,
    id,



});
export const getEditorDetailsSuccess = data => ({
    type: GET_EDITOR_DETAILS_SUCCESS,
    payload: data,

});

export const getEditorDetailsFail = error => ({
    type: GET_EDITOR_DETAILS_FAIL,
    payload: error,

})

export const ClearContentEditorDetails = () => {
    return {
        type: CLEAR_CONTENT_EDITOR_DETAILS
    }
}

export const updateContentEditorDetailsState = (data) => {
    return {
        type: UPDATE_CONTENT_EDITOR_DETAILS_STATE,
        payload: data
    }
}