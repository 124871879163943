import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
} from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  createScorecardClear,
  getAccountHolderUsers,
  getChannelData,
  updateScorecardDetailClear,
  ClearAccountOwnerDetails,
  getAccountDetails,
  updateAccountDetailsState,
  getScorecardDetail,
  getScorecardDetailClear,
  IsAccountOwner,
  toggleBuyCreditMenu,
} from "store/actions"
import {
  Form,
  Input,
  InputGroup,
  Label,
  NavItem,
  NavLink,
  Button,
  TabContent,
  TabPane,
  FormFeedback,
  Modal,
  UncontrolledAlert,
  Toast,
  ToastBody,
  ToastHeader
} from "reactstrap"
import Select from "react-select"
import { Country, State } from "country-state-city"

import firebase from "firebase/compat"
import "./Scorecard.css"

//validtions
// Formik validation
import * as Yup from "yup"
import { useFormik, yupToFormErrors } from "formik"
import { Link } from "react-router-dom"
import {
  createScorecard,
  getScorecardsList,
  getScorecardReport,
  updateScorecardDetail,
  sendScorecardCreationReminder,
  clearSendScorecardCreationReminder
} from "store/actions"
import {
  ANNUAL_REVENUE,
  INDUSTRY,
  NUMBER_OF_EMPLOYEES,
  OWNERSHIP,
} from "./scorecardconfig/scorecardconfig"
import SweetAlert from "react-bootstrap-sweetalert"
import ScorecardCreatedIcon from "../../../assets/images/NewPopupIcons/Scorecard created.png"
import ScorecardCreationFaildIcon from "../../../assets/images/NewPopupIcons/FailedIcon.png"
import NoCredit from "../../../assets/images/NewPopupIcons/NoCredit.png"
import ContactMamboMediaIcon from "../../../assets/images/NewPopupIcons/ContactMamboMediaIcon.png"
import { channelSelectionLimit } from "config/UserCreationLimit_config"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const NewScorecard = () => {
  const [countries, setCountries] = useState(null)
  const [successMsg, setSuccessMsg] = useState(false)
  const history = useHistory()
  const [selectedMulti, setselectedMulti] = useState([])
  const [selectedCheckbox, setSelectedCheckbox] = useState([])

  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [modal_backdrop_message, setmodal_backdrop_message] = useState(false)
  const [isInsufficientPermission, setIsInsufficientPermission] = useState({
    forProScorecard: false,
    forSelfReportScorecard: false
  })

  const [zeroCredit, setZeroCredit] = useState(false)

  const [isUserAssigned, setIsUserAssigned] = useState(false)

  const [isChannelSelected, setIsChannelSelected] = useState(false)
  const [data, setData] = useState({})

  const [userOrChannelNotSelected, setUserOrChannelNotSelected] =
    useState(false)
  const [exceedChannelSelectionLimit, setExceedChannelSelectionLimit] = useState(false)
  const [createdScorecardId, setCreatedScorecardId] = useState("")
  const [activateScorecardId, setActivateScorecardId] = useState(false)

  const [assigneeSelected, setAssigneeSelected] = useState(false)
  const [showAssigneeSelectionMessage, setShowAssigneeSelectionMessage] = useState(false)

  const [scorecardCreationMailOptions, setScorecardCreationMailOptions] = useState({})

  const [accountOwnerMessage, setAccountOwnerMessage] = useState("")
  const [message, setMessage] = useState("")

  const [toastForScorecardName, setToastForScorecardName] = useState(false)

  const [messageSaveClick, setMessageSaveClick] = useState(false)

  // State to display users name and their email id's for popup
  const [selectedUsersDetailsForPopop, setSelectedUsersDetailsForPopop] = useState([])
  // State to set popup text count limit (Max 150 characters)
  const [popupTextCountLimit, setPopupTextCountLimit] = useState(0)

  const ValidationStyle = isUserAssigned
    ? {
      display: "block",
      borderRadius: "4px",
      border: "1px solid #f46a6a",
      boxShadow: "0 0 2px 2px #fcdada",
      // overflow: "hidden",
      position: "relative",
      zIndex: 10,
      // border: '1px solid red'
    }
    : {}

  
  // Popup configuration
  const CommonStyleForPopup = {
    position: 'top-right',
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    theme: 'colored',
  }
  // Saved message text for toast notification.
  const PopupMessageTextForSave = "Message Saved. It will automatically sent to recipient after the scorecard is created."
  // Scorecard name warning message text for toast notification.
  const PopupMessageTextForScorecardName = "Please enter scorecard name"

  const [toggleColor, setToggleColor] = useState(false)
  const dispatch = useDispatch()

  const [isUserSelected, setIsUserSelected] = useState(false)
  const {
    channelData,
    accountHolderUsers,
    scorecardData,
    scorecardsList,
    scorecardDetail,
    updatedScorecardDetail,
    accountDetails,
    scorecardReport_data
  } = useSelector(state => ({
    scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
    channelData: state.GetChannelData.channelData,
    accountHolderUsers: state.AccountHolderUsersReducer.accountHolderUsers,
    scorecardData: state.GetScorecardsListReducer.scorecardData,
    scorecardsList: state.GetScorecardsListReducer.scorecardsList,
    updatedScorecardDetail:
      state.GetScorecardsListReducer.updatedScorecardDetail,
    accountDetails: state.SuperAdmin.accountDetails,
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
  }))
  const [selectedChannelList, setSelectedChannelList] = useState([])

  useEffect(() => {
    dispatch(getChannelData())
    dispatch(getScorecardDetail(params.scorecardId))
    dispatch(
      getAccountHolderUsers(JSON.parse(localStorage.getItem("authUser")).uid)
    )
    if (!scorecardsList[0]) {
      dispatch(
        getScorecardsList(JSON.parse(localStorage.getItem("authUser")).uid)
      )
    }
    // setChannel(channelData)
  }, [dispatch])

  useEffect(() => {
    // if (data.admin) {
    dispatch(
      getAccountDetails(JSON.parse(localStorage.getItem("authUser")).uid)
    )
    // }
  }, [dispatch])

  useEffect(() => {
    if (accountDetails && accountDetails.scorecardVersions) {
      const { scorecardVersions } = accountDetails;

      if (
        location.pathname.includes("/self-report") && // For Self Report Scorecard
        !params.scorecardId &&
        !scorecardVersions.selfReportScorecard
      ) {
        setIsInsufficientPermission({
          forProScorecard: false,
          forSelfReportScorecard: true
        })
      } else if (
        !location.pathname.includes("/self-report") && // For Pro Scorecard
        !params.scorecardId &&
        !scorecardVersions.proScorecard
      ) {
        setIsInsufficientPermission({
          forProScorecard: true,
          forSelfReportScorecard: false
        })
      } else {
        setIsInsufficientPermission({
          forProScorecard: false,
          forSelfReportScorecard: false
        })
      }
    }
  }, [accountDetails])

  useEffect(() => {
    if (
      location.pathname.includes("/self-report") &&
      accountDetails.scorecardVersions &&
      accountDetails.scorecardVersions.selfReportScorecard &&
      accountDetails.credit <= 0 &&
      !params.scorecardId
    ) {
      setmodal_backdrop(true)
      // console.log("Self Report")
    } else if (
      !location.pathname.includes("/self-report") &&
      accountDetails.scorecardVersions &&
      accountDetails.scorecardVersions.proScorecard &&
      accountDetails.credit <= 1 &&
      !params.scorecardId
    ) {
      setmodal_backdrop(true)
      // console.log("Pro Report")
    }
  }, [accountDetails])

  useEffect(() => {
    dispatch(updateScorecardDetailClear())
  }, [])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user
          .getIdTokenResult()
          .then(token => {
            setData(token.claims)
          })
          .catch(err => { })
      }
    })

    return () => {
      setData(null)
    }
  }, [])

  const params = useParams()

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  function tog_backdrop_message() {
    setmodal_backdrop_message(!modal_backdrop_message)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const ColorStyle = !params.scorecardId
    ? {
      color: "#0D0F11",
    }
    : { color: "#05727B" }

  //Additional States for handleing custom requests:
  const [isUserAdded, setIsUserAdded] = useState(false)
  // validations
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      scorecardName:
        "" || (params.scorecardId && scorecardDetail.scorecardName),
      companyName: "" || (params.scorecardId && scorecardDetail.companyName),
      contactName: "" || (params.scorecardId && scorecardDetail.contactName),
      phone: "" || (params.scorecardId && scorecardDetail.phone),
      email: "" || (params.scorecardId && scorecardDetail.email),
      companyAddress1:
        "" || (params.scorecardId && scorecardDetail.companyAddress1),
      companyAddress2:
        "" || (params.scorecardId && scorecardDetail.companyAddress2),
      annualRevenue:
        "" || (params.scorecardId && scorecardDetail.annualRevenue),
      country:
        "" ||
        (params.scorecardId && scorecardDetail.country) ||
        "United States",
      state: "" || (params.scorecardId && scorecardDetail.state),
      numberOfEmployee:
        "" || (params.scorecardId && scorecardDetail.numberOfEmployee),
      industry: "" || (params.scorecardId && scorecardDetail.industry),
      websiteUrl: "" || (params.scorecardId && scorecardDetail.websiteUrl),
      ownership: "" || (params.scorecardId && scorecardDetail.ownership),
      clientAttribute1:
        "" || (params.scorecardId && scorecardDetail.clientAttribute1),
      clientAttribute2:
        "" || (params.scorecardId && scorecardDetail.clientAttribute2),
      clientAttribute3:
        "" || (params.scorecardId && scorecardDetail.clientAttribute3),
      clientAttribute4:
        "" || (params.scorecardId && scorecardDetail.clientAttribute4),
      countryCode:
        "" || (params.scorecardId && scorecardDetail.countryCode) || "1",
      assignedTo: "" || (params.scorecardId && scorecardDetail.assignedTo),
      selectedChannels:
        "" || (params.scorecardId && scorecardDetail.selectedChannels),
    },

    validationSchema: Yup.object({
      scorecardName: Yup.string().required("Please enter scorecard name"),

      email: Yup.string().email("Must be valid email"),
      phone: Yup.string()
        .max(10, "Phone number must be atleast 10 digits")
        .min(10, "Phone number must be atleast 10 digits"),
    }),



    onSubmit: values => {

      if (!selectedChannelList[0]) setIsChannelSelected(true)

      if (selectedChannelList[0]) {
        selectedChannelList.sort((a, b) => {
          return channelData.findIndex(channel => channel.channelName === a.channelName) - channelData.findIndex(ch => ch.channelName === b.channelName)
        })
      }

      if (
        !params.scorecardId &&
        !accountDetails.credit <= 1 &&
        selectedChannelList[0]
      ) {
        if (selectedMulti && !checkScorecardUniqueName()) {
          setSuccessMsg(true)
          let scdata = {
            ...values,
            accountId: JSON.parse(localStorage.getItem("authUser")).uid,
            creatorId: JSON.parse(localStorage.getItem("authUser")).uid,
            creatorName:
              JSON.parse(localStorage.getItem("authUser")).providerData[0]
                .displayName || "",
            creatorEmail:
              JSON.parse(localStorage.getItem("authUser")).email,
            lastUpdatedOn: {
              _miliseconds: 0,
              _seconds: new Date().getTime() / 1000,
            },
            createdOn: {
              _miliseconds: 0,
              _seconds: new Date().getTime() / 1000,
            },
            status: "Not started",
            assignedTo: selectedMulti,
            selectedChannels: selectedChannelList,
            scorecardVersion: location.pathname.includes("/self-report") ?
              "Self Report" : "Pro",
            accountOwnerMessage: message
          }
          // console.log("Scorecard Data selectedMulti...", scdata)

          let mailObject = {
            accountOwnerEmailId: scdata.creatorEmail,
            accountUsersNames: scdata.assignedTo.map(e => {
              return e.accountUserContactName
            }).filter(e => e != undefined),
            accountUsersEmailIds: scdata.assignedTo.map(e => {
              return e.label.split('(')[1].split(')')[0]
            }).filter(e => e != undefined),
            accountOwnerMessage: message,
            envLink: `${process.env.REACT_APP_FRONTEND_LINK}`,
          }

          setScorecardCreationMailOptions(e => {
            return {
              ...e,
              ...mailObject
            }
          })

          dispatch(createScorecard({ ...scdata }))

        }
      } else {
        setSuccessMsg(true)
        if (params.scorecardId) {
          dispatch(
            updateScorecardDetail(params.scorecardId, {
              ...values,
              lastUpdatedOn: {
                _miliseconds: 0,
                _seconds: new Date().getTime() / 1000,
              },
            })
          )
          // console.log(values)
        } else {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
          setSuccessMsg(false)
        }
      }
      // }
    },

    onReset: () => {
      if (!params.scorecardId) {
        setselectedMulti([])
        var allCheckbox = document.querySelectorAll(".ChannelList")
        allCheckbox.forEach(checkbox => {
          checkbox.checked = false
        })
        setSelectedChannelList([])
        setIsChannelSelected(false)
      } else {
        // history.push("/view/scorecard/" + params.scorecardId)
      }
    },
  })
  //console.log(selectedChannelList)
  // console.log("accountHolderUsers...", accountHolderUsers)

  const getSelectedUsersList = () => {
    const optionGroup = accountHolderUsers[0]
      ? accountHolderUsers
        .filter(user => user.isDisabled === false)
        .map(user => {
          return {
            label: `${user.contactName} (${user.contactEmail})`,
            value: user.id,
            accountUserContactName: user.contactName,
            accountUserId: user.id,
            address: user.address,
          }
        })
      : []

    return [
      {
        options: [...optionGroup],
      },
    ]
  }

  // Get user names and email id's to display in a popup
  const showUserDetails = (usersArr) => {
    const allUsersDetails = [...usersArr]
      const usersNamesAndEmailIds = 
        allUsersDetails.map(e => {
          return e.label
        }).filter(e => e != undefined)

      if (usersNamesAndEmailIds) {
        // console.log("Name & Email: ", usersNamesAndEmailIds)
        setSelectedUsersDetailsForPopop(usersNamesAndEmailIds)
      }
  }

  // useEffect to run a function whenever user get's changed
  useEffect(() => {
    if (selectedMulti && selectedMulti[0] && location.pathname.includes("/self-report")) {
      showUserDetails(selectedMulti)
    } else {
      setSelectedUsersDetailsForPopop([])
    }
  }, [selectedMulti])
  // console.log("Users List...",getSelectedUsersList())

  // useEffect(() => {
  //   setCountries(Country.getAllCountries())
  // }, [])

  useEffect(() => {
    var countries_ = [...Country.getAllCountries()].map(country => {
      if (country.isoCode === "CA") {
        return {
          ...country,
          phonecode: "001",
        }
      } else if (country.isoCode === "UM") {
        return {
          ...country,
          phonecode: "01",
        }
      } else {
        return country
      }
    })

    // console.log(countries_)
    setCountries(countries_)
  }, [])

  const GetCountryByCode = () => {
    if (countries) {
      var country = countries.filter(
        country => country.name === validation.values.country
      )
      return State.getStatesOfCountry(country[0].isoCode)
    }
  }

  const checkScorecardUniqueName = () => {
    var isNameAvailable = scorecardsList[0]
      ? scorecardsList.map(scorecard => scorecard.scorecardName)
      : []
    var isIndexAvailable = isNameAvailable.indexOf(
      validation.values.scorecardName
    )
    if (isIndexAvailable >= 0 || validation.values.scorecardName === "") {
      return true
    } else {
      return false
    }
    // return IsNameAvailable.indexOf(validation.values.scorecardName)
  }
  const formMandatory = {
    color: "red",
    fontWeight: "bold",
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user
          .getIdTokenResult()
          .then(token => {
            dispatch(IsAccountOwner(token.claims.admin))
          })
          .catch(err => { })
      }
    })
  }, [])

  useEffect(() => {
    if (scorecardData.status === 201) {
      var SCORECARD_ID = scorecardData["data"]["_path"]["segments"][1]
      // console.log("Scorecard ID..", SCORECARD_ID)
      setCreatedScorecardId(SCORECARD_ID)
    }
  }, [scorecardData])

  useEffect(() => {
    if (Object.keys(scorecardDetail).includes("selectedChannels")) {
      window.localStorage.setItem(
        "selectedChannels",
        JSON.stringify(
          scorecardDetail.selectedChannels.map(channel => ({
            channelId: channel.channelId,
            channelName: channel.channelName,
            tactics: channel.tactics.map(tactic => ({
              ...tactic,
              tacticName: tactic.tacticName,
              tacticId: tactic.tacticId,

            })),
          }))
        )
      )
    }
  }, [scorecardDetail])

  useEffect(() => {
    if (activateScorecardId) {
      dispatch(
        updateScorecardDetail(
          createdScorecardId,
          {
            activeUser: {
              userName: JSON.parse(
                localStorage.getItem(
                  "authUser"
                )
              ).displayName,
              userId: JSON.parse(
                localStorage.getItem(
                  "authUser"
                )
              ).uid,
              isActive: true,
            },
            lastUpdatedOn: {
              _miliseconds: 0,
              _seconds: new Date().getTime() / 1000,
            },
            status: "In progress",
            scorecardVersion: location.pathname.includes("/self-report") ?
              "Self Report" : "Pro",
          }
        )
      )
    }
  }, [scorecardDetail])

  useEffect(() => {
    if (activateScorecardId && updatedScorecardDetail.status === 200) {
      var channelId = scorecardDetail.selectedChannels[0].channelId
      var tacticId = scorecardDetail.selectedChannels[0].tactics[0].tacticId
      history.push(
        `/take-a-survey/${createdScorecardId}/${channelId}/${tacticId}/${tacticId}`
      )
      window.location.reload()
      dispatch(
        updateAccountDetailsState({
          ...accountDetails,
          credit: accountDetails.credit - 2,
        })
      )
      setActivateScorecardId(false)
      dispatch(createScorecardClear())
    }
  }, [updatedScorecardDetail])

  useEffect(() => {
    !accountHolderUsers[0] &&
      dispatch(
        getAccountHolderUsers(JSON.parse(localStorage.getItem("authUser")).uid)
      )

    if (scorecardReport_data.id !== createdScorecardId) {
      dispatch(getScorecardReport(createdScorecardId))
    }

    return () => {
      dispatch(getScorecardDetailClear())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(updateScorecardDetailClear())
  }, [])

  useEffect(() => {
    if (channelData && channelData[0]) {
      const selectAllChannels = channelData
        .filter(channel => !channel.isDisabled)
        .filter(
          channel_ =>
            channel_.tactics[0] !== undefined
        )
        .slice(0, 15)
        .map((channel, index) => channel)

      // console.log("For Select all Channels: ", selectAllChannels)
      setSelectedChannelList([...selectAllChannels])
      var allCheckbox = document.querySelectorAll(".ChannelList")
      const slicedElement = [...allCheckbox]
      slicedElement.slice(0, 15).forEach(checkbox => {
        checkbox.checked = true
      })
    }
  }, [channelData])


  const handleSelfReportRedirection = () => {
    dispatch(sendScorecardCreationReminder({ ...scorecardCreationMailOptions }))
    history.push("/view/scorecards")
    setSuccessMsg(false)
    dispatch(createScorecardClear())
    validation.handleReset()
  }

  // Added By Mohanish
  useEffect(() => {
    let timeOutForRedirect;
    if (scorecardData && scorecardData.status === 201 && location.pathname.includes("/self-report")) {
      timeOutForRedirect = setTimeout(handleSelfReportRedirection, 4000);
    }
    // Cleanup function
    return () => clearTimeout(timeOutForRedirect);
  })


  const notifyScorecardNameRequire = () =>
    toast.info(PopupMessageTextForScorecardName, {
      ...CommonStyleForPopup,
      progressStyle: { background: '#fff' },
      style: { background: '#CB6928' },
    });

  // const notifyMessageSave = () =>
  //   toast.success(PopupMessageTextForSave, {
  //     ...CommonStyleForPopup,
  //     progressStyle: { background: '#fff' },
  //     delay: 1000,
  //     style: { background: '#05727B' },
  //   })

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {params.scorecardId
              ? "Mambo - Update Client Information"
              : "Mambo - Create New Scorecard"}
          </title>
        </MetaTags>

        <Container fluid={true}>
          {updatedScorecardDetail.status === 200 && (
            <SweetAlert
              style={{ height: "270px", width: "250px" }}
              custom
              customIcon={
                <Row>
                  <Col md="12" className="mb-3">
                    <img
                      src={ScorecardCreatedIcon}
                      style={{
                        height: "70px",
                        width: "75px",
                      }}
                    />
                  </Col>
                  <Col
                    md="12"
                    className="text-center container-fluid"
                    style={{
                      color: "#0D0F11",
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "28px",
                    }}
                  >
                    Changes updated successfully.
                  </Col>
                </Row>
              }
              customButtons={
                <div className="container-fluid">
                  <Button
                    block
                    size="sm"
                    style={{
                      background: "#0D0F11",
                      border: "#0D0F11",
                    }}
                    onClick={() => {
                      setSuccessMsg(false)
                      dispatch(updateScorecardDetailClear())
                      // history.push("/view/scorecard/" + params.scorecardId)
                      history.goBack()
                    }}
                  >
                    Close
                  </Button>
                </div>
              }
            ></SweetAlert>
          )}

          {updatedScorecardDetail.status === 403 && (
            <SweetAlert
              style={{ height: "230px", width: "250px" }}
              custom
              customIcon={
                <Row>
                  <Col md="12" className="mb-3">
                    <img
                      src={ScorecardCreationFaildIcon}
                      style={{
                        height: "65px",
                        width: "65px",
                      }}
                    />
                  </Col>
                  <Col
                    md="12"
                    className="text-center container-fluid"
                    style={{
                      color: "#0D0F11",
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "25px",
                    }}
                  >
                    Failed to save data.
                  </Col>
                </Row>
              }
              customButtons={
                <div className="container-fluid">
                  <Button
                    block
                    size="sm"
                    style={{
                      background: "#0D0F11",
                      border: "#0D0F11",
                    }}
                    onClick={() => {
                      setSuccessMsg(false)
                      dispatch(updateScorecardDetailClear())
                    }}
                  >
                    Close
                  </Button>
                </div>
              }
            ></SweetAlert>
          )}

          {scorecardData.status === 201 && (
            <SweetAlert
              style={{ height: "270px", width: "250px" }}
              custom
              customIcon={
                <Row>
                  <Col md="12" className="mb-2 mt-1">
                    <img
                      src={ScorecardCreatedIcon}
                      style={{
                        height: "80px",
                        width: "85px",
                      }}
                    />
                  </Col>
                  <Col
                    md="12"
                    className="text-center container-fluid"
                    style={{
                      color: "#0D0F11",
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "25px",
                    }}
                  >
                    Scorecard created successfully.
                  </Col>
                </Row>
              }

              // customButtons={<div className="container-fluid"></div>}
              customButtons={location.pathname.includes("/self-report") ?
                <div className="container-fluid"></div>
                :
                <div className="container-fluid" style={{ marginTop: "-15px" }}>
                  <Button
                    block
                    size="sm"
                    className="my-2"
                    style={{
                      background: "#0D0F11",
                      border: "#0D0F11",
                    }}
                    disabled={activateScorecardId}
                    onClick={() => {
                      setSuccessMsg(false)
                      setActivateScorecardId(true)
                      dispatch(getScorecardDetail(createdScorecardId))
                      validation.handleReset()
                    }}
                  >
                    {activateScorecardId ? "" : "Start Assessment"}
                    {activateScorecardId ? (
                      <Spinner type="border" size="sm" />
                    ) : null}
                  </Button>
                  <Button
                    block
                    size="sm"
                    className="my-2"
                    style={{
                      background: "#E7EAEE",
                      border: "#E7EAEE",
                    }}
                    onClick={() => {
                      dispatch(
                        updateAccountDetailsState({
                          ...accountDetails,
                          credit: accountDetails.credit - 2,
                        })
                      )
                      history.push("/view/scorecards")
                      setSuccessMsg(false)
                      dispatch(createScorecardClear())
                      validation.handleReset()
                    }}
                  >
                    <p className="my-0 p-0 text-dark">Close</p>
                  </Button>
                </div>
              }
            >

            </SweetAlert>
          )}

          {
            (isInsufficientPermission.forProScorecard ||
              isInsufficientPermission.forSelfReportScorecard
            ) && (
              <SweetAlert
                style={{ height: "270px", width: "250px" }}
                custom
                customIcon={
                  <Row>
                    <Col md="12" className="mb-2 mt-1">
                      <img
                        src={ContactMamboMediaIcon}
                        style={{
                          height: "80px",
                          width: "85px",
                        }}
                      />
                    </Col>
                    <Col
                      md="12"
                      className="text-center container-fluid"
                      style={{
                        color: "#0D0F11",
                        fontWeight: 600,
                        fontSize: "24px",
                        lineHeight: "30px",
                      }}
                    >
                      Contact Mambo Media to enable this function
                    </Col>
                  </Row>
                }

                customButtons={
                  <div className="container-fluid" style={{ marginTop: "-15px" }}>
                    <Button
                      block
                      size="sm"
                      className="my-2"
                      style={{
                        background: "#0D0F11",
                        border: "#0D0F11",
                      }}
                      onClick={() => {
                        history.push("/dashboard")
                      }}
                    >
                      <p className="my-0 p-0 text-white">Close</p>
                    </Button>
                  </div>
                }
              >

              </SweetAlert>
            )}

          {scorecardData.status === 403 && (
            <SweetAlert
              style={{ height: "230px", width: "250px" }}
              custom
              customIcon={
                <Row>
                  <Col md="12" className="mb-3">
                    <img
                      src={ScorecardCreationFaildIcon}
                      style={{
                        height: "65px",
                        width: "65px",
                      }}
                    />
                  </Col>
                  <Col
                    md="12"
                    className="text-center container-fluid"
                    style={{
                      color: "#0D0F11",
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "25px",
                    }}
                  >
                    Failed to create Scorecard.
                  </Col>
                </Row>
              }
              customButtons={
                <div className="container-fluid">
                  <Button
                    block
                    size="sm"
                    style={{
                      background: "#0D0F11",
                      border: "#0D0F11",
                    }}
                    onClick={() => {
                      setSuccessMsg(false)
                      dispatch(createScorecardClear())
                    }}
                  >
                    Close
                  </Button>
                </div>
              }
            ></SweetAlert>
          )}

          {exceedChannelSelectionLimit && (
            <SweetAlert
              style={{ height: "270px", width: "250px" }}
              custom
              customIcon={
                <Row>
                  <Col md="12" className="mb-3">
                    <img
                      src={ScorecardCreationFaildIcon}
                      style={{
                        height: "65px",
                        width: "65px",
                      }}
                    />
                  </Col>
                  <Col
                    md="12"
                    className="text-center container-fluid"
                    style={{
                      color: "#0D0F11",
                      fontWeight: 600,
                      fontSize: "19px",
                      // lineHeight:'25px',
                    }}
                  >
                    You can select maximum 15 Channels to create Scorecard.
                  </Col>
                </Row>
              }
              customButtons={
                <div className="container-fluid">
                  <Button
                    block
                    size="sm"
                    style={{
                      background: "#0D0F11",
                      border: "#0D0F11",
                    }}
                    onClick={() => {
                      setExceedChannelSelectionLimit(false)
                    }}
                  >
                    Close
                  </Button>
                </div>
              }
            ></SweetAlert>
          )}

          <ToastContainer pauseOnFocusLoss={false}/>

          <Row style={{ color: "#0D0F11" }}>
            <Col xl="12" style={{ margin: "auto" }}>
              <Card>
                <CardBody>
                  <Modal
                    isOpen={modal_backdrop}
                    toggle={() => {
                      tog_backdrop()
                    }}
                    style={{ width: "370px", height: "270px" }}
                    centered
                    backdrop={"static"}
                    keyboard={false}
                    id="staticBackdrop"
                  >
                    <Row>
                      <Col md="12" className="text-center mt-4">
                        <img
                          src={ScorecardCreationFaildIcon}
                          style={{
                            height: "75px",
                            width: "75px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="modal-body">
                      <Col
                        md="12"
                        className="text-center"
                        style={{
                          color: "#0D0F11",
                          fontWeight: 600,
                          fontSize: "24px",
                          // lineHeight:'28px',
                        }}
                      >
                        <p
                          className="my-0 p-0"
                          style={{
                            color: "#0D0F11",
                            fontWeight: 600,
                            fontSize: "24px",
                            lineHeight: "30px",
                          }}
                        >
                          You cannot start a new Scorecard because you have no credits.
                          Buy some credits and try again
                        </p>
                      </Col>
                      <Col md="12" className="mt-1">
                        <Button
                          block
                          size="sm"
                          className="my-2"
                          type="button"
                          style={{
                            background: "#0D0F11",
                            border: "#0D0F11",
                          }}
                          onClick={() => {
                            history.push("/dashboard")
                            dispatch(toggleBuyCreditMenu(true))
                          }}
                        >
                          Buy Credits
                        </Button>
                      </Col>
                    </Row>
                  </Modal>

                  <Modal
                    isOpen={modal_backdrop_message}
                    toggle={() => {
                      tog_backdrop_message();
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                  >
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">Welcome Message</h5>
                      <button type="button" className="btn-close"
                        onClick={() => {
                          setmodal_backdrop_message(false);
                          setMessageSaveClick(false)
                        }}
                        aria-label="Close">
                      </button>
                    </div>
                    <div className="modal-body">
                      {
                        selectedUsersDetailsForPopop[0] ? (
                          <div>
                            <p>
                              The following email message will be sent to {" "}
                              {selectedUsersDetailsForPopop.toString().split(",").join(", ")}
                            </p>
                            <div className="p-1" style={{
                              border: "1px solid #d6d6cd",
                              borderRadius: "5px"
                            }}>
                              <p className="my-1">
                                Hello {"<user name>"}!
                              </p>
                              <p className="mb-1 mt-0">
                                You have been asked to complete a Modern Marketing Scorecard assessment.
                              </p>
                              <p className="my-1">
                                Please go to {" "}
                                <a 
                                  href={`${process.env.REACT_APP_FRONTEND_LINK}`} 
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {`${process.env.REACT_APP_FRONTEND_LINK}`}
                                </a>
                              </p>
                              <p className="my-1">
                                Your username is {"<user email address>"}
                              </p>
                              <p className="my-1">
                                Click on Create Password and follow the prompts to log in
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div >
                            <p>
                              The following email message will be sent to {"<user name>"} at {"<user email address>"}
                            </p>
                            <div className="p-1" style={{
                              border: "1px solid #d6d6cd",
                              borderRadius: "5px"
                            }}>
                              <p className="my-1">
                                Hello!
                              </p>
                              <p className="mb-1 mt-0">
                                You have been asked to complete a Modern Marketing Scorecard assessment.
                              </p>
                              <p className="my-1">
                                Please go to {" "}<a href="https://modernmarketingscorecard.com/">modernmarketingscorecard.com</a>
                              </p>
                              <p className="my-1">
                                Your username is {"<user email address>"}
                              </p>
                              <p className="my-1">
                                Click on Create Password and follow the prompts to log in
                              </p>
                            </div>
                          </div>  
                        )
                      }
                      
                      <div className="mt-2">
                        <label className="my-1">To add a custom message, enter it below (150 character max)</label>
                        <Input
                          type="textarea"
                          id="popup-custom-input"
                          maxLength={150}
                          style={{ resize: 'none' }}
                          onChange={(e) => {
                            setPopupTextCountLimit(e.target.value.length)
                            setAccountOwnerMessage(e.target.value)
                          }}
                          placeholder="Anything specific you would like to convey to the user."
                          value={accountOwnerMessage}
                        />
                        {!accountOwnerMessage && messageSaveClick && 
                          <span 
                            style={{ 
                              color: "red", 
                              fontSize: '11px' 
                            }}
                          >
                            Please enter the message
                          </span>
                        }
                          <span
                            className="badgecount badge bg-primary"
                            style={{
                              float: "right",
                              marginTop: "5px",
                            }}
                            >
                              {popupTextCountLimit} / 150
                          </span>
                          <div>
                            <label className="my-1 fw-bold" style={{color: "#000000", fontSize: '11px'}}>
                              Note: This message will be sent once the scorecard is created.
                            </label>
                          </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <Button
                        className="btn btn-light"
                        onClick={() => {
                          setmodal_backdrop_message(false);
                          setMessageSaveClick(false)
                        }}
                        style={{
                          backgroundColor: '#000000',
                          boxShadow: 'none',
                          borderColor: '#000000'
                        }}
                      // color="#D66F35"  AD2423
                      >
                        Close
                      </Button>
                      <Button
                        className="btn btn-primary"
                        // color="#0F838C"
                        onClick={() => {
                          setMessageSaveClick(true)
                          if (accountOwnerMessage) {
                            setMessage(accountOwnerMessage)
                            setmodal_backdrop_message(false);
                            // notifyMessageSave()
                          }
                        }}
                        style={{
                          backgroundColor: '#0F838C',
                          boxShadow: 'none',
                          borderColor: '#0F838C'
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Modal>

                  <Form
                    className="needs-validation outer-repeater"
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="9" style={{ display: "flex" }}>
                        <h2 className="fw-bold" style={{ color: "#0D0F11" }}>
                          {`${!params.scorecardId
                            ?
                            'New ' + (location.pathname.includes("/self-report") ?
                              "Self Report" : "Pro") + ' Scorecard'  //"New Scorecard"
                            :
                            "Update " + (location.pathname.includes("/self-report") ?
                              "Self Report" : "Pro") + " Scorecard Details"
                            }`}
                        </h2>
                      </Col>
                      {!params.scorecardId || (params.scorecardId && (location.pathname.includes("/self-report"))) && (
                        <Col md="3">
                          {
                            <p
                              className="mx-3"
                              style={{
                                marginTop: "5px",
                                fontWeight: "bold",
                                color: "#0D0F11",
                                float: "right",
                              }}
                            >
                              (&nbsp;<span style={formMandatory}>*</span>
                              Indicates required field)
                            </p>
                          }
                        </Col>
                      )}
                    </Row>

                    <Row>
                      <Col xs="3">
                        {!params.scorecardId ? (
                          <FormGroup className="mb-3">
                            <span style={formMandatory}>*</span>

                            <label className="fw-bold">Scorecard Name:</label>

                            <Input
                              name="scorecardName"
                              placeholder="Required"
                              type="text"
                              className="form-control"
                              id="txtscorecardName"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.scorecardName || ""}
                              invalid={
                                (validation.touched.scorecardName &&
                                  checkScorecardUniqueName()) ||
                                  validation.errors.scorecardName
                                  ? true
                                  : false
                              }
                            // required
                            />
                            {(validation.touched.scorecardName &&
                              checkScorecardUniqueName()) ||
                              validation.errors.scorecardName ? (
                              <FormFeedback type="invalid">
                                {checkScorecardUniqueName() &&
                                  validation.values.scorecardName !== ""
                                  ? "Scorecard name must be unique"
                                  : validation.errors.scorecardName}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        ) : (
                          <FormGroup className="mb-3 tour-guide-scorecard-name">
                            <Input
                              name="scorecardName"
                              placeholder="scorecard file name"
                              type="text"
                              style={{ backgroundColor: "#E7EAEE" }}
                              className="form-control"
                              id="txtscorecardName"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.scorecardName || ""}
                              readOnly
                            />
                          </FormGroup>
                        )}
                      </Col>

                      <Col xs="3">
                        {!params.scorecardId && (
                          <div className="mb-3">
                            <label className="control-label fw-bold">
                              Assign To:
                            </label>

                            <span style={ValidationStyle}>
                              <Select
                                name="assignedTo"
                                id="tassignedTo"
                                placeholder="select user"
                                value={selectedMulti}
                                isMulti={true}
                                onChange={e => {
                                  setIsUserAssigned(false)
                                  setselectedMulti(e)
                                }}
                                onBlur={validation.handleBlur}
                                options={getSelectedUsersList()}
                                classNamePrefix="select2-selection"
                              // invalid={
                              //   validation.touched.assignedTo &&
                              //     validation.errors.assignedTo
                              //     ? true
                              //     : false
                              // }
                              />
                            </span>
                          </div>
                        )}
                      </Col>

                      {location.pathname.includes("/self-report") && !params.scorecardId &&
                        (<Col className="mt-4" md={3}>
                          <div className="mt-1">
                            {/* <label hidden> <span>Add Message</span></label> */}
                            <Button
                              // block
                              onClick={() => {
                                validation.values.scorecardName ?
                                  setmodal_backdrop_message(true)
                                  :
                                  // setToastForScorecardName(true)
                                  notifyScorecardNameRequire()
                              }}
                              style={{
                                backgroundColor: '#0F838C',
                                boxShadow: 'none',
                                borderColor: '#0F838C'
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <span>Add Message</span>
                                <i className="bx bx-message-dots ms-2 font-size-16" />
                              </div>
                            </Button>
                          </div>
                        </Col>)
                      }
                    </Row>

                    <Row>
                      {  //params.scorecardId || !location.pathname.includes("/self-report")) &&
                        (params.scorecardId || !location.pathname.includes("/self-report")) &&
                        <Col md="6">
                          <h4 className="fw-bold" style={ColorStyle}>
                            Client Information
                          </h4>
                        </Col>
                      }
                      <Col md="6" className="d-flex">
                        <h4 className="fw-bold mx-3" style={ColorStyle}>
                          Channels
                        </h4>
                        {isChannelSelected && !params.scorecardId && (
                          <span
                            className="mx-3"
                            style={{
                              color: "#EC3849",
                              fontSize: "11.5px",
                              paddingTop: "6px",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Please select at least one channel.
                          </span>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      {
                        (params.scorecardId || !location.pathname.includes("/self-report")) &&
                        (
                          <Col md="6" className="tour-guide-client-information">
                            {/* <Row>
                              <Col md="6">
                                {!params.scorecardId ?
                                  !location.pathname.includes("/self-report") &&
                                  (
                                    <FormGroup className="mb-3">
                                      <label className="fw-bold">Company Name:</label>

                                      <Input
                                        name="companyName"
                                        placeholder="company name"
                                        type="text"
                                        className="form-control"
                                        id="txtcompanyName"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.companyName || ""}
                                      />
                                    </FormGroup>
                                  )
                                  :
                                  (
                                    <FormGroup className="mb-3">
                                      <label className="fw-bold">Company Name</label>

                                      <Input
                                        name="companyName"
                                        placeholder="company name"
                                        type="text"
                                        className="form-control"
                                        id="txtcompanyName"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.companyName || ""}
                                      />
                                    </FormGroup>
                                  )}
                              </Col>

                              <Col md="6">
                                {!params.scorecardId ?
                                  !location.pathname.includes("/self-report") &&
                                  (
                                    <FormGroup className="mb-3">
                                      <label className="fw-bold">Contact Name:</label>

                                      <Input
                                        name="contactName"
                                        placeholder="contact name"
                                        type="text"
                                        className="form-control"
                                        id="txtcontactName"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.contactName || ""}
                                      />
                                    </FormGroup>
                                  )
                                  :
                                  (
                                    <FormGroup className="mb-3">
                                      <label className="fw-bold">Contact Name</label>

                                      <Input
                                        name="contactName"
                                        placeholder="Required"
                                        type="text"
                                        className="form-control"
                                        id="txtcontactName"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.contactName || ""}
                                      />
                                    </FormGroup>
                                  )}
                              </Col>
                            </Row> */}

                            {/* Below Row is added by Mohanish Saim */}
                            <Row>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  {
                                    (params.scorecardId && (location.pathname.includes("/self-report"))) && (<span style={formMandatory}>*</span>)
                                  }
                                  <label>Company Name</label>
                                  <Input
                                    name="companyName"
                                    placeholder="company name"
                                    type="text"
                                    className="form-control"
                                    id="txtcompanyName"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.companyName || ""}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  {
                                    (params.scorecardId && (location.pathname.includes("/self-report"))) && (<span style={formMandatory}>*</span>)
                                  }
                                  <label>Contact Name</label>
                                  <Input
                                    name="contactName"
                                    placeholder="Contact Name"
                                    type="text"
                                    className="form-control"
                                    id="txtcontactName"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.contactName || ""}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            {/* New Change */}

                            <Row>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  {
                                    (params.scorecardId && (location.pathname.includes("/self-report"))) && (<span style={formMandatory}>*</span>)
                                  }
                                  <Label htmlFor="validationCustom01">Phone</Label>
                                  <select
                                    className="form-select"
                                    name="countryCode"
                                    disabled={isUserAdded}
                                    value={validation.values.countryCode}
                                    onChange={validation.handleChange}
                                  >
                                    {countries &&
                                      countries.map(country => (
                                        <option
                                          key={country.name}
                                          value={country.phonecode}
                                        >
                                          {country.flag}
                                          &nbsp;&nbsp;{country.isoCode}
                                          &nbsp;&nbsp;({country.phonecode})
                                        </option>
                                      ))}
                                  </select>

                                  {/* {validation.touched.countryCode &&
                                validation.errors.countryCode ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.countryCode}
                                </FormFeedback>
                              ) : null} */}
                                </FormGroup>
                              </Col>

                              <Col md="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="validationCustom01">&nbsp;</Label>
                                  <Input
                                    name="phone"
                                    placeholder="phone"
                                    type="number"
                                    min="1"
                                    style={{
                                      MozAppearance: "none",
                                    }}
                                    className="form-control"
                                    // disabled={isUserAdded}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone || ""}
                                    invalid={
                                      validation.touched.phone &&
                                        validation.errors.phone
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.phone &&
                                    validation.errors.phone ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.phone}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  {
                                    (params.scorecardId && (location.pathname.includes("/self-report"))) && (<span style={formMandatory}>*</span>)
                                  }
                                  <label>Email</label>
                                  <Input
                                    name="email"
                                    placeholder="email"
                                    type="text"
                                    className="form-control"
                                    id="txtemail"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                      validation.touched.email &&
                                        validation.errors.email
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.email &&
                                    validation.errors.email ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.email}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  {
                                    (params.scorecardId && (location.pathname.includes("/self-report"))) && (<span style={formMandatory}>*</span>)
                                  }
                                  <label>Company Address</label>
                                  <Input
                                    name="companyAddress1"
                                    placeholder="line 1"
                                    type="text"
                                    className="form-control"
                                    id="txtcompanyAddress1"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.companyAddress1 || ""}
                                  // invalid={
                                  //   validation.touched.companyAddress1 &&
                                  //     validation.errors.companyAddress1
                                  //     ? true
                                  //     : false
                                  // }
                                  />
                                  {/* {validation.touched.companyAddress1 &&
                                validation.errors.companyAddress1 ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.companyAddress1}
                                </FormFeedback>
                              ) : null} */}
                                </FormGroup>
                              </Col>
                            </Row>

                            {/* New Change */}
                            <Row>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  {
                                    (params.scorecardId && (location.pathname.includes("/self-report"))) && (<span style={formMandatory}>*</span>)
                                  }
                                  <label>Country</label>
                                  <Input
                                    type="select"
                                    className="form-select"
                                    name="country"
                                    onChange={validation.handleChange}
                                    // invalid={
                                    //   validation.touched.country &&
                                    //     validation.errors.country
                                    //     ? true
                                    //     : false
                                    // }
                                    value={validation.values.country || ""}
                                  >
                                    <option value="">choose</option>
                                    {countries &&
                                      countries.map(country => (
                                        <option
                                          key={country.name}
                                          value={country.name}
                                        >
                                          {country.flag}&nbsp;&nbsp;{country.name}
                                        </option>
                                      ))}
                                  </Input>
                                  {/* {validation.touched.country &&
                                validation.errors.country ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.country}
                                </FormFeedback>
                              ) : null} */}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  {
                                    (params.scorecardId && (location.pathname.includes("/self-report"))) && (<span style={formMandatory}>*</span>)
                                  }
                                  <label>State</label>
                                  <Input
                                    type="select"
                                    className="form-select"
                                    name="state"
                                    onChange={validation.handleChange}
                                    // invalid={
                                    //   validation.touched.state &&
                                    //     validation.errors.state
                                    //     ? true
                                    //     : false
                                    // }
                                    value={validation.values.state || ""}
                                  >
                                    <option value="">choose</option>
                                    {countries &&
                                      GetCountryByCode().map(state => (
                                        <option key={state.name} value={state.name}>
                                          {state.name}
                                        </option>
                                      ))}
                                  </Input>
                                  {/* {validation.touched.state &&
                                validation.errors.state ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.state}
                                </FormFeedback>
                              ) : null} */}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  {
                                    (params.scorecardId && (location.pathname.includes("/self-report"))) && (<span style={formMandatory}>*</span>)
                                  }
                                  <label>Annual Revenue</label>
                                  <Input
                                    type="select"
                                    className="form-select"
                                    name="annualRevenue"
                                    onChange={validation.handleChange}
                                    // invalid={
                                    //   validation.touched.annualRevenue &&
                                    //     validation.errors.annualRevenue
                                    //     ? true
                                    //     : false
                                    // }
                                    value={validation.values.annualRevenue || ""}
                                  >
                                    <option value="">choose</option>
                                    {ANNUAL_REVENUE.data.map((revenue, i) => (
                                      <option key={i}>{revenue}</option>
                                    ))}
                                  </Input>
                                  {/* {validation.touched.annualRevenue &&
                                validation.errors.annualRevenue ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.annualRevenue}
                                </FormFeedback>
                              ) : null} */}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                {" "}
                                <FormGroup className="mb-3">
                                  {
                                    (params.scorecardId && (location.pathname.includes("/self-report"))) && (<span style={formMandatory}>*</span>)
                                  }
                                  <label>Number Of Employees</label>
                                  <Input
                                    type="select"
                                    className="form-select"
                                    name="numberOfEmployee"
                                    onChange={validation.handleChange}
                                    // invalid={
                                    //   validation.touched.numberOfEmployee &&
                                    //     validation.errors.numberOfEmployee
                                    //     ? true
                                    //     : false
                                    // }
                                    value={validation.values.numberOfEmployee || ""}
                                  >
                                    <option value="">choose</option>
                                    {NUMBER_OF_EMPLOYEES.data.map((Employee, i) => (
                                      <option key={i}>{Employee}</option>
                                    ))}
                                  </Input>
                                  {/* {validation.touched.numberOfEmployee &&
                                validation.errors.numberOfEmployee ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.numberOfEmployee}
                                </FormFeedback>
                              ) : null} */}
                                </FormGroup>
                              </Col>
                            </Row>

                            {/* New Chnage */}
                            <Row>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  {
                                    (params.scorecardId && (location.pathname.includes("/self-report"))) && (<span style={formMandatory}>*</span>)
                                  }
                                  <label>Industry</label>
                                  <Input
                                    type="select"
                                    className="form-select"
                                    name="industry"
                                    onChange={validation.handleChange}
                                    // invalid={
                                    //   validation.touched.industry &&
                                    //     validation.errors.industry
                                    //     ? true
                                    //     : false
                                    // }
                                    value={validation.values.industry || ""}
                                  >
                                    <option value="">choose</option>
                                    {INDUSTRY.data.map((industry, i) => (
                                      <option key={i}>{industry}</option>
                                    ))}
                                  </Input>
                                  {/* {validation.touched.industry &&
                                validation.errors.industry ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.industry}
                                </FormFeedback>
                              ) : null} */}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  {
                                    (params.scorecardId && (location.pathname.includes("/self-report"))) && (<span style={formMandatory}>*</span>)
                                  }
                                  <label>Website URL</label>
                                  <Input
                                    name="websiteUrl"
                                    placeholder="website url"
                                    type="text"
                                    className="form-control"
                                    id="txtwebsiteUrl"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.websiteUrl || ""}
                                  // invalid={
                                  //   validation.touched.websiteUrl &&
                                  //     validation.errors.websiteUrl
                                  //     ? true
                                  //     : false
                                  // }
                                  />
                                  {/* {validation.touched.websiteUrl &&
                                validation.errors.websiteUrl ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.websiteUrl}
                                </FormFeedback>
                              ) : null} */}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  <label> Client Attributes </label>
                                  <Input
                                    name="clientAttribute1"
                                    placeholder="client attribute 1"
                                    type="text"
                                    className="form-control"
                                    id="txtclientAttribute1"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.clientAttribute1 || ""}
                                  // invalid={
                                  //   validation.touched.clientAttribute1 &&
                                  //     validation.errors.clientAttribute1
                                  //     ? true
                                  //     : false
                                  // }
                                  />
                                  {/* {validation.touched.clientAttribute1 &&
                                validation.errors.clientAttribute1 ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.clientAttribute1}
                                </FormFeedback>
                              ) : null} */}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  <label> &nbsp;</label>
                                  <Input
                                    name="clientAttribute2"
                                    placeholder="client attribute 2"
                                    type="text"
                                    className="form-control"
                                    id="txtclientAttribute1"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.clientAttribute2 || ""}
                                  // invalid={
                                  //   validation.touched.clientAttribute2 &&
                                  //     validation.errors.clientAttribute2
                                  //     ? true
                                  //     : false
                                  // }
                                  />
                                  {/* {validation.touched.clientAttribute2 &&
                                validation.errors.clientAttribute2 ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.clientAttribute2}
                                </FormFeedback>
                              ) : null} */}
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                      {/* } */}

                      <Col md={location.pathname.includes("/self-report") && !params.scorecardId ? "12" : "6"} className="tour-guide-channels">
                        <Row>
                          <Col md="12" className="mx-3">
                            <label
                              style={{ color: "#0D0F11", fontWeight: "bold" }}
                            >
                              {data.accountUser ? "These are the channels you’ve been asked to assess" : "Selected Channels"}
                            </label>{" "}
                            &nbsp;
                          </Col>
                        </Row>

                        <Row
                          className="m-auto"
                          style={{
                            height: location.pathname.includes("/self-report") && !params.scorecardId ? "fit-content" : "520px",
                            width: "100%",
                            overflow: "auto",
                          }}
                        >
                          {!channelData[0] && (
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                height: "250px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Spinner
                                type="border"
                                size="md"
                                style={{
                                  marginTop: !location.pathname.includes("/self-report") ? "150px" : ""
                                }}
                              />
                            </div>
                          )}
                          {params.scorecardId &&
                            !scorecardDetail.selectedChannels && (
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  // height:'520px'
                                }}
                              >
                                <Spinner
                                  type="border"
                                  size="md"
                                  style={{ marginTop: "230px" }}
                                />
                              </div>
                            )}
                          <Col md="12">
                            {!params.scorecardId ? (
                              <Row className="pt-2">
                                {channelData[0] &&
                                  channelData
                                    .filter(channel => !channel.isDisabled)
                                    .filter(
                                      channel_ =>
                                        channel_.tactics[0] !== undefined
                                    )
                                    .map((channel, index) => (
                                      <Col
                                        className="d-flex justify-content-middle border border-4 border-white rounded-3"
                                        md={location.pathname.includes("/self-report") && !params.scorecardId ? "3" : "6"}
                                        xl={location.pathname.includes("/self-report") && !params.scorecardId ? "3" : "6"}
                                        sm={location.pathname.includes("/self-report") && !params.scorecardId ? "3" : "6"}
                                        xs="12"
                                        key={index}
                                        style={{
                                          background: "#F4F5F6",
                                          height: "63px",
                                        }}
                                      >
                                        <div className="d-flex align-items-center w-100 ms-2" id="selectChannelCheckbox">
                                          <Input
                                            type="checkbox"
                                            className="ChannelList"
                                            style={{
                                              height: "22px",
                                              width: "22px",
                                            }}
                                            id={`btncheck${index}`}
                                            value={channel.channelId}
                                            onChange={e => {
                                              setIsChannelSelected(false)
                                              if (e.target.checked) {
                                                if (
                                                  selectedChannelList.length <
                                                  channelSelectionLimit
                                                ) {
                                                  var channels =
                                                    channelData.filter(
                                                      channel =>
                                                        channel.channelId ===
                                                        e.target.value
                                                    )
                                                  setSelectedChannelList(p => [
                                                    ...p,
                                                    ...channels,
                                                  ])
                                                } else {
                                                  e.target.checked = false
                                                  setExceedChannelSelectionLimit(
                                                    true
                                                  )
                                                }
                                              } else {
                                                setSelectedChannelList(
                                                  selectedChannelList.filter(
                                                    channelList =>
                                                      channelList.channelId !==
                                                      e.target.value
                                                  )
                                                )
                                              }
                                            }}
                                          />
                                          <Label
                                            htmlFor={`btncheck${index}`}
                                            className="fw-bold ms-2 pt-2"
                                          >
                                            {channel.channelName}
                                          </Label>
                                        </div>
                                      </Col>
                                    ))}
                              </Row>
                            ) : (
                              <Row>
                                {scorecardDetail.selectedChannels &&
                                  scorecardDetail.selectedChannels.map(
                                    (channel, index) => (
                                      <Col
                                        className="d-flex justify-content-center text-white align-items-center border border-4 border-white rounded-3"
                                        key={index}
                                        md="6"
                                        xl="6"
                                        sm="6"
                                        xs="12"
                                        style={{
                                          backgroundColor: "#05727B",
                                          height: "63px",
                                        }}
                                      >
                                        {channel.channelName}
                                      </Col>
                                    )
                                  )}

                                {channelData[0] &&
                                  channelData
                                    .filter(channel => !channel.isDisabled)
                                    .filter(
                                      channel_ =>
                                        channel_.tactics[0] !== undefined
                                    )
                                    .filter(
                                      o1 =>
                                        scorecardDetail.selectedChannels &&
                                        !scorecardDetail.selectedChannels.some(
                                          o2 => o1.channelId === o2.channelId
                                        )
                                    )
                                    .map((channel, index) => (
                                      <Col
                                        className="d-flex justify-content-center text-muted align-items-center border border-4 border-white rounded-3"
                                        md="6"
                                        xl="6"
                                        sm="6"
                                        xs="12"
                                        key={index}
                                        style={{
                                          background: "#E7EAEE",
                                          height: "63px",
                                        }}
                                      >
                                        {channel.channelName}
                                      </Col>
                                    ))}
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <br></br>

                    <Row>
                      <Col md="6" className="col-sm-6">
                        {!params.scorecardId ? (
                          <FormGroup className="mb-3">
                            <Button
                              style={{
                                backgroundColor: "#CB6928",
                                boxShadow: "none",
                                borderColor: "#CB6928",
                              }}
                              type="reset"
                              onClick={() => {
                                // ResetForm()
                                setAccountOwnerMessage("")
                                validation.handleReset()
                              }}
                              block
                            >
                              <div
                                className="d-flex justify-content-center"
                                style={{ color: "#ffffff" }}
                              >
                                <i className="bx bx-left-arrow-alt mx-2 mt-1"></i>
                                <span className="font-size-14">
                                  Reset Information
                                </span>
                              </div>
                            </Button>
                          </FormGroup>
                        ) : (
                          <FormGroup className="mb-3 tour-guide-back">
                            <Button
                              style={{
                                backgroundColor: "#CB6928",
                                boxShadow: "none",
                                borderColor: "#CB6928",
                              }}
                              type="reset"
                              onClick={() => {
                                history.goBack()
                              }}
                              block
                            >
                              <div
                                className="d-flex justify-content-center"
                                style={{ color: "#ffffff" }}
                              >
                                <i className="bx bx-left-arrow-alt mx-2 mt-1"></i>
                                <span className="font-size-14">Back</span>
                              </div>
                            </Button>
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="6" className="col-sm-6">
                        {!params.scorecardId ? (
                          <FormGroup className="mb-3">
                            <Button
                              style={{ backgroundColor: "#0D0F11" }}
                              type="submit"
                              disabled={
                                successMsg && !isUserAssigned ? true : false
                              }
                              block
                            >
                              {successMsg && !isUserAssigned ? (
                                ""
                              ) : (
                                <div className="d-flex justify-content-center">
                                  <span className="text-white font-size-14">
                                    Create Scorecard
                                  </span>
                                  <i className="bx bx-right-arrow-alt text-white fw-bold mx-2 mt-1"></i>
                                </div>
                              )}
                              {successMsg && !isUserAssigned ? (
                                <Spinner type="border" size="sm" />
                              ) : null}
                            </Button>
                          </FormGroup>
                        ) : (
                          <FormGroup className="mb-3 ">
                            <Button
                              style={{ backgroundColor: "#0D0F11" }}
                              type="submit"
                              disabled={successMsg ? true : false}
                              block
                              className="tour-guide-next"
                            >
                              {successMsg ? "" : "Next"}
                              {successMsg ? (
                                <Spinner type="border" size="sm" />
                              ) : null}
                            </Button>
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                    {!params.scorecardId && (
                      <Row>
                        <Col md="6"></Col>
                        <Col md="6">
                          {!selectedChannelList[0] ||
                            !validation.values.scorecardName ? (
                            <p className="message">
                              Please complete the required fields
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container >
      </div >
    </>
  )
}

export default NewScorecard
