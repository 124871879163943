// import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Spinner, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"
import MetaTags from 'react-meta-tags'
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDemoData } from "../../../store/actions"
import { Link } from "react-router-dom"
import { getAccountDetails, getAccountHolderUsers, getScorecardsList, clearAccountHolderUsersState, clearScorecardList } from "store/actions"
import { useHistory } from "react-router-dom"
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// detect offline:
import { Offline, Online } from "react-detect-offline";
import NotFound from "components/Common/NotFound/NotFound.component";
import { ACCOUNT_OWNER, NETWORK_ERROR } from "config/AlertMessage.config";
// import "../../../config/customPagination.css"
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumbs 
// import "../../Tables/datatables.scss"
// CSS Imports
import "./Dashboard.style.css"



const DashboardAccountOwner = () => {
  const [isDataAvail, setIsDataAvail] = useState(true)
  const [paginationReset, setPaginationReset] = useState(false)
  const [isThereData, setData] = useState(false)

  const dispatch = useDispatch();
  const history = useHistory()
  // const [isUserUpdated, setIsUserUpdated] = useState(false)
  const { accountHolderUsers, scorecardsList, accountUserDetails } = useSelector(state => ({
    accountHolderUsers: state.AccountHolderUsersReducer.accountHolderUsers,
    scorecardsList: state.GetScorecardsListReducer.scorecardsList,
    accountUserDetails: state.AccountHolderUsersReducer.accountUserDetails,

  }));

  // const { accountDetails } = useSelector(state => ({
  //   accountDetails: state.SuperAdmin.accountDetails,
  // }));
  // get account details:

  // console.log("Account Holder's Users", accountHolderUsers)

  useEffect(() => {
    dispatch(getAccountHolderUsers(JSON.parse(localStorage.getItem("authUser")).uid))
    dispatch(getScorecardsList(JSON.parse(localStorage.getItem("authUser")).uid));

  }, [dispatch]);

  // console.log(accountHolderUsers)



  useEffect(() => {
    // var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)
    if (accountHolderUsers.status === 404) {
      setIsDataAvail(false)
    }

    if (accountHolderUsers[0]) {
      var search_bar = document.querySelector("#search-bar-0")
      search_bar.setAttribute("autoComplete", "off")
      // clearTimeout(WaitForData)
      search_bar.addEventListener("focus", () => setPaginationReset(true))

    }

  }, [accountHolderUsers])

  const MyIcon = (isActive) => {
    return (
      <div style={{
        width: "60%",
        textAlign: "center"
      }} >
        {
          isActive ?
            <i className="bx bxs-user-check" style={{ color: "green", fontSize: "24px" }} ></i>
            :
            <i className="mdi mdi-account-cancel" style={{ color: "red", fontSize: "24px" }} ></i>
        }
      </div>
    )
  }


  const columns = [

    {
      dataField: 'Status',
      text: '',
      // sort: true,
    },
    //   {
    //   dataField: 'id',
    //   text: 'Id',
    //   sort: true,
    // },
    {
      dataField: 'accounts',
      text: 'Name',
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to="#" style={{ color: '#05727B', fontSize: '17px', fontWeight: 'bold' }}>
          {row.accounts}
        </Link>
      ),
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true
    },
    {
      dataField: 'contactNumber',
      text: 'Phone',
      sort: true
    },
    {
      dataField: 'Designation',
      text: 'Title',
      sort: true
    },
    {
      dataField: 'proScorecardsCreated',
      text: 'Pro Scorecard',
      sort: true,
    },
    {
      dataField: 'selfReportScorecardsCreated',
      text: 'Self Report Scorecard',
      sort: true,
    },
    {
      dataField: 'scorecardsCreated',
      text: '',

    },

  ];

  // Table Data
  // const productData = [
  //   {
  //     "id": 1,
  //     "accounts": "pratik",
  //     "email": "pratik@example.com",
  //     "credits": 10
  //   }];


  const GetScorecardCount = (user_id, scorecardType) => {
    const filteredScorecards = scorecardsList[0]
      ? scorecardsList.filter(scorecards => scorecards.scorecardVersion !== undefined) : []

    const listForScorecards = filteredScorecards[0]
      ? filteredScorecards.filter(obj => obj.scorecardVersion === scorecardType) : []

    var AssignedScorecards = listForScorecards[0] ?
      listForScorecards.map(scorecards => {
        return (
          scorecards.assignedTo ?
            scorecards.assignedTo.filter(assignee => assignee.accountUserId === user_id)
            :
            scorecards.assigneTo.filter(assignee => assignee.accountUserId === user_id)
        )
      })
      :
      [[]]

    return AssignedScorecards.reduce((acc, curVal) => {
      return acc.concat(curVal)
    }, []).length
    // return AssignedScorecards.length
  }


  const productData = !accountHolderUsers[0] ? [] : accountHolderUsers.map((user, i) => {

    return {
      Status: MyIcon(!user.isDisabled),
      id: ++i,
      accounts: user.contactName,
      email: user.contactEmail,
      contactNumber: user.contactNumber ? user.contactNumber : "---",
      scorecardsCreated: (
        <button
          className="view-user-btn-for-all-user-list"
          onClick={() => {
            history.push("/users/" + user.id)
          }}
        >
          View User
        </button>
      ),
      Designation: user.address ? user.address : "---",
      proScorecardsCreated: GetScorecardCount(user.id, "Pro"),
      selfReportScorecardsCreated: GetScorecardCount(user.id, "Self Report"),
    }
  })

  // console.log(GetScorecardCount("gCJ5wlRLk3RNslFTedjT6vfN6jS2"))

  // const defaultSorted = [{
  //   dataField: 'id',
  //   order: 'asc'
  // }];


  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (productData).length }];

  const pageOptions = {
    // page:1,
    // sizePerPage: 10,
    onPageChange: () => setPaginationReset(false),
    // totalSize: productData.length, // replace later with size(customers),
    custom: true,
    sizePerPageList
  }

  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/users/" + row.actions)
    }
  }
  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - All Users</title>
        </MetaTags>
        <div className="container-fluid">
          {/* <Offline>
            <h1>You are offline</h1>
          </Offline> */}


          {/* <Online> */}

          {
            !accountHolderUsers[0] ?       //  && !accountDetails[0]
              <div style={
                {
                  width: "100%",
                  textAlign: "center",
                  height: "400px"
                }
              } >
                {
                  !isDataAvail ?
                    <>
                      {accountHolderUsers.status === 404 ?
                        <NotFound
                          Message={ACCOUNT_OWNER.EMPTY_ACCOUNT_USERS}
                          Icon={() => (
                            <i className="bx bxs-user-plus"
                              style={{ borderBottom: "1px solid #000" }}
                            ></i>
                          )}
                          Url="/create/user"
                          IsButton
                          btnText="Create User"
                        />
                        :
                        <NotFound Message={NETWORK_ERROR} Icon={() => (
                          <i className="bx bx-error"
                            style={{ borderBottom: "1px solid #000", fontSize: "40px" }}
                          ></i>
                        )}
                          IsButton
                          btnText="Reload"
                          Reload
                        />
                      }
                    </>
                    :
                    <Spinner
                      style={{
                        width: "60px", height: "60px",
                        margin: "calc(200px - 30px)",
                        color: '#05727B'

                      }}
                      role="grid"
                      type="border"

                    />
                }
              </div>
              :
              <>
                {/* <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{accountDetails && accountDetails.accountName}</CardTitle>
                  <p className="card-title-desc" >{accountDetails && accountDetails.street}</p>
                  <p className="card-title-desc" >{accountDetails && accountDetails.city}, {accountDetails && accountDetails.state}</p>
                </CardBody>
              </Card>
            </Col>
          </Row> */}


                <Row>

                  <Col className="col-12">
                    <Card>
                      <CardBody>



                        <PaginationProvider
                          pagination={
                            paginationReset ?
                              paginationFactory({
                                ...pageOptions,
                                page: 1
                              }
                              ) :
                              paginationFactory(pageOptions)
                          }
                          keyField='id'
                          columns={columns}
                          data={productData}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField='id'
                              columns={columns}
                              data={productData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>

                                  <Row className="mb-2">
                                    <Col md="9">
                                      <h2 className="fw-bold" style={{ color: '#0D0F11' }}>
                                        All Users
                                      </h2>
                                    </Col>
                                    <Col md="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            autoComplete="off"
                                            style={{ width: "100%", float: "right" }}
                                            {...toolkitProps.searchProps}
                                            onClear={() => setPaginationReset(false)}
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable

                                          keyField={"id"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          // defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap table-hover"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}

                                        />

                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )
                              }
                            </ToolkitProvider>
                          )
                          }</PaginationProvider>










                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>}
          {/* </Online> */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default DashboardAccountOwner
