import { takeEvery, put, call,all,fork  } from "redux-saga/effects";

// Login Redux States
import {
    GET_CHANNEL_DATA,
} from "./actionTypes"
import {
    getChannelDataSuccess,
    getChannelDataFail,
} from "./actions"

//Axios get call
import { getChannelsTactics } from "../../helpers/fakebackend_helper";

function* fetchChannelData() {
  try {
    const response = yield call(getChannelsTactics)
    yield put(getChannelDataSuccess(response))
  } catch (error) {
    yield put(getChannelDataFail(error))
  }
}
                                      
export function* watchFetchChannelData() {
  yield takeEvery(GET_CHANNEL_DATA, fetchChannelData);
}
                                      
function* channelSaga() {
  yield all([fork(watchFetchChannelData)]);
}
                                      
export default channelSaga;
