import { color } from "@mui/system"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Grid } from "@mui/material"
import SweetAlert from "react-bootstrap-sweetalert"

import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
  Modal,
  Tooltip
} from "reactstrap"
import { Opacity } from "@mui/icons-material"
import CropEasy from "../../../Crop/CropEasy"
import { CLOUD_STORAGE } from "config/cloudStorage.config"
import {
  getScorecardReport,
  UpdateScorecardReport,
  ClearUpdateScorecardReport,
  updateGetScorecardReport,
  handleUnsavedChanges,
  handleChangesHappend,
  updateScorecardDetail,
  getAllInstructionData,
} from "../../../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams, useHistory } from "react-router-dom"
import { ACCOUNT_USER } from "config/AlertMessage.config"
import { REPORT } from "../config/style.config"
import updateSuccessIcon from "../../../../assets/images/NewPopupIcons/Scorecard created.png"
import updateFailedIcon from "../../../../assets/images/NewPopupIcons/FailedIcon.png"

const TableOfContent = () => {
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const [image, setImage] = useState({ preview: "", raw: "" })

  const [openCrop, setOpenCrop] = useState(false)
  const [file, setFile] = useState(null)
  const [image_url, setImage_Url] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [HeightWidth, setHeightWidth] = useState({})
  const [isDataAdded, setIsDataAdded] = useState(false)
  const [isImageThere, setImageThere] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [IsImage, setIsImage] = useState(false)

   const [toolTipForSave, setToolTipForSave] = useState(false)
  const [toolTipForUploadImage, setToolTipForUploadImage] = useState(false)
  const [infoForSaveButton, setInfoForSaveButton] = useState("")
  const [infoForUploadImage, setInfoForUploadImage] = useState("")
  const [data, setData] = useState({})

  // setting state for Tooltip over a save button
  const [tooltip, setTooltip] = useState(false)

  const { scorecardReport_data, scorecardReportUpdate, isDataEdited, isThereChanges, getInstructionData } = useSelector(
    state => ({
      scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
      scorecardReportUpdate:
        state.ScorecardReport_Reducer.scorecardReportUpdate,
      isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
      isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
      getInstructionData: state.instructionPanelReducer.getInstructionData,
    })
  )

  useEffect(() => {
    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
    // dispatch(getScorecardReport('UufUpT9yB9k5x7nMSwh7'))
  }, [dispatch])

  useEffect(() => {
    if (IsImage) {
      setIsModalOpen(false)
      setImageThere(false)
      setIsImage(false)
    }
  }, [image_url])

  const handleChange = e => {
    const file = e.target.files[0]
    if (file) {
      var u = URL.createObjectURL(file)
      var img = new Image()
      img.onload = function () {
        var IMAGE_HEIGHT = img.height
        var IMAGE_WIDTH = img.width

        // console.log(
        //   "Image Width: " +
        //     " " +
        //     IMAGE_WIDTH +
        //     " " +
        //     "Image Height : " +
        //     IMAGE_HEIGHT
        // )

        setHeightWidth({
          myheight: IMAGE_HEIGHT,
          mywidth: IMAGE_WIDTH,
        })
      }
      img.src = u
      setFile(file)
      setImage_Url(u)
      setOpenCrop(true)
      setTimeout(() => setIsImage(true), 2000)
      dispatch(handleChangesHappend(true))
      e.target.value = ''
    }


  }

  const handleSave = () => {
    setIsDataAdded(false)
    setIsSaved(true)
    var UpdatedData = {
      tableOfContents: {
        ...scorecardReport_data.tableOfContents,
        bgImage: image_url,
      },
    }
    dispatch(updateScorecardDetail(params.scorecardId, {
      lastUpdatedOn: {
        _miliseconds: 0,
        _seconds: new Date().getTime() / 1000
      }
    }))
    dispatch(UpdateScorecardReport(params.scorecardId, UpdatedData))
  }

  // const storeData = () => {
  //   localStorage.setItem("data", JSON.stringify(data))
  //   localStorage.setItem("image", JSON.stringify(image_url))
  // }
  // console.log(storeData)

  useEffect(() => {
    if (scorecardReport_data && scorecardReport_data.tableOfContents) {
      var data = scorecardReport_data.tableOfContents

      setImage_Url(data.bgImage)
    }
  }, [scorecardReport_data])

  useEffect(() => {
    if (getInstructionData.infoForAccountUser !== undefined) {
      var data = {
        infoForSaveBtn: getInstructionData.infoForAccountUser.tableOfContents.infoForSaveBtn ? getInstructionData.infoForAccountUser.tableOfContents.infoForSaveBtn : "",
        infoForUploadImageBtn: getInstructionData.infoForAccountUser.tableOfContents.infoForUploadImageBtn ? getInstructionData.infoForAccountUser.tableOfContents.infoForUploadImageBtn : "",
      }
      setInfoForSaveButton(data.infoForSaveBtn)
      setInfoForUploadImage(data.infoForUploadImageBtn)
    }
  },[getInstructionData])  

  return (
    <>
      <div className="page-content" style={{ marginBottom: 5 }}>
        <MetaTags>
          <title> Mambo -Report</title>
        </MetaTags>
        {/* {scorecardReportUpdate && scorecardReportUpdate.status === 200 && (
          <SweetAlert
            style={{ height: "255px", width: "368px" }}
            // title="Data saved successfully!"
            success
            confirmBtnBsStyle="primary"
            onConfirm={() => {
              setIsSaved(false)
              // setIsDataAdded(false)
              dispatch(updateGetScorecardReport({
                ...scorecardReport_data,
                tableOfContents: {
                  ...scorecardReport_data.tableOfContents,
                  bgImage: image_url,
                },

              }))
              dispatch(ClearUpdateScorecardReport())
              // history.push("/pageSecond")
            }}
          >{ACCOUNT_USER.EDIT_REPORT.SUCCESS}</SweetAlert>
        )} */}

        {scorecardReportUpdate && scorecardReportUpdate.status === 200 && (
          <SweetAlert
            style={{height:"270px", width:"270px"}}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img src={updateSuccessIcon}
                    style={{
                      height:'80px', 
                      width:'85px'
                  }}/>
                </Col>
                <Col md="12" className="text-center container-fluid mb-2"
                  style={{
                    color:'#0D0F11',
                    fontWeight:600,
                    fontSize:'24px',
                    lineHeight:'25px',
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.SUCCESS}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                  <Button block size="sm" className="mb-2"
                    style={{
                      background:'#0D0F11',
                      border:'#0D0F11'
                    }}
                    onClick={() => {
                      setIsSaved(false)
                      // setIsDataAdded(false)
                      dispatch(updateGetScorecardReport({
                        ...scorecardReport_data,
                        tableOfContents: {
                          ...scorecardReport_data.tableOfContents,
                          bgImage: image_url,
                        },

                      }))
                      dispatch(ClearUpdateScorecardReport())
                      // history.push("/pageSecond")
                    }}
                  >
                    Close
                  </Button>
              </div>
            }
          >
          </SweetAlert>
        )}

        {/* {scorecardReportUpdate && scorecardReportUpdate.status === 403 && (
          <SweetAlert
            // title="Failed to save !"
            style={{ height: "255px", width: "368px" }}
            error
            confirmBtnBsStyle="primary"
            onConfirm={() => {
              setIsSaved(false)
              setIsDataAdded(false)
              dispatch(ClearUpdateScorecardReport())
            }}
          >{ACCOUNT_USER.EDIT_REPORT.FAILED}</SweetAlert>
        )} */}

        {scorecardReportUpdate && scorecardReportUpdate.status === 403 && (
          <SweetAlert
            style={{height:"270px", width:"270px"}}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img src={updateFailedIcon}
                    style={{
                      height:'80px', 
                      width:'80px'
                  }}/>
                </Col>
                <Col md="12" className="text-center container-fluid"
                  style={{
                    color:'#0D0F11',
                    fontWeight:600,
                    fontSize:'24px',
                    lineHeight:'25px',
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.FAILED}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                  <Button block size="sm" className=""
                    style={{
                      background:'#0D0F11',
                      border:'#0D0F11'
                    }}
                    onClick={() => {
                      setIsSaved(false)
                      setIsDataAdded(false)
                      dispatch(ClearUpdateScorecardReport())
                    }}
                  >
                    Close
                  </Button>
              </div>
            }
          >
          </SweetAlert>
        )}
        
        <Container
          fluid={true}
          style={{
            transform: "scale(.8) translate(-130px, -132px) ",
            width: "1400px",
          }}
        >
          {/* <Breadcrumbs title="New Page" breadcrumbItem="New Page" /> */}
          <Card>
            <CardBody>
              <div>
                <div
                  style={{
                    height: "1056px",
                    width: "816px",
                    transform: "scale(1)",
                    backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url(${image_url})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Row style={{ paddingRight: '10px' }}>
                    <Col xs="9"></Col>
                    <Col xs="2" style={{ marginTop: '15px' }}>
                      <FormGroup>
                        <Button
                          type="button"
                          size="sm"
                          id="TooltipSave"
                          style={{
                            backgroundColor: "#5015cf",
                            marginLeft: '54px',
                            width: '75px',
                            border: '1px solid white'
                          }}
                          onClick={() => {
                            handleSave()
                            dispatch(handleChangesHappend(false))
                          }}

                          className="mt-2"
                          disabled={isSaved}
                        >
                          {isSaved ? "" : "Save"}
                          {isSaved ? (
                            <Spinner type="border" size="sm" />
                          ) : null}
                        </Button>
                        <Tooltip
                      style={{backgroundColor:'#FEF3C7', color:'#0D0F11', zIndex: 1}}
                      placement="auto"
                      isOpen={toolTipForSave}
                      target="TooltipSave"
                      toggle={() => {
                        setToolTipForSave(!toolTipForSave);
                      }}
                    >
                     {infoForSaveButton} 
                    </Tooltip>
                      </FormGroup>
                    </Col>
                    <Col xs="1" style={{ marginTop: '23px' }}>
                      <div>
                        <label htmlFor="upload-button" type="button" >
                          <>
                            {" "}
                            <span
                              className="fas fa-pencil-alt"
                              id = "TooltipPencilIcon"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "18px",
                                color: "#5015cf",
                                height: "28px",
                                width: "35px",
                                border: '1px solid #5015cf',
                                borderRadius: '4px',
                                backgroundColor: "#fff",
                              }}
                              onClick={() => setIsModalOpen(true)}
                            ></span>
                          </>
                        </label>
                        <Tooltip
                      style={{backgroundColor:'#FEF3C7', color:'#0D0F11', zIndex: 1}}
                      placement="right"
                      isOpen={toolTipForUploadImage}
                      target="TooltipPencilIcon"
                      toggle={() => {
                        setToolTipForUploadImage(!toolTipForUploadImage);
                      }}
                    >
                     {infoForUploadImage} 
                    </Tooltip>
                        <input
                          type="file"
                          id="uploadImage"
                          name="Image_URL"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleChange}
                        />
                        <br />
                      </div>
                    </Col>
                  </Row>


                  <CardBody style={{ marginTop: 25, marginLeft: 90, fontFamily: REPORT.FONT_FACE.HEADING }}>
                    <div>
                      <h1 style={{ color: "white", fontSize: "40px", fontFamily: REPORT.FONT_FACE.HEADING }}>
                        TABLE OF{" "}
                      </h1>
                      <label
                        style={{
                          color: "white",
                          fontSize: "40px",
                          borderBottom: "3px solid #FF6060",
                          fontFamily: REPORT.FONT_FACE.HEADING
                        }}
                      >
                        {" "}
                        CONTENTS
                      </label>
                    </div>

                    <div style={{ marginTop: 35 }}>
                      <p
                        style={{
                          color: "white",
                          fontSize: "30px",
                          fontWeight: "-100px",
                          fontFamily: REPORT.FONT_FACE.HEADING
                        }}
                      >
                        <label
                          style={{
                            backgroundColor: "rgba(235, 76, 39, 0.8)",
                            width: "60px",
                            border: "1px",
                            padding: "10px",
                            margin: "10px",
                            textAlign: "center",
                            fontFamily: REPORT.FONT_FACE.HEADING
                          }}
                        >
                          1
                        </label>
                        &nbsp; Scorecard Methodology
                      </p>

                      <p
                        style={{ color: "white", fontSize: "30px", marginTop: 15, fontFamily: REPORT.FONT_FACE.HEADING }}
                      >
                        <label
                          style={{
                            backgroundColor: "rgba(235, 76, 39, 0.8)",
                            width: "60px",
                            border: "1px",
                            padding: "10px",
                            margin: "10px",
                            textAlign: "center",
                            fontFamily: REPORT.FONT_FACE.HEADING
                          }}
                        >
                          2
                        </label>
                        &nbsp; Executive Summary
                      </p>

                      <p
                        style={{ color: "white", fontSize: "30px", marginTop: 15, fontFamily: REPORT.FONT_FACE.HEADING }}
                      >
                        <label
                          style={{
                            backgroundColor: "rgba(235, 76, 39, 0.8)",
                            width: "60px",
                            border: "1px",
                            padding: "10px",
                            margin: "10px",
                            textAlign: "center",
                            fontFamily: REPORT.FONT_FACE.HEADING
                          }}
                        >
                          3
                        </label>
                        &nbsp;&nbsp;Priority Areas of Focus
                      </p>

                      <p
                        style={{ color: "white", fontSize: "30px", marginTop: 15, fontFamily: REPORT.FONT_FACE.HEADING }}
                      >
                        <label
                          style={{
                            backgroundColor: "rgba(235, 76, 39, 0.8)",
                            width: "60px",
                            border: "1px",
                            padding: "10px",
                            margin: "10px",
                            textAlign: "center",
                            fontFamily: REPORT.FONT_FACE.HEADING
                          }}
                        >
                          4
                        </label>
                        &nbsp;&nbsp;Scores at a Glance
                      </p>

                      <p
                        style={{ color: "white", fontSize: "30px", marginTop: 15, fontFamily: REPORT.FONT_FACE.HEADING }}
                      >
                        <label
                          style={{
                            backgroundColor: "rgba(235, 76, 39, 0.8)",
                            width: "60px",
                            border: "1px",
                            padding: "10px",
                            margin: "10px",
                            textAlign: "center",
                            fontFamily: REPORT.FONT_FACE.HEADING
                          }}
                        >
                          5
                        </label>
                        &nbsp;&nbsp;Deep Dive into Your Channels
                      </p>

                      <p
                        style={{ color: "white", fontSize: "30px", marginTop: 15, fontFamily: REPORT.FONT_FACE.HEADING }}
                      >
                        <label
                          style={{
                            backgroundColor: "rgba(235, 76, 39, 0.8)",
                            width: "60px",
                            border: "1px",
                            padding: "10px",
                            margin: "10px",
                            textAlign: "center",
                            fontFamily: REPORT.FONT_FACE.HEADING
                          }}
                        >
                          6
                        </label>
                        &nbsp;&nbsp;Key Takeaways
                      </p>
                    </div>

                    {/* <div>
                    <label htmlFor="upload-button">
                    
                        <>
                          <span className="fa-stack fa-2x mt-3 mb-2" >
                            <i className="fas fa-circle fa-stack-2x" />
                            <i className="fas fa-store fa-stack-1x fa-inverse" /> 
                          </span>
                        </>
                      
                    </label>
                    <input
                      type="file"
                      id="upload-button"
                                          
                      name="Image_URL"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleChange}
                    />
                    <br />
                  
                  </div> */}

                    <Modal
                      isOpen={isModalOpen}
                      ariaHideApp={false}
                      onRequestClose={() => setIsModalOpen(false)}
                      style={{ marginTop: '250px', width:'650px' }}
                    >
                      {
                        !isImageThere ?
                          <>
                            {/* <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                // width:'100%',
                                marginLeft: "5px",
                              }}
                            >
                              <h5 style={{ margin: '15px 0 10px 140px' }}>Upload background image</h5>
                              <i
                                className="bx bx-x"
                                style={{ fontSize: "25px", cursor: "pointer", width: '40px', margin: '14px 0 10px 80px' }}
                                onClick={() => setIsModalOpen(false)}
                              ></i>
                            </div> */}
                            {/* <Button
                              color="primary"
                              onClick={() => {
                                var inputFile = document.querySelector("#uploadImage")
                                inputFile.click()
                              }}
                              style={{
                                alignItems: "center",
                                margin: "0 0 20px 180px",
                                width: "150px",
                              }}
                            >
                              <i
                                className="mdi mdi-image"
                                style={{ marginRight: "5px", fontSize: "15px" }}
                              ></i>
                              Choose Image
                            </Button> */}
                            <Row className="modal-body">
                            <Col md="12" className="text-center font-size-18 mb-3"
                              style={{
                                color:'#0D0F11',
                                fontWeight:600,
                              }}
                            >
                              Upload New Image
                              <button
                                style={{
                                  marginTop:'-10px',
                                  marginLeft:'-20px'
                                }}
                                type="button"
                                onClick={() => {
                                  setIsModalOpen(false);
                                }}
                                className="close text-center font-size-14"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                              </button>
                            </Col>
                            <Col md="4"></Col>
                            <Col md="4">
                              <Button
                                size="sm"
                                block
                                onClick={() => {
                                  var inputFile = document.querySelector("#uploadImage")
                                  inputFile.click()
                                }}
                                style={{
                                  background:'#0D0F11',
                                  border:'#0D0F11'
                                }}
                              >
                                <div
                                  className="d-flex justify-content-center"
                                >
                                  <i className="mdi mdi-image fw-bold mx-2 font-size-14"></i>
                                  <span className="font-size-14">
                                    Choose Image
                                  </span>
                                </div>
                              </Button>
                            </Col>
                            <Col md="4"></Col>
                          </Row>
                            <div style={{justifyContent:'center', margin:'auto 10px auto 10px'}}>
                              {openCrop && (
                                <CropEasy
                                  image_url={image_url}
                                  imageName={file.name}
                                  image_storagePath={CLOUD_STORAGE.EDIT_SCORECARD_REPORT.TABLE_OF_CONTENT.IMAGE}
                                  setOpenCrop={setOpenCrop}
                                  setImageUrl={setImage_Url}
                                  setFile={setFile}

                                  setImageThere={setImageThere}
                                  ImageHeightWidth={HeightWidth}
                                  ImageType="BACKGROUND"
                                />
                              )}
                            </div>  
                          </>
                          :
                          // <Col md="8" style={{
                          //   textAlign: 'center',
                          //   margin: '40px 0 40px 90px'
                          // }}>
                          //   <Spinner />
                          //   <br />
                          //   <br />
                          //   <p
                          //     style={{
                          //       color: '#292929',
                          //       fontSize: REPORT.FONT_SIZE.LARGE
                          //     }}
                          //   >
                          //     Please wait.  Image is being uploaded.</p>
                          // </Col>
                          <Row className="modal-body text-center">
                            <Col md='12' className="my-3 fw-bold font-size-18">
                              <p style={{
                                color: '#0D0F11',
                              }}>
                                Please wait.  Image is being uploaded.
                              </p>
                            </Col>
                            <Col md='12' >
                              <Spinner style={{color:'#0D0F11'}}/>
                            </Col>
                          </Row>
                      }
                      {/* <Button 
                            color="secondary"
                            onClick={setIsModalOpen(false)}
                            >
                              

                            </Button>  */}
                    </Modal>
                  </CardBody>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default TableOfContent
