import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import { Data } from "../../../common/data/buyCredits"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Card,
  CardBody,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  CardText,
  Modal,
  Button,
  Spinner,
} from "reactstrap"

import {
  getProduct,
  ClearProduct,
  checkoutRequestData,
  ClearCheckoutData,
  toggleBuyCreditMenu
} from "../../../store/actions"

import BuyButton from "../Credits/BuyButton"

import classnames from "classnames"
import "./New_Dashboard.css"

import AddCredit from "./AddCredit/AddCredit"

import Credit from "../New Dashboard/CreditImages/credits_icon.png"
import Credit1 from "../../../assets/images/BuyCreditImages/Buy 1 Credit.png"
import Credit4 from "../../../assets/images/BuyCreditImages/Buy 4 Credits.png"
import Credit8 from "../../../assets/images/BuyCreditImages/Buy 8 Credits.png"

//import images

const CreditBox = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [activeTab, toggleTab] = useState("1")

  const [addCredit, setAddCredit] = useState(false)

  const [modal_backdrop, setmodal_backdrop] = useState(false)

  const [modal_small, setmodal_small] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const [buyCreditListData, setBuyCreditListData] = useState([])

  const { getProductList, checkoutData, isBuyCreditMenuToggle } = useSelector(state => ({
    getProductList: state.GetProductReducer.getProductList,
    checkoutData: state.GetProductReducer.checkoutData,
    isBuyCreditMenuToggle: state.BuyCreditMenuReducer.isBuyCreditMenuToggle
  }))

  useEffect(() => {
    if (getProductList && getProductList) {
      dispatch(getProduct())
    }
  }, [dispatch])

  const HandlePay = item => {
    var customerId = JSON.parse(localStorage.getItem("authUser")).uid
    //var customerId = "xxsasrefass"
    var quantity =
      item.prices.transform_quantity && item.prices.transform_quantity
        ? item.prices.transform_quantity.divide_by
        : 1
    var priceId = item.prices.id

    var isAdjustableQty = quantity === 1 ? true : false

    var successUrl = `${window.location.origin}/success`
    var cancelUrl = `${window.location.origin}/cancel`

    var Checkout_data = {
      customerId,
      quantity,
      priceId,
      successUrl,
      cancelUrl,
      isAdjustableQty,
    }

    // console.log(Checkout_data)
    // setShowSpinner(true)

    dispatch(checkoutRequestData(Checkout_data))
  }

  useEffect(() => {
    if (checkoutData !== "") {
      if (checkoutData.response && checkoutData.response.status === 400) {
        setShowAlert(true)
      } else {
        window.location.replace(checkoutData)
        dispatch(ClearCheckoutData())
      }
    }
  }, [checkoutData])

  const GetPrice = item => {
    var unitPrice = item.prices.unit_amount
    var transformCredits = item.prices.transform_quantity
      ? item.prices.transform_quantity.divide_by
      : 1

    return unitPrice / 100 / transformCredits
  }

  // const PurchaseData = getProductList ? getProductList.map((item, index) => {
  //   return {
  //     id: index,
  //     name: item.name,
  //     credit: item.prices.transform_quantity && item.prices.transform_quantity
  //       ? item.prices.transform_quantity.divide_by
  //       : 1,
  //     price: "$ " + GetPrice(item),
  //     total: "$ " + item.prices.unit_amount / 100,
  //     // action: (
  //     //   <BuyButton
  //     //     HANDLE_PAY={() => HandlePay(item)}
  //     //     showAlert={showAlert}
  //     //   // setShowAlert = {setShowAlert}

  //     //   />

  //     // ),
  //   }
  // })
  //   :
  // null

  //console.log(window.location.origin)
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  function tog_small() {
    setmodal_small(!modal_small)
    // removeBodyCss()
  }

  // To auto open the buy credit menu
  useEffect(() => {
    if (isBuyCreditMenuToggle) {
      tog_small()
    }
  }, [isBuyCreditMenuToggle])

  // To clear the products and toggle state when closing modal
  useEffect(() => {
    if (modal_small === false) {
      dispatch(toggleBuyCreditMenu(false))
    }
  }, [modal_small])
  
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const openCreditPopup = () => {
    setAddCredit(!addCredit)
  }

  const eitherSort = (arr = []) => {
    const sorter = (a, b) => {
      return +a.prices.unit_amount / 100 - +b.prices.unit_amount / 100
    }
    return arr.sort(sorter)
  }
  
  useEffect(() => {
    if (getProductList && getProductList[0]) {
      const resultant_arr = eitherSort(getProductList)
      setBuyCreditListData(resultant_arr)
      // console.log("res: ", resultant_arr)
    }
  }, [getProductList])

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={modal_small}
        toggle={() => {
          tog_small()
        }}
        backdrop={true}
        // id="staticBackdrop"
        style={{ visibility: "hidden", marginTop: "180px", display: "flex" }}
      >
        <Row
          style={{
            transform: "scale(.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {buyCreditListData[0] &&
            buyCreditListData.map((item, index) => (
              <Col
                md="6"
                sm="6"
                lg="4"
                xl="3"
                className="flex-wrap gap-2 justify-content-center"
                key={index}
                style={{
                  visibility: "visible",
                  // width:"287px", height:"258px", marginLeft: "100px"
                }}
              >
                <div className="mt-4 card justify-content-center">
                  <div className="card-body text-center">
                    <img
                      className="mt-2"
                      src={
                        index === 0 ? Credit1 : index === 1 ? Credit4 : Credit8
                      }
                    />
                    <h3
                      className="fw-bold mt-3"
                      style={{
                        color: "#0D0F11",
                      }}
                    >
                      Buy{" "}
                      {item.prices.transform_quantity &&
                      item.prices.transform_quantity
                        ? item.prices.transform_quantity.divide_by
                        : 1}{" "}
                      {index === 0 ? "Credit" : "Credits"}
                    </h3>
                    <p
                      className="fw-bold font-size-22"
                      style={{ color: "gray", marginTop: "-10px" }}
                    >
                      {"$ " + item.prices.unit_amount / 100 + ".00"}
                    </p>
                    <BuyButton
                      HANDLE_PAY={() => HandlePay(item)}
                      showAlert={showAlert}
                      INDEX={index}
                    />
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </Modal>

      {showAlert && (
        <SweetAlert
          error
          confirmBtnBsStyle="primary"
          style={{ height: "255px", width: "368px" }}
          onConfirm={() => {
            setShowAlert(false)
            dispatch(ClearCheckoutData())
          }}
        >
          You are not registered
        </SweetAlert>
      )}
      <Row>
        <Col md="12" style={{ background: "#003f44", borderRadius: '6px'}}>
          <div className="w-100 d-grid justify-content-center" style={{borderRadius: '6px'}}>
            <div className="d-flex justify-content-center mt-5 mb-3">
              <img src={Credit} alt="Credit_Icon" />
            </div>

            <div className="d-flex justify-content-center my-2">
              <h5
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "#1a919b",
                }}
              >
                Add some credits!
              </h5>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <p className="text-white">
                Click here to buy more credits. You must have credits available
                in order to start any new Scorecard.
              </p>
            </div>
            <div className="d-flex justify-content-center mb-3">
              <button
                className="add-credit-btn"
                onClick={() => {
                  tog_small()
                }}
              >
                Add Credits
                <i
                  style={{ marginLeft: "10px" }}
                  className="mdi mdi-arrow-right"
                ></i>
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CreditBox
