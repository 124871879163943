import React from "react"
import {
    Row,
    Col,
} from "reactstrap"

import { REPORT_PAGE, REPORT, MATURITY_LEVEL_IMAGES } from "../config/style.config"
import Knob from "../../../chart/knob/knob"
import BubbleChart from "./component/BubbleChart/BubbleChart.componet"

import { getChannelScore } from 'commonFunctions/GetChannelScore/getChannelScore';
import ArrowChart from "./component/ArrowChart/ArrowChart.componet"

const TacticEvaluated = (props) => {

    const PROPS = props;
    const { ChannelData } = PROPS

    const GetMaturityImage = () => {
        const {
            Early,
            Beginner,
            Intermediate,
            Advanced,
            Mature
        } = MATURITY_LEVEL_IMAGES

        var ChannelScore = Math.round(ChannelData && getChannelScore(ChannelData))
        if (ChannelScore > 19 && ChannelScore <= 39)
            return Beginner
        else if (ChannelScore > 39 && ChannelScore <= 59)
            return Intermediate;
        else if (ChannelScore > 59 && ChannelScore <= 79)
            return Advanced;
        else if (ChannelScore > 79 && ChannelScore <= 100)
            return Mature;
        else
            return Early

    }


    return (
        <>

            <div style={REPORT_PAGE}>
                <Row className="g-0 p-0 m-0" style={REPORT_PAGE} >
                    <Col xs="12" >
                        <div
                            style={{
                                // width: "85%",
                                width: "100%",
                                // padding: "30px 0 0 30px",
                                padding: "30px 40px 0 40px",
                                height: '100px',
                                // border:'1px solid black'
                            }}
                        >
                            <h2
                                style={{
                                    fontSize: REPORT.FONT_SIZE.XLARGE,
                                    fontWeight: 600,
                                    color: '#0D0F11',
                                    letterSpacing: '2px',
                                    fontFamily: REPORT.FONT_FACE.HEADING,
                                    // paddingBottom: "8px",
                                    paddingBottom: "10px",
                                    borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,

                                }}
                            >
                                {ChannelData.channelName.toUpperCase()} TACTICS EVALUATED

                            </h2>
                        </div>
                    </Col>

                    <Col xs="12" style={{ height: "645px" }} >
                        <div
                            style={{
                                width: "100%",
                                height: "645px",
                                paddingLeft: '60px',
                                paddingTop: '17px',
                                // marginTop:'-20px',
                                border: '1px solid rgba(255, 255, 255, .0)'
                                // border:'none'
                            }}
                        >
                            <ArrowChart Length={
                                ChannelData
                                &&
                                ChannelData.tactics.length
                            }
                                Tactics={
                                    ChannelData
                                    &&
                                    ChannelData.tactics

                                }
                                Id={ChannelData && ChannelData.channelId}
                            />
                            <BubbleChart Length={
                                ChannelData
                                &&
                                ChannelData.tactics.length
                            }
                                Tactics={
                                    ChannelData
                                    &&
                                    ChannelData.tactics

                                }
                                Id={ChannelData && ChannelData.channelId}

                            >
                                <div className="text-center" dir="ltr" style={{
                                    margin: "0 auto",
                                    // border: "1px solid red",
                                    width: "fit-content",
                                    height: "fit-content",
                                    marginLeft: 80,
                                    marginTop: -720
                                }} >
                                    <Knob
                                        value={
                                            ChannelData
                                            && Math.round(getChannelScore(
                                                ChannelData
                                            )) || 0
                                        }
                                        fgColor="#000"
                                        bgColor={REPORT.COLOR.FOOTER}
                                        thickness={0.2}
                                        readOnly={true}
                                        height={200}
                                        width={200}
                                        onChange={e => {
                                            setreadonly(10)
                                        }}
                                    />
                                </div>
                            </BubbleChart>

                        </div>



                    </Col>

                    <Col xs="12" >
                        <Row className="g-0 p-0 m-0" style={{
                            backgroundColor: "#F4EBD3",
                        }}  >

                            <Col xs="6" >
                                <div style={
                                    {
                                        margin: "0 auto",
                                        textAlign: "center",


                                    }
                                } >

                                    <img
                                        src={GetMaturityImage()}
                                        style={{
                                            height: "230px",
                                            width: "230px",
                                            // padding: "10px",
                                            textAlign: 'center',
                                            margin: "0 auto",
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs="6" >
                                <div style={{
                                    margin: "0 auto",
                                    padding: "12px",
                                    textAlign: "center"


                                }} >

                                    <Knob
                                        value={
                                            ChannelData
                                            && Math.round(getChannelScore(
                                                ChannelData
                                            )) || 0
                                        }
                                        fgColor="#000"
                                        bgColor={REPORT.COLOR.FOOTER}
                                        thickness={0.2}
                                        readOnly={true}
                                        height={150}
                                        width={150}
                                        onChange={e => {
                                            setreadonly(10)
                                        }}

                                    />
                                    <p style={{
                                        fontWeight: 700,
                                        fontSize: '18px',
                                        color: '#0D0F11',
                                        marginTop: '-11px',
                                        fontFamily: REPORT.FONT_FACE.HEADING,
                                    }}>{ChannelData.channelName.toUpperCase()}<br />SCORE&nbsp;&nbsp;
                                        {ChannelData
                                            && Math.round(getChannelScore(
                                                ChannelData
                                            )) || 0
                                        }%
                                    </p>
                                </div>
                            </Col>

                        </Row>

                    </Col>
                </Row>
                <Row>
                    <Col
                        xs="12"
                        style={{
                            position: "absolute",
                            bottom: 0,
                            zIndex: 100,
                        }}
                    >
                        <div
                            style={{
                                width: "816px",
                                padding: "5px 5px 5px 40px",
                                // border: '1px solid black',
                                backgroundColor: REPORT.COLOR.FOOTER,
                                opacity: 0.9,
                                fontWeight: 600,
                                letterSpacing: '2px',
                                fontFamily: REPORT.FONT_FACE.HEADING,
                                color: "white",
                                fontSize: REPORT.FONT_SIZE.MEDIUM,
                            }}
                        >
                            {`${ChannelData && ChannelData.channelName.toUpperCase()} - TACTICS EVALUATED`}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default TacticEvaluated
