import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DatatablesTable from "../../Tables/DatatableTables"
import firebase from "firebase/compat";
import {
  Container,
} from "reactstrap"

class Dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard</title>
          </MetaTags>
          <Container fluid>
          <Breadcrumbs title="Super Admin" breadcrumbItem="dashboard" />
          {/* <DatatablesTable /> */}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard;
