import { call, put, takeEvery } from "redux-saga/effects"

import { updateInstructionSuccess, updateInstructionFail } from "./actions"

import { UPDATE_INSTRUCTION } from "./actionTypes"

import { updateInstruction } from "../../helpers/fakebackend_helper"

function* onUpdateInstruction({ payload: data }) {
    try {
      const response = yield call(updateInstruction, data)
      yield put(updateInstructionSuccess(response))
    } catch (error) {
      yield put(updateInstructionFail(error))
    }
  }

  function* UpdateInstructionSaga() {
   
    yield takeEvery(UPDATE_INSTRUCTION, onUpdateInstruction)
  }

  export default UpdateInstructionSaga