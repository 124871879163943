export const startSurveyTourSteps = [
  {
    element: ".tour-guide-sidebar",
    popover: {
      title: "Channels and Tactics",
      description: "You can click over here to jump to a different question.",
      side: "left",
      align: 'start'
    },
  },
  {
    element: ".tour-guide-survey-options",
    popover: {
      title: "Survey Options",
      description: "Select one choice that best describes your company's status or process in the area.",
      side: "top",
      align: "start"
    },
  },
  {
    element: ".tour-guide-close-assessment",
    popover: {
      title: "Close Assessment",
      description:
        "Once you've answered every question, you'll be able to click here to close the scorecard.",
      side: "top",
      align: "start"
    },
  },
  {
    element: ".tour-guide-previous-question",
    popover: {
      // title: "Last Question",
      title: "Previous or Last Question",
      description:
        "Click here to move on to the previously attempted question.",
      side: "top",
      align: "start"
    },
  },
  {
    element: ".tour-guide-next-question",
    popover: {
      title: "Next Question",
      description:
        "Once you've selected, click here to move on to the next question.",
      side: "top",
      align: "start"
    },
  },
  {
    element: ".tour-guide-save-and-exit",
    popover: {
      title: "Save and Exit",
      description: "Click here to save your work and continue later.",
      side: "top",
      align: "start"
    },
  },
]


export const updateSelfReportScorecardTourSteps = [
  {
    element: ".tour-guide-sidebar-self-report",
    popover: {
      title: "Scorecards List",
      description: "You can click over here to jump to see Scorecards list.",
      side: "bottom",
      align: 'end'
    },
  },
  {
    element: ".tour-guide-sidebar-self-report-account-owner",
    popover: {
      title: "Sidebar Menus",
      description: "Using these menus, you can create users, scorecards, and view the list of scorecards.",
      side: "reght",
      align: 'center'
    },
  },
  {
    element: ".tour-guide-scorecard-name",
    popover: {
      title: "Scorecard Name",
      description: "This is the name of Scorecard",
      side: "right",
      align: 'start'
    },
  },
  {
    element: ".tour-guide-client-information",
    popover: {
      title: "Client Information",
      description: "This is the client information that needs to be filled out before taking an assessment. '*' indicates the required information to proceed further.",
      side: "right",
      align: 'center'
    },
  },
  {
    element: ".tour-guide-channels",
    popover: {
      title: "Listed Channels",
      description: "These are the channels listed, with highlighted channels selected for assessment.",
      side: "left",
      align: 'center'
    },
  },
  {
    element: ".tour-guide-back",
    popover: {
      title: "Back",
      description: "Click here to return to the previous page.",
      side: "top",
      align: 'center'
    },
  },
  {
    element: ".tour-guide-next",
    popover: {
      title: "Next",
      description: "Once you have updated the client information, click here to save it.",
      side: "top",
      align: 'center'
    },
  },
]