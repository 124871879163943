import { getLoginUserClaims } from "./actions";
import {
  GET_SCORECARDS_LIST_SUCCESS,
  GET_SCORECARDS_LIST_FAIL,
  CREATE_SCORECARD_SUCCESS,
  CREATE_SCORECARD_FAIL,
  CLEAR_SCORECARD_LIST,
  CREATE_SCORECARD_CLEAR,
  GET_SCORECARDS_LIST_SUCCESS_FOR_ACCOUNT_USER,
  GET_SCORECARDS_LIST_FAIL_FOR_ACCOUNT_USER,
  GET_SCORECARD_DETAIL,
  GET_SCORECARD_DETAIL_SUCCESS,
  GET_SCORECARD_DETAIL_FAIL,
  GET_SCORECARD_DETAIL_CLEAR,
  UPDATE_SCORECARD_DETAIL_SUCCESS,
  UPDATE_SCORECARD_DETAIL_FAIL,
  UPDATE_SCORECARD_DETAIL_CLEAR,
  CHANGE_SCORECARD_DETAIL,
  GET_LOGINUSER_CLAIMS,
  IS_ACCOUNT_OWNER,
  SCORECARD_PATH,

  GET_THREE_SCORECARDS_SUCCESS,
  GET_THREE_SCORECARDS_FAIL,
  CLEAR_GET_THREE_SCORECARDS,

} from "./actionTypes";

const INIT_STATE = {
  scorecardsList: [],
  scorecardsListForAccountUser: [],
  scorecardDetail: {},
  scorecardData: {},
  updatedScorecardDetail: {},
  isAccountOwner: false,
  scorecard_path: "/view/scorecards",
  listOfThreeScorecards: [],
};

const GetScorecardsListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCORECARDS_LIST_SUCCESS:
      return {
        ...state,
        scorecardsList: action.payload,
      };

    case GET_SCORECARDS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_SCORECARD_LIST:
      return{
        ...state,
        scorecardsList:[]
      };
    
    case GET_THREE_SCORECARDS_SUCCESS:
      return {
        ...state,
        listOfThreeScorecards: action.payload
      };
    
    case GET_THREE_SCORECARDS_FAIL:
      return {
        ...state,
        error: action.payload 
      }  

    case CLEAR_GET_THREE_SCORECARDS: 
      return {
        ...state,
        listOfThreeScorecards: []
      }  

    case GET_SCORECARDS_LIST_SUCCESS_FOR_ACCOUNT_USER:
      return {
        ...state,
        scorecardsListForAccountUser: action.payload,
      };

    case GET_SCORECARDS_LIST_FAIL_FOR_ACCOUNT_USER:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_SCORECARD_SUCCESS:
      return {
        ...state,
        scorecardData: action.payload,
      };

    case CREATE_SCORECARD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_SCORECARD_CLEAR:
      return {
        ...state,
        scorecardData: {},
      };

    case GET_SCORECARD_DETAIL_SUCCESS:
      return {
        ...state,
        scorecardDetail: action.payload,
      };

    case GET_SCORECARD_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_SCORECARD_DETAIL_CLEAR:
      return {
        ...state,
        scorecardDetail: {},
      };

    case UPDATE_SCORECARD_DETAIL_SUCCESS:
      return {
        ...state,
        updatedScorecardDetail: action.payload,
      };

    case UPDATE_SCORECARD_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_SCORECARD_DETAIL_CLEAR:
      return {
        ...state,
        updatedScorecardDetail: {},
      };
    case CHANGE_SCORECARD_DETAIL:
      return {
        ...state,
        scorecardDetail: action.payload,
      };

    case IS_ACCOUNT_OWNER:
      return {
        ...state,
        isAccountOwner: action.payload
      }


      case SCORECARD_PATH:
        return{
          ...state,
          scorecard_path: action.payload
        }

    default:
      return state;
  }
};

export default GetScorecardsListReducer;