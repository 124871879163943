import { actionTypes } from "redux-form";
import {
    GET_CHANNEL_DETAILS,
    GET_CHANNEL_DETAILS_SUCCESS,
    GET_CHANNEL_DETAILS_FAIL,

    UPDATE_CHANNEL_DETAILS,
    UPDATE_CHANNEL_DETAILS_SUCCESS,
    UPDATE_CHANNEL_DETAILS_FAIL,
    CLEAR_CHANNEL_DETAILS,
    CLEAR_UPDATE_CHANNEL_DETAILS
} from "./actionTypes"

const INIT_STATE = {
    channelDetails: {},
    updateChannel: {}
};

const ChannelDetailsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CHANNEL_DETAILS_SUCCESS:
            return {
                ...state,
                channelDetails: action.payload

            };

        case GET_CHANNEL_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload

            };

        case UPDATE_CHANNEL_DETAILS_SUCCESS:
            return {
                ...state,
                updateChannel: action.payload
            };

        case UPDATE_CHANNEL_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_CHANNEL_DETAILS:
            return {
                ...state,
                channelDetails: {},
            }

        case CLEAR_UPDATE_CHANNEL_DETAILS:
            return {
                ...state,
                updateChannel: {},
            }

        default:
            return state;


    }


}

export default ChannelDetailsReducer