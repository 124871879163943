import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  CardText,
  Spinner,
} from "reactstrap"

import classnames from "classnames"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import "./New_Dashboard.css"
import {
  getScorecardsList,
  ScorecardRoutingHandler,
  clearScorecardList,
  getListOfThreeScorecards,
} from "store/actions"
import { useHistory } from "react-router-dom"
import NotFound from "components/Common/NotFound/NotFound.component"
import { ACCOUNT_OWNER, NETWORK_ERROR } from "config/AlertMessage.config"

import { useSelector, useDispatch } from "react-redux"

import AddCredit from "./AddCredit/AddCredit"

//import images

const ScorecardList = () => {
  const [activeTab, toggleTab] = useState("1")
  const [isDataAvail, setIsDataAvail] = useState(true)
  const [paginationReset, setPaginationReset] = useState(false)
  const [accountOwnerType, setAccountOwnerType] = useState(null)

  const history = useHistory()
  const dispatch = useDispatch()
  const {
    scorecardsList,
    scorecardDetail,
    listOfThreeScorecards,
    accountDetails,
  } = useSelector(state => ({
    scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
    scorecardsList: state.GetScorecardsListReducer.scorecardsList,
    listOfThreeScorecards: state.GetScorecardsListReducer.listOfThreeScorecards,
    accountDetails: state.SuperAdmin.accountDetails,
  }))

  // useEffect(() => {
  //   dispatch(getScorecardsList(JSON.parse(localStorage.getItem("authUser")).uid));
  // }, []);

  useEffect(() => {
    dispatch(
      getListOfThreeScorecards(JSON.parse(localStorage.getItem("authUser")).uid)
    )
  }, [])

  // useEffect(() => {

  //     dispatch(clearScorecardList())

  // },[!JSON.parse(localStorage.getItem("authUser")).uid])

  useEffect(() => {
    if (listOfThreeScorecards.status === 404) {
      setIsDataAvail(false)
    }
    // if (scorecardsList[0]) {
    //   var search_bar = document.querySelector("#search-bar-0")
    //   search_bar.setAttribute("autoComplete", "off")
    //   search_bar.addEventListener("focus", () => setPaginationReset(true))

    // }
  }, [listOfThreeScorecards])

  // useEffect to find out the permission of scorecard type of a account owner
  useEffect(() => {
    if (accountDetails && accountDetails.scorecardVersions) {
      const { scorecardVersions } = accountDetails

      if (
        scorecardVersions.proScorecard === true &&
        scorecardVersions.selfReportScorecard === true
      ) {
        setAccountOwnerType("Both")
      } else if (
        scorecardVersions.proScorecard === true &&
        scorecardVersions.selfReportScorecard === false
      ) {
        setAccountOwnerType("Pro")
      } else if (
        scorecardVersions.proScorecard === false &&
        scorecardVersions.selfReportScorecard === true
      ) {
        setAccountOwnerType("Self Report")
      }
    } else {
      setAccountOwnerType("Old")
    }
  }, [accountDetails])

  // console.log("latestThreeScorecards: ", listOfThreeScorecards)
  // const marginStyle = listOfThreeScorecards && listOfThreeScorecards.length > 3 ? {
  //   marginRight: '5px !important'
  // } : {
  //   marginLeft: '5px !important'
  // }

  const columns = [
    {
      dataField: "companyName",
      text: "Company Name",
      // sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link
          to="#"
          style={{ color: "#05727B", fontSize: "17px", fontWeight: "bold" }}
        >
          {row.companyName}
        </Link>
      ),
    },
    {
      dataField: "scorecardName",
      text: "Scorecard Name",
      // sort: true,
    },
    {
      dataField: "createdOn",
      text: "Created On",
      // sort: true,
      style: {
        width: '120px'
      }
    },
    {
      dataField: "status",
      text: "Status",
      style: {
        width: '120px'
      }
    },
    {
      dataField: "scorecardVersion",
      text: "Scorecard Type",
      // sort: true,
      style: {
        width: '120px',
        textAlign: 'center'
      }
    },
    {
      dataField: "viewCard",
      text: (
        <button
          type="button"
          className="view-all-scorecard-btn"
          onClick={() => {
            history.push("/view/scorecards")
          }}
        >
          View All Scorecards
          <i className="mdi mdi-arrow-right"></i>
        </button>
      ),
      sort: false,
      headerStyle: {
        textAlign: 'center'
      },
      style: {
        textAlign: 'center'
      }
    },
  ]

  const productData = !listOfThreeScorecards[0] ? [] : listOfThreeScorecards.map((user, i) => {
    return {
      id: ++i,
      companyName: user.companyName ? user.companyName : "---",
      scorecardName: user.scorecardName,
      createdOn: new Date(user.createdOn._seconds * 1000)
        .toDateString()
        .slice(3),
      status: user.status,
      // scorecardVersion: user.scorecardVersion,
      scorecardVersion: (
        <div style={{ textAlign: 'left' }}>
          {user.scorecardVersion}
        </div>
      ),

      viewCard: (
        <button
          className="view-card-btn"
          onClick={() => {
            dispatch(ScorecardRoutingHandler(document.location.pathname)),
              user.status === "Not started"
                ? history.push("/view/scorecard/" + user.id)
                : history.push("/viewScoringSummary/" + user.id)
          }}
        >
          View Card
        </button>
      ),
    }
  })

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (productData).length }];

  const pageOptions = {
    // page: 1,
    // sizePerPage: 10,
    onPageChange: () => setPaginationReset(false),
    // totalSize: productData.length, // replace later with size(customers),
    custom: true,
    sizePerPageList,
  }

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <Row>
          <Col
            className="scorecards-black-card"
            lg="1"
            md="1"
            sm="12"
            xl="1"
            xs="12"
          >
            <h2>Scorecards</h2>
          </Col>
          <Col lg="11" md="11" sm="12" xl="11" xs="12">
            {!listOfThreeScorecards[0] ? (
              <div
                style={{
                  width: "100%",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: "center",
                  height: "250px",
                }}
              >
                {!isDataAvail ? (
                  <>
                    {listOfThreeScorecards.status === 404 ? (
                      <NotFound
                        Message={ACCOUNT_OWNER.EMPTY_SCORECARDS}
                        Icon={() => (
                          <i
                            className="mdi mdi-book-edit"
                            style={{
                              borderBottom: "1px solid #000",
                            }}
                          ></i>
                        )}
                        IsButton
                        Url={
                          accountOwnerType && accountOwnerType === "Pro"
                            ? "/create/scorecard"
                            : accountOwnerType &&
                              accountOwnerType === "Self Report"
                              ? "/create/self-report/scorecard"
                              : accountOwnerType && accountOwnerType === "Both"
                                ? "/create/scorecard"
                                : "/create/scorecard"
                        }
                        btnText="Create New Scorecard"
                      />
                    ) : null}
                  </>
                ) : (
                  <Spinner
                    style={{
                      width: "60px",
                      height: "60px",
                      margin: "calc(200px - 100px)",
                      color: "#05727B",
                    }}
                    role="grid"
                    type="border"
                  />
                )}
              </div>
            ) : (
              <PaginationProvider
                pagination={
                  paginationReset
                    ? paginationFactory({
                      ...pageOptions,
                      page: 1,
                    })
                    : paginationFactory(pageOptions)
                }
                keyField="id"
                columns={columns}
                data={productData}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <div className="table-responsive Rules-Container">
                          <BootstrapTable
                            // rowEvents={rowEvents}
                            keyField={"id"}
                            responsive
                            headerClasses="tableHeader bordered border-bottom-dark border-2"
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle custom-scorecard-table"}
                            headerWrapperClasses={""}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  )
}

export default ScorecardList
