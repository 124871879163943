import {
    ADD_EDITOR_DATA,
    ADD_EDITOR_DATA_SUCCESS,
    ADD_EDITOR_DATA_FAIL,
    CLEAR_EDITOR_DATA,
    GET_EDITOR_DATA,
    GET_EDITOR_DATA_SUCCESS,
    GET_EDITOR_DATA_FAIL
} from "./actionTypes"

const INIT_STATE = {
    addData: [],
    getData: []
}

const addEditorReducer = (state = INIT_STATE , action) => {

    switch(action.type) {
        case ADD_EDITOR_DATA_SUCCESS:
            return{
               ...state,
               
               addData: [...state.addData, action.payload],
               
               
             }
             case ADD_EDITOR_DATA_FAIL:
             return {
               ...state,
               addData: [],
              
             }
        case CLEAR_EDITOR_DATA : 
        return {
            ...state,
            addData: []
        }

        case GET_EDITOR_DATA_SUCCESS:
            return{
                ...state,
                getData: action.payload
            }

        case GET_EDITOR_DATA_FAIL:
            return{
                ...state,
                error:action.payload
                

            }
        default:
        return state
    }

}
export default addEditorReducer