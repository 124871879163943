import { GET_CONTENT_EDITOR_TABLE, GET_CONTENT_EDITOR_TABLE_SUCCESS, GET_CONTENT_EDITOR_TABLE_FAIL, UPDATE_CONTENT_EDITOR_STATE, CLEAR_CONTENT_EDITOR_TABLE } from "./actionTypes"


export const getContentEditorData = () => ({
    type: GET_CONTENT_EDITOR_TABLE,
});

export const getContentEditorDataSuccess = data => ({
    type: GET_CONTENT_EDITOR_TABLE_SUCCESS,
    payload: data,

});
export const getContentEditorDataFail = error => ({
    type: GET_CONTENT_EDITOR_TABLE_FAIL,
    payload: error,

});

export const updateContentEditorState = data => {
    return {
        type: UPDATE_CONTENT_EDITOR_STATE,
        payload: data
    }
}

export const clearContentEdiotosDataState = () => {
    return {
        type: CLEAR_CONTENT_EDITOR_TABLE,
    }
}

