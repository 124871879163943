//Create report template.
export const CREATE_REPORT_TEMPLATE =  "CREATE_REPORT_TEMPLATE"
export const CREATE_REPORT_TEMPLATE_SUCCESS =  "CREATE_REPORT_TEMPLATE_SUCCESS"
export const CREATE_REPORT_TEMPLATE_FAIL =  "CREATE_REPORT_TEMPLATE_FAIL"
export const CREATE_REPORT_TEMPLATE_CLEAR = "CREATE_REPORT_TEMPLATE_CLEAR"

// GEt report template.
export const GET_REPORT_TEMPLATE =  "GET_REPORT_TEMPLATE"
export const GET_REPORT_TEMPLATE_SUCCESS =  "GET_REPORT_TEMPLATE_SUCCESS"
export const GET_REPORT_TEMPLATE_FAIL =  "GET_REPORT_TEMPLATE_FAIL"
export const GET_REPORT_TEMPLATE_CLEAR = "GET_REPORT_TEMPLATE_CLEAR"

export const UPDATE_GET_REPORT_TEMPLATE = "UPDATE_GET_REPORT_TEMPLATE"