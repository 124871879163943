import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { Link } from "react-router-dom"

import {



  FormGroup,
  Button,

  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
  
} from "reactstrap";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { getScorecardsListForAccountUser, getAccountHolderUserDetails, getAccountUserData } from "store/actions";
import { getAccountDetails } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { height } from "@mui/system";
import NotFound from "components/Common/NotFound/NotFound.component";
import { ACCOUNT_USER, NETWORK_ERROR } from "config/AlertMessage.config";
//Import Breadcrumb

//import "./datatables.scss"
// CSS Imports
import "./UserDashboard.style.css"

const AccoutnUserDashboard = () => {
  const [isDataAvail, setIsDataAvail] = useState(true)
  const [paginationReset, setPaginationReset] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const { scorecardsListForAccountUser, accountUserDetails, accountDetails } = useSelector(state => ({
    accountUserDetails: state.AccountHolderUsersReducer.accountUserDetails,
    scorecardsListForAccountUser: state.GetScorecardsListReducer.scorecardsListForAccountUser,
    accountDetails: state.SuperAdmin.accountDetails
  }));

  useEffect(() => {
    dispatch(getScorecardsListForAccountUser(JSON.parse(localStorage.getItem("authUser")).uid)),
      dispatch(getAccountHolderUserDetails(JSON.parse(localStorage.getItem("authUser")).uid))


  }, [dispatch])


  useEffect(() => {
    dispatch(getAccountUserData(JSON.parse(localStorage.getItem("authUser")).uid))
  }, [dispatch])

  // console.log("account details", accountDetails)

  useEffect(() => {
    // var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)

    if (scorecardsListForAccountUser.status === 404) {
      setIsDataAvail(false)
    }
    if (scorecardsListForAccountUser[0]) {
      var search_bar = document.querySelector("#search-bar-0")
      search_bar.setAttribute("autoComplete", "off")
      search_bar.addEventListener("focus", () => setPaginationReset(true))

      // clearTimeout(WaitForData)
    }
  }, [scorecardsListForAccountUser])

  const columns = [
    //   {
    //   dataField: 'userName',
    //   text: 'User Name',
    //   sort: true,
    // }, 
    {
      dataField: 'companyName',
      text: 'Company Name',
      sort: true, 
       // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
       <Link to="#"  style ={{color:'#05727B', fontSize:'17px' , fontWeight:'bold'}}>
         {row.companyName}
       </Link>
     ),
    }, {
      dataField: 'scorecardName',
      text: 'Scorecard Name',
      sort: true
    },
    {
      dataField: 'createdOn',
      text: 'Created On',
      sort: true, 
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
       <Link to="#"  style={{color:'#495057', textAlign: "center", width: "140px" }}>
         {row.createdOn}
       </Link>
     ),
    },
    {
      dataField: 'lastUpdatedOn',
      text: 'Last Updated On',
      sort: true
    },
    {
      dataField: 'scorecardVersion',
      text: 'Scorecard Type',
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true
    },
    {
      dataField:'viewCard',
      text: '',
      sort: false,
    }

  ];

  const ScorecardName = (name) => {
    return(
      <div style = {{
        width:"100%",
        textAlign:"left",
        color:"#05727B",
        fontSize:"16px"
       
      }}>
        {name}

      </div>
    )
  }


  // Table Data
  const productData = !scorecardsListForAccountUser[0]
    ? []
    : scorecardsListForAccountUser.map((scorecard, i) => {
        return {
          id: ++i,
          companyName: scorecard.companyName ? scorecard.companyName : "---",
          scorecardName: scorecard.scorecardName,
          createdOn: new Date(scorecard.createdOn._seconds * 1000)
            .toDateString()
            .slice(3),

          lastUpdatedOn: new Date(scorecard.lastUpdatedOn._seconds * 1000)
            .toDateString()
            .slice(3),
          scorecardVersion: !scorecard.scorecardVersion
            ? "NA"
            : scorecard.scorecardVersion,
          status: scorecard.status,
          viewCard:
            scorecard.scorecardVersion === "Self Report" &&
            scorecard.status === "Report Only" ? (
              <button className="au-closed-btn-css">Closed</button>
            ) : (
              <button
                className="au-view-card-btn"
                onClick={() => {
                  scorecard.status === "Not started"
                    ? history.push("/view/scorecard/" + scorecard.id)
                    : history.push("/viewScoringSummary/" + scorecard.id)
                }}
              >
                View Card
              </button>
            ),
        }
      })


    
  
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];


  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (productData).length }];

  const pageOptions = {
    // page:1,
    onPageChange: () => setPaginationReset(false),
    // sizePerPage: 10,
    // totalSize: productData.length, // replace later with size(customers),
    custom: true,
    sizePerPageList,
    // paginationSize: 4,
    // pageStartIndex: 1,
    // showTotal: true,

  }

  // Select All Button operation
  //   const selectRow = {
  //     mode: 'checkbox'
  //   }
  const selectRow = {
    mode: 'checkbox'
  }

  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     history.push("/view/scorecard/" + row.actions)
  //   }
  // }
  const { SearchBar } = Search;
  return (

    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          {/* <title>Mambo - Scorecard List </title> */}
          {/* {
            accountUserDetails.creatorName ?
              //  <title>Mambo - {accountUserDetails.creatorName + " - Scorecard List"}</title>
              // <title>Mambo - {`${accountUserDetails.creatorName} : Scorecard List`}</title>
              : */}
              <title>Mambo - My Scorecards</title>
          {/* } */}
        </MetaTags>

        <div className="container-fluid">
          <Row>
            {
              !scorecardsListForAccountUser[0] ?
                <div style={
                  {
                    width: "100%",
                    textAlign: "center",
                    height: "400px"
                  }
                } >
                  {
                    !isDataAvail ?
                      <>
                        {
                          scorecardsListForAccountUser.status === 404 ?
                            <NotFound Message={ACCOUNT_USER.EMPTY_SCORECARDS} Icon={() => (
                              <i className="fas fa-list"
                                style={{ borderBottom: "1px solid #000" }}
                              ></i>
                            )} />
                            :
                            <NotFound Message={NETWORK_ERROR} Icon={() => (
                              <i className="bx bx-error"
                                style={{ borderBottom: "1px solid #000", fontSize: "40px" }}
                              ></i>
                            )}
                              IsButton
                              btnText="Reload"
                              Reload
                            />
                        }
                      </>
                      :
                      <Spinner
                        style={{
                          width: "60px", height: "60px",
                          margin: "calc(200px - 30px)",
                          color:'#05727B'

                        }}
                        role="grid"
                        type="border"
                        // color="primary"
                      />
                  }
                </div>
                :
                <>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <PaginationProvider
                          pagination={
                            paginationReset ?
                              paginationFactory({
                                ...pageOptions,
                                page: 1
                              }
                              ) :
                              paginationFactory(pageOptions)
                          }
                          keyField='id'
                          columns={columns}
                          data={productData}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField='id'
                              columns={columns}
                              data={productData}
                              search={{
                                afterSearch: () => {
                                  // console.log(paginationProps)
                                }
                              }}
                            >
                              {toolkitProps => (
                                <React.Fragment>

                                  <Row className="mb-2">
                                    <Col md="9">
                                      <h2 className="fw-bold" style={{color:'#0D0F11'}}>
                                        My Scorecards
                                      </h2>
                                    </Col>
                                    <Col md="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                            style={{ width: "100%", float: "right" }}
                                            onClear={() => setPaginationReset(false)}
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          bootstrap4
                                          // rowEvents={rowEvents}
                                          keyField={"id"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap table-hover"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}

                                        />

                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />

                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )
                              }
                            </ToolkitProvider>
                          )
                          }</PaginationProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </>
            }
          </Row>

        </div>
      </div>
    </React.Fragment>
  )
}
2
export default AccoutnUserDashboard
