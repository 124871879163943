import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import NotFound from "components/Common/NotFound/NotFound.component";
import { SUPER_ADMIN } from "config/AlertMessage.config";



const ManagePrice = () => {
// const [isEdit, setIsEdit] = useState(false)

// const products = [
//   { id: 1, credit:"1-5", creditPrice: "$ 30", edit: (
//     <i
//       className="fas fa-pencil-alt text-primary"
//       // onClick={}
//       style={{
//         textAlign: "center",
//         fontSize: "12px",
//         width: "28px",
//         height: "24px",
//         cursor: "pointer",
//         borderRadius: "2px",
//         border:'1px solid #3D57A3',
//         padding: "6px",
//       }}
//     ></i>
//   ),},
//   { id: 2, credit: "6-10", creditPrice: "$ 40",edit:<i
//   className="fas fa-pencil-alt text-primary"
//   style={{
//     textAlign: "center",
//     fontSize: "12px",
//     width: "28px",
//     height: "24px",
//     cursor: "pointer",
//     borderRadius: "2px",
//     border:'1px solid #3D57A3',
//     padding: "6px",
//   }}
// ></i> },
//   { id: 3, credit: "11-15", creditPrice: "$ 50",edit: <i
//   className="fas fa-pencil-alt text-primary"
//   style={{
//     textAlign: "center",
//     fontSize: "12px",
//     width: "28px",
//     height: "24px",
//     cursor: "pointer",
//     borderRadius: "2px",
//     border:'1px solid #3D57A3',
//     padding: "6px",
//   }}
// ></i> },
//   { id: 4, credit: "16-20", creditPrice: "$ 60", edit: <i
//   className="fas fa-pencil-alt text-primary"
//   style={{
//     textAlign: "center",
//     fontSize: "12px",
//     width: "28px",
//     height: "24px",
//     cursor: "pointer",
//     borderRadius: "2px",
//     border:'1px solid #3D57A3',
//     padding: "6px",
//   }}
// ></i> },
//   { id: 5, credit: "21-25", creditPrice: "$ 70", edit: <i
//   className="fas fa-pencil-alt text-primary"
//   cellEdit={cellEditFactory({ mode:"click" })}
//   style={{
//     textAlign: "center",
//     fontSize: "12px",
//     width: "28px",
//     height: "24px",
//     cursor: "pointer",
//     borderRadius: "2px",
//     border:'1px solid #3D57A3',
//     padding: "6px",
//   }}
//   onClick={{}}
// ></i> },
// ]

// const columns = [

//   {
//     dataField: "credit",
//     text: "Credit",
//   },
//   {
//     dataField: "creditPrice",
//     text: "Value of credit",
//   },
//   {
//     dataField: "edit",
//     text: "Edit",
//   },
// ]

  const [isDataAvail, setIsDataAvail] = useState(true)
  useEffect(() => {
    var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)
    // if (scorecardsList[0]) {
    //   var search_bar = document.querySelector("#search-bar-0")
    //   search_bar.setAttribute("autoComplete", "off")
    //   clearTimeout(WaitForData)
    // }

  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Manage Price</title>
        </MetaTags>
        <div className="container-fluid">
          <div style={
            {
              width: "100%",
              textAlign: "center",
              height: "400px"
            }
          } >
            {
              !isDataAvail ?
                <NotFound Message={SUPER_ADMIN.EMPTY_MANAGE_PRICE} Icon={() => (
                  <i className="fas fa-hand-holding-usd"
                  style={{
                      borderBottom: "1px solid #000",
                      paddingBottom:'5px'
                    }}
                  ></i>
                )} />
                :
                <Spinner
                  style={{
                    width: "60px", height: "60px",
                    margin: "calc(200px - 30px)",

                  }}
                  role="grid"
                  type="border"
                  color="primary"
                />
            }
          </div>

          {/* <Row>
            <Col>
              <Card> */}
                {/* <CardBody style={{textAlign:"center"}}> */}
                  {/* <CardTitle>Discount Rule</CardTitle> */}
                  {/* <div className="table-responsive">
                    <BootstrapTable
                      keyField="id"
                      data={products}
                      columns={columns}
                      
                      cellEdit={cellEditFactory({ mode:"click" })}
                    />
                  </div> */}
                {/* </CardBody> */}
              {/* </Card>
            </Col>
          </Row> */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManagePrice
