import { color, fontSize } from "@mui/system"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Grid } from "@mui/material"
// import img from "../../../../assets/images/reportScorcard/executiveCardimg.PNG"
// import { CKEditor } from "@ckeditor/ckeditor5-react"
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// // import {mainDivStyle} from './pageFifth.cssConfig'
import { REPORT_PAGE, REPORT, RECOMMENDATIONS } from "../config/style.config"
import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
} from "reactstrap"
import { Opacity } from "@mui/icons-material"
import Footer from "components/VerticalLayout/Footer"
import {
  getScorecardReport,
  UpdateScorecardReport,
  ClearUpdateScorecardReport,
} from "store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams, useHistory } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"

import ChildRecommendationPage from "./ChildIndex"

const ChannelIndex = (props) => {

  const PROPS = props
  const { Content_recomendation, ChannelData } = PROPS

//  console.log("Content_recomendation...", Content_recomendation)


  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const [isPageAdded, setIsPageAdded] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const [saveTacticData, setSaveTacticData] = useState({})
  const [initialtactic, setInitialTactic] = useState({})
  const [editTacticRecommendation, setEditTacticRecommendation] = useState([])

  const [editRecommendation, setEditRecommendation] = useState([])
  const [saveRecommendation, setSaveRecommendation] = useState([])

  //
  const [testTR, setTestTR] = useState([])

  const [] = useState()



  useEffect(() => {
    // dispatch(getScorecardReport(params.scorecardId))
    setEditTacticRecommendation([
      {
        pageId: 0,
      },
    ])
  }, [dispatch])



  useEffect(() => {
    setEditTacticRecommendation(Content_recomendation)
  }, [Content_recomendation])

  // console.log('jskdjskdjsj', editTacticRecommendation)
  // console.log("CONTENT RECOMMENDATION .... ",Content_recomendation.length)

  useEffect(() => {
    if (Content_recomendation.length / 6 <= 1 ) {
      // console.log("in if",Content_recomendation.length / 5 )
      setTestTR([
        { tactics: [...Content_recomendation.slice(0, 6)] },
        //{ tactics: [...Content_recomendation.slice(6, 10)] },
        // { tactics: [...Content_recomendation.slice(7, 8 )] }

      ])
    }
    else if(Content_recomendation.length / 6 > 1 ){
      setTestTR([
        { tactics: [...Content_recomendation.slice(0, 6)] },
        { tactics: [...Content_recomendation.slice(6, 10)] }

      ])
     

    }
    // else if (Content_recomendation.length / 5 > 2) {
    //   // console.log("in else if",Content_recomendation.length / 5)

    //   setTestTR([
    //     { tactics: [...Content_recomendation.slice(0, 5)] },
    //     { tactics: [...Content_recomendation.slice(5, 10)] },
    //     { tactics: [...Content_recomendation.slice(10, 15)] },
    //   ])
    // } 
    // else {
    //   setTestTR([{ tactics: [...Content_recomendation.slice(0, 6)] }])
    // }
  }, [Content_recomendation])

  const AddNewPage = () => {
    setEditTacticRecommendation(e => [
      ...e,
      {
        pageId: 1,
        contents: "",
      },
    ])
  }



  const handleChangeRecommendation = (e, id) => {
    var myRecommendation = editTacticRecommendation.map(tactic => {
      if (tactic.tacticId === id) {
        return {
          ...tactic,
          recommendation: e,
        }
      } else {
        return tactic
      }
    })
    setEditTacticRecommendation(myRecommendation)
  }

  //
  // console.log("Edit tactic recommendation...", editTacticRecommendation)
  // console.log("TEST-TR", testTR)


  const AddRecommendation = (data, pageId) => {
    var temp = {
      pageId: pageId,
      contents: data,
    }
    var temp_1 = [...editTacticRecommendation]
    temp_1[pageId] = temp

    console.log(temp_1)
    setEditTacticRecommendation(temp_1)
  }

  return (
    <>
      {/* <div className="page-content">
        <MetaTags>
          <title> Mambo -Report</title>
        </MetaTags>
        <Container
          fluid={true}
          style={{
            transform: "scale(.8) translate(-130px, -132px) ",
            width: "1400px",
          }}
        >
         
          <Row> */}
      <Col xs="12">
        {/* <Card>
                <CardBody> */}
        {testTR[0] &&
          testTR.map((tactic_1, x) => (
            <div
            
              key={x}
            >
            
              <ChildRecommendationPage
                index={x}
                addPage={AddNewPage}
                HandlePageAdded={e => setIsPageAdded(e)}
                HANDLE_CHANGE_RECOMMENDATION={() => handleChangeRecommendation(e, id)}

                CHANNEL_DATA={ChannelData}
                Data_Length={editTacticRecommendation.length}
                // DATA_AVAIL={scorecardReport_data.selectedChannels}            // entire selected data
                DATA_AVAIL={Content_recomendation}                             // data for each channel
                IsPageAdded={isPageAdded}
                RECOMMENDATION_DATA={tactic_1}
                SAVE_RECOMMENDATION={AddRecommendation}
                // IS_SAVED={isSaved}
                SET_IS_SAVED={e => setIsSaved(e)}
                // HandleSaveBtn={handleSave}
                TacticRecommendation={editTacticRecommendation}
              />
              {/* {console.log(tactic_1)}                                  */}

              {/* <span
                          style={{
                            display: "block",
                            width: "100%",
                            height: "80px",
                          }}
                        ></span> */}
            </div>
          ))}
        {/* </CardBody>
              </Card> */}
      </Col>
      {/* </Row>
        </Container>
      </div> */}
    </>
  )
}

export default ChannelIndex
