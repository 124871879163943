import {
    ADD_NEW_USER,
    ADD_USER_SUCCESS,
    ADD_USER_FAIL,
    CLEAR_USER_STATE
} from "./actionTypes"


export const AddNewUser = (data) =>{
return {
    type: ADD_NEW_USER,
    payload: data
}
}

export const AddUserSuccess = (data) =>{
    return {
        type: ADD_USER_SUCCESS,
        payload: data
    }
}

export const AddUserFail = (error) =>{
    return {
        type: ADD_USER_FAIL,
        payload: error
    }
}

export const ClearAddUser = () => {
    return{
        type: CLEAR_USER_STATE,
    }
}