import { CLEAR_ACCOUNT_OWNER_DETAILS } from "store/SuperAdminTable/actionTypes"
import {

  GET_ACCOUNT_HOLDER_USERS_SUCCESS,
  GET_ACCOUNT_HOLDER_USERS_FAIL,
  GET_ACCOUNT_HOLDER_USER_DETAILS_SUCCESS,
  GET_ACCOUNT_HOLDER_USER_DETAILS_FAIL,
  UPDATE_ACCOUNT_HOLDER_USERS_STATE,
  CLEAR_ACCOUNT_HOLDER_USER_DETAILS,
  UPDATE_ACCOUNT_HOLDER_USERS_DETAILS_STATE,
  CLEAR_ACCOUNT_HOLDER_USERS_STATE
} from "./actionTypes"

const INIT_STATE = {
  accountHolderUsers: [],
  accountUserDetails: [],
  error: {},
}

const AccountHolderUsersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ACCOUNT_HOLDER_USERS_SUCCESS:
      return {
        ...state,
        accountHolderUsers: action.payload,
      }

    case GET_ACCOUNT_HOLDER_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ACCOUNT_HOLDER_USERS_STATE:
      return {
        ...state,
        accountHolderUsers: action.payload,
      }

    case CLEAR_ACCOUNT_HOLDER_USERS_STATE:
      return {
        ...state,
        accountHolderUsers: [],
      }

    case GET_ACCOUNT_HOLDER_USER_DETAILS_SUCCESS:
      return {
        ...state,
        accountUserDetails: action.payload,
      }

    case GET_ACCOUNT_HOLDER_USER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case CLEAR_ACCOUNT_HOLDER_USER_DETAILS:
      return {
        ...state,
        accountUserDetails: []
      }
    case UPDATE_ACCOUNT_HOLDER_USERS_DETAILS_STATE:
      return {
        ...state,
        accountUserDetails: action.payload,
      }
    default:
      return state
  }
}

export default AccountHolderUsersReducer
