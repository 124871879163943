import React from 'react';
import { REPORT, REPORT_PAGE } from '../config_v0_1/style.config';
import {
    Row,
    Col,
} from "reactstrap";

const CompanyInformationPage = (props) => {
    const PROPS = props;
    const { Company_Information } = PROPS
    return (
        <>
            <Col xs="12" className="p-0 m-0 ">
                <div style={REPORT_PAGE}>
                    <Row style={REPORT_PAGE} >
                        <Col xs="12" >
                            <div style={{
                                width: "100%",
                                height: REPORT_PAGE.height,
                                // border: "1px solid grey"
                                // position: "absolute"
                            }} >

                                <Row style={{ width: "816px" }} className="p-0 m-0" >
                                    <Col xs="12" >
                                        <div style={{
                                            // margin: "auto",
                                            // width: "90%",
                                            width: "100%",
                                            height: "100px",
                                            padding: "30px 40px 0 30px",
                                            position: "relative",
                                            textTransform: "uppercase"
                                        }} >

                                            <h1 style={{
                                                fontSize: REPORT.FONT_SIZE.XLARGE,
                                                fontFamily: REPORT.FONT_FACE.HEADING,
                                                letterSpacing: '2px',
                                                paddingBottom: '10px',
                                                fontWeight: 600,
                                                color: '#0D0F11',
                                                // paddingBottom: "8px",
                                                borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                                            }}>
                                                Company Information
                                            </h1>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="1" />
                                    <Col xs="10" >
                                        <div style={{
                                            fontSize: REPORT.FONT_SIZE.SMALL,
                                        }}>
                                            <Row className='mt-4'>
                                                <Col xs={6}>
                                                    <i
                                                        className="bx bx-chevron-up-square"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i>
                                                    <label className='company-information-labels'>COMPANY NAME</label><br></br>
                                                    <p className='ms-4 company-information-value'>{Company_Information.companyName || "N.A."}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <i
                                                        className="bx bx-user"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i><label className='company-information-labels'>CONTACT NAME</label><br></br>
                                                    <p className='ms-4 company-information-value'>{Company_Information.contactName || "N.A."}</p>
                                                </Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col xs={6}>
                                                    <i
                                                        className="bx bx-phone-call"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i><label className='company-information-labels'>CONTACT NUMBER</label><br></br>
                                                    <p className='ms-4 company-information-value'> +{Company_Information.countryCode || "N.A."}{" "}
                                                        &nbsp;{Company_Information.phone || "N.A."}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <i
                                                        className="bx bx-envelope"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i><label className='company-information-labels'>EMAIL</label><br></br>
                                                    <p className='ms-4 company-information-value'>{Company_Information.email || "N.A."}</p>
                                                </Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col xs={6}>
                                                    <i
                                                        className="bx bx-map"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i><label className='company-information-labels'>COMPANY ADDRESS</label><br></br>
                                                    <p className='ms-4 company-information-value'>
                                                        {Company_Information.companyAddress1 || "N.A."}
                                                    </p>
                                                </Col>
                                                <Col xs={6}>
                                                    <i
                                                        className="bx bx-world"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i><label className='company-information-labels'>COUNTRY</label><br></br>
                                                    <p className='ms-4 company-information-value'>
                                                        {Company_Information.country || "N.A."}
                                                    </p>
                                                </Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col xs={6}>
                                                    <i
                                                        className="bx bx-world"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i> <label className='company-information-labels'>STATE</label><br></br>
                                                    <p className='ms-4 company-information-value'>
                                                        {Company_Information.state || "N.A."}
                                                    </p>
                                                </Col>
                                                <Col xs={6}>
                                                    <i
                                                        className="bx bx-dollar-circle"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i><label className='company-information-labels'>ANNUAL REVENUE</label><br></br>
                                                    <p className='ms-4 company-information-value'>
                                                        {Company_Information.annualRevenue || "N.A."}
                                                    </p>
                                                </Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col xs={6}>
                                                    <i
                                                        className="bx bx-group"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i><label className='company-information-labels'>NUM OF EMPLOYEE</label><br></br>
                                                    <p className='ms-4 company-information-value'>
                                                        {Company_Information.numberOfEmployee || "N.A."}
                                                    </p>
                                                </Col>
                                                <Col xs={6}>
                                                    <i
                                                        className="bx bx-vector"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i><label className='company-information-labels'>INDUSTRY</label><br></br>
                                                    <p className='ms-4 company-information-value'>
                                                        {Company_Information.industry || "N.A."}
                                                    </p>
                                                </Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col xs={6}>
                                                    <i
                                                        className="dripicons-web"
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i><label className='company-information-labels'>WEBSITE URL</label><br></br>
                                                    <p className='ms-4 company-information-value'>
                                                        {Company_Information.websiteUrl || "N.A."}
                                                    </p>
                                                </Col>
                                                <Col xs={6}>
                                                    <i
                                                        className='bx bxs-key'
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                    </i><label className='company-information-labels'>OWNERSHIP</label><br></br>
                                                    <p className='ms-4 company-information-value'>
                                                        {Company_Information.creatorName || "N.A."}
                                                    </p>
                                                </Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col xs={12}>
                                                    <i
                                                        className="bx bx-align-left"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#0F838C",
                                                            marginRight: "8px",
                                                        }}
                                                    ></i>
                                                    <label className='company-information-labels'>CLIENT ATTRIBUTES</label>
                                                </Col>
                                                <Col xs="6">
                                                    <p className='ms-4 company-information-value'>
                                                        {Company_Information.clientAttribute1 || "N.A."}
                                                    </p>
                                                </Col>
                                                <Col xs="6">
                                                    <p className='ms-4 company-information-value'>
                                                        {Company_Information.clientAttribute2 || "N.A."}
                                                    </p>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Col>
                                    <Col xs="1" />
                                </Row>


                            </div>
                        </Col>
                    </Row>

                    <Col xs="12" style={{
                        // border: '1px solid black'
                        position: "absolute",
                        bottom: 0,
                        zIndex: 100,
                    }}>
                        <div style={{
                            width: '100%',
                            fontSize: REPORT.FONT_SIZE.MEDIUM,
                            padding: '5px 5px 5px 40px',
                            backgroundColor: REPORT.COLOR.FOOTER,
                            fontFamily: REPORT.FONT_FACE.HEADING,
                            letterSpacing: '2px',
                            opacity: 0.9,
                            fontWeight: 600,
                            color: 'white'
                        }}>
                            COMPANY IMFORMATION</div>
                    </Col>

                </div>
            </Col>

        </>
    );
}

export default CompanyInformationPage;