import { ACCOUNT_USER } from "config/AlertMessage.config"
import React from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Button } from "reactstrap"
import { toggleBuyCreditMenu } from "store/actions"
const NotFound = props => {
  const PROPS = props
  const { Message, Icon, IsButton, Url, btnText, Reload, Action } = PROPS
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <div style={{ marginTop: "60px" }}>
      <h2>{Icon && Icon()}</h2>
      {/* &nbsp; */}
      <h5>{Message.message}</h5>
      <p
        style={{
          fontSize: "12px",
        }}
      >
        {Message.subMessage}
      </p>
      {IsButton && (
        <Button
          size="sm"
          className="w-sm"
          style={{ background: "#05727B" }}
          onClick={() => {
            if (Reload) {
                window.location.reload();
            } else {
                if (Url) {
                    if (Action  && Action === "Buy Credit") {
                        history.push(Url);
                        dispatch(toggleBuyCreditMenu(true))
                    } else {
                        history.push(Url);
                    }
                }
            }
          }}
        >
          {btnText ? btnText : "Create"}
        </Button>
      )}
    </div>
  )
}

export default NotFound
