import { color, fontSize } from "@mui/system"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Grid } from "@mui/material"
import img from "../../../../assets/images/reportScorcard/executiveCardimg.PNG"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// import {mainDivStyle} from './pageFifth.cssConfig'
import { REPORT_PAGE, REPORT, RECOMMENDATIONS } from "../config/style.config"
import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
} from "reactstrap"
import { Opacity } from "@mui/icons-material"
import Footer from "components/VerticalLayout/Footer"
import {
  getScorecardReport,
  UpdateScorecardReport,
  ClearUpdateScorecardReport,
  handleChangesHappend,
  updateGetScorecardReport,
  updateScorecardDetail,
} from "../../../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams, useHistory } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"

import ChildRecommendationPage from "./ChildRecommendationPage"
import { ACCOUNT_USER } from "config/AlertMessage.config"
import ScorecardReport_Reducer from "store/ScorecardReport/reducer"
import { channel } from "redux-saga"
import updateSuccessIcon from "../../../../assets/images/NewPopupIcons/Scorecard created.png"
import updateFailedIcon from "../../../../assets/images/NewPopupIcons/FailedIcon.png"

const ChannelRecommendation = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const [isPageAdded, setIsPageAdded] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const [saveTacticData, setSaveTacticData] = useState({})
  const [initialtactic, setInitialTactic] = useState({})
  const [editTacticRecommendation, setEditTacticRecommendation] = useState([])

  const [editRecommendation, setEditRecommendation] = useState([])
  const [saveRecommendation, setSaveRecommendation] = useState([])

  //
  const [tacticsArray, setTacticsArray] = useState([])

  const [] = useState()

  const { scorecardReport_data, scorecardReportUpdate } = useSelector(
    state => ({
      scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
      scorecardReportUpdate:
        state.ScorecardReport_Reducer.scorecardReportUpdate,
    })
  )

  useEffect(() => {
    dispatch(getScorecardReport(params.scorecardId))
    setEditTacticRecommendation([
      {
        pageId: 0,
      },

    ])
  }, [dispatch])

  useEffect(() => {
    if (scorecardReport_data && scorecardReport_data.selectedChannels) {
      var Tactic = scorecardReport_data.selectedChannels.filter(
        channel => channel.channelId === params.channelId
      )[0].tactics
      setEditTacticRecommendation(Tactic)
    }
  }, [scorecardReport_data])

  useEffect(() => {
    if (scorecardReport_data && scorecardReport_data.selectedChannels) {
      var Tactic = scorecardReport_data.selectedChannels.filter(
        channel => channel.channelId === params.channelId
      )[0].tactics
      if (Tactic.tacticId === params.tacticId) {
        // setEditRecommendation(Tactic)
        setEditTacticRecommendation(Tactic)
      }
    }
  }, [scorecardReport_data, params.tacticId])

  // console.log("editTacticRecommendation",editTacticRecommendation)

  useEffect(() => {
    if (
      editTacticRecommendation.length / 6 <= 1
    ) {
      setTacticsArray([
        { tactics: [...editTacticRecommendation.slice(0, 6)] },
        // { tactics: [...editTacticRecommendation.slice(5, 10)] },
      ])
    } else if (editTacticRecommendation.length / 6 > 1) {
      setTacticsArray([
        { tactics: [...editTacticRecommendation.slice(0, 6)] },
        { tactics: [...editTacticRecommendation.slice(6, 10)] },
        // { tactics: [...editTacticRecommendation.slice(10, 15)] },
      ])
    } 
    // else {
    //   setTacticsArray([{ tactics: [...editTacticRecommendation.slice(0, 5)] }])
    // }
  }, [scorecardReport_data, editTacticRecommendation])

  const AddNewPage = () => {
    setEditTacticRecommendation(e => [
      ...e,
      {
        pageId: 1,
        contents: "",
      },
      {
        pageId: 2,
        contents: "",
      },
    ])
  }
  //   const ckRef = useRef()
  // console.log(scorecardReport_data)
  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
      var img = new Image()
    }
  }

  // console.log(tacticsArray)
  // useEffect(() => {
  //   const GetChannelId = () => {
  //     var ChannelId =
  //       scorecardReport_data.selectedChannels &&
  //       scorecardReport_data.selectedChannels.filter(channel => {
  //         return channel.channelId === params.channelId
  //       })
  //     return ChannelId
  //   }
  //   //console.log(GetChannelId())
  // })
  //   useEffect(() => {

  //       if(  scorecardReport_data && scorecardReport_data.selectedChannels){
  //       var Channel =

  //             scorecardReport_data.selectedChannels.filter(channel => channel.channelId === params.channelId)[0]
  //             // return [...Channel.tactics.map(obj => obj)]

  //           setTacticData(Channel.tactics)

  //         }
  //          },[scorecardReport_data , params.channelId])
  //  //console.log(tacticData)

  const handleChangeRecommendation = (e, id) => {
    var myRecommendation = editTacticRecommendation.map(tactic => {
      if (tactic.tacticId === id) {
        return {
          ...tactic,
          recommendation: e,
        }
      } else {
        return tactic
      }
    })
    setEditTacticRecommendation(myRecommendation)
  }

  //
  // console.log(editTacticRecommendation)

  //  console.log(tacticData)
  const handleSave = () => {
    // if (editTacticRecommendation) {
    var Channel = scorecardReport_data.selectedChannels.map(channel => {
      if (channel.channelId === params.channelId) {
        return {
          ...channel,
          tactics: editTacticRecommendation,
        }
      } else {
        return channel
      }
    })

    var data = {
      selectedChannels: Channel,
      lastUpdatedOn: {
        _miliseconds: 0,
        _seconds: new Date().getTime() / 1000,
      },
    }
    //console.log("Updated Data is ", data)
    dispatch(updateScorecardDetail(params.scorecardId, {
      lastUpdatedOn: {
        _miliseconds: 0,
        _seconds: new Date().getTime() / 1000
      }
    }))
    dispatch(UpdateScorecardReport(params.scorecardId, data))
    dispatch(handleChangesHappend(false))
  }

  const AddRecommendation = (data, pageId) => {
    var temp = {
      pageId: pageId,
      contents: data,
    }
    var temp_1 = [...editTacticRecommendation]
    temp_1[pageId] = temp

    // console.log(temp_1)
    setEditTacticRecommendation(temp_1)
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Mambo -Report</title>
        </MetaTags>
        {/* {scorecardReportUpdate && scorecardReportUpdate.status === 200 && (
          <SweetAlert
            style={{
              position: "absolute",
              top: 170,
              height: "255px",
              width: "368px",
            }}
            // title="Data saved successfully!"
            success
            confirmBtnBsStyle="primary"
            onConfirm={() => {
              dispatch(updateGetScorecardReport({
                ...scorecardReport_data,
                selectedChannels: scorecardReportUpdate.data.selectedChannels,
                lastUpdatedOn: scorecardReportUpdate.data.lastUpdatedOn,
              }))
              setIsSaved(false)
              setSaveRecommendation(editRecommendation || "")
              dispatch(ClearUpdateScorecardReport())
            }}
          >
            {ACCOUNT_USER.EDIT_REPORT.SUCCESS}
          </SweetAlert>
        )} */}

        {scorecardReportUpdate && scorecardReportUpdate.status === 200 && (
          <SweetAlert
            style={{height:"270px", width:"270px"}}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img src={updateSuccessIcon}
                    style={{
                      height:'80px', 
                      width:'85px'
                  }}/>
                </Col>
                <Col md="12" className="text-center container-fluid mb-2"
                  style={{
                    color:'#0D0F11',
                    fontWeight:600,
                    fontSize:'24px',
                    lineHeight:'25px',
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.SUCCESS}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                  <Button block size="sm" className="mt-2"
                    style={{
                      background:'#0D0F11',
                      border:'#0D0F11'
                    }}
                    onClick={() => {
                      dispatch(updateGetScorecardReport({
                        ...scorecardReport_data,
                        selectedChannels: scorecardReportUpdate.data.selectedChannels,
                        lastUpdatedOn: scorecardReportUpdate.data.lastUpdatedOn,
                      }))
                      setIsSaved(false)
                      setSaveRecommendation(editRecommendation || "")
                      dispatch(ClearUpdateScorecardReport())
                    }}
                  >
                    Close
                  </Button>
              </div>
            }
          >
          </SweetAlert>
        )}      

        {/* {scorecardReportUpdate && scorecardReportUpdate.status === 403 && (
          <SweetAlert
            style={{
              position: "absolute",
              top: 170,
              height: "255px",
              width: "368px",
            }}
            // title="Failed to save !"
            error
            confirmBtnBsStyle="primary"
            onConfirm={() => {
              setIsSaved(false)

              dispatch(ClearUpdateScorecardReport())
            }}
          >
            {ACCOUNT_USER.EDIT_REPORT.FAILED}
          </SweetAlert>
        )} */}

        {scorecardReportUpdate && scorecardReportUpdate.status === 403 && (
          <SweetAlert
            style={{height:"270px", width:"270px"}}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img src={updateFailedIcon}
                    style={{
                      height:'80px', 
                      width:'80px'
                  }}/>
                </Col>
                <Col md="12" className="text-center container-fluid"
                  style={{
                    color:'#0D0F11',
                    fontWeight:600,
                    fontSize:'24px',
                    lineHeight:'25px',
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.FAILED}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                  <Button block size="sm" className=""
                    style={{
                      background:'#0D0F11',
                      border:'#0D0F11'
                    }}
                    onClick={() => {
                      setIsSaved(false)
                      dispatch(ClearUpdateScorecardReport())
                    }}
                  >
                    Close
                  </Button>
              </div>
            }
          >
          </SweetAlert>
        )}      

        <Container
          fluid={true}
          style={{
            transform: "scale(.8) translate(-130px, -132px) ",
            width: "1400px",
          }}
        >
          <Row>
            <Col className="col-12 m-auto ">
              <Card>
                <CardBody>
                  {tacticsArray[0] &&
                    tacticsArray.map((tactic_1, x) => (
                      <div 
                        key={x}
                      >
                        <ChildRecommendationPage
                          index={x}
                          addPage={AddNewPage}
                          HandlePageAdded={e => setIsPageAdded(e)}
                          HANDLE_CHANGE_RECOMMENDATION={
                            handleChangeRecommendation
                          }
                          Data_Length={editTacticRecommendation.length}
                          DATA_AVAIL={scorecardReport_data.selectedChannels}
                          IsPageAdded={isPageAdded}
                          RECOMMENDATION_DATA={tactic_1}
                          SAVE_RECOMMENDATION={AddRecommendation}
                          IS_SAVED={isSaved}
                          SET_IS_SAVED={e => setIsSaved(e)}
                          HandleSaveBtn={handleSave}
                          TacticRecommendation={editTacticRecommendation}
                          REPORT_UPDATE= {scorecardReportUpdate}
                          SCORECARDREPORT_DATA={scorecardReport_data}
                        />
                        {/* {console.log(tactic_1)} */}
                        {/* {tactic_1.tactics.map((tactic, i) => (
                          console.log(tactic)
                          // <ChildRecommendationPage
                          //   key={i}
                          //   index={i}
                          //   addPage={AddNewPage}
                          //   HandlePageAdded={e => setIsPageAdded(e)}
                          //   HANDLE_CHANGE_RECOMMENDATION={
                          //     handleChangeRecommendation
                          //   }
                          //   Data_Length={editTacticRecommendation.length}
                          //   DATA_AVAIL={scorecardReport_data.selectedChannels}
                          //   IsPageAdded={isPageAdded}
                          //   RECOMMENDATION_DATA={tactic}
                          //   SAVE_RECOMMENDATION={AddRecommendation}
                          //   IS_SAVED={isSaved}
                          //   SET_IS_SAVED={e => setIsSaved(e)}
                          //   HandleSaveBtn={handleSave}
                          //   TacticRecommendation={editTacticRecommendation}
                          // />
                        ))} */}
                        <span
                          style={{
                            display: "block",
                            width: "100%",
                            height: "80px",
                          }}
                        ></span>
                      </div>
                    ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ChannelRecommendation
