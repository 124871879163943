import { border, color, fontSize, textAlign } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import MetaTags from 'react-meta-tags';  // Added Meta Tag npm Package
import { Grid } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import ReactHtmlParser from "react-html-parser"

import {
  Table,
  Container, Row, Col,
  Alert,
  Card, CardBody, CardTitle, FormGroup, Spinner, Form, Input, Button, Modal
} from "reactstrap";
import { Opacity } from '@mui/icons-material';
import Footer from 'components/VerticalLayout/Footer';
import { executiveSummary } from './data';
import { Link, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { useSelector, useDispatch } from "react-redux"


import "../config/style.config.css"
import CropEasy from 'pages/Crop/CropEasy';

import {
  getReportTemplate,
  UpdateScorecardReport,
  ClearUpdateScorecardReport,
  getScorecardReport,
  updateGetScorecardReport,
  handleUnsavedChanges,
  handleChangesHappend,
  updateScorecardDetail,

} from "store/actions"
import { REPORT, REPORT_PAGE } from '../config/style.config';
import SweetAlert from 'react-bootstrap-sweetalert';
import ExecutiveSummary from './component/ExecutiveSummary/ExecutiveSummary.component';
import { ACCOUNT_USER } from 'config/AlertMessage.config';
import updateSuccessIcon from "../../../../assets/images/NewPopupIcons/Scorecard created.png"
import updateFailedIcon from "../../../../assets/images/NewPopupIcons/FailedIcon.png"


const ExecutiveSummaryPage = () => {
  const [pageSize, setPageSize] = useState([{}])
  const [isPageAdded, setIsPageAdded] = useState(false)
  const [executiveSummary, setExecutiveSummary] = useState([])


  const [executiveData, setExecutiveData] = useState([])

  const [image_url, setImage_Url] = useState(null)


  const [editExecutiveSummary, setEditExecutiveSummary] = useState([])
  const [saveExecutiveSummary, setSaveExecutiveSummary] = useState([])
  const [isSaved, setIsSaved] = useState(false)



  const params = useParams()

  const dispatch = useDispatch()

  const { getReport, scorecardReportUpdate, scorecardReport_data, isDataEdited, isThereChanges } = useSelector(state => ({
    getReport: state.createReportReducer.getReport,
    scorecardReportUpdate: state.ScorecardReport_Reducer.scorecardReportUpdate,
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
    isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges


  }))

  useEffect(() => {
    dispatch(getReportTemplate())
    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
    // dispatch(getScorecardReport(params.scorecardId))
    setExecutiveSummary([
      {
        pageId: 0,
        imgUrl: image_url,
        // contents: "<p>Hello world</p>"
      },
    ])
  }, [dispatch])

  
  // useEffect(() => {
  //   setExecutiveSummary([
  //     {
  //       pageId: 0,
  //       imgUrl: image_url,
  //       // contents: "<p>Hello world</p>"
  //     },
  //   ])

  // }, [scorecardReport_data])



  useEffect(() => {
    if (scorecardReport_data && scorecardReport_data.executiveSummary) {
      setExecutiveSummary(scorecardReport_data.executiveSummary)
      setImage_Url(scorecardReport_data.executiveSummary[0].bgImage)
      if (scorecardReport_data.executiveSummary.length === 2) {
        setIsPageAdded(true)
      }
    }
  }, [scorecardReport_data])


  const AddNewPage = () => {


    setExecutiveSummary(e => [...e, {
      pageId: 1,
      bgImage: image_url ? image_url : "",
      contents: ""
    }
    ])

  }

  const RemoveNewPage = () => {
    // var pageRemoved = executiveSummary.pop()
    setExecutiveSummary([
      {
        ...executiveSummary[0]
      },
    ])
    dispatch(handleChangesHappend(true))
  }

  // const SetPageData = () => {
  //   setEditExecutiveSummary([{}])
  // }
  // Save button handler
  const HandleSaveButton = () => {
    setIsSaved(true)
    var data = {
      executiveSummary: executiveSummary.map(summary => ({
        ...summary,
        bgImage: image_url
      })),
      lastUpdatedOn: {
        _miliseconds: 0,
        _seconds: new Date().getTime() / 1000,
      },
    }
    // setIsSaved(false)
    // console.log(data)
    dispatch(updateScorecardDetail(params.scorecardId, {
      lastUpdatedOn: {
        _miliseconds: 0,
        _seconds: new Date().getTime() / 1000
      }
    }))
    dispatch(UpdateScorecardReport(params.scorecardId, data))
    dispatch(handleChangesHappend(false))
  }

  const AddExecutiveSummary = (data, pageId) => {
    var temp = {
      pageId: pageId,
      bgImage: image_url,
      contents: data
    }

    // console.log("IMAGE URL...",imageurl)

    var temp_1 = [...executiveSummary]
    temp_1[pageId] = temp

    // console.log(temp_1)
    setExecutiveSummary(temp_1)
    dispatch(handleChangesHappend(true))


  }

  // console.log(executiveSummary)

  return (
    <>
      <div className="page-content" >
        <MetaTags>
          <title> Mambo -Report</title>
        </MetaTags>
        {/* {
          scorecardReportUpdate.status === 200 &&
          <SweetAlert
            style={{
              position: "absolute",
              top: 170,
              height: "255px", width: "368px"
            }}
            // title="Executive summary saved successfully!"
            success
            confirmBtnBsStyle="primary"

            onConfirm={() => {
              dispatch(updateGetScorecardReport({
                ...scorecardReport_data,
                executiveSummary: executiveSummary.map(summary => ({
                  ...summary,
                  bgImage: image_url
                })),
                lastUpdatedOn: {
                  _miliseconds: 0,
                  _seconds: new Date().getTime() / 1000,
                }
              }))
              setIsSaved(false)

              setSaveExecutiveSummary(editExecutiveSummary || " ")

              dispatch(ClearUpdateScorecardReport())
            }
            }
          >{ACCOUNT_USER.EDIT_REPORT.SUCCESS}
          </SweetAlert>
        } */}

        {
          scorecardReportUpdate.status === 200 && 
          <SweetAlert
            style={{height:"270px", width:"270px"}}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img src={updateSuccessIcon}
                    style={{
                      height:'80px', 
                      width:'85px'
                  }}/>
                </Col>
                <Col md="12" className="text-center container-fluid mb-2"
                  style={{
                    color:'#0D0F11',
                    fontWeight:600,
                    fontSize:'24px',
                    lineHeight:'25px',
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.SUCCESS}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                  <Button block size="sm" className="mt-2"
                    style={{
                      background:'#0D0F11',
                      border:'#0D0F11'
                    }}
                    onClick={() => {
                      dispatch(updateGetScorecardReport({
                        ...scorecardReport_data,
                        executiveSummary: executiveSummary.map(summary => ({
                          ...summary,
                          bgImage: image_url
                        })),
                        lastUpdatedOn: {
                          _miliseconds: 0,
                          _seconds: new Date().getTime() / 1000,
                        }
                      }))
                      setIsSaved(false)
        
                      setSaveExecutiveSummary(editExecutiveSummary || " ")
        
                      dispatch(ClearUpdateScorecardReport())
                    }}
                  >
                    Close
                  </Button>
              </div>
            }
          >
          </SweetAlert>
        }

        {/* {
          scorecardReportUpdate.status === 403 &&

          <SweetAlert
            // title="Failed to save executive summary!"
            error
            confirmBtnBsStyle="primary"
            style={{
              position: "absolute",
              top: 170,
              height: "255px", width: "368px"
            }}
            onConfirm={() => {

              setIsSaved(false)
              dispatch(ClearUpdateScorecardReport())

            }}
          >{ACCOUNT_USER.EDIT_REPORT.FAILED}
          </SweetAlert>

        } */}

        {
          scorecardReportUpdate.status === 403 && 
          <SweetAlert
            style={{height:"270px", width:"270px"}}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img src={updateFailedIcon}
                    style={{
                      height:'80px', 
                      width:'80px'
                  }}/>
                </Col>
                <Col md="12" className="text-center container-fluid"
                  style={{
                    color:'#0D0F11',
                    fontWeight:600,
                    fontSize:'24px',
                    lineHeight:'25px',
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.FAILED}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                  <Button block size="sm" className=""
                    style={{
                      background:'#0D0F11',
                      border:'#0D0F11'
                    }}
                    onClick={() => {
                      setIsSaved(false)
                      dispatch(ClearUpdateScorecardReport())
                    }}
                  >
                    Close
                  </Button>
              </div>
            }
          >
          </SweetAlert>
        }  

        <Container fluid={true} style={{
          transform: "scale(.8) translate(-130px, -132px) ",
          width: "1400px",
        }} >


          <Row >
            <Col className="col-12 m-auto " >
              <Card >
                <CardBody  >

                  {
                    executiveSummary[0] && executiveSummary.map((Executive_Summary, i) => (
                      <>
                        <ExecutiveSummary
                          key={i}
                          Index={i}
                          GetReport={getReport}
                          AddPage={AddNewPage}
                          RemovePage={RemoveNewPage}
                          HandlePageAdded={(e) => setIsPageAdded(e)}
                          DATA_LENGTH={executiveSummary.length}
                          DATA_AVAIL={scorecardReport_data.executiveSummary}
                          REPORT_UPDATE = {scorecardReportUpdate}
                          IsPageAdded={isPageAdded}
                          EXECUTIVE_SUMMARY={Executive_Summary}
                          SAVE_EXECUTIVE_SUMMARY={AddExecutiveSummary}
                          SET_IMAGE_URL={(imgUrl) => setImage_Url(imgUrl)}
                          IMAGE_URL={image_url ? image_url : ""}

                          IS_SAVED={isSaved}
                          SET_IS_SAVED={(e) => setIsSaved(e)}

                          HandleSaveBtn={HandleSaveButton}
                        />
                        <span style={{
                          display: "block",
                          width: "100%",
                          height: "80px"
                        }} ></span>
                      </>
                    ))
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >
    </>
  );
}

export default ExecutiveSummaryPage;