
import {
  ADD_ACCOUNT_HOLDER,
  ADD_ACCOUNT_HOLDER_SUCCESS,
  ADD_ACCOUNT_HOLDER_FAIL,
  CLEAR_ACCOUNT_HOLDER,

  // DELETE_ACCOUNT_HOLDER,
  // DELETE_ACCOUNT_HOLDER_SUCCESS,
  // DELETE_ACCOUNT_HOLDER_FAIL,

 

  
} from "./actionTypes"

const initialState = {
  
  addAccountUser: [],
  
}

const createAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case ADD_ACCOUNT_HOLDER_SUCCESS:
     return{
        ...state,
        
        addAccountUser: [...state.addAccountUser, action.payload],
        
        
      }
     
    case ADD_ACCOUNT_HOLDER_FAIL:
      return {
        ...state,
        addAccountUser: [],
       
      }
     

    case CLEAR_ACCOUNT_HOLDER:
      return {
        ...state,
        addAccountUser:[],
        
      }
   

    //  case DELETE_ACCOUNT_HOLDER_SUCCESS:
    //    return{
    //      ...state,
         
    //       addAccountUser: state.addAccountUser.filter(
    //         user => user.id.toString() !== action.payload.id.toString()
    //       ),
    //     };
    //     case DELETE_ACCOUNT_HOLDER_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };
  
       
        
    default:
      return state
     
  }
  
}


export default createAccountReducer
//export default deleteAccountReducer
