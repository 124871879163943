import {
    ADD_NEW_INSTRUCTION_SUCCESS,
    ADD_NEW_INSTRUCTION_FAIL,
    ADD_NEW_INSTRUCTION_CLEAR,

    GET_ALL_INSTRUCTION_DATA_SUCCESS,
    GET_ALL_INSTRUCTION_DATA_FAIL,
    GET_ALL_INSTRUCTION_DATA_CLEAR,
    
    UPDATE_INSTRUCTION_SUCCESS,
    UPDATE_INSTRUCTION_FAIL,
    UPDATE_INSTRUCTION_CLEAR
} from "./actionTypes"

const INIT_STATE = {
    addInstructionData: {},
    getInstructionData: {},
    updateInstructionData : {}
}

const instructionPanelReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_NEW_INSTRUCTION_SUCCESS:
            return {
                ...state,
                addInstructionData: action.payload
            }
        case ADD_NEW_INSTRUCTION_FAIL:
            return {
                ...state,
                addInstructionData: action.payload
            }
        case ADD_NEW_INSTRUCTION_CLEAR:
            return {
                ...state,
                addInstructionData: {}
            }
        case GET_ALL_INSTRUCTION_DATA_SUCCESS:
            return {
                ...state,
                getInstructionData: action.payload
            }
        case GET_ALL_INSTRUCTION_DATA_FAIL:
            return {
                ...state,
                getInstructionData: action.payload
            }
        case GET_ALL_INSTRUCTION_DATA_CLEAR:
            return {
                ...state,
                getInstructionData: {}
            }
        case UPDATE_INSTRUCTION_SUCCESS:
            return {
                ...state,
                updateInstructionData: action.payload
            }
        case UPDATE_INSTRUCTION_FAIL:
            return {
                ...state,
                updateInstructionData: action.payload
            }
        case UPDATE_INSTRUCTION_CLEAR:
            return {
                ...state,
                updateInstructionData: {}
            }
        default:
            return state
    }
}
export default instructionPanelReducer;