export const GET_SCORECARDS_LIST = "GET_SCORECARDS_LIST"
export const GET_SCORECARDS_LIST_SUCCESS = "GET_SCORECARDS_LIST_SUCCESS"
export const GET_SCORECARDS_LIST_FAIL = "GET_SCORECARDS_LIST_FAIL"
export const CLEAR_SCORECARD_LIST = "CLEAR_SCORECARD_LIST"
// export const CLEAR_ACCOUNT_HOLDER_USER = "CLEAR_ACCOUNT_HOLDER_USER"

// Scorecard list for account users.
export const GET_SCORECARDS_LIST_FOR_ACCOUNT_USER = "GET_SCORECARDS_LIST_FOR_ACCOUNT_USER"
export const GET_SCORECARDS_LIST_SUCCESS_FOR_ACCOUNT_USER = "GET_SCORECARDS_LIST_SUCCESS_FOR_ACCOUNT_USER"
export const GET_SCORECARDS_LIST_FAIL_FOR_ACCOUNT_USER = "GET_SCORECARDS_LIST_FAIL_FOR_ACCOUNT_USER"

// Create scorecard
export const CREATE_SCORECARD = "CREATE_SCORECARD"
export const CREATE_SCORECARD_SUCCESS = "CREATE_SCORECARD_SUCCESS"
export const CREATE_SCORECARD_FAIL = "CREATE_SCORECARD_FAIL"
export const CREATE_SCORECARD_CLEAR = "CREATE_SCORECARD_CLEAR"

// Get scorecard
export const GET_SCORECARD_DETAIL = "GET_SCORECARD_DETAIL"
export const GET_SCORECARD_DETAIL_SUCCESS = "GET_SCORECARD_DETAIL_SUCCESS"
export const GET_SCORECARD_DETAIL_FAIL = "GET_SCORECARD_DETAIL_FAIL"
export const GET_SCORECARD_DETAIL_CLEAR = "GET_SCORECARD_DETAIL_CLEAR"

//update scorecard
export const UPDATE_SCORECARD_DETAIL = "UPDATE_SCORECARD_DETAIL"
export const UPDATE_SCORECARD_DETAIL_SUCCESS = "UPDATE_SCORECARD_DETAIL_SUCCESS"
export const UPDATE_SCORECARD_DETAIL_FAIL = "UPDATE_SCORECARD_DETAIL_FAIL"
export const UPDATE_SCORECARD_DETAIL_CLEAR = "UPDATE_SCORECARD_DETAIL_CLEAR"
export const CHANGE_SCORECARD_DETAIL = "CHANGE_SCORECARD_DETAIL"

// Get Logged in user claims
export const IS_ACCOUNT_OWNER = "IS_ACCOUNT_OWNER"

// scorecard route handler:
export const SCORECARD_PATH = "SCORECARD_PATH"

// To get Only Latest Scorecards 
export const GET_THREE_SCORECARDS = "GET_THREE_SCORECARDS"
export const GET_THREE_SCORECARDS_SUCCESS = "GET_THREE_SCORECARDS_SUCCESS"
export const GET_THREE_SCORECARDS_FAIL = "GET_THREE_SCORECARDS_FAIL"
export const CLEAR_GET_THREE_SCORECARDS = "CLEAR_GET_THREE_SCORECARDS"