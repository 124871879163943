import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ACCOUNT_HOLDER_USERS, GET_ACCOUNT_HOLDER_USER_DETAILS } from "./actionTypes"
import {
    
  getAccountHolderUsersSuccess,
  getAccountHolderUsersFail,
  getAccountHolderUserDetailsFail,
  getAccountHolderUserDetailsSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getAccountHolderUsers, getAccountHolderUserDetails } from "helpers/fakebackend_helper"

function* fetchAccountHolderUsers({ accountId }) {
  try {
    const response = yield call(getAccountHolderUsers, accountId)
    yield put(getAccountHolderUsersSuccess(response))
  } catch (error) {
    yield put(getAccountHolderUsersFail(error))
  }
}

function* fetchAccountHolderUserDetail({ userId }) {
  try {
    const response = yield call(getAccountHolderUserDetails, userId)
    yield put(getAccountHolderUserDetailsSuccess(response))
  } catch (error) {
    yield put(getAccountHolderUserDetailsFail(error))
  }
}

function* accountHolderUsersSaga() {
  yield takeEvery(GET_ACCOUNT_HOLDER_USERS, fetchAccountHolderUsers)
  yield takeEvery(GET_ACCOUNT_HOLDER_USER_DETAILS, fetchAccountHolderUserDetail)

}

export default accountHolderUsersSaga
