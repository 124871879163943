import {
  ADD_TACTIC_SUCCESS,
  ADD_TACTIC_FAIL,
  CLEAR_TACTIC_STATE,
  GET_TACTIC_INFO_SUCCESS,
  GET_TACTIC_INFO_FAIL,
  GET_TACTIC_INFO_CLEAR,
  UPDATE_TACTIC_STATE,
  CLEAR_UPDATE_TACTIC_STATE
} from "./actionTypes"

const INIT_STATE = {
  addTacticData : [],
  GetTacticInfo :{},
}

const AddTacticReducer = (state = INIT_STATE, action) => {

  switch(action.type) {
      case ADD_TACTIC_SUCCESS : return {
          ...state,
          addTacticData: action.payload,
      }
      case ADD_TACTIC_FAIL : return {
          ...state,
          addTacticData: action.payload,
      }
      case CLEAR_TACTIC_STATE : return {
          ...state,
          addTacticData: []
      }
      case GET_TACTIC_INFO_SUCCESS:
            return {
                ...state,
                GetTacticInfo: action.payload
            }
        case GET_TACTIC_INFO_FAIL:
            return {
                ...state,
                GetTacticInfo: action.payload
            }
        case GET_TACTIC_INFO_CLEAR:
            return {
                ...state,
                GetTacticInfo: {}
            }
        case UPDATE_TACTIC_STATE:
            return{
                ...state,
                GetTacticInfo:action.payload
            }
        case CLEAR_UPDATE_TACTIC_STATE:
            return{
                ...state,
                GetTacticInfo:[]
            }
      default:
      return state
  }
  
  
}

export default AddTacticReducer;