
const { GET_SCORECARD_REPORT_SUCCESS,
    GET_SCORECARD_REPORT_FAIL,

    CLEAR_GET_SCORECARD_REPORT,
    CLEAR_CREATE_SCORECARD_REPORT,
    CLEAR_UPDATE_SCORECARD_REPORT,

    CREATE_SCORECARD_REPORT_SUCCESS,
    CREATE_SCORECARD_REPORT_FAIL,

    UPDATE_SCORECARD_REPORT_SUCCESS,
    UPDATE_SCORECARD_REPORT_FAIL,
    UPDATE_GET_SCORECARD_REPORT,
    SET_WINDOW_INACTIVITY,
    UNSAVED_CHANGES,
    CHNAGES_HAPPEND,
    GET_ROUTES,
    CLOSE_REPORT_EDITING,

    SET_SESSION_TIMEOUT,
    IS_USER_EXITED

} = require("./actionTypes")

const INIT_STATE = {
    scorecardReport_data: {},
    scorecardReportCreateResponse: {},
    scorecardReportUpdate: {},
    windowInActivity: false,
    isDataEdited: false,
    isThereChanges: false,
    getRoutes: null,
    exitFromEditReport: false,
    sessionTimeOut: false,
    isUserExited: false
}

const ScorecardReport_Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SCORECARD_REPORT_SUCCESS: return {
            ...state,
            scorecardReport_data: action.payload
        }
        case GET_SCORECARD_REPORT_FAIL: return {
            ...state,
            scorecardReport_data: action.payload
        }
        case CLEAR_GET_SCORECARD_REPORT: return {
            ...state,
            scorecardReport_data: {}
        }
        case UPDATE_GET_SCORECARD_REPORT: return {
            ...state,
            scorecardReport_data: action.payload
        }

        // Crate Scorecard:
        case CREATE_SCORECARD_REPORT_SUCCESS: return {
            ...state,
            scorecardReportCreateResponse: action.payload
        }
        case CREATE_SCORECARD_REPORT_FAIL: return {
            ...state,
            scorecardReportCreateResponse: action.payload
        }
        case CLEAR_CREATE_SCORECARD_REPORT: return {
            ...state,
            scorecardReportCreateResponse: {}
        }

        //Update Scorecard:
        case UPDATE_SCORECARD_REPORT_SUCCESS: return {
            ...state,
            scorecardReportUpdate: action.payload

        }

        case UPDATE_SCORECARD_REPORT_FAIL: return {
            ...state,
            scorecardReportUpdate: action.payload
        }

        case CLEAR_UPDATE_SCORECARD_REPORT: return {
            ...state,
            scorecardReportUpdate: {}
        }

        case SET_WINDOW_INACTIVITY: return {
            ...state,
            windowInActivity: action.payload
        }

        // handle session time out
        case SET_SESSION_TIMEOUT: return {
            ...state,
            sessionTimeOut: action.payload
        }

        // user exit after 20 min inactivity from Report Editing or Take A Survey page
        case IS_USER_EXITED: return {
            ...state,
            isUserExited: action.payload
        }

        // handle close editing:
        case CLOSE_REPORT_EDITING:
            return {
                ...state,
                exitFromEditReport: action.payload
            }
        case UNSAVED_CHANGES:
            return {
                ...state,
                isDataEdited: action.payload,

            }
        case CHNAGES_HAPPEND:
            return {
                ...state,
                isThereChanges: action.payload

            }
        case GET_ROUTES:
            return {
                ...state,
                getRoutes: action.payload

            }



        default: return state
    }
}
export default ScorecardReport_Reducer;