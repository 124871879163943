import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState, useCallback, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, withRouter, useParams, useHistory } from "react-router-dom"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from "reactstrap"

import SweetAlert from "react-bootstrap-sweetalert"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import { Toast } from "react-bootstrap"
import * as Yup from "yup"
import { setIn, useFormik } from "formik"
// import SweetAlert from "react-bootstrap-sweetalert"

import First_Image from "../../../../assets/images/ScreenshotsForInstructions/instructionForPriorityAreasOfFocus.png"
import Second_Image from "../../../../assets/images/ScreenshotsForInstructions/priorityAreasOfFocusEditMode.png"

import {
  getAllInstructionData,
  addNewInstruction,
  updateInstruction,
  handleChangesHappend,
  clearupdateInstruction
} from "store/actions"

const Priority_Areas_Instruction = () => {
  const params = useParams()
  const history = useHistory()
  const hiddenFile = useRef()
  const dispatch = useDispatch()

  const [accountUserInstructionData, setAccountUserInstructionData] =
    useState(null)
  const [pageData, setPageData] = useState(true)
  const [infoForResetPageButton, setInfoForResetPageButton] = useState("")
  const [infoForEditButton, setInfoForEditButton] = useState("")
  const [infoForSaveButton, setInfoForSaveButton] = useState("")
  const [infoForInsertButton, setInfoForInsertButton] = useState("")

  const [saveInfo, setSaveInfo] = useState(false)
  const [alldata, setAllData] = useState({})
  const [toastSuccess, setToastSuccess] = useState(false)
  const [toastFail, setToastFail] = useState(false)

  const [openPopoverForResetPageBtn, setOpenPopoverForResetPageBtn] =
    useState(false)
  const [openPopoverForSavePageBtn, setOpenPopoverForSavePageBtn] =
    useState(false)
  const [openPopoverForApplyPageBtn, setOpenPopoverForApplyPageBtn] =
    useState(false)
  const [openPopoverForEditBtn, setOpenPopoverForEditBtn] = useState(false)

  const { getInstructionData, addInstructionData, isThereChange, updateInstructionData } = useSelector(
    state => ({
      getInstructionData: state.instructionPanelReducer.getInstructionData,
      addInstructionData: state.instructionPanelReducer.addInstructionData,
      isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
      updateInstructionData: state.UpdateInstructionReducer.updateInstructionData
    })
  )

  useEffect(() => {
    if (!Object.keys(getInstructionData).includes("infoForAccountUser")) {
      dispatch(getAllInstructionData())
    }
  }, [dispatch])

  useEffect(() => {
    if (getInstructionData.infoForAccountUser) {
      var ALL_DATA = {
        ...getInstructionData.infoForAccountUser,
      }
      setAccountUserInstructionData(ALL_DATA)
    }
  }, [getInstructionData])

  useEffect(() => {
    if (accountUserInstructionData) {
      setPageData(false)
      var data = {
        infoForSaveBtn: accountUserInstructionData.Priority_Areas.infoForSaveBtn
          ? accountUserInstructionData.Priority_Areas.infoForSaveBtn
          : "",
        infoForEditBtn: accountUserInstructionData.Priority_Areas.infoForEditBtn
          ? accountUserInstructionData.Priority_Areas.infoForEditBtn
          : " ",
        infoForInsertBtn: accountUserInstructionData.Priority_Areas
          .infoForInsertBtn
          ? accountUserInstructionData.Priority_Areas.infoForInsertBtn
          : " ",
        infoForResetPageBtn: accountUserInstructionData.Priority_Areas
          .infoForResetPageBtn
          ? accountUserInstructionData.Priority_Areas.infoForResetPageBtn
          : "",
      }
      setAllData({ ...data })
      setInfoForResetPageButton(data.infoForResetPageBtn)

      setInfoForSaveButton(data.infoForSaveBtn)
      setInfoForInsertButton(data.infoForInsertBtn)
      setInfoForEditButton(data.infoForEditBtn)
    }
  }, [accountUserInstructionData])

  const resetPage = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForResetPageBtn}
    >
      <Popover.Header>
        Reset Page
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForResetPageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  Priority_Areas: {
                    ...alldata,
                    infoForResetPageBtn: infoForResetPageButton,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForResetPageButton(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForResetPageButton}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForResetPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )
  const saveBtn = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForSavePageBtn}
    >
      <Popover.Header>
        Save Button
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForSavePageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  Priority_Areas: {
                    ...alldata,
                    infoForSaveBtn: infoForSaveButton,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForSaveButton(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForSaveButton}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForSavePageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )
  const InsertBtn = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForApplyPageBtn}
    >
      <Popover.Header>
        Insert Selected Content
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForApplyPageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  Priority_Areas: {
                    ...alldata,
                    infoForInsertBtn: infoForInsertButton,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForInsertButton(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForInsertButton}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForApplyPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )
  const editBtn = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForEditBtn}
    >
      <Popover.Header>
        Edit Button
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForEditBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  Priority_Areas: {
                    ...alldata,
                    infoForEditBtn: infoForEditButton,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForEditButton(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForEditButton}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForApplyPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )

  useEffect(() => {
    if (updateInstructionData.status === 200) {
      setToastSuccess(true)
      setOpenPopoverForResetPageBtn(false)

      setOpenPopoverForSavePageBtn(false)
      setOpenPopoverForApplyPageBtn(false)

      setOpenPopoverForEditBtn(false)

      setSaveInfo(false)
      dispatch(getAllInstructionData())
      dispatch(clearupdateInstruction())
      
    } else if (updateInstructionData.status === 403) {
      setToastFail(true)
      setOpenPopoverForResetPageBtn(false)

      setOpenPopoverForApplyPageBtn(false)

      setOpenPopoverForEditBtn(false)

      setSaveInfo(false)
      dispatch(clearupdateInstruction())
    }
  }, [updateInstructionData])

  return (
    <>
      <div className="page-content">

      {toastSuccess && (
          <div
            className="position-fixed top-0 left-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast
              
              delay={3000}
              show={true}
              onClose={() => setToastSuccess(false)}
              autohide
            >
              <Toast.Body
                style={{ color: "#fff", height: "55px" }}
                className="font-size-18 bg-primary"
              >
                <i
                  className="mdi mdi-check-circle d-flex"
                  style={{ fontSize: 22 }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      fontStyle: "normal",
                      marginTop: "2px",
                    }}
                    className="mx-3"
                  >
                    Instructions saved successfully.
                  </p>
                </i>
              </Toast.Body>
            </Toast>
          </div>
        )}
        {toastFail && (
         <div className="position-fixed top-0 left-0 p-3" style={{ zIndex: "1005" }}>
        <Toast
             
                delay={3000}
                show={toastFail}
                onClose={() => setToastFail(false)}
                autohide
              >
                <Toast.Body
                  style={{ color: "#fff", height: "55px" }}
                  className="font-size-18 bg-danger"
                >
                  <i
                    className="mdi mdi-close-circle d-flex"
                    style={{ fontSize: 22 }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontStyle: "normal",
                        marginTop: "2px",
                      }}
                      className="mx-3"
                    >
                      Failed to save instructions.
                    </p>
                  </i>
                </Toast.Body>
              </Toast>
     </div>
          
              
        
        )}
        <MetaTags>
          <title>Mambo - Instructions For Account User</title>
        </MetaTags>
        {
          pageData ?
            <div style={
              {
                width: "100%",
                textAlign: "center",
                height: "400px"
              }
            } >
              <Spinner
                style={{
                  width: "60px", height: "60px",
                  margin: "calc(200px - 30px)",

                }}
                role="grid"
                type="border"
                color="primary"
              />
            </div>
          :
        <Container 
          fluid={true}
          className="my-3 justify-content-center"
          style={{
            transform: "scale(.8) translate(-170px, -132px) ",
            width: "1550px",
          }}
        >
        <Row>
         <Col>
          <Card>
            <CardBody>
             
              <div>
                <div className="my-3">
                  <h2 className="fw-bold" style={{color:"#0D0F11"}}>
                    1. Instruction For Priority Area Of Focus
                  </h2>
                </div>
                <div
                  style={{
                    height: "650px",
                    width: "100%",
                    transform: "scale(1)",
                    backgroundImage: `url(${First_Image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Row>
                    <Col md="8"></Col>
                    <Col md="1">
                      <div>
                        <label
                          type="button"
                          style={{marginTop:'105px', marginLeft:'50px'}}
                        >
                          
                          <OverlayTrigger 
                            trigger="click" 
                            placement="bottom" 
                            overlay={editBtn}
                            show={openPopoverForEditBtn}
                          >
                              <span
                                className="mdi mdi-information"
                                onClick={() => setOpenPopoverForEditBtn(!openPopoverForEditBtn)}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "22px",
                                  color: "#5015cf",
                                  height: "20px",
                                  width: "20px",
                                  borderRadius: "50%",
                                  backgroundColor: "#fff",
                                }}
                              ></span>
                          </OverlayTrigger>
                          
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>


              <div>
                <div className="mt-5 mb-2">
                  <h2 className="fw-bold" style={{color:"#0D0F11"}}>
                    2. Instruction For Priority Area Of Focus(In Edit Mode)
                  </h2>
                </div>
                <div
                  style={{
                    height: "650px",
                    width: "100%",
                    transform: "scale(1)",
                    backgroundImage: `url(${Second_Image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  

                    {/* First Row for i-button */}
                    <Row>
                    <Col xs="3"></Col>
                    <Col xs="1">
                      <div>
                        <label
                          type="button"
                          style={{marginTop:'73px', marginLeft:'20px'}}
                        >
                          <OverlayTrigger 
                              trigger="click" 
                              placement="bottom" 
                              overlay={resetPage}
                              show={openPopoverForResetPageBtn}
                            >
                                <span
                                  className="mdi mdi-information"
                                  onClick={() => setOpenPopoverForResetPageBtn(!openPopoverForResetPageBtn)}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "22px",
                                    color: "#5015cf",
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "50%",
                                    backgroundColor: "#fff",
                                  }}>
                                  </span>
                            </OverlayTrigger>
                        </label>
                      </div>
                    </Col>
                    <Col xs="3"></Col>
                    <Col xs="1">
                      <div>
                        <label
                          type="button"
                          style={{marginTop:'127px', marginLeft:'-22px'}}
                        >
                        
                          
                            
                        </label>
                      </div>
                      </Col>
                      <Col xs="1">
                        <div>
                          <label
                            type="button"
                            style={{marginTop:'110px', marginLeft:'8px'}}
                          >
                            
                            <OverlayTrigger 
                              trigger="click" 
                              placement="bottom" 
                              overlay={saveBtn}
                              show={openPopoverForSavePageBtn}
                            >
                                <span
                                  className="mdi mdi-information"
                                  onClick={() => setOpenPopoverForSavePageBtn(!openPopoverForSavePageBtn)}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "22px",
                                    color: "#5015cf",
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "50%",
                                    backgroundColor: "#fff",
                                  }}
                                ></span>
                            </OverlayTrigger>
                            
                          </label>
                        </div>
                      </Col>
                      <Col xs="1"></Col>
                      <Col xs="1"></Col>
                      <Col xs="1">
                      <div>
                        <label
                          type="button"
                          style={{marginTop:'230px', marginLeft:'-100px'}}
                        >
                          <OverlayTrigger 
                              trigger="click" 
                              placement="bottom" 
                              overlay={InsertBtn}
                              show={openPopoverForApplyPageBtn}
                            >
                              <span
                                className="mdi mdi-information"
                                onClick={() => setOpenPopoverForApplyPageBtn(!openPopoverForApplyPageBtn)}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "22px",
                                  color: "#5015cf",
                                  height: "20px",
                                  width: "20px",
                                  borderRadius: "50%",
                                  backgroundColor: "#fff",
                                }}
                              ></span>
                            </OverlayTrigger>
                          
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4"></Col>
                      <Col md="1">
                      <div>
                        <label
                          type="button"
                          style={{marginTop:'414px', marginLeft:'10px'}}
                        >
                         
                          
                          </label>
                        </div>
                      </Col>
                    </Row>
                </div>
              </div>

              {/* For 3rd option */}
            
            </CardBody>
          </Card>
          </Col>
          </Row> 
        </Container>
        }  
      </div>
    </>
  )
}
export default Priority_Areas_Instruction
