import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Label,
  FormFeedback,
  Button,
} from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Switch from "react-switch"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import {
  getScorecardDetail,
  IsAccountOwner,
  updateScorecardDetail,
  updateScorecardDetailClear,
  getAccountHolderUsers,
  getScorecardReport,
  getScorecardDetailClear,
  sendScorecardCreationReminder,
} from "store/actions"
// import { Button } from 'bootstrap';
import * as Yup from "yup"
import { Formik, useFormik } from "formik" //Import Breadcrumb

import Select from "react-select"
import { display } from "@mui/system"

import SweetAlert from "react-bootstrap-sweetalert"
import firebase from "firebase/compat"
// import { getAccountDetails, getAccountHolderUsers } from "store/actions"

// import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ACCOUNT_OWNER } from "config/AlertMessage.config"
import updateSuccessIcon from "../../../assets/images/NewPopupIcons/Scorecard created.png"
import updateFailedIcon from "../../../assets/images/NewPopupIcons/FailedIcon.png"
import warningIcon from "../../../assets/images/NewPopupIcons/Warning.png"

const ScorecardDetails = () => {
  const params = useParams()
  const history = useHistory()
  const [isEditMode, setIsEditMode] = useState(false)
  const [is_user_exist, set_is_user_exist] = useState(false)
  const [editAssignee, setEditAssignee] = useState(false)
  const [selectedMulti, setselectedMulti] = useState([])
  const [isspin, setisSpin] = useState(false)
  const [is_take_a_survey, set_is_take_a_survey] = useState(false)
  const [is_user_active_limit_exceeded, set_is_user_active_limit_exceeded] = useState(false)
  const [HandlePopUp, setHandlePopUp] = useState(false)

  const [isAllFieldsPresent, setIsAllFieldsPresent] = useState(true)
  const [displayIncompleteFieldsPopUp, setDisplayIncompleteFieldsPopUp] = useState(false)
  // const [displayPencil, setDisplayPencil] = useState(true)

  // Newly Assigned Users to the scorecard
  const [newlyAssignedUsersToScorecard, setNewlyAssignedUsersToScorecard] = useState([])

  const formMandatory = {
    color: "red",
    fontWeight: "bold",
  }
  // const dispatch = useDispatch();
  // const [isUserAdded, setIsUserAdded] = useState(false)
  // const [isUserDisabled, setIsUserDisabled] = useState(false)

  const dispatch = useDispatch()
  const {
    scorecardReport_data,
    scorecardDetail,
    accountHolderUsers,
    updatedScorecardDetail,
    isAccountOwner,
    scorecard_path,
  } = useSelector(state => ({
    scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
    accountHolderUsers: state.AccountHolderUsersReducer.accountHolderUsers,
    updatedScorecardDetail:
      state.GetScorecardsListReducer.updatedScorecardDetail,
    isAccountOwner: state.GetScorecardsListReducer.isAccountOwner,
    scorecard_path: state.GetScorecardsListReducer.scorecard_path,
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
  }))

  // console.log("Log in USERS... ",getUserClaims)
  // console.log("SCORECARD-UPDATE-STATUS...", updatedScorecardDetail.status)
  // console.log("Account owner", isAccountOwner)
  // console.log("accountHolderUsers... ",accountHolderUsers)
  // console.log(scorecard_path)
  useEffect(() => {
    !accountHolderUsers[0] &&
      dispatch(
        getAccountHolderUsers(JSON.parse(localStorage.getItem("authUser")).uid)
      )

    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }

    return () => {
      dispatch(getScorecardDetailClear())
    }
  }, [dispatch])

  useEffect(() => {
    if (Object.keys(scorecardDetail).includes("selectedChannels")) {
      window.localStorage.setItem(
        "selectedChannels",
        JSON.stringify(
          scorecardDetail.selectedChannels.map(channel => ({
            channelId: channel.channelId,
            channelName: channel.channelName,
            tactics: channel.tactics.map(tactic => ({
              ...tactic,
              tacticName: tactic.tacticName,
              tacticId: tactic.tacticId,

            })),
          }))
        )
      )
    }
  }, [scorecardDetail])

  useEffect(() => {
    if (
      Object.keys(scorecardReport_data).includes("selectedChannels") &&
      scorecardDetail.isReportCreated
    ) {
      // console.log(scorecardReport_data)
      window.localStorage.setItem(
        "selectedChannelsForEditReport",
        JSON.stringify(
          scorecardReport_data.selectedChannels.map(channel => ({
            channelId: channel.channelId,
            channelName: channel.channelName,
            tactics: channel.tactics.map(tactic => ({
              ...tactic,
              tacticName: tactic.tacticName,
              tacticId: tactic.tacticId,

            })),
          }))
        )
      )
    }
  }, [scorecardReport_data])

  const getSelectedUsersList = () => {
    const optionGroup = accountHolderUsers
      .filter(user => user.isDisabled === false)
      .map(user => {
        return {
          label: `${user.contactName} (${user.contactEmail})`,
          value: user.id,
          accountUserContactName: user.contactName,
          accountUserId: user.id,
          address: user.address,
        }
      })

    return [
      {
        options: [...optionGroup],
      },
    ]
  }
  // console.log(scorecard_path)

  const saveAssignee = () => {
    // console.log("SELECTED-MULTI...",selectedMulti)

    if (selectedMulti[0] !== undefined) {
      var data = {
        assignedTo: [...selectedMulti],
      }
      dispatch(updateScorecardDetail(params.scorecardId, data))
    } else {
      // console.log("DONE...!")
    }
  }

  useEffect(() => {
    dispatch(updateScorecardDetailClear())
  }, [])

  useEffect(() => {
    dispatch(getScorecardDetail(params.scorecardId))
    setselectedMulti(scorecardDetail.assignedTo)
  }, [dispatch, editAssignee])

  // useEffect(() => {
  //   if (Object.keys(scorecardDetail).includes("selectedChannels")) {
  //     window.localStorage.setItem("selectedChannels", JSON.stringify(scorecardDetail.selectedChannels))
  //   }
  // }, [scorecardDetail])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user
          .getIdTokenResult()
          .then(token => {
            dispatch(IsAccountOwner(token.claims.admin))
          })
          .catch(err => { })
      }
    })
  }, [])


  useEffect(() => {
    if (isEditMode && !scorecardDetail.activeUser) {
      dispatch(
        updateScorecardDetail(
          params.scorecardId,
          {
            activeUser: {
              userName: JSON.parse(
                localStorage.getItem(
                  "authUser"
                )
              ).displayName,
              userId: JSON.parse(
                localStorage.getItem(
                  "authUser"
                )
              ).uid,
              isActive: true,
            },
            lastUpdatedOn: {
              _miliseconds: 0,
              _seconds: new Date().getTime() / 1000,
            },
          }
        )
      )

    } else if (isEditMode && scorecardDetail.activeUser) {
      setIsEditMode(false)
      // alert("user exist.")
      set_is_user_exist(true)
    }

    if (is_take_a_survey && !scorecardDetail.activeUser) {
      dispatch(
        updateScorecardDetail(
          params.scorecardId,
          {
            activeUser: {
              userName: JSON.parse(
                localStorage.getItem(
                  "authUser"
                )
              ).displayName,
              userId: JSON.parse(
                localStorage.getItem(
                  "authUser"
                )
              ).uid,
              isActive: true,
            },
            lastUpdatedOn: {
              _miliseconds: 0,
              _seconds: new Date().getTime() / 1000,
            },
            status: "In progress",
          }
        )
      )

    } else if (is_take_a_survey && scorecardDetail.activeUser) {
      set_is_take_a_survey(false)
      // alert("user exist.")
      set_is_user_exist(true)
    }
    // ------------------------------
    // if (isEditMode) {
    //   setIsEditMode(false)
    //   alert("user exist.")
    // }
    // else {
    //     if (isEditMode && updatedScorecardDetail.status === 200) {
    //       history.push(
    //         `/edit/scorecard-report/${params.scorecardId}/cover-page`
    //       )
    //     }
    //   }
    // --------------------------------


  }, [scorecardDetail])


  useEffect(() => {
    if (scorecardDetail.activeUser) {
      var time_1 = new Date().getTime() / 1000
      var time_2 = scorecardDetail.lastUpdatedOn._seconds
      var totalSeconds = time_1 - time_2
      var seconds = (totalSeconds % 60)
      var minutes = (totalSeconds % 3600) / 60
      var hours = (totalSeconds % 86400) / 3600
      var days = (totalSeconds % (86400 * 30)) / 86400
      // console.log(seconds, minutes, hours, days)

      if (days >= 1 || hours >= 1 || minutes >= 20) {
        // console.log("active from too long")
        dispatch(
          updateScorecardDetail(
            params.scorecardId,
            {
              activeUser: null,

            }
          )
        )
        set_is_user_active_limit_exceeded(true)
        setHandlePopUp(true)
      }


    }
  }, [scorecardDetail])

  useEffect(() => {
    if (is_user_active_limit_exceeded && updatedScorecardDetail.status === 200) {
      dispatch(getScorecardDetail(params.scorecardId))
      set_is_user_active_limit_exceeded(false)
      setHandlePopUp(false)
      dispatch(updateScorecardDetailClear())
    }
  }, [updatedScorecardDetail])

  useEffect(() => {
    if (isEditMode && updatedScorecardDetail.status === 200) {
      history.push(
        `/edit/scorecard-report/${params.scorecardId}/cover-page`
      )
    }
    if (is_take_a_survey && updatedScorecardDetail.status === 200) {

      var channelId =
        scorecardDetail
          .selectedChannels[0].channelId
      var tacticId =
        scorecardDetail
          .selectedChannels[0].tactics[0]
          .tacticId
      history.push(
        `/take-a-survey/${params.scorecardId}/${channelId}/${tacticId}/${tacticId}`
      )
    }
  }, [updatedScorecardDetail])


  ///////////////////////////////// Below UseEffect is added by Mohanish Saim //////////////////////////////////

  useEffect(() => {

    const requireKeys = ["scorecardName", "scorecardVersion", "phone", "email", "companyAddress1", "country", "state", "annualRevenue", "numberOfEmployee", "industry", "websiteUrl"]

    setIsAllFieldsPresent(hasRequiredKeys(scorecardDetail, requireKeys))

  }, [scorecardDetail])

  function hasRequiredKeys(obj, keys) {
    // console.log("CALLING ALL REQUIRED KEYS FUNCTION...")
    for (let key of keys) {
      if (!(scorecardDetail[key])) {
        // if (!(key in obj)) {
        return false;
      }
    }
    return true;
  }

  // ------------------------- Email Notification Functionality ---------------------------
  // sent email if another user get's added into selectMulti array
  const getEmailNotificationToNewlyAssignedUser = (prevUsers, currUsers) => {
    let newlyAssignedUsers = [];
    // Check each user in the updated array
    currUsers.forEach(currUser => {
      // Check if the user exists in the previous array
      const exists = prevUsers.some(prevUser => prevUser.accountUserId === currUser.accountUserId);
      // If the user doesn't exist in the previous array, add them to the newly assigned users array
      if (!exists) {
        newlyAssignedUsers.push(currUser);
      }
    });

    // console.log("newlyAssignedUsers: ", newlyAssignedUsers)
    setNewlyAssignedUsersToScorecard([...newlyAssignedUsers])
  }

  // useEffect to trigger 
  useEffect(() => {
    if (editAssignee && scorecardDetail && scorecardDetail.assignedTo) {
      if (selectedMulti && selectedMulti[0]) {
        let prevUsers = [...scorecardDetail.assignedTo]
        let currUsers = [...selectedMulti]
        getEmailNotificationToNewlyAssignedUser(prevUsers, currUsers)
      } else {
        // console.log("No one selected..")
        setNewlyAssignedUsersToScorecard([])
      }
    } else {
      // console.log("I am not editing more..")
      setNewlyAssignedUsersToScorecard([])
    }
  }, [scorecardDetail, editAssignee, selectedMulti])

  // Sent the mail whenever the user related changes are saved
  useEffect(() => {
    if (updatedScorecardDetail.status === 200 && newlyAssignedUsersToScorecard[0]) {
      let mailObject = {
        accountOwnerEmailId: scorecardDetail.creatorEmail,
        accountUsersNames: newlyAssignedUsersToScorecard.map(e => {
          return e.accountUserContactName
        }).filter(e => e != undefined),
        accountUsersEmailIds: newlyAssignedUsersToScorecard.map(e => {
          return e.label.split('(')[1].split(')')[0]
        }).filter(e => e != undefined),
        accountOwnerMessage: scorecardDetail.accountOwnerMessage,
        envLink: `${process.env.REACT_APP_FRONTEND_LINK}`,
        mailHeading: "New User Assigned to the Scorecard"
      }

      // console.log("mailObject: ", mailObject)
      dispatch(sendScorecardCreationReminder(mailObject))
    }
  }, [updatedScorecardDetail])

  // console.log("scorecardDetail...scorecardDetail...", scorecardDetail)
  // console.log("setIsAllFieldsPresent...", isAllFieldsPresent)

  return (
    <>
      <div className="page-content" style={{ marginBottom: 5 }}>
        <MetaTags>
          <title> Mambo -Scorecard Details</title>
        </MetaTags>
        <Container fluid={true}>

          {!HandlePopUp && !isEditMode && updatedScorecardDetail.status === 200 && (
            <SweetAlert
              style={{ height: "270px", width: "270px" }}
              custom
              customIcon={
                <Row>
                  <Col md="12" className="mt-2">
                    <img src={updateSuccessIcon}
                      style={{
                        height: '80px',
                        width: '85px'
                      }} />
                  </Col>
                  <Col md="12" className="text-center container-fluid mt-3"
                    style={{
                      color: '#0D0F11',
                      fontWeight: 600,
                      fontSize: '24px',
                      lineHeight: '28px',
                    }}
                  >
                    {ACCOUNT_OWNER.VIEW_USER_DETAILS.SUCCESS}
                  </Col>
                </Row>
              }
              customButtons={
                <div className="container-fluid mb-2" style={{ marginTop: '-10px' }}>
                  <Button block size="sm" className="my-2"
                    style={{
                      background: '#0D0F11',
                      border: '#0D0F11'
                    }}
                    onClick={() => {
                      setEditAssignee(false)
                      setisSpin(false)
                      dispatch(updateScorecardDetailClear())
                      // history.push("/view/scorecard/" + params.scorecardId)
                    }}
                  >
                    Close
                  </Button>
                </div>
              }
            >
            </SweetAlert>
          )}


          {!HandlePopUp && !isEditMode && updatedScorecardDetail.status === 403 && (
            <SweetAlert
              style={{ height: "270px", width: "270px" }}
              custom
              customIcon={
                <Row>
                  <Col md="12" className="mt-2">
                    <img src={updateFailedIcon}
                      style={{
                        height: '80px',
                        width: '80px'
                      }} />
                  </Col>
                  <Col md="12" className="text-center container-fluid mt-3"
                    style={{
                      color: '#0D0F11',
                      fontWeight: 600,
                      fontSize: '24px',
                      lineHeight: '28px',
                    }}
                  >
                    Failed to update changes.
                  </Col>
                </Row>
              }
              customButtons={
                <div className="container-fluid mb-2" style={{ marginTop: '-10px' }}>
                  <Button block size="sm" className="my-2"
                    style={{
                      background: '#0D0F11',
                      border: '#0D0F11'
                    }}
                    onClick={() => {
                      setEditAssignee(false)
                      setisSpin(false)
                      dispatch(updateScorecardDetailClear())
                    }}
                  >
                    Close
                  </Button>
                </div>
              }
            >
            </SweetAlert>
          )}


          {is_user_exist && (
            <SweetAlert
              style={{ height: "270px", width: "270px" }}
              custom
              customIcon={
                <Row>
                  <Col md="12" className="mt-2">
                    <img src={updateFailedIcon}
                      style={{
                        height: '80px',
                        width: '80px'
                      }} />
                  </Col>
                  <Col md="12" className="text-center container-fluid mt-3"
                    style={{
                      color: '#0D0F11',
                      fontWeight: 600,
                      fontSize: '20px',
                      lineHeight: '28px',
                    }}
                  >
                    {scorecardDetail.activeUser && scorecardDetail.activeUser.userName} {" "} is working.
                  </Col>
                </Row>
              }
              customButtons={
                <div className="container-fluid mb-2" style={{ marginTop: '-10px' }}>
                  <Button block size="sm" className="my-2"
                    style={{
                      background: '#0D0F11',
                      border: '#0D0F11'
                    }}
                    onClick={() => {
                      set_is_user_exist(false)
                    }}
                  >
                    Close
                  </Button>
                </div>
              }
            >
            </SweetAlert>
          )}

          {displayIncompleteFieldsPopUp && (
            <SweetAlert
              style={{ height: "270px", width: "270px" }}
              custom
              customIcon={
                <Row>
                  <Col md="12" className="mt-2">
                    <img src={warningIcon}
                      style={{
                        height: '80px',
                        width: '80px'
                      }} />
                  </Col>
                  <Col md="12" className="text-center container-fluid mt-3"
                    style={{
                      color: '#0D0F11',
                      fontWeight: 600,
                      fontSize: '20px',
                      lineHeight: '28px',
                    }}
                  >
                    {"Please fill all required Client Information"}
                  </Col>
                </Row>
              }
              customButtons={
                <div className="container-fluid mb-2" style={{ marginTop: '-10px' }}>
                  <Button block size="sm" className="my-2"
                    style={{
                      background: '#0D0F11',
                      border: '#0D0F11'
                    }}
                    onClick={() => {
                      // set_is_user_exist(false)
                      setDisplayIncompleteFieldsPopUp(false)
                    }}
                  >
                    Close
                  </Button>
                </div>
              }
            >
            </SweetAlert>
          )}

          <Row>
            <Row>
              {!scorecardDetail.id ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    height: "400px",
                  }}
                >
                  <Spinner
                    style={{
                      width: "60px",
                      height: "60px",
                      margin: "calc(200px - 30px)",
                      color: '#05727B'
                    }}
                    role="grid"
                    type="border"
                  // color="primary"
                  />
                </div>
              ) : (
                <>
                  <Col className="col-12">
                    <Card style={{ borderRadius: "2px" }}>
                      <CardBody>
                        <Row>
                          <Col sm="4">
                            <CardTitle
                              className="h4"
                              style={{ color: "#0F838C", fontSize: "18px" }}
                            >
                              Scorecard File Name
                            </CardTitle>
                            <p>{scorecardDetail.scorecardName || "N.A."}</p>
                          </Col>
                          {/* <Col sm="5"></Col> */}
                          <Col sm="7">
                            <CardTitle
                              className="h4"
                              style={{ color: "#0F838C", fontSize: "18px" }}
                            >
                              Scorecard Type
                            </CardTitle>
                            <p>{`${scorecardDetail.scorecardVersion} Scorecard` || "N.A."}</p>
                          </Col>
                          <Col sm="1">
                            <i
                              className="bx bx-undo "
                              style={{
                                fontSize: "30px",
                                cursor: "pointer",
                                textAlign: "right",
                                width: "120%",
                                color: '#05727B'

                              }}
                              onClick={() => {
                                // isAccountOwner && scorecard_path
                                //   ? history.push(scorecard_path)
                                //   : history.goBack()
                                history.goBack()
                              }}
                            ></i>
                          </Col>
                          {/* </Row>
                        <Row> */}

                        </Row>
                      </CardBody>
                    </Card>

                    <Row>
                      <Col className="col-12" style={{}}>
                        <Card
                          style={{
                            borderRadius: "2px",
                            position: "relative",
                            overflow: "hidden",
                          }}
                        >
                          <CardBody>
                            <CardTitle
                              className="h4"
                              style={{
                                color: "white",
                                fontSize: "18px",
                                backgroundColor: "#0F838C",
                                padding: "10px 20px",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                              }}
                            >
                              <Row>
                                <Col md="11">Client Information</Col>
                                <Col md="1">
                                  <i
                                    className="fas fa-pencil-alt"
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      color: "#fff",
                                      textAlign: "right",
                                      width: "100%",
                                    }}
                                    onClick={() => {
                                      if (scorecardDetail && scorecardDetail.scorecardVersion === "Pro") {
                                        history.push(
                                          "/scorecard/update/" +
                                          params.scorecardId
                                        )
                                      } else {
                                        history.push(
                                          "/scorecard/self-report/update/" +
                                          params.scorecardId
                                        )
                                      }
                                    }}
                                  ></i>
                                </Col>
                              </Row>
                            </CardTitle>
                            <br></br>
                            <Row style={{ marginTop: "26px" }}>
                              <Col sm="4">
                                <i
                                  className="bx bx-chevron-up-square"
                                  style={{
                                    fontSize: "16px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  COMPANY NAME
                                </label>
                                <p style={{ marginLeft: "26px" }}>
                                  {scorecardDetail.companyName || "N.A."}
                                </p>
                              </Col>
                              <Col sm="4">
                                <i
                                  className="bx bx-user"
                                  style={{
                                    fontSize: "16px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  CONTACT NAME
                                </label>
                                <p style={{ marginLeft: "26px" }}>
                                  {scorecardDetail.contactName || "N.A."}
                                </p>
                              </Col>
                              <Col sm="4">
                                <i
                                  className="bx bx-phone-call"
                                  style={{
                                    fontSize: "16px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  PHONE
                                </label>
                                {/* <p style={{ }} >{scorecardDetail.countryCode || "N.A."}</p> */}
                                <p style={{ marginLeft: "26px" }}>
                                  +{scorecardDetail.countryCode || "N.A."}{" "}
                                  &nbsp;{scorecardDetail.phone || "N.A."}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="4">
                                <i
                                  className="bx bx-envelope"
                                  style={{
                                    fontSize: "16px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  EMAIL
                                </label>
                                <p style={{ marginLeft: "26px" }}>
                                  {scorecardDetail.email || "N.A."}
                                </p>
                              </Col>
                              <Col sm="4">
                                <i
                                  className="bx bx-map"
                                  style={{
                                    fontSize: "16px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  COMPANY ADDRESS
                                </label>
                                <p style={{ marginLeft: "26px" }}>
                                  {scorecardDetail.companyAddress1 || "N.A."}
                                </p>
                              </Col>
                              <Col sm="4">
                                <i
                                  className="bx bx-world"
                                  style={{
                                    fontSize: "16px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  COUNTRY
                                </label>
                                <p style={{ marginLeft: "26px" }}>
                                  {scorecardDetail.country || "N.A."}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="4">
                                <i
                                  className="bx bx-world"
                                  style={{
                                    fontSize: "16px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  STATE
                                </label>
                                <p style={{ marginLeft: "26px" }}>
                                  {scorecardDetail.state || "N.A."}
                                </p>
                              </Col>
                              <Col sm="4">
                                <i
                                  className="bx bx-dollar-circle"
                                  style={{
                                    fontSize: "16px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  ANNUAL REVENUE
                                </label>
                                <p style={{ marginLeft: "26px" }}>
                                  {scorecardDetail.annualRevenue || "N.A."}
                                </p>
                              </Col>
                              <Col sm="4">
                                <i
                                  className="bx bx-group"
                                  style={{
                                    fontSize: "16px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  NUMBER OF EMPLOYEES
                                </label>
                                <p style={{ marginLeft: "26px" }}>
                                  {scorecardDetail.numberOfEmployee || "N.A."}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="4">
                                <i
                                  className="bx bx-vector"
                                  style={{
                                    fontSize: "16px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  INDUSTRY
                                </label>
                                <p style={{ marginLeft: "26px" }}>
                                  {scorecardDetail.industry || "N.A."}
                                </p>
                              </Col>
                              <Col sm="4">
                                <i
                                  className="dripicons-web"
                                  style={{
                                    fontSize: "14px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  WEBSITE URL
                                </label>
                                <p style={{ marginLeft: "26px" }}>
                                  {scorecardDetail.websiteUrl || "N.A."}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="4">
                                <i
                                  className="bx bx-align-left"
                                  style={{
                                    fontSize: "16px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  CLIENT ATTRIBUTES
                                </label>
                                <p style={{ marginLeft: "26px" }}>
                                  {scorecardDetail.clientAttribute1 || "N.A."}
                                </p>
                              </Col>
                              <Col sm="4">
                                <label> </label>
                                <p
                                  style={{
                                    marginLeft: "26px",
                                    marginTop: "8px",
                                  }}
                                >
                                  {scorecardDetail.clientAttribute2 || ""}
                                </p>
                              </Col>
                              <Col sm="4">
                                <label></label>
                                <p
                                  style={{
                                    marginLeft: "26px",
                                    marginTop: "8px",
                                  }}
                                >
                                  {scorecardDetail.clientAttribute3 || ""}
                                </p>
                              </Col>

                              {scorecardDetail.clientAttribute4 ? (
                                <div>
                                  <Col sm="auto">
                                    <label></label>
                                    <p style={{ marginLeft: "26px" }}>
                                      {scorecardDetail.clientAttribute4 || ""}
                                    </p>
                                  </Col>
                                </div>
                              ) : null}
                            </Row>
                            {/* <Row>
                               
                              </Row> */}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12">
                        <Card style={{ borderRadius: "2px" }}>
                          <CardBody>
                            <Row>
                              <Col md="12">
                                <i
                                  className="bx bxs-share-alt"
                                  style={{
                                    fontSize: "16px",
                                    marginBottom: "10px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  SELECTED CHANNELS
                                </label>
                              </Col>
                              <Col md="12">
                                <Row>
                                  {scorecardDetail.selectedChannels ? (
                                    scorecardDetail.selectedChannels.map(
                                      channel => (
                                        <Col md="4" key={channel.channelId}>
                                          <ul>
                                            <li style={{ color: "#0F838C" }}>
                                              <span style={{ color: "black" }}>
                                                {channel.channelName}
                                              </span>
                                            </li>
                                          </ul>
                                        </Col>
                                      )
                                    )
                                  ) : (
                                    <Col md="4">
                                      <ul>
                                        <li style={{ color: "#0F838C" }}>
                                          <span style={{ color: "black" }}>
                                            N.A.
                                          </span>
                                        </li>
                                      </ul>
                                    </Col>
                                  )}
                                </Row>
                              </Col>
                            </Row>
                            {/* <label></label> */}
                            <Row>
                              {/* <Row> */}

                              <div style={{}}>
                                <i
                                  className="bx bx-user-plus"
                                  style={{
                                    fontSize: "16px",
                                    marginBottom: "10px",
                                    color: "#0F838C",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                <label style={{ fontWeight: "bold" }}>
                                  SCORECARD ASSIGNED TO
                                </label>
                                {isAccountOwner ? (
                                  <i
                                    className="fas fa-pencil-alt"
                                    style={{
                                      marginLeft: "15px",
                                      color: "#0F838C",
                                      fontSize: "16px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setEditAssignee(true)
                                      // setDisplayPencil(false)
                                    }}
                                  ></i>
                                ) : null}
                              </div>

                              {/* </Row> */}
                              {editAssignee ? (
                                <div className="mb-3">
                                  {/* <label className="control-label">
                                        
                                      </label> */}
                                  {/* <span style={formMandatory}>*</span> */}
                                  <Select
                                    value={selectedMulti}
                                    isMulti={true}
                                    onChange={e => {
                                      setselectedMulti(e)
                                    }}
                                    // onBlur={validation.handleBlur}

                                    options={getSelectedUsersList()}
                                    classNamePrefix="select2-selection"
                                  />
                                  <p></p>
                                  <Col md="10"></Col>
                                  <Row>
                                    <Col md="8"></Col>
                                    <Col md="2">
                                      <Button
                                        // color="danger"
                                        style={{ backgroundColor: 'black' }}
                                        onClick={() => {
                                          setEditAssignee(false)
                                          // setDisplayPencil(true)
                                        }}
                                        block
                                      >
                                        Cancel
                                      </Button>
                                    </Col>

                                    <Col md="2">
                                      <Button
                                        // color="primary"
                                        style={{
                                          background: '#0F838C',
                                          border: '#0F838C',
                                          height: '36.53px'
                                        }}
                                        onClick={() => {
                                          setHandlePopUp(false)
                                          setisSpin(true)
                                          saveAssignee()
                                        }}
                                        block
                                        disabled={
                                          selectedMulti &&
                                            selectedMulti[0] === undefined
                                            ? true
                                            : false
                                        }
                                      >
                                        {!isspin ? "Save" : ""}
                                        {!isspin ? null : (
                                          <Spinner type="border" size="sm" />
                                        )}
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              ) : (
                                <div>
                                  <div>
                                    <Col md="12">
                                      <Row>
                                        {scorecardDetail.assignedTo ? (
                                          scorecardDetail.assignedTo.map(
                                            assignee => (
                                              <Col
                                                md="4"
                                                key={assignee.accountUserId}
                                              >
                                                <ul>
                                                  <li
                                                    style={{
                                                      marginRight: "40px",
                                                      color: "#05727B",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ color: "black" }}
                                                    >
                                                      {
                                                        assignee.accountUserContactName
                                                      }
                                                    </span>
                                                  </li>
                                                </ul>
                                              </Col>
                                            )
                                          )
                                        ) : (
                                          <Col md="4">
                                            <ul>
                                              <li
                                                style={{ color: "background" }}
                                              >
                                                <span
                                                  style={{ color: "black" }}
                                                >
                                                  N.A.
                                                </span>
                                              </li>
                                            </ul>
                                          </Col>
                                        )}
                                      </Row>
                                    </Col>
                                  </div>
                                </div>
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    {
                      scorecardDetail.status === "Not started" &&

                      <Row>
                        <Col className="col-12">
                          <Card style={{ borderRadius: "2px" }}>
                            <CardBody>
                              {scorecardDetail.isSurveyCompleted &&
                                scorecardDetail.isSurveyCompleted ? (
                                scorecardDetail.isReportCreated ? (
                                  <Row>
                                    {scorecardDetail.activeUser &&
                                      scorecardDetail.activeUser.userId ===
                                      JSON.parse(localStorage.getItem("authUser"))
                                        .uid ? (
                                      <>
                                        <Col md="9"></Col>
                                        <Col md="3">
                                          <Button
                                            style={{ backgroundColor: '#05727B' }}
                                            onClick={() => {
                                              history.push(
                                                `/edit/scorecard-report/${params.scorecardId}/cover-page`
                                              )
                                            }}
                                            block
                                          >
                                            Continue Editing
                                          </Button>
                                        </Col>
                                      </>
                                    ) : (
                                      <>
                                        <Col md="10"></Col>
                                        <Col md="2">
                                          <Button
                                            disabled={
                                              scorecardDetail.activeUser &&
                                              scorecardDetail.activeUser.isActive
                                              || isEditMode
                                            }
                                            style={{ backgroundColor: '#05727B' }}
                                            onClick={() => {
                                              setIsEditMode(true)
                                              dispatch(getScorecardDetail(params.scorecardId))
                                            }}
                                            block
                                          >
                                            {isEditMode ? "" : "Edit Report"}
                                            {isEditMode ? (
                                              <Spinner
                                                // style={{ width: "0.8rem", height: "0.8rem" }}
                                                type="border"
                                                size="sm"
                                              />
                                            ) : null}
                                            {/* Edit Report */}
                                          </Button>
                                        </Col>
                                      </>
                                    )}
                                  </Row>
                                ) : (
                                  <Row>
                                    <Col md="9"></Col>
                                    <Col md="3">
                                      <Button
                                        // disabled={}
                                        style={{ backgroundColor: '#05727B' }}
                                        onClick={() => {
                                          history.push(
                                            `/scoringSummary/${params.scorecardId}`
                                          )
                                        }}
                                        block
                                      >
                                        View Scorecard Summary
                                      </Button>
                                    </Col>
                                  </Row>
                                )
                              ) : (
                                scorecardDetail.id && (
                                  <Row>
                                    {scorecardDetail.activeUser &&
                                      scorecardDetail.activeUser.userId ===
                                      JSON.parse(localStorage.getItem("authUser"))
                                        .uid ? (
                                      <>
                                        <Col md="9"></Col>
                                        <Col md="3">
                                          <Button
                                            style={{ backgroundColor: '#05727B' }}
                                            onClick={() => {
                                              var channelId =
                                                scorecardDetail
                                                  .selectedChannels[0].channelId
                                              var tacticId =
                                                scorecardDetail
                                                  .selectedChannels[0].tactics[0]
                                                  .tacticId
                                              history.push(
                                                `/take-a-survey/${params.scorecardId}/${channelId}/${tacticId}/${tacticId}`
                                              )
                                            }}
                                            block
                                          >
                                            Continue Assessment
                                          </Button>
                                        </Col>
                                      </>
                                    ) : (
                                      <>
                                        <Col md="9"></Col>
                                        <Col md="3">
                                          <Button
                                            disabled={
                                              is_take_a_survey ||
                                              (scorecardDetail.activeUser &&
                                                scorecardDetail.activeUser
                                                  .isActive)
                                            }
                                            style={{ backgroundColor: "#0F838C" }}
                                            onClick={() => {
                                              if (scorecardDetail.scorecardVersion === "Self Report") {
                                                if (isAllFieldsPresent) {
                                                  set_is_take_a_survey(true)
                                                  dispatch(getScorecardDetail(params.scorecardId))
                                                }
                                                else {
                                                  setDisplayIncompleteFieldsPopUp(true)
                                                }
                                              }
                                              else {
                                                set_is_take_a_survey(true)
                                                dispatch(getScorecardDetail(params.scorecardId))
                                              }
                                            }}
                                            block
                                          >
                                            {is_take_a_survey ? "" : "Take an Assessment"}
                                            {is_take_a_survey ? (
                                              <Spinner type="border" size="sm" />
                                            ) : null}
                                            {/* Take A Survey */}
                                          </Button>
                                        </Col>
                                      </>
                                    )}
                                  </Row>
                                )
                              )}
                              {scorecardDetail.activeUser &&
                                scorecardDetail.activeUser.userId !==
                                JSON.parse(localStorage.getItem("authUser"))
                                  .uid && (
                                  <span
                                    style={{
                                      marginTop: "10px",
                                      textAlign: "center",
                                      color: "red",
                                      fontSize: "10px",
                                      float: "right",
                                      padding: "0px 12px",
                                    }}
                                  >
                                    {scorecardDetail.activeUser &&
                                      scorecardDetail.activeUser.userName}{" "}
                                    is working...
                                  </span>
                                )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    }
                  </Col>
                </>
              )}
            </Row>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ScorecardDetails
