import { color, width } from "@mui/system"
import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package

//pages
import Methodology from "./Report/pageThird/index"
import MaturityDefined from "./Report/pageFourth/index"
import AreaFocus from "./Report/pageSix/index"
import ExecutiveSummary from "./Report/pageFive/index"
import ScoreGlance from "./Report/pageSeven/index"
import KeyTakeaway from "./Report/pageEight/index"
import PageFirst from "./Report/index"
import TableContent from "./Report/pageSecond"
import ContentQuick from "./Report/pageNine"
import KeyObservations from "./Report/pageTen/index"
import DoingWell from "./Report/pageEleven/index"
import ChannelIndex from "./Report/pageTwelve/ChannelIndex"
import TacticEvaluated from "./Report/pageThirteen/index"
import ChannelFirst from "./Report/pageFourteen/index"

import CompanyInformationPage from "./Report/companyInformation"

//  import ReactDOMServer from "react-dom/server"
// import jsPDF from 'jspdf';
// import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
} from "reactstrap"

import ReactToPrint from "react-to-print"
import "./pdf.test.css"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
// import ReactToPdf from "react-to-pdf"
import { getScorecardReport } from "store/actions"
import { useHistory, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { channel } from "redux-saga"

const GeneratePDF = () => {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [executiveSummary, setExecutiveSummary] = useState([])
  const { scorecardReport_data } = useSelector(state => ({
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
  }))

  useEffect(() => {
    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
    setExecutiveSummary([
      {
        pageId: 0,
        bgImage: "",
        imgUrl: "",
        // imgUrl: image_url,
        // contents: "<p>Hello world</p>"
      },
    ])
  }, [dispatch])

  // console.log(executiveSummary[0] && executiveSummary.map((ex, i) => ex))
  // console.log(scorecardReport_data.executiveSummary && scorecardReport_data.executiveSummary.map((ex, i) => ex))

  // const pdfComponentRef = React.createRef();
  const doc = new jsPDF()
  // doc.save("test.pdf")

  const pdfComponentRef = useRef(null)

  return (
    <div>
      <div className="page-content Dom_Test" id="Report">
        <MetaTags>
          <title> Mambo - PDF </title>
        </MetaTags>
        <Container fluid={true}>
          <div className="Dom_Test11">
            <Button
              style={{
                marginLeft: 627,
                marginRight: 15,
              }}
              color="primary"
              className="mb-2"
              onClick={() => {
                history.goBack()
              }}
            >
              {
                scorecardReport_data && 
                scorecardReport_data.scorecardVersion === "Pro" ?
                "Continue Editing" : "View Report"
              }
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button color="primary" className="mb-2">
                  Print
                </Button>
              )}
              content={() => pdfComponentRef.current}
            />
            {Object.keys(scorecardReport_data).includes("id") &&
            scorecardReport_data.scorecardVersion === "Pro" ? (
              <div
                ref={pdfComponentRef}
                className="demo-pdf"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="pagebreak" />

                <PageFirst
                  Cover_page={
                    scorecardReport_data.cover && scorecardReport_data
                  }
                />
                <div className="pagebreak" />

                <TableContent
                  Table_of_content={
                    scorecardReport_data.tableOfContents && scorecardReport_data
                  }
                />
                <div className="pagebreak" />

                <Methodology />
                <div className="pagebreak" />

                <MaturityDefined />
                <div className="pagebreak" />
                {scorecardReport_data.executiveSummary
                  ? scorecardReport_data.executiveSummary.map((ExSum, i) => (
                      <ExecutiveSummary key={i} Executive_summary={ExSum} />
                    ))
                  : executiveSummary[0] &&
                    executiveSummary.map((Ex_Summary, i) => (
                      <ExecutiveSummary
                        key={i}
                        Executive_summary={Ex_Summary}
                      />
                    ))}
                <div className="pagebreak" />

                <AreaFocus
                  Priority_area_of_focus={
                    scorecardReport_data.priorityAreaOfFocus &&
                    scorecardReport_data
                  }
                />

                <div className="pagebreak" />

                <ScoreGlance
                  Score_at_glance={
                    scorecardReport_data.selectedChannels[0] &&
                    scorecardReport_data
                  }
                />
                <div className="pagebreak" />

                {scorecardReport_data.selectedChannels[0] &&
                  scorecardReport_data.selectedChannels.map(channel => (
                    <>
                      <ChannelFirst ChannelData={channel} />
                      <div className="pagebreak" />

                      <TacticEvaluated ChannelData={channel} />
                      <div className="pagebreak" />
                      <KeyObservations
                        Key_observations={channel.keyObservations}
                        ChannelData={channel}
                      />
                      <div className="pagebreak" />

                      <DoingWell
                        Doing_well={channel.doingWell}
                        ChannelData={channel}
                      />

                      <div className="pagebreak" />
                      <ContentQuick
                        Content_quick_wins={channel.quickWins}
                        ChannelData={channel}
                      />

                      <div className="pagebreak" />

                      <ChannelIndex
                        Content_recomendation={
                          channel.tactics &&
                          channel.tactics.filter(
                            tactic => tactic.recommendation !== ""
                          )
                        }
                        ChannelData={channel}
                      />
                    </>
                  ))}
                <KeyTakeaway
                  Key_takeaways={
                    scorecardReport_data.keyTakeaways &&
                    scorecardReport_data.keyTakeaways
                  }
                />
              </div>
            ) : Object.keys(scorecardReport_data).includes("id") && 
            scorecardReport_data.scorecardVersion === "Self Report" ? (
              <div
                ref={pdfComponentRef}
                className="demo-pdf"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="pagebreak" />

                <PageFirst
                  Cover_page={
                    scorecardReport_data.cover && scorecardReport_data
                  }
                />
                <div className="pagebreak" />

                <CompanyInformationPage
                  Company_Information={scorecardReport_data}
                />
                <div className="pagebreak" />

                <Methodology />
                <div className="pagebreak" />

                <MaturityDefined />
                <div className="pagebreak" />

                <ScoreGlance
                  Score_at_glance={
                    scorecardReport_data.selectedChannels[0] &&
                    scorecardReport_data
                  }
                />
                <div className="pagebreak" />

                {scorecardReport_data.selectedChannels[0] &&
                  scorecardReport_data.selectedChannels.map(channel => (
                    <>
                      <ChannelFirst ChannelData={channel} />
                      <div className="pagebreak" />

                      <TacticEvaluated ChannelData={channel} />
                      <div className="pagebreak" />

                      <ChannelIndex
                        Content_recomendation={
                          channel.tactics &&
                          channel.tactics.filter(
                            tactic => tactic.recommendation !== ""
                          )
                        }
                        ChannelData={channel}
                      />
                    </>
                  ))}
              </div>
            ) : null}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default GeneratePDF
