export const REPORT = {
  COLOR: {
    PRIMARY: "#000000",
    SECONDARY: "#ffffff",
    PRIMARY_BG: "#eee1bb",
    SECONDARY_BG: "#f76c5b",
    PRIORITY_1: "#94d2bd",
    PRIORITY_2: "#ed9c37",
    PRIORITY_3: "#269396",
    PRIORITY_4: "#015f73",
    FOOTER: "#f88f82",
  },
  FONT_SIZE: {
    SMALL: "12px",
    MEDIUM: "14px",
    LARGE: "16px",
    XLARGE: "28PX",
  },
  IMAGE: {
    BACKGROUND: {
      HEIGHT: 1056,
      WIDTH: 816,
    },
    EXECUTIVE: {
      HEIGHT: 1056,
      WIDTH: 339.2,
    },
    PRIORITY: {
      HEIGHT: 384.96,
      WIDTH: 326.08,
    },
    CHANNEL: {
      HEIGHT: 529.92,
      WIDTH: 816,
    },
    KEY_OBSERVATIONS: {
      HEIGHT: 249.92,
      WIDTH: 188.16,
    },
   QUICK_WINS: {
      HEIGHT: 408,
      WIDTH: 305.92,
    },
    KEY_TAKEAWAYS:{
      HEIGHT:1056,
      WIDTH:396.16
    },
    REPORT: {
      HEIGHT: 1056,
      WIDTH: 816,
    },
  },
 
}
