import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row className="text-center">  
          <Col md={12}>
            <a href="https://www.mambomedia.com" style={{color:'#A0ABBB'}}>{new Date().getFullYear()} © Mambo Media Inc. All Rights Reserved.</a>  
            <a href="/terms-conditions" style={{marginLeft:'5px', color:'#0DF11', textDecoration:'none'}}>Terms of Service</a>&nbsp;  | 
            &nbsp;<a href="/privacy-policy" style={{color:'#0DF11', textDecoration:'none'}}>Privacy Policy</a>&nbsp; |
            &nbsp;<a href = "/contact-us" style={{color:'#0DF11', textDecoration:'none'}}>Contact Us</a> | 
            &nbsp;<a href="https://zingworks.in/" style={{color:'#0DF11',textDecoration:'none'}}>Powered by Zingworks LLP</a></Col>
            {/* <Col md={3}>
              <div className="text-sm-end d-none d-sm-block">
                <a href="https://zingworks.in/">Powered by Zingworks LLP</a>
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
