import { takeEvery, put, call,all,fork  } from "redux-saga/effects";

// Login Redux States
import {
    GET_ACCOUNTUSER_DATA,
} from "./actionTypes"
import {
    getAccountUserDataSuccess,
    getAccountUserDataFail,
} from "./actions"
                                      
import { getAccountuserData } from "../../helpers/fakebackend_helper";

function* fetchAccountUserData() {
  try {
    const response = yield call(getAccountuserData)
    yield put(getAccountUserDataSuccess(response))
  } catch (error) {
    yield put(getAccountUserDataFail(error))
  }
}
                                      
export function* AccountUserSaga() {
  yield takeEvery(GET_ACCOUNTUSER_DATA, fetchAccountUserData);
}
                                      

                                      
export default AccountUserSaga;