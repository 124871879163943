import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import SweetAlert from "react-bootstrap-sweetalert"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import "../../configCk/ck.style.css"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  NavItem,
  NavLink,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"

import { useHistory } from "react-router-dom"

import {
  createReportTemplate,
  getReportTemplate,
  clearReportTemplate,
  handleUnsavedChanges,
  handleChangesHappend,
  UpdateGetReportTemplate,
} from "store/actions"

const KeyTakeaways = () => {
  const dispatch = useDispatch()
  const [rows1, setrows1] = useState([])
  const [input, setInput] = useState([])
  const history = useHistory()
  const [isDataAdd, setIsDataAdd] = useState(false)
  const [isGetReportData, setIsGetReportData] = useState(true)
  const [KeyTakeawaysUpdate, setKeyTakeawaysUpdate] = useState({})
  // const [isGetReportData, setIsGetReportData] = useState(true)


  const { getReport, createReport, isDataEdited, isThereChange } = useSelector(state => ({
    getReport: state.createReportReducer.getReport,
    createReport: state.createReportReducer.createReport,
    isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
  }))

  // console.log("Report", getReport)

  useEffect(() => {
    if (!Object.keys(getReport).includes("KeyTakeaways")) {
      dispatch(getReportTemplate())
    }
  }, [dispatch])
  // console.log(createReport)

  useEffect(() => {
    if (getReport.keyTakeaways !== undefined) {
      // setInput(getReport.executiveSummary.contents)
      var x = getReport.keyTakeaways.contents.map((KeyTakeaways_, i) => {
        return {
          id: Math.random(),
          content: KeyTakeaways_,
        }
      })
      setrows1(x)
      setIsGetReportData(false)
    } else {
      setrows1([{ id: Math.random(), content: "" }])
      setIsGetReportData(false)

    }
  }, [getReport])

  const KeyTakeawaysManager = () => {
    var getKeyTakeaways = document.querySelectorAll(".ck-content")
    var myKeyTakeawaysList = [...getKeyTakeaways]

    var myKeyTakeaways = myKeyTakeawaysList.map(obs => obs.innerHTML)
    return myKeyTakeaways
  }

  function handleAddRowNested() {
    if (rows1.length < 10) {
      // const modifiedRows = [...rows1]
      // modifiedRows.push({ id: modifiedRows.length + 1 })
      setrows1(e => [...e, { id: Math.random(), content: "" }])
    }
    dispatch(handleChangesHappend(true)) 

  }

  function handleRemoveRow(id) {
    if (rows1.length > 1) {
      // var modifiedRows = [...rows1]
      // modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows1(rows1.filter(row => row.id !== id))
    }
    dispatch(handleChangesHappend(true))
  }

  const submitHandler = () => {
    const KeyTakeaways = {
      keyTakeaways: {
        contents: KeyTakeawaysManager(),
      },
    }
    setKeyTakeawaysUpdate({
      ...getReport,
      keyTakeaways: {
        contents: KeyTakeawaysManager(),
      },
    })
    setIsDataAdd(true)
    dispatch(createReportTemplate(KeyTakeaways))
    dispatch(handleChangesHappend(false))
    // history.push("/")
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Scorecard Report Template</title>
        </MetaTags>
        <Container fluid={true}>
          {
            isGetReportData ?
              <div style={
                {
                  width: "100%",
                  textAlign: "center",
                  height: "400px"
                }
              } >
                <Spinner
                  style={{
                    width: "60px", height: "60px",
                    margin: "calc(200px - 30px)",

                  }}
                  role="grid"
                  type="border"
                  color="primary"
                />
              </div>
              :
              <Row>
                <Col xl="12" style={{ margin: "auto" }}>
                  <Card>
                    <CardBody>
                      {createReport.status === 200 && (
                        <SweetAlert
                          // title="Tactic Updated successfully"
                          success
                          confirmBtnBsStyle="primary"
                          style={{ height: "255px", width: "368px" }}
                          onConfirm={() => {
                            dispatch(UpdateGetReportTemplate(KeyTakeawaysUpdate))
                            setIsDataAdd(false)
                            // setsuccess_msg(false)
                            dispatch(clearReportTemplate())
                            // history.push("/")
                          }}
                        >
                          Data saved successfully.
                        </SweetAlert>
                      )}
                      {createReport.status === 403 && (
                        <SweetAlert
                          // title="Tactic Updated successfully"
                          error
                          confirmBtnBsStyle="primary"
                          style={{ height: "255px", width: "368px" }}
                          onConfirm={() => {
                            setIsDataAdd(false)
                            // setsuccess_msg(false)
                            dispatch(clearReportTemplate())
                            // history.push("/")
                          }}
                        >
                          Failed to saved data.
                        </SweetAlert>
                      )}
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          submitHandler()
                        }}
                      >
                        <Row>
                          <h4
                            className="card-title"
                          // style={{ color: "Background" }}
                          >
                            Key Takeaways
                          </h4>
                        </Row>
                        <br></br>
                        <Row>
                          <Col md="12">
                            {/* repeater */}
                            <div data-repeater-list="outer-group" className="outer">
                              <div data-repeater-item className="outer">
                                <div className="inner-repeater mb-4">
                                  <table
                                    style={{ width: "100%" }}
                                    className="Pointer"
                                  >
                                    <tbody>
                                      {rows1.map((formRow, key) => (
                                        <tr key={key}>
                                          <td>
                                            <Row className="mb-2">
                                              <Label>Pointer {++key} </Label>

                                              <Col md="11" style={{cursor:'text'}}>
                                                <CKEditor
                                                  //  placeholder = {`key obervation - ${++key}`}
                                                  id= {formRow.id}
                                                  editor={ClassicEditor}
                                                  data={
                                                    formRow.content

                                                    // getReport.KeyTakeaways !==
                                                    //   undefined
                                                    //   ? getReport.KeyTakeaways
                                                    //     .contents[--key] || ""
                                                    //   : ""
                                                  }
                                                  config={{
                                                    removePlugins: [
                                                      "EasyImage",
                                                      "ImageUpload",
                                                      "MediaEmbed",
                                                      "Essentials",
                                                      "Table",
                                                      "Table Toolbar",
                                                      "CkFinderUploadAdapter",
                                                      "CKFinder",
                                                      "Autoformat",
                                                      "Link",
                                                      "BlockQuote",
                                                    ],
                                                  }}
                                                  onReady={editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                  }}
                                                  onChange={(e, data) => {
                                                    KeyTakeawaysManager()
                                                    setrows1(e =>
                                                      e.map(rw => {
                                                        if (rw.id === formRow.id)
                                                          return {
                                                            id: formRow.id,
                                                            content: data.getData() ? data.getData() : formRow.content,
                                                          }
                                                        else return rw
                                                      })
                                                    )
                                                    dispatch(handleChangesHappend(true))
                                                  }}
                                                />
                                              </Col>
                                              <Col md="1">
                                                <FormGroup>
                                                  {rows1.length > 1 ? (
                                                    <i
                                                      className="mdi mdi-trash-can d-block font-size-24"
                                                      style={{
                                                        color: "#FF6060",
                                                      }}
                                                      onClick={e => {
                                                        handleRemoveRow(formRow.id)
                                                        dispatch(handleChangesHappend(true))
                                                      }}
                                                    ></i>
                                                  ) : (
                                                    true
                                                  )}
                                                </FormGroup>
                                                {/* <Button
                                              color=""
                                              className="btn-block inner"
                                              id="unknown-btn"
                                              style={{ width: "100%", color:"blue"  }}
                                              disabled={
                                                formRow.id > 1 ? false : true
                                              }
                                              onClick={e => {
                                                handleRemoveRow(formRow.id)
                                              }}
                                            >
                                              <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                                            </Button> */}
                                              </Col>
                                            </Row>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                  <Button
                                    disabled={rows1.length < 10 ? false : true}
                                    onClick={() => {
                                      handleAddRowNested()
                                    }}
                                    color="primary"
                                    className="mt-1"
                                  >
                                    Add New
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <br></br>
                        <Row>
                          <Col md="10"></Col>
                          <Col md="2">
                            <Button
                              color="primary"
                              type="submit"
                              style={{ width: "100%" }}
                              disabled={isDataAdd ? true : false}
                              block
                            >
                              {isDataAdd ? "" : "Save"}
                              {isDataAdd ? (
                                <Spinner type="border" size="sm" />
                              ) : null}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
        </Container>
      </div>
    </>
  )
}

export default KeyTakeaways
