//Account Owner - Buy Credit

export const GET_PRODUCT = "GET_PRODUCT"
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS"
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL"
export const CLEAR_PRODUCT = "CLEAR_PRODUCT"

//Account Owner - CheckoutRequestData

export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST"
export const CHECKOUT_REQUEST_SUCCESS = "CHECKOUT_REQUEST_SUCCESS"
export const CHECKOUT_REQUEST_FAIL = "CHECKOUT_REQUEST_FAIL"
export const CLEAR_CHECKOUT_DATA = "CLEAR_CHECKOUT_DATA"