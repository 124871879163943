import React from "react"
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const Privacy = () => {
    return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Mambo - Privacy Policy</title>
            </MetaTags>
            <div className="container-fluid">
              
              <Row>
              <Col style= {{margin: "auto"}}>

                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <h2>Mambo Media Inc.</h2>
                      {/* <h5>Privacy Notice</h5> */}
                    </div>
                    <div id="policy" width="640" height="480"
                      data-policy-key="YjI5TGJEWm1jbUl5WlVaVVdVRTlQUT09" data-extra="table-style=accordion" > 
                    </div>
                  </CardBody>
                </Card>
                    
              </Col>

              </Row>


              </div>
              </div>
              </React.Fragment>
    )

}
export default Privacy