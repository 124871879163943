import { color, fontSize } from "@mui/system"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { REPORT, REPORT_PAGE } from "../config/style.config"
import "./companyInformation.page.css"

import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"

import { getScorecardReport } from "store/actions"
import { useParams } from "react-router-dom"

const CompanyInformation = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const { scorecardReport_data } = useSelector(state => ({
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
  }))

  useEffect(() => {
    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
    // dispatch(getScorecardReport(params.scorecardId))
  }, [dispatch])

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Mambo -Report</title>
        </MetaTags>
        <Container
          fluid={true}
          style={{
            transform: "scale(.8) translate(-130px, -132px) ",
            width: "1400px",
          }}
        >
          <Row>
            <Col className="col-12 m-auto ">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <div style={{ ...REPORT_PAGE }}>
                        <Row className="g-0">
                          <Col md="12">
                            <div
                              style={{
                                width: "100%",
                                height: REPORT_PAGE.height,
                                // border: "1px solid grey"
                              }}
                            >
                              <Row>
                                <Col md="12">
                                  <div
                                    style={{
                                      margin: "auto",
                                      width: "100%",
                                      height: "100px",
                                      // padding: "30px 0 25px 8px",
                                      padding: "30px 40px 0 40px",
                                      position: "relative",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    <h1
                                      style={{
                                        fontSize: REPORT.FONT_SIZE.XLARGE,
                                        fontFamily: REPORT.FONT_FACE.HEADING,
                                        fontWeight: 600,
                                        color: "#0D0F11",
                                        letterSpacing: "2px",
                                        paddingBottom: "10px",
                                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                                      }}
                                    >
                                      COMPANY INFORMATION
                                    </h1>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="1" />
                                <Col md="10">
                                  <div
                                    style={{
                                      fontSize: REPORT.FONT_SIZE.SMALL,
                                    }}
                                  >
                                    <Row className="mt-4">
                                      <Col md={6}>
                                        <i
                                          className="bx bx-chevron-up-square"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          COMPANY NAME
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.companyName ||
                                            "N.A."}
                                        </p>
                                      </Col>
                                      <Col md={6}>
                                        <i
                                          className="bx bx-user"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          CONTACT NAME
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.contactName ||
                                            "N.A."}
                                        </p>
                                      </Col>
                                    </Row>

                                    <Row className="mt-4">
                                      <Col md={6}>
                                        <i
                                          className="bx bx-phone-call"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          CONTACT NUMBER
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {" "}
                                          +
                                          {scorecardReport_data.countryCode ||
                                            "N.A."}{" "}
                                          &nbsp;
                                          {scorecardReport_data.phone || "N.A."}
                                        </p>
                                      </Col>
                                      <Col md={6}>
                                        <i
                                          className="bx bx-envelope"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          EMAIL
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.email || "N.A."}
                                        </p>
                                      </Col>
                                    </Row>

                                    <Row className="mt-4">
                                      <Col md={6}>
                                        <i
                                          className="bx bx-map"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          COMPANY ADDRESS
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.companyAddress1 ||
                                            "N.A."}
                                        </p>
                                      </Col>
                                      <Col md={6}>
                                        <i
                                          className="bx bx-world"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          COUNTRY
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.country ||
                                            "N.A."}
                                        </p>
                                      </Col>
                                    </Row>

                                    <Row className="mt-4">
                                      <Col md={6}>
                                        <i
                                          className="bx bx-world"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>{" "}
                                        <label className="company-information-labels">
                                          STATE
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.state || "N.A."}
                                        </p>
                                      </Col>
                                      <Col md={6}>
                                        <i
                                          className="bx bx-dollar-circle"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          ANNUAL REVENUE
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.annualRevenue ||
                                            "N.A."}
                                        </p>
                                      </Col>
                                    </Row>

                                    <Row className="mt-4">
                                      <Col md={6}>
                                        <i
                                          className="bx bx-group"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          NUM OF EMPLOYEE
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.numberOfEmployee ||
                                            "N.A."}
                                        </p>
                                      </Col>
                                      <Col md={6}>
                                        <i
                                          className="bx bx-vector"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          INDUSTRY
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.industry ||
                                            "N.A."}
                                        </p>
                                      </Col>
                                    </Row>

                                    <Row className="mt-4">
                                      <Col md={6}>
                                        <i
                                          className="dripicons-web"
                                          style={{
                                            fontSize: "14px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          WEBSITE URL
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.websiteUrl ||
                                            "N.A."}
                                        </p>
                                      </Col>
                                      <Col md={6}>
                                        <i
                                          className="bx bxs-key"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          OWNERSHIP
                                        </label>
                                        <br></br>
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.creatorName ||
                                            "N.A."}
                                        </p>
                                      </Col>
                                    </Row>

                                    <Row className="mt-4">
                                      <Col md={12}>
                                        <i
                                          className="bx bx-align-left"
                                          style={{
                                            fontSize: "16px",
                                            color: "#0F838C",
                                            marginRight: "8px",
                                          }}
                                        ></i>
                                        <label className="company-information-labels">
                                          CLIENT ATTRIBUTES
                                        </label>
                                      </Col>
                                      <Col md="6">
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.clientAttribute1 ||
                                            "N.A."}
                                        </p>
                                      </Col>
                                      <Col md="6">
                                        <p className="ms-4 company-information-value">
                                          {scorecardReport_data.clientAttribute2 ||
                                            "N.A."}
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col md="1" />
                              </Row>
                            </div>
                          </Col>
                          <Col
                            md="12"
                            style={{
                              // border: '1px solid black'
                              position: "absolute",
                              bottom: 0,
                              zIndex: 100,
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                fontSize: REPORT.FONT_SIZE.MEDIUM,
                                padding: "5px 5px 5px 40px",
                                backgroundColor: REPORT.COLOR.FOOTER,
                                fontFamily: REPORT.FONT_FACE.HEADING,
                                letterSpacing: "2px",
                                opacity: 0.9,
                                fontWeight: 600,
                                color: "white",
                              }}
                            >
                              COMPANY INFORMATION
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default CompanyInformation
