import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Select from "react-select";
import { Link, withRouter, useParams, useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { addContentEditor, clearContentEditor, getContentEditorData, updateContentEditorState } from "../../../store/actions"

import SweetAlert from "react-bootstrap-sweetalert";


import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
  UncontrolledAlert

} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik, yupToFormErrors } from "formik";
import { SUPER_ADMIN } from "config/AlertMessage.config";
// import { contentEditorLimit } from "../../../config/UserCreationLimit_config";
import { contentEditorLimit } from "config/UserCreationLimit_config";

//Import Breadcrumb
//import Breadcrumbs from "../../../components/Common/Breadcrumb";

//action




const CreateContentEditor = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [success_msg, setsuccess_msg] = useState(false)

  const [isContentEditorAdd, setIsContentEditorAdd] = useState(false);
  const [isContentEditorUpdate, setIsContentEditorUpdate] = useState(false)
  const [limitExceed, setLimitExceed] = useState(false)
  const [HandleContentEditor, setHandleContentEditor] = useState({})

  const { addContentEditorUser, editorData } = useSelector(state => ({
    addContentEditorUser: state.createContentEditorReducer.addContentEditorUser,
    editorData: state.EditorListReducer.editorData
  }))

  const ContentCreatorsLimit = () => {
    return editorData.filter(editor => editor.isDisabled === false).length
  }

  useEffect(() => {
    !editorData[0] && dispatch(getContentEditorData())
  }, [dispatch])
  // Form validation 
  //console.log("Editor-data", ContentCreatorsLimit())




  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,



    initialValues: {

      username: '',
      password: '',
      // password1: '',
      email: '',


      isdisable: false
    },
    validationSchema: Yup.object().shape({

      username: Yup.string().required(
        " Please enter name"
      ),
      // password: Yup.string().min(6, "Password must be at least 6 characters").required(
      //   "Please enter password"
      // ),

      email: Yup.string()
        .email("Please enter a valid email ")
        .max(255)
        .required("Please enter email"),
    }),




    onSubmit: (values) => {

      // console.log("Length ... ", ContentCreatorsLimit())

      if (ContentCreatorsLimit() < contentEditorLimit) {
        var date = new Date()
        var user = JSON.parse(localStorage.getItem("authUser"))
        //    console.log("Values", { 
        //     username: values.username,
        //     contactEmail:values.email,
        //     password:values.password,
        //     phone:values.phone,
        //     role:values.role,
        //     createdOn: date,
        //     createdBy: created_by,
        //     isDisabled:values.isdisable

        //   }
        // );

        const addContentEditorData = {
          // id: values.id,
          contactName: values.username,
          contactEmail: values.email,
          // password:values.password,
          createdOn: date,
          createdBy: user.email,
          creatorId: user.uid,
          isDisabled: values.isdisable

        }
        setIsContentEditorAdd(true)
        setHandleContentEditor(addContentEditorData)
        dispatch(addContentEditor(addContentEditorData))
        // console.log("Content editor created successfully...")
      }
      else {
        setLimitExceed(true)
      }

    }
  });

  // if(addContentEditorUser[0] && addContentEditorUser[0].status === 200){
  //   setTimeout(() => {

  //     validation.values.username = ""
  //     validation.values.email = ""
  //     validation.values.password = ""




  //       dispatch(clearContentEditor()) 
  //       setIsContentEditorAdd(false)
  //       history.push("/dashboard-content-editor-list")
  //    }, 2000)
  // }

  // if(addContentEditorUser[0] && addContentEditorUser[0].status === 403){
  //   setTimeout(() =>{
  //     dispatch(clearContentEditor()) 
  //     setIsContentEditorAdd(false)

  //   },2000)

  // }

  // console.log("Content Editor",addContentEditorUser)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Create Content Editor </title>
        </MetaTags>

        <Container fluid={true}>
          {
            limitExceed && <UncontrolledAlert color="danger" onClick={() => setLimitExceed(false)}>{
              <strong>{SUPER_ADMIN.CONTENT_EDITOR_LIMIT_EXCEED.message}</strong>
            }
            </UncontrolledAlert>
          }

          {
            addContentEditorUser[0] && addContentEditorUser[0].status === 200 &&
            <SweetAlert
              title=""
              success
              confirmBtnBsStyle='primary'
              style={{
                height: '255px',
                width: '368px'
              }}
              onConfirm={() => {
                if (editorData[0]) {
                  dispatch(updateContentEditorState([
                    ...editorData,
                    {
                      ...HandleContentEditor,
                      id: addContentEditorUser[0].id
                    }
                  ]))
                } else {
                  dispatch(updateContentEditorState([
                    {
                      ...HandleContentEditor,
                      id: addContentEditorUser[0].id
                    }
                  ]))
                }
                setIsContentEditorAdd(false)
                dispatch(clearContentEditor())
                history.push("/dashboard-content-editor-list")
              }}
            >{SUPER_ADMIN.CREATE_CONTENT_EDITOR.SUCCESS}
            </SweetAlert>
          }

          {
            addContentEditorUser[0] && addContentEditorUser[0].status === 409 &&
            <SweetAlert
              style={{
                height: '255px',
                width: '368px'
              }}

              warning

              onConfirm={() => {
                setIsContentEditorAdd(false)
                dispatch(clearContentEditor())
              }}
            >
              {SUPER_ADMIN.CREATE_CONTENT_EDITOR.EXISTING_MAIL}
            </SweetAlert>

          }

          {
            addContentEditorUser[0] && addContentEditorUser[0].status === 403 &&
            <SweetAlert

              error
              confirmBtnBsStyle="primary"
              style={{
                height: '255px',
                width: '368px'
              }}
              onConfirm={() => {
                setsuccess_msg(false)
                setIsContentEditorAdd(false)
                dispatch(clearContentEditor())
                // history.push("/create-content-editor")
                // setIsContentEditorUpdate(true)
              }}
            >
              {SUPER_ADMIN.CREATE_CONTENT_EDITOR.FAILED}
            </SweetAlert>
          }

          <Row>

            <Col xl="12" style={{ margin: "auto" }}>
              <Card>


                <CardBody>

                  {/* {addContentEditorUser[0] && addContentEditorUser[0].status === 200  && 
              <Alert color = "success" role = "alert" className = "alert-error">
                Content Editor Added Successfully !
              </Alert>}
              {addContentEditorUser[0] && addContentEditorUser[0].status === 403 &&
                                    <Alert color="danger" role="alert" className='alert-error' >
                                        Email is already exist !
                                    </Alert>
                                    } */}


                  <Form className="needs-validation"
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>

                    <Row>

                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Name</Label>
                          <Input
                            name="username"
                            placeholder="enter name"
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username && validation.errors.username ? true : false
                            }
                          />
                          {validation.touched.username && validation.errors.username ? (
                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Email</Label>
                          <Input
                            name="email"
                            autoComplete="off"
                            placeholder="enter email"
                            type="email"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>


                    </Row>
                    {/* <Row>
                   
                    <Col md="6">
                      <FormGroup className="mb-3">
                      
                        <Label htmlFor="validationCustom04">Password</Label>
                        <Input
                          name="password"
                          autoComplete="off"
                          placeholder="enter password"
                          type="password"
                          className="form-control"
                          id="validationCustom04"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    </Row> */}



                    <Row >

                      <Col md="8"></Col>
                      <Col md="2">
                        <FormGroup >
                          <Button color="danger" onClick={(e) => {
                            validation.resetForm()
                          }}
                            block >
                            Cancel
                          </Button>
                        </FormGroup>
                      </Col>
                      <Col md="2">

                        <FormGroup>
                          <Button color="primary" type="submit"
                            disabled={isContentEditorAdd}
                            block >
                            {isContentEditorAdd ? "" : "Create"}
                            {isContentEditorAdd ?
                              <Spinner
                                // style={{ width: "0.8rem", height: "0.8rem" }}
                                type="border"
                                size="sm"
                              />
                              :
                              null
                            }
                          </Button>
                        </FormGroup>
                      </Col>

                    </Row>

                  </Form>
                </CardBody>
              </Card>
            </Col>


          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
}

export default CreateContentEditor;














