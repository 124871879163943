import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import { Icon } from "react-icons-kit"
import { eyeOff } from "react-icons-kit/feather/eyeOff"
import { eye } from "react-icons-kit/feather/eye"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  UncontrolledAlert,
  Spinner,
  InputGroup
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useHistory, Redirect } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { clearError, loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
//import logo from "assets/images/logo.png"
import Logo from "assets/images/MamboScorecardLogo/Logo.png"
//Import config
import { facebook, google } from "../../config"

const Login = props => {
  const dispatch = useDispatch()
  const [isLoginClick, setisLoginClick] = useState(false)

  const history = useHistory()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter email"),
      password: Yup.string().required("Please enter password"),
    }),
    onSubmit: values => {
      setisLoginClick(true)
      dispatch(loginUser(values, props.history))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  // useEffect(() => {
  //   return () => dispatch(clearError())
  // },[dispatch])

  useEffect(() => {
    if(error){
      setisLoginClick(false)
    }
  },[error])

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history))
  }

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  // if(error){
  //   setisLoginClick(false)
  // }

  // eyeOn and eyeOff Functionality   
  const [type, setType] = useState("password")
  const [icon, setIcon] = useState(eyeOff)
  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye)
      setType("text")
    } else {
      setIcon(eyeOff)
      setType("password")
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Mambo - Login</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block" >
        <Link to="/" className="text-dark" srt>
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-3 pt-sm-5" >
        <Container
       
        >
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card
                className="overflow-hidden"
                style={{
                  boxShadow: "0px 0px 20px 0px rgba(61, 87, 163, .1)",
                  
                 
                }}
              >
                <div className="bg-primary bg-soft">
                  <Row
                    style={{
                      backgroundColor: "#fff",
                      borderBottom: "2px solid rgba(61, 87, 163, .16)",
                    }}
                  >
                    <Col
                      xs={4}
                      style={{
                        backgroundColor: "rgba(61, 87, 163, .16)",
                        borderRadius: "0px 50px 50px 0px",
                      }}
                    >
                      <div className="text-primary p-4">
                        <img
                          src={Logo}
                          alt=""
                          // className="rounded-circle"
                          height="50"
                        />
                        {/* <h5 className="text-primary">Welcome !</h5>
                        <p>Sign in to continue to Mambo.</p> */}
                      </div>
                    </Col>
                    <Col xs={8} className="col-5 align-self-end">
                      <h2
                        className="text"
                        style={{ marginLeft: "20px" , color:'#05727B' }}
                      >
                        Welcome !
                      </h2>
                      <p
                        style={{
                          marginLeft: "20px",
                          fontSize: "12px",
                          marginTop: "-4px",
                        }}
                      >
                        please enter your email and password
                      </p>
                      {/* <img src={profile} alt="" className="img-fluid" /> */}
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        {/* <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span> */}
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error && ( 
                        <UncontrolledAlert color="danger"  onClick={() => {
                          dispatch(clearError()) 
                          setisLoginClick(false)
                      }} 
                      
                      >
                          
                          {
                          error.includes("administrator")
                            ? "The user account has been disabled by an administrator."
                            : "You have entered an invalid email or password"
                      
                            }

                        </UncontrolledAlert>
                      )}

                      <div className="mb-3" style={{marginTop:'-15px'}}>
                        <Label
                          className="form-label"
                          style={{ color: "#05727B" }}
                        >
                          Email
                        </Label>
                        <Input
                          autoComplete="off"
                          name="email"
                          className="form-control"
                          placeholder="enter email"
                          type="email"
                          onFocus={() => dispatch(clearError()) }
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          disabled= {isLoginClick ? true : false}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label
                          className="form-label"
                          style={{ color: "#05727B" }}
                        >
                          Password
                        </Label>
                        <InputGroup>
                          <Input
                            name="password"
                            autoComplete="none"
                            value={validation.values.password || ""}
                            type={type}
                            placeholder="enter password"
                            onChange={validation.handleChange}
                            onFocus={() => dispatch(clearError()) }
                            onBlur={validation.handleBlur}
                            disabled= {isLoginClick ? true : false}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <div className="input-group-text">
                            <span onClick={handleToggle}>
                              <Icon icon={icon}></Icon>
                            </span>
                          </div>
                        </InputGroup>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        {/* <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label> */}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-block"
                          style = {{background:'#05727B', color:'white'}}
                          type="submit"
                          // onClick={() => setisLoginClick(true)}
                        >
                          {isLoginClick ? "" : "Log In"}
                          {isLoginClick ?
                            <Spinner
                              // style={{ width: "0.8rem", height: "0.8rem" }}
                              type="border"
                              size="sm"
                            />
                            : null
                          }
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        {/* <h5 className="font-size-14 mb-3">Sign in with</h5> */}

                        <ul className="list-inline">
                          {/* <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li> */}
                          {/*<li className="list-inline-item">*/}
                          {/*  <TwitterLogin*/}
                          {/*    loginUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                          {/*    }*/}
                          {/*    onSuccess={this.twitterResponse}*/}
                          {/*    onFailure={this.onFailure}*/}
                          {/*    requestTokenUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                          {/*    }*/}
                          {/*    showIcon={false}*/}
                          {/*    tag={"div"}*/}
                          {/*  >*/}
                          {/*    <a*/}
                          {/*      href=""*/}
                          {/*      className="social-list-item bg-info text-white border-info"*/}
                          {/*    >*/}
                          {/*      <i className="mdi mdi-twitter"/>*/}
                          {/*    </a>*/}
                          {/*  </TwitterLogin>*/}
                          {/*</li>*/}
                          {/* <li className="list-inline-item">
                          {/*<li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="/dashboard"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => { }}
                            />
                          </li> */}
                        </ul>
                      </div>

                      <div className="mt-4 text-center">

                        <Link to="/forgot-password" className="text-muted" onClick={() => dispatch(clearError())}>
                          <i className="mdi mdi-lock me-1"  />
                          Create or Change Password
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 text-center">
                <p>
                  © {new Date().getFullYear()} MAMBO
                 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
