import { CLEAR_ACCOUNT_OWNER_DETAILS } from "store/SuperAdminTable/actionTypes"
import {

  GET_ACCOUNT_HOLDER_USERS,
  GET_ACCOUNT_HOLDER_USERS_FAIL,
  GET_ACCOUNT_HOLDER_USERS_SUCCESS,
  GET_ACCOUNT_HOLDER_USER_DETAILS,
  GET_ACCOUNT_HOLDER_USER_DETAILS_SUCCESS,
  GET_ACCOUNT_HOLDER_USER_DETAILS_FAIL,
  UPDATE_ACCOUNT_HOLDER_USERS_STATE,
  CLEAR_ACCOUNT_HOLDER_USER_DETAILS,
  UPDATE_ACCOUNT_HOLDER_USERS_DETAILS_STATE,
  CLEAR_ACCOUNT_HOLDER_USERS_STATE,
  // CHANGE_ACCOUNT_HOLDER_USER_LIST
} from "./actionTypes"


export const getAccountHolderUsers = accountId => ({
  type: GET_ACCOUNT_HOLDER_USERS,
  accountId,
})

export const getAccountHolderUsersSuccess = users => ({
  type: GET_ACCOUNT_HOLDER_USERS_SUCCESS,
  payload: users,
})

export const getAccountHolderUsersFail = error => ({
  type: GET_ACCOUNT_HOLDER_USERS_FAIL,
  payload: error,
})

export const updateAccountHolderUsersState = DATA => ({
  type: UPDATE_ACCOUNT_HOLDER_USERS_STATE,
  payload: DATA,
})

export const clearAccountHolderUsersState = () => ({
  type: CLEAR_ACCOUNT_HOLDER_USERS_STATE,
})

export const getAccountHolderUserDetails = userId => ({
  type: GET_ACCOUNT_HOLDER_USER_DETAILS,
  userId,
})

export const getAccountHolderUserDetailsSuccess = user => ({
  type: GET_ACCOUNT_HOLDER_USER_DETAILS_SUCCESS,
  payload: user,
})

export const getAccountHolderUserDetailsFail = error => ({
  type: GET_ACCOUNT_HOLDER_USER_DETAILS_FAIL,
  payload: error,
})

export const ClearAccountHolderUserDetails = () => {
  return {
    type: CLEAR_ACCOUNT_HOLDER_USER_DETAILS
  }
}

export const UpdateAccountUsersDetailsState = (data) => {
  return {
    type: UPDATE_ACCOUNT_HOLDER_USERS_DETAILS_STATE,
    payload: data
  }
}