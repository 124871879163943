import { call, put, takeEvery } from "redux-saga/effects"

import {
    AddUserSuccess,
    AddUserFail
} from "./actions"

import {
    ADD_NEW_USER
} from "./actionTypes"

import {
    addAccountUser
} from "../../helpers/fakebackend_helper"


function* onAddNewUser( { payload: data } ) {

    try{
        const response = yield call(addAccountUser, data)
        yield put(AddUserSuccess(response))
    }catch(error) {
        yield put(AddUserFail(error))
    }

}

function* AddNewUserSaga(){
    yield takeEvery(ADD_NEW_USER, onAddNewUser)
}

export default AddNewUserSaga