import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
    paymentSuccessDetailSuccess,
    paymentSuccessDetailFail
} from "./actions"

//Include Both Helper File with needed methods
import { PaymentSuccess } from "helpers/fakebackend_helper"
import { PAYMENT_SUCCESS } from "./actionTypes"

function* fetchPaymentSuccess({ id }) {
    try {
        const response = yield call(PaymentSuccess, id)
        yield put(paymentSuccessDetailSuccess(response))
    } catch (error) {
        yield put(paymentSuccessDetailFail(error))
    }
}




function* paymentSuccessSaga() {
    yield takeEvery(PAYMENT_SUCCESS, fetchPaymentSuccess)

}


export default paymentSuccessSaga
