import { color, fontSize, height } from "@mui/system"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Grid } from "@mui/material"
import img from "../../../../../assets/images/reportScorcard/channelcontimg.PNG"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import "../config_v0_1/style.config.css"
import { REPORT, REPORT_PAGE } from "../config_v0_1/style.config"

// import {mainDivStyle} from './pageFifth.cssConfig'

import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
} from "reactstrap"
import { Opacity } from "@mui/icons-material"
import Footer from "components/VerticalLayout/Footer"
import { getScorecardReport, getChannelDetails } from "../../../../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams, useHistory } from "react-router-dom"
import ReactHtmlParser from "react-html-parser"

const ChannelFirst = (props) => {
  const PROPS = props;

  const {
    ChannelData
  } = PROPS;

  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const [image, setImage] = useState({ preview: "", raw: "" })
  const [executiveData, setExecutiveData] = useState([])

  const [test, setTest] = useState(null)

  const { scorecardReport_data, channelDetails } = useSelector(state => ({
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
    channelDetails: state.ChannelDetailsReducer.channelDetails,

  }))

  // console.log(Object.keys(ChannelData).includes("bgImage"))

  // useEffect(() => {
  //   dispatch(getScorecardReport("UufUpT9yB9k5x7nMSwh7"))
  // }, [dispatch])

  // useEffect(() => {
  //   dispatch(getChannelDetails(ChannelData.channelId))
  //   if (channelDetails.id) {
  //     setChannelData([channelDetails])
  //   }

  // }, [dispatch  ])
  //   const ckRef = useRef()

  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
      var img = new Image()
    }
  }

  const listToPara = e => {
    var temp = ""
    executiveData.forEach(data => {
      temp += data
    })

    setTest(temp)
  }
  return (
    <>
      <Col xs="12" className="print-content p-0 m-0 ">
        <div style={{ ...REPORT_PAGE }}>
          <Row className="p-0 m-0 g-0" >
            <Col xs="12">
              <div
                style={{
                  width: "100%",
                  height: "528px",
                  // border: "1px solid grey",
                }}
              >
                <img src={Object.keys(ChannelData).includes("Image_URL") ? ChannelData.Image_URL : ""} style={{
                  width: '100%',
                  height: '528px'
                }} />
              </div>
            </Col>
          </Row>
          <Row className="p-0 m-0 g-0" >
            <Col xs="6">
              <h2 style={{
                fontWeight: 700,
                // maxHeight:'65px',
                maxHeight:'60px',
                width:'95%',
                color: '#0D0F11',
                margin: '10px 0 5px 15px',
                overflow:'hidden',
                // border:'1px solid black'
              }}><span>{ChannelData && ChannelData.channelDescriptionTitle}</span>
              </h2>

              <div 
                style={{
                  width: "100%",
                  height: "410px",
                  overflow: "hidden",
                  padding: "0 6px 6px 6px",
                  margin: '5px 0 2px 0',
                  // border: "1px solid grey",
                }}
                className="--channel-first-page"
              >
                <div
                  style={{
                    height: "400px",
                    // width: "96%",
                    fontSize: REPORT.FONT_SIZE.MEDIUM,
                    fontFamily: REPORT.FONT_FACE.HEADING,
                    margin: 0,
                    paddingLeft:'7px',
                    overflow:'hidden',
                    listStyleType:'none',
                    color:'#0D0F11'
                    // border: "1px solid grey",
                  }}
                  className="SavedDescription"
                >
                  {ChannelData && ChannelData.channelDescription && <li>{
                    ReactHtmlParser(ChannelData.channelDescription)
                  }</li>} 
                 
                </div>
              </div>
            </Col>
            <Col xs="6">
              <div
                style={{
                  width: "100%",
                  height: "526px",
                  backgroundColor: REPORT.COLOR.PRIMARY_BG,
                  // border: '1px solid black',
                }}
              >
                <h1 style={{
                  fontSize: '1.42188rem',
                  fontWeight: 600,
                  color: '#0D0F11',
                  fontFamily:REPORT.FONT_FACE.PARAGRAPH,
                  fontStyle: 'italic',
                  lineHeight: 1.6,
                  padding: '125px 15px 0 10px',
                  textAlign: 'right'
                }}>
                  {`${ChannelData && ChannelData.channelQuote}`.toUpperCase()}
                </h1>
              </div>
            </Col>
          </Row>
          <Col
            xs="12"
            style={{
              // border: '1px solid black'
              position: "absolute",
              bottom: 0,
              zIndex: 100,
            }}
          >
            <div
              style={{
                width: "100%",
                fontSize: REPORT.FONT_SIZE.MEDIUM,
                padding: "5px 5px 5px 40px",
                backgroundColor: REPORT.COLOR.FOOTER,
                fontFamily:REPORT.FONT_FACE.HEADING,
                letterSpacing:'2px',
                opacity: 0.9,
                fontWeight: 600,
                color: "white",
              }}
            >
              {ChannelData && ChannelData.channelName.toUpperCase()}
            </div>
          </Col>
        </div>
      </Col>
    </>
  )
}

export default ChannelFirst
