import {
    ADD_NEW_CHANNEL,
    ADD_CHANNEL_SUCCESS,
    ADD_CHANNEL_FAIL,
    CLEAR_CHANNEL_STATE
} from "./actionTypes"


export const AddNewChannel = (data) =>{
return {
    type: ADD_NEW_CHANNEL,
    payload: data
}
}

export const AddChannelSuccess = (data) =>{
    return {
        type: ADD_CHANNEL_SUCCESS,
        payload: data
    }
}

export const AddChannelFail = (error) =>{
    return {
        type: ADD_CHANNEL_FAIL,
        payload: error
    }
}

export const ClearAddChannel = () => {
    return{
        type: CLEAR_CHANNEL_STATE,
    }
}