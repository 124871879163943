
export const ANNUAL_REVENUE = {
    data: ["Less than $1,000,000",
    "$1,000,000‐$10,000,000",
    "$10,000,000‐$20,000,000",
    "$20,000,000‐$50,000,000",
    "$50,000,000‐$100,000,000",
    "More than $100,000,000" ]
}

export const NUMBER_OF_EMPLOYEES = {
    data: ["Less than 10",
    "10‐25",
    "25‐50",
    "50‐100",
    "100‐1,000",
    "More than 1,000"]
}

export const INDUSTRY = {
    data: ["Accounting",
    "Administration & Office Support",
    "Arts & Media","Banking & Financial Services",
    "Call Centre & Customer Service",
    "Community Services & Development",
    "Construction",
    "Consulting & Strategy",
    "Design & Architecture",
    "Education & Training",
    "Engineering",
    "Farming, Animals & Conservation",
    "Government & Defense",
    "Healthcare & Medical",
    "Hospitality & Tourism",
    "Human Resources & Recruitment",
    "Information & Communication Technology",
    "Insurance & Investing",
    "Legal",
    "Manufacturing, Transport & Logistics",
    "Marketing, Advertising & Communications",
    "Mining, Resources & Energy",
    "Real Estate & Property",
    "Retail & Consumer Products",
    "Sales",
    "Science & Technology",
    "Sport & Recreation",
    "Trades & Services"]
}

export const OWNERSHIP = {
    data: ["Publicly Held",
    "Privately Held"]
}









