import React, { useEffect } from "react"
import "./ArrowChart.style.css"
import Arrow from "./assets/arrowRight.svg";

const ArrowChart = (props) => {
    const PROPS = props
    const { Length, Tactics, Id } = PROPS;
    // const DrawArrowChart = (n, data) => {

    const DrawArrowChart = (n) => {
        var div = 360 / n;
        var radius = 140;
        var parentdiv = document.getElementById(Id);
        var offsetToParentCenter = parseInt(parentdiv.offsetWidth / 2); //assumes parent is square
        var offsetToChildCenter = 20;
        var totalOffset = offsetToParentCenter - offsetToChildCenter;
        for (var i = 1; i <= n; ++i) {
            var childdiv = document.createElement('div');
            var childSpan = document.createElement('span');
            childdiv.className = 'div3';
            // childSpan.innerHTML = `<i class = "bx bx-right-arrow-alt"
            // style="font-size: 40px; color: red";
            // ></i>`
            childSpan.innerHTML = `<img src=${Arrow} />`
            childdiv.style.position = 'absolute';
            var y = Math.sin((div * i) * (Math.PI / 180)) * radius;
            var x = Math.cos((div * i) * (Math.PI / 180)) * radius;
            childdiv.style.top = (y + totalOffset).toString() + "px";
            childdiv.style.left = (x + totalOffset).toString() + "px";
            // console.log(`rotate("${(div * i).toString() + "deg"}");`)
            // childdiv.style.transform = `rotate("${(div * i).toString() + "deg"}");`
            childdiv.style.transform = "rotate(" + div * i + "deg)"
            childdiv.style.backgroundColor = "none"
            parentdiv.appendChild(childdiv);
            childdiv.appendChild(childSpan)
        }
    }

    useEffect(() => {
        if (Tactics) {
            document.querySelectorAll(".span").forEach(el => el.remove())
            DrawArrowChart(Length)
        }
    }, [Length, Tactics])
    // }, [])

    return (
        <div className="Bubble-container">
            <div id={Id} style={{
                position: "relative",
                width: "200px",
                height: "200px",
                backgroundColor: "transparent",
                borderRadius: "150px",
                margin: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "200px",
            }} >
                {/* {PROPS.children} */}
            </div>
        </div >
    );
}

export default ArrowChart;