import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom"

import { Row, Col, Card, CardBody, CardTitle, Spinner, Button } from "reactstrap"
import { paymentSuccessDetail, paymentSuccessDetailClear } from "store/actions";



const SuccessPayment = () => {
    const history = useHistory()
    const search = useLocation().search;
    const dispatch = useDispatch();
    const { paymentSuccessData } = useSelector(state => ({
        paymentSuccessData: state.PaymentSuccessReducer.paymentSuccessData,

    }));


    useEffect(() => {
        const sessionId = new URLSearchParams(search).get('session_id');
        dispatch(paymentSuccessDetail(sessionId))
    }, [dispatch])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Mambo</title>
                </MetaTags>
                <div className="container-fluid">

                    <Row>
                        <Col>
                            <Card>
                                <CardBody style={{ textAlign: "center" }}>

                                    {
                                        paymentSuccessData &&
                                            paymentSuccessData.message ?
                                            (
                                                <h3>Sessios expired!</h3>

                                            )
                                            :
                                            (
                                                <div>

                                                    <CardTitle>
                                                        <i className="fas fa-check-circle"
                                                            style={{

                                                                color: "#34c38f",
                                                                fontSize: "42px"
                                                            }
                                                            }
                                                        ></i>
                                                    </CardTitle>
                                                    <h3>Payment Successfull</h3>
                                                    <p>
                                                        Amount paid: $ {paymentSuccessData && paymentSuccessData.amount_total / 100}.00
                                                    </p>
                                                    <p
                                                        style={{
                                                            color: "#34c38f",

                                                        }}
                                                    >
                                                        Payment Status: {
                                                            paymentSuccessData ?
                                                                paymentSuccessData.payment_status.toUpperCase()
                                                                :
                                                                ""
                                                        }
                                                    </p>

                                                    <Button
                                                        color="success"
                                                        size="sm"
                                                        onClick={() => {
                                                            dispatch(paymentSuccessDetailClear())
                                                            history.push("/")
                                                        }}
                                                    >Dashboard</Button>
                                                </div>
                                            )
                                    }

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SuccessPayment
