import {
    GET_ACCOUNTUSER_DATA,
    GET_ACCOUNTUSER_DATA_SUCCESS,
    GET_ACCOUNTUSER_DATA_FAIL,
  } from "./actionTypes";
  
  export const getAccountUserData = () => ({
    type: GET_ACCOUNTUSER_DATA,
  });
  
  export const getAccountUserDataSuccess = data => ({
    type: GET_ACCOUNTUSER_DATA_SUCCESS,
    payload: data,
  });
  
  export const getAccountUserDataFail = error => ({
    type: GET_ACCOUNTUSER_DATA_FAIL,
    payload: error,
  });