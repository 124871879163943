
import {
  Row, Col, Card, CardBody, CardTitle, DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown, DropdownItem, Spinner, Container
} from "reactstrap"
import MetaTags from 'react-meta-tags'
import React, { useEffect, useState } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import { getContentEditorData } from "../../store/ListContentEditors/actions"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Link, useHistory, useParams } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { SUPER_ADMIN, NETWORK_ERROR } from "config/AlertMessage.config";
import NotFound from "components/Common/NotFound/NotFound.component";


const ContentEditorList = (props) => {
  const [isDataAvail, setIsDataAvail] = useState(true)
  const [paginationReset, setPaginationReset] = useState(false)

  const history = useHistory();

  const params = useParams();
  const dispatch = useDispatch();

  const { editorData } = useSelector(state => ({
    editorData: state.EditorListReducer.editorData,
  }));

  /*
  get data
  */

  // console.log("Editors data is here...", editorData)

  useEffect(() => {

    !editorData[0] && dispatch(getContentEditorData());
    // var search_bar = document.querySelector("#search-bar-0")
    // search_bar.setAttribute("autoComplete", "off")
  }, [dispatch, editorData]);


  useEffect(() => {
    if (editorData.status === 404) {
      setIsDataAvail(false)
    }
    if (editorData[0]) {
      var search_bar = document.querySelector("#search-bar-0")
      search_bar.setAttribute("autoComplete", "off")
      search_bar.addEventListener("focus", () => setPaginationReset(true))

    }
  }, [editorData])

  const MyIcon = (isActive) => {
    return (
      <div style={{
        width: "60%",
        textAlign: "center"
      }} >
        {
          isActive ?
            <i className="bx bxs-user-check" style={{ color: "green", fontSize: "22px" }} ></i>
            :
            <i className="mdi mdi-account-cancel" style={{ color: "red", fontSize: "22px" }} ></i>
        }
      </div>
    )
  }

  const columns = [
    {
      dataField: 'status',
      text: ''
    },
    {
      dataField: 'username',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true
    },

  ];


  const EditorData = !editorData[0] ? [] : editorData.map((user, i) => {
    return {
      "id": i,
      "status": MyIcon(!user.isDisabled),
      "username": user.contactName ? user.contactName : user.userName,
      "email": user.contactEmail,
      "actions": user.id,
      "button": <div style={{ width: "50px", textAlign: "center" }}><i className="bx bx-edit-alt" style={{ fontSize: 20, color: "#3D57A3" }}></i></div>,

    }
  })
  // const defaultSorted = [{
  //   dataField: 'username',
  //   order: 'asc'
  // }];

  const pageOptions = {
    // page:1,
    // sizePerPage: 10,
    onPageChange: () => setPaginationReset(false),
    // totalSize: EditorData.length, // replace later with size(customers),
    custom: true,
  }
  // useEffect(() => {
  //   var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)

  //   if (editorData[0]) {
  //     var search_bar = document.querySelector("#search-bar-0")
  //     search_bar.setAttribute("autoComplete", "off")
  //     clearTimeout(WaitForData)

  //   }

  // }, [editorData])
  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (EditorData).length }];


  // Select All Button operation

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/contenteditor/${row.actions}`)
    }
  }


  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Content Editors</title>
        </MetaTags>
        {/* <Row>
        <Col xl = "12" style={{margin: "auto"}} >
                <Container fluid={true}>
                    <h4 className="card-title" style={{justifyContent:"left"}}>Content Editors</h4>
                </Container>
                    </Col>
        </Row> */}
        <div className="container-fluid">
          {
            !editorData[0] ?
              <div style={
                {
                  width: "100%",
                  textAlign: "center",
                  height: "400px"
                }
              } >

                {
                  !isDataAvail ?
                    <>
                      {editorData.status === 404 ?
                        <NotFound
                          Message={SUPER_ADMIN.EMPTY_CONTENT_EDITORS}
                          Icon={() => (
                            <i className="bx bx-edit-alt"
                            ></i>
                          )}
                          Url="/create/content-editor"
                          IsButton
                        />
                        :
                        <NotFound Message={NETWORK_ERROR} Icon={() => (
                          <i className="bx bx-error"
                            style={{ borderBottom: "1px solid #000", fontSize: "40px" }}
                          ></i>
                        )}
                          IsButton
                          btnText="Reload"
                          Reload
                        />
                      }
                    </>
                    :
                    <Spinner
                      style={{
                        width: "60px", height: "60px",
                        margin: "calc(200px - 30px)",

                      }}
                      role="grid"
                      type="border"
                      color="primary"
                    />
                }
              </div>
              :
              <>



                <Row >
                  <Col className="col-12">
                    <Card>
                      <CardBody >

                        <PaginationProvider
                          pagination={
                            paginationReset ?
                              paginationFactory({
                                ...pageOptions,
                                page: 1,
                              }) :
                              paginationFactory(pageOptions)
                          }
                          keyField='id'
                          columns={columns}
                          data={EditorData}

                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField='id'
                              columns={columns}
                              data={EditorData}
                              search

                            >
                              {toolkitProps => (
                                <React.Fragment>

                                  <Row  >
                                    <Col xl="9"></Col>
                                    <Col xl="3">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                            onClear={() => setPaginationReset(false)}

                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive" >
                                        <BootstrapTable
                                          rowEvents={rowEvents}
                                          keyField={"id"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          // defaultSorted={defaultSorted}

                                          classes={
                                            "table align-middle table-nowrap table-hover"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />

                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )
                              }
                            </ToolkitProvider>
                          )
                          }</PaginationProvider>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ContentEditorList
