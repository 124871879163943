import {
  GET_SCORECARDS_LIST,
  GET_SCORECARDS_LIST_SUCCESS,
  GET_SCORECARDS_LIST_FAIL,
  CLEAR_SCORECARD_LIST,
  CREATE_SCORECARD,
  CREATE_SCORECARD_FAIL,
  CREATE_SCORECARD_SUCCESS,
  CREATE_SCORECARD_CLEAR,
  GET_SCORECARDS_LIST_FOR_ACCOUNT_USER,
  GET_SCORECARDS_LIST_SUCCESS_FOR_ACCOUNT_USER,
  GET_SCORECARDS_LIST_FAIL_FOR_ACCOUNT_USER,
  GET_SCORECARD_DETAIL,
  GET_SCORECARD_DETAIL_SUCCESS,
  GET_SCORECARD_DETAIL_FAIL,
  GET_SCORECARD_DETAIL_CLEAR,
  UPDATE_SCORECARD_DETAIL_CLEAR,
  UPDATE_SCORECARD_DETAIL,
  CHANGE_SCORECARD_DETAIL,
  UPDATE_SCORECARD_DETAIL_SUCCESS,
  UPDATE_SCORECARD_DETAIL_FAIL,

  GET_LOGINUSER_CLAIMS,
  IS_ACCOUNT_OWNER,
  SCORECARD_PATH,

  GET_THREE_SCORECARDS,
  GET_THREE_SCORECARDS_SUCCESS,
  GET_THREE_SCORECARDS_FAIL,
  CLEAR_GET_THREE_SCORECARDS

} from "./actionTypes";

export const getScorecardsList = (id) => ({
  type: GET_SCORECARDS_LIST,
  id
});

export const getScorecardsListSuccess = data => ({
  type: GET_SCORECARDS_LIST_SUCCESS,
  payload: data,
});

export const getScorecardsListFail = error => ({
  type: GET_SCORECARDS_LIST_FAIL,
  payload: error,
});

export const clearScorecardList = () => ({
  type:CLEAR_SCORECARD_LIST,
  
});



// To get only latest three scorecards

export const getListOfThreeScorecards = (id) => ({
  type: GET_THREE_SCORECARDS,
  id
});

export const getListOfThreeScorecardsSuccess = data => ({
  type: GET_THREE_SCORECARDS_SUCCESS,
  payload: data,
});

export const getListOfThreeScorecardsFail = error => ({
  type: GET_THREE_SCORECARDS_FAIL,
  payload: error,
});

export const clearGetListOfThreeScorecards = () => ({
  type:CLEAR_GET_THREE_SCORECARDS,
  
});



export const getScorecardsListForAccountUser = (id) => ({
  type: GET_SCORECARDS_LIST_FOR_ACCOUNT_USER,
  id
});

export const getScorecardsListSuccessForAccountUser = data => ({
  type: GET_SCORECARDS_LIST_SUCCESS_FOR_ACCOUNT_USER,
  payload: data,
});

export const getScorecardsListFailForAccountUser = error => ({
  type: GET_SCORECARDS_LIST_FAIL_FOR_ACCOUNT_USER,
  payload: error,
});

// Create scorecard
export const createScorecard = (data) => ({
  type: CREATE_SCORECARD,
  payload: data
});

export const createScorecardSuccess = data => ({
  type: CREATE_SCORECARD_SUCCESS,
  payload: data,
});

export const createScorecardFail = error => ({
  type: CREATE_SCORECARD_FAIL,
  payload: error,
});

export const createScorecardClear = () => ({
  type: CREATE_SCORECARD_CLEAR,
});

//Get scorecard details
export const getScorecardDetail = (id) => ({
  type: GET_SCORECARD_DETAIL,
  id
});

//get scorecard 
export const getScorecardDetailSuccess = data => ({
  type: GET_SCORECARD_DETAIL_SUCCESS,
  payload: data,
});

export const getScorecardDetailFail = error => ({
  type: GET_SCORECARD_DETAIL_FAIL,
  payload: error,
});

export const getScorecardDetailClear = () => ({
  type: GET_SCORECARD_DETAIL_CLEAR,
});

//update scorecard
export const updateScorecardDetail = (id, data) => ({
  type: UPDATE_SCORECARD_DETAIL,
  id,
  payload: data
});

export const updateScorecardDetailSuccess = data => ({
  type: UPDATE_SCORECARD_DETAIL_SUCCESS,
  payload: data,
});

export const updateScorecardDetailFail = error => ({
  type: UPDATE_SCORECARD_DETAIL_FAIL,
  payload: error,
});

export const updateScorecardDetailClear = () => ({
  type: UPDATE_SCORECARD_DETAIL_CLEAR,
});

export const changeScorecardDetail = (data) => ({
  type: CHANGE_SCORECARD_DETAIL,
  payload: data
})

export const IsAccountOwner = (claims) => ({
  type: IS_ACCOUNT_OWNER,
  payload: claims
})


// scorecard routing handler:
export const ScorecardRoutingHandler = (path) => {
  return {
    type: SCORECARD_PATH,
    payload: path,
  }
}