import {
  GET_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  CLEAR_PRODUCT,
  CHECKOUT_REQUEST,
  CHECKOUT_REQUEST_SUCCESS,
  CHECKOUT_REQUEST_FAIL,
  CLEAR_CHECKOUT_DATA,
} from "./actionTypes"

const initialState = {
  getProductList: [],
  checkoutData: "",
}

const GetProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        getProductList: action.payload,
      }

    case GET_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CLEAR_PRODUCT:
      return {
        ...state,
        getProductList: [],
      }

    case CHECKOUT_REQUEST_SUCCESS:
      return {
        ...state,
        checkoutData: action.payload,
      }

    case CHECKOUT_REQUEST_FAIL:
      return{
        ...state,
        error:action.payload
      }

    case CLEAR_CHECKOUT_DATA:
      return{
        ...state,
        checkoutData:""

      }

    default:
      return state
  }
}

export default GetProductReducer
