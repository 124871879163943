//This is only component connected for both {after creation of tactic Redirection of evaluation criteri} and {From sidebar edit tactic page}
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import Switch from "react-switch"

import TacticPreview from "../TacticPreviewPage/TacticPreview"
import { channelTacticsLimit } from "../../../config/UserCreationLimit_config"


import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  NavItem,
  NavLink,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
  TabContent,
  TabPane,
  Modal,
  UncontrolledAlert
} from "reactstrap"

import * as Yup from "yup"
import { useFormik } from "formik"

import classnames from "classnames"

import {
  AddTactic,
  getTacticInfo,
  updateTacticDetails,
  clearUpdateTacticDetails,
  getTacticInfoClear,
  handleUnsavedChanges,
  getChannelData,
  handleChangesHappend,
  updateTacticState,
  ClearUpdateTacticState,
  changeChannelData,
  
} from "../../../store/actions"
import { getTacticDetails } from "helpers/fakebackend_helper"
import { CONTENT_EDITOR } from "config/AlertMessage.config"
import { truncate } from "lodash"

const Update_tactic = () => {
  const params = useParams()

  const history = useHistory()

  ///table
  const [isTacticUpdate, setIsTacticUpdate] = useState(false)
  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1, 2, 3, 4, 5])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  const [Evaluation, setEvaluation] = useState("")
  const [Reccomendation, setReccomendation] = useState("")
  // const [Score,setScore] =useState("")

  const [Evaluation1, setEvaluation1] = useState("")
  const [Reccomendation1, setReccomendation1] = useState("")
  //  const [Score1,setScore1] =useState("")

  const [Evaluation2, setEvaluation2] = useState("")
  const [Reccomendation2, setReccomendation2] = useState("")
  //  const [Score2,setScore2] =useState("")

  const [Evaluation3, setEvaluation3] = useState("")
  const [Reccomendation3, setReccomendation3] = useState("")
  //  const [Score3,setScore3] =useState("")

  const [Evaluation4, setEvaluation4] = useState("")
  const [Reccomendation4, setReccomendation4] = useState("")
  //  const [Score4,setScore4] =useState("")

  //popup

  // bubble preview popup and toggle function
  const [modal_center, setmodal_center] = useState(false)
  const [preview, setPreview] = useState(false)
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  //popup

  const [success_msg, setsuccess_msg] = useState(false)
  const [step1_txt, setstep1_txt] = useState("")
  const [step2_txt, setstep2_txt] = useState("")
  const [step3_txt, setstep3_txt] = useState("")
  const current_ip = "219.91.239.22"
  // const [close_timer, setclose_timer] = useState(false);

  // Text Limit on Recommendation
  const [textareabadge, settextareabadge] = useState(false)
  const [textcount, settextcount] = useState(null)
  const [isTacticDisabled, setIsTacticDisabled] = useState(false)
  const [TacticUpdateState, setTacticUpdateState] = useState({})
  const [allowEnableTactic , setAllowEnableTactic] = useState(false)

  const [tacticsLimitExceed, settacticsLimitExceed] = useState(false)
  const [emptyEvaluationAlert , setEmaptyEvaluationAlert] = useState(false)

  function handleStep1Change(e) {
    setstep1_txt(e.target.value)
  }

  function handleStep2Change(e) {
    setstep2_txt(e.target.value)
  }

  function handleStep3Change(e) {
    setstep3_txt(e.target.value)
  }
  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 5) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          marginRight: "34px",
        }}
      >
        {" "}
        Disabled
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          marginLeft: "34px",
        }}
      >
        {" "}
        Enabled
      </div>
    )
  }
  // const [isUnmounted, setIsUnmounted] = useState(true)
  // const [channelId, setChannelId]= useState("")

  // console.log("You are here")
  <Col md="4">
    {
      <>
        <div
          style={{
            padding: "6px",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              marginRight: "8px",
            }}
          >
            {" "}
            Tactic Status:{" "}
          </span>
          <Switch
            uncheckedIcon={<Offsymbol />}
            checkedIcon={<OnSymbol />}
            className="me-2 mb-sm-8 mb-4"
            onColor="#3d57a3"
            width={100}
            onChange={e => {
              setIsTacticDisabled(p => !p)
              dispatch(handleChangesHappend(true))
            }}
            checked={isTacticDisabled}
          />
        </div>
      </>
    }
  </Col>

  const dispatch = useDispatch()
  const {
    GetTacticInfo,
    updateTactic,
    isDataEdited,
    isThereChanges,
    channelData,
  } = useSelector(state => ({
    GetTacticInfo: state.AddTacticReducer.GetTacticInfo,
    updateTactic: state.UpdateTacticDetailsReducer.updateTactic,
    isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    channelData: state.GetChannelData.channelData,
  }))
  // tactics:
  // const { tacticData } = useSelector(state => ({
  //   tacticData: state.AddTacticData.tacticData,
  // }))
  // const { addtacticData } = useSelector(state => ({
  //   addTacticData: state.AddTacticReducer.addTacticData,
  // }))

  // useEffect(() => {
  //  dispatch(getTacticInfo(params.channelId,params.tacticId))
  // }, [dispatch])

  // console.log("Get Tactic info...", GetTacticInfo)

  // console.log(TacticUpdateState)

  useEffect(() => {
    dispatch(getChannelData())
  }, [dispatch])

  useEffect(() => {
    setIsTacticDisabled(!GetTacticInfo.isDisabled)
  }, [GetTacticInfo])

  useEffect(() => {
    dispatch(getTacticInfo(params.channelId, params.tacticId))
  }, [dispatch, params.tacticId])

  useEffect(() => {
    return () => dispatch(getTacticInfoClear())
  }, [dispatch])

  const EnableTacticCount = () =>{
     var currentChannel = channelData.filter(ch => ch.channelId === params.channelId)
    //  console.log(currentChannel[0] && currentChannel[0].tactics.filter(tactic => tactic.isDisabled === false).length )

    var tacticCount =  currentChannel[0] && currentChannel[0].tactics.filter(tactic => tactic.isDisabled === false).length 
    return tacticCount

    
    // var currentChannel = channelData.filter(ch => ch.channelId === params.channelId)
    // console.log(currentChannel[0].tactics.filter(tactic => tactic.isDisabled === false).length)
  
    // if (currentChannel[0] && currentChannel[0].tactics.filter(tactic => tactic.isDisabled === false).length < channelTacticsLimit) {
    //   settacticsLimitExceed(false)
    // }
    // else{
    //   settacticsLimitExceed(true)
  
    // }

  }
    
  

 
   

  
// console.log(tacticsLimitExceed)
 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      TacticName: GetTacticInfo.tacticName ? GetTacticInfo.tacticName : "",
      // channelName: GetTacticInfo && GetTacticInfo.channelName || "",
      channelName: GetTacticInfo.channelName ? GetTacticInfo.channelName : "",
      Interrogation: GetTacticInfo.interrogation
        ? GetTacticInfo.interrogation
        : "",
      Evaluation: GetTacticInfo.early ? GetTacticInfo.early.evaluation : "",
      Reccomendation: GetTacticInfo.early
        ? GetTacticInfo.early.recommendation
        : "",
      Score: "0",
      Evaluation1: GetTacticInfo.beginner
        ? GetTacticInfo.beginner.evaluation
        : "",
      Reccomendation1: GetTacticInfo.beginner
        ? GetTacticInfo.beginner.recommendation
        : "",
      Score1: "25",
      Evaluation2: GetTacticInfo.intermediate
        ? GetTacticInfo.intermediate.evaluation
        : "",
      Reccomendation2: GetTacticInfo.intermediate
        ? GetTacticInfo.intermediate.recommendation
        : "",
      Score2: "50",
      Evaluation3: GetTacticInfo.advanced
        ? GetTacticInfo.advanced.evaluation
        : "",
      Reccomendation3: GetTacticInfo.advanced
        ? GetTacticInfo.advanced.recommendation
        : "",
      Score3: "75",
      Evaluation4: GetTacticInfo.mature ? GetTacticInfo.mature.evaluation : "",
      Reccomendation4: GetTacticInfo.mature
        ? GetTacticInfo.mature.recommendation
        : "",
      Score4: "100",
    },

    validationSchema: Yup.object({
      // channelId: Yup.string().required("Please select channel name"),
      // TacticName: Yup.string().required("Please enter tactic Name"),
      // Interrogation: Yup.string().required("Please enter interrogation"),
    }),
    onSubmit: values => {
     
    
      // var currentChannel = channelData.filter(ch => ch.channelId === params.channelId)
      // console.log(currentChannel[0].tactics.filter(tactic => tactic.isDisabled === false).length)
      // console.log(isTacticDisabled)

      // // if(currentChannel[0].tactics.filter(tactic => tactic.isDisabled === false).length <10 && isTacticDisabled === true || isTacticDisabled === false)
      // if(currentChannel[0].tactics.filter(tactic => tactic.isDisabled === false).length === 10){
      //   settacticsLimitExceed(true)

      // }
      // else if(currentChannel[0].tactics.filter(tactic => tactic.isDisabled === false).length <=10 ){

        const tacticData = {
          tacticName: values.TacticName,
          channelName: values.channelName,
          interrogation: values.Interrogation,
          isDisabled: !isTacticDisabled,
          early: {
            evaluation: values.Evaluation,
            recommendation: values.Reccomendation,
            score: values.Score,
          },
          beginner: {
            evaluation: values.Evaluation1,
            recommendation: values.Reccomendation1,
            score: values.Score1,
          },
          intermediate: {
            evaluation: values.Evaluation2,
            recommendation: values.Reccomendation2,
            score: values.Score2,
          },
          advanced: {
            evaluation: values.Evaluation3,
            recommendation: values.Reccomendation3,
            score: values.Score3,
          },
          mature: {
            evaluation: values.Evaluation4,
            recommendation: values.Reccomendation4,
            score: values.Score4,
          },
        }
        // console.log("values", tacticData)
        setTacticUpdateState(tacticData)
        setIsTacticUpdate(true)
        dispatch(
          updateTacticDetails(params.channelId, params.tacticId, tacticData)
        )
        dispatch(handleChangesHappend(false))          
        
      // else if(currentChannel[0].tactics.filter(tactic => tactic.isDisabled === false).length >=10){
      //   settacticsLimitExceed(true)
      // }
      }
    })
  
  

      

      
        
      
       
      


const GetEnableTacticCount = (values) => {
  const tacticData = {
    tacticName: values.TacticName,
    channelName: values.channelName,
    interrogation: values.Interrogation,
    isDisabled: !isTacticDisabled,
    early: {
      evaluation: values.Evaluation,
      recommendation: values.Reccomendation,
      score: values.Score,
    },
    beginner: {
      evaluation: values.Evaluation1,
      recommendation: values.Reccomendation1,
      score: values.Score1,
    },
    intermediate: {
      evaluation: values.Evaluation2,
      recommendation: values.Reccomendation2,
      score: values.Score2,
    },
    advanced: {
      evaluation: values.Evaluation3,
      recommendation: values.Reccomendation3,
      score: values.Score3,
    },
    mature: {
      evaluation: values.Evaluation4,
      recommendation: values.Reccomendation4,
      score: values.Score4,
    },
  }
  // console.log("values", tacticData)
  setTacticUpdateState(tacticData)
  setIsTacticUpdate(true)
  dispatch(
    updateTacticDetails(params.channelId, params.tacticId, tacticData)
  )
  dispatch(handleChangesHappend(false))

}
        

      
        
      
    
     


       
       
    



      
      
        
        
       
  
      
 
      


      
     
     

       
      
    
     

  // function textareachange(event) {
  //   const count = event.target.value.length
  //   if (count > 0) {
  //     settextareabadge(true)
  //   } else {
  //     settextareabadge(false)
  //   }
  //   settextcount(event.target.value.length)
  // }

  // function stimer() {
  //   const getTimer = () => (
  //     <SweetAlert
  //       title={
  //         <span>
  //           HTML <small>AutoClose Timer</small>!
  //         </span>
  //       }
  //       onConfirm={() => hideTimeAlert()}
  //     >
  //       {" "}
  //       <span>Timer Will Expire after 3 Seconds.</span>
  //     </SweetAlert>
  //   )
  //   settimeralert(getTimer())
  //   setTimeout(
  //     function () {
  //       settimeralert(null)
  //     }.bind(this),
  //     3000
  //   )
  // }

  // function hideTimeAlert() {
  //   settimeralert(null)
  // }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Tactic Details</title>
        </MetaTags>
        <Container fluid={true}>
          {!Object.keys(GetTacticInfo).includes("id") ? (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                height: "400px",
              }}
            >
              <Spinner
                style={{
                  width: "60px",
                  height: "60px",
                  margin: "calc(200px - 30px)",
                }}
                role="grid"
                type="border"
                color="primary"
              />
            </div>
          ) : (
            <Row>
              <Col sm="12" style={{ margin: "auto" }}>
                <Card>
                  <CardBody>
                    {tacticsLimitExceed && 
                       <SweetAlert
                       title=""
                       warning
                       confirmBtnBsStyle="primary"
                      
                       confirmButtonText="Yes"
                       
                       
                       onConfirm={() => {
                        settacticsLimitExceed(false)
                       
                       }}
                      
                     >
                      A maximum of 10 Tactics are allowed to enable under one Channel.
                     </SweetAlert>
                     

                    }
                    {emptyEvaluationAlert && 
                       <SweetAlert
                       title=""
                       warning
                       confirmBtnBsStyle="primary"
                      
                       confirmButtonText="Yes"
                       
                       
                       onConfirm={() => {
                        setEmaptyEvaluationAlert(false)
                       
                       }}
                      
                     >
                      Please fill evaluation and then enable tactic.
                     </SweetAlert>
                     

                    }
                 
                    {updateTactic.status === 200 && (
                      <SweetAlert
                        // title="Tactic Updated successfully"
                        success
                        confirmBtnBsStyle="primary"
                        style={{ height: "255px", width: "368px" }}
                        onConfirm={() => {
                          var newList = channelData.map(ch => {
                            if (ch.channelId === params.channelId) {
                              return {
                                ...ch,
                                tactics: ch.tactics.map(ch_t => {
                                  if (ch_t.tacticId === params.tacticId) {
                                    return {
                                      

                                      ...TacticUpdateState,
                                      tacticId: params.tacticId,
                                    }
                                  } else return ch_t
                                }),
                              }

                              // [...ch.tactics, {
                              //   ...TacticUpdateState,
                              //   tacticId: params.tacticId
                              // }]
                            } else {
                              return ch
                            }
                          })
                          // console.log(newList)
                          dispatch(changeChannelData(newList))
                          // console.log(newList)
                          setsuccess_msg(false)
                          setIsTacticUpdate(false)
                          dispatch(clearUpdateTacticDetails())
                          //  history.push("/")
                        }}
                      >
                        {CONTENT_EDITOR.UPDATE_TACTIC.SUCCESS}
                      </SweetAlert>
                    )}
                    {updateTactic.status === 403 && (
                      <SweetAlert
                        // title="Failed to update tactic"
                        error
                        confirmBtnBsStyle="primary"
                        style={{ height: "255px", width: "368px" }}
                        onConfirm={() => {
                          setsuccess_msg(false)
                          dispatch(clearUpdateTacticDetails())
                          setIsTacticUpdate(false)
                        }}
                      >
                        {CONTENT_EDITOR.UPDATE_TACTIC.FAILED}
                      </SweetAlert>
                    )}

{/* {
                    tacticsLimitExceed && 
                    <UncontrolledAlert color="danger" onClick={() => {
                      settacticsLimitExceed(false),setIsTacticUpdate(false)

                    }}>{
                      <strong> </strong>
                    }
                    </UncontrolledAlert>
                  } */}
                    <Form
                      className="needs-validation"
                      autoComplete="off"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {/* <Row>  <h4 className="card-title" style={{textTransform: ""}}>Tactic Details </h4></Row>
                                        <br></br>    */}

                      <Row>
                        <Col sm="4 ">
                          <FormGroup className="mb-3">
                            <label>Channel Name</label>
                            <Input
                              name="channelName"
                              placeholder=""
                              type="text"
                              readOnly
                              id="horizontal-channelname-Input"
                              value={validation.values.channelName || ""}
                              invalid={
                                validation.touched.channelId &&
                                validation.errors.channelId
                                  ? true
                                  : false
                              }
                            />

                            {validation.touched.channelId &&
                            validation.errors.channelId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.channelId}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup className="mb-3">
                            <label>Tactic Name</label>
                            <Input
                              name="TacticName"
                              placeholder="tactic"
                              // readOnly
                              type="text"
                              className="form-control"
                              id="horizontal-firstname-Input"
                              onChange={e => {
                                validation.handleChange(e)
                                dispatch(handleChangesHappend(true))
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.TacticName || ""}
                            />
                            {validation.touched.TacticName &&
                            validation.errors.TacticName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.TacticName}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup className="mb-3">
                            <label>Interrogation</label>
                            <Input
                              name="Interrogation"
                              placeholder="interrogation"
                              type="text"
                              className="form-control"
                              // readOnly
                              id="horizontal-firstname-Input"
                              onChange={e => {
                                validation.handleChange(e)
                                dispatch(handleChangesHappend(true))
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.Interrogation || ""}
                              invalid={
                                validation.touched.Interrogation &&
                                validation.errors.Interrogation
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.Interrogation &&
                            validation.errors.Interrogation ? (
                              <FormFeedback type="invalid">
                                {validation.errors.Interrogation}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <br></br>

                      {/* Bubble Preview Functionality */}
                        <div style={{marginTop:'-95px', border:'1px solid transparent', marginBottom:'-90px'}}>
                          <TacticPreview
                            TACTIC_NAME={
                              validation.values.TacticName || "Tactic name"
                            }
                          />
                        </div>
                      {/* <Row>
                        <Col md="10">
                          <Modal
                            isOpen={modal_center}
                            toggle={() => {
                              setmodal_center(!modal_center)
                              removeBodyCss()
                            }}
                            centered
                          >
                            <div className="modal-header">
                              <h5 className="modal-title mt-0">
                                Tactic Bubble Preview
                              </h5>
                              <button
                                type="button"
                                onClick={() => {
                                  setmodal_center(false)
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <TacticPreview
                                TACTIC_NAME={
                                  validation.values.TacticName || "Tactic name"
                                }
                              />
                            </div>
                          </Modal>
                        </Col>
                        <Col md="2">
                          <FormGroup className="mb-3">
                            <Button
                              color="primary"
                              type="button"
                              block
                              onClick={() => {
                                setmodal_center(true)
                              }}
                            >
                              Preview Bubble
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row> */}

                      <Row>
                        <Col lg="12">
                          <Card>
                            <CardBody>
                              <div className="wizard clearfix">
                                <div className="steps clearfix">
                                  <ul>
                                    <NavItem
                                      className={classnames({
                                        current: activeTab === 1,
                                      })}
                                      style={
                                        activeTab === 1
                                          ? {
                                              borderBottom: "3px solid #3D57A3",
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        setactiveTab(1)
                                      }}
                                    >
                                      <NavLink
                                        className={classnames({
                                          current: activeTab === 1,
                                        })}
                                        onClick={() => {
                                          setactiveTab(1)
                                        }}
                                        disabled={
                                          !(passedSteps || []).includes(1)
                                        }
                                        style={{
                                          textAlign: "center",
                                          background: "#f2f6fa",
                                          color: "#3D57A3",
                                          // border:'2px'
                                        }}
                                      >
                                        {" "}
                                        Early
                                      </NavLink>
                                    </NavItem>
                                    <NavItem
                                      className={classnames({
                                        current: activeTab === 2,
                                      })}
                                      style={
                                        activeTab === 2
                                          ? {
                                              borderBottom: "3px solid #3D57A3",
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        setactiveTab(2)
                                      }}
                                    >
                                      <NavLink
                                        className={classnames({
                                          active: activeTab === 2,
                                        })}
                                        onClick={() => {
                                          setactiveTab(2)
                                        }}
                                        disabled={
                                          !(passedSteps || []).includes(2)
                                        }
                                        style={{
                                          textAlign: "center",
                                          background: "#f2f6fa",
                                          color: "#3D57A3",
                                        }}
                                      >
                                        Beginner
                                      </NavLink>
                                    </NavItem>
                                    <NavItem
                                      className={classnames({
                                        current: activeTab === 3,
                                      })}
                                      style={
                                        activeTab === 3
                                          ? {
                                              borderBottom: "3px solid #3D57A3",
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        setactiveTab(3)
                                      }}
                                    >
                                      <NavLink
                                        className={classnames({
                                          active: activeTab === 3,
                                        })}
                                        onClick={() => {
                                          setactiveTab(3)
                                        }}
                                        disabled={
                                          !(passedSteps || []).includes(3)
                                        }
                                        style={{
                                          textAlign: "center",
                                          background: "#f2f6fa",
                                          color: "#3D57A3",
                                        }}
                                      >
                                        Intermediate
                                      </NavLink>
                                    </NavItem>
                                    <NavItem
                                      className={classnames({
                                        current: activeTab === 4,
                                      })}
                                      style={
                                        activeTab === 4
                                          ? {
                                              borderBottom: "3px solid #3D57A3",
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        setactiveTab(4)
                                      }}
                                    >
                                      <NavLink
                                        className={classnames({
                                          active: activeTab === 4,
                                        })}
                                        onClick={() => {
                                          setactiveTab(4)
                                        }}
                                        disabled={
                                          !(passedSteps || []).includes(4)
                                        }
                                        style={{
                                          textAlign: "center",
                                          background: "#f2f6fa",
                                          color: "#3D57A3",
                                        }}
                                      >
                                        Advanced
                                      </NavLink>
                                    </NavItem>
                                    <NavItem
                                      className={classnames({
                                        current: activeTab === 5,
                                      })}
                                      style={
                                        activeTab === 5
                                          ? {
                                              borderBottom: "3px solid #3D57A3",
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        setactiveTab(5)
                                      }}
                                    >
                                      <NavLink
                                        className={classnames({
                                          active: activeTab === 5,
                                        })}
                                        onClick={() => {
                                          setactiveTab(5)
                                        }}
                                        disabled={
                                          !(passedSteps || []).includes(5)
                                        }
                                        style={{
                                          textAlign: "center",
                                          background: "#f2f6fa",
                                          color: "#3D57A3",
                                          // borderTopRightRadius:'5px'
                                        }}
                                      >
                                        Mature
                                      </NavLink>
                                    </NavItem>
                                  </ul>
                                </div>
                                <div
                                  className="content clearfix mt-4"
                                  style={{ background: "#ECF9F6" }}
                                >
                                  <Row>
                                    <Col md="10"></Col>
                                    <Col md="2">
                                      {/* <i className = "fas fa-pencil-alt text-primary"
                      style={{float:"right"}}></i> */}
                                    </Col>
                                  </Row>
                                  <br></br>

                                  <TabContent activeTab={activeTab}>
                                    <TabPane tabId={1}>
                                      <Form>
                                        <Row>
                                          <Col sm="2" style={{ textAlign: "" }}>
                                            <label>Evaluation</label>
                                          </Col>
                                          <Col sm="10">
                                            <FormGroup className="mb-3">
                                              <Input
                                                style={{
                                                  background: "#ECF9F6",
                                                  height: "95px",
                                                }}
                                                name="Evaluation"
                                                placeholder=""
                                                type="textarea"
                                                className="form-control"
                                                id="horizontal-Evaluation-Input"
                                                onChange={e => {
                                                  validation.handleChange(e)
                                                  dispatch(
                                                    handleChangesHappend(true)
                                                  )
                                                }}
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .Evaluation || ""
                                                }
                                                invalid={
                                                  validation.touched
                                                    .Evaluation &&
                                                  validation.errors.Evaluation
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validation.touched.Evaluation &&
                                              validation.errors.Evaluation ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.Evaluation}
                                                </FormFeedback>
                                              ) : null}
                                            </FormGroup>
                                          </Col>{" "}
                                        </Row>
                                        <Row>
                                          <Col sm="2" style={{ textAlign: "" }}>
                                            <label>Recommendation</label>
                                          </Col>
                                          <Col sm="10">
                                            <FormGroup className="mb-3">
                                              <Input
                                                style={{
                                                  background: "#ECF9F6",
                                                  height: "95px",
                                                }}
                                                name="Reccomendation"
                                                placeholder=""
                                                type="textarea"
                                                maxLength="450"
                                                className="form-control"
                                                id="horizontal-Reccomendation-Input"
                                                onChange={e => {
                                                  validation.handleChange(e)
                                                  settextcount(
                                                    e.target.value.length
                                                  )
                                                  dispatch(
                                                    handleChangesHappend(true)
                                                  )
                                                }}
                                                onFocus={e => {
                                                  settextareabadge(true)
                                                  settextcount(
                                                    e.target.value.length
                                                  )
                                                }}
                                                onBlur={() => {
                                                  settextareabadge(false)
                                                  validation.handleBlur
                                                }}
                                                value={
                                                  validation.values
                                                    .Reccomendation || ""
                                                }
                                                invalid={
                                                  validation.touched
                                                    .Reccomendation &&
                                                  validation.errors
                                                    .Reccomendation
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {textareabadge ? (
                                                <span
                                                  className="badgecount badge bg-primary"
                                                  style={{ float: "right" }}
                                                >
                                                  {textcount} / 450
                                                </span>
                                              ) : null}
                                              {validation.touched
                                                .Reccomendation &&
                                              validation.errors
                                                .Reccomendation ? (
                                                <FormFeedback type="invalid">
                                                  {
                                                    validation.errors
                                                      .Reccomendation
                                                  }
                                                </FormFeedback>
                                              ) : null}
                                            </FormGroup>
                                          </Col>{" "}
                                        </Row>
                                        <Row>
                                          <Col
                                            sm="2"
                                            className="mt-2"
                                            style={{ textAlign: "" }}
                                          >
                                            <label>Score</label>
                                          </Col>
                                          <Col sm="1">
                                            <FormGroup className="mb-3">
                                              <Input
                                                readOnly
                                                style={{
                                                  background: "#ECF9F6",
                                                  border: "0",
                                                }}
                                                name="Score"
                                                placeholder=""
                                                type="number"
                                                className="form-control"
                                                id="horizontal-Score-Input"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.Score || "0"
                                                }
                                                invalid={
                                                  validation.touched.Score &&
                                                  validation.errors.Score
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validation.touched.Score &&
                                              validation.errors.Score ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.Score}
                                                </FormFeedback>
                                              ) : null}
                                            </FormGroup>
                                          </Col>{" "}
                                        </Row>
                                      </Form>
                                    </TabPane>
                                    <TabPane tabId={2}>
                                      <div>
                                        <Form>
                                          <Row>
                                            <Col
                                              sm="2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Evaluation</label>
                                            </Col>
                                            <Col sm="10">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  style={{
                                                    background: "#ECF9F6",
                                                    height: "95px",
                                                  }}
                                                  name="Evaluation1"
                                                  placeholder=""
                                                  type="textarea"
                                                  className="form-control"
                                                  id="horizontal-Evaluation1-Input"
                                                  onChange={e => {
                                                    validation.handleChange(e)
                                                    dispatch(
                                                      handleChangesHappend(true)
                                                    )
                                                  }}
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .Evaluation1 || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .Evaluation1 &&
                                                    validation.errors
                                                      .Evaluation1
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .Evaluation1 &&
                                                validation.errors
                                                  .Evaluation1 ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .Evaluation1
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                          <Row>
                                            <Col
                                              sm="2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Recommendation</label>
                                            </Col>
                                            <Col sm="10">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  style={{
                                                    background: "#ECF9F6",
                                                    height: "95px",
                                                  }}
                                                  name="Reccomendation1"
                                                  placeholder=""
                                                  type="textarea"
                                                  maxLength="450"
                                                  className="form-control"
                                                  id="horizontal-Reccomendation1-Input"
                                                  onChange={e => {
                                                    validation.handleChange(e)
                                                    settextcount(
                                                      e.target.value.length
                                                    )
                                                    dispatch(
                                                      handleChangesHappend(true)
                                                    )
                                                  }}
                                                  onFocus={e => {
                                                    settextareabadge(true)
                                                    settextcount(
                                                      e.target.value.length
                                                    )
                                                  }}
                                                  onBlur={() => {
                                                    settextareabadge(false)
                                                    validation.handleBlur
                                                  }}
                                                  value={
                                                    validation.values
                                                      .Reccomendation1 || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .Reccomendation1 &&
                                                    validation.errors
                                                      .Reccomendation1
                                                      ? true
                                                      : false
                                                  }
                                                />

                                                {textareabadge ? (
                                                  <span
                                                    className="badgecount badge bg-primary"
                                                    style={{ float: "right" }}
                                                  >
                                                    {textcount} / 450
                                                  </span>
                                                ) : null}

                                                {validation.touched
                                                  .Reccomendation1 &&
                                                validation.errors
                                                  .Reccomendation1 ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .Reccomendation1
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                          <Row>
                                            <Col
                                              sm="2"
                                              className="mt-2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Score</label>
                                            </Col>
                                            <Col sm="1">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  readOnly
                                                  style={{
                                                    background: "#ECF9F6",
                                                    border: "0",
                                                  }}
                                                  name="Score1"
                                                  placeholder=""
                                                  type="number"
                                                  className="form-control"
                                                  id="horizontal-Score1-Input"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.Score1 ||
                                                    "25"
                                                  }
                                                  invalid={
                                                    validation.touched.Score1 &&
                                                    validation.errors.Score1
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.Score1 &&
                                                validation.errors.Score1 ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.Score1}
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                        </Form>
                                      </div>
                                    </TabPane>
                                    <TabPane tabId={3}>
                                      <div>
                                        <Form>
                                          <Row>
                                            <Col
                                              sm="2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Evaluation</label>
                                            </Col>
                                            <Col sm="10">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  style={{
                                                    background: "#ECF9F6",
                                                    height: "95px",
                                                  }}
                                                  name="Evaluation2"
                                                  placeholder=""
                                                  type="textarea"
                                                  className="form-control"
                                                  id="horizontal-Evaluation2-Input"
                                                  onChange={e => {
                                                    validation.handleChange(e)
                                                    dispatch(
                                                      handleChangesHappend(true)
                                                    )
                                                  }}
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .Evaluation2 || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .Evaluation2 &&
                                                    validation.errors
                                                      .Evaluation2
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .Evaluation2 &&
                                                validation.errors
                                                  .Evaluation2 ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .Evaluation2
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                          <Row>
                                            <Col
                                              sm="2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Recommendation</label>
                                            </Col>
                                            <Col sm="10">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  style={{
                                                    background: "#ECF9F6",
                                                    height: "95px",
                                                  }}
                                                  name="Reccomendation2"
                                                  placeholder=""
                                                  type="textarea"
                                                  maxLength="450"
                                                  className="form-control"
                                                  id="horizontal-Reccomendation2-Input"
                                                  onChange={e => {
                                                    validation.handleChange(e)
                                                    settextcount(
                                                      e.target.value.length
                                                    )
                                                    dispatch(
                                                      handleChangesHappend(true)
                                                    )
                                                  }}
                                                  onFocus={e => {
                                                    settextareabadge(true)
                                                    settextcount(
                                                      e.target.value.length
                                                    )
                                                  }}
                                                  onBlur={() => {
                                                    settextareabadge(false)
                                                    validation.handleBlur
                                                  }}
                                                  value={
                                                    validation.values
                                                      .Reccomendation2 || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .Reccomendation2 &&
                                                    validation.errors
                                                      .Reccomendation2
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {textareabadge ? (
                                                  <span
                                                    className="badgecount badge bg-primary"
                                                    style={{ float: "right" }}
                                                  >
                                                    {textcount} / 450
                                                  </span>
                                                ) : null}
                                                {validation.touched
                                                  .Reccomendation2 &&
                                                validation.errors
                                                  .Reccomendation2 ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .Reccomendation2
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                          <Row>
                                            <Col
                                              sm="2"
                                              className="mt-2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Score</label>
                                            </Col>
                                            <Col sm="1">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  readOnly
                                                  style={{
                                                    background: "#ECF9F6",
                                                    border: "0",
                                                  }}
                                                  name="Score2"
                                                  placeholder=""
                                                  type="number"
                                                  className="form-control"
                                                  id="horizontal-Score2-Input"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.Score2 ||
                                                    "50"
                                                  }
                                                  invalid={
                                                    validation.touched.Score2 &&
                                                    validation.errors.Score2
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.Score2 &&
                                                validation.errors.Score2 ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.Score2}
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                        </Form>
                                      </div>
                                    </TabPane>
                                    <TabPane tabId={4}>
                                      <div>
                                        <Form>
                                          <Row>
                                            <Col
                                              sm="2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Evaluation</label>
                                            </Col>
                                            <Col sm="10">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  style={{
                                                    background: "#ECF9F6",
                                                    height: "95px",
                                                  }}
                                                  name="Evaluation3"
                                                  placeholder=""
                                                  type="textarea"
                                                  className="form-control"
                                                  id="horizontal-Evaluation3-Input"
                                                  onChange={e => {
                                                    validation.handleChange(e)
                                                    dispatch(
                                                      handleChangesHappend(true)
                                                    )
                                                  }}
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .Evaluation3 || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .Evaluation3 &&
                                                    validation.errors
                                                      .Evaluation3
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .Evaluation3 &&
                                                validation.errors
                                                  .Evaluation3 ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .Evaluation3
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                          <Row>
                                            <Col
                                              sm="2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Recommendation</label>
                                            </Col>
                                            <Col sm="10">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  style={{
                                                    background: "#ECF9F6",
                                                    height: "95px",
                                                  }}
                                                  name="Reccomendation3"
                                                  placeholder=""
                                                  type="textarea"
                                                  maxLength="450"
                                                  className="form-control"
                                                  id="horizontal-Reccomendation3-Input"
                                                  onChange={e => {
                                                    validation.handleChange(e)
                                                    settextcount(
                                                      e.target.value.length
                                                    )
                                                    dispatch(
                                                      handleChangesHappend(true)
                                                    )
                                                  }}
                                                  onFocus={e => {
                                                    settextareabadge(true)
                                                    settextcount(
                                                      e.target.value.length
                                                    )
                                                  }}
                                                  onBlur={() => {
                                                    settextareabadge(false)
                                                    validation.handleBlur
                                                  }}
                                                  value={
                                                    validation.values
                                                      .Reccomendation3 || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .Reccomendation3 &&
                                                    validation.errors
                                                      .Reccomendation3
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {textareabadge ? (
                                                  <span
                                                    className="badgecount badge bg-primary"
                                                    style={{ float: "right" }}
                                                  >
                                                    {textcount} / 450
                                                  </span>
                                                ) : null}
                                                {validation.touched
                                                  .Reccomendation3 &&
                                                validation.errors
                                                  .Reccomendation3 ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .Reccomendation3
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                          <Row>
                                            <Col
                                              sm="2"
                                              className="mt-2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Score</label>
                                            </Col>
                                            <Col sm="1">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  readOnly
                                                  style={{
                                                    background: "#ECF9F6",
                                                    border: "0",
                                                  }}
                                                  name="Score3"
                                                  placeholder=""
                                                  type="number"
                                                  className="form-control"
                                                  id="horizontal-Score3-Input"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.Score3 ||
                                                    "75"
                                                  }
                                                  invalid={
                                                    validation.touched.Score3 &&
                                                    validation.errors.Score3
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.Score3 &&
                                                validation.errors.Score3 ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.Score3}
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                        </Form>
                                      </div>
                                    </TabPane>
                                    <TabPane tabId={5}>
                                      <div>
                                        <Form>
                                          <Row>
                                            <Col
                                              sm="2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Evaluation</label>
                                            </Col>
                                            <Col sm="10">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  style={{
                                                    background: "#ECF9F6",
                                                    height: "95px",
                                                  }}
                                                  name="Evaluation4"
                                                  placeholder=""
                                                  type="textarea"
                                                  className="form-control"
                                                  id="horizontal-Evaluation4-Input"
                                                  onChange={e => {
                                                    validation.handleChange(e)
                                                    dispatch(
                                                      handleChangesHappend(true)
                                                    )
                                                  }}
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .Evaluation4 || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .Evaluation4 &&
                                                    validation.errors
                                                      .Evaluation4
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .Evaluation4 &&
                                                validation.errors
                                                  .Evaluation4 ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .Evaluation4
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                          <Row>
                                            <Col
                                              sm="2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Recommendation</label>
                                            </Col>
                                            <Col sm="10">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  style={{
                                                    background: "#ECF9F6",
                                                    height: "95px",
                                                  }}
                                                  name="Reccomendation4"
                                                  placeholder=""
                                                  type="textarea"
                                                  maxLength="450"
                                                  className="form-control"
                                                  id="horizontal-Reccomendation4-Input"
                                                  onChange={e => {
                                                    validation.handleChange(e)
                                                    settextcount(
                                                      e.target.value.length
                                                    )
                                                    dispatch(
                                                      handleChangesHappend(true)
                                                    )
                                                  }}
                                                  onFocus={e => {
                                                    settextareabadge(true)
                                                    settextcount(
                                                      e.target.value.length
                                                    )
                                                  }}
                                                  onBlur={() => {
                                                    settextareabadge(false)
                                                    validation.handleBlur
                                                  }}
                                                  value={
                                                    validation.values
                                                      .Reccomendation4 || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .Reccomendation4 &&
                                                    validation.errors
                                                      .Reccomendation4
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {textareabadge ? (
                                                  <span
                                                    className="badgecount badge bg-primary"
                                                    style={{ float: "right" }}
                                                  >
                                                    {textcount} / 450
                                                  </span>
                                                ) : null}
                                                {validation.touched
                                                  .Reccomendation4 &&
                                                validation.errors
                                                  .Reccomendation4 ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .Reccomendation4
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                          <Row>
                                            <Col
                                              sm="2"
                                              className="mt-2"
                                              style={{ textAlign: "" }}
                                            >
                                              <label>Score</label>
                                            </Col>
                                            <Col sm="1">
                                              <FormGroup className="mb-3">
                                                <Input
                                                  readOnly
                                                  style={{
                                                    background: "#ECF9F6",
                                                    border: "0",
                                                  }}
                                                  name="Score4"
                                                  placeholder=""
                                                  type="number"
                                                  className="form-control"
                                                  id="horizontal-Score4-Input"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.Score4 ||
                                                    "100"
                                                  }
                                                  invalid={
                                                    validation.touched.Score4 &&
                                                    validation.errors.Score4
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.Score4 &&
                                                validation.errors.Score4 ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.Score4}
                                                  </FormFeedback>
                                                ) : null}
                                              </FormGroup>
                                            </Col>{" "}
                                          </Row>
                                        </Form>
                                      </div>
                                    </TabPane>
                                  </TabContent>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="5">
                          {
                            <>
                              <div>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginRight: "8px",
                                  }}
                                >
                                  {" "}
                                  Tactic Status:{" "}
                                </span>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-2 mb-sm-8 mb-4"
                                  onColor="#3d57a3"
                                  width={100}
                                  onChange={e => {
                                    if(GetTacticInfo.isDisabled === true && EnableTacticCount() < channelTacticsLimit ){
                                      setIsTacticDisabled(p => !p)

                                    }
                                    else if(GetTacticInfo.isDisabled === false){
                                      setIsTacticDisabled(p => !p)

                                    }
                                    
                                    else{
                                     
                                      settacticsLimitExceed(true)
                                    }
                                    
                                    // getEnableTacticCount()
                                   /// GetEnableTacticCount()
                                    
                                    dispatch(handleChangesHappend(true))
                                  }}
                                  checked={isTacticDisabled}
                                />
                                <span
                                  style={{
                                    fontWeight: "bold",

                                    color: "#C0392B",
                                  }}
                                >
                                  {" "}
                                  (Enable/Disable this tactic)
                                </span>
                              </div>
                            </>
                          }
                        </Col>

                        <Col md="3"></Col>

                        <Col md="2">
                          <FormGroup className="mb-3">
                            {/* <Button
                            color="danger"
                            onClick={() => {
                              validationForAddChannel.resetForm()
                            }}
                            block
                          >
                            Cancel
                          </Button> */}
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup className="mb-3">
                            <Button
                              color="primary"
                              type="submit"
                              disabled={isTacticUpdate}
                              block
                              
                              
                            >
                              {isTacticUpdate ? "" : "Update"}
                              {isTacticUpdate ? (
                                <Spinner type="border" size="sm" />
                              ) : null}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  )
}

export default Update_tactic
