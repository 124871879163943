import {
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  CLEAR_PRODUCT,

  CHECKOUT_REQUEST,
  CHECKOUT_REQUEST_SUCCESS,
  CHECKOUT_REQUEST_FAIL,
  CLEAR_CHECKOUT_DATA



} from "./actionTypes"

export const getProduct = () => {
    return{
        type:GET_PRODUCT,
    }

}

export const getProductSuccess = (data) => {
    return{
        type:GET_PRODUCT_SUCCESS,
        payload:data
    }
}

export const getProductFail = (error) => {
    return{
        type:GET_PRODUCT_FAIL,
        payload:error
    }

}

export const ClearProduct = () => {
    return{
        type:CLEAR_PRODUCT
    }

}

export const checkoutRequestData = (data) => {
    return{
        type:CHECKOUT_REQUEST,
        payload:data

    }
}

export const checkoutRequestDataSuccess = (data) => {
    return{
        type:CHECKOUT_REQUEST_SUCCESS,
        payload:data
    }
}

export const checkoutRequestDataFail = (error) => {
    return{
        type:CHECKOUT_REQUEST_FAIL,
        payload:error
    }

}

export const ClearCheckoutData = () => {
    return{
        type:CLEAR_CHECKOUT_DATA,
        
    }
}