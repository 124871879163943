import {
    ADD_EDITOR_DATA,
    ADD_EDITOR_DATA_SUCCESS,
    ADD_EDITOR_DATA_FAIL,
    CLEAR_EDITOR_DATA,

    GET_EDITOR_DATA,
    GET_EDITOR_DATA_SUCCESS,
    GET_EDITOR_DATA_FAIL
} from "./actionTypes"

export const addEditorData = (data) =>{
    return{
        type:ADD_EDITOR_DATA,
        payload:data
    }
}

export const addEditorDataSuccess = (data) => {
    return{
        type:ADD_EDITOR_DATA_SUCCESS,
        payload:data
    }

}
export const addEditorDataFail = (error) => {
    return{
        type:ADD_EDITOR_DATA_FAIL,
        payload:error
    }

}
export const clearEditorData = () => {
    return{
      type: CLEAR_EDITOR_DATA
    }
  }

export const getEditorData = (id) =>{
    return{
        type:GET_EDITOR_DATA,
        id,
    }
}

export const getEditorDataSuccess = (data) =>{
    return{
        type:GET_EDITOR_DATA_SUCCESS,
        payload:data
    }
}

export const getEditordataFail = (error) =>{
    return{
        type:GET_EDITOR_DATA_FAIL,
        payload:error

    }
}