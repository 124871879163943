//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
// export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"

// Add channel
export const ADD_CHANNEL = "/channels"

export const GET_DEMO_DATA = "/dummy"

export const GET_CHANNEL_DATA = "/channels"

//SUPER ADMIN - tables
export const GET_ACCOUNT_TABLE = "/users/ah"

export const GET_CONTENT_EDITOR_TABLE = "/users/ce"

//SUPER ADMIN - Get Account information
export const GET_ACCOUNT_DETAILS = "/users/ah"

//SUPER ADMIN -Get Content editor information
export const GET_EDITOR_DETAILS = "/users/ce"

//SUPER ADMIN - creating user
export const ADD_ACCOUNT_HOLDER = "/users/ah"
export const ADD_CONTENT_EDITOR = "/users/ce"

//SUPER ADMIN - Updating user

//export const UPDATE_ACCOUNT_HOLDER = "/users/ah"
export const UPDATE_ACCOUNT_DETAILS = "/users/ah"

//SUPER ADMIN -Updating content editor
export const UPDATE_EDITOR_DETAILS = "/users/ce"

//SUPER ADMIN - Fetching Account Users

//export const GET_ACCOUNT_USERS_LIST = "/users/ah"

//Super Admin - deleting user
//export const DELETE_ACCOUNT_HOLDER = "/users/ah"

// Account Holder:
export const ADD_ACCOUNT_USER = "/users/a-users"
export const GET_ACCOUNT_HOLDER_USERS = "/users/ah"
export const GET_ACCOUNT_HOLDER_USER_DETAILS = "/users/a-users"
export const UPDATE_ACCOUNT_HOLDER_USER_DETAILS = "/users/a-users"
export const GET_SCORECARDS_LIST = "/users/ah"
export const CREATE_SCORECARD = "/scorecards"
export const GET_SCORECARD = "/scorecards"

// export const GET_SCORECARDS_LIST = "/dummyScorecards"

//Account User




export const GET_ACCOUNTUSER_DATA = "/dummyScorecards";
export const GET_SCORECARDS_LIST_OF_ACCOUNT_USER = "/users/a-users"





//CkEditor Testing
export const ADD_EDITOR_DATA = "/channels"

//CkEditor getdata
export const GET_EDITOR_DATA = "/channels"

// ContentEditor:
export const CREATE_REPORT_TEMPLATE = "/report/template"
export const GET_REPORT_TEMPLATE = "/report/template"

//ContentEditor|Create Tactic
export const ADD_TACTIC = "/channels"
export const GET_TACTIC_DETAILS = "/channels"

//ContentEditor | Get Individual  Channel
export const GET_CHANNEL_DETAILS = "/channels"

//ContentEditor | Update Individual Channel
export const UPDATE_CHANNEL_DETAILS = "/channels"

//ContentEditor | Update tactic
export const UPDATE_TACTIC_DETAILS = "/channels"

//ContentEditor | Edit tactic
export const EDIT_TACTIC_DETAIL = "/channels"


// Scorecard Report Url's:
export const GET_SCORECARD_REPORT = {
    collection: "scorecards",
    subCollection: "report"
}

//Scorecard Report Update Url's:
export const UPDATE_SCORECARD_REPORT = {
    collection: "scorecards",
    subCollection: "report"
}

//Buy Credits{Account Owner}
export const GET_PRODUCT = "mambo/stripe/stripe-products"

//Checkout Page
export const CHECKOUT_REQUEST = "mambo/stripe/stripe-checkout"

export const PAYMENT_SUCCESS = "/mambo/stripe/success"

export const TRANSACTION = "transactions"

// ContentEditor | InstructionPanel For Account User and Account Owner
export const GET_INSTRUCTIONS = "/instruction"    // For Getting data
export const ADD_NEW_INSTRUCTION = "/instruction" // For Adding New Data
export const UPDATE_INSTRUCTION = "/instruction"  // For Updating existing data

//Arrange Channel Sequence
export const ARRANGE_CHANNEL_SEQUENCE = "/channels/test/list" //For Updating Channel List

export const NEW_DASHBOAD_SCORECOUNT = "/scorecards/test"

// To get latest 3 scorecards
export const GET_ONLY_THREE_SCORECARDS = "/users/getOnlyThreeScorecards"

// Email for Account Owner when completing assessment by Account User
export const SEND_EMAIL_NOTIFICATION = "/notification/assessment-completion"


// EMAIL NOTIFICATIONS FOR NEW SCORECARD CREATION REMINDER
export const SEND_SCORECARD_CREATION_REMINDER = "/notification/scorecard-creation"