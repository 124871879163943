import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState, useCallback, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CropEasy from "../../Crop/CropEasy"
import { getOrientation } from "get-orientation/browser"
import getCroppedImg from "pages/Crop/utils/CropImage"
import parse from "html-react-parser"
import Switch from "react-switch"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

import * as Yup from "yup"
import { setIn, useFormik } from "formik"
// import SweetAlert from "react-bootstrap-sweetalert"

//Upload Image

import firebase from "firebase/compat"
import "firebase/storage"

import { Link, withRouter, useParams, useHistory } from "react-router-dom"

// import { getDemoData } from "../../store/actions";
//Import Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import FormValidation from "../Form/index"

import {
  getChannelData,
  AddNewChannel,
  ClearAddChannel,
  getChannelDetails,
  updateChannelDetails,
  clearChannelDetails,
  clearUpdateChannelDetails,
  handleChangesHappend,
  changeChannelData,
} from "../../../store/actions"
import { values } from "lodash"
import { getDownloadURL } from "firebase/storage"
import ImagePreview from "../../../components/Common/ImagePreview/ImagePreview.component"
import ImageThumbnail from "components/Common/ImageThumbnail/ImageThumbnail.component"
import { createThumbnail } from "commonFunctions/CreateThumbnails/createThumbnail"
import { style, width } from "@mui/system"
import { CONTENT_EDITOR } from "config/AlertMessage.config"
import { CLOUD_STORAGE } from "config/cloudStorage.config"
import ChannelDescriptionFirstPage from "../ChannelDescriptionFirstPage/component/channelDescriptionFirstPage.component"

const ViewChannel = props => {
  var parameter = props
  const params = useParams()
  const history = useHistory()

  const hiddenFile = useRef()
  const { data, setImageUrl, imageName } = parameter

  //Additional States for handleing custom requests:
  const [isChannelAdded, setIsChannelAdded] = useState(false)
  const [isUnmounted, setIsUnmounted] = useState(true)
  const [rows1, setrows1] = useState([])
  const [item, setItem] = useState("")
  const [input, setInput] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)
  const [HeightWidth, setHeightWidth] = useState({})

  const [file, setFile] = useState(null)
  const [image_url, setImage_Url] = useState(null)
  const [image, setImage] = useState()
  const [openCrop, setOpenCrop] = useState(false)
  const [previewImage, setPreviewImage] = useState({ visible: false })
  const [iconThumbnail, setIconThumbnail] = useState(null)
  const [icon, setIcon] = useState([])
  const [isImageThere, setImageThere] = useState(false)

  const [isChannelUpdate, setIsChannelUpdate] = useState(false)
  const [isChannelDisabled, setIsChannelDisabled] = useState(false)
  const [showPreview, setShowPreview] = useState(false)

  const [handleChannelData, setHandleChannelData] = useState({})
  const uploadReset = useRef()

  //const fileInput = useRef(null)

  const keyObservationManager = () => {
    var getKeyObservations = document.querySelectorAll(".ck-content")
    var myKeyObservationsList = [...getKeyObservations]

    var myKeyObservations = myKeyObservationsList.map(obs => obs.innerHTML)
    myKeyObservations.splice(0, 1)
    return myKeyObservations
  }

  // console.log("ROWS!", rows1)

  const keyObservationResetHandler = () => {
    var getKeyObservations = document.querySelectorAll(".ck-content")
    setrows1([{ id: 1 }])

    getKeyObservations[1].childNodes[0].innerHTML = ""

    uploadReset.current.value = ""
  }

  const handleChange = e => {
    const file = e.target.files[0]
    if (file) {
      var u = URL.createObjectURL(file)
      var img = new Image()
      img.onload = function () {
        var IMAGE_HEIGHT = img.height
        var IMAGE_WIDTH = img.width

        // console.log(
        //   "Image Width: " +
        //     " " +
        //     IMAGE_WIDTH +
        //     " " +
        //     "Image Height : " +
        //     IMAGE_HEIGHT
        // )

        setHeightWidth({
          myheight: IMAGE_HEIGHT,
          mywidth: IMAGE_WIDTH,
        })
      }
      img.src = u
      setFile(file)
      setImage_Url(u)
      setOpenCrop(true)
      dispatch(handleChangesHappend(true))
      e.target.value = ""
    }
    // console.log(file)
  }

  //   console.log(image_url)

  function handleAddRowNested() {
    if (rows1.length < 10) {
      // const modifiedRows = [...rows1]
      // modifiedRows.push({ id: modifiedRows.length + 1 })
      setrows1(e => [...e, { id: Math.random(), content: "" }])
    }
  }

  function handleRemoveRow(id) {
    if (rows1.length > 1) {
      // var modifiedRows = [...rows1]
      // modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      // setIcon(icon.filter((icon, i) => i !== id - 1 ))
      // setIconThumbnail(iconThumbnail.filter((icon, i) => i !== id - 1 ))
      setrows1(rows1.filter(row => row.id !== id))
    }
  }
  // console.log(rows1)
  const dispatch = useDispatch()
  //get channel and tactics:
  const { channelData } = useSelector(state => ({
    channelData: state.GetChannelData.channelData,
  }))
  const { addChannelData } = useSelector(state => ({
    addChannelData: state.AddChannelReducer.addChannelData,
  }))

  const { channelDetails, updateChannel } = useSelector(state => ({
    channelDetails: state.ChannelDetailsReducer.channelDetails,
    updateChannel: state.ChannelDetailsReducer.updateChannel,
  }))

  useEffect(() => {
    setIsChannelDisabled(!channelDetails.isDisabled)
  }, [channelDetails])

  useEffect(() => {
    if (isUnmounted) {
      dispatch(getChannelData())
    }
    //  console.log(dispatch(getChannelData()))
    return () => {
      setIsUnmounted(false)
    }
  }, [dispatch])


 
  // useEffect(() =>{
  // },[dispatch])

  // console.log("You are here")
  useEffect(() => {
    if (params && params.channelId) {
      // console.log(params.channelId)
      dispatch(getChannelDetails(params.channelId))
    }
    return () => dispatch(clearChannelDetails())
    // Clear channel details
  }, [dispatch])

  // console.log(channelDetails)

  useEffect(() => {
    if (channelDetails.keyObservations !== undefined) {
      var Observation = channelDetails.keyObservations.map(
        (keyObservation, i) => {
          return {
            id: Math.random(),
            content: keyObservation,
          }
        }
      )
      setrows1(Observation)
    }

    return () => setrows1([])
  }, [channelDetails])

  useEffect(() => {
    // setIsChannelAdded(false)

    // validationForAddChannel.values.channelName = ""

    // validationForAddChannel.values.channelDescriptionTitle = ""
    // validationForAddChannel.values.channelQuote = ""
    // setImage_Url("")
    // setItem()
    // keyObservationResetHandler()
    // keyObservationManager("")
    setImage_Url()
  }, [isChannelAdded])

  useEffect(() => {
    dispatch(getChannelDetails(params.channelId))
  }, [dispatch, params.channelId])

  // console.log("Channel details...", channelDetails)

  const validationForAddChannel = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      channelName: channelDetails.channelName ? channelDetails.channelName : "",
      channelDescriptionTitle: channelDetails.channelDescriptionTitle
        ? channelDetails.channelDescriptionTitle
        : "",

      channelQuote: channelDetails.channelQuote
        ? channelDetails.channelQuote
        : "",
    },
    validationSchema: Yup.object({
      channelName: Yup.string().required("Please enter channel name"),

      channelDescriptionTitle: Yup.string().required(
        "Please enter description title"
      ),
      channelQuote: Yup.string().required("Please enter channel quote"),
    }),
    onSubmit: values => {
      var date = new Date()
      var user = JSON.parse(localStorage.getItem("authUser"))
      // console.log("values", {
      //   channelName: values.channelName,
      //   channelDescriptionTitle: values.channelDescriptionTitle,
      //   channelDescription: values.channelDescription,
      //   channelQuote: values.channelQuote,
      //   keyObservations: values.keyObservations,
      //   createdOn: date,
      //   lastUpdatedOn: date,
      // })
      var myChannelData = {
        channelName: values.channelName,
        channelDescriptionTitle: values.channelDescriptionTitle,
        channelDescription: item,
        channelQuote: values.channelQuote,
        Image_URL: image_url,
        createdOn: date,
        createdBy: user.email,
        creatorId: user.uid,
        lastUpdatedOn: date,
        keyObservations: keyObservationManager(),
        isDisabled: !isChannelDisabled,
      }
      setIsChannelAdded(true)
      // console.log("myChannelData", myChannelData)
      setHandleChannelData(myChannelData)

      dispatch(updateChannelDetails(params.channelId, myChannelData))
      dispatch(handleChangesHappend(false))
      // values.Image_URL = setImage_Url()
      // values.channelName = null
      // values.channelDescription = setItem()
      // values.channelDescriptionTitle = null
      // values.channelQuote = null
      // values.keyObservations = setrows1({id:1})
      // keyObservationResetHandler()
      // setItem()
    },
  })

  // console.log("Channel Details ... ", channelDetails)

  //validation for tactics
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      tacticName: "",
      channelSelect: "",
    },
    validationSchema: Yup.object({
      channelName: Yup.string().required("Please enter Channel Name"),
      tacticName: Yup.string().required("Please Enter Tactic Name"),
    }),
    onSubmit: values => {
      // console.log("values", values)
    },
  })
  //   const preview =() =>{
  // frame.src=URL.createObjectURL(event.target.files[0]);
  //   }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          marginRight: "34px",
        }}
      >
        {" "}
        Disabled
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          marginLeft: "34px",
        }}
      >
        {" "}
        Enabled
      </div>
    )
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Channel Details</title>
        </MetaTags>
        <Container fluid={true}>
          {/* <h4 className="card-title" style={{textTransform: "uppercase"}}>Create channel & tactic </h4> */}
        </Container>
        <Container fluid={true}>
          {/* <Breadcrumbs title="Content Editor" breadcrumbItem="Tactics Details" /> */}
          {/* <FormValidation /> */}
          {!Object.keys(channelDetails).includes("id") ? (
            // !channelData[0]?
            <div
              style={{
                width: "100%",
                textAlign: "center",
                height: "400px",
              }}
            >
              <Spinner
                style={{
                  width: "60px",
                  height: "60px",
                  margin: "calc(200px - 30px)",
                }}
                role="grid"
                type="border"
                color="primary"
              />
            </div>
          ) : (
            <Row>
              <Col xl="12" style={{ margin: "auto" }}>
                <Card>
                  {previewImage.visible && (
                    <ImagePreview
                      ImageUrl={image_url || channelDetails.Image_URL}
                      TogglePreview={() => {
                        setPreviewImage(e => ({
                          ...e,
                          visible: false,
                        }))
                      }}
                    />
                  )}
                  <CardBody>
                    {/* {addChannelData.status === 200 && (
                    <Alert color="success" role="alert" className="alert-error">
                      Channel created successfully !
                    </Alert>
                  )} */}
                    {updateChannel.status === 200 && (
                      <SweetAlert
                        // title="Channel updated successfully"
                        success
                        confirmBtnBsStyle="primary"
                        style={{ height: "255px", width: "368px" }}
                        onConfirm={() => {
                          var NewUpdated_List = channelData.map(ch => {
                            if (ch.channelId === params.channelId) {
                              return {
                                ...ch,
                                ...handleChannelData,
                                channelId: params.channelId,
                              }
                            } else return ch
                          })
                          dispatch(changeChannelData(NewUpdated_List))
                          setsuccess_msg(false)
                          setIsChannelAdded(false)
                          dispatch(clearUpdateChannelDetails())
                          dispatch(getChannelDetails(params.channelId))
                          // validationForAddChannel.handleReset()
                          //dispatch(clearUpdateChannelDetails())
                          // history.push("/")
                        }}
                      >
                        {CONTENT_EDITOR.UPDATE_CHANNEL.SUCCESS}
                      </SweetAlert>
                    )}
                    {updateChannel.status === 403 && (
                      <SweetAlert
                        error
                        // title="Failed to update channel"

                        confirmBtnBsStyle="primary"
                        style={{ height: "255px", width: "368px" }}
                        onConfirm={() => {
                          setsuccess_msg(false)
                          dispatch(clearUpdateChannelDetails())
                          setIsChannelAdded(false)
                        }}
                      >
                        {CONTENT_EDITOR.UPDATE_CHANNEL.FAILED}
                      </SweetAlert>
                    )}
                    <Form
                      className="needs-validation outer-repeater"
                      autoComplete="off"
                      onSubmit={e => {
                        e.preventDefault()
                        validationForAddChannel.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Channel Name
                            </Label>
                            <Input
                              name="channelName"
                              placeholder="channel name"
                              type="text"
                              readOnly
                              className="form-control"
                              id="validationCustom01"
                              onChange={validationForAddChannel.handleChange}
                              onBlur={validationForAddChannel.handleBlur}
                              value={
                                validationForAddChannel.values.channelName || ""
                              }
                              invalid={
                                validationForAddChannel.touched.channelName &&
                                validationForAddChannel.errors.channelName
                                  ? true
                                  : false
                              }
                            />
                            {validationForAddChannel.touched.channelName &&
                            validationForAddChannel.errors.channelName ? (
                              <FormFeedback type="invalid">
                                {validationForAddChannel.errors.channelName}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="8">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Channel Description Title
                            </Label>
                            <Input
                              name="channelDescriptionTitle"
                              placeholder="channel description title"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={e => {
                                validationForAddChannel.handleChange(e)
                                dispatch(handleChangesHappend(true))
                              }}
                              onBlur={validationForAddChannel.handleBlur}
                              value={
                                validationForAddChannel.values
                                  .channelDescriptionTitle || ""
                              }
                              invalid={
                                validationForAddChannel.touched
                                  .channelDescriptionTitle &&
                                validationForAddChannel.errors
                                  .channelDescriptionTitle
                                  ? true
                                  : false
                              }
                            />
                            {validationForAddChannel.touched
                              .channelDescriptionTitle &&
                            validationForAddChannel.errors
                              .channelDescriptionTitle ? (
                              <FormFeedback type="invalid">
                                {
                                  validationForAddChannel.errors
                                    .channelDescriptionTitle
                                }
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="8">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Channel Quote
                            </Label>
                            <Input
                              name="channelQuote"
                              placeholder="channel quote"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={e => {
                                validationForAddChannel.handleChange(e)
                                dispatch(handleChangesHappend(true))
                              }}
                              onBlur={validationForAddChannel.handleBlur}
                              value={
                                validationForAddChannel.values.channelQuote ||
                                ""
                              }
                              invalid={
                                validationForAddChannel.touched.channelQuote &&
                                validationForAddChannel.errors.channelQuote
                                  ? true
                                  : false
                              }
                            />
                            {validationForAddChannel.touched.channelQuote &&
                            validationForAddChannel.errors.channelQuote ? (
                              <FormFeedback type="invalid">
                                {validationForAddChannel.errors.channelQuote}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <label htmlFor="input">Upload Image</label>
                          <Row>
                            <Col md="8">
                              <input
                                id="input"
                                type="file"
                                name="Image_URL"
                                accept="image/*"
                                className="form-control form-control-md inputFile"
                                style={{ display: "none" }}
                                onChange={handleChange}
                              />
                              <Button
                                color="primary"
                                onClick={() => {
                                  var inputFile =
                                    document.querySelector("#input")
                                  inputFile.click()
                                }}
                                style={{
                                  position: "absolute",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <i
                                  className="mdi mdi-image"
                                  style={{
                                    marginRight: "5px",
                                    fontSize: "15px",
                                  }}
                                ></i>
                                Choose Image
                              </Button>{" "}
                              <br />
                            </Col>

                            <Col md="1">
                              {isImageThere && !iconThumbnail ? (
                                <Spinner />
                              ) : (
                                <ImageThumbnail
                                  onClick={() => {
                                    setPreviewImage({
                                      visible: true,
                                      key: 0,
                                    })
                                  }}
                                  imgeUrl={
                                    iconThumbnail || channelDetails.Image_URL
                                  }
                                />
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="8">
                          {
                             openCrop && (
                                <CropEasy
                                  image_url={image_url}
                                  imageName={file.name}
                                  image_storagePath={
                                    CLOUD_STORAGE.CHANNEL.IMAGE
                                  }
                                  thumbnail_storagePath={
                                    CLOUD_STORAGE.CHANNEL.THUMBNAIL
                                  }
                                  setOpenCrop={setOpenCrop}
                                  setImageUrl={setImage_Url}
                                  setFile={setFile}
                                  setIconThumbnail={setIconThumbnail}
                                  ImageHeightWidth={HeightWidth}
                                  setImageThere={setImageThere}
                                  ImageType="CHANNEL"
                                />
                              )
                            }
                        </Col>
                      </Row>

                      <br />
                      <Row>
                        <Col md="2"></Col>
                        {/* <Col md="8">
                        (
                        null
                          // <img src={channelDetails.Image_URL} height="250px" />
                        ) : }
                      </Col> */}
                      </Row>

                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Channel Description
                            </Label>
                            {/* <Input
                                                    name="channelDescription"
                                                    placeholder="Channel Description"
                                                    type="textarea"
                                                    className="form-control"
                                                    id="validationCustom01"
                                                    onChange={validationForAddChannel.handleChange}
                                                    onBlur={validationForAddChannel.handleBlur}
                                                    value={validationForAddChannel.values.channelDescription || ""}
                                                    invalid={
                                                    validationForAddChannel.touched.channelDescription && validationForAddChannel.errors.channelDescription ? true : false
                                                    }
                                                    />
                                                    {validationForAddChannel.touched.channelDescription && validationForAddChannel.errors.channelDescription ? (
                                                    <FormFeedback type="invalid">{validationForAddChannel.errors.channelDescription}</FormFeedback>
                                                    ) : null} */}

                            {/* <Form method="post"> */}
                            <CKEditor
                              editor={ClassicEditor}
                              // id = "validationCustom01"
                              name="channelDescription"
                              data={channelDetails.channelDescription}
                              config={{
                                removePlugins: [
                                  "EasyImage",
                                  "ImageUpload",
                                  "MediaEmbed",
                                  "Essentials",
                                  "Table",
                                  "Table Toolbar",
                                  "CkFinderUploadAdapter",
                                  "CKFinder",
                                  "Autoformat",
                                  "Link",
                                  "BlockQuote",
                                ],
                              }}
                              onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log('Editor is ready to use!', editor);
                                setItem(channelDetails.channelDescription)
                              }}
                              onChange={(event, data) => {
                                setItem(data.getData())
                                dispatch(handleChangesHappend(true))
                              }}

                              //onBlur = {validationForAddChannel.handleBlur}
                              //    invalid = {validationForAddChannel.data.channelDescription && validationForAddChannel.errors.channelDescription ? true : false}
                            />
                            {/* {validationForAddChannel.touched.channelDescription && validationForAddChannel.errors.channelDescription ? (
                                                    <FormFeedback type="invalid">{validationForAddChannel.errors.channelDescription}</FormFeedback>
                                                    ) : null}  */}
                          </FormGroup>
                        </Col>
                      </Row>
                      {showPreview && (
                        <Container>
                          <Row>
                            <Card>
                              <CardBody>
                                <ChannelDescriptionFirstPage
                                  DESCRIPTION={
                                      item || "Channel Description here"
                                  }
                                  QUOTE={
                                    (validationForAddChannel.values.channelQuote) ||
                                    "Channel Quote here"
                                  }
                                  IMAGEPATH={
                                    image_url || channelDetails.Image_URL
                                    
                                  }
                                  CHANNELNAME={
                                    (channelDetails &&
                                      channelDetails.channelName) ||
                                    "Channel Name here"
                                  }
                                  CHANNELTITLE={
                                    (validationForAddChannel.values.channelDescriptionTitle) ||
                                      "Channel Description Title here"
                                  }
                                />
                              </CardBody>
                            </Card>
                          </Row>
                        </Container>
                      )}
                      <br></br>
                      <Row>
                        <Col md="10"></Col>
                        <Col md="2">
                          <Button
                            block
                            color="primary"
                            className="mt-1"
                            onClick={() => {
                              setShowPreview(e => !e)
                            }}
                          >
                            {showPreview ? "Back" : "Preview"}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          {/* repeater */}
                          <div
                            data-repeater-list="outer-group"
                            className="outer"
                          >
                            <div data-repeater-item className="outer">
                              <div className="inner-repeater mb-4">
                                <table
                                  style={{ width: "100%" }}
                                  className="keyObservation"
                                >
                                  <tbody>
                                    {rows1.map((formRow, key) => (
                                      <tr key={key}>
                                        <td>
                                          <Row className="mb-2">
                                            <Label>
                                              Key Observations {++key}
                                            </Label>
                                            <Col
                                              xl="11"
                                              style={{ cursor: "text" }}
                                            >
                                              {/* <Input
                                            // id = {`keyObservation_${++key}`}
                                            type="textarea"
                                            className="inner form-control"
                                            placeholder = {`key observation - ${++key}`}
                                            maxLength = "250"
                                            // onChange={ (e) => {
                                                // var allKeys = document.querySelectorAll(".keyObservation textarea")
                                                // console.log(allKeys)
                                                // keyObservationManager()
                                            // } }
                                            /> */}
                                              <CKEditor
                                                //  placeholder = {`key obervation - ${++key}`}
                                                id={formRow.id}
                                                editor={ClassicEditor}
                                                data={
                                                  formRow.content
                                                  // channelDetails.keyObservations !==
                                                  //   undefined
                                                  //   ? channelDetails
                                                  //     .keyObservations[--key] ||
                                                  //   ""
                                                  //   : ""
                                                }
                                                config={{
                                                  removePlugins: [
                                                    "EasyImage",
                                                    "ImageUpload",
                                                    "MediaEmbed",
                                                    "Essentials",
                                                    "Table",
                                                    "Table Toolbar",
                                                    "CkFinderUploadAdapter",
                                                    "CKFinder",
                                                    "Autoformat",
                                                    "Link",
                                                    "BlockQuote",
                                                  ],
                                                  uiColor: "#9AB8F3",
                                                }}
                                                onReady={editor => {
                                                  // You can store the "editor" and use when it is needed.
                                                  //console.log('Editor is ready to use!', editor);
                                                }}
                                                onChange={(e, data) => {
                                                  // const data = editor.getData();
                                                  // validationForAddChannel.handleChange
                                                  // const data1 = data.getData();
                                                  // console.log(data1);
                                                  // var allkeys = document.querySelectorAll(".ck-content")
                                                  // console.log(allkeys[1].innerHTML)
                                                  keyObservationManager()
                                                  setrows1(e =>
                                                    e.map(rw => {
                                                      if (rw.id === formRow.id)
                                                        return {
                                                          id: formRow.id,
                                                          content:
                                                            data.getData()
                                                              ? data.getData()
                                                              : formRow.content,
                                                        }
                                                      else return rw
                                                    })
                                                  )
                                                  dispatch(
                                                    handleChangesHappend(true)
                                                  )
                                                }}
                                              />
                                            </Col>
                                            <Col xl="1">
                                              {/* <Button
                                              color="primary"
                                              className="btn-block inner"
                                              id="unknown-btn"
                                              style={{ width: "100%" }}
                                              disabled={
                                                formRow.id > 1 ? false : true
                                              }
                                              onClick={e => {
                                                handleRemoveRow(formRow.id)
                                              }}
                                            >
                                              {" "}
                                              Delete{" "}
                                            </Button> */}
                                              {rows1.length > 1 ? (
                                                <i
                                                  className="mdi mdi-trash-can d-block font-size-24"
                                                  style={{
                                                    color: "#FF6060",
                                                  }}
                                                  onClick={e => {
                                                    handleRemoveRow(formRow.id)
                                                    dispatch(
                                                      handleChangesHappend(true)
                                                    )
                                                  }}
                                                ></i>
                                              ) : (
                                                true
                                              )}
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <Button
                                  disabled={rows1.length < 10 ? false : true}
                                  onClick={() => {
                                    handleAddRowNested()
                                    dispatch(handleChangesHappend(true))
                                  }}
                                  color="primary"
                                  className="mt-1"
                                >
                                  Add New
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md="5">
                          {
                            <>
                              <div>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginRight: "7px",
                                  }}
                                >
                                  {" "}
                                  Channel Status:{" "}
                                </span>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-2 mb-sm-8 mb-4"
                                  onColor="#3d57a3"
                                  width={100}
                                  onChange={e => {
                                    setIsChannelDisabled(p => !p)
                                    dispatch(handleChangesHappend(true))
                                  }}
                                  checked={isChannelDisabled}
                                />
                                <span
                                  style={{
                                    color: "#C0392B",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  (Enable/Disable this channel)
                                </span>
                              </div>
                            </>
                          }
                        </Col>
                        <Col md="3"></Col>
                        <Col md="2">
                          <FormGroup className="mb-3">
                            <Button
                              color="danger"
                              onClick={() => {
                                dispatch(handleChangesHappend(false))
                                history.push("/")
                              }}
                              block
                            >
                              Cancel
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          {/* <FormGroup className="mb-3">
                          <Button color="primary" type="submit" block>
                            Next
                          </Button>
                        </FormGroup> */}

                          <Button
                            color="primary"
                            type="submit"
                            disabled={isChannelAdded}
                            block
                          >
                            {isChannelAdded ? "" : "Update"}
                            {isChannelAdded ? (
                              <Spinner type="border" size="sm" />
                            ) : null}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  )
}
// function readFile(file) {
//   return new Promise(resolve => {
//     const reader = new FileReader()
//     reader.addEventListener("load", () => resolve(reader.result), false)
//     reader.readAsDataURL(file)
//   })
// }

export default ViewChannel
