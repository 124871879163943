import { takeEvery, put, call,all,fork  } from "redux-saga/effects";
import {GET_EDITOR_DETAILS} from "./actionTypes"

import {getEditorDetailsSuccess, getEditorDetailsFail} from "./actions"
import {getEditorDetails} from "../../helpers/fakebackend_helper"

function* fetchEditorDetails({id}) {
    try {
      const response = yield call(getEditorDetails , id)
      yield put(getEditorDetailsSuccess(response))
    } catch (error) {
      yield put(getEditorDetailsFail(error))
    }
  }

    
  export function* watchFetchEditorDetails(){
    yield takeEvery(GET_EDITOR_DETAILS, fetchEditorDetails)
  }
  function* ContentEditor() {
   
    yield all([fork(watchFetchEditorDetails)]);
   
  }
  export default ContentEditor