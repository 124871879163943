import NotFound from "components/Common/NotFound/NotFound.component";
import { SUPER_ADMIN } from "config/AlertMessage.config";
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Spinner,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const DemoTransaction = () => {
  const [isDataAvail, setIsDataAvail] = useState(true)
  useEffect(() => {
    var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)
    // if (scorecardsList[0]) {
    //   var search_bar = document.querySelector("#search-bar-0")
    //   search_bar.setAttribute("autoComplete", "off")
    //   clearTimeout(WaitForData)
    // }

  }, [])

  // console.log("You are here...")

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Purchase History</title>
        </MetaTags>
        <div className="container-fluid">
          <div style={
            {
              width: "100%",
              textAlign: "center",
              height: "400px"
            }
          } >
            {
              !isDataAvail ?
                <NotFound Message={SUPER_ADMIN.EMPTY_PURCHASE_HISTORY} Icon={() => (
                  <i className="bx bx-cart-alt"
                    style={{
                      borderBottom: "1px solid #000"
                    }}
                  ></i>
                )} />
                :
                <Spinner
                  style={{
                    width: "60px", height: "60px",
                    margin: "calc(200px - 30px)",

                  }}
                  role="grid"
                  type="border"
                  color="primary"
                />
            }
          </div>
          {/* <Row>       
            <Col lg={12} style= {{margin: "auto"}}>
              <Card>
                <CardBody >
                  <div className="table-responsive">
                    
                    

                    <div className="table-responsive">
                      <Table className="align-middle mb-0">

                        <thead>
                          <tr>
                           
                            <th>Date</th>
                            <th>Purchased By</th>
                            <th>Credit Purchased</th>
                            <th>Discount Availed</th>
                            <th>Amount Paid</th>
                           
                            <th>Payment Mode</th>
                            <th>Invoice</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                           
                            <td>10/21/2021</td>
                            <td>Digital Authority Partner</td>
                            <td>20</td>
                            <td>1%</td>
                            <td>$1.45</td>
                            
                            <td>Visa</td>
                            <td>
                              <button type="button" className="btn btn-light btn-sm" style = {{background:'#3D57A3',color:'white', }}>Generate</button>
                            </td>
                          </tr>
                          <tr>
                         
                            <td>08/22/2020</td>
                            <td>SmartSites</td>
                            <td>50</td>
                            <td>5%</td>
                            <td>$3</td>
                            
                            <td>Mastercard</td>
                            <td>
                              <button type="button" className="btn btn-light btn-sm" style = {{background:'#3D57A3',color:'white', }}>Generate</button>
                            </td>
                          </tr>
                          <tr>
                        
                            <td>12/10/2019</td>
                            <td>Searchbloom</td>
                            <td>10</td>
                            <td>0</td>
                            <td>$0.50</td>
                           
                            <td>Paypal</td>
                            <td>
                              <button type="button" className="btn btn-light btn-sm" style = {{background:'#3D57A3',color:'white', }}>Generate</button>
                            </td>
                          </tr>
                          <tr>
                         
                            <td>09/28/2020</td>
                            <td>Ignite Visibility</td>
                            <td>60</td>
                            <td>7%</td>
                            <td>$5</td>
                            
                            <td>COD</td>
                            <td>
                              <button type="button" className="btn btn-light btn-sm" style = {{background:'#3D57A3',color:'white', }}>Generate</button>
                            </td>
                          </tr>
                          <tr>
                        
                            <td>09/28/2020</td>
                            <td>Uplers</td>
                            <td>100</td>
                            <td>20%</td>
                            <td>$15</td>
                            
                            <td>Mastercard</td>
                            <td>
                              <button type="button"className="btn btn-light btn-sm" style = {{background:'#3D57A3',color:'white', }}>Generate</button>
                            </td>
                          </tr>
                          <tr>
                        
                            <td>09/28/2020</td>
                            <td>Sure Oak</td>
                            <td>200</td>
                            <td>50%</td>
                            <td>$20</td>
                            
                            <td>Paypal</td>
                            <td>
                              <button type="button" className="btn btn-light btn-sm" style = {{background:'#3D57A3',color:'white', }}>Generate</button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>

                  </div>
                </CardBody>
              </Card>
              </Col>
            </Row> */}
        </div>
      </div>
    </React.Fragment>
  )
}
export default DemoTransaction
