import { color, fontSize, height } from "@mui/system"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Grid } from "@mui/material"
import img from "../../../../assets/images/reportScorcard/executiveCardimg.PNG"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// import {mainDivStyle} from './pageFifth.cssConfig'

import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
} from "reactstrap"
import { Opacity } from "@mui/icons-material"
import Footer from "components/VerticalLayout/Footer"
import ruff from "../../../../assets/images/reportScorcard/Capture-removebg-preview.png"
import smack from "../../../../assets/images/reportScorcard/Capturetemp-removebg-preview.png"
import { REPORT_PAGE, REPORT } from "../config/style.config"
import Knob from "../../chart/knob/knob"
import BubbleChart from "./component/BubbleChart/BubbleChart.componet"
import { useSelector, useDispatch } from "react-redux"

import {
  // getReportTemplate,
  getScorecardReport
} from "store/actions"
import TacticEvaluated from "./component/Pages/TacticEvaluated.component"
import { useParams } from "react-router-dom"

const ChannelTacticEvaluated = () => {

  const [channelData, setChannelData] = useState([])
  const params = useParams()

  const dispatch = useDispatch()

  const { scorecardReport_data } = useSelector(state => ({
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
  }))

  useEffect(() => {
    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
    // dispatch(getScorecardReport(params.scorecardId))
  }, [dispatch])

  useEffect(() => {
    setChannelData([])
    if (scorecardReport_data && scorecardReport_data.selectedChannels) {
      var GetChannel = scorecardReport_data.selectedChannels
        .filter(channel => channel.channelId === params.channelId)
      setChannelData(GetChannel)
    }
  }, [params.channelId, scorecardReport_data])

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Mambo -Report</title>
        </MetaTags>
        <Container
          fluid={true}
          style={{
            transform: "scale(.8) translate(-130px, -132px) ",
            width: "1400px",
          }}
        >
          <Row>
            <Col className="col-12 m-auto ">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="10">
                      {/* {
                        scorecardReport_data.selectedChannels &&
                        scorecardReport_data.selectedChannels.map(channel => ( */}
                      {
                        channelData[0] ?

                          <TacticEvaluated ChannelData={channelData[0]} />
                          :
                          null
                      }

                      {/* ))
                      } */}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ChannelTacticEvaluated
