import { call, put, takeEvery } from "redux-saga/effects"

import {
    AddChannelSuccess,
    AddChannelFail
} from "./actions"

import {
    ADD_NEW_CHANNEL
} from "./actionTypes"

import {
    AddChannel
} from "../../helpers/fakebackend_helper"


function* onAddNewChannel( { payload: data } ) {

    try{
        const response = yield call(AddChannel, data)
        yield put(AddChannelSuccess(response))
    }catch(error) {
        yield put(AddChannelFail(error))
    }

}

function* AddNewChannelSaga(){
    yield takeEvery(ADD_NEW_CHANNEL, onAddNewChannel)
}

export default AddNewChannelSaga