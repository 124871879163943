import {
  // Global State for Coaching Page
  IS_COACHING_STARTED,
  IS_UPDATE_SELF_REPORT_SCORECARD_COACHING_STARTED
} from "./actionTypes.js"

// Action to set the global state for coaching (Tour)
export const startCoachingForAssessment = data => ({
  type: IS_COACHING_STARTED,
  payload: data,
})

export const startCoachingForSelfReportUpdateScorecard = data => ({
  type: IS_UPDATE_SELF_REPORT_SCORECARD_COACHING_STARTED,
  payload: data
})