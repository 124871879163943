import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';  // Added Meta Tag npm Package
import "../../../AccountUser/Report/config/style.config.css"
import { REPORT_PAGE, REPORT } from 'pages/AccountUser/Report/config/style.config';
import ReactHtmlParser from "react-html-parser"

import {
    Table,
    Container, Row, Col,
    Alert,
    Card, CardBody, CardTitle, FormGroup, Spinner, Form, Input, Button
} from "reactstrap";
import { getChannelDetails, getScorecardReport } from "store/actions"
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom"



const ChannelDescriptionFirstPage = (props) => {

    // const dispatch = useDispatch()

    const PROPS = props;

    const {
        DESCRIPTION,
        QUOTE,
        IMAGEPATH,
        CHANNELNAME,
        CHANNELTITLE
    } = PROPS

    // console.log("in ChannelDescriptionFirstPage")

    return (
        <>
            <div className="page-content" >
                {/* <MetaTags>
                    <title> Mambo -Report</title>
                </MetaTags> */}
              
                                    <Row>
                                        <Col md="12">
                                            <div style={{ ...REPORT_PAGE }}>

                                                <Row>
                                                    <Col md="12" >
                                                        <div style={{
                                                            width: "100%",
                                                            height: '528px',
                                                            // border: "1px solid grey",
                                                        }}>
                                                            <img src={IMAGEPATH} style={{
                                                                width: '100%',
                                                                height: '528px'
                                                            }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <h2 style={{
                                                            color:'#0D0F11',
                                                            fontWeight: 700,
                                                            maxHeight:'65px',
                                                            width:'100%',
                                                            margin: '10px 0 5px 15px',
                                                            overflow:'hidden'
                                                            // border:'1px solid black'
                                                        }}><span>{CHANNELTITLE}</span>
                                                        </h2>

                                                        <div style={{
                                                            width: '100%',
                                                            height: '410px',
                                                            overflow: 'hidden',
                                                            padding: '0 6px 6px 6px',
                                                            margin: '5px 0 2px 20px',
                                                            // border: "1px solid grey",  
                                                        }}
                                                        className="--channel-first-page"
                                                        >
                                                            <div style={{
                                                                height: '400px',
                                                                fontSize: REPORT.FONT_SIZE.MEDIUM,
                                                                fontFamily:REPORT.FONT_FACE.HEADING,
                                                                margin: 0,
                                                                // border: "1px solid grey", 
                                                                overflow:'hidden',
                                                                listStyleType:'none',
                                                                color:'#0D0F11'

                                                            }}
                                                            className="SavedDescription"
                                                            >
                                                                <li>
                                                                    {ReactHtmlParser(DESCRIPTION)}
                                                                </li>
                                                                
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="6" >
                                                        <div style={{
                                                            width: '100%',
                                                            height: '526px',
                                                            backgroundColor: REPORT.COLOR.PRIMARY_BG,
                                                            // border: '1px solid black',
                                                        }}>
                                                            <h1 style={{
                                                                fontSize: '1.42188rem',
                                                                fontWeight: 600,
                                                                color: '#0D0F11',
                                                                fontFamily:REPORT.FONT_FACE.PARAGRAPH,
                                                                fontStyle: 'italic',
                                                                lineHeight: 1.6,
                                                                padding: '125px 15px 0 10px',
                                                                textAlign: 'right'
                                                            }}>
                                                                {`${QUOTE}`.toUpperCase()}
                                                            </h1>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Col md="12" style={{
                                                    // border: '1px solid black'
                                                    position: "absolute",
                                                    bottom: 0,
                                                    zIndex: 100,
                                                }}>
                                                    <div style={{
                                                        width: '100%',
                                                        fontSize: REPORT.FONT_SIZE.MEDIUM,
                                                        padding: '5px 5px 5px 40px',
                                                        backgroundColor: REPORT.COLOR.FOOTER,
                                                        fontFamily:REPORT.FONT_FACE.HEADING,
                                                        letterSpacing:'2px',
                                                        opacity: 0.9,
                                                        fontWeight: 600,
                                                        color: 'white'
                                                    }}>{CHANNELNAME}</div>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                             
            </div >
        </>
    );
}

export default ChannelDescriptionFirstPage;