export const REPORT_PAGE = {
    width: "816px",
    height: "1056px",
    // border: "1px solid grey",
    // boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, .1)",
    position: "relative",
    // transform: "scale(.8) translate(-100px, -132px) ",
}

// Report theme:
export const REPORT = {
    COLOR: {
        PRIMARY: "#000000",
        SECONDARY: "#ffffff",
        PRIMARY_BG: "#eee1bb",
        SECONDARY_BG: "#f76c5b",
        PRIORITY_1: "#abd1be",
        PRIORITY_2: "#a47d42",
        PRIORITY_3: "#589296",
        PRIORITY_4: "#f76c5b",
        FOOTER: "#f88f82",
    },
    FONT_SIZE: {
        XSMALL: "14px",
        SMALL: "12px",
        MEDIUM: "14px",
        LARGE: "16px",
        XLARGE: "38px",
    },
  
    FONT_FACE: {
        HEADING: "'Montserrat', sans-serif",
        PARAGRAPH: "'Roboto', sans-serif"
    },
    IMAGE: {
        BACKGROUND: {
            HEIGHT: 1056,
            WIDTH: 816,
        },
        EXECUTIVE: {
            HEIGHT: 3300,
            WIDTH: 1060,
        },
        PRIORITY: {
            HEIGHT: 1203,
            WIDTH: 1019,
        },
        CHANNEL: {
            HEIGHT: 1656,
            WIDTH: 2550,
        },
        KEY_OBSERVATIONS: {
            HEIGHT: 781,
            WIDTH: 588,
        },
        QUICK_WINS: {
            HEIGHT: 1275,
            WIDTH: 956,
        },
        KEY_TAKEAWAYS: {
            HEIGHT: 3300,
            WIDTH: 1238
        }
    },
    PRIORITY_CARDS_HEIGHT: {
        MAIN: 384,
        DIV_ONE: 90,
        DIV_TWO: 65,
        DIV_THREE: 195,
      },
}

export const RECOMMENDATIONS = {
    fontSize: "11px",
     padding:'12px',
}

export const MATURITY = {
    fontWeight:600,
    fontSize:'18px',
    fontFamily:"'Roboto', sans-serif",
    color: "#292929"
}

export const IMAGE_SIZE = {
    height:'130px', 
    width:'130px'
}