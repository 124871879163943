import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useHistory } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword, clearForgetPasswordError, clearForgetPasswordSuccessMessage } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
// import logo from "../../assets/images/logo.svg";
// import logo from "assets/images/logo.png"
import Logo from "assets/images/MamboScorecardLogo/Logo.png"
const ForgetPasswordPage = props => {
  const dispatch = useDispatch()

  const [isResetClick, setIsResetClick] = useState(false)
  const [isSuccess, setIsSuccess] = useState(true)
  const history = useHistory()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),

    onSubmit: values => {
      if(!isSuccess){
        // <Link to="login" className="font-weight-medium text-primary"></Link>
        dispatch(clearForgetPasswordSuccessMessage())
        history.push("/login")

      }else{
        setIsResetClick(true)
        // console.log("We are passing this value...", values)
        dispatch(userForgetPassword(values, props.history))
      }
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  useEffect(() => {
    if(forgetError){
      setIsResetClick(false)
    }
  },[forgetError])

  useEffect(() => {
    if(forgetSuccessMsg){
      // setIsResetClick(false)
      setIsSuccess(false)
    }
  },[forgetSuccessMsg])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Mambo - Forgot Password</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden"
                style={{
                  boxShadow: "0px 0px 20px 0px rgba(61, 87, 163, .1)",
                }}>
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row
                    style={{
                      backgroundColor: "#fff",
                      borderBottom: "2px solid rgba(61, 87, 163, .16)",
                    }}
                  >
                    <Col
                      xs={4}
                      style={{
                        backgroundColor: "rgba(61, 87, 163, .16)",
                        borderRadius: "0px 50px 50px 0px",
                      }}
                    >
                      <div className="text-primary p-4">
                        <img
                          src={Logo}
                          alt=""
                          // className="rounded-circle"
                          height="50"
                        />
                        {/* <h5 className="text-primary">Welcome !</h5>
                        <p>Sign in to continue to Mambo.</p> */}
                      </div>
                    </Col>
                    <Col xs={8} className="col-5 align-self-end">
                      <h3
                        className="text"
                        style={{ marginLeft: "20px", color:"#05727B" }}
                      >
                        Forgot Password ?
                      </h3>
                      <p
                        style={{
                          marginLeft: "20px",
                          fontSize: "12px",
                          marginTop: "-4px",
                        }}
                      >
                        No worries, we`ll send you reset instuctions.
                      </p>
                      {/* <img src={profile} alt="" className="img-fluid" /> */}
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        {/* <span className="avatar-title rounded-circle bg-light"> */}
                        {/* <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span> */}
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {
                            "A password reset link has been sent to your given email address." 
                        }
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3" style={{ marginTop: '-15px' }}>
                        <Label
                          className="form-label"
                          style={{ color: "#05727B" }}
                        >
                          Email
                        </Label>
                        <Input
                          autoComplete="off"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          disabled= {!isSuccess ? true : false}
                          onFocus= {() => {dispatch(clearForgetPasswordError())}}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            style={{background:'#05727B', color:'white'}}
                            className="btn w-md "
                            type="submit"
                          >
                            {
                            isSuccess ?
                            <div>
                            {isResetClick ? "" : "Reset"}
                            {isResetClick ?
                              <Spinner
                                // style={{ width: "0.8rem", height: "0.8rem" }}
                                type="border"
                                size="sm"
                              />
                              : null
                            }
                            </div>
                            :
                             "Back To LogIn"
                            }

                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p >
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text" style = {{color:"#05727B"}}>
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} MAMBO
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
