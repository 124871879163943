import {
    GET_ACCOUNT_TABLE_SUCCESS,
    GET_ACCOUNT_TABLE_FAIL,

    GET_ACCOUNT_DETAILS_SUCCESS,
    GET_ACCOUNT_DETAILS_FAIL,
    CHANGE_ACCOUNT_DATA,

    GET_ACCOUNT_USERS_SUCCESS,
    GET_ACCOUNT_USERS_FAIL,
    CLEAR_ACCOUNT_OWNER_DETAILS,
    UPDATE_ACCOUNT_TABLE,
    CLEAR_ACCOUNT_DATA_STATE,
    UPDATE_ACCOUNT_DETAILS_STATE,


} from "./actionTypes"

const INIT_STATE = {
    accountData: [],
    accountDetails: [],

};

const SuperAdmin = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ACCOUNT_TABLE_SUCCESS:
            return {
                ...state,
                accountData: action.payload
            };

        case GET_ACCOUNT_TABLE_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_ACCOUNT_TABLE:
            return {
                ...state,
                accountData: action.payload
            };

        case CLEAR_ACCOUNT_DATA_STATE:
            return {
                ...state,
                accountData: action.payload
            };

        case GET_ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                accountDetails: action.payload
            };

        case GET_ACCOUNT_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case CLEAR_ACCOUNT_OWNER_DETAILS: {
            return {
                ...state,
                accountDetails: {}
            }
        }
        case UPDATE_ACCOUNT_DETAILS_STATE: {
            return {
                ...state,
                accountDetails: action.payload
            };
        }


        default:
            return state;
    }

};

export default SuperAdmin