import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
  UncontrolledAlert,
  FormGroup,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import firebase from "firebase/compat";

import avatar from "../../assets/images/users/avatar-2.jpg";
// actions
import { editProfile, resetProfileFlag, userForgetPassword, getAccountHolderUserDetails, clearForgetPasswordSuccessMessage, clearForgetPasswordError } from "../../store/actions";

const UserProfile = props => {
  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);
  const [isClicked, setIsClicked] = useState(false)
  const [data, setData] = useState({})

  const { forgetError, forgetSuccessMsg, accountUserDetails } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    accountUserDetails: state.AccountHolderUsersReducer.accountUserDetails,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  useEffect(() => {
    dispatch(getAccountHolderUserDetails(JSON.parse(localStorage.getItem("authUser")).uid))

    return () => {
      dispatch(clearForgetPasswordSuccessMessage())
      dispatch(clearForgetPasswordError())
    }
  }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username);
        setemail(obj.email);
        setidx(obj.uid);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, forgetSuccessMsg]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // username: name || '',
      // idx : idx || '',
      email: email,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      setIsClicked(true)
       console.log("Email Send Successfully..!", values)
      dispatch(userForgetPassword(values, props.history));
    }
  });

  // const HandleClicked = () => {
  //   setIsClicked(true)
  // }

  useEffect(() => {
    if (forgetSuccessMsg || forgetError) {
      setIsClicked(false)
    }
  }, [forgetSuccessMsg, forgetError])


  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdTokenResult().then(token => {
          setData(token.claims)
          // console.log(token.claims)
        }).catch(err => {

        })
      }
    })

    return () => {
      setData(null)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Profile</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="MAMBO" breadcrumbItem="Profile" /> */}
          {
            forgetError && forgetError ?
              <UncontrolledAlert
                color="danger"
              // onAnimationStart={setIsClicked(false)}
              // onClick={() => setIsClicked(false)} 
              >
                {forgetError}
              </UncontrolledAlert>
              :
              null
          }

          {
            forgetSuccessMsg && forgetSuccessMsg ?
              <UncontrolledAlert
                color="success"
              // onAnimationStart={setIsClicked(false)}
              // onClick={() => setIsClicked(false)} 
              >
                {forgetSuccessMsg}
              </UncontrolledAlert>
              :
              null
          }
          <Row>
            <Col lg="12">
              <Card>

                <CardBody>
                  <div className="d-flex">
                    {/* <div className="mx-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div> */}
                    <div className="flex-grow-1 align-self-center mx-3">
                      <div className="text-muted">
                        <h5 className="fs-4 fw-bold m-0">{name}</h5>
                        {
                          data.superAdmin ?
                            <p className="m-0" style={{ fontSize: '14px' }}>Super Admin</p>
                            :
                            data.accountUser ?
                              <p className="m-0" style={{ fontSize: '14px' }}>Account User of {accountUserDetails.creatorName}</p>
                              :
                              data.contentEditor ?
                                <p className="m-0" style={{ fontSize: '14px' }}>Content Editor</p>
                                :
                                data.admin ?
                                  <p className="m-0" style={{ fontSize: '14px' }}>Account Owner</p>
                                  :
                                  null
                        }
                        <p className="mb-0">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                    <div className="p-2">

                      <Row>
                        <Col md="12">
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <div className="text-center mx-3">
                              <FormGroup className="mb-3">
                                <Button
                                  // color="primary"
                                  type="submit"
                                  disabled={isClicked ? true : false}
                                  style={{ 
                                    width: '130px',
                                    background:'#05727B',
                                    border:'#05727B'
                                  }}
                                // onClick={HandleClicked}
                                >

                                  {isClicked ? "" : "Reset Password"}
                                  {isClicked ? (
                                    <Spinner type="border" size="sm" />
                                  ) : null}
                                </Button>
                              </FormGroup>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <h4 className="card-title mb-4">Change User Name</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username ? true : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  history: PropTypes.object,
};

export default withRouter(UserProfile);
