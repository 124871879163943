import { CHANGE_LAYOUT } from "store/layout/actionTypes"
import {
    CLEAR_CREATE_SCORECARD_REPORT,
    CLEAR_GET_SCORECARD_REPORT,
    CLEAR_UPDATE_SCORECARD_REPORT,

    CREATE_SCORECARD_REPORT,
    CREATE_SCORECARD_REPORT_FAIL,
    CREATE_SCORECARD_REPORT_SUCCESS,

    GET_SCORECARD_REPORT,
    GET_SCORECARD_REPORT_FAIL,
    GET_SCORECARD_REPORT_SUCCESS,

    UPDATE_SCORECARD_REPORT,
    UPDATE_SCORECARD_REPORT_SUCCESS,
    UPDATE_SCORECARD_REPORT_FAIL,
    UPDATE_GET_SCORECARD_REPORT,
    SET_WINDOW_INACTIVITY,
    UNSAVED_CHANGES,
    CHNAGES_HAPPEND,
    GET_ROUTES,
    CLOSE_REPORT_EDITING,

    SET_SESSION_TIMEOUT,
    IS_USER_EXITED


} from "./actionTypes"

export const getScorecardReport = (scorecardId) => {
    return {
        type: GET_SCORECARD_REPORT,
        scorecardId
    }
}

export const getScorecardReportSuccess = (data) => {
    return {
        type: GET_SCORECARD_REPORT_SUCCESS,
        payload: data
    }
}

export const getScorecardReportFail = (error) => {
    return {
        type: GET_SCORECARD_REPORT_FAIL,
        payload: error
    }
}

export const clearScorecardReport = () => {
    return {
        ...state,
        type: CLEAR_GET_SCORECARD_REPORT
    }
}

export const updateGetScorecardReport = (data) => {
    return {
        type: UPDATE_GET_SCORECARD_REPORT,
        payload: data
    }
}


// Create Scorecard Report:
export const createScorecardReport = (scorecardId, data) => {
    return {
        type: CREATE_SCORECARD_REPORT,
        scorecardId,
        payload: data
    }
}

export const createScorecardReportSuccess = (data) => {
    return {
        type: CREATE_SCORECARD_REPORT_SUCCESS,
        payload: data
    }
}

export const createScorecardReportFail = (error) => {
    return {
        type: CREATE_SCORECARD_REPORT_FAIL,
        payload: error
    }
}

export const clearCreateScorecardReport = () => {
    return {
        type: CLEAR_CREATE_SCORECARD_REPORT
    }
}

export const UpdateScorecardReport = (scorecardId, data) => {
    return {
        type: UPDATE_SCORECARD_REPORT,
        scorecardId,
        payload: data

    }

}

export const UpdateScorecardReportSuccess = (data) => {
    return {
        type: UPDATE_SCORECARD_REPORT_SUCCESS,
        payload: data
    }

}

export const UpdateScorecardReportFail = (error) => {
    return {
        type: UPDATE_SCORECARD_REPORT_FAIL,
        payload: error

    }
}

export const ClearUpdateScorecardReport = () => {
    return {
        type: CLEAR_UPDATE_SCORECARD_REPORT
    }
}

// HANDLE WINDOW IN-ACTIVITY:
export const HandleWindowInActivity = (data) => {
    return {
        type: SET_WINDOW_INACTIVITY,
        payload: data
    }
}

// HANDLE SESSION TIMEOUT:
export const HandleSessionTimeOut = (data) => {
    return {
        type: SET_SESSION_TIMEOUT,
        payload: data
    }
}

// user exit after 20 min inactivity from Report Editing or Take A Survey page
export const HandleIsUserExit = (data) => {
    return {
        type: IS_USER_EXITED,
        payload: data
    }
}

// Handle close editing.
export const handleCloseReportEditing = (data) => {
    return {
        type: CLOSE_REPORT_EDITING,
        payload: data,
    }
}

//Handle Popup 
export const handleUnsavedChanges = (data) => {
    return {
        type: UNSAVED_CHANGES,
        payload: data

    }
}

//Handle Is there any changes:
export const handleChangesHappend = (data) => {
    return {
        type: CHNAGES_HAPPEND,
        payload: data
    }

}

export const GetRoutes = (path) => {
    return {
        type: GET_ROUTES,
        payload: path
    }

}

