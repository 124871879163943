module.exports = {
  google: {
    API_KEY: "AIzaSyCm0SFVyWNLmPhYO-yIBxI43ABVYqE3YXw",
    CLIENT_ID: "959108169907-n8c99jv6o6vfkvc63jinqohr6kc7hke8.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "",
  },
}
