// Add channel
export const ADD_NEW_TACTIC = "ADD_NEW_TACTIC"
export const ADD_TACTIC_SUCCESS = "ADD_TACTIC_SUCCESS"
export const ADD_TACTIC_FAIL = "ADD_TACTIC_FAIL"
export const CLEAR_TACTIC_STATE = "CLEAR_TACTIC_STATE"
export const UPDATE_TACTIC_STATE = "UPDATE_TACTIC_STATE"
export const CLEAR_UPDATE_TACTIC_STATE = "CLEAR_UPDATE_TACTIC_STATE"


///get Edit Tactic
// GEt report template.
export const GET_TACTIC_INFO =  "GET_TACTIC_INFO"
export const GET_TACTIC_INFO_SUCCESS =  "GET_TACTIC_INFO_SUCCESS"
export const GET_TACTIC_INFO_FAIL =  "GET_TACTIC_INFO_FAIL"
export const GET_TACTIC_INFO_CLEAR = "GET_TACTIC_INFO_CLEAR"