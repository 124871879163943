import { takeEvery, put, call,all,fork  } from "redux-saga/effects";

// Login Redux States
import {
    ADD_CONTENT_EDITOR,
} from "./actionTypes"
import {
    addContentEditorSuccess,
    addContentEditorFail,
} from "./actions"
                                      
import { addContentEditor} from "../../helpers/fakebackend_helper";



function* onAddContentEditor({ payload: user}) {
  try {
    const response = yield call(addContentEditor, user);
    yield put(addContentEditorSuccess(response));
  } catch (error) {
    yield put(addContentEditorFail(error));
  }
}

function* addcontenteditorSaga (){
  yield takeEvery(ADD_CONTENT_EDITOR, onAddContentEditor);

}
export default addcontenteditorSaga