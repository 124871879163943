/* ACCOUNT_HOLDER_USERS */
export const GET_ACCOUNT_HOLDER_USERS = "GET_ACCOUNT_HOLDER_USERS"
export const GET_ACCOUNT_HOLDER_USERS_SUCCESS = "GET_ACCOUNT_HOLDER_USERS_SUCCESS"
export const GET_ACCOUNT_HOLDER_USERS_FAIL = "GET_ACCOUNT_HOLDER_USERS_FAIL"
export const UPDATE_ACCOUNT_HOLDER_USERS_STATE = "UPDATE_ACCOUNT_HOLDER_USERS_STATE"
export const CLEAR_ACCOUNT_HOLDER_USERS_STATE = "CLEAR_ACCOUNT_HOLDER_USERS_STATE"

// users details
export const GET_ACCOUNT_HOLDER_USER_DETAILS = "GET_ACCOUNT_HOLDER_USER_DETAILS"
export const GET_ACCOUNT_HOLDER_USER_DETAILS_SUCCESS = "GET_ACCOUNT_HOLDER_USER_DETAILS_SUCCESS"
export const GET_ACCOUNT_HOLDER_USER_DETAILS_FAIL = "GET_ACCOUNT_HOLDER_USER_DETAILS_FAIL"
export const CLEAR_ACCOUNT_HOLDER_USER_DETAILS = "CLEAR_ACCOUNT_HOLDER_USER_DETAILS"
export const UPDATE_ACCOUNT_HOLDER_USERS_DETAILS_STATE = "UPDATE_ACCOUNT_HOLDER_USERS_DETAILS_STATE"
