import { takeEvery, put, call,all,fork  } from "redux-saga/effects";


import {GET_ACCOUNT_TABLE, GET_ACCOUNT_DETAILS,} from "./actionTypes";

import {getAccountDataSuccess, getAccountDataFail, getAccountDetailsSuccess, getAccountDetailsFail} from "./actions"
import {getAccountData, getAccountDetails} from "../../helpers/fakebackend_helper"


function* fetchDemoData() {
    try {
      const response = yield call(getAccountData)
      yield put(getAccountDataSuccess(response))
    } catch (error) {
      yield put(getAccountDataFail(error))
    }
  }
  function* fetchAccountDetails({id}) {
    try {
      const response = yield call(getAccountDetails , id)
      yield put(getAccountDetailsSuccess(response))
    } catch (error) {
      yield put(getAccountDetailsFail(error))
    }
  }

  
  
                                        
  export function* watchFetchDemoData() {
    yield takeEvery(GET_ACCOUNT_TABLE, fetchDemoData);
  }
    
  export function* watchFetchAccountDetails(){
    yield takeEvery(GET_ACCOUNT_DETAILS, fetchAccountDetails)
  }
  export function* watchFetchAccountUsers(){
    yield takeEvery(GET_ACCOUNT_USERS,fetchAccountUsers )
  }
  
  function* superAdmin() {
    yield all([fork(watchFetchDemoData)]);
    yield all([fork(watchFetchAccountDetails)]);
    
    
  }
                                        
  export default superAdmin;