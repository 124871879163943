import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Label,
  FormFeedback,
  Button,
  UncontrolledAlert,
} from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Switch from "react-switch"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import {
  clearUpdateAccountHolderUser,
  getAccountHolderUserDetails,
  updateAccountHolderUser,
  getScorecardsListForAccountUser,
  ScorecardRoutingHandler,
  ClearAccountHolderUserDetails,
  UpdateAccountUsersDetailsState,
  clearAccountHolderUsersState,
  updateAccountHolderUsersState
} from "store/actions"
// import { Button } from 'bootstrap';
import * as Yup from "yup"
import { Formik, useFormik } from "formik" //Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert"
import { ACCOUNT_OWNER } from "config/AlertMessage.config"

import { getAccountHolderUsers } from "store/actions"
import { accountUsersLimit } from "../../../config/UserCreationLimit_config"
// import { ACCOUNT_OWNER } from "../../../config/AlertMessage.config"
import { Country, State } from "country-state-city"

import NotFound from "components/Common/NotFound/NotFound.component"
import { ACCOUNT_USER } from "config/AlertMessage.config"
import { NoBackpackSharp } from "@mui/icons-material"
import "../New Dashboard/New_Dashboard.css"
import updateSuccessIcon from "../../../assets/images/NewPopupIcons/Scorecard created.png"
import updateFailedIcon from "../../../assets/images/NewPopupIcons/FailedIcon.png"

import { Link } from "react-router-dom"

const ViewUsersDetails = () => {
  const params = useParams()
  const history = useHistory()
  const [isEditMode, setIsEditMode] = useState(false)
  const dispatch = useDispatch()
  const [isUserAdded, setIsUserAdded] = useState(false)
  const [isUserDisabled, setIsUserDisabled] = useState(false)
  const [limitExceed, setLimitExceed] = useState(false)
  const [isDataAvail, setIsDataAvail] = useState(true)
  const [paginationReset, setPaginationReset] = useState(false)
  const [HandleAccountUsersDetails, setHandleAccountUsersDetails] = useState({})
  const [countries, setCountries] = useState(null)

  const {
    accountUserDetails,
    updateAccountUserDetails,
    scorecardsListForAccountUser,
    accountHolderUsers,
    accountDetails
  } = useSelector(state => ({
    updateAccountUserDetails:
      state.UpdateAccountHolderUsersReducer.updateAccountUserDetails,
    accountUserDetails: state.AccountHolderUsersReducer.accountUserDetails,
    scorecardsListForAccountUser:
      state.GetScorecardsListReducer.scorecardsListForAccountUser,
    accountHolderUsers: state.AccountHolderUsersReducer.accountHolderUsers,
    accountDetails: state.SuperAdmin.accountDetails,
  }))

  useEffect(() => {
    dispatch(getAccountHolderUserDetails(params.userId))
    dispatch(getScorecardsListForAccountUser(params.userId))
    dispatch(
      getAccountHolderUsers(JSON.parse(localStorage.getItem("authUser")).uid)
    )
    return () => {
      dispatch(ClearAccountHolderUserDetails())
      dispatch(clearAccountHolderUsersState())
    } // added
  }, [dispatch])

  // console.log("AccountHolderUsers...", accountHolderUsers)

  const GetEnabledUsersCount = () => {
    return accountHolderUsers[0]
      ? accountHolderUsers.filter(user => user.isDisabled === false).length
      : 0
  }

  useEffect(() => {
    setIsUserDisabled(!accountUserDetails.isDisabled)

    // return () => dispatch(clearAccountHolderUsersState())
  }, [accountUserDetails])

  // useEffect(() => {
  //   // var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)

  //   if (scorecardsListForAccountUser.status === 404 || scorecardsListForAccountUser.status === 403) {
  //     setIsDataAvail(false)
  //   }
  //   if (scorecardsListForAccountUser[0]) {
  //     var search_bar = document.querySelector("#search-bar-0")
  //     search_bar.setAttribute("autoComplete", "off")
  //     // clearTimeout(WaitForData)
  //   }
  // }, [scorecardsListForAccountUser])

  // useEffect(() => {
  //   // if (scorecardsListForAccountUser[0]) {
  //   if (scorecardsListForAccountUser[0] && Object.keys(accountUserDetails).includes("id")) {           // replaced
  //     var search_bar = document.querySelector("#search-bar-0")
  //     search_bar.setAttribute("autoComplete", "off")
  //   }

  // }, [scorecardsListForAccountUser])

  const [success_msg, setsuccess_msg] = useState(false)
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          marginRight: "34px",
        }}
      >
        {" "}
        Disabled
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          marginLeft: "34px",
        }}
      >
        {" "}
        Enabled
      </div>
    )
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      //   userName: accountUserDetails && accountUserDetails.userName || "",
      contactName: (accountUserDetails && accountUserDetails.contactName) || "",
      countryCode:
        (accountUserDetails.countryCode &&
          accountUserDetails.countryCode.includes("+")
          ? accountUserDetails.countryCode.slice(1)
          : accountUserDetails.countryCode) || "",
      contactNumber:
        (accountUserDetails && accountUserDetails.contactNumber) || "",
      address: (accountUserDetails && accountUserDetails.address) || "",
      contactEmail:
        (accountUserDetails && accountUserDetails.contactEmail) || "",
    },

    validationSchema: Yup.object({
      //   userName: Yup.string().required("Please enter user name!"),
      contactName: Yup.string().required("Please enter contact name!"),
      contactEmail: Yup.string()
        .email("Must be valid email!")
        .required("Please enter contact email!"),
      // address: Yup.string().required("Please enter address!"),
      // contactNumber: Yup.string()
      //   .max(10, "Contact number must be atleast 10 digits!")
      //   .min(10, "Contact number must be atleast 10 digits!")
      //   .required("Please enter contact number!"),
    }),
    onSubmit: values => {
      var date = new Date()
      var user = JSON.parse(localStorage.getItem("authUser"))

      // console.log("values", {
      //     channelName: values.channelName,
      //     channelDescriptionTitle: values.channelDescriptionTitle,
      //     channelDescription: values.channelDescription,
      //     channelQuote: values.channelQuote,
      //     createdOn: date,
      //     createdBy: created_by,
      // });

      var myUserData = {
        // userName: values.userName,
        contactName: values.contactName,
        contactEmail: values.contactEmail,
        contactNumber: values.contactNumber,
        countryCode: values.countryCode,
        address: values.address,
        createdOn: date,
        createdBy: user.email,
        creatorId: user.uid,
        isDisabled: !isUserDisabled,
      }
      setHandleAccountUsersDetails(myUserData)
      setIsUserAdded(true)
      dispatch(updateAccountHolderUser(params.userId, myUserData))
    },
  })

  // if(updateAccountUserDetails[0] && updateAccountUserDetails[0].status === 200){
  // setTimeout(() => {
  //     // validation.values.userName = ""
  //     validation.values.contactName = ""
  //     validation.values.contactEmail = ""
  //     validation.values.contactNumber = ""
  //     validation.values.address = ""

  //     dispatch(clearUpdateAccountHolderUser())
  //     setIsUserAdded(false)

  //     setTimeout(() => {
  //         history.push("/")
  //     }, 2000)

  //  }, 2000)
  // }

  // if(updateAccountUserDetails[0] && updateAccountUserDetails[0].status !== 200){
  // setTimeout(() => {

  //     dispatch(clearUpdateAccountHolderUser())
  //     setIsUserAdded(false)

  //  }, 2000)
  // }

  const columns = [
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to="#" style={{ color: '#05727B', fontSize: '17px', fontWeight: 'bold' }}>
          {row.companyName}
        </Link>
      ),
    },
    {
      dataField: "scorecardName",
      text: "Scorecard Name",
      sort: true,
    },
    {
      dataField: "createdOn",
      text: "Created On",
      sort: true,
    },
    {
      dataField: "lastUpdatedOn",
      text: "Last Updated On",
      sort: true,
    },
    {
      dataField: "scorecardVersion",
      text: "Scorecard Type",
      // sort: true,
    },
    {
      dataField: "status",
      text: "Status",
    },
  ]

  const productData = !scorecardsListForAccountUser[0]
    ? []
    : scorecardsListForAccountUser.map((user, i) => {
      return {
        id: ++i,
        companyName: user.companyName,
        scorecardName: user.scorecardName,
        createdOn: new Date(user.createdOn._seconds * 1000).toDateString().slice(3),
        lastUpdatedOn: new Date(
          user.lastUpdatedOn._seconds * 1000
        ).toDateString().slice(3),
        scorecardVersion: !user.scorecardVersion ? "NA" : user.scorecardVersion,
        status: user.status,
        actions: user.id,
      }
    })

  useEffect(() => {
    // var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)
    if (
      scorecardsListForAccountUser[0] &&
      Object.keys(accountUserDetails).includes("id")
    ) {
      // console.log("I am here !")
      // console.log("scorecardsListForAccountUser: ", scorecardsListForAccountUser)
      var search_bar = document.querySelector("#search-bar-0")
      search_bar.setAttribute("autoComplete", "off")
      search_bar.addEventListener("focus", () => setPaginationReset(true))
    }
  }, [scorecardsListForAccountUser, accountUserDetails])

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    // page: 1,
    onPageChange: () => setPaginationReset(false),
    // sizePerPage: 10,
    // totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: productData.length },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     dispatch(ScorecardRoutingHandler(document.location.pathname))
  //     history.push("/view/scorecard/" + row.actions)
  //   },
  // }

  const { SearchBar } = Search

  useEffect(() => {
    var countries_ = [...Country.getAllCountries()].map(country => {
      if (country.isoCode === "CA") {
        return {
          ...country,
          phonecode: "001",
        }
      } else if (country.isoCode === "UM") {
        return {
          ...country,
          phonecode: "01",
        }
      } else {
        return country
      }
    })

    // console.log(countries_)
    setCountries(countries_)
  }, [])

  const GetCountryByCode = () => {
    if (countries) {
      var country = countries.filter(
        country => country.name === validation.values.country
      )
      return State.getStatesOfCountry(country[0].isoCode)
    }
  }

  const formMandatory = {
    color: "red",
    fontWeight: "bold",
  }


  // console.log("accountDetails....", accountDetails)

  // console.log("Account user details...", accountUserDetails)

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Mambo - User Details</title>
        </MetaTags>
        <Container fluid={true}>
          {/* <Breadcrumbs title="New Page" breadcrumbItem="New Page" /> */}
          {!Object.keys(accountUserDetails).includes("id") ? (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                height: "400px",
              }}
            >
              <Spinner
                style={{
                  width: "60px",
                  height: "60px",
                  margin: "calc(200px - 30px)",
                  color: '#05727B'
                }}
                role="grid"
                type="border"
              // color="primary"
              />
            </div>
          ) : (
            <Row>
              {/* {
                  !scorecardsListForAccountUser[0] && !accountUserDetails[0] ?
                    <div style={
                      {
                        width: "100%",
                        textAlign: "center",
                        height: "200px"
                      }
                    } >

                      <Spinner
                        style={{
                          width: "60px", height: "60px",
                          margin: "calc(200px - 30px)",

                        }}
                        role="grid"
                        type="border"
                        color="primary"
                      />
                    </div>
                    : */}
              <>
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        {/* {updateAccountUserDetails[0] &&
                          updateAccountUserDetails[0].status === 200 && (
                            <SweetAlert
                              style={{
                                height: "255px",
                                width: "368px",
                              }}
                              // title="Updated successfully"
                              success
                              confirmBtnBsStyle="primary"
                              onConfirm={() => {
                                dispatch(
                                  UpdateAccountUsersDetailsState({
                                    ...HandleAccountUsersDetails,
                                    id: params.userId,
                                  })
                                )
                                setsuccess_msg(false)
                                setIsUserAdded(false)

                                dispatch(clearUpdateAccountHolderUser())
                                // history.push("/")
                              }}
                            >
                              {ACCOUNT_OWNER.VIEW_USER_DETAILS.SUCCESS}
                            </SweetAlert>
                          )} */}

                        {updateAccountUserDetails[0] &&
                          updateAccountUserDetails[0].status === 200 && (
                            <SweetAlert
                              style={{ height: "270px", width: "270px" }}
                              custom
                              customIcon={
                                <Row>
                                  <Col md="12" className="mt-2">
                                    <img src={updateSuccessIcon}
                                      style={{
                                        height: '80px',
                                        width: '85px'
                                      }} />
                                  </Col>
                                  <Col md="12" className="text-center container-fluid mt-3"
                                    style={{
                                      color: '#0D0F11',
                                      fontWeight: 600,
                                      fontSize: '24px',
                                      lineHeight: '28px',
                                    }}
                                  >
                                    {ACCOUNT_OWNER.VIEW_USER_DETAILS.SUCCESS}
                                  </Col>
                                </Row>
                              }
                              customButtons={
                                <div className="container-fluid mb-2" style={{ marginTop: '-10px' }}>
                                  <Button block size="sm" className="my-2"
                                    style={{
                                      background: '#0D0F11',
                                      border: '#0D0F11'
                                    }}
                                    onClick={() => {
                                      dispatch(
                                        UpdateAccountUsersDetailsState({
                                          ...HandleAccountUsersDetails,
                                          id: params.userId,
                                        })
                                      )
                                      setsuccess_msg(false)
                                      setIsUserAdded(false)
                                      dispatch(clearUpdateAccountHolderUser())
                                    }}
                                  >
                                    Close
                                  </Button>
                                </div>
                              }
                            >
                            </SweetAlert>
                          )}

                        {/* {updateAccountUserDetails[0] && updateAccountUserDetails[0].status === 409 &&
                                <SweetAlert
                                  // title="Failed to update "
                                  warning
                                  confirmBtnBsStyle="primary"
                                  style={{
                                    height: '255px',
                                    width: '368px'
                                  }}
                                  onConfirm={() => {
                                    setsuccess_msg(false)
                                    setIsUserAdded(false)

                                    dispatch(clearUpdateAccountHolderUser())

                                  }}
                                >
                                  {ACCOUNT_OWNER.VIEW_USER_DETAILS.CONFLICT}
                                </SweetAlert>
                              } */}
                        {/* {updateAccountUserDetails[0] &&
                          updateAccountUserDetails[0].status === 403 && (
                            <SweetAlert
                              // title="Failed to update "
                              error
                              confirmBtnBsStyle="primary"
                              style={{
                                height: "255px",
                                width: "368px",
                              }}
                              onConfirm={() => {
                                setsuccess_msg(false)
                                setIsUserAdded(false)

                                dispatch(clearUpdateAccountHolderUser())
                              }}
                            >
                              {ACCOUNT_OWNER.VIEW_USER_DETAILS.FAILED}
                            </SweetAlert>
                          )} */}

                        {updateAccountUserDetails[0] &&
                          updateAccountUserDetails[0].status === 403 && (
                            <SweetAlert
                              style={{ height: "270px", width: "270px" }}
                              custom
                              customIcon={
                                <Row>
                                  <Col md="12" className="mt-2">
                                    <img src={updateFailedIcon}
                                      style={{
                                        height: '80px',
                                        width: '80px'
                                      }} />
                                  </Col>
                                  <Col md="12" className="text-center container-fluid mt-3"
                                    style={{
                                      color: '#0D0F11',
                                      fontWeight: 600,
                                      fontSize: '24px',
                                      lineHeight: '28px',
                                    }}
                                  >
                                    {ACCOUNT_OWNER.VIEW_USER_DETAILS.FAILED}
                                  </Col>
                                </Row>
                              }
                              customButtons={
                                <div className="container-fluid mb-2" style={{ marginTop: '-10px' }}>
                                  <Button block size="sm" className="my-2"
                                    style={{
                                      background: '#0D0F11',
                                      border: '#0D0F11'
                                    }}
                                    onClick={() => {
                                      setsuccess_msg(false)
                                      setIsUserAdded(false)
                                      dispatch(clearUpdateAccountHolderUser())
                                    }}
                                  >
                                    Close
                                  </Button>
                                </div>
                              }
                            >
                            </SweetAlert>
                          )}

                        <Form
                          className="needs-validation outer-repeater"
                          autoComplete="off"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <Row>
                            <Col md="10">
                              <CardTitle
                                className="h4"
                                style={{ fontSize: "20px", fontWeight: 500 }}
                              >
                                {(isEditMode &&
                                  validation.values.contactName) ||
                                  (accountUserDetails &&
                                    accountUserDetails.contactName)}
                              </CardTitle>
                            </Col>

                            <Col md="2">
                              {isEditMode ? (
                                <i
                                  className="bx bx-undo "
                                  style={{
                                    fontSize: "30px",
                                    cursor: "pointer",
                                    color: "#05727B",
                                    marginLeft: "100px",
                                  }}
                                  onClick={() => setIsEditMode(false)}
                                ></i>
                              ) : (
                                <i
                                  className="fas fa-pencil-alt"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "12px",
                                    height: "30px",
                                    width: "140px",
                                    cursor: "pointer",
                                    borderRadius: "2px",
                                    background: "#FEF3C7",
                                    padding: "10px",
                                    color: "#fff",
                                    borderRadius: "10px",
                                    justifyContent: "center",
                                    backgroundColor: "#CB6928"
                                  }}
                                  onClick={() => setIsEditMode(true)}
                                >
                                  <span style={{ marginLeft: "5px", color: '#fff' }}>
                                    Edit Information
                                  </span>
                                </i>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            {/* <Col md="12" >
                                <Row> */}
                            <Col md="6">
                              {isEditMode && (
                                <FormGroup className="mb-3">

                                  <Label htmlFor="validationCustom01">
                                    Contact Name
                                  </Label>
                                  <Input
                                    name="contactName"
                                    placeholder="User Name"
                                    type="text"
                                    disabled={isUserAdded}
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.contactName || ""}
                                    invalid={
                                      validation.touched.contactName &&
                                        validation.errors.contactName
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.contactName &&
                                    validation.errors.contactName ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.contactName}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              )}
                            </Col>
                            {/* </Row> */}

                            {/* <Row> */}
                            <Col md="6">
                              {!isEditMode ? null : (
                                <FormGroup className="mb-3">
                                  <Label htmlFor="validationCustom01">
                                    Contact Email
                                  </Label>
                                  <Input
                                    name="contactEmail"
                                    placeholder="Contact Name"
                                    type="text"
                                    disabled={isUserAdded}
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.contactEmail || ""}
                                    invalid={
                                      validation.touched.contactEmail &&
                                        validation.errors.contactEmail
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.contactEmail &&
                                    validation.errors.contactEmail ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.contactEmail}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              )}
                            </Col>
                          </Row>
                          {/* {accountDetails && accountDetails.scorecardVersions && !accountDetails.scorecardVersions.proScorecard && */}
                          <Row>
                            <Col md="6">
                              {!isEditMode ?
                                // accountDetails && accountDetails.scorecardVersions && accountDetails.scorecardVersions.proScorecard ?
                                (
                                  <div style={{ display: 'inline-block', alignItems: 'center', justifyContent: 'center' }}>
                                    {accountUserDetails && accountUserDetails.contactNumber &&
                                      (< span
                                        style={{
                                          fontSize: "14px",
                                          marginTop: -15,
                                          textAlign: "left",
                                          marginTop: '20px',
                                          marginRight: '40px'
                                        }}
                                      >
                                        <i className="mdi mdi-phone fw-bold mt-1 mx-1 font-size-14"></i>
                                        {accountUserDetails &&
                                          accountUserDetails.countryCode.includes("+")
                                          ? accountUserDetails.countryCode
                                          : "+" +
                                          accountUserDetails.countryCode}{" "}

                                        <span className="font-size-14 mx-1">
                                          {accountUserDetails && accountUserDetails.contactNumber}
                                        </span>
                                      </span>)
                                    }
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        marginTop: -50,
                                        textAlign: "center",
                                      }}
                                    >
                                      <i className="mdi mdi-email fw-bold mt-1 font-size-14"></i>
                                      <span className="font-size-14 mx-2">
                                        {accountUserDetails && accountUserDetails.contactEmail}
                                      </span>

                                    </span>
                                  </div>
                                )
                                // :
                                // (<div>
                                //   <span
                                //     style={{
                                //       fontSize: "14px",
                                //       marginTop: -50,
                                //       textAlign: "center",
                                //     }}
                                //   >
                                //     <i className="mdi mdi-email fw-bold mt-1 font-size-14"></i>
                                //     <span className="font-size-14 mx-2">
                                //       {accountUserDetails && accountUserDetails.contactEmail}
                                //     </span>

                                //   </span>
                                // </div>)

                                :
                                // null
                                // accountDetails && accountDetails.scorecardVersions && accountDetails.scorecardVersions.proScorecard &&

                                (<div>
                                  <Row>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01">
                                          Country Code
                                        </Label>
                                        {/* <Input
                                                  name="countryCode"
                                                  placeholder="Country Code"
                                                  type="text"
                                                  disabled={isUserAdded}

                                                  className="form-control"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.countryCode || ""}
                                                  invalid={
                                                    validation.touched.countryCode && validation.errors.countryCode ? true : false
                                                  }
                                                /> */}
                                        <Input
                                          type="select"
                                          className="form-control"
                                          name="countryCode"
                                          disabled={isUserAdded}
                                          value={validation.values.countryCode}
                                          onChange={validation.handleChange}
                                        >
                                          {/* {
                                                              countries && countries.map(country => (
                                                                  <option key={country.name} value={country.phonecode} >
                                                                      {country.phonecode.includes("+") ? country.phonecode : "+" + country.phonecode}
                                                                  </option>
                                                              ))
                                                          } */}
                                          {countries &&
                                            countries.map(country => (
                                              <option
                                                key={country.name}
                                                value={country.phonecode}
                                              >
                                                {country.flag}
                                                &nbsp;&nbsp;{country.isoCode}
                                                &nbsp;&nbsp;({country.phonecode})
                                              </option>
                                            ))}
                                        </Input>
                                        {/* {validation.touched.countryCode &&
                                          validation.errors.countryCode ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.countryCode}
                                          </FormFeedback>
                                        ) : null} */}
                                      </FormGroup>
                                    </Col>
                                    <Col md="8">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01">
                                          Contact Number
                                        </Label>
                                        <Input
                                          name="contactNumber"
                                          placeholder="Contact Number"
                                          type="text"
                                          disabled={isUserAdded}
                                          className="form-control"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.contactNumber || ""
                                          }
                                        // invalid={
                                        //   validation.touched.contactNumber &&
                                        //     validation.errors.contactNumber
                                        //     ? true
                                        //     : false
                                        // }
                                        />
                                        {/* {validation.touched.contactNumber &&
                                          validation.errors.contactNumber ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.contactNumber}
                                          </FormFeedback>
                                        ) : null} */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>
                                )

                              }
                            </Col>

                            <Col md="6">
                              {!isEditMode ?
                                // accountDetails && accountDetails.scorecardVersions && accountDetails.scorecardVersions.proScorecard &&
                                (
                                  <div>
                                    {accountUserDetails && accountUserDetails.address &&
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          marginTop: -5,
                                          textAlign: "left",

                                        }}
                                      >
                                        <i className="bx bxs-briefcase fw-bold mt-1 font-size-14"></i>
                                        <span className="font-size-14 mx-2">
                                          {accountUserDetails && accountUserDetails.address}
                                        </span>
                                      </span>
                                    }
                                  </div>

                                ) : (
                                  // accountDetails && accountDetails.scorecardVersions && accountDetails.scorecardVersions.proScorecard &&

                                  <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">
                                      Title
                                    </Label>
                                    <Input
                                      name="address"
                                      placeholder="designation"
                                      type="text"
                                      disabled={isUserAdded}
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.address || ""}
                                    // invalid={
                                    //   validation.touched.address &&
                                    //     validation.errors.address
                                    //     ? true
                                    //     : false
                                    // }
                                    />
                                    {/* {validation.touched.address &&
                                      validation.errors.address ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.address}
                                      </FormFeedback>
                                    ) : null} */}
                                  </FormGroup>
                                )}
                            </Col>
                          </Row>

                          <Row>
                            {/* <Col md="6" >
                                    {
                                      !isEditMode ?
                                        <p style={{ fontSize: "14px", marginTop: -15, textAlign: 'left' }}>
                                          <i style={{ fontSize: "18px" }}
                                            className="fas fa-address-card" ></i>

                                          &nbsp; &nbsp;

                                          {accountUserDetails && accountUserDetails.address}
                                        </p>
                                        :

                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom01">Title</Label>
                                          <Input
                                            name="address"
                                            placeholder="designation"
                                            type="text"
                                            disabled={isUserAdded}

                                            className="form-control"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.address || ""}
                                            invalid={
                                              validation.touched.address && validation.errors.address ? true : false
                                            }
                                          />
                                          {validation.touched.address && validation.errors.address ? (
                                            <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                    }
                                  </Col> */}
                          </Row>
                          <Row>
                            <Col md="4">
                              {isEditMode && (
                                <>
                                  <div
                                    style={{
                                      padding: "6px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "8px",
                                      }}
                                    >
                                      {" "}
                                      User Status:{" "}
                                    </span>
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-2 mb-sm-8 mb-4"
                                      onColor="#05727B"
                                      width={100}
                                      onChange={e => {
                                        // console.log("limit", accountUsersLimit)
                                        // console.log("User count...", GetEnabledUsersCount())
                                        // console.log(accountHolderUsers.filter(user => params.userId === user.id)[0].isDisabled)
                                        if (
                                          accountHolderUsers.filter(
                                            user => params.userId === user.id
                                          )[0].isDisabled === true &&
                                          GetEnabledUsersCount() <
                                          accountUsersLimit
                                        ) {
                                          setIsUserDisabled(p => !p)
                                          // console.log("Enabled successfully...!")
                                        } else if (
                                          accountHolderUsers.filter(
                                            user => params.userId === user.id
                                          )[0].isDisabled === false
                                        ) {
                                          setIsUserDisabled(p => !p)
                                        } else {
                                          setLimitExceed(true)
                                        }
                                      }}
                                      checked={isUserDisabled}
                                    />
                                  </div>

                                  {/* <label
                                                              className="form-check-label"
                                                              htmlFor="customSwitchsizelg"
                                                          >
                                                              Disable user?
                                                          </label>
                                                          <div className="form-check form-switch form-switch-lg mb-3">
                                                              <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              value= " pratik"
                                                              id="customSwitchsizelg"
                                                              defaultChecked = { !accountUserDetails.isDisabled}
                                                              onChange = {(e) => setIsUserDisabled(!e.target.checked) }
                                                              />
                                                              
                                                          </div> */}
                                </>
                              )}
                            </Col>
                            <Col md="6"></Col>
                            <Col md="2">
                              {isEditMode && (
                                <FormGroup className="mb-3">
                                  <Button
                                    style={{ backgroundColor: "#05727B" }}
                                    type="submit"
                                    disabled={isUserAdded}
                                    block
                                  >
                                    {isUserAdded ? "" : "Update"}
                                    {isUserAdded ? (
                                      <Spinner
                                        // style={{ width: "0.8rem", height: "0.8rem" }}
                                        type="border"
                                        size="sm"
                                      />
                                    ) : null}
                                  </Button>
                                </FormGroup>
                              )}
                            </Col>
                            {limitExceed && (
                              <UncontrolledAlert
                                color="danger"
                                onClick={() => setLimitExceed(false)}
                              >
                                {
                                  <strong>
                                    {
                                      ACCOUNT_OWNER.USER_CREATE_LIMIT_EXCEED
                                        .message_update
                                    }
                                  </strong>
                                }
                              </UncontrolledAlert>
                            )}
                          </Row>

                          {/* </Col>

                            </Row> */}
                        </Form>
                      </CardBody>
                    </Card>
                    {/* {
                      !scorecardsListForAccountUser[0] ?
                        <div style={
                          {
                            width: "100%",
                            textAlign: "center",
                            height: "400px"
                          }
                        } >
                          {
                            !isDataAvail ?

                              <NotFound
                                Message={ACCOUNT_USER.USER_WITHOUT_SCORECARD} />
                              :
                              <Spinner
                                style={{
                                  width: "60px", height: "60px",
                                  margin: "calc(200px - 30px)",

                                }}
                                role="grid"
                                type="border"
                                color="primary"
                              />
                          }
                        </div>
                        : */}
                    <Row>
                      <Col className="col-12">
                        <Card>
                          <CardBody>
                            <CardTitle
                              className="h4"
                              style={{ fontSize: "20px", fontWeight: 500 }}
                            >
                              Scorecards Assigned To :{" "}
                              {accountUserDetails.contactName}
                            </CardTitle>
                            <PaginationProvider
                              pagination={
                                paginationReset
                                  ? paginationFactory({
                                    ...pageOptions,
                                    page: 1,
                                  })
                                  : paginationFactory(pageOptions)
                              }
                              keyField="id"
                              columns={columns}
                              data={productData}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="id"
                                  columns={columns}
                                  data={productData}
                                  search
                                >
                                  {toolkitProps => (
                                    <React.Fragment>
                                      <Row className="mb-2">
                                        <Col md="9"></Col>
                                        <Col md="3">
                                          <div className="search-box me-2 mb-2 d-inline-block">
                                            <div className="position-relative">
                                              <SearchBar
                                                {...toolkitProps.searchProps}
                                                onClear={() =>
                                                  setPaginationReset(false)
                                                }
                                              />
                                              <i className="bx bx-search-alt search-icon" />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              // rowEvents={rowEvents}
                                              keyField={"id"}
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              defaultSorted={defaultSorted}
                                              classes={
                                                "table align-middle table-nowrap"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}

                                            //         </Col>

                                            //                 </Row>
                                            //               </Form>
                                            //     </CardBody>
                                            // </Card>
                                            //       <Row>
                                            //         <Col className="col-12">
                                            //           <Card>
                                            //             <CardBody>

                                            //               <CardTitle className="h4" style={{ fontSize: '20px' }}>View Scorecard</CardTitle>
                                            //               <PaginationProvider
                                            //                 pagination={paginationFactory(pageOptions)}
                                            //                 keyField='id'
                                            //                 columns={columns}
                                            //                 data={productData}
                                            //               >
                                            //                 {({ paginationProps, paginationTableProps }) => (
                                            //                   <ToolkitProvider
                                            //                     keyField='id'
                                            //                     columns={columns}
                                            //                     data={productData}
                                            //                     search
                                            //                   >
                                            //                     {toolkitProps => (
                                            //                       <React.Fragment>

                                            //                         <Row className="mb-2">
                                            //                           <Col md="9"></Col>
                                            //                           <Col md="3">
                                            //                             <div className="search-box me-2 mb-2 d-inline-block">
                                            //                               <div className="position-relative">
                                            //                                 <SearchBar
                                            //                                   {...toolkitProps.searchProps}
                                            />
                                            {/* <i className="bx bx-search-alt search-icon" />
                                                      </div>
                                                    </div>
                                                  </Col>
                                                </Row> */}
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                          <div className="d-inline">
                                            <SizePerPageDropdownStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                          <div className="text-md-right ms-auto">
                                            <PaginationListStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    {/* // } */}
                  </Col>
                </Row>
              </>
              {/* } */}
            </Row>
          )}
        </Container>
      </div>
    </>
  )
}

export default ViewUsersDetails
