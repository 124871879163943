import {
  ARRANGE_CHANNEL_SEQUENCE,
  ARRANGE_CHANNEL_SEQUENCE_SUCCESS,
  ARRANGE_CHANNEL_SEQUENCE_FAIL,
  CLEAR_ARRANGE_CHANNEL_SEQUENCE,
} from "./actionTypes"


export const ArrangeChannelSequence = (data) =>({
    type:ARRANGE_CHANNEL_SEQUENCE,
    payload:data

})
export const ArrangeChannelSequenceSuccess = (data) => ({
    type:ARRANGE_CHANNEL_SEQUENCE_SUCCESS,
    payload:data

})
export const ArrangeChannelSequenceFail = (error) => ({
    type:ARRANGE_CHANNEL_SEQUENCE_FAIL,
    payload: error

})

export const ClearArrangeChannelSequence = () => ({
    type:CLEAR_ARRANGE_CHANNEL_SEQUENCE
})