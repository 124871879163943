import {
    ADD_NEW_INSTRUCTION,
    ADD_NEW_INSTRUCTION_SUCCESS,
    ADD_NEW_INSTRUCTION_FAIL,
    ADD_NEW_INSTRUCTION_CLEAR,

    GET_ALL_INSTRUCTION_DATA,
    GET_ALL_INSTRUCTION_DATA_SUCCESS,
    GET_ALL_INSTRUCTION_DATA_FAIL,
    GET_ALL_INSTRUCTION_DATA_CLEAR,

    UPDATE_INSTRUCTION,
    UPDATE_INSTRUCTION_SUCCESS,
    UPDATE_INSTRUCTION_FAIL,
    UPDATE_INSTRUCTION_CLEAR
} from "./actionTypes"

// To add new instuction 
export const addNewInstruction = (data) => {
    return {
        type: ADD_NEW_INSTRUCTION,
        payload: data
    }
}
export const addNewInstructionSuccess = (data) => {
    return {
        type: ADD_NEW_INSTRUCTION_SUCCESS,
        payload: data
    }
}
export const addNewInstructionFail = (error) => {
    return {
        type: ADD_NEW_INSTRUCTION_FAIL,
        payload: error
    }
}
export const clearAddNewInstruction = () => {
    return {
        type: ADD_NEW_INSTRUCTION_CLEAR
    }
}


// To get all instruction from database
export const getAllInstructionData = () => {
    return {
        type: GET_ALL_INSTRUCTION_DATA,
    }
}
export const getAllInstructionDataSuccess = (data) => {
    return {
        type: GET_ALL_INSTRUCTION_DATA_SUCCESS,
        payload: data
    }
}
export const getAllInstructionDataFail = (error) => {
    return {
        type: GET_ALL_INSTRUCTION_DATA_FAIL,
        payload: error
    }
}
export const clearGetAllInstructionData = () => {
    return {
        type: GET_ALL_INSTRUCTION_DATA_CLEAR
    }
}


// To Update Instruction of contentEditor
export const updateInstruction = (data) => {
    return {
        type: UPDATE_INSTRUCTION,
        payload: data
    }
}
export const updateInstructionSuccess = (data) => {
    return {
        type: UPDATE_INSTRUCTION_SUCCESS,
        payload: data
    }
}
export const updateInstructionFail = (error) => {
    return {
        type: UPDATE_INSTRUCTION_FAIL,
        payload: error
    }
}
export const clearUpdateInstruction = () => {
    return {
        type: UPDATE_INSTRUCTION_CLEAR
    }
}