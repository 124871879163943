import {

    UPDATE_ACCOUNT_HOLDER_USER_SUCCESS,
    UPDATE_ACCOUNT_HOLDER_USER_FAIL,
    CLEAR_ACCOUNT_HOLDER_USER
    
  } from "./actionTypes"
  
  const INIT_STATE = {
    updateAccountUserDetails: [],
    error: {},
  }
  
  const UpdateAccountHolderUsersReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

      case UPDATE_ACCOUNT_HOLDER_USER_SUCCESS:
        return {
          ...state,
          updateAccountUserDetails: action.payload,
        }
  
      case UPDATE_ACCOUNT_HOLDER_USER_FAIL:
        return {
          ...state,
          error: action.payload,
        }

        case CLEAR_ACCOUNT_HOLDER_USER:
        return {
          ...state,
          updateAccountUserDetails: [],
        }

      default:
        return state
    }
  }
  
  export default UpdateAccountHolderUsersReducer
  