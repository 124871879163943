import { color, fontSize } from "@mui/system"
import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Grid } from "@mui/material"
import img from "../../../../assets/images/reportScorcard/executiveCardimg.PNG"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import ReactHtmlParser from "react-html-parser"

import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
  Modal,
  Tooltip,
} from "reactstrap"
import { Opacity, TryRounded } from "@mui/icons-material"
import Footer from "components/VerticalLayout/Footer"
import { executiveSummary } from "../ExecutiveSummary/data"
import { Link, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { useSelector, useDispatch } from "react-redux"

import "../config/style.config.css"
import CropEasy from "pages/Crop/CropEasy"

import {
  getChannelDetails,
  UpdateScorecardReport,
  ClearUpdateScorecardReport,
  getScorecardReport,
  updateGetScorecardReport,
  handleUnsavedChanges,
  handleChangesHappend,
  updateScorecardDetail,
  getAllInstructionData,
} from "store/actions"
import { REPORT, REPORT_PAGE } from "../config/style.config"
import SweetAlert from "react-bootstrap-sweetalert"
import { ACCOUNT_USER } from "config/AlertMessage.config"
import { CLOUD_STORAGE } from "config/cloudStorage.config"
import updateSuccessIcon from "../../../../assets/images/NewPopupIcons/Scorecard created.png"
import updateFailedIcon from "../../../../assets/images/NewPopupIcons/FailedIcon.png"

const KeyObservations = () => {
  const params = useParams()
  const [image, setImage] = useState({ preview: "", raw: "" })
  const [KeyObservationData, setKeyObservationData] = useState([])
  const [isApplied, setIsApplied] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])

  const [HeightWidth, setHeightWidth] = useState({})
  const [openCrop, setOpenCrop] = useState(false)

  const [file, setFile] = useState(null)
  const [image_url, setImage_Url] = useState([])
  const [iconThumbnail, setIconThumbnail] = useState(null)

  const [editKeyObservation, seteditKeyObservation] = useState({})
  const [saveKeyObservation, setsaveKeyObservation] = useState({})
  const [isSaved, setIsSaved] = useState(false)

  // const [test, setTest] = useState(null)

  const [channelName, setChannelName] = useState([])

  const [multiImageUrl, setMultiImageUrl] = useState([])
  const [imageIndex, setImageIndex] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const [modal_center, setmodal_center] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [isImageThere, setImageThere] = useState(false)
  const [IsImage, setIsImage] = useState(false)

  // state to show tooltip over save button
  const [tooltip, setTooltip] = useState(false)

  //Fetched Tooltip Data
  const [tooltipForSaveBtn, setTooltipForSaveBtn] = useState(false)
  const [tooltipForAddPageBtn, setTooltipForAddPageBtn] = useState(false)
  const [tooltipForInsertBtn, setTooltipForInsertBtn] = useState(false)

  const [tooltipForResetPageBtn, setTooltipForResetPageBtn] = useState(false)
  const [tooltipForImageUpload, setTooltipForImageUpload] = useState(false)
  const [tooltipForRemovePage, setTooltipForRemovePage] = useState(false)
  const [tooltipForEditBtn, setTooltipForEditBtn] = useState(false)
  const [tooltipData, setTooltipData] = useState({})

  const dispatch = useDispatch()

  const {
    scorecardReportUpdate,
    scorecardReport_data,
    channelDetails,
    isDataEdited,
    isThereChanges,
    getInstructionData,
  } = useSelector(state => ({
    scorecardReportUpdate: state.ScorecardReport_Reducer.scorecardReportUpdate,
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
    channelDetails: state.ChannelDetailsReducer.channelDetails,
    isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    getInstructionData: state.instructionPanelReducer.getInstructionData,
  }))

  useEffect(() => {
    // dispatch(getScorecardReport(params.scorecardId))
    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
  }, [dispatch])
  
  //console.log(scorecardReport_data)

  useEffect(() => {
    if (channelDetails.id !== params.channelId) {
      dispatch(getChannelDetails(params.channelId))
    }
    // dispatch(getChannelDetails(params.channelId))

    if (scorecardReport_data && scorecardReport_data.selectedChannels) {
      var data = scorecardReport_data.selectedChannels.filter(
        keyObs => keyObs.channelId === params.channelId
      )[0]

      //console.log(data)

      setChannelName(data.channelName)
      setsaveKeyObservation(
        data.keyObservations && data.keyObservations.contents
      )
      setKeyObservationData(
        data.keyObservations &&  data.keyObservations.contents  ? data.keyObservations.contents : []
          
      )
      seteditKeyObservation(
        data.keyObservations &&  data.keyObservations.contents 
      )
      setMultiImageUrl(data.keyObservations && data.keyObservations.bgImage ? data.keyObservations.bgImage : [])
    }
  }, [scorecardReport_data, params.channelId])

  // console.log(scorecardReportUpdate)

  //console.log(KeyObservationData)

  useEffect(() => {
    if (croppedImage) {
      var temp = [...multiImageUrl]
      temp[imageIndex] = croppedImage
      setMultiImageUrl(temp)
    }
  }, [croppedImage])

  useEffect(() => {
    if (!Object.keys(getInstructionData).includes("infoForAccountUser")) {
      dispatch(getAllInstructionData())
    }
  }, [dispatch])

  useEffect(() => {
    if (getInstructionData.infoForAccountUser !== undefined) {
      var data = {
        infoForInsertBtn: getInstructionData.infoForAccountUser.Key_Observations
          .infoForInsertBtn
          ? getInstructionData.infoForAccountUser.Key_Observations
              .infoForInsertBtn
          : "",
        infoForEditBtn: getInstructionData.infoForAccountUser.Key_Observations
          .infoForEditBtn
          ? getInstructionData.infoForAccountUser.Key_Observations
              .infoForEditBtn
          : "",
        infoForUploadImageBtn: getInstructionData.infoForAccountUser
          .Key_Observations.infoForUploadImageBtn
          ? getInstructionData.infoForAccountUser.Key_Observations
              .infoForUploadImageBtn
          : "",
        infoForResetPageBtn: getInstructionData.infoForAccountUser
          .Key_Observations.infoForResetPageBtn
          ? getInstructionData.infoForAccountUser.Key_Observations
              .infoForResetPageBtn
          : "",
        infoForSaveBtn: getInstructionData.infoForAccountUser.Key_Observations
          .infoForSaveBtn
          ? getInstructionData.infoForAccountUser.Key_Observations
              .infoForSaveBtn
          : "",
      }
      setTooltipData({ ...data })
    }
  }, [getInstructionData])

  const listToPara = e => {
    var temp = ""
    KeyObservationData.forEach(data => {
      temp += data
    })
    var executiveSummaaryContent = document.querySelectorAll(
      ".executiveSummaaryContent"
    )
    executiveSummaaryContent.forEach(checkBox => {
      checkBox.checked = false
    })
    seteditKeyObservation(temp)
    dispatch(handleChangesHappend(true))
  }

  function tog_center(Index) {
    setmodal_center(!modal_center)
    removeBodyCss()
    setImageIndex(Index)
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
    // HandleChange(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // const HandleChange = e => {
  //   const file = e[0]
  //   if (file) {
  //     var u = URL.createObjectURL(file)
  //     var img = new Image()
  //     img.onload = function () {
  //       var IMAGE_HEIGHT = img.height
  //       var IMAGE_WIDTH = img.width
  //       setHeightWidth({
  //         myheight: IMAGE_HEIGHT,
  //         mywidth: IMAGE_WIDTH,
  //       })
  //     }
  //     img.src = u
  //     setFile(file)
  //     setImage_Url(u)
  //     setOpenCrop(true)
  //   }
  // }

  useEffect(() => {
    if (IsImage) {
      setIsModalOpen(false)
      setImageThere(false)
      setIsImage(false)
    }
  }, [croppedImage])

  const handleChange = e => {
    const file = e.target.files[0]
    if (file) {
      var u = URL.createObjectURL(file)
      var img = new Image()
      img.onload = function () {
        var IMAGE_HEIGHT = img.height
        var IMAGE_WIDTH = img.width

        setHeightWidth({
          myheight: IMAGE_HEIGHT,
          mywidth: IMAGE_WIDTH,
        })
      }
      img.src = u
      setFile(file)
      setImage_Url(u)
      setOpenCrop(true)
      setTimeout(() => setIsImage(true), 2000)
      dispatch(handleChangesHappend(true))
      e.target.value = ""
    }
  }

  function handleAddRowNested() {
    if (rows1.length < 10) {
      const modifiedRows = [...rows1]
      modifiedRows.push({ id: modifiedRows.length + 1 })
      setrows1(modifiedRows)
    }
  }

  const HandleSaveButton = () => {
    setIsSaved(true)
    if (saveKeyObservation) {
      setsaveKeyObservation(null)
      setIsSaved(false)
    } else {
      var updatedChannelList = scorecardReport_data.selectedChannels.map(
        channel => {
          if (channel.channelId === params.channelId) {
            return {
              ...channel,
              keyObservations: {
                bgImage: multiImageUrl,
                contents: editKeyObservation,
              },
            }
          } else {
            return channel
          }
        }
      )

      var data = {
        selectedChannels: updatedChannelList,
        lastUpdatedOn: {
          _miliseconds: 0,
          _seconds: new Date().getTime() / 1000,
        },
      }
      dispatch(
        updateScorecardDetail(params.scorecardId, {
          lastUpdatedOn: {
            _miliseconds: 0,
            _seconds: new Date().getTime() / 1000,
          },
        })
      )
      dispatch(UpdateScorecardReport(params.scorecardId, data))
      dispatch(handleChangesHappend(false))
    }
  }
  //console.log(CLOUD_STORAGE)

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Mambo -Report</title>
        </MetaTags>
        {/* {
          scorecardReportUpdate.status === 200 &&
          <SweetAlert
            style={{
              position: "absolute",
              top: 170,
              height: "255px", width: "368px"
            }}
            // title="Key observation saved successfully!"
            success
            confirmBtnBsStyle="primary"

            onConfirm={() => {
              dispatch(updateGetScorecardReport({
                ...scorecardReport_data,
                selectedChannels: scorecardReportUpdate.data.selectedChannels,
                lastUpdatedOn: scorecardReportUpdate.data.lastUpdatedOn,
              }))
              setIsSaved(false)

              setsaveKeyObservation(editKeyObservation || " ")

              dispatch(ClearUpdateScorecardReport())
            }
            }
          >{ACCOUNT_USER.EDIT_REPORT.SUCCESS}
          </SweetAlert>
        } */}

        {scorecardReportUpdate.status === 200 && (
          <SweetAlert
            style={{ height: "270px", width: "270px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img
                    src={updateSuccessIcon}
                    style={{
                      height: "80px",
                      width: "85px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid mb-2"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "25px",
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.SUCCESS}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                <Button
                  block
                  size="sm"
                  className="mt-2"
                  style={{
                    background: "#0D0F11",
                    border: "#0D0F11",
                  }}
                  onClick={() => {
                    dispatch(
                      updateGetScorecardReport({
                        ...scorecardReport_data,
                        selectedChannels:
                          scorecardReportUpdate.data.selectedChannels,
                        lastUpdatedOn: scorecardReportUpdate.data.lastUpdatedOn,
                      })
                    )
                    setIsSaved(false)

                    setsaveKeyObservation(editKeyObservation || " ")
                    setTooltipForSaveBtn(false)
                    setTooltipForInsertBtn(false)
                    setTooltipForResetPageBtn(false)
                    setTooltipForImageUpload(false)
                    setTooltipForEditBtn(false)

                    dispatch(ClearUpdateScorecardReport())
                  }}
                >
                  Close
                </Button>
              </div>
            }
          ></SweetAlert>
        )}

        {/* {
          scorecardReportUpdate.status === 403 &&

          <SweetAlert
            // title="Failed to save key observations!"
            style={{
              position: "absolute",
              top: 170,
              height: "255px", width: "368px"
            }}
            error
            confirmBtnBsStyle="primary"

            onConfirm={() => {

              setIsSaved(false)
              dispatch(ClearUpdateScorecardReport())

            }}
          >{ACCOUNT_USER.EDIT_REPORT.FAILED}
          </SweetAlert>

        } */}

        {scorecardReportUpdate.status === 403 && (
          <SweetAlert
            style={{ height: "270px", width: "270px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img
                    src={updateFailedIcon}
                    style={{
                      height: "80px",
                      width: "80px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "25px",
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.FAILED}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                <Button
                  block
                  size="sm"
                  className=""
                  style={{
                    background: "#0D0F11",
                    border: "#0D0F11",
                  }}
                  onClick={() => {
                    setIsSaved(false)
                    setTooltipForSaveBtn(false)
                    setTooltipForInsertBtn(false)
                    setTooltipForResetPageBtn(false)
                    setTooltipForImageUpload(false)
                    setTooltipForEditBtn(false)
                    dispatch(ClearUpdateScorecardReport())
                  }}
                >
                  Close
                </Button>
              </div>
            }
          ></SweetAlert>
        )}

        <Container
          fluid={true}
          style={{
            transform: "scale(.8) translate(-130px, -132px) ",
            width: "1400px",
          }}
        >
          <Row>
            <Col className="col-12 m-auto ">
              <Card>
                <CardBody>
                  <Row>
                    <Row>
                      <Col md="3" className="mb-2">
                        <Button
                          className="btn btn-primary"
                          id="TooltipReset"
                          color="primary"
                          onClick={() => {
                            seteditKeyObservation(null)
                            setsaveKeyObservation(null)
                            setKeyObservationData([])
                            dispatch(handleChangesHappend(true))
                          }}
                        >
                          Reset Page
                        </Button>
                        <Tooltip
                          style={{
                            backgroundColor: "#FEF3C7",
                            color: "#0D0F11",
                            zIndex: 1,
                          }}
                          placement="right"
                          isOpen={tooltipForResetPageBtn}
                          target="TooltipReset"
                          toggle={() => {
                            setTooltipForResetPageBtn(!tooltipForResetPageBtn)
                          }}
                        >
                          {tooltipData.infoForResetPageBtn}
                        </Tooltip>
                      </Col>
                      <Col md="9"></Col>
                    </Row>
                    <Col md="8">
                      <div style={{ ...REPORT_PAGE }}>
                        <Row className="g-0">
                          {/* <Modal
                            isOpen={modal_center}
                            toggle={() => {
                              tog_center()
                            }}
                            centered
                          >
                            <div className="modal-header">
                              <h5 className="modal-title mt-0">
                                Modern Marketing Scorecard
                              </h5>
                              <button
                                type="button"
                                onClick={() => {
                                  setmodal_center(false)
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              {!openCrop ?
                                <Form>
                                  <Dropzone
                                    onDrop={acceptedFiles => {
                                      handleAcceptedFiles(acceptedFiles)
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick mt-2"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="mb-3">
                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                          </div>
                                          <h4>Drop files here or click to upload.</h4>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div className="dropzone-previews mt-3" id="file-previews">
                                    {selectedFiles.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                              </Col>
                                              <Col>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>{f.formattedSize}</strong>
                                                </p>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      )
                                    })}
                                  </div>
                                </Form>
                                :
                                <CropEasy
                                  image_url={image_url}
                                  imageName={file.name}
                                  image_storagePath={"/ContentEditor/images/"}
                                  thumbnail_storagePath={"/ContentEditor/Thumbnail/"}
                                  setOpenCrop={setOpenCrop}
                                  setImageUrl={setCroppedImage}
                                  setFile={setFile}
                                  setIconThumbnail={setIconThumbnail}
                                  ImageHeightWidth={HeightWidth}
                                  ImageType="KEY_OBSERVATIONS"
                                />
                              }
                              <div className="text-center mt-4">
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                >
                                  Send Files
                                </button>
                              </div>
                            </div>
                          </Modal> */}

                          <Modal
                            isOpen={isModalOpen}
                            onRequestClose={() => setIsModalOpen(false)}
                            style={{ marginTop: "250px", width: "650px" }}
                          >
                            {!isImageThere ? (
                              <>
                                {/* <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // width:'100%',
                                    marginLeft: "5px",
                                  }}
                                  >
                                    <h5 style={{ margin: '15px 0 10px 140px' }}>Upload background image</h5>
                                    <i className="bx bx-x" style={{ fontSize: '25px', cursor: 'pointer', width: '40px', margin: '14px 0 10px 80px' }}
                                      onClick={() => setIsModalOpen(false)}></i>

                                  </div>
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      var inputFile = document.querySelector("#upload-button")
                                      inputFile.click()
                                    }}
                                    style={{

                                      alignItems: "center",
                                      margin: "0 0 20px 180px",
                                      width: '150px'
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-image"
                                      style={{ marginRight: "5px", fontSize: "15px" }}
                                    ></i>
                                    Choose Image
                                  </Button>{" "} */}
                                <Row className="modal-body">
                                  <Col
                                    md="12"
                                    className="text-center font-size-18 mb-3"
                                    style={{
                                      color: "#0D0F11",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Upload New Image
                                    <button
                                      style={{
                                        marginTop: "-10px",
                                        marginLeft: "-20px",
                                      }}
                                      type="button"
                                      onClick={() => {
                                        setIsModalOpen(false)
                                      }}
                                      className="close text-center font-size-14"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </Col>
                                  <Col md="4"></Col>
                                  <Col md="4">
                                    <Button
                                      size="sm"
                                      block
                                      onClick={() => {
                                        var inputFile =
                                          document.querySelector(
                                            "#upload-button"
                                          )
                                        inputFile.click()
                                      }}
                                      style={{
                                        background: "#0D0F11",
                                        border: "#0D0F11",
                                      }}
                                    >
                                      <div className="d-flex justify-content-center">
                                        <i className="mdi mdi-image fw-bold mx-2 font-size-14"></i>
                                        <span className="font-size-14">
                                          Choose Image
                                        </span>
                                      </div>
                                    </Button>
                                  </Col>
                                  <Col md="4"></Col>
                                </Row>
                                <div
                                  style={{
                                    justifyContent: "center",
                                    margin: "auto 10px auto 10px",
                                  }}
                                >
                                  {openCrop && (
                                    <CropEasy
                                      image_url={image_url}
                                      imageName={file.name}
                                      image_storagePath={
                                        CLOUD_STORAGE.EDIT_SCORECARD_REPORT
                                          .KEY_OBSERVATIONS.IMAGE
                                      }
                                      thumbnail_storagePath={
                                        CLOUD_STORAGE.EDIT_SCORECARD_REPORT
                                          .KEY_OBSERVATIONS.THUMBNAIL
                                      }
                                      setOpenCrop={setOpenCrop}
                                      setImageUrl={setCroppedImage}
                                      setFile={setFile}
                                      setImageThere={setImageThere}
                                      setIconThumbnail={setIconThumbnail}
                                      ImageHeightWidth={HeightWidth}
                                      ImageType="KEY_OBSERVATIONS"
                                    />
                                  )}
                                </div>
                              </>
                            ) : (
                              // <Col md="8" style={{
                              //   textAlign: 'center',
                              //   margin: '40px 0 40px 90px'
                              // }}>
                              //   <Spinner />
                              //   <br />
                              //   <br />
                              //   <p
                              //     style={{
                              //       color: '#0D0F11',
                              //       fontSize: REPORT.FONT_SIZE.LARGE
                              //     }}
                              //   >
                              //     Please wait.  Image is being uploaded.</p>
                              // </Col>
                              <Row className="modal-body text-center">
                                <Col
                                  md="12"
                                  className="my-3 fw-bold font-size-18"
                                >
                                  <p
                                    style={{
                                      color: "#0D0F11",
                                    }}
                                  >
                                    Please wait. Image is being uploaded.
                                  </p>
                                </Col>
                                <Col md="12">
                                  <Spinner style={{ color: "#0D0F11" }} />
                                </Col>
                              </Row>
                            )}
                          </Modal>

                          <Col md="7">
                            <div
                              style={{
                                width: "108%",
                                height: REPORT_PAGE.height,
                                overflow: "hidden",
                                // border: "1px solid grey"
                              }}
                            >
                              <Row>
                                <Col md="12">
                                  <div
                                    style={{
                                      // margin: "auto",
                                      width: "100%",
                                      height: "fit-content",
                                      padding: "30px 0 10px 40px",
                                      // padding: "30px 0 25px 8px",
                                      position: "relative",
                                      textTransform: "uppercase",
                                      // marginBottom:'30px',
                                      // border:'1px solid black',
                                    }}
                                  >
                                    <h3
                                      style={{
                                        fontSize: REPORT.FONT_SIZE.XLARGE,
                                        fontWeight: 700,
                                        fontFamily: REPORT.FONT_FACE.HEADING,
                                        color: "#0D0F11",
                                        letterSpacing: "2px",
                                        paddingBottom: "10px",
                                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                                      }}
                                    >
                                      {`KEY OBSERVATIONS ON ${channelName}`.toUpperCase()}
                                    </h3>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "920px",
                                      // border: "1px solid grey",
                                      overflow: "hidden",
                                      fontSize: REPORT.FONT_SIZE.XSMALL,
                                      lineHeight: "1.15",
                                      padding: "0 0 0 40px",
                                      marginTop: "-20px",
                                    }}
                                    className="--key-observations --container"
                                  >
                                    {saveKeyObservation ? (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          // width: "96%",
                                          padding: "4px",
                                          overflow: "hidden",
                                          height: "860px",
                                          overflow: "hidden",
                                          // border: "1px solid red",
                                          fontSize: REPORT.FONT_SIZE.XSMALL,
                                          fontFamily:
                                            REPORT.FONT_FACE.PARAGRAPH,
                                          color: "#0D0F11",
                                          // margin:'15px auto 10px 20px'
                                        }}
                                        className="SavedKeyObservations"
                                      >
                                        {ReactHtmlParser(saveKeyObservation)}
                                      </div>
                                    ) : (
                                      <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                          removePlugins: [
                                            "EasyImage",
                                            "ImageUpload",
                                            "MediaEmbed",
                                            "Essentials",
                                            "Table",
                                            "Table Toolbar",
                                            "CkFinderUploadAdapter",
                                            "CKFinder",
                                            "Autoformat",
                                            "Link",
                                            "BlockQuote",
                                          ],
                                          uiColor: "#9AB8F3",
                                        }}
                                        data={
                                          editKeyObservation
                                            ? editKeyObservation
                                            : ""
                                        }
                                        onReady={editor => {
                                          // listToPara()
                                          // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(e, data) => {
                                          seteditKeyObservation(data.getData())
                                          setKeyObservationData(data.getData())
                                          dispatch(handleChangesHappend(true))
                                        }}
                                      />
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col md="5">
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: 6,
                                // marginRight:'18px'
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: "20px",
                                  padding: 6,
                                  width: "80px",
                                }}
                              >
                                {!saveKeyObservation ? (
                                  <>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      size="sm"
                                      id="TooltipSave"
                                      block
                                      onClick={HandleSaveButton}
                                      disabled={isSaved}
                                    >
                                      {isSaved ? "" : "Save"}
                                      {isSaved ? (
                                        <Spinner type="border" size="sm" />
                                      ) : null}
                                    </Button>
                                    <Tooltip
                                      style={{
                                        backgroundColor: "#FEF3C7",
                                        color: "#0D0F11",
                                        zIndex: 1,
                                      }}
                                      placement="left"
                                      isOpen={tooltipForSaveBtn}
                                      target="TooltipSave"
                                      toggle={() => {
                                        setTooltipForSaveBtn(!tooltipForSaveBtn)
                                      }}
                                    >
                                      {tooltipData.infoForSaveBtn}
                                    </Tooltip>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      color="primary"
                                      id="TooltipEdit"
                                      size="sm"
                                      block
                                      onClick={HandleSaveButton}
                                    >
                                      Edit
                                    </Button>
                                    <Tooltip
                                      style={{
                                        backgroundColor: "#FEF3C7",
                                        color: "#0D0F11",
                                        zIndex: 1,
                                      }}
                                      placement="left"
                                      isOpen={tooltipForEditBtn}
                                      target="TooltipEdit"
                                      toggle={() => {
                                        setTooltipForEditBtn(!tooltipForEditBtn)
                                      }}
                                    >
                                      {tooltipData.infoForEditBtn}
                                    </Tooltip>
                                  </>
                                )}
                              </div>
                            </div>
                            <div
                              className="ImageUploadContainer"
                              style={{
                                width: "60%",
                                height: "265px",
                                // border: "1px solid grey",
                                backgroundImage: multiImageUrl
                                  ? "url(" + multiImageUrl[0] + ")"
                                  : "",
                                backgroundColor: "#f0f0f0",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                margin: "130px 40px 0 70px",
                              }}
                            >
                              <div
                                className="container--toggle ContainerAnimation--FadeIn "
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(0, 0, 0, .3)",
                                }}
                              >
                                {/* <span

                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                    // border: "1px solid red",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    transition: "all 1s ease-in-out"
                                  }}
                                >
                                  <Button
                                    size='sm'
                                    color='primary'
                                    style={{
                                      transform: "scale(.8)"
                                    }}
                                    onClick={() => tog_center(0)}
                                  >
                                    Change Image
                                  </Button>
                                </span> */}
                                <span
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                    // border: "1px solid red",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    transition: "all 1s ease-in-out",
                                  }}
                                >
                                  <input
                                    type="file"
                                    id="upload-button"
                                    name="Image_URL"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={handleChange}
                                  />
                                  <Button
                                    size="sm"
                                    id="TooltipUploadImage"
                                    color="primary"
                                    style={{
                                      transform: "scale(1)",
                                      width: "110px",
                                      height: "28px",
                                    }}
                                    onClick={() => {
                                      setIsModalOpen(true)
                                      tog_center(0)
                                      setsaveKeyObservation(null)
                                    }}
                                  >
                                    Upload Image
                                  </Button>
                                  <Tooltip
                                    style={{
                                      backgroundColor: "#FEF3C7",
                                      color: "#0D0F11",
                                      zIndex: 1,
                                    }}
                                    placement="left"
                                    isOpen={tooltipForImageUpload}
                                    target="TooltipUploadImage"
                                    toggle={() => {
                                      setTooltipForImageUpload(
                                        !tooltipForImageUpload
                                      )
                                    }}
                                  >
                                    {tooltipData.infoForUploadImageBtn}
                                  </Tooltip>
                                </span>
                              </div>
                            </div>
                            <div
                              className="ImageUploadContainer"
                              style={{
                                width: "60%",
                                height: "265px",
                                // border: "1px solid grey",
                                backgroundImage: multiImageUrl
                                  ? "url(" + multiImageUrl[1] + ")"
                                  : "",
                                backgroundColor: "#f0f0f0",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                margin: "20px 40px 0 70px",
                              }}
                            >
                              <div
                                className="container--toggle ContainerAnimation--FadeIn "
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(0, 0, 0, .3)",
                                }}
                              >
                                {/* <span

                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                    // border: "1px solid red",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    transition: "all 1s ease-in-out"
                                  }}
                                >
                                  <Button
                                    size='sm'
                                    color='primary'
                                    style={{
                                      transform: "scale(.8)"
                                    }}
                                    onClick={() => tog_center(1)}
                                  >
                                    Change Image
                                  </Button>
                                </span> */}
                                <span
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                    // border: "1px solid red",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    transition: "all 1s ease-in-out",
                                  }}
                                >
                                  <input
                                    type="file"
                                    id="upload-button"
                                    name="Image_URL"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={handleChange}
                                  />
                                  <Button
                                    size="sm"
                                    color="primary"
                                    id="TooltipUploadImage"
                                    style={{
                                      transform: "scale(1)",
                                      width: "110px",
                                      height: "28px",
                                    }}
                                    onClick={() => {
                                      setIsModalOpen(true)
                                      tog_center(1)
                                      setsaveKeyObservation(null)
                                    }}
                                  >
                                    Upload Image
                                  </Button>
                                </span>
                              </div>
                            </div>
                            <div
                              className="ImageUploadContainer"
                              style={{
                                width: "60%",
                                height: "265px",
                                // border: "1px solid grey",
                                backgroundImage: multiImageUrl
                                  ? "url(" + multiImageUrl[2] + ")"
                                  : "",
                                backgroundColor: "#f0f0f0",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                margin: "20px 40px 0 70px",
                              }}
                            >
                              <div
                                className="container--toggle ContainerAnimation--FadeIn "
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(0, 0, 0, .3)",
                                }}
                              >
                                {/* <span

                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                    // border: "1px solid red",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    transition: "all 1s ease-in-out"
                                  }}
                                >
                                  <Button
                                    size='sm'
                                    color='primary'
                                    style={{
                                      transform: "scale(.8)"
                                    }}
                                    onClick={() => tog_center(2)}
                                  >
                                    Change Image
                                  </Button>
                                </span> */}

                                <span
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                    // border: "1px solid red",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    transition: "all 1s ease-in-out",
                                  }}
                                >
                                  <input
                                    type="file"
                                    id="upload-button"
                                    name="Image_URL"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={handleChange}
                                  />
                                  <Button
                                    size="sm"
                                    color="primary"
                                    id="TooltipUploadImage"
                                    style={{
                                      transform: "scale(1)",
                                      width: "110px",
                                      height: "28px",
                                    }}
                                    onClick={() => {
                                      setIsModalOpen(true)
                                      tog_center(2)
                                      setsaveKeyObservation(null)
                                    }}
                                  >
                                    Upload Image
                                  </Button>
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col
                            md="12"
                            style={{
                              // border: '1px solid black'
                              position: "absolute",
                              bottom: 0,
                              zIndex: 100,
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                fontSize: REPORT.FONT_SIZE.MEDIUM,
                                padding: "5px 5px 5px 40px",
                                backgroundColor: REPORT.COLOR.FOOTER,
                                fontFamily: REPORT.FONT_FACE.HEADING,
                                letterSpacing: "2px",
                                opacity: 0.9,
                                fontWeight: 600,
                                color: "white",
                              }}
                            >
                              {`${channelName} - Key Observations`.toUpperCase()}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    {/* Select list */}

                    {!saveKeyObservation && (
                      <Col md="4">
                        <div
                          style={{
                            width: "100%",
                            height: REPORT_PAGE.height,
                            // border: "1px solid grey",
                            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, .1)",
                            marginLeft: "-55PX",
                          }}
                        >
                          <Row>
                            <Col md="12">
                              <div
                                style={{
                                  width: "100%",
                                  height: "36px",
                                  padding: "4px",
                                  // border: "1px solid grey",
                                  boxShadow:
                                    "0px 4px 10px -2px rgba(0, 0, 0, .1)",
                                }}
                              >
                                <Row className="g-0">
                                  <Col md="8"></Col>
                                  <Col md="4">
                                    <Button
                                      size="sm"
                                      color="primary"
                                      id="TooltipInsert"
                                      block
                                      onClick={listToPara}
                                      // disabled={isApplied}
                                    >
                                      Insert Selected Content
                                    </Button>
                                    <Tooltip
                                      style={{
                                        backgroundColor: "#FEF3C7",
                                        color: "#0D0F11",
                                        zIndex: 1,
                                      }}
                                      placement="right"
                                      isOpen={tooltipForInsertBtn}
                                      target="TooltipInsert"
                                      toggle={() => {
                                        setTooltipForInsertBtn(
                                          !tooltipForInsertBtn
                                        )
                                      }}
                                    >
                                      {tooltipData.infoForInsertBtn}
                                    </Tooltip>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col md="12">
                              <div>
                                <ul
                                  style={{
                                    height: "1020px",
                                    fontSize: REPORT.FONT_SIZE.XSMALL,
                                    padding: "10px",
                                    overflow: "scroll",
                                    listStyleType: "none",
                                  }}
                                >
                                  {channelDetails.id &&
                                    channelDetails.keyObservations[0] &&
                                    channelDetails.keyObservations.map(
                                      (data, i) => (
                                        <li
                                          key={i}
                                          style={{
                                            marginBottom: "12px",
                                          }}
                                        >
                                          <Input
                                            // disabled={isApplied}
                                            className="executiveSummaaryContent"
                                            type="checkbox"
                                            value={data}
                                            style={{
                                              width: "17px",
                                              height: "16px",
                                              margin: "-1px 0 5px 0",
                                              textAlign: "left",
                                              color: "#0D0F11",
                                            }}
                                            onChange={e => {
                                              e.target.checked &&
                                                setKeyObservationData(x => [
                                                  ...x,
                                                  e.target.value,
                                                ])
                                            }}
                                          />
                                          &nbsp;&nbsp;
                                          {data.replace(/(<([^>]+)>)/gi, "")}
                                        </li>
                                      )
                                    )}
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default KeyObservations
