import { takeEvery, put, call,all,fork  } from "redux-saga/effects";

// Login Redux States
import {
    ADD_ACCOUNT_HOLDER,
    // DELETE_ACCOUNT_HOLDER,
     UPDATE_ACCOUNT_HOLDER
} from "./actionTypes"
import {
    addAccountHolderSuccess,
    addAccountHolderFail,

    updateAccountHolderSuccess,
    updateAccountHolderFail,

    // deleteAccountHolderSuccess,
    // deleteAccountHolderFail


    
} from "./actions"
                                      
import { addAccountHolder} from "../../helpers/fakebackend_helper";
 
// import{deleteAccountHolder} from "../../helpers/fakebackend_helper";



function* onAddAccountHolder({ payload: user}) {
  try {
    const response = yield call(addAccountHolder, user);
    yield put(addAccountHolderSuccess(response));
  } catch (error) {
    yield put(addAccountHolderFail(error));
  }
}



// function* onDeleteAccountHolder({payload: user}){
//   try {
//     const response = yield call(deleteAccountHolder, user);
//     yield put(deleteAccountHolderSuccess(response));
//   } catch (error) {
//     yield put(deleteAccountHolderFail(error));
//   }

// }
function* addaccountholderSaga (){
  yield takeEvery(ADD_ACCOUNT_HOLDER, onAddAccountHolder);
  //yield takeEvery(UPDATE_ACCOUNT_HOLDER, onUpdateAccountHolder);
  // yield takeEvery(DELETE_ACCOUNT_HOLDER, onDeleteAccountHolder);

}

export default addaccountholderSaga

