import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  CardText,
  FormGroup,
  Spinner,
  Button,
  Tooltip
} from "reactstrap"

import classnames from "classnames"
import "./New_Dashboard.css"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import CreditBox from "./CreditBox"

import {
  getAccountDetails,
  getAccountHolderUsers,
  getScorecardsList,
  clearAccountHolderUsersState,
  clearScorecardList,

  NewDashboardScorecount,
  ClearNewDashboard

} from "store/actions"
import NotFound from "components/Common/NotFound/NotFound.component"
import { ACCOUNT_OWNER, NETWORK_ERROR } from "config/AlertMessage.config"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import Profile from "../../../assets/images/UserProfileTopBarIcon/Profile.png"
import { PanoramaSharp } from "@mui/icons-material"
//import images

const Users = () => {
  const [activeTab, toggleTab] = useState("1")

  const dispatch = useDispatch()
  const [isDataAvail, setIsDataAvail] = useState(true)
  const [paginationReset, setPaginationReset] = useState(false)
  const [scorecradCountArray, setScorecardCountArray] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const [isDataSort, setDataSort] = useState(false)

  // Tooltip for Phone & E-mail
  const [tooltipForPhone, setTooltipForPhone] = useState(false)
  const [tooltipForEmail, setTooltipForEmail] = useState(false)

  const { accountHolderUsers, scorecardsList, accountUserDetails, getScorecardData } =
    useSelector(state => ({
      accountHolderUsers: state.AccountHolderUsersReducer.accountHolderUsers,
      scorecardsList: state.GetScorecardsListReducer.scorecardsList,
      accountUserDetails: state.AccountHolderUsersReducer.accountUserDetails,
      getScorecardData: state.NewDashbordScorecountReducer.getScorecardData
    }))
  const history = useHistory()


  var AccountID = JSON.parse(localStorage.getItem("authUser")).uid

  useEffect(() => {
    dispatch(NewDashboardScorecount({ "id": AccountID }))

  }, [dispatch])


  useEffect(() => {
    if (getScorecardData.status === 403
    ) {
      setIsDataAvail(false)

    }
  }, [getScorecardData])

  // Toggle functionality for Tooltip
  // For Phone
  const handleToolTipCloseForPhone = () => {
    setTooltipForPhone(e => !e)
  }

  // For Email
  const handleToolTipCloseForEmail = () => {
    setTooltipForEmail(e => !e)
  }

  // Tooltip Style for Email & Phone
  const TooltipStyle = {
    backgroundColor: '#ffffff',
    color: 'black',
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    height: "40px",
    maxWidth: "100%",
    zIndex: 3,
  }

  const columns = [
    {
      dataField: "contactName",
      text: "Name",
      // sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="gap-2 d-flex align-items-center">
          <div><img src={Profile} alt="Profile_Image" /></div>
          <div style={{ color: "#05727B", fontSize: "17px", fontWeight: "bold" }}>
            {row.contactName}
          </div>
        </div>
      ),
      style: {
        width: '230px'
      }
    },
    {
      dataField: "contactNumber",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="d-flex align-items-center">
          <div className="userlist-tooltip">
            <i
              style={{ fontSize: "17px", color: 'black' }}
              className="mdi mdi-phone-in-talk"
            />
            <div className="tooltiptext">
              {(row.contactNumber ? `+${row.countryCode}` : "") } &nbsp;
              {row.contactNumber || "N.A."}
            </div>
          </div>
        </div>
      ),
      style: {
        width: '60px'
      }
    },
    {
      dataField: "contactEmail",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="d-flex align-items-center">
          <div className="userlist-tooltip">
            <i
              style={{ fontSize: "17px", color: 'black' }}
              className="mdi mdi-email-outline"
            />
            <div className="tooltiptext">
              {row.contactEmail}
            </div>
          </div>
        </div>
      ),
      style: {
        width: '60px'
      }
    },
    {
      dataField: "scorecardCount",
      text: "Scorecard Assigned",
      style: {
        textAlign: 'center'
      },
      headerStyle: {
        textAlign: 'center'
      }
    },
    {
      dataField: "viewUser",
      text: (
        <button
          type="button"
          className="view-all-users-btn"
          onClick={() => {
            history.push("/users")
          }}
        >
          View All Users
          <i className="mdi mdi-arrow-right"></i>
        </button>
      ),
      sort: false,
      headerStyle: {
        textAlign: 'center'
      },
      style: {
        textAlign: 'center'
      }
    },
  ]

  const usersData = !getScorecardData[0] ? [] : getScorecardData.slice(0, 4).map((user, i) => {
    return {
      id: ++i,
      contactName: user.contactName,
      countryCode: user.countryCode,
      contactNumber: user.contactNumber,
      contactEmail: user.contactEmail,
      contactNumber: user.contactNumber,
      scorecardCount: user.count,
      viewUser: (
        <button
          className="view-user-btn"
          onClick={() => {
            history.push("/users/" + user.id)
          }}
        >
          View User
        </button>
      ),
    }
  })

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (usersData).length }];

  const pageOptions = {
    // page: 1,
    // sizePerPage: 10,
    onPageChange: () => setPaginationReset(false),
    // totalSize: productData.length, // replace later with size(customers),
    custom: true,
    sizePerPageList,
  }

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <Row>
          <Col
            className="users-black-card"
            lg="1"
            md="1"
            sm="12"
            xl="1"
            xs="12"
          >
            <h2>Users</h2>
          </Col>
          <Col lg="8" md="8" sm="12" xl="8" xs="12">
            {!getScorecardData[0] ? (
              <div
                style={{
                  width: "100%",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: "center",
                  height: "250px",
                }}
              >
                {!isDataAvail ? (
                  <>
                    {getScorecardData.status === 403 ? (
                      <NotFound
                        Message={ACCOUNT_OWNER.EMPTY_ACCOUNT_USERS}
                        Icon={() => (
                          <i
                            className="bx bxs-user-plus"
                            style={{ borderBottom: "1px solid #000" }}
                          ></i>
                        )}
                        Url="/create/user"
                        IsButton
                        btnText="Create User"
                      />
                    ) : null}
                  </>
                ) : (
                  <Spinner
                    style={{
                      width: "60px",
                      height: "60px",
                      margin: "calc(200px - 100px)",
                      color: "#05727B",
                    }}
                    role="grid"
                    type="border"
                  />
                )}
              </div>
            ) : (
              <PaginationProvider
                pagination={
                  paginationReset
                    ? paginationFactory({
                      ...pageOptions,
                      page: 1,
                    })
                    : paginationFactory(pageOptions)
                }
                keyField="id"
                columns={columns}
                data={usersData}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={usersData}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <div className="table-responsive Rules-Container-Users">
                          <BootstrapTable
                            // rowEvents={rowEvents}
                            keyField={"id"}
                            responsive
                            headerClasses="tableHeaderUser"
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle custom-users-table"}
                            headerWrapperClasses={""}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </Col>
          <Col lg="3" md="3" sm="12" xl="3" xs="12" className="">
            <CreditBox />
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  )
}

export default Users
