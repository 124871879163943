import {
    CREATE_REPORT_TEMPLATE,
    CREATE_REPORT_TEMPLATE_SUCCESS,
    CREATE_REPORT_TEMPLATE_FAIL,
    CREATE_REPORT_TEMPLATE_CLEAR,
    GET_REPORT_TEMPLATE,
    GET_REPORT_TEMPLATE_SUCCESS,
    GET_REPORT_TEMPLATE_FAIL,
    UPDATE_GET_REPORT_TEMPLATE
} from "./actionTypes"

export const createReportTemplate = (data) => {
    return {
        type: CREATE_REPORT_TEMPLATE,
        payload: data
    }
}
export const createReportTemplateSuccess = (data) => {
    return {
        type: CREATE_REPORT_TEMPLATE_SUCCESS,
        payload: data
    }
}
export const createReportTemplateFail = (error) => {
    return {
        type: CREATE_REPORT_TEMPLATE_FAIL,
        payload: error
    }
}
export const clearReportTemplate = () => {
    return {
        type: CREATE_REPORT_TEMPLATE_CLEAR
    }
}
// get report.
export const getReportTemplate = () => {
    return {
        type: GET_REPORT_TEMPLATE,
    }
}
export const getReportTemplateSuccess = (data) => {
    return {
        type: GET_REPORT_TEMPLATE_SUCCESS,
        payload: data
    }
}
export const getReportTemplateFail = (error) => {
    return {
        type: GET_REPORT_TEMPLATE_FAIL,
        payload: error
    }
}

// update create report
export const UpdateGetReportTemplate = (data) => {
    return {
        type: UPDATE_GET_REPORT_TEMPLATE,
        payload: data
    }
}