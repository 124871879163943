import {
    GET_ACCOUNT_TABLE,
    GET_ACCOUNT_TABLE_SUCCESS,
    GET_ACCOUNT_TABLE_FAIL,

    GET_ACCOUNT_DETAILS,
    GET_ACCOUNT_DETAILS_SUCCESS,
    GET_ACCOUNT_DETAILS_FAIL,

    GET_ACCOUNT_USERS,
    GET_ACCOUNT_USERS_SUCCESS,
    GET_ACCOUNT_USERS_FAIL,

    UPDATE_ACCOUNT_HOLDER,
    UPDATE_ACCOUNT_HOLDER_SUCCESS,
    UPDATE_ACCOUNT_HOLDER_FAIL,
    CLEAR_ACCOUNT_OWNER_DETAILS,
    UPDATE_ACCOUNT_TABLE,
    CLEAR_ACCOUNT_DATA_STATE,
    UPDATE_ACCOUNT_DETAILS_STATE,
} from "./actionTypes"


export const getAccountData = () => ({
    type: GET_ACCOUNT_TABLE,
});

export const getAccountDataSuccess = data => ({
    type: GET_ACCOUNT_TABLE_SUCCESS,
    payload: data,

});
export const getAccountDataFail = error => ({
    type: GET_ACCOUNT_TABLE_FAIL,
    payload: error,
});

export const updateAccountDataState = (data) => ({
    type: UPDATE_ACCOUNT_TABLE,
    payload: data
});

export const clearAccountDataState = () => ({
    type: CLEAR_ACCOUNT_DATA_STATE,
    payload: []
});

export const getAccountDetails = (id) => ({
    type: GET_ACCOUNT_DETAILS,
    id,
});
export const getAccountDetailsSuccess = data => ({
    type: GET_ACCOUNT_DETAILS_SUCCESS,
    payload: data,
    
});

export const getAccountDetailsFail = error => ({
    type: GET_ACCOUNT_DETAILS_FAIL,
    payload: error,
    
})

export const ClearAccountOwnerDetails = () => {
    return {
        type: CLEAR_ACCOUNT_OWNER_DETAILS
    }
}

export const updateAccountDetailsState = (data) => ({
    type: UPDATE_ACCOUNT_DETAILS_STATE,
    payload: data
})





