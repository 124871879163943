import {
    UPDATE_TACTIC_DETAILS_SUCCESS,
    UPDATE_TACTIC_DETAILS_FAIL,
    CLEAR_TACTIC_DETAILS,

    EDIT_TACTIC_DETAIL,
    EDIT_TACTIC_DETAIL_SUCCESS,
    EDIT_TACTIC_DETAIL_FAIL,
    CLEAR_EDIT_TACTIC_DETAIL
} from "./acyionTypes"

const INIT_STATE = {
    updateTactic:[],

    editTactic:{},
    error:{}

}

const UpdateTacticDetailsReducer = (state = INIT_STATE , action) =>{
    switch (action.type) {

        case UPDATE_TACTIC_DETAILS_SUCCESS:
            return{
               ...state,
               updateTactic: action.payload

            }

        case UPDATE_TACTIC_DETAILS_FAIL:
            return{
                ...state,
                error:action.payload
            }

        case CLEAR_TACTIC_DETAILS:
           return{
               ...state,
               updateTactic:[]
           }

        case EDIT_TACTIC_DETAIL_SUCCESS:
            return{
               ...state,
               editTactic: action.payload

            }
        case EDIT_TACTIC_DETAIL_FAIL:
            return{
                ...state,
                error:action.payload
            }

        case CLEAR_EDIT_TACTIC_DETAIL:
            return{
                ...state,
                editTactic:{}
            }

           default:
        return state
    }

}

export default UpdateTacticDetailsReducer 