import {
  NEW_DASHBOAD_SCORECOUNT,
  NEW_DASHBOAD_SCORECOUNT_SUCCESS,
  NEW_DASHBOAD_SCORECOUNT_FAIL,
  CLEAR_NEW_DASHBOARD_SCORECOUNT
} from "./actionTypes"

export const NewDashboardScorecount = (data) => {
  return {
    type: NEW_DASHBOAD_SCORECOUNT,
    payload: data,
  }
}

export const NewDashboardScorecountSuccess = (data) => {
  return {
    type: NEW_DASHBOAD_SCORECOUNT_SUCCESS,
    payload: data,
  }
}

export const NewDashboardScorecountFail = (error) => {
  return {
    type: NEW_DASHBOAD_SCORECOUNT_FAIL,
    payload: error,
  }
}

export const ClearNewDashboard = () => {
    return {
        type:CLEAR_NEW_DASHBOARD_SCORECOUNT
    }
}