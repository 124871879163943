import React, { useEffect, useState } from 'react';
import cap from '../../../../../assets/images/reportScorcard/capture.png';
import '../config_v0_1/style.config.js'
import "../config_v0_1/style.config.css"
import { REPORT, REPORT_PAGE } from '../config_v0_1/style.config';
import PAFCard from './component/PAFCard.component.jsx'
import {
  Row, Col,
} from "reactstrap";



const AreaFocus = (props) => {
  const PROPS = props;
  const { Priority_area_of_focus } = PROPS
  return (
    <>
      <Col xs="12">
        <div style={REPORT_PAGE}>
          <Row style={REPORT_PAGE}>
            <Col xs="12" >
              <div style={{
                width: "100%",
                height: REPORT_PAGE.height,
                // border: "1px solid grey",
              }}>
                <Row >
                  <Col xs="12">
                    <div style={{
                      margin: "auto",
                      // width: "90%",
                      width: "100%",
                      height: "120px",
                      // padding: "30px 0 25px 18px",
                      padding: "30px 40px 0 40px",
                      position: "relative",
                      textTransform: "uppercase",
                      marginBottom: '30px',
                      // border: "1px solid grey",
                      // padding: "15px 0 10px 30px",

                    }} > 
                      <h2 style={{
                        fontSize: REPORT.FONT_SIZE.XLARGE,
                        fontWeight: 600,
                        color:'#0D0F11',
                        fontFamily: REPORT.FONT_FACE.HEADING,
                        // paddingBottom: "8px",
                        paddingBottom: "10px",
                        letterSpacing:'2px',
                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                      }}>PRIORITY
                        <span style={{
                          fontFamily: REPORT.FONT_FACE.HEADING,
                          letterSpacing:'2px'
                        }}><br></br>AREAS OF FOCUS</span>
                      </h2>
                    </div>
                  </Col>
                </Row>
                {/* Main Row for cards */}
                <Row style={{
                  padding: '0 50px 22px 40px',
                  color: '#0D0F11'
                }} >
                  {
                    Priority_area_of_focus ?
                      Priority_area_of_focus && Priority_area_of_focus.priorityAreaOfFocus.map((priority, i) => (
                        <Col xs="6" key={i}>
                          <PAFCard
                            Index={i}
                            Title={priority.title}
                            Icon={priority.icon}
                            Description={priority.description}
                          />
                        </Col>
                      ))
                      :
                      [{}, {}, {}, {}].map((priority, i) => (
                        <Col xs="6" key={i}>
                          <PAFCard
                            Index={i}
                            Title={priority.title}
                            Icon={cap}
                          />
                        </Col>
                      ))
                  }
                </Row>
                <Col xs="12" style={{
                  // border: '1px solid black'
                  position: "absolute",
                  bottom: 0,
                  zIndex: 100,
                }}>
                  <div style={{
                    width: '100%',
                    fontSize: REPORT.FONT_SIZE.MEDIUM,
                    padding: '5px 5px 5px 40px',
                    backgroundColor: REPORT.COLOR.FOOTER,
                    opacity: 0.9,
                    fontWeight: 600,
                    fontFamily:REPORT.FONT_FACE.HEADING,
                    letterSpacing:'2px',
                    color: 'white'
                  }}>PRIORITY AREAS OF FOCUS</div>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
}

export default AreaFocus;