import { call, put, takeEvery } from "redux-saga/effects"

import {UPDATE_TACTIC_DETAILS, EDIT_TACTIC_DETAIL} from "./acyionTypes"

import {updateTacticDetailsSuccess,updateTacticDetailsFail, editTacticDetailsSuccess,editTacticDetailsFail} from "./actions"

import {updateTacticDetails, editTacticDetails} from "../../helpers/fakebackend_helper"

function* updateTactic({channelId ,tacticId ,  payload:data}){
    try{
        const response = yield call (updateTacticDetails , channelId , tacticId,data)
        yield put(updateTacticDetailsSuccess(response))
    }
    catch(error){
        yield put(updateTacticDetailsFail(error))


    }
}

function* editTactic({tacticId,channelId, payload:data}){
    try{
        const response = yield call (editTacticDetails ,channelId, tacticId,data)
        yield put(editTacticDetailsSuccess(response))
    }
    catch(error){
        yield put(editTacticDetailsFail(error))

    }

}

function* updateTacticDetailsSaga(){
    yield takeEvery(UPDATE_TACTIC_DETAILS, updateTactic)
    yield takeEvery(EDIT_TACTIC_DETAIL , editTactic)

}

export default updateTacticDetailsSaga