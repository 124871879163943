import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { useHistory, useParams, withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import {
  getChannelData,
  getChannelDetails,
  handleUnsavedChanges,
  GetRoutes,
} from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"

const SidebarForContentEditor = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const ref = useRef()

  const { channelData, isThereChanges, getRoutes } = useSelector(state => ({
    channelData: state.GetChannelData.channelData,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    getRoutes: state.ScorecardReport_Reducer.getRoutes,
  }))

  useEffect(() => {
    !channelData[0] && dispatch(getChannelData())
  }, [channelData,dispatch])

  useEffect(() => {
    window.localStorage.setItem("channelList", JSON.stringify(channelData))

    // return () => window.localStorage.removeItem("channelList")
  }, [channelData])

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    // window.location.reload(false);

    const pathName = props.location.pathname
    // console.log(pathName)
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, params.channelId])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  //console.log("chaneelsss...", channelData)

  const bubble = {
    display: "block",
    backgroundColor: "transperent",
    padding: "2px",
    width: "25px",
    textAlign: "center",
    lineHeight: "30px",
    height: "25px",
    color: "#000",
    borderRadius: "20px",
    transform: "scale(.65)",
    position: "absolute",
    right: "2px",
    top: "0px",
    zIndex: 100,
    justifyContent: "center",
   
    // fontWeight: "bold",
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" >
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("")} </li>

            {/* create channel */}
            <li
              onClick={() => {
                if (isThereChanges) {
                  dispatch(handleUnsavedChanges(true))
                }
                dispatch(GetRoutes("/dashboard"))
              }}
            >
              <Link to={!isThereChanges && "/dashboard"}>
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Create Channel")}</span>
              </Link>
            </li>

             <li
              onClick={() => {
                if (isThereChanges) {
                  dispatch(handleUnsavedChanges(true))
                }
                dispatch(GetRoutes("/Arrange-Sequence"))
              }}
            >
              <Link to={!isThereChanges && "/Arrange-Sequence"}>
                <i className="bx bx-list-ul"></i>
                <span style={{ marginLeft: 2 }}>
                  {props.t("Arrange Channel")}
                </span>
                &nbsp;&nbsp;&nbsp;{" "}
                <span style={{ marginLeft: 33 }}>{props.t("Sequence")}</span>
              </Link>
            </li> 

            {/* Edit channel */}
            <li>
              <Link to="/ViewChannel" className="has-arrow">
                <i className="bx bx-edit-alt"></i>
                <span>{props.t("Edit Channels")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false" >
                {channelData && channelData.map((channel, p) => (
                  <li
                    key={p}
                    onClick={() => {
                      if (isThereChanges) {
                        dispatch(handleUnsavedChanges(true))
                      }
                      dispatch(
                        GetRoutes(
                          `/channel-details/${channel.channelId}/${channel.channelId}`
                        )
                      )
                    }}
                  >
                    <Link
                      to={
                        !isThereChanges &&
                        `/channel-details/${channel.channelId}/${channel.channelId}`
                      }
                    >
                     

                    

                      {props.t(channel.channelName)}
                        {channel.isDisabled === false? 
                        <span style ={bubble}>
                         <i className ="mdi mdi-check-circle"style={{color:"#8EB820", fontSize:'27px'}}></i> 

                        </span>
                         
                          :
                          <span style = {bubble}>
                          <i className ="mdi mdi-check-circle" style = {{color:"#908F8F", fontSize:'27px'}}></i>

                          </span>}
                            
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            {/* create tactic */}
            <li
              onClick={() => {
                if (isThereChanges) {
                  dispatch(handleUnsavedChanges(true))
                }
                dispatch(GetRoutes("/create-tactic"))
              }}
            >
              <Link to={!isThereChanges && "/create-tactic"}>
                <i className="bx bx-file-blank"></i>
                <span>{props.t("Create Tactic")}</span>
              </Link>
            </li>
            {/* Edit tactic */}


            <li>
              <Link to="/#" className="has-arrow">
                <i className="mdi mdi-playlist-edit"></i>

                <span>{props.t("Edit Tactic")}</span>
              </Link>
              <ul className="sub-menu" >
                {channelData
                  .filter(channel_ => channel_.tactics[0] !== undefined)
                  .map((channel, p) => (
                    <li key={p}>
                      <Link to="/#" className="has-arrow">
                        {props.t(channel.channelName)}
                      </Link>
                      <ul className="sub-menu test-ul" >
                        {channel.tactics.map((tactic, i) => (
                          <li
                            key={i}
                            onClick={() => {
                              if (isThereChanges) {
                                dispatch(handleUnsavedChanges(true))
                              }
                              dispatch(
                                GetRoutes(
                                  `/tactic-details/${channel.channelId}/tactics/${tactic.tacticId}/${tactic.tacticId}`
                                )
                              )
                            }}
                          >
                            <Link
                              to={
                                !isThereChanges &&
                                `/tactic-details/${channel.channelId}/tactics/${tactic.tacticId}/${tactic.tacticId}`
                              }
                            >
                               {/* {tactic.isDisabled === false? <i className ="mdi mdi-check-circle"style={{color:"#8EB820"}}></i> : <i className ="mdi mdi-check-circle" style = {{color:"#908F8F"}}></i>} */}
                               {props.t(tactic.tacticName)}
                               {tactic.isDisabled === false ?
                               <span style ={bubble}>
                         <i className ="mdi mdi-check-circle"style={{color:"#8EB820", fontSize:'27px', paddingTop:'5px'}}></i> 

                        </span>
                         
                          :
                          <span style = {bubble}>
                          <i className ="mdi mdi-check-circle" style = {{color:"#908F8F", fontSize:'27px', paddingTop:'5px'}}></i>

                          </span>}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
              </ul>
            </li>

            {/* report template */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-message-rounded-x"></i>
                <span style={{ marginLeft: 2 }}>
                  {props.t("Scorecard Report")}
                </span>
                &nbsp;&nbsp;&nbsp;{" "}
                <span style={{ marginLeft: 33 }}>{props.t(" Template")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li
                  onClick={() => {
                    if (isThereChanges) {
                      dispatch(handleUnsavedChanges(true))
                    }
                    dispatch(GetRoutes("/Report"))
                  }}
                >
                  <Link to={!isThereChanges && "/Report"}>
                    {props.t("Cover Page & Table of Contents")}
                  </Link>
                </li>
                <li
                  onClick={() => {
                    if (isThereChanges) {
                      dispatch(handleUnsavedChanges(true))
                    }
                    dispatch(GetRoutes("/Summary"))
                  }}
                >
                  <Link to={!isThereChanges && "/Summary"}>
                    {props.t("Executive Summary")}
                  </Link>
                </li>
                <li
                  onClick={() => {
                    if (isThereChanges) {
                      dispatch(handleUnsavedChanges(true))
                    }
                    dispatch(GetRoutes("/PriorityArea"))
                  }}
                >
                  <Link to={!isThereChanges && "/PriorityArea"}>
                    {props.t("Priority Areas of Focus")}
                  </Link>
                </li>
             
                <li
                  onClick={() => {
                    if (isThereChanges) {
                      dispatch(handleUnsavedChanges(true))
                    }
                    dispatch(GetRoutes("/KeyTakeaways"))
                  }}
                >
                  <Link to={!isThereChanges && "/KeyTakeaways"}>
                    {props.t("Key Takeaways")}
                  </Link>
                </li>
              </ul>
            </li>

            {/* For "i" button POC */}
             <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-pen" ></i>
                <span >{props.t("Instruction Panel")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link className="has-arrow" to="/#">
                    <span>{props.t("Account User")}</span>
                  </Link>
                  <ul className="sub-menu test-ul"> 

                    {/* Cover Page */}
                     <li
                      onClick={() => {
                        if (isThereChanges) {
                          dispatch(handleUnsavedChanges(true))
                        }
                        dispatch(GetRoutes("/instructionForAccountUser/coverPage"))
                      }}
                    >
                      <Link to={!isThereChanges && "/instructionForAccountUser/coverPage"}>
                        {props.t("Cove Page")}
                      </Link>
                    </li> 

                    {/* Table of Content Page */}
                     <li
                      onClick={() => {
                        if (isThereChanges) {
                          dispatch(handleUnsavedChanges(true))
                        }
                        dispatch(GetRoutes("/instructionForAccountUser/tableOfContents"))
                      }}
                    >
                      <Link to={!isThereChanges && "/instructionForAccountUser/tableOfContents"}>
                        {props.t("Table Of Contents")}
                      </Link>
                    </li> 


                    {/* Executive Summary */}
                     <li
                      onClick={() => {
                        if (isThereChanges) {
                          dispatch(handleUnsavedChanges(true))
                        }
                        dispatch(GetRoutes("/instructionForAccountUser/executiveSummary"))
                      }}
                    >
                      <Link to={!isThereChanges && "/instructionForAccountUser/executiveSummary"}>
                        {props.t("Executive Summary")}
                      </Link>
                    </li> 

                   { /*Priority Area*/}
                   <li
                      onClick={() => {
                        if (isThereChanges) {
                          dispatch(handleUnsavedChanges(true))
                        }
                        dispatch(GetRoutes("/instructionForAccountUser/priorityArea"))
                      }}
                    >
                      <Link to={!isThereChanges && "/instructionForAccountUser/priorityArea"}>
                        {props.t("Priority Areas of Focus")}
                      </Link>
                    </li> 

                  

                     
                    { /*Key Observations*/}
                   <li
                      onClick={() => {
                        if (isThereChanges) {
                          dispatch(handleUnsavedChanges(true))
                        }
                        dispatch(GetRoutes("/instructionForAccountUser/keyObservations"))
                      }}
                    >
                      <Link to={!isThereChanges && "/instructionForAccountUser/keyObservations"}>
                        {props.t("Key Observations")}
                      </Link>
                    </li> 

                    { /*Doing Well*/}
                   <li
                      onClick={() => {
                        if (isThereChanges) {
                          dispatch(handleUnsavedChanges(true))
                        }
                        dispatch(GetRoutes("/instructionForAccountUser/doingWell"))
                      }}
                    >
                      <Link to={!isThereChanges && "/instructionForAccountUser/doingWell"}>
                        {props.t("What You Are Doing Well")}
                      </Link>
                    </li> 

                    { /*Quick Wins*/}
                   <li
                      onClick={() => {
                        if (isThereChanges) {
                          dispatch(handleUnsavedChanges(true))
                        }
                        dispatch(GetRoutes("/instructionForAccountUser/quickWins"))
                      }}
                    >
                      <Link to={!isThereChanges && "/instructionForAccountUser/quickWins"}>
                        {props.t("Quick Wins")}
                      </Link>
                    </li> 

                    { /*Recommendation*/}
                   <li
                      onClick={() => {
                        if (isThereChanges) {
                          dispatch(handleUnsavedChanges(true))
                        }
                        dispatch(GetRoutes("/instructionForAccountUser/recommendation"))
                      }}
                    >
                      <Link to={!isThereChanges && "/instructionForAccountUser/recommendation"}>
                        {props.t("Recommendation")}
                      </Link>
                    </li> 

                    { /*Key Takeaways*/}
                   <li
                      onClick={() => {
                        if (isThereChanges) {
                          dispatch(handleUnsavedChanges(true))
                        }
                        dispatch(GetRoutes("/instructionForAccountUser/keyTakeaways"))
                      }}
                    >
                      <Link to={!isThereChanges && "/instructionForAccountUser/keyTakeaways"}>
                        {props.t("Key Takeaways")}
                      </Link>
                    </li> 






                    
                   </ul>
                </li>
              </ul>
            </li> 
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarForContentEditor.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarForContentEditor))
