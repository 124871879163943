import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Data } from "../../../common/data/buyCredits"
import SweetAlert from "react-bootstrap-sweetalert"

import {
  getProduct,
  ClearProduct,
  checkoutRequestData,
  ClearCheckoutData,
} from "../../../store/actions"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Table,
  Button,

} from "reactstrap"
// Editable

import cellEditFactory from "react-bootstrap-table2-editor"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import NotFound from "components/Common/NotFound/NotFound.component"
import { ACCOUNT_OWNER } from "config/AlertMessage.config"

import "./creditTable.scss"
import axios from "axios"

import BuyButton from "./BuyButton"

const Credits = (para) => {
  const parameter = para
  const { setShowSpinner } = parameter
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const [isDataAvail, setIsDataAvail] = useState(true)
  const [paginationReset, setPaginationReset] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const [stopSpinner, setStopSpinner] = useState(false)


  const { getProductList, checkoutData } = useSelector(state => ({
    getProductList: state.GetProductReducer.getProductList,
    checkoutData: state.GetProductReducer.checkoutData,
  }))

  useEffect(() => {
    // var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)
    if (getProductList[0]) {
      var search_bar = document.querySelector("#search-bar-0")
      search_bar.setAttribute("autoComplete", "off")
      // clearTimeout(WaitForData)
    }
  }, [getProductList])

  useEffect(() => {
    if (getProductList && getProductList) {
      dispatch(getProduct())
    }
  }, [dispatch])

  // console.log(checkoutData)

  // var accountOwner = JSON.parse(localStorage.getItem("authUser"))
  //console.log(accountOwner.uid)

  const HandlePay = item => {
    var customerId = JSON.parse(localStorage.getItem("authUser")).uid
    //var customerId = "xxsasrefass"
    var quantity = item.prices.transform_quantity && item.prices.transform_quantity
      ? item.prices.transform_quantity.divide_by
      : 1
    var priceId = item.prices.id

    var isAdjustableQty = quantity === 1 ? true : false

    var successUrl = `${window.location.origin}/success`
    var cancelUrl = `${window.location.origin}/cancel`

    var Checkout_data = {
      customerId,
      quantity,
      priceId,
      successUrl,
      cancelUrl,
      isAdjustableQty,
    }

    // console.log(Checkout_data)
    // setShowSpinner(true)

    dispatch(checkoutRequestData(Checkout_data))
  }

  // console.log(HandlePay())

  useEffect(() => {

    if (checkoutData !== "") {


      //
      if (checkoutData.response && checkoutData.response.status === 400) {




        setShowAlert(true)

      } else {
        window.location.replace(checkoutData)
        dispatch(ClearCheckoutData())


      }
    }
  }, [checkoutData])

  //  useEffect(()=>{
  //    showSpinner ? 
  //    <Spinner type="border" size="sm" />
  //    :
  //    null
  //  })


  const columns = [
    // {
    //   dataField: 'status',
    //   text: '',
    //   sort: false,

    // },
    {
      dataField: "name",
      text: "Product Name",
      sort: true,
    },
    {
      dataField: "credit",
      text: "Credit",
      sort: true,
    },
    {
      dataField: "price",
      text: "Per Unit Amount",
      sort: true,
    },
    {
      dataField: "total",
      text: "Total Amount",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },

  ]

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    // page:2,
    // sizePerPage: 10,
    onPageChange: () => setPaginationReset(false),
    // totalSize: AccountData.length, // replace later with size(customers),
    custom: true,
  }

  const GetPrice = item => {
    var unitPrice = item.prices.unit_amount
    var transformCredits = item.prices.transform_quantity
      ? item.prices.transform_quantity.divide_by
      : 1

    return unitPrice / 100 / transformCredits
  }


  const PurchaseData = getProductList ? getProductList.map((item, index) => {
    return {
      id: index,
      name: item.name,
      credit: item.prices.transform_quantity && item.prices.transform_quantity
        ? item.prices.transform_quantity.divide_by
        : 1,
      price: "$ " + GetPrice(item),
      total: "$ " + item.prices.unit_amount / 100,
      action: (
        <BuyButton
          HANDLE_PAY={() => HandlePay(item)}
          showAlert={showAlert}
        // setShowAlert = {setShowAlert}

        />

      ),
    }
  })
    :
    null







  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: PurchaseData.length },
  ]

  const { SearchBar } = Search
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Buy Credits</title>
        </MetaTags>
        <div className="container-fluid">
          {/* <div style={
            {
              width: "100%",
              textAlign: "center",
              height: "400px"
            }
          } >
            {
              !isDataAvail ?
                <NotFound Message={ACCOUNT_OWNER.EMPTY_CREDITS} Icon={() => (
                  <i className="fas fa-cart-plus"
                    style={{
                      borderBottom: "1px solid #000",
                      fontSize:'17px',
                      paddingBottom:'3px'
                    }}
                  ></i>
                )}
                btnText = "Purchase" 
                IsButton
                />
                :
                <Spinner
                  style={{
                    width: "60px", height: "60px",
                    margin: "calc(200px - 30px)",
                  }}
                  role="grid"
                  type="border"
                  color="primary"
                />
            }
          </div> */}
          {showAlert ?
            <SweetAlert


              error
              confirmBtnBsStyle="primary"
              style={{ height: "255px", width: "368px" }}
              onConfirm={() => {



                setShowAlert(false)

                dispatch(ClearCheckoutData())


              }}
            >
              You are not registered

            </SweetAlert>
            : null
          }


          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={
                      paginationReset
                        ? paginationFactory({
                          ...pageOptions,
                          page: 1,
                        })
                        : paginationFactory(pageOptions)
                    }
                    keyField="id"
                    columns={columns}
                    data={PurchaseData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={PurchaseData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="9"></Col>
                              <Col xl="3">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      onClear={() => setPaginationReset(false)}
                                    // ref={search_ref}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // key={"id"}
                                    // rowEvents={rowEvents}
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col
                                className="inner-custom-pagination d-flex"
                                style={{ marginTop: 10 }}
                              >
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Credits
