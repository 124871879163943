import {
    TRANSACTION_SUCCESS,
    TRANSACTION_FAIL,
    CHANGE_TRANSACTION,
    CLEAR_TRANSACTION


} from "./actionTypes"

const INIT_STATE = {
    transaction:[]

}

const TransactionReducer = (state = INIT_STATE , action) => {
    switch(action.type){
        case TRANSACTION_SUCCESS:
            {
                return{
                    ...state,
                    transaction:action.payload
    
                }

            }
            

        case TRANSACTION_FAIL:{
            return{
                ...state,
                error:action.payload

            }

        }
          

        case CLEAR_TRANSACTION:{
            return{
                ...state,
                transaction:[]
            }

        }
            

        case CHANGE_TRANSACTION:{
            return{
                ...state,
                transaction:action.payload

            }

        }

        default:
            return state
           

    }

};

export default TransactionReducer
