import { color, fontSize } from '@mui/system';
import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';  // Added Meta Tag npm Package
import { Grid } from "@mui/material";
import img from '../../../../assets/images/reportScorcard/spiderimg.PNG';
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import RadarChart from 'pages/AccountUser/chart/chart';
import { REPORT, REPORT_PAGE } from '../config/style.config';


import {
  Table,
  Container, Row, Col,
  Alert,
  Card, CardBody, CardTitle, FormGroup, Spinner, Form, Input, Button
} from "reactstrap";
import { Opacity } from '@mui/icons-material';
import Footer from 'components/VerticalLayout/Footer';
import { useSelector, useDispatch } from "react-redux"


import {
  // getReportTemplate,
  getScorecardReport
} from "store/actions"
import { getChannelScore } from 'commonFunctions/GetChannelScore/getChannelScore';
import { useParams } from 'react-router-dom';

const ScoreAtGlance = () => {


  const params = useParams()
  const dispatch = useDispatch()

  const { scorecardReport_data } = useSelector(state => ({
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
  }))

  useEffect(() => {
    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
    // dispatch(getScorecardReport(params.scorecardId))
  }, [dispatch])

  // console.log("SCORECARD REPORT DATA... ",scorecardReport_data)

  const channelClassifier = (data, level) => {
    if (scorecardReport_data.selectedChannels) {
      var channelName =

        scorecardReport_data && [...scorecardReport_data.selectedChannels]
          .sort((a, b) => getChannelScore(b) - getChannelScore(a))
          .map(channel => {
            var channelScore = getChannelScore(channel)

            if (channelScore <= data && (data === 100 ? channelScore >= (data - level) : channelScore > (data - level))) {
              return channel.channelName
            }
          })
        ||
        [...scorecardReport_data.selectedChannels]
          .sort((a, b) => getChannelScore(b) - getChannelScore(a))
          .map(channel => {
            var channelScore = getChannelScore(channel)

            if (channelScore <= data && (data === 100 ? channelScore >= (data - level) : channelScore > (data - level))) {
              return channel.channelName
            }

          })
    }
    return channelName && channelName.filter(channel => channel !== undefined) || []
  }



  return (
    <>
      <div className="page-content" >
        <MetaTags>
          <title> Mambo -Report</title>
        </MetaTags>
        <Container fluid={true} style={{
          transform: "scale(.8) translate(-130px, -132px) ",
          width: "1400px",
        }}>
          <Row>
            <Col className="col-12 m-auto "  >
              <Card>
                <CardBody>
                  <Row  >
                    <Col md="12">
                      <div style={{ ...REPORT_PAGE }}>

                        <Row className='g-0'>
                          <Col md="12" >
                            <div style={{
                              width: "100%",
                              height: REPORT_PAGE.height,
                              // border: "1px solid grey"
                            }} >

                              <Row>
                                <Col md="12" >
                                  <div style={{
                                    margin: "auto",
                                    width: "100%",
                                    height: "100px",
                                    // padding: "30px 0 25px 8px",
                                    padding: "30px 40px 0 40px",
                                    position: "relative",
                                    textTransform: "uppercase"
                                  }} >

                                    <h1 style={{
                                      fontSize: REPORT.FONT_SIZE.XLARGE,
                                      fontFamily:REPORT.FONT_FACE.HEADING,
                                      fontWeight: 600,
                                      color: '#0D0F11',
                                      letterSpacing:'2px',
                                      paddingBottom:'10px',
                                      borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                                    }}>
                                      SCORES AT A GLANCE
                                    </h1>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <div style={{
                                    width: '100%',
                                    height: '305px',
                                    // border:'1px solid black',
                                    marginLeft: '70px',
                                    fontSize: REPORT.FONT_SIZE.SMALL,
                                    padding: '10px 100px 0 20px'
                                  }}>

                                    <Table className='table table-bordered mb-0' style={{
                                      height: '130px',
                                      marginTop: 'auto',
                                      fontWeight: 500,
                                      fontSize: REPORT.FONT_SIZE.SMALL,
                                      border:"1px solid #0D0F11"
                                    }}>
                                      <thead border style={{
                                        backgroundColor: '#0D0F11',
                                        color: 'white',
                                        
                                      }}>
                                        <tr>
                                          <th style={{
                                            padding: '0',
                                            paddingLeft: '5px',
                                            textAlign:'center',
                                            border: "2px solid #0D0F11",
                                          }}>Channels</th>
                                          <th style={{
                                            padding: '0',
                                            paddingLeft: '5px',
                                            textAlign:'center',
                                            border: "2px solid #0D0F11"
                                          }}>Scores</th>
                                        </tr>
                                      </thead>
                                      <tbody>

                                        {
                                          scorecardReport_data.id && [...scorecardReport_data.selectedChannels]
                                            .sort((a, b) => getChannelScore(b) - getChannelScore(a))
                                            .map(channel => (
                                              <tr key={channel.channelId} style={{color:'#0D0F11'}}>
                                                <td scope="row" style={{
                                                  padding: '3px',
                                                  border: "2px solid black",
                                                  fontWeight:'bolder'
                                                
                                                  
                                                }}>{channel.channelName}</td>
                                                <td style={{
                                                  padding: '3px',
                                                  textAlign:'center',
                                                  border: "2px solid black",
                                                  fontWeight:'bolder'
                                                }}>{getChannelScore(channel)}%</td>
                                              </tr>
                                            ))
                                        }
                                        
                                      </tbody>
                                    </Table>

                                  </div>
                                </Col>
                                <Col md="6">
                                  <div style={{
                                    width: '100%',
                                    height: '230px',
                                    paddingLeft: '50px',
                                    paddingTop:'5px',
                                    fontSize: '12px',
                                  }}>
                                    <p style={{
                                      color: '#0D0F11',
                                      fontSize: REPORT.FONT_SIZE.LARGE,
                                      margin: '0 10px 13px 0',
                                      fontWeight: 'bolder',
                                      fontFamily:REPORT.FONT_FACE.HEADING
                                    }}>LEVELS OF MATURITY</p>

                                    {
                                      channelClassifier(100, 20)[0] &&
                                      <>
                                        <p style={{
                                          color: '#0D0F11',
                                          // fontSize:'8px',
                                          marginTop:'-5px',
                                          fontWeight: 'bolder',
                                          margin: 0
                                        }}>Mature</p>
                                        <ul style={{
                                          fontWeight: 500
                                        }}>
                                          {
                                            channelClassifier(100, 20)[0] && channelClassifier(100, 20).map((channel, i) => (
                                              <li key={i} style={{color:"#0D0F11"}}>{channel}</li>
                                            ))
                                          }
                                          
                                        </ul>
                                      </>
                                    }
                                    {
                                      channelClassifier(79, 20)[0] &&
                                      <>
                                        <p style={{
                                          color: '#0D0F11',
                                          // fontSize:'8px',
                                          marginTop:'-5px',
                                          fontWeight: 600,
                                          margin: 0
                                        }}>Advanced</p>
                                        <ul style={{
                                          fontWeight: 500
                                        }}>
                                          {
                                            channelClassifier(79, 20)[0] && channelClassifier(79, 20).map((channel, i) => (
                                              <li key={i} style={{color:"#0D0F11"}}>{channel}</li>
                                            ))
                                          }
                                        </ul>
                                      </>
                                    }
                                    {
                                      channelClassifier(59, 20)[0] &&
                                      <>
                                        <p style={{
                                          color: '#0D0F11',
                                          // fontSize:'8px',
                                          marginTop:'-5px',
                                          fontWeight: 600,
                                          margin: 0
                                        }}>Intermediate</p>
                                        <ul style={{
                                          fontWeight: 500
                                        }}>
                                          {
                                            channelClassifier(59, 20)[0] && channelClassifier(59, 20).map((channel, i) => (
                                              <li key={i} style={{color:"#0D0F11"}}>{channel}</li>
                                            ))
                                          }
                                        </ul>
                                      </>
                                    }
                                    {
                                      channelClassifier(39, 40)[0] &&
                                      <>
                                        <p style={{
                                          color: '#0D0F11',
                                          // fontSize:'8px',
                                          marginTop:'-5px',
                                          fontWeight: 600,
                                          margin: 0
                                        }}>Early & Beginner</p>
                                        <ul style={{
                                          fontWeight: 500
                                        }}>
                                          {
                                            channelClassifier(39, 40)[0] && channelClassifier(39, 40).map((channel, i) => (
                                              <li key={i} style={{color:"#0D0F11"}}>{channel}</li>
                                            ))
                                          }
                                        </ul>
                                      </>
                                    }
                                  </div>
                                </Col>
                                {/* To adding space between the Row of Table and Spider Graph Div*/}
                                {/* <Col md='12' style={{ height: '20px', border:'1px solid black' }}></Col> */}

                                <Col md="12">
                                  <div style={{
                                    // width: '100% !important',
                                    height: '510px',
                                    // backgroundColor: '#f0e0c0',
                                    marginTop: '140px',
                                    // paddingTop:'1px',
                                    // transform: "scale(1.1)",
                                    // border:'1px solid black'
                                  }}>
                                    <div style={{
                                      width: "700px",
                                      height: "fit-content",
                                      margin: "15px auto 0 auto",
                                      padding:0,
                                      // border:'1px solid black'

                                    }} >
                                      {scorecardReport_data.selectedChannels &&
                                        <RadarChart DATA={scorecardReport_data} />

                                      }

                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md="12" style={{
                            // border: '1px solid black'
                            position: "absolute",
                            bottom: 0,
                            zIndex: 100,
                          }}>
                            <div style={{
                              width: '100%',
                              fontSize: REPORT.FONT_SIZE.MEDIUM,
                              padding: '5px 5px 5px 40px',
                              backgroundColor: REPORT.COLOR.FOOTER,
                              fontFamily:REPORT.FONT_FACE.HEADING,
                              letterSpacing:'2px',
                              opacity: 0.9,
                              fontWeight: 600,
                              color: 'white'
                            }}>SCORES AT A GLANCE</div>
                          </Col>
                        </Row>

                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >
    </>
  );
}

export default ScoreAtGlance;