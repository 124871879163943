import {
    UPDATE_TACTIC_DETAILS,
    UPDATE_TACTIC_DETAILS_SUCCESS,
    UPDATE_TACTIC_DETAILS_FAIL,
    CLEAR_TACTIC_DETAILS,

    EDIT_TACTIC_DETAIL,
    EDIT_TACTIC_DETAIL_SUCCESS,
    EDIT_TACTIC_DETAIL_FAIL,
    CLEAR_EDIT_TACTIC_DETAIL
} from "./acyionTypes"

export const  updateTacticDetails =(channelId, tacticId ,data) => {
    return{
        type: UPDATE_TACTIC_DETAILS,
        channelId,
        tacticId,
        payload: data
    }
}

export const updateTacticDetailsSuccess = (data) => {
    return{
        type:UPDATE_TACTIC_DETAILS_SUCCESS,
        payload:data

    }
}

export const updateTacticDetailsFail = (error) => {
    return{
        type:UPDATE_TACTIC_DETAILS_FAIL,
        payload:error
    }
}

export const clearUpdateTacticDetails = () => {
    return{
        type:CLEAR_TACTIC_DETAILS
    }
}

export const editTacticDetails = (channelId,tacticId, data) => {
    return{
        type:EDIT_TACTIC_DETAIL,
        
        channelId,
        tacticId,
        
        payload:data
    }

}

export const editTacticDetailsSuccess = (data) => {
    return{
        type:EDIT_TACTIC_DETAIL_SUCCESS,
        payload:data

    }
}

export const editTacticDetailsFail = (error) => {
    return{
        type:EDIT_TACTIC_DETAIL_FAIL,
        payload:error

    }
}

export const clearEditTacticDetails = () => {
    return{
        type:CLEAR_EDIT_TACTIC_DETAIL
    }
}