import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, withRouter } from "react-router-dom";

import firebase from "firebase/compat";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
import SidebarForContentEditor from "./SidebarForContentEditor"
import SidebarContentForSuperAdmin from "./sidebarContentForSuperAdmin"
import SidebarContentForAccountOwner from "./sidebarContentForAccountOwner"
import SideContentForAccountuser from "./SideContentForAccountuser";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import logoLightPng from "../../assets/images/logo.png";
import logoLightSvg from "../../assets/images/logo.png";
import logoDark from "../../assets/images/logo.png";
import TakeASurveySidebar from "./TakeASurvey.sidebar";
import ScorecardReportSideBar from "./ScorecardReport/ScorecardReport.SideBar";
import SelfReportSideBar from "./ScorecardReport/SelfReport.SideBar";
import { GetRoutes, handleCloseReportEditing } from "store/actions";
import Logo from "assets/images/MamboScorecardLogo/Logo.png"

// var checkIfContentEditor = JSON.parse(localStorage.getItem("myClaims") || "") || null

const Sidebar = props => {

  const [data, setData] = useState({})
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const { scorecardDetail } = useSelector(state => ({
    scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
  }))

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdTokenResult().then(token => {
          setData(token.claims)
        }).catch(err => {

        })
      }
    })

    return () => {
      setData(null)
    }
  }, [])

  const handleDashboardNavigation = () => {
    var is_report_editing_path = location.pathname.includes("edit/scorecard-report")
    var is_generate_pdf_path = location.pathname.includes("/generate-pdf")
    var is_take_a_survey_path = location.pathname.includes("/take-a-survey")

    if (is_report_editing_path || is_generate_pdf_path || is_take_a_survey_path) {
      dispatch(GetRoutes("/"))
      dispatch(handleCloseReportEditing(true))
    } else {
      history.push("/")
    }
  }

  return (
    <React.Fragment >
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          {/* {
            checkIfContentEditor.contentEditor?
            <Link to="/dashboard" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoLightSvg} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoLightPng} alt="" height="60" />
              </span>
            </Link> 
            : */}

          <Link to="#"
            className="logo logo-light"
            style={{
              hight: '100px',
              width: '277px',
              backgroundColor: '#FFFFFF',
              marginRight: '50px',
              marginLeft: "-30px"
            }}
            onClick={handleDashboardNavigation}
          >
            <span className="logo-sm">
              <img src={Logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={Logo} alt="" height="50" />
            </span>
          </Link>

          {/* } */}

          <Link
            onClick={handleDashboardNavigation}
            to="#" className="logo logo-dark">
            <span className="logo-sm">
              <img src={Logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={Logo} alt="" height="60" />
            </span>
          </Link>

          {/* <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="60" />
            </span>
          </Link> */}

        </div>
        <div data-simplebar className="h-100">

          {/* {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />} */}


          {
            data.superAdmin && <SidebarContentForSuperAdmin />
          }


          {
            data.admin && params.tacticId ?
              <TakeASurveySidebar />
              :
              data.admin && history.location.pathname.includes("/edit/scorecard-report") && params.scorecardId ?
                scorecardDetail && scorecardDetail.scorecardVersion === "Pro" ? 
                <ScorecardReportSideBar /> : <SelfReportSideBar />
                :
                data.admin && <SidebarContentForAccountOwner />
          }


          {
            data.accountUser && params.tacticId ?
              <TakeASurveySidebar />
              :
              data.accountUser && history.location.pathname.includes("/edit/scorecard-report") && params.scorecardId ?
              scorecardDetail && scorecardDetail.scorecardVersion === "Pro" ? 
              <ScorecardReportSideBar /> : <SelfReportSideBar />
                :
                data.accountUser && <SideContentForAccountuser />
          }


          {
            data.contentEditor && <SidebarForContentEditor />
          }


          {/* {
            data.accountuser && <SideContentForAccountuser />
          } */}


          {/* {props.type !== "condensed" ? <SidebarForContentEditor /> : <SidebarForContentEditor />} */}
          {/* {props.type !== "condensed" ? <SidebarContentForSuperAdmin /> : <SidebarContentForSuperAdmin />}  */}

        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
