import { color, height, width } from "@mui/system"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Grid, imageListItemClasses } from "@mui/material"
import CropEasy from "../../Crop/CropEasy"
import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
  Modal,
  Label,
  FormFeedback,
  Tooltip,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { Link, useParams, useHistory, Prompt } from "react-router-dom"
import { CLOUD_STORAGE } from "config/cloudStorage.config"
import {
  getScorecardReport,
  UpdateScorecardReport,
  ClearUpdateScorecardReport,
  updateGetScorecardReport,
  handleUnsavedChanges,
  handleChangesHappend,
  updateScorecardDetail,
  getAllInstructionData,
  getReportTemplate
} from "../../../store/actions"

import { Clear, Html, Opacity } from "@mui/icons-material"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import { imageUploadHandler } from "commonFunctions/UploadImage/uploadImage"
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import { Formik, useFormik } from "formik" //Import Breadcrumb
import { reset } from "redux-form"
import { values } from "lodash"
import { ACCOUNT_USER } from "config/AlertMessage.config"
import { REPORT } from "./config/style.config"
import updateSuccessIcon from "../../../assets/images/NewPopupIcons/Scorecard created.png"
import updateFailedIcon from "../../../assets/images/NewPopupIcons/FailedIcon.png"
import moment from "moment"

const pageReport = props => {
  const parameter = props

  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  //For tooltip
  const [tooltipForImageUpload, setTooltipForImageUpload] = useState(false)
  const [tooltipForAddConsultant, setTooltipForAddConsultant] = useState(false)
  const [tooltipForCloceEdit, setTooltipForCloseEdit] = useState(false)
  const [tooltipForGeneratePDF, setTooltipForGeneratePDF] = useState(false)
  const [tooltipForSave, setTooltipForSave] = useState(false)
  const [tooltipData, setTooltipData] = useState({})

  const [success_dlg, setsuccess_dlg] = useState(false)

  const [openCrop, setOpenCrop] = useState(false)
  const [file, setFile] = useState(null)
  const [image_url, setImage_Url] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [HeightWidth, setHeightWidth] = useState({})
  const [formData, setFormData] = useState([
    {
      accountUserContactName: "",
      accountUserId: "",
      designation: "",
      address: "",
    },
    {
      accountUserContactName: "",
      accountUserId: "",
      designation: "",
      address: "",
    },
  ])
  const [data, setData] = useState([])
  const [updatedUsers, setUpdatedUsers] = useState([])

  const [backend_Image, setBackend_Image] = useState(null)

  const [IsImage, setIsImage] = useState(false)

  const [isDataAdded, setIsDataAdded] = useState(false)

  const [isScorecardUpdate, setIsScorecardUpdate] = useState(false)

  // const [ setUnsavedChanges , setPrestine] = useUnsavedChnages()

  // state for Tootip functionality over save button
  const [tooltip, setTooltip] = useState(false)

  const [isupdate, setIsUpdate] = useState(false)
  const [isImageThere, setImageThere] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const {
    scorecardReport_data,
    scorecardReportUpdate,
    isDataEdited,
    isThereChanges,
    getInstructionData,
    getReport
  } = useSelector(state => ({
    getReport: state.createReportReducer.getReport,
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
    scorecardReportUpdate: state.ScorecardReport_Reducer.scorecardReportUpdate,
    isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    getInstructionData: state.instructionPanelReducer.getInstructionData,
  }))

  useEffect(() => {
    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
    dispatch(getReportTemplate())
  }, [dispatch])

  useEffect(() => {
    if (IsImage) setIsModalOpen(false)
    setImageThere(false)
    setIsImage(false)
  }, [image_url])

  useEffect(() => {
    if (scorecardReport_data.assignedTo) {
      console.log("scorecardReport_data: ", scorecardReport_data)
      if (scorecardReport_data.consultantList) {
        setUpdatedUsers(scorecardReport_data.consultantList)
      } else {
        var emptyUser = {
          accountUserContactName: "",
          accountUserId: "",
          designation: "",
          address: "",
        }
        setUpdatedUsers([scorecardReport_data.assignedTo[0], emptyUser])
      }
    }
  }, [scorecardReport_data])

  // console.log("Unsaved Changes Popup State",isDataEdited)

  // console.log("Data Updated State",isThereChanges)
  //console.log(updatedUsers)

  useEffect(() => {
    if (scorecardReport_data && scorecardReport_data.cover) {
      setBackend_Image(scorecardReport_data.cover.bgImage)
    }
  }, [scorecardReport_data])

  // ---------- consultant list ---------

  useEffect(() => {
    if (scorecardReport_data.consultantList) {
      setFormData(scorecardReport_data.consultantList)
    } else {
      if (scorecardReport_data.assignedTo) {
        var consultant = scorecardReport_data.assignedTo[0]
        var consultant_obj = {
          accountUserContactName: consultant.accountUserContactName,
          accountUserId: consultant.accountUserId,
          designation: "",
          address: "",
        }

        setFormData([
          consultant_obj,
          {
            accountUserContactName: "",
            accountUserId: "",
            designation: "",
            address: "",
          },
        ])
      }
    }
  }, [scorecardReport_data])

  useEffect(() => {
    if (getInstructionData.infoForAccountUser !== undefined) {
      var data = {
        infoForAddConsultantPencil: getInstructionData.infoForAccountUser
          .coverPage.infoForAddConsultantPencil
          ? getInstructionData.infoForAccountUser.coverPage
            .infoForAddConsultantPencil
          : "",
        infoForCloseEditingBtn: getInstructionData.infoForAccountUser.coverPage
          .infoForCloseEditingBtn
          ? getInstructionData.infoForAccountUser.coverPage
            .infoForCloseEditingBtn
          : "",
        infoForGeneratePDFBtn: getInstructionData.infoForAccountUser.coverPage
          .infoForGeneratePDFBtn
          ? getInstructionData.infoForAccountUser.coverPage
            .infoForGeneratePDFBtn
          : "",
        infoForSaveBtn: getInstructionData.infoForAccountUser.coverPage
          .infoForSaveBtn
          ? getInstructionData.infoForAccountUser.coverPage.infoForSaveBtn
          : "",
        infoForUploadImageBtn: getInstructionData.infoForAccountUser.coverPage
          .infoForUploadImageBtn
          ? getInstructionData.infoForAccountUser.coverPage
            .infoForUploadImageBtn
          : "",
      }
      setTooltipData({ ...data })
    }
  }, [getInstructionData])

  // ------------------------------------

  const handleChange = e => {
    const file = e.target.files[0]
    if (file) {
      var u = URL.createObjectURL(file)
      var img = new Image()
      img.onload = function () {
        var IMAGE_HEIGHT = img.height
        var IMAGE_WIDTH = img.width

        setHeightWidth({
          myheight: IMAGE_HEIGHT,
          mywidth: IMAGE_WIDTH,
        })
      }
      img.src = u
      setFile(file)
      setImage_Url(u)
      setOpenCrop(true)
      setTimeout(() => setIsImage(true), 2000)
      dispatch(handleChangesHappend(true))
      e.target.value = ""
    }
  }

  const handleSaveButton = () => {
    setIsDataAdded(true)
    if (!formData) {
      setFormData({})
      setIsDataAdded(false)
    } else {
      var Data = {
        consultantList: updatedUsers,
        cover: {
          ...scorecardReport_data.cover,
          bgImage: image_url,
        },
      }
      setIsDataAdded(true)
    }
    setIsSaved(true)
    dispatch(
      updateScorecardDetail(params.scorecardId, {
        lastUpdatedOn: {
          _miliseconds: 0,
          _seconds: new Date().getTime() / 1000,
        },
      })
    )
    dispatch(UpdateScorecardReport(params.scorecardId, Data))
  }

  //  console.log(updatedUsers)

  //  }
  //modals
  const [modal_center, setmodal_center] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  useEffect(() => {
    if (scorecardReport_data && scorecardReport_data.cover) {
      var data = scorecardReport_data.cover

      setImage_Url(data.bgImage)
    }
  }, [scorecardReport_data])

  const handleForm = (e, index) => {
    const { name, value } = e.target
    const list = [...formData]

    if (e.target.name === "accountUserId") {
      let Consultant_Name = e.target.value?.split(",")
      list[index]["accountUserContactName"] = Consultant_Name[1]
      list[index]["designation"] = Consultant_Name[2]
      list[index][name] = Consultant_Name[0]
    } else {
      list[index][name] = value
    }
    setIsUpdate(false)

    setFormData(list)
  }

  const handleConsultantSubmit = () => {
    //  formData[{
    //    accountUserContactName:ConsultantName()
    //  }]

    // updatedUsersData([...formData])

    setmodal_center(false)
    setIsUpdate(true)

    setUpdatedUsers([...formData])
    dispatch(handleChangesHappend(true))
  }


  // console.log("getReport....", getReport)



  // console.log(updatedUsers)
  return (
    <>
      <div className="page-content" style={{ marginBottom: 5 }}>
        <MetaTags>
          <title> Mambo -Report</title>
        </MetaTags>
        {/* {scorecardReportUpdate && scorecardReportUpdate.status === 200 && (
          <SweetAlert
            style={{
              height: "255px",
              width: "368px",

            }}
            // title="Data saved successfully!"
            success
            confirmBtnBsStyle="primary"
            onConfirm={() => {
              setIsSaved(false)
              setIsDataAdded(false)
              // setImage_Url(image_url || "")
              dispatch(
                updateGetScorecardReport({
                  ...scorecardReport_data,

                  consultantList: updatedUsers,
                  cover: {
                    ...scorecardReport_data.cover,
                    bgImage: image_url,
                  },
                })
              )
              dispatch(ClearUpdateScorecardReport())
            }}
          >
            {ACCOUNT_USER.EDIT_REPORT.SUCCESS}
          </SweetAlert>
        )} */}

        {scorecardReportUpdate && scorecardReportUpdate.status === 200 && (
          <SweetAlert
            style={{ height: "270px", width: "270px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img
                    src={updateSuccessIcon}
                    style={{
                      height: "80px",
                      width: "85px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid mb-2"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "25px",
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.SUCCESS}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                <Button
                  block
                  size="sm"
                  className="mb-2"
                  style={{
                    background: "#0D0F11",
                    border: "#0D0F11",
                  }}
                  onClick={() => {
                    setIsSaved(false)
                    setIsDataAdded(false)
                    // setImage_Url(image_url || "")
                    dispatch(
                      updateGetScorecardReport({
                        ...scorecardReport_data,

                        consultantList: updatedUsers,
                        cover: {
                          ...scorecardReport_data.cover,
                          bgImage: image_url,
                        },
                      })
                    )
                    dispatch(ClearUpdateScorecardReport())
                  }}
                >
                  Close
                </Button>
              </div>
            }
          ></SweetAlert>
        )}

        {/* {scorecardReportUpdate && scorecardReportUpdate.status === 403 && (
          <SweetAlert
            style={{
              height: "255px",
              width: "368px",

            }}
            // title="Failed to save !"
            error
            confirmBtnBsStyle="primary"
            onConfirm={() => {
              setIsSaved(false)
              setIsDataAdded(false)
              dispatch(ClearUpdateScorecardReport())
              // history.push("/page-report")
            }}
          >
            {" "}
            {ACCOUNT_USER.EDIT_REPORT.FAILED}
          </SweetAlert>
        )} */}

        {scorecardReportUpdate && scorecardReportUpdate.status === 403 && (
          <SweetAlert
            style={{ height: "270px", width: "270px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img
                    src={updateFailedIcon}
                    style={{
                      height: "80px",
                      width: "80px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "25px",
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.FAILED}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                <Button
                  block
                  size="sm"
                  className=""
                  style={{
                    background: "#0D0F11",
                    border: "#0D0F11",
                  }}
                  onClick={() => {
                    setIsSaved(false)
                    setIsDataAdded(false)
                    dispatch(ClearUpdateScorecardReport())
                    // history.push("/page-report")
                  }}
                >
                  Close
                </Button>
              </div>
            }
          ></SweetAlert>
        )}

        <Container
          fluid={true}
          style={{
            transform: "scale(.8) translate(-130px, -132px) ",
            width: "1400px",
          }}
        >
          {!Object.keys(scorecardReport_data).includes("id") ? (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                height: "400px",
              }}
            >
              <Spinner
                style={{
                  width: "60px",
                  height: "60px",
                  margin: "calc(200px - 30px)",
                }}
                role="grid"
                type="border"
                color="primary"
              />
            </div>
          ) : (
            <Card>
              <CardBody>
                <div>
                  <div
                    style={{
                      height: "1056px",
                      width: "816px",
                      transform: "scale(1)",
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${image_url})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Row style={{ paddingRight: "5px" }}>
                      <Col xs={
                        scorecardReport_data && scorecardReport_data.scorecardVersion === "Pro" ?
                        "9" : "10"
                      }/>
                      <Col xs="2" style={{ marginTop: "20px" }}>
                        <FormGroup>
                          {/* {formData && image_url && ( */}
                          <Button
                            type="button"
                            size="sm"
                            id="TooltipSave"
                            style={{
                              backgroundColor: "#5015cf",
                              marginLeft: scorecardReport_data && 
                              scorecardReport_data.scorecardVersion === "Pro" ? 
                              "55px" : "25px",
                              width: "75px",
                              border: "1px solid white",
                              zIndex: 1,
                            }}
                            onClick={() => {
                              handleSaveButton()
                              dispatch(handleChangesHappend(false))
                            }}
                            disabled={isSaved}
                          >
                            {isSaved ? "" : "Save"}
                            {isSaved ? (
                              <Spinner type="border" size="sm" />
                            ) : null}
                          </Button>
                          <Tooltip
                            style={{
                              backgroundColor: "#FEF3C7",
                              color: "#0D0F11",
                              zIndex: 1,
                            }}
                            placement="left"
                            isOpen={tooltipForSave}
                            target="TooltipSave"
                            toggle={() => {
                              setTooltipForSave(!tooltipForSave)
                            }}
                          >
                            {tooltipData.infoForSaveBtn}
                          </Tooltip>
                          {/* )} */}
                        </FormGroup>
                      </Col>
                      {
                        scorecardReport_data && scorecardReport_data.scorecardVersion === "Pro" ? (
                          <Col xs="1" style={{ marginTop: "20px" }}>
                            <div>
                              <label
                                type="button"
                                id="more"
                                onClick={() => {
                                  tog_backdrop()
                                }}
                              >
                                <span
                                  className="fas fa-pencil-alt"
                                  id="TooltipForPencil"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "18px",
                                    color: "#5015cf",
                                    height: "28px",
                                    width: "35px",
                                    border: "1px solid #5015cf",
                                    borderRadius: "4px",
                                    backgroundColor: "#fff",

                                  }}
                                  onClick={() => {
                                    setIsModalOpen(true)
                                  }}
                                ></span>
                              </label>
                              <Tooltip
                                style={{
                                  backgroundColor: "#FEF3C7",
                                  color: "#0D0F11",
                                  zIndex: 1,
                                }}
                                placement="right"
                                isOpen={tooltipForImageUpload}
                                target="TooltipForPencil"
                                toggle={() => {
                                  setTooltipForImageUpload(!tooltipForImageUpload)
                                }}
                              >
                                {tooltipData.infoForUploadImageBtn}
                              </Tooltip>

                              <input
                                type="file"
                                id="upload-button"
                                name="Image_URL"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleChange}
                              />
                              <br />
                            </div>
                          </Col>
                        ) : (
                          null
                        )
                      }
                      
                    </Row>
                    <div>
                      <div
                        style={{
                          marginLeft: 50,
                          height: "245px",
                          width: "554px",
                          backgroundColor: "rgba(52, 52, 52, 0.8)",
                          padding: 10,
                        }}
                      >
                        <div style={{ paddingLeft: "5px" }}>
                          <Col sm="12">
                            <h4
                              style={{
                                color: "white",
                                fontSize: "60px",
                                fontFamily: REPORT.FONT_FACE.HEADING,
                                fontWeight: "700",
                                lineHeight: 0.8,
                              }}
                            >
                              Modern
                            </h4>
                          </Col>
                          <Col sm="12">
                            <h4
                              style={{
                                color: "white",
                                fontSize: "60px",
                                fontFamily: REPORT.FONT_FACE.HEADING,
                                fontWeight: "700",
                                lineHeight: 0.8,
                              }}
                            >
                              Marketing
                            </h4>
                          </Col>
                          <Col sm="12">
                            <h4
                              style={{
                                color: "white",
                                fontSize: "60px",
                                fontFamily: REPORT.FONT_FACE.HEADING,
                                fontWeight: "700",
                                lineHeight: 0.8,
                              }}
                            >
                              {" "}
                              Scorecard
                              <sup
                                style={{
                                  fontSize: "25px",
                                  fontFamily: REPORT.FONT_FACE.HEADING,
                                }}
                              >
                                TM
                              </sup>
                            </h4>
                          </Col>
                          <Col sm="12">
                            &nbsp;
                            <label
                              style={{
                                color: "white",
                                borderBottom: "3px solid #FF6060",
                                fontSize: "28px",
                                fontWeight: "normal",
                                fontFamily: REPORT.FONT_FACE.PARAGRAPH,
                              }}
                            >
                              {`${scorecardReport_data &&
                                scorecardReport_data.companyName
                                }`.toUpperCase()}
                            </label>
                          </Col>
                        </div>
                      </div>
                    </div>

                    <div style={{ marginTop: 348, marginLeft: 50 }}>
                      <div
                        style={{
                          height: "400px",
                          width: "305px",
                          backgroundColor: "#FF6060",
                          opacity: 0.8,
                          padding: "20px 10px 10px 20px",
                          // marginRight:'20px'
                        }}
                      >
                        <div>
                          <label
                            style={{
                              color: "white",
                              borderBottom: "3px solid white",
                            }}
                          >
                            PREPARED BY:
                          </label>
                          {
                            scorecardReport_data && 
                            scorecardReport_data.scorecardVersion === "Self Report" ? (
                              <>
                                <div>
                                  <label
                                    style={{
                                      color: "white",
                                    }}
                                  >
                                    {`Created On: 
                                    ${scorecardReport_data && scorecardReport_data.reportGenerationDate ? 
                                      moment(scorecardReport_data.reportGenerationDate * 1000)
                                      .format("DD/MM/YYYY") :
                                      moment(scorecardReport_data.createdOn._seconds * 1000)
                                      .format("DD/MM/YYYY")  
                                    }`}
                                  </label>
                                </div>
                                <div>
                                  <label
                                    style={{
                                      color: "white",
                                    }}
                                  >
                                    Status: Completed
                                  </label>
                                </div>
                              </>
                            ) : null
                          }
                          <i
                            className="fas fa-pencil-alt "
                            id="TooltipForConsultant"
                            style={{
                              fontSize: "18px",
                              cursor: "pointer",
                              color: "#fff",
                              textAlign: "right",
                              width: "100%",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                            onClick={() => {
                              tog_center()
                            }}
                          ></i>
                          <Tooltip
                            style={{
                              backgroundColor: "#FEF3C7",
                              color: "#0D0F11",
                              zIndex: 1,
                            }}
                            placement="right"
                            isOpen={tooltipForAddConsultant}
                            target="TooltipForConsultant"
                            toggle={() => {
                              setTooltipForAddConsultant(!tooltipForAddConsultant)
                            }}
                          >
                            {tooltipData.infoForAddConsultantPencil}
                          </Tooltip>

                          { }

                          <div style={{ color: "white" }}>
                            {!updatedUsers[0] ? (
                              <div>
                                <p
                                  style={{
                                    display: "flex",

                                    backgroundColor: "#2D3033",

                                    opacity: 0.5,

                                    width: "265px",
                                    height: "45px",
                                    fontSize: "25px",
                                    alignItems: "center",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {scorecardReport_data.assignedTo &&
                                    scorecardReport_data.assignedTo[0]
                                      .accountUserContactName}
                                </p>
                                {/* <p
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {" "}
                                  { }
                                </p> */}
                                <p
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {" "}
                                  { }
                                </p>
                              </div>
                            ) : (
                              updatedUsers
                                .filter(
                                  data =>
                                    data.accountUserContactName !== "" &&
                                    data.accountUserContactName !== undefined
                                )
                                .map((user, index) => (
                                  <div
                                    key={index}
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",

                                        backgroundColor: "#2D3033",
                                        width: "265px",
                                        height: "45px",
                                        fontSize: "25px",
                                        alignItems: "center",
                                        paddingLeft: "10px",
                                        // border:'1px solid black'
                                      }}
                                    >
                                      {user.accountUserContactName}
                                    </p>
                                    <p
                                      style={{
                                        margin: "-13px 0 0 10px",
                                        fontSize: "18px",
                                        padding: 0,
                                        // border:'1px solid black'
                                      }}
                                    >
                                      {" "}
                                      {user.designation}
                                    </p>
                                    {/* <p
                                      style={{
                                        margin: "5px 0 4px 10px",
                                        fontSize: "15px",
                                        padding: 0,
                                        // border:'1px solid black'
                                      }}
                                    >
                                      {" "}
                                      {user.address}
                                    </p> */}
                                  </div>
                                ))
                            )}
                          </div>

                          <Modal
                            isOpen={modal_center}
                            toggle={() => {
                              tog_center()
                            }}
                            style={{ paddingTop: "300px" }}
                          >
                            <div className="modal-header">
                              <h5 className="modal-title mt-0">
                                Modern Marketing Scorecard
                              </h5>
                              <button
                                type="button"
                                onClick={() => {
                                  setmodal_center(false)
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>

                            <div className="modal-body">
                              <Form
                                autoComplete="off"
                                onSubmit={e => {
                                  e.preventDefault()
                                }}
                              >
                                {formData.map((items, i) => (
                                  <div key={i}>
                                    <Row>
                                      <Col md="12">
                                        <label className="mb-0">
                                          Consultant Name
                                        </label>
                                        <Input
                                          name="accountUserId"
                                          type="select"
                                          className="form-select mb-3"
                                          onChange={e => handleForm(e, i)}
                                          value={`${items.accountUserId},${items.accountUserContactName},${items.designation}`}

                                        // = {items.accountUserContactName !== ''?items.accountUserContactName: null}
                                        >
                                          <option value={""}>Consultant</option>
                                          {scorecardReport_data.assignedTo &&
                                            scorecardReport_data.assignedTo.map(
                                              user => (
                                                <option
                                                  key={
                                                    user.accountUserContactName
                                                  }
                                                  value={`${user.accountUserId},${user.accountUserContactName},${user.address}`}
                                                >
                                                  {`${user.accountUserContactName
                                                    } ${" - "} ${user.address}`}
                                                </option>
                                              )
                                            )}
                                        </Input>
                                      </Col>
                                      {/* <Col md="6">

                                        <label>Title</label>
                                        <Input
                                          name="designation"
                                          type="select"
                                          className="form-control"
                                          placeholder="Title"
                                          value={items.designation}
                                          onChange={e => handleForm(e, i)}
                                        >
                                          <option value="">Select Title</option>
                                          {scorecardReport_data.assignedTo &&
                                            scorecardReport_data.assignedTo.filter(data => 
                                              data.accountUserId === consultantAddress && data.address !== "").map(
                                              user => (
                                                <option
                                                  key={user.accountUserId}
                                                  value={user.address}
                                                >
                                                  {user.address}
                                                </option>
                                              )
                                            )}
                                          </Input>   
                                      </Col> */}
                                      {/* <Col md="4">
                                        <label>Address</label>
                                        <Input
                                          name="address"
                                          type="text"
                                          className="form-control"
                                          placeholder="Address"
                                          value={items.address}
                                          onChange={e => handleForm(e, i)}
                                        />

                                      </Col> */}
                                    </Row>
                                  </div>
                                ))}

                                <Button
                                  style={{
                                    background: "#175dcf",
                                    width: "90px",
                                  }}
                                  onClick={handleConsultantSubmit}
                                >
                                  Save
                                </Button>
                              </Form>
                            </div>
                          </Modal>
                        </div>
                      </div>
                    </div>
                    <Modal
                      isOpen={isModalOpen}
                      onRequestClose={() => setIsModalOpen(false)}
                      style={{ marginTop: "250px", width: "650px" }}
                    // size="lg"
                    >
                      {!isImageThere ? (
                        <>
                          {/* <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // width:'100%',
                              marginLeft: "5px",
                            }}
                          >
                            <h5 style={{ margin: '15px 0 10px 140px' }}>Upload background image</h5>
                            <i
                              className="bx bx-x"
                              style={{ width: '40px', fontSize: "25px", cursor: "pointer", margin: '14px 0 10px 80px' }}
                              onClick={() => {
                                setIsModalOpen(false)
                              }


                              }
                            ></i>
                          </div> */}
                          <Row className="modal-body">
                            <Col
                              md="12"
                              className="text-center font-size-18 mb-3"
                              style={{
                                color: "#0D0F11",
                                fontWeight: 600,
                              }}
                            >
                              Upload New Image
                              <button
                                style={{
                                  marginTop: "-10px",
                                  marginLeft: "-20px",
                                }}
                                type="button"
                                onClick={() => {
                                  setIsModalOpen(false)
                                }}
                                className="close text-center font-size-14"
                                data-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </Col>
                            <Col md="4"></Col>
                            <Col md="4">
                              <Button
                                size="sm"
                                block
                                onClick={() => {
                                  var inputFile =
                                    document.querySelector("#upload-button")
                                  inputFile.click()
                                }}
                                style={{
                                  background: "#0D0F11",
                                  border: "#0D0F11",
                                }}
                              >
                                <div className="d-flex justify-content-center">
                                  <i className="mdi mdi-image fw-bold mx-2 font-size-14"></i>
                                  <span className="font-size-14">
                                    Choose Image
                                  </span>
                                </div>
                              </Button>
                            </Col>
                            <Col md="4"></Col>
                          </Row>
                          <div
                            style={{
                              justifyContent: "center",
                              margin: "auto 10px auto 10px",
                            }}
                          >
                            {openCrop && (
                              <CropEasy
                                image_url={image_url}
                                imageName={file.name}
                                image_storagePath={
                                  CLOUD_STORAGE.EDIT_SCORECARD_REPORT.COVER
                                    .IMAGE
                                }
                                setOpenCrop={setOpenCrop}
                                setImageUrl={setImage_Url}
                                setImageThere={setImageThere}
                                setFile={setFile}
                                ImageHeightWidth={HeightWidth}
                                ImageType="BACKGROUND"
                              />
                            )}
                          </div>
                        </>
                      ) : (
                        // <Col md="8" style={{
                        //   textAlign: 'center',
                        //   margin: '40px 0 40px 90px'
                        // }}>
                        //   <Spinner />
                        //   <br />
                        //   <br />
                        //   <p
                        //     style={{
                        //       color: '#292929',
                        //       fontSize: REPORT.FONT_SIZE.LARGE
                        //     }}
                        //   >
                        //     Please wait.  Image is being uploaded.</p>
                        // </Col>
                        <Row className="modal-body text-center">
                          <Col md="12" className="my-3 fw-bold font-size-18">
                            <p
                              style={{
                                color: "#0D0F11",
                              }}
                            >
                              Please wait. Image is being uploaded.
                            </p>
                          </Col>
                          <Col md="12">
                            <Spinner style={{ color: "#0D0F11" }} />
                          </Col>
                        </Row>
                      )}
                    </Modal>
                  </div>
                </div>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </>
  )
}

export default pageReport
