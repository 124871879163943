import { takeEvery, put, call,all,fork  } from "redux-saga/effects";

import {GET_CHANNEL_DETAILS , UPDATE_CHANNEL_DETAILS} from "./actionTypes"

import {getChannelDetailsSuccess , getChannelDetailsFail , updateChannelDetailsSuccess,updateChannelDetailsFail} from "./actions"

import {getChannelDetails , updateChannelDetails} from "../../helpers/fakebackend_helper"

function* fetchChannelDetails ({id}) {
    try{
        const response = yield call(getChannelDetails, id)
        yield put(getChannelDetailsSuccess(response))
    }
    catch(error){
        yield put(getChannelDetailsFail(error))

    }
}

function* fetchUpdateChannelDetails ({id , payload:data}) {
    try{
        const response = yield call(updateChannelDetails, id , data)
        yield put(updateChannelDetailsSuccess(response))

    }
    catch(error){
        yield put(updateChannelDetailsFail(error))

    }
}

export function* watchFetchChannelDetails(){
    yield takeEvery (GET_CHANNEL_DETAILS, fetchChannelDetails);

}

export function* watchFetchUpdateChannelDetails() {
    yield takeEvery (UPDATE_CHANNEL_DETAILS, fetchUpdateChannelDetails);
}

function* ChannelUpdateDetails () {
    yield all ([fork(watchFetchChannelDetails)]) ;
    yield all ([fork(watchFetchUpdateChannelDetails)]);
}

export default ChannelUpdateDetails