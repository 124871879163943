import React from "react"
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const Help = () => {
    return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Mambo - Help</title>
            </MetaTags>
            <div className="container-fluid">
              
              <Row>
              <Col lg={9} style= {{margin: "auto"}}>

                <Card>
                <CardBody>
                  <div className="table-responsive">
                    <h3>Mambo Media Inc.</h3>
                    
                    <h5>Hello.&nbsp; What can we help you with..?</h5>
                   

                    

                    </div>
                    
                    </CardBody>
                    </Card>
                    
              </Col>

              </Row>


              </div>
              </div>
              </React.Fragment>
    )

}
export default Help