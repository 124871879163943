import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter, useParams, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import { Country, State } from "country-state-city"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import SweetAlert from "react-bootstrap-sweetalert"
import { isEmpty, map } from "lodash"
import * as Yup from "yup"
import { Formik, useFormik } from "formik" //Import Breadcrumb
import Switch from "react-switch"

import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Label,
  FormFeedback,
  Button,
} from "reactstrap"

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb";
//Import Image
//import logo from "../../assets/images/logo-dark.png";
import {
  clearAccountDataState,
  ClearAccountOwnerDetails,
  getAccountDetails as onGetAccountDetails,
  updateAccountDetailsState,
  getScorecardsList
} from "store/actions"

import { updateAccountData, clearUpdateAccount } from "store/actions"

import { getAccountHolderUsers } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import { SUPER_ADMIN } from "config/AlertMessage.config"

const AccountDetail = props => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [isDataAvail, setIsDataAvail] = useState(true)
  const [isAccountAdd, setIsAccountAdd] = useState(false)
  const [isAccountUpdate, setIsAccountUpdate] = useState(false)
  const [isAccountDisabled, setIsAccountDisabled] = useState("Disable")
  const [paginationReset, setPaginationReset] = useState(false)
  const [HandleAccountHolderData, setHandleAccountHolderData] = useState({})
  const [countries, setCountries] = useState(null)
  // State for Scorecard version for Account Owner
  const [scorecardVersions, setScorecardVersions] = useState({
    proScorecard: false,
    selfReportScorecard: false,
  })
  const [ErrorsForScorecardVersion, setErrorsForScorecardVersion] = useState(false)

  const { accountDetails, updateAccount } = useSelector(state => ({
    accountDetails: state.SuperAdmin.accountDetails,
    updateAccount: state.UpdateAccountReducer.updateAccount,
  }))

  const { accountHolderUsers, scorecardsList } = useSelector(state => ({
    accountHolderUsers: state.AccountHolderUsersReducer.accountHolderUsers,
    scorecardsList: state.GetScorecardsListReducer.scorecardsList,
  }))

  //   const {
  //     match: { params },
  //   } = props;

  // console.log(accountDetails)
  // console.log("Account User...." , accountHolderUsers)

  useEffect(() => {
    if (params && params.userId) {
      // console.log("I AM IN IF BLOCKKK...", params.userId)
      dispatch(onGetAccountDetails(params.userId))     // Fetch all Account holders
      dispatch(getAccountHolderUsers(params.userId))   // Fetch Account Holder's Users List
    }

    // if (Object.keys(accountDetails).includes("id")) {
    //   var search_bar = document.querySelector("#search-bar-0")
    //   search_bar.setAttribute("autoComplete", "off")
    // }

    return () => {
      dispatch(ClearAccountOwnerDetails())
      dispatch(clearAccountDataState())
    }
  }, [dispatch])

  useEffect(() => {
    setIsAccountDisabled(!accountDetails.isDisabled)
    if (accountDetails.contactName) {
      var search_bar = document.querySelector("#search-bar-0")
      search_bar.setAttribute("autoComplete", "off")
      // clearTimeout(WaitForData)
      search_bar.addEventListener("focus", () => setPaginationReset(true))

    }

    // return () => dispatch(clearAccountDataState())
  }, [accountDetails])


  useEffect(() => {
    dispatch(getScorecardsList(params.userId))
  }, [accountDetails])

  useEffect(() => {
    if (accountDetails && accountDetails.scorecardVersions) {
      setScorecardVersions(accountDetails.scorecardVersions)
    } else {
      setScorecardVersions({
        proScorecard: false,
        selfReportScorecard: false
      })
    }
  }, [accountDetails])

  // Function to handle change for scorecard version type
  const handleScorecardType = (event) => {
    const { name, checked } = event.target;
    setScorecardVersions((e) => ({
      ...e,
      [name]: checked
    }))
    setErrorsForScorecardVersion(false)
  }
  
  // Function which will return the owner permission for scorecard
  const getOwnerPermissionForScorecard = (DATA) => {
    let scorecardPermission = "";
    if (DATA && DATA.scorecardVersions) {
      const { scorecardVersions } = DATA;
      if (scorecardVersions.proScorecard && scorecardVersions.selfReportScorecard) {
        scorecardPermission = "Pro Scorecard & Self Report Scorecard";
      } else if (scorecardVersions.proScorecard && !scorecardVersions.selfReportScorecard) {
        scorecardPermission = "Pro Scorecard";
      } else if (!scorecardVersions.proScorecard && scorecardVersions.selfReportScorecard) {
        scorecardPermission = "Self Report Scorecard";
      }
      return scorecardPermission;
    } else {
      scorecardPermission = "Not Available"
      return scorecardPermission;
    }
  }

  const columns = [
    //text: 'Id',
    //sort: true,
    {
      dataField: "accounts",
      text: "User",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "contactNumber",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "proScorecardsCreated",
      text: "Pro Scorecard",
      sort: true,
       // eslint-disable-next-line react/display-name
       formatter: (cellContent, row) => (
        <Link to="#"  style={{color:'#495057', textAlign: "center", width: "140px" }}>
          {row.proScorecardsCreated}
        </Link>
      ),
    },
    {
      dataField: "selfReportScorecardsCreated",
      text: "Self Report Scorecard",
      sort: true,
       // eslint-disable-next-line react/display-name
       formatter: (cellContent, row) => (
        <Link to="#"  style={{color:'#495057', textAlign: "center", width: "140px" }}>
          {row.selfReportScorecardsCreated}
        </Link>
      ),
    },
  ]
  const GetScorecardCountByType = (user_id, scorecardType) => {
    const filteredScorecards = scorecardsList[0]
      ? scorecardsList.filter(scorecards => scorecards.scorecardVersion !== undefined) : []
    
    const listForScorecards = filteredScorecards[0]
      ? filteredScorecards.filter(obj => obj.scorecardVersion === scorecardType) : []

    var AssignedScorecards = listForScorecards[0]
      ? listForScorecards.map(scorecards => {
          return scorecards.assignedTo
            ? scorecards.assignedTo.filter(
                assignee => assignee.accountUserId === user_id
              )
            : scorecards.assigneTo.filter(
                assignee => assignee.accountUserId === user_id
              )
        })
      : [[]]

    var Scorecards = AssignedScorecards.reduce((acc, curVal) => {
      return acc.concat(curVal)
    }, []).length
    return Scorecards

    // return AssignedScorecards.length
  }

  const productData = !accountHolderUsers[0]
    ? []
    : accountHolderUsers.map((user, i) => {
      return {
        id: ++i,
        accounts: user.contactName,
        email: user.contactEmail,
        contactNumber: user.contactNumber,
        proScorecardsCreated:  GetScorecardCountByType(user.id, "Pro"),
        selfReportScorecardsCreated:  GetScorecardCountByType(user.id, "Self Report"),
        actions: user.id,
      }
    })

  const defaultSorted = [
    {
      dataField: "accounts",
      order: "asc",
    },
  ]

  const pageOptions = {
    // sizePerPage: 10,
    onPageChange: () => setPaginationReset(false),
    // totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: productData.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/users/" + row.actions)
    },
  }
  const { SearchBar } = Search

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      accountName: (accountDetails && accountDetails.accountName) || "",
      contactName: (accountDetails && accountDetails.contactName) || "",
      countryCode: (accountDetails.countryCode && accountDetails.countryCode.includes("+") ? accountDetails.countryCode.slice(1) : accountDetails.countryCode) || "",
      phone: (accountDetails && accountDetails.phone) || "",
      street: (accountDetails && accountDetails.street) || "",
      city: (accountDetails && accountDetails.city) || "",
      state: (accountDetails && accountDetails.state) || "",
      zip: (accountDetails && accountDetails.zip) || "",
      contactEmail: (accountDetails && accountDetails.contactEmail) || "",
      credit: (accountDetails && accountDetails.credit) || "",
    },

    validationSchema: Yup.object({
      accountName: Yup.string().required("Please enter account name"),
      contactName: Yup.string().required("Please enter account owner name"),
      countryCode: Yup.string().required("Please select country code"),
      contactEmail: Yup.string()
        .email("Please enter valid email ")
        .required("Please enter contact email"),
      street: Yup.string().required("Please enter street"),
      city: Yup.string().required("Please enter city"),
      state: Yup.string().required("Please enter state"),
      zip: Yup.number().required("Please enter zipcode"),
      phone: Yup.string().max(10, "Contact number must be atleast 10 digits").min(10, "Contact number must be atleast 10 digits").required("Please enter contact number"),
    }),
    onSubmit: values => {
      var date = new Date()
      var user = JSON.parse(localStorage.getItem("authUser"))

      if (scorecardVersions.proScorecard || scorecardVersions.selfReportScorecard) {
        const myData = {
          accountName: values.accountName,
          contactName: values.contactName,
          contactEmail: values.contactEmail,
          phone: values.phone,
          countryCode: values.countryCode,
          street: values.street,
          city: values.city,
          state: values.state,
          zip: values.zip,
          credit: values.credit,
          createdOn: date,
          createdBy: user.email,
          creatorId: user.uid,
          isDisabled: !isAccountDisabled,
          scorecardVersions: scorecardVersions
        }
        // console.log("myData: ", myData)
        setHandleAccountHolderData(myData)
        setIsAccountAdd(true)
        dispatch(updateAccountData(params.userId, myData))
      } else {
        setErrorsForScorecardVersion(true)
      }
    },
  })

  // if (updateAccount[0] && updateAccount[0].status === 200) {
  //   setTimeout(() => {
  //     validation.values.accountName = ""
  //     validation.values.contactName = ""
  //     validation.values.contactEmail = ""
  //     validation.values.phone = ""
  //     validation.values.street = ""
  //     validation.values.city = ""
  //     validation.values.state = ""
  //     validation.values.zip = ""
  //     validation.values.credit = ""

  //     dispatch(clearUpdateAccount())
  //     setIsAccountAdd(false)

  //     setTimeout(() => {
  //       history.push("/")
  //     }, 2000)

  //   }, 2000)
  // }

  // if (updateAccount[0] && updateAccount[0].status !== 200) {
  //   setTimeout(() => {

  //     dispatch(clearUpdateAccount())
  //     setIsAccountAdd(false)

  //   }, 2000)
  // }

  //console.log(updateAccount.isDisabled)
  const [success_msg, setsuccess_msg] = useState(false)

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          marginRight: "34px",
        }}
      >
        {" "}
        Disabled
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          marginLeft: "34px",
        }}
      >
        {" "}
        Enabled
      </div>
    )
  }

  // const toggle1 = () => {
  //   var x = document.getElementsByClassName("customSwitchsizelg");
  //   if(x.innerHTML === "Disable Account ?"){
  //     x.innerHTML = "Enable Account ?"
  //   }
  //   else{
  //     x.innerHTML = "Disable Account ?"
  //   }

  // }

  useEffect(() => {

    var countries_ = [...Country.getAllCountries()]
      .map(country => {
        if (country.isoCode === "CA") {
          return {
            ...country,
            phonecode: "001"
          }
        } else
          if (country.isoCode === "UM") {
            return {
              ...country,
              phonecode: "01"
            }
          } else {
            return country;
          }
      })

    // console.log(countries_)
    setCountries(countries_)
  }, [])

  const GetCountryByCode = () => {
    if (countries) {
      var country = countries.filter(
        country => country.name === validation.values.country
      )
      return State.getStatesOfCountry(country[0].isoCode)
    }
  }



  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Mambo - Account Details</title>
        </MetaTags>
        <div className="container-fluid">
          {!Object.keys(accountDetails).includes("id") ? (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                height: "400px",
              }}
            >
              <Spinner
                style={{
                  width: "60px",
                  height: "60px",
                  margin: "calc(200px - 30px)",
                }}
                role="grid"
                type="border"
                color="primary"
              />
            </div>
          ) : (
            <>
              <Card>
                <CardBody>
                  {/* {updateAccount[0] && updateAccount[0].status === 200 &&
                <Alert color="success" role="alert" className='alert-error' >
                  Account updated successfully !
                </Alert>
              }
              {updateAccount[0] && updateAccount[0].status === 403 &&
                <Alert color="danger" role="alert" className='alert-error' >
                  failed to update  !
                </Alert>
              } */}
                  {updateAccount[0] && updateAccount[0].status === 200 && (
                    <SweetAlert
                      style={{ height: "255px", width: "368px" }}
                      // title="Updated successfully"
                      success
                      confirmBtnBsStyle="primary"
                      onConfirm={() => {
                        dispatch(updateAccountDetailsState({
                          ...HandleAccountHolderData,
                          id: params.userId
                        }))
                        setsuccess_msg(false)
                        dispatch(clearUpdateAccount())
                        setIsAccountAdd(false)

                        // history.push("/")
                      }}
                    >
                      {SUPER_ADMIN.CREATE_ACCOUNT_OWNER.SUCCESS}
                    </SweetAlert>
                  )}

                  {updateAccount[0] && updateAccount[0].status === 403 && (
                    <SweetAlert
                      // title="Failed to update "
                      error
                      confirmBtnBsStyle="primary"
                      style={{ height: "255px", width: "368px" }}
                      onConfirm={() => {
                        setsuccess_msg(false)
                        setIsAccountAdd(false)

                        dispatch(clearUpdateAccount())
                        setIsAccountUpdate(true)
                      }}
                    >
                      {SUPER_ADMIN.CREATE_ACCOUNT_OWNER.FAILED}
                    </SweetAlert>
                  )}
                  <Form
                    className="needs-validation outer-repeater"
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="11">
                        <CardTitle className="h4" style={{ fontSize: "20px", fontWeight: 500 }}>
                          {(isAccountUpdate && validation.values.accountName) ||
                            (accountDetails && accountDetails.accountName)}
                        </CardTitle>
                      </Col>

                      <Col md="1">
                        {isAccountUpdate ? (
                          <i
                            className="bx bx-undo text-primary"
                            style={{ fontSize: "30px", cursor: "pointer" }}
                            onClick={() => setIsAccountUpdate(false)}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-pencil-alt text-light"
                            style={{ textAlign: 'center', fontSize: "12px", width: '28px', height: '24px', cursor: "pointer", borderRadius: '2px', background: '#3D57A3', padding: '6px' }}
                            onClick={() => setIsAccountUpdate(true)}
                          ></i>
                        )}
                      </Col>
                      {/* <Col md = "1">
                            <i className = "fas fa-trash-alt text-danger" style={{ fontSize: "14px", cursor: "pointer" }}  ></i>

                        </Col> */}
                    </Row>
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col md="6">
                            {isAccountUpdate && (
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">
                                  Account Name
                                </Label>
                                <Input
                                  name="accountName"
                                  placeholder="account name"
                                  type="text"
                                  disabled={isAccountAdd}
                                  className="form-control"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.accountName || ""}
                                  invalid={
                                    validation.touched.accountName &&
                                      validation.errors.accountName
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.accountName &&
                                  validation.errors.accountName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.accountName}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            {!isAccountUpdate ? (
                              <p style={{ fontSize: "14px" }}>
                                <i
                                  style={{ fontSize: "18px" }}
                                  className="mdi mdi-account-circle"
                                ></i>
                                &nbsp;&nbsp;&nbsp;
                                {accountDetails && accountDetails.contactName}
                              </p>
                            ) : (
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">
                                  Account Owner Name
                                </Label>
                                <Input
                                  name="contactName"
                                  placeholder="account owner name"
                                  type="text"
                                  disabled={isAccountAdd}
                                  className="form-control"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.contactName || ""}
                                  invalid={
                                    validation.touched.contactName &&
                                      validation.errors.contactName
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.contactName &&
                                  validation.errors.contactName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.contactName}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            {!isAccountUpdate ? (
                              <p style={{ fontSize: "14px", marginTop: -15 }}>
                                <i
                                  style={{ fontSize: "18px", paddingRight: '3px' }}
                                  className="mdi mdi-email"
                                ></i>
                                &nbsp;&nbsp;
                                {accountDetails && accountDetails.contactEmail}
                              </p>
                            ) : (
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">
                                  Email
                                </Label>
                                <Input
                                  name="contactEmail"
                                  placeholder="email"
                                  type="text"
                                  disabled={isAccountAdd}
                                  className="form-control"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.contactEmail || ""}
                                  invalid={
                                    validation.touched.contactEmail &&
                                      validation.errors.contactEmail
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.contactEmail &&
                                  validation.errors.contactEmail ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.contactEmail}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            {!isAccountUpdate ? (
                              <p style={{ fontSize: "14px", marginTop: -15 }}>
                                <i
                                  style={{ fontSize: "18px" }}
                                  className="mdi mdi-phone"
                                ></i>
                                &nbsp; &nbsp;
                                {accountDetails &&
                                  accountDetails.countryCode.includes("+") ? accountDetails.countryCode : "+" + accountDetails.countryCode}{" "}
                                {accountDetails && accountDetails.phone}{" "}
                              </p>
                            ) : (
                              <Row>
                                <Col md="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">
                                      Phone
                                    </Label>
                                    <Input type="select"
                                      className="form-control"
                                      name="countryCode"
                                      disabled={isAccountAdd}
                                      value={validation.values.countryCode}
                                      onChange={validation.handleChange}
                                    >
                                      {/* {
                                                            countries && countries.map(country => (
                                                                <option key={country.name} value={country.phonecode} >
                                                                    {country.phonecode.includes("+") ? country.phonecode : "+" + country.phonecode}
                                                                </option>
                                                            ))
                                                        } */}
                                      {countries &&
                                        countries.map(country => (
                                          <option
                                            key={country.name}
                                            value={country.phonecode}
                                          >
                                            {country.flag}
                                            &nbsp;&nbsp;{country.isoCode}
                                            &nbsp;&nbsp;({country.phonecode})
                                          </option>
                                        ))}
                                    </Input>
                                    {/* <Input
                                                name="countryCode"
                                                placeholder="Country Code"
                                                type="text"
                                                disabled = { isAccountAdd }

                                                className="form-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.countryCode || ""}
                                                invalid={
                                                validation.touched.countryCode && validation.errors.countryCode ? true : false
                                                }
                                                /> */}
                                    {validation.touched.countryCode &&
                                      validation.errors.countryCode ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.countryCode}
                                      </FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                                <Col md="8">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">
                                      &nbsp;
                                    </Label>
                                    <Input
                                      name="phone"
                                      placeholder="phone"
                                      type="text"
                                      disabled={isAccountAdd}
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.phone || ""}
                                      invalid={
                                        validation.touched.phone &&
                                          validation.errors.phone
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.phone &&
                                      validation.errors.phone ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.phone}
                                      </FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            {!isAccountUpdate ? (
                              <p style={{ fontSize: "14px", marginTop: -15 }}>
                                <i
                                  style={{ fontSize: "18px" }}
                                  className="dripicons dripicons-location"
                                ></i>
                                &nbsp; &nbsp;
                                {accountDetails && accountDetails.street}{" "}
                                {accountDetails && accountDetails.city}{" "}
                              </p>
                            ) : (
                              <Row>
                                <Col md="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">
                                      Street
                                    </Label>
                                    <Input
                                      name="street"
                                      placeholder="street"
                                      type="text"
                                      disabled={isAccountAdd}
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.street || ""}
                                      invalid={
                                        validation.touched.street &&
                                          validation.errors.street
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.street &&
                                      validation.errors.street ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.street}
                                      </FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                                <Col md="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">
                                      City
                                    </Label>
                                    <Input
                                      name="city"
                                      placeholder="city"
                                      type="text"
                                      disabled={isAccountAdd}
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.city || ""}
                                      invalid={
                                        validation.touched.city &&
                                          validation.errors.city
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.city &&
                                      validation.errors.city ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.city}
                                      </FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            {!isAccountUpdate ? (
                              <p
                                style={{
                                  fontSize: "14px",
                                  marginTop: -15,
                                  marginLeft: "18px",
                                }}
                              >
                                &nbsp; &nbsp;
                                {accountDetails && accountDetails.state}{" "}
                                {accountDetails && accountDetails.zip}{" "}
                              </p>
                            ) : (
                              <Row>
                                <Col md="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">
                                      State
                                    </Label>
                                    <Input
                                      name="state"
                                      placeholder="state"
                                      type="text"
                                      disabled={isAccountAdd}
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.state || ""}
                                      invalid={
                                        validation.touched.state &&
                                          validation.errors.state
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.state &&
                                      validation.errors.state ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.state}
                                      </FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                                <Col md="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">
                                      Zipcode
                                    </Label>
                                    <Input
                                      name="zip"
                                      placeholder="Zipcode"
                                      type="text"
                                      disabled={isAccountAdd}
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.zip || ""}
                                      invalid={
                                        validation.touched.zip &&
                                          validation.errors.zip
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.zip &&
                                      validation.errors.zip ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.zip}
                                      </FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          {!isAccountUpdate ? (
                            <Col md="6">
                            <p
                              style={{
                                fontSize: "15px",
                                marginTop: -10,
                              }}
                            >
                              <i
                                style={{ fontSize: "18px" }}
                                className="mdi mdi-card-text-outline"
                              />
                              &nbsp; &nbsp;
                              {getOwnerPermissionForScorecard(accountDetails)}
                            </p>
                          </Col>
                          ) : (
                          <Col md="6">
                            <Label className="my-0 py-0">Select Scorecard Type</Label>
                            <br />
                            {ErrorsForScorecardVersion ? (
                              <span className="text-danger" style={{
                                fontSize: '11px',
                                letterSpacing: 0.1
                              }}>
                                (Please select at least one type of scorecard)
                              </span>
                            ) : null}
                            <div className="d-flex align-items-center gap-4">
                              <div className="d-flex align-items-center">
                                <Input 
                                  type="checkbox"
                                  style={{
                                    width: '23px',
                                    height: '20px'
                                  }}
                                  name="proScorecard"
                                  className="scorecard-versions"
                                  id="proScorecard"
                                  defaultChecked={scorecardVersions.proScorecard}
                                  onClick={handleScorecardType}
                                />
                                <div className="ms-2 pt-2">
                                  <label htmlFor="proScorecard">Pro Scorecard</label>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <Input 
                                  type="checkbox"
                                  style={{
                                    width: '23px',
                                    height: '20px'
                                  }}
                                  name="selfReportScorecard"
                                  className="scorecard-versions"
                                  id="selfReportScorecard"
                                  defaultChecked={scorecardVersions.selfReportScorecard}
                                  onClick={handleScorecardType}
                                />
                                <div className="ms-2 pt-2">
                                  <label htmlFor="selfReportScorecard">Self Report Scorecard</label>
                                </div>
                              </div>
                            </div>
                          </Col>
                          )}
                        </Row>

                          <Row>
                          <Col md="4" ></Col>
                          <Col md="2">
                            {isAccountUpdate && (
                              <FormGroup className="mb-3">
                                <Button
                                  color="primary"
                                  type="submit"
                                  disabled={isAccountAdd ? true : false}
                                  block
                                >
                                  {isAccountAdd ? "" : "Update"}
                                  {isAccountAdd ? (
                                    <Spinner type="border" size="sm" />
                                  ) : null}
                                </Button>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                  
                </CardBody>
              </Card>
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4" style={{ fontSize: 20, fontWeight: 500 }}>
                        Users
                      </CardTitle>
                      {/* <p className="card-title-desc">
                    react-bootstrap-table-next plugin has most features enabled by default,
                    so all you need to do to use it with your own tables is to
                    call the construction function:{" "}
                    <code>react-bootstrap-table-next </code>.
                  </p> */}

                      <PaginationProvider
                        pagination={
                          paginationReset ?
                            paginationFactory({
                              ...pageOptions,
                              page: 1
                            }
                            ) :
                            paginationFactory(pageOptions)
                        }
                        keyField="action"
                        columns={columns}
                        data={productData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="action"
                            columns={columns}
                            data={productData}
                            search
                          // autoComplete="off"
                          // Input='text'
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col xl="9"></Col>

                                  <Col xl="3">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                          onClear={() => setPaginationReset(false)}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        // rowEvents={rowEvents}
                                        keyField={"action"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap table-hover"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  )
}

AccountDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(AccountDetail)
