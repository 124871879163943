import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import SweetAlert from "react-bootstrap-sweetalert"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CropEasy from "../../../Crop/CropEasy"
import "../../configCk/ck.style.css"


import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  NavItem,
  NavLink,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"


import { useHistory } from "react-router-dom"

import {
  createReportTemplate, getReportTemplate, clearReportTemplate, handleUnsavedChanges,
  handleChangesHappend,
  UpdateGetReportTemplate,
} from "store/actions"
import { imageUploadHandler } from "commonFunctions/UploadImage/uploadImage"
import ImageThumbnail from "components/Common/ImageThumbnail/ImageThumbnail.component"
import { createThumbnail } from "commonFunctions/CreateThumbnails/createThumbnail"
import ImagePreview from "components/Common/ImagePreview/ImagePreview.component"
import { TrendingUpRounded } from "@mui/icons-material"
import { CLOUD_STORAGE } from "config/cloudStorage.config"


const PriorityArea = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [rows1, setrows1] = useState([])
  const [input, setInput] = useState([])
  const [file, setFile] = useState()
  const [imageData, setImageData] = useState(null)
  const [icon, setIcon] = useState([])
  const [imgName, setImgName] = useState(0)
  const [iconThumbnail, setIconThumbnail] = useState([])
  const [previewImage, setPreviewImage] = useState({
    visible: false,
    image: 0
  });
  const [openCrop, setOpenCrop] = useState(false)
  const [image_storagePath, setStoragePath] = useState()
  const [thumbnail_storagePath, setThumbnailPath] = useState()
  const [HeightWidth, setHeightWidth] = useState({})
  const [isDataAdd, setIsDataAdd] = useState(false)

  const [isImageThere, setImageThere] = useState(false)
  const [isGetReportData, setIsGetReportData] = useState(true)
  const [PriorityAreaUpdate, setPriorityAreaUpdate] = useState({})


  const { getReport, createReport, isDataEdited, isThereChange } = useSelector(state => ({
    getReport: state.createReportReducer.getReport,
    createReport: state.createReportReducer.createReport,
    isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges
  }))

  useEffect(() => {
    if (!Object.keys(getReport).includes("priorityAreaOfFocus")) {
      dispatch(getReportTemplate())
    }
  }, [dispatch])

  useEffect(() => {
    if (getReport.priorityAreaOfFocus !== undefined) {
      // setInput(getReport.executiveSummary.contents)
      setIcon(getReport.priorityAreaOfFocus.map(icon => icon.icon))
      setIconThumbnail(getReport.priorityAreaOfFocus.map(icon => icon.iconThumbnail))

      var x = getReport.priorityAreaOfFocus.map((priority, i) => {
        return {
          id: Math.random(),
          title: priority.title,
          description: priority.description,
          icon: priority.icon,
          iconThumbnail: priority.iconThumbnail
        }
      })
      setrows1(x)
      setIsGetReportData(false)
    } else {
      setrows1([{ id: Math.random(), content: "" }])
      setIsGetReportData(false)

    }
  }, [getReport])




  const priorityAreaManager = () => {
    var getPriorityArea = document.querySelectorAll(".ck-content")
    var getPriorityTitle = document.querySelectorAll(".test01")
    var myPriorityAreaList = [...getPriorityArea]
    var myPriorityTitlesList = [...getPriorityTitle]
    var myPriorityArea = myPriorityAreaList.map(obs => obs.innerHTML)
    var myPriorityTitle = myPriorityTitlesList.map(obs => obs.value)

    return {
      priorityDescriptions: myPriorityArea,
      priorityTitles: myPriorityTitle,
    }
  }

  const myTestFunction = () => {
    const list = priorityAreaManager()
    var newObj = list.priorityTitles.map((obj, i) => {
      return {
        title: list.priorityTitles[i],
        description: list.priorityDescriptions[i],
        icon: icon[i] || getReport.priorityAreaOfFocus[i] && getReport.priorityAreaOfFocus[i].icon,
        iconThumbnail: iconThumbnail[i] || getReport.priorityAreaOfFocus[i] && getReport.priorityAreaOfFocus[i].iconThumbnail
      }
    })
    return newObj
  }

  function handleAddRowNested() {
    if (rows1.length < 10) {
      // const modifiedRows = [...rows1]
      // modifiedRows.push({ id: modifiedRows.length + 1 })
      setrows1(e => [...e, { id: Math.random(), description: "", icon: "", iconThumbnail: "", title: "" }])
    }
  }

  function handleRemoveRow(id, INDEX) {

    if (rows1.length > 1) {
      // var modifiedRows = [...rows1]
      // modifiedRows = modifiedRows.filter(x => x["id"] !== id)

      var ICON = icon
      var ICON_THUMBNAIL = iconThumbnail
      // console.log(INDEX)
      ICON.splice(INDEX, 1)
      ICON_THUMBNAIL.splice(INDEX, 1)

      setIcon(ICON)
      setIconThumbnail(ICON_THUMBNAIL)
      setrows1(rows1.filter(row => row.id !== id))

    }
    dispatch(handleChangesHappend(true))
  }
  const getIconsUrl = (imgUrl) => {

    if (icon[imgName]) {
      var replaceImageUrl = icon.map((url, index) => {
        if (imgName === index) {
          return imgUrl
        }
        else
          return url
      })
      
      setIcon(replaceImageUrl)
      var row_icon = rows1
      row_icon[imgName].icon = replaceImageUrl[imgName]
      setrows1(row_icon)
    } else {
      setIcon(e => [...e, imgUrl])
      var row_icon = rows1
      row_icon[imgName].icon = imgUrl
      setrows1(row_icon)
    }

  }
  const getIconsTumbnailUrl = (imgUrl) => {

    if (iconThumbnail[imgName]) {
      var replaceImageUrl = iconThumbnail.map((url, index) => {
        if (imgName === index) {
          return imgUrl
        }
        else
          return url
      })
      setIconThumbnail(replaceImageUrl)

      var row_thumbIcon = rows1
      row_thumbIcon[imgName].iconThumbnail = replaceImageUrl[imgName]
      setrows1(row_thumbIcon)


    } else {
      setIconThumbnail(e => [...e, imgUrl])
      var row_thumbIcon = rows1
      row_thumbIcon[imgName].iconThumbnail = imgUrl
      setrows1(row_thumbIcon)
    }

  }
  const handleChange = e => {
    const file = e.target.files[0]

    if (file) {
      var u = URL.createObjectURL(file)
      var img = new Image;
      img.onload = function () {
        var IMAGE_HEIGHT = img.height
        var IMAGE_WIDTH = img.width
        // HEIGHT > WIDTH ? console.log('vertical Image') : console.log('horizontal image')

        // console.log("Width: " + IMAGE_WIDTH + " " + "Height: " + IMAGE_HEIGHT);

        setHeightWidth({
          myheight: IMAGE_HEIGHT,
          mywidth: IMAGE_WIDTH
        })


      };
      img.src = u;

      setFile(file)
      setImageData(u)
      // setOpenCrop(true)
      dispatch(handleChangesHappend(TrendingUpRounded))
      e.target.value = ''
    }
    // console.log(file)
  }

  useEffect(() => {
    if (file) {
      imageUploadHandler(`${CLOUD_STORAGE.CREATE_REPORT_TEMPLATE.PRIORITY_AREA_OF_FOCUS_IMAGE}`, `/priority_${file.name}`.split(".")[0] + (Math.random()).toString().split(".")[1] + "." + `/priority_${file.name}`.split(".")[1], file, getIconsUrl, () => setFile(null))
      createThumbnail(`${CLOUD_STORAGE.CREATE_REPORT_TEMPLATE.PRIORITY_AREA_OF_FOCUS_THUMBNAIL}`, `/priority_${file.name}`.split(".")[0] + (Math.random()).toString().split(".")[1] + "." + `/priority_${file.name}`.split(".")[1], file, getIconsTumbnailUrl, false, "jpeg/png")
    }
  }, [file])

  useEffect(() => {
    setImageData(null)
  }, [iconThumbnail])
  // console.log(icon, iconThumbnail)

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Scorecard Report Template</title>
        </MetaTags>
        <Container fluid={true}  >

          {isGetReportData ?
            <div style={
              {
                width: "100%",
                textAlign: "center",
                height: "400px"
              }
            } >
              <Spinner
                style={{
                  width: "60px", height: "60px",
                  margin: "calc(200px - 30px)",

                }}
                role="grid"
                type="border"
                color="primary"
              />
            </div>
            :
            <Row>
              <Col xl="12" style={{ margin: "auto" }}>
                <Card style={{ position: "relative" }}>
                  {
                    previewImage.visible &&
                    <ImagePreview ImageUrl={icon[previewImage.image]} TogglePreview={() => {

                      setPreviewImage(e => ({
                        ...e,
                        visible: false
                      }));
                    }} />
                  }

                  <CardBody>
                    {createReport.status === 200 && (
                      <SweetAlert
                        // title="Tactic Updated successfully"
                        success
                        confirmBtnBsStyle="primary"
                        style={{ height: "255px", width: "368px" }}
                        onConfirm={() => {
                          dispatch(UpdateGetReportTemplate(PriorityAreaUpdate))
                          setIsDataAdd(false)
                          // setsuccess_msg(false)
                          dispatch(clearReportTemplate())
                          // history.push("/")
                        }}

                      >
                        Data saved successfully.
                      </SweetAlert>
                    )}
                    {createReport.status === 403 && (
                      <SweetAlert
                        // title="Tactic Updated successfully"
                        error
                        confirmBtnBsStyle="primary"
                        style={{ height: "255px", width: "368px" }}
                        onConfirm={() => {
                          setIsDataAdd(false)
                          // setsuccess_msg(false)
                          dispatch(clearReportTemplate())
                          // history.push("/")
                        }}

                      >
                        Failed to saved data.
                      </SweetAlert>
                    )}
                    
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        setIsDataAdd(true)
                        setPriorityAreaUpdate({
                          ...getReport,
                          priorityAreaOfFocus: myTestFunction()
                        })
                        dispatch(
                          createReportTemplate({
                            priorityAreaOfFocus: myTestFunction(),
                          })
                        )
                        dispatch(handleChangesHappend(false))
                        // history.push("/ContentQuick")
                      }}
                    >
                      <Row>
                        <h4
                          className="card-title"
                        // style={{color:"Background"}}
                        >
                          Priority Areas of Focus
                        </h4>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md="12">
                          {/* repeater */}
                          <div data-repeater-list="outer-group" className="outer">
                            <div data-repeater-item className="outer">
                              <div className="inner-repeater mb-4">
                                <table
                                  style={{ width: "100%" }}
                                  className="Pointer"
                                >
                                  <tbody>
                                    {rows1.map((formRow, key) => (
                                      <tr key={key}>
                                        <td>
                                          <Row className="mb-2">
                                            <Row
                                            // style={{
                                            //   marginTop: "20px",
                                            //   marginBottom: "10px"
                                            // }}
                                            >
                                              <Col md="5">
                                                {/* <div className="col-md-10"> */}
                                                <FormGroup className="mb-2" >
                                                  <input
                                                    // style={{textAlign:"left"}}
                                                    // ref={myRef}
                                                    placeholder="Title"
                                                    value={
                                                      formRow.title
                                                      // getReport.priorityAreaOfFocus &&
                                                      //   getReport.priorityAreaOfFocus[
                                                      //   key
                                                      //   ]
                                                      //   ? getReport
                                                      //     .priorityAreaOfFocus[
                                                      //     key
                                                      //   ].title
                                                      //   : ""
                                                    }
                                                    className="form-control test01"
                                                    type="text"
                                                    onChange={(x) => {
                                                      setrows1(e => e.map(rw => {
                                                        if (rw.id === formRow.id)
                                                          return {
                                                            ...rw,
                                                            title: x.target.value
                                                          }
                                                        else
                                                          return rw

                                                      }))
                                                      dispatch(handleChangesHappend(true))
                                                    }}
                                                  // defaultValue="PRIORITIZE PROSPECTIVE CUSTOMER"
                                                  />
                                                </FormGroup>
                                                {/* </div> */}
                                              </Col>
                                              <Col md="2"></Col>

                                              <Col md="2">
                                                {/* <div className="col-md-12"> */}
                                                <FormGroup>
                                                  <Input

                                                    //  style={{  textAlign:"right" }}
                                                    className="form-control form-control-md inputFile"
                                                    id="formFileSm"
                                                    type="file"
                                                    onChange={e => {
                                                      handleChange(e)
                                                      setImgName(key)
                                                    }
                                                    }


                                                    style={{ display: "none" }}
                                                  />
                                                  <Button
                                                    color="primary"
                                                    onClick={() => {
                                                      var inputFile = document.querySelectorAll(".inputFile")
                                                      inputFile[key].click()
                                                    }}
                                                    style={{
                                                      position: "absolute",
                                                      right: 0,
                                                    }}
                                                  >Upload Icon</Button>{""}
                                                  <br />

                                                  {/* {openCrop && key === rows1.length - (rows1.length - imgName) && (
                              <CropEasy
                              image_url={imageData}
                                imageName={`/priority_${imageData.name}`}
                                image_storagePath ={"/reportTemplate/priorityAreaOfFocus/images"}
                                thumbnail_storagePath = {"/reportTemplate/priorityAreaOfFocus/thumbnails"}
                                setOpenCrop={setOpenCrop}
                                setImageUrl={setImageData}
                                setFile={setFile}
                                setIconThumbnail ={setIconThumbnail}
                                ImageHeightWidth = { HeightWidth }
                                ImageType = "PRIORITY"
                              />
                            )}   */}
                                                  {/* </div> */}
                                                </FormGroup>
                                              </Col>
                                              <Col md="1"></Col>

                                              <Col md="1" >
                                                {
                                                  file && imageData && key === rows1.length - (rows1.length - imgName) ?
                                                    <Spinner />
                                                    :
                                                    <ImageThumbnail
                                                      onClick={() => {
                                                        setPreviewImage({
                                                          visible: true,
                                                          image: key
                                                        });
                                                      }}
                                                      imgeUrl={
                                                        // formRow.iconThumbn ail
                                                        iconThumbnail[key]
                                                      }
                                                    />

                                                }
                                              </Col>
                                              <Row>
                                                <Col md="2"></Col>
                                                <Col md="8">

                                                  {/* {openCrop && key === rows1.length - (rows1.length - imgName) && (
                                                    <CropEasy
                                                      image_url={imageData}
                                                      imageName={`/priority_${file.name}`}
                                                      image_storagePath={CLOUD_STORAGE.CREATE_REPORT_TEMPLATE.PRIORITY_AREA_OF_FOCUS_IMAGE}
                                                      thumbnail_storagePath={CLOUD_STORAGE.CREATE_REPORT_TEMPLATE.PRIORITY_AREA_OF_FOCUS_THUMBNAIL}
                                                      setOpenCrop={setOpenCrop}
                                                      setImageUrl={getIconsUrl}
                                                      setFile={setFile}
                                                      setIconThumbnail={getIconsTumbnailUrl}
                                                      ImageHeightWidth={HeightWidth}
                                                      setImageThere={setImageThere}
                                                      ImageType="PRIORITY"
                                                    />
                                                  )} */}
                                                </Col>
                                              </Row>


                                            </Row>




                                            <Row  >


                                              <Col md="11" style={{ cursor: 'text' }}>
                                                <FormGroup>
                                                  <CKEditor
                                                    //  placeholder = {`key obervation - ${++key}`}
                                                    id={formRow.id}
                                                    editor={ClassicEditor}
                                                    data={
                                                      formRow.description
                                                      // getReport.priorityAreaOfFocus &&
                                                      //   getReport.priorityAreaOfFocus[
                                                      //   key
                                                      //   ]
                                                      //   ? getReport
                                                      //     .priorityAreaOfFocus[
                                                      //     key
                                                      //   ].description
                                                      //   : ""
                                                    }
                                                    config={{
                                                      removePlugins: [
                                                        "EasyImage",
                                                        "ImageUpload",
                                                        "MediaEmbed",
                                                        "Essentials",
                                                        "Table",
                                                        "Table Toolbar",
                                                        "CkFinderUploadAdapter",
                                                        "CKFinder",
                                                        "Autoformat",
                                                        "Link",
                                                        "BlockQuote",
                                                      ],
                                                      heading: {
                                                        options: [
                                                          { model: 'heading3', view: 'h4', title: 'Heading 3' }
                                                        ]
                                                      },
                                                      toolbar: [
                                                        'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent'
                                                      ]
                                                    }}
                                                    onReady={editor => {
                                                      editor.execute("heading", { value: "heading3" });
                                                      // You can store the "editor" and use when it is needed.
                                                    }}
                                                    onChange={(e, data) => {
                                                      priorityAreaManager()
                                                      setrows1(e => e.map(rw => {
                                                        if (rw.id === formRow.id)
                                                          return {
                                                            ...rw,
                                                            description: data.getData() ? data.getData() : formRow.description
                                                          }
                                                        else
                                                          return rw

                                                      }))
                                                      dispatch(handleChangesHappend(true))
                                                    }}
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col md="1">
                                                <FormGroup>
                                                  {
                                                    rows1.length > 1 ? <i className="mdi mdi-trash-can d-block font-size-24" style={{
                                                      color: "#FF6060",
                                                    }}
                                                      onClick={e => {
                                                        handleRemoveRow(formRow.id, key)
                                                      }}

                                                    ></i> : true
                                                  }
                                                  {/* <Button
                                                  color=""
                                                  className="btn-block inner"
                                                  id="unknown-btn"
                                                  style={{
                                                    width: "100%",
                                                    color: "blue",
                                                  }}
                                                  disabled={
                                                    formRow.id > 1 ? false : true
                                                  }
                                                  onClick={e => {
                                                    handleRemoveRow(formRow.id)
                                                  }}
                                                > */}
                                                  {/* {
                                                  formRow.id >= rows1.length ? <i className="mdi mdi-trash-can d-block font-size-24" style={{
                                                    color: "#FF6060",
                                                  }}
                                                    onClick={e => {
                                                      handleRemoveRow(formRow.id)
                                                    }}

                                                  ></i> : true
                                                } */}
                                                  {/* <i className="mdi mdi-trash-can d-block font-size-24" style={{
                                                    color: "#FF6060",
                                                  }} 
                                                  onClick={e => {
                                                    handleRemoveRow(formRow.id)
                                                  }}
                                                
                                                  ></i>{" "} */}
                                                  {/* </Button> */}
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          </Row>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <Button
                                  disabled={rows1.length < 10 ? false : true}
                                  onClick={() => {
                                    handleAddRowNested()
                                  }}
                                  color="primary"
                                  className="mt-1"
                                >
                                  Add New
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md="10"></Col>
                        <Col md="2">
                          <Button
                            color="primary"
                            type="submit"
                            style={{ width: "100%" }}
                            disabled={isDataAdd ? true : false}
                            block
                          >
                            {isDataAdd ? "" : "Save"}
                            {isDataAdd ? (
                              <Spinner type="border" size="sm" />
                            ) : null}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </>
  )
}

export default PriorityArea
