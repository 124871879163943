import { PAYMENT_SUCCESS_CLEAR, PAYMENT_SUCCESS_FAIL, PAYMENT_SUCCESS_SUCCESS } from "./actionTypes";


const INIT_STATE = {
    paymentSuccessData: null //object
};

const PaymentSuccessReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case PAYMENT_SUCCESS_SUCCESS:
            return {
                ...state,
                paymentSuccessData: action.payload,
            };

        case PAYMENT_SUCCESS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case PAYMENT_SUCCESS_CLEAR:
            return {
                ...state,
                paymentSuccessData: null,
            };

        default:
            return state;
    }
};

export default PaymentSuccessReducer;