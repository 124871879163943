import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState, useCallback, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import { Toast } from "react-bootstrap"
import * as Yup from "yup"
import { setIn, useFormik } from "formik"
// import SweetAlert from "react-bootstrap-sweetalert"

// Essential import statement for POC

import Image_1 from "../../../assets/images/ScreenshotsForInstructions/RecommendationWithEdit.png"
import Image_2 from "../../../assets/images/ScreenshotsForInstructions/RecommendationWithoutEdit.png"
import { Link, withRouter, useParams, useHistory } from "react-router-dom"

import {
  getAllInstructionData,
  addNewInstruction,
  updateInstruction,
  handleChangesHappend,
  clearupdateInstruction
} from "store/actions"

const Recommendation = () => {
  const params = useParams()
  const history = useHistory()
  const hiddenFile = useRef()
  const dispatch = useDispatch()

  const [infoForSaveButton, setInfoForSaveButton] = useState("")
  const [infoForEditButton, setInfoForEditButton] = useState("")
  const [infoForUploadImageButton, setInfoForUploadImageButton] = useState("")

  const [saveInfo, setSaveInfo] = useState(false)
  const [alldata, setAllData] = useState({})
  const [toastSuccess, setToastSuccess] = useState(false)
  const [toastFail, setToastFail] = useState(false)
  const [pageData, setPageData] = useState(true)
  const [accountUserInstructionData, setAccountUserInstructionData] =
    useState(null)

  const [openPopoverForSavePageBtn, setOpenPopoverForSavePageBtn] =
    useState(false)
  const [openPopoverForUploadImageBtn, setOpenPopoverForUploadImageBtn] =
    useState(false)
  const [openPopoverForEditBtn, setOpenPopoverForEditBtn] = useState(false)

  const { getInstructionData, addInstructionData, isThereChange, updateInstructionData } = useSelector(
    state => ({
      getInstructionData: state.instructionPanelReducer.getInstructionData,
      addInstructionData: state.instructionPanelReducer.addInstructionData,
      isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
      updateInstructionData: state.instructionPanelReducer.updateInstructionData,
    })
  )

  useEffect(() => {
    if (!Object.keys(getInstructionData).includes("infoForAccountUser")) {
      dispatch(getAllInstructionData())
    }
  }, [dispatch])

  useEffect(() => {
    if (getInstructionData.infoForAccountUser) {
      var ALL_DATA = {
        ...getInstructionData.infoForAccountUser,
      }
      setAccountUserInstructionData(ALL_DATA)
    }
  }, [getInstructionData])

  useEffect(() => {
    if (accountUserInstructionData) {
      setPageData(false)
      var data = {
        infoForSaveBtn: accountUserInstructionData.recommendation.infoForSaveBtn
          ? accountUserInstructionData.recommendation.infoForSaveBtn
          : "",
        infoForEditBtn: accountUserInstructionData.recommendation.infoForEditBtn
          ? accountUserInstructionData.recommendation.infoForEditBtn
          : "",
      }
      setAllData({ ...data })
      setInfoForSaveButton(data.infoForSaveBtn)
      setInfoForEditButton(data.infoForEditBtn)
    }
  }, [accountUserInstructionData])
  // ----- Saving all instruction to the backend ----
  //   console.log(openPopoverForCloseEditing)

  // For Save Button
  const saveBtn = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForSavePageBtn}
    >
      <Popover.Header>
        Save Button
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForSavePageBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  recommendation: {
                    ...alldata,
                    infoForSaveBtn: infoForSaveButton,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForSaveButton(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForSaveButton}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForSavePageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )

  // For upload image Button
  const editBtn = (
    <Popover
      id="popover-positioned-auto"
      style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}
      show={openPopoverForEditBtn}
    >
      <Popover.Header>
        Edit Button
        <button
          type="button"
          className="close"
          onClick={() => setOpenPopoverForEditBtn(false)}
        ></button>
      </Popover.Header>
      <Popover.Body>
        <Form
          onSubmit={e => {
            setSaveInfo(true)
            e.preventDefault()
            dispatch(
              updateInstruction({
                ...getInstructionData,
                infoForAccountUser: {
                  ...accountUserInstructionData,
                  recommendation: {
                    ...alldata,
                    infoForEditBtn: infoForEditButton,
                  },
                },
              })
            )
            dispatch(handleChangesHappend(false))
          }}
        >
          <textarea
            className="form-control-sm form-control mb-1 font-size-13"
            style={{ height: "100px", width: "242px" }}
            onChange={e => {
              setInfoForEditButton(e.target.value)
              dispatch(handleChangesHappend(true))
            }}
            value={infoForEditButton}
          ></textarea>
          <div>
            <Row>
              <Col md="8"></Col>
              {/* <Col md="3">
                <Button 
                  color="danger"
                  type="button"
                  size="sm"
                  className="mx-1 mt-1" 
                  onClick={() => setOpenPopoverForApplyPageBtn(false)}
                >
                  Cancel
                </Button>
              </Col> */}
              <Col md="4">
                <Button
                  color="primary"
                  className="mt-1"
                  type="submit"
                  size="sm"
                  block
                  disabled={saveInfo ? true : false}
                >
                  {saveInfo ? "" : "Save"}
                  {saveInfo ? <Spinner type="border" size="sm" /> : null}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  )

  useEffect(() => {
    if (updateInstructionData.status === 200) {
      setToastSuccess(true)
      setOpenPopoverForSavePageBtn(false)
      setOpenPopoverForUploadImageBtn(false)
      setOpenPopoverForEditBtn(false)
      setSaveInfo(false)
      dispatch(getAllInstructionData())
      dispatch(clearupdateInstruction())
     
    } else if (updateInstructionData.status === 403) {
      setToastFail(true)
      setOpenPopoverForSavePageBtn(false)
      setOpenPopoverForUploadImageBtn(false)
      setOpenPopoverForEditBtn(false)
      setSaveInfo(false)
      dispatch(clearupdateInstruction())
    }
  }, [updateInstructionData])

  

  return (
    <>
      <div className="page-content">
        {toastSuccess && (
          <div
            className="position-fixed top-0 left-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast
              
              delay={3000}
              show={true}
              onClose={() => setToastSuccess(false)}
              autohide
            >
              <Toast.Body
                style={{ color: "#fff", height: "55px" }}
                className="font-size-18 bg-primary"
              >
                <i
                  className="mdi mdi-check-circle d-flex"
                  style={{ fontSize: 22 }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      fontStyle: "normal",
                      marginTop: "2px",
                    }}
                    className="mx-3"
                  >
                    Instructions saved successfully.
                  </p>
                </i>
              </Toast.Body>
            </Toast>
          </div>
        )}
        {toastFail && (
         <div className="position-fixed top-0 left-0 p-3" style={{ zIndex: "1005" }}>
        <Toast
               
                delay={3000}
                show={toastFail}
                onClose={() => setToastFail(false)}
                autohide
              >
                <Toast.Body
                  style={{ color: "#fff", height: "55px" }}
                  className="font-size-18 bg-danger"
                >
                  <i
                    className="mdi mdi-close-circle d-flex"
                    style={{ fontSize: 22 }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontStyle: "normal",
                        marginTop: "2px",
                      }}
                      className="mx-3"
                    >
                      Failed to save instructions.
                    </p>
                  </i>
                </Toast.Body>
              </Toast>
     </div>
          
              
        
        )}
        <MetaTags>
          <title>Mambo - Instructions For Account User</title>
        </MetaTags>
        {pageData ? (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              height: "400px",
            }}
          >
            <Spinner
              style={{
                width: "60px",
                height: "60px",
                margin: "calc(200px - 30px)",
              }}
              role="grid"
              type="border"
              color="primary"
            />
          </div>
        ) : (
          <Container
            fluid={true}
            className="my-3 justify-content-center"
            style={{
              transform: "scale(.8) translate(-130px, -132px) ",
              width: "1550px",
            }}
          >
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div>
                      <div className="my-3">
                        <h2 className="fw-bold" style={{ color: "#0D0F11" }}>
                          Instruction For Recommendation
                        </h2>
                      </div>
                      <div
                        style={{
                          height: "650px",
                          width: "100%",
                          transform: "scale(1)",
                          backgroundImage: `url(${Image_1})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        {/* First Row for i-button in Table of Content Page */}

                        <Row>
                          <Col md="8"></Col>
                          <Col md="1">
                            <div>
                              <label
                                type="button"
                                style={{
                                  marginTop: "80px",
                                  marginLeft: "20px",
                                }}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="bottom"
                                  overlay={saveBtn}
                                  show={openPopoverForSavePageBtn}
                                >
                                  <span
                                    id="close-editing"
                                    className="mdi mdi-information"
                                    onClick={() =>
                                      setOpenPopoverForSavePageBtn(
                                        !openPopoverForSavePageBtn
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "22px",
                                      color: "#5015cf",
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: "#fff",
                                      cursor: "pointer",
                                    }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                          </Col>

                          <Col md="2"></Col>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <div className="mt-5 mb-2">
                        <h2 className="fw-bold" style={{ color: "#0D0F11" }}>
                          2. Instruction For Recommendation
                        </h2>
                      </div>
                      <div
                        style={{
                          height: "650px",
                          width: "100%",
                          transform: "scale(1)",
                          backgroundImage: `url(${Image_2})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        {/* First Row for i-button */}
                        <Row>
                          <Col xs="7"></Col>
                          <Col xs="1">
                            <div>
                              <label
                                type="button"
                                style={{
                                  marginTop: "127px",
                                }}
                              ></label>
                            </div>
                          </Col>
                          <Col md="1">
                            <div>
                              <label
                                type="button"
                                style={{
                                  marginTop: "80px",
                                  marginLeft: "20px",
                                }}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="bottom"
                                  overlay={editBtn}
                                  show={openPopoverForEditBtn}
                                >
                                  <span
                                    className="mdi mdi-information"
                                    onClick={() =>
                                      setOpenPopoverForEditBtn(
                                        !openPopoverForEditBtn
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "22px",
                                      color: "#5015cf",
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: "#fff",
                                    }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6"></Col>

                          {/* <Col md="1">
                            <div>
                              <label
                                type="button"
                                style={{
                                  marginTop: "265px",
                                  marginLeft:'100px'
                                 
                                }}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="top"
                                  overlay={uploadImageBtn}
                                  show={openPopoverForUploadImageBtn}
                                >
                                  <span
                                    className="mdi mdi-information"
                                    onClick={() =>
                                      setOpenPopoverForUploadImageBtn(
                                        !openPopoverForUploadImageBtn
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "22px",
                                      color: "#5015cf",
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: "#fff",
                                    }}
                                  ></span>
                                </OverlayTrigger>
                              </label>
                            </div>
                          </Col> */}
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  )
}

export default Recommendation
