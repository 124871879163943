import {
    GET_CHANNEL_DATA_SUCCESS,
    GET_CHANNEL_DATA_FAIL,
    CHANGE_CHANNEL_DATA,
    UPDATE_CHANNEL_DATA_STATE,
    CLEAR_UPDATE_CHANNEL_DATA_STATE
  } from "./actionTypes";
  
  const INIT_STATE = {
    channelData: [],
  };
  
const GetChannelData = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_CHANNEL_DATA_SUCCESS:
        return {
          ...state,
          channelData: action.payload,
        };
        
      case GET_CHANNEL_DATA_FAIL:
        return {
          ...state,
          error: action.payload,
        };

      case CHANGE_CHANNEL_DATA:
        return {
          ...state,
          channelData: action.payload
        };
      case UPDATE_CHANNEL_DATA_STATE:
        return{
          ...state,
          channelData: action.payload
        }
      case CLEAR_UPDATE_CHANNEL_DATA_STATE:
        return{
          ...state,
          channelData:[]

        }
        
      default:
        return state;
    }
  };
  
  export default GetChannelData;