import React from "react";
import { Spinner } from "reactstrap";
import "./ImageThumbnail.style.css"
const ImageThumbnail = (props) => {
    const ImageUrl = props
    return (
        <div className="thumbnail-container" style={{
           
            backgroundImage: `url(${ImageUrl.imgeUrl})`
        }} 
        onClick = {ImageUrl.onClick}
        >
            {/* {
                ImageUrl.imageData && ImageUrl.Key === ImageUrl.rowCount.length-1 &&
                <Spinner />
            } */}
        </div>
    );
}

export default ImageThumbnail;