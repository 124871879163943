import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
// ------------------------------------------------------------

// Vendor imports:
import RadarChart from "pages/AccountUser/chart/chart"
import { Modal, Row, Table, Spinner, Col, Button } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { getChannelScore } from "commonFunctions/GetChannelScore/getChannelScore"
import {
  // getReportTemplate,
  getScorecardReport,
  HandleWindowInActivity,
  getScorecardDetail,
  startCoachingForAssessment,
} from "store/actions"

import ShowSpinner from "commonFunctions/ShowSpinner/ShowSpinner"
import "./TakeASurvey.sidebar.css"
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { startSurveyTourSteps } from "config/TourSteps.config"

const TakeASurvey = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  // --------------------------------------------------------------------

  // Vendor states & hooks (functionality):
  const [modal_standard, setmodal_standard] = useState(false)
  const [activityState, setActivityState] = useState(false)

  const [refresh_score, set_Refresh_Score] = useState(false)
  const [spinnerForSecond, setSpinnerForSecond] = useState(null)
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const { scorecardDetail, windowInActivity, isCoachingStartedForAssessment, GetTacticInfo } = useSelector(state => ({
    scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
    windowInActivity: state.ScorecardReport_Reducer.windowInActivity,
    isCoachingStartedForAssessment: state.CoachingPageReducer.isCoachingStartedForAssessment,
    GetTacticInfo: state.AddTacticReducer.GetTacticInfo,
  }))

  useEffect(() => {
    dispatch(getScorecardDetail(params.scorecardId))
  }, [dispatch])

  // Handle in-activity:
  const logout = () => {
    dispatch(HandleWindowInActivity(true))
  }
  useEffect(() => {
    let time
    setActivityState(true)
    if (activityState) {
      const resetTimer = () => {
        clearTimeout(time)
        time = setTimeout(logout, 18 * 60000)
      }
      window.onload = resetTimer
      document.onmousemove = resetTimer
      document.onkeypress = resetTimer
    }

    return () => {
      clearTimeout(time)
      setActivityState(false)
      window.onload = () => {}
      document.onmousemove = () => {}
      document.onkeypress = () => {}
    }
  }, [activityState])

  // useEffect(() => {
  //   if (Object.keys(scorecardDetail).includes("selectedChannels")) {
  //     window.localStorage.setItem("selectedChannels", JSON.stringify(scorecardDetail.selectedChannels))
  //   }
  // }, [scorecardDetail])

  // console.log("Details...", scorecardDetail)

  useEffect(() => {
    if (
      scorecardDetail[0] &&
      scorecardDetail.selectedChannels.map(
        channel => getChannelScore(channel) >= 0
      )
    ) {
      set_Refresh_Score(false)
    }
  })

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  // console.log("Scorecard Detail...", scorecardDetail)
  // Vendor js styling:
  const bubble = {
    display: "block",
    backgroundColor: "#fff",
    padding: "2px",
    width: "50px",
    textAlign: "center",
    lineHeight: "30px",
    height: "35px",
    color: "#000",
    borderRadius: "20px",
    transform: "scale(.65)",
    position: "absolute",
    right: "0px",
    top: "0px",
    zIndex: 100,
    justifyContent: "center",
    marginTop:'3px',
    fontSize:'17px',
    marginLeft:'50px'
    // fontWeight:600
  
   
    // fontWeight: "bold",
  }
  const TickMarkbubble = {
    display: "block",
    backgroundColor: "transperent",
    padding: "2px",
    width: "25px",
    textAlign: "center",
    lineHeight: "30px",
    height: "30px",
    color: "#000",
    borderRadius: "20px",
    transform: "scale(.65)",
    position: "absolute",
    right: "4px",
    top: "0px",
    zIndex: 100,
    justifyContent: "center",
    
  
   
    // fontWeight: "bold",
  }

  // Functionality to redirect inactive user to scorecard details page.
  useEffect(() => {
    var active_userName =
      scorecardDetail.activeUser && scorecardDetail.activeUser.userName
    var current_userName = JSON.parse(
      localStorage.getItem("authUser")
    ).displayName

    if (scorecardDetail.id && !scorecardDetail.activeUser) {
      history.push(`/view/scorecard/${params.scorecardId}`)
    }
    //
    if (scorecardDetail.id && active_userName !== current_userName) {
      history.push(`/view/scorecard/${params.scorecardId}`)
    }
    // return () => dispatch(clearScore)
  }, [scorecardDetail])
  // ------------

  // ---------------------------------------------------------------------
  // Don't touch, else you will have to pay $4000.00
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])
  useEffect(() => {
    ref.current.recalculate()
  })
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  // --------------------

  const ShowChannelRefreshment = (ID) => {
    var spanId = document.getElementById(ID)
    if(spanId){
      set_Refresh_Score(true)
      // console.log(ID)
    }

  }

  const getSpinnerActivate = (id) => {
    var channelId = id
    setSpinnerForSecond(channelId)
    setTimeout(() => {
      setSpinnerForSecond(null)
    }, 2000)
  }

  // console.log(refresh_score)

  // To start tour guide for survey page
  const startTourGuideObj = driver({
    animate: true,
    showProgress: true,
    allowKeyboardControl: true,
    disableActiveInteraction: false,
    progressText: "{{current}} / {{total}}",
    steps: [
      ...startSurveyTourSteps
    ],
    onCloseClick: () => {
      dispatch(startCoachingForAssessment(false))
    },
    onDestroyed: (element, steps, option) => {
      dispatch(startCoachingForAssessment(false))
    },
  })

  const handleTourGuide = () => {
    dispatch(startCoachingForAssessment(true))
  }

  useEffect(() => {
    if (isCoachingStartedForAssessment) {
      startTourGuideObj.drive()
    } else {
      startTourGuideObj.destroy()
    }
  }, [isCoachingStartedForAssessment])
  

  return (
    <React.Fragment>
      {
        scorecardDetail && scorecardDetail.scorecardVersion === "Pro" ? (
          <div className="mb-1 mx-1 py-1 spider-graph-menu px-3">
            <li className="py-1 rounded-3">
              <div
                onClick={() => tog_standard()}
              >
                <i className="mdi mdi-eye-outline mx-2"></i>
                <span>{props.t("View Scores")}</span>
              </div>
            </li>
          </div>
        ) : (
          <div className="mb-1 mx-1 py-1 tour-guide-menu px-3">
            <li className="py-1 rounded-3">
              <div
                className="d-flex align-items-center justify-content-center"
                onClick={handleTourGuide}
              >
                <button className="coach-page-btn">
                  <i className="bx bx-book mx-2"></i>
                  <span className="me-2">{props.t("Coaching Page")}</span>
                </button>
              </div>
            </li>
          </div>    
        )
      }
      <div className="row text-center d-flex justify-content-center mt-2 pb-2">
        <div className="w-75" style={{borderBottom:'1px solid #B8C0CC'}}></div>
      </div>
      <SimpleBar ref={ref} style={{ overflowX: "hidden", overflowY:'auto', height:'fit-content', height:'90%' }} className="tour-guide-sidebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="mt-2 mb-2 mx-3 px-2"
              style={{
                color: "#003F44",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "19px",
                margin: "15px px",
              }}
            >
              {props.t("Channels")}{":"}
            </li>
            {Object.keys(scorecardDetail).includes("selectedChannels") &&
              scorecardDetail.selectedChannels.map((CHANNEL, INDEX) => (
                <li key={INDEX}>
                  <Link
                    to={{}}
                    // to={`/take-a-survey/${params.scorecardId}/${CHANNEL.channelId}/${CHANNEL.tactics[0].tacticId}/${CHANNEL.tactics[0].tacticId}`}
                 >
                    <span>{props.t(CHANNEL.channelName)}</span>
                    {
                      scorecardDetail.scorecardVersion === "Pro" ?
                        <span 
                          style={bubble}
                          onClick={() => {
                            getSpinnerActivate(CHANNEL.channelId)
                          }}  
                        >
                          {
                            spinnerForSecond === CHANNEL.channelId && spinnerForSecond !== null ?
                            <Spinner size="sm" style = {{color:'#145A32', height:'25px' , width:'25px', paddingTop:'5px'}} />
                            :
                            (getChannelScore(CHANNEL)) + "%"
                          }
                        </span>
                      : null  
                    }
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {CHANNEL.tactics
                      .filter(TACTIC => !TACTIC.isDisabled)
                      .map((TACTIC, INDEX_1) => (
                        <li key={INDEX_1}>
                          <Link
                            to={`/take-a-survey/${params.scorecardId}/${CHANNEL.channelId}/${TACTIC.tacticId}/${TACTIC.tacticId}`}
                          >
                            {props.t(TACTIC.tacticName)}
                            {TACTIC.tacticScore ?(
                              <span style={TickMarkbubble}>
                                <i
                                  className="mdi mdi-check-circle"
                                  style={{color:"#8EB820", fontSize:'30px'}}
                                ></i> 
                              </span> 
                            ): null
                         }
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
         
         
        </div>
        {/* <Row>
          <div id="sidebar-menu">
            <div className="navbar-brand-box" style={{ marginTop: 565 }}>
              <button
                type="button"
                onClick={() => {
                  tog_standard()
                }}
                // className="btn btn-primary"
                color="primary"
                data-toggle="modal"
                data-target="#myModal"
                style={{
                  padding: 5,
                  marginLeft: 20,
                  color: "black",
                  border: "1px solid white",
                  backgroundColor: "white",
                  marginBottom: 40,
                  marginTop: 30,
                  fontWeight: 500,
                  borderRadius: "4px",
                  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, .2)",
                }}
              >
                Scoring Summary
              </button>
            </div>
          </div>
        </Row> */}
        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
          scrollable={true}
          style={{width:'550px'}}
        >
          <Row className="m-1">
            <Col md="10" className="pt-2"
              style={{
                fontSize:'24px',
                color:'#0D0F11',
                fontWeight:'normal'
            }}
            >
              <b>Scores Report</b>
            </Col>
            <Col md="2" className="pt-2 text-center">
              <span className="rounded-circle"
                style={{
                  color:'#64748B',
                  marginLeft:'10px',
                  cursor:'pointer'
                }}
                onClick={() => {
                  setmodal_standard(false)
                }}
              >
                <i className="mdi mdi-close-circle font-size-24"></i>
              </span>
            </Col>
          </Row>
          
          <Row className="mt-3 fw-bold font-size-18" style={{color:'#0D0F11'}}>
            <Col md="5" className="mb-1" style={
              scorecardDetail.selectedChannels &&
              [...scorecardDetail.selectedChannels].length >= 4 ?
              {paddingLeft:'15%'} : {textAlign:'center'}
            }>
                Channels
            </Col>
            <Col md="2" className="mb-1 text-center">
            </Col>
            <Col md="5" className="mb-1" style={
              scorecardDetail.selectedChannels &&
              [...scorecardDetail.selectedChannels].length >= 4 ?
              {paddingLeft:'13%'} : {textAlign:'center'}
            }>
                Scores
            </Col>
            <span 
              className="mt-1"
              style={{
                  width:'85%',
                  borderBottom:'1px solid #B8C0CC',
                  marginLeft:'35px'
              }}
            >
            </span>
          </Row>

          <Row className="modal-body" style={
            scorecardDetail.selectedChannels && 
            [...scorecardDetail.selectedChannels].length >= 4 ?
            {marginLeft:'5px'} : {}
          }>
            {
              scorecardDetail.selectedChannels &&
                [...scorecardDetail.selectedChannels].sort((a,b) => 
                    getChannelScore(b) - getChannelScore(a)
                ).map((channel, index) => (
                  <Row key={index} className="mb-3">
                    <Col md="7"
                      style={{
                        color:'#05727B',
                        fontSize:'15px',
                        fontWeight:600,
                        paddingLeft:'35px',
                    }}
                    >
                      {channel.channelName}
                    </Col>
                    <Col md="3">
                    </Col>
                    <Col md="2" style={{marginLeft:'-20px'}}>
                      <Button
                        size="sm"
                        style={{
                          background:'#E5FCFF',
                          width:'40px',
                          border:'#E5FCFF'
                        }}
                      >
                        <span className="text-center fw-bold"
                          style={{color:'#003F44'}}
                        >{getChannelScore(channel)}%</span>
                      </Button>
                    </Col>
                  </Row>
              ))
            }
            
              {scorecardDetail ? (
                <RadarChart
                DATA={
                  scorecardDetail.id && {
                    selectedChannels: [...scorecardDetail.selectedChannels],
                  }
                }
                />
                ) : (
                  <RadarChart
                  DATA={scorecardDetail.selectedChannels && scorecardDetail}
                  />
                  )}
            </Row>
        </Modal>
      </SimpleBar>
    </React.Fragment>
  )
}

TakeASurvey.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(TakeASurvey))
