const { GetScorecardReport, CreateScorecardReport, UpdateScorecardReport } = require("helpers/fakebackend_helper");
const { call, put, takeEvery } = require("redux-saga/effects");
const { getScorecardReportSuccess, getScorecardReportFail, createScorecardReportSuccess, createScorecardReportFail, UpdateScorecardReportSuccess , UpdateScorecardReportFail} = require("./actions");
const { GET_SCORECARD_REPORT, CREATE_SCORECARD_REPORT, UPDATE_SCORECARD_REPORT} = require("./actionTypes");

function* fetchScorecardReport({ scorecardId }) {
    try {
        const response = yield call(GetScorecardReport, scorecardId)
        yield put(getScorecardReportSuccess(response))
    } catch (error) {
        yield put(getScorecardReportFail(error))
    }
}

function* createScorecardReport({ scorecardId, payload: data }) {
    try {
        const response = yield call(CreateScorecardReport, scorecardId, data)
        yield put(createScorecardReportSuccess(response))
    } catch (error) {
        yield put(createScorecardReportFail(error))
    }
}

function* updateScorecardReport({scorecardId, payload: data}){
    try{
        const response = yield call (UpdateScorecardReport , scorecardId, data)
        yield put(UpdateScorecardReportSuccess(response))
    } catch (error) {
        yield put(UpdateScorecardReportFail(error))

    }
}

function* ScorecardReport_Saga() {
    yield takeEvery(GET_SCORECARD_REPORT, fetchScorecardReport)
    yield takeEvery(CREATE_SCORECARD_REPORT, createScorecardReport)
    yield takeEvery(UPDATE_SCORECARD_REPORT, updateScorecardReport)
}

export default ScorecardReport_Saga;