import { call, put, takeEvery } from "redux-saga/effects"

import {
    NEW_DASHBOAD_SCORECOUNT
} from "./actionTypes"

import {NewDashboardScorecountSuccess , NewDashboardScorecountFail} from "./actions"
import {NewDashboardScorecount} from "../../helpers/fakebackend_helper"

function* GetNewDashboardScorecount ({payload:data}) {
    try{
        const response = yield call(NewDashboardScorecount, data)
        yield put (NewDashboardScorecountSuccess(response))
    }
    catch(error){
        yield put (NewDashboardScorecountFail(error))

    }

}
function* GetNewDashboardScorecountSaga () {
    yield takeEvery(NEW_DASHBOAD_SCORECOUNT , GetNewDashboardScorecount)
}

export default GetNewDashboardScorecountSaga