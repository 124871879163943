import {
  SEND_EMAIL_NOTIFICATION,
  SEND_EMAIL_NOTIFICATION_SUCCESS,
  SEND_EMAIL_NOTIFICATION_FAIL,
  CLEAR_SEND_EMAIL_NOTIFICATION,

  SEND_SCORECARD_CREATION_REMINDER,
  SEND_SCORECARD_CREATION_REMINDER_SUCCESS,
  SEND_SCORECARD_CREATION_REMINDER_FAIL,
  CLEAR_SEND_SCORECARD_CREATION_REMINDER
} from "./actionTypes"

export const sendEmailNotificationToAccountOwner = data => ({
  type: SEND_EMAIL_NOTIFICATION,
  payload: data,
})

export const sendEmailNotificationToAccountOwnerSuccess = data => ({
  type: SEND_EMAIL_NOTIFICATION_SUCCESS,
  payload: data,
})

export const sendEmailNotificationToAccountOwnerFail = error => ({
  type: SEND_EMAIL_NOTIFICATION_FAIL,
  payload: error,
})

export const clearSendEmailNotificationToAccountOwner = () => ({
  type: CLEAR_SEND_EMAIL_NOTIFICATION,
})

////////////////////////////////////////////////////////////////////

export const sendScorecardCreationReminder = data => ({
  type: SEND_SCORECARD_CREATION_REMINDER,
  payload: data
})

export const sendScorecardCreationReminderSuccess = data => ({
  type: SEND_SCORECARD_CREATION_REMINDER_SUCCESS,
  payload: data
})

export const sendScorecardCreationReminderFail = error => ({
  type: SEND_SCORECARD_CREATION_REMINDER_FAIL,
  payload: error
})

export const clearSendScorecardCreationReminder = () => ({
  type: CLEAR_SEND_SCORECARD_CREATION_REMINDER
})