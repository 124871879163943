import { color, fontSize } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from "react-html-parser"

import {
  Table,
  Container, Row, Col,
  Alert,
} from "reactstrap";

import "../config/style.config.css"
import { REPORT, REPORT_PAGE } from '../config/style.config';


const DoingWell = (props) => {
  const PROPS = props
  const { Doing_well, ChannelData} = PROPS

  return (
    <>
      <Col xs="12">
        <div style={{...REPORT_PAGE}}>
          <Row className='g-0 m-0 p-0'>
            <Col xs="7" >
              <div style={{
                width: "108%",
                height: REPORT_PAGE.height,
                overflow:'hidden',
                // border: "1px solid grey"
              }} >

                <Row className='g-0 m-0 p-0'>
                  <Col xs="12" >

                    <div style={{
                      // margin: "auto",
                      width: "100%",
                      height: "120px",
                      // padding: "30px 0 25px 30px",
                      padding: "30px 0 10px 40px",
                      position: "relative",
                      textTransform: "uppercase",
                      marginBottom:'30px',
                      // border:'1px solid black',
                    }} >

                      <h3 style={{
                        fontSize: REPORT.FONT_SIZE.XLARGE,
                        fontFamily: REPORT.FONT_FACE.HEADING,
                        fontWeight:600,
                        // paddingBottom: "8px",
                        letterSpacing:'2px',
                        color:'#0D0F11',
                        paddingBottom:'10px',
                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,

                      }}>WHAT YOU ARE
                      <span style={{
                        fontFamily: REPORT.FONT_FACE.HEADING,
                        letterSpacing:'2px',
                      }}><br/>DOING WELL</span>
                      </h3>

                    </div>

                  </Col>
                  <Col xs="12" >
                    <div style={{
                      width: "100%",
                      height: "920px",
                      marginTop:'-15px',
                      // border: "1px solid grey", 
                      fontSize: REPORT.FONT_SIZE.XSMALL,
                      lineHeight:'1.15', 
                      overflow: "hidden",
                      // padding: "4px",
                      padding:'0 0 0 40px',

                      }} 
                      className="--doing-well --container"
                      >
                        <div
                          style={{
                          marginTop: "10px",
                          width: "96%",
                          // padding: "4px",
                          // border: "1px solid grey",  
                          overflow: "hidden",
                          height: "860px",
                          fontSize: REPORT.FONT_SIZE.XSMALL,
                          fontFamily: REPORT.FONT_FACE.PARAGRAPH,
                          color:'#0D0F11'
                          // margin:'10px auto 4px 20px',
                          }}
                          className="SavedDoingWell"
                          >
                          {ReactHtmlParser(Doing_well && Doing_well.contents)}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xs="5" style={{marginTop:'130px'}}>
              <div style={{
                  position: "absolute",
                  top: 0,
                  right: '20px',
                  padding: 6,
                  width:'80px'
                  // marginRight:'18px'
                  }}>
                  
              </div>
              <div
                className='ImageUploadContainer'
                style={{

                  // width: "80%",
                  width: "60%",
                  height: "265px",
                  // border: "1px solid grey",
                  backgroundImage: Doing_well ? "url(" + Doing_well.bgImage[0] + ")" : " "  ,
                  // backgroundColor: "#f0f0f0",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  // margin: '0 40px 0 35px',
                  margin: '0 40px 0 70px',
                  

                }} >
                <div className='container--toggle'
                  style={{
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "rgba(0, 0, 0, .3)"
                    
                  }}
                >
                  <span
                  
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      // border: "1px solid red",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "all 1s ease-in-out"
                    }}
                  >

                    <input
                      type="file"
                      id="upload-button"
                      name="Image_URL"
                      accept="image/*"
                      style={{ display: "none" }}
                      // onChange={handleChange}
                    />
                    
                  </span>
                </div>
              </div>
              <div
                className='ImageUploadContainer'
                style={{

                  // width: "80%",
                  width: "60%",
                  height: "265px",
                  // border: "1px solid grey",
                  backgroundImage: Doing_well ? "url(" + Doing_well.bgImage[1] + ")" : " "  ,
                  // backgroundColor: "#f0f0f0",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  // margin: '20px 40px 0 35px',
                  margin: '20px 40px 0 70px',

                }} >
                <div className='container--toggle'
                  style={{
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "rgba(0, 0, 0, .3)"
                    
                  }}
                >

                  <span
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      // border: "1px solid red",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "all 1s ease-in-out"
                    }}
                    >

                    <input
                      type="file"
                      id="upload-button"
                      name="Image_URL"
                      accept="image/*"
                      style={{ display: "none" }}
                      // onChange={handleChange}
                    />
                    
                  </span>
                </div>
              </div>
              <div
                className='ImageUploadContainer'
                style={{

                  width: "60%",
                  height: "265px",
                  // border: "1px solid grey",
                  backgroundImage: Doing_well ? "url(" + Doing_well.bgImage[2] + ")" : " "  ,
                  // backgroundColor: "#f0f0f0",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  // margin: '20px 40px 0 35px',
                  margin: '20px 40px 0 70px',

                }} >
                <div className='container--toggle'
                  style={{
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "rgba(0, 0, 0, .3)"
                    
                  }}
                >

                  <span
                  
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      // border: "1px solid red",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "all 1s ease-in-out"
                    }}
                  >

                    <input
                      type="file"
                      id="upload-button"
                      name="Image_URL"
                      accept="image/*"
                      style={{ display: "none" }}
                      // onChange={handleChange}
                    />
                    
                  </span>
                </div>
              </div>
            </Col>
            <Col xs="12" style={{
              // border: '1px solid black'
              position: "absolute",
              bottom: 0,
              zIndex: 100,
              }}>
                <div style={{
                  width: '100%',
                  fontSize:REPORT.FONT_SIZE.MEDIUM,
                  fontFamily: REPORT.FONT_FACE.HEADING,
                  letterSpacing:"2px",
                  padding:'5px 5px 5px 40px',
                  backgroundColor: REPORT.COLOR.FOOTER,
                  opacity: 0.9,
                  fontWeight: 600,
                  color: 'white'
                }}>{`${ChannelData && ChannelData.channelName} - What Are You Doing Well`.toUpperCase()}</div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
}

export default DoingWell;