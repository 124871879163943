import { call, put, takeEvery } from "redux-saga/effects"

import {
    createReportTemplateSuccess,
    createReportTemplateFail,
    getReportTemplateSuccess,
    getReportTemplateFail
} from "./actions"

import {
    CREATE_REPORT_TEMPLATE,
    GET_REPORT_TEMPLATE
} from "./actionTypes"

import {
    CreateReportTemplate,
    GetReportTemplate
} from "../../helpers/fakebackend_helper"


function* onCreateReportTemplate({ payload: data }) {

    try {
        const response = yield call(CreateReportTemplate, data)
        yield put(createReportTemplateSuccess(response))
    } catch (error) {
        yield put(createReportTemplateFail(error))
    }

}

function* onGetReportTemplate() {

    try {
        const response = yield call(GetReportTemplate)
        yield put(getReportTemplateSuccess(response))
    } catch (error) {
        yield put(getReportTemplateFail(error))
    }

}

function* createReportTemplateSaga() {
    yield takeEvery(CREATE_REPORT_TEMPLATE, onCreateReportTemplate)
    yield takeEvery(GET_REPORT_TEMPLATE, onGetReportTemplate)

}

export default createReportTemplateSaga