import NotFound from "components/Common/NotFound/NotFound.component"
import { ACCOUNT_OWNER, NETWORK_ERROR } from "config/AlertMessage.config";
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams, Link } from "react-router-dom"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Spinner,
} from "reactstrap"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getTransaction, toggleBuyCreditMenu } from "store/actions"

import "./transactionTable.scss"
import SweetAlert from "react-bootstrap-sweetalert"

const Transactions = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [isDataAvail, setIsDataAvail] = useState(true)
  const [paginationReset, setPaginationReset] = useState(false)

  const [showTransaction ,setShowTransaction] = useState(false)
  // useEffect(() => {
  //   var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)
  //   // if (scorecardsList[0]) {
  //   //   var search_bar = document.querySelector("#search-bar-0")
  //   //   search_bar.setAttribute("autoComplete", "off")
  //   //   clearTimeout(WaitForData)
  //   // }
  // }, [])

  const { transaction } = useSelector(state => ({
    transaction: state.TransactionReducer.transaction,
  }))

  useEffect(() => {
    var accountOwnerId = JSON.parse(localStorage.getItem("authUser")).uid
    !transaction[0] && dispatch(getTransaction(accountOwnerId))
  }, [dispatch])

  useEffect(() => {
    // var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)
    if (transaction.status === 400) {
      setIsDataAvail(false)
    }

    if (transaction[0]) {
      var search_bar = document.querySelector("#search-bar-0")
      search_bar.setAttribute("autoComplete", "off")
      // clearTimeout(WaitForData)
      search_bar.addEventListener("focus", () => setPaginationReset(true))

    }

  }, [transaction])

  // console.log(showTransaction)

  

  const columns = [
    // {
    //   dataField: 'status',
    //   text: '',
    //   sort: false,

    // },
    {
      dataField: "name",
      text: "Product Name",
      sort: true,
    },
    {
      dataField: "credit",
      text: "Credit",
      sort: true,
    },
    {
      dataField:"transactionDate",
      text:"Date",
      sort:true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to="#"  style={{color:'#495057', textAlign: "center", width: "140px" }}>
          {row.transactionDate}
        </Link>
      )  
    },
    {
      dataField: "price",
      text: "Per Unit Amount",
      sort: true,
    },
    {
      dataField: "total",
      text: "Total Amount",
      sort: true,
    },
    {
      dataField:"paymentStatus",
      text:"Payment Status",
      sort:true
    },
   {
     dataField:"paymentMethod",
     text:"Payment Method",
     sort:true
   }
  ]

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    // page:2,
    // sizePerPage: 10,
    onPageChange: () => setPaginationReset(false),
    // totalSize: AccountData.length, // replace later with size(customers),
    custom: true,
  }

  // const GetPrice = item => {
  //   var unitPrice = item.prices.unit_amount
  //   var transformCredits = item.prices.transform_quantity
  //     ? item.prices.transform_quantity.divide_by
  //     : 1

  //   return unitPrice / 100 / transformCredits
  // }
  const TransactionData = !transaction[0]? [] : transaction.map((item, index) => {
    return {
      id: index,
      name: item.productName,
      credit:item.creditPurchased,
      transactionDate: new Date(item.transactionDate._seconds * 1000).toDateString().slice(3),
      price: "$ " + item.creditPrice,
      total: "$ " + item.totalAmount, 
      paymentStatus: item.paymentStatus,
      paymentMethod:item.paymentMethod[0]
     
    }
  })
 


  
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: (TransactionData).length },
    ]

  
  

  const { SearchBar } = Search
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Transactions</title>
        </MetaTags>
        <div className="container-fluid">
          <div
            style={{
              width: "100%",
              textAlign: "center",
              height: "400px",
            }}
          >

{
            !transaction[0] ?       //  && !accountDetails[0]
              <div style={
                {
                  width: "100%",
                  textAlign: "center",
                  height: "400px"
                }
              } >
                {
                  !isDataAvail ?
                    <>
                      {transaction.status === 400 ?
                        <NotFound
                          Message={ACCOUNT_OWNER.EMPTY_TRANSACTIONS}
                          Icon={() => (
                            <i className="fas fa-credit-card"
                              style={{ borderBottom: "1px solid #000" }}
                            ></i>
                          )}
                          Url="/dashboard"
                          IsButton
                          btnText="Buy Credit"
                          Action="Buy Credit"
                        />
                        :
                        <NotFound Message={NETWORK_ERROR} Icon={() => (
                          <i className="bx bx-error"
                            style={{ borderBottom: "1px solid #000", fontSize: "40px" }}
                          ></i>
                        )}
                          IsButton
                          btnText="Reload"
                          Reload
                        />
                      }
                    </>
                    :
                    <Spinner
                      style={{
                        width: "60px", height: "60px",
                        margin: "calc(200px - 30px)",
                        color:'#05727B'
                      }}
                      role="grid"
                      type="border"
                      // color="primary"
                    />
                }
              </div>
              :
              <>
                <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={
                        paginationReset
                          ? paginationFactory({
                            ...pageOptions,
                            page: 1,
                          })
                          : paginationFactory(pageOptions)
                      }
                      keyField="id"
                      columns={columns}
                      data={TransactionData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={TransactionData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col md="9">
                                  <h2 className="fw-bold" style={{color: "#0D0F11", float:'left' }}>
                                    Transaction
                                  </h2>
                                </Col>
                                <Col md="3">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        onClear={() => setPaginationReset(false)}
                                      // ref={search_ref}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      // key={"id"}
                                      // rowEvents={rowEvents}
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
  
                              <Row className="align-items-md-center mt-30">
                                <Col
                                  className="inner-custom-pagination d-flex"
                                  style={{ marginTop: 10 }}
                                >
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row> 
              </>
            
              

            }
             
              
               
            

          
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Transactions
