import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import React, { useEffect, useState, useCallback, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CropEasy from "../../Crop/CropEasy"
import { getOrientation } from "get-orientation/browser"
import getCroppedImg from "pages/Crop/utils/CropImage"
import parse from "html-react-parser"
import "../configCk/ck.style.css"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from "reactstrap"

import * as Yup from "yup"
import { setIn, useFormik } from "formik"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory } from "react-router-dom"

//Upload Image

import firebase from "firebase/compat"
import "firebase/storage"

// import { getDemoData } from "../../store/actions";
//Import Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import FormValidation from "../Form/index"

import {
  getChannelData,
  AddNewChannel,
  ClearAddChannel,
  handleUnsavedChanges,
  handleChangesHappend,
  changeChannelData,
} from "../../../store/actions"

import { getDownloadURL } from "firebase/storage"
import ImagePreview from "../../../components/Common/ImagePreview/ImagePreview.component"
import ImageThumbnail from "components/Common/ImageThumbnail/ImageThumbnail.component"
import { createThumbnail } from "commonFunctions/CreateThumbnails/createThumbnail"
import { CONTENT_EDITOR } from "config/AlertMessage.config"
import { CLOUD_STORAGE } from "config/cloudStorage.config"
import ChannelDescriptionFirstPage from "../ChannelDescriptionFirstPage/component/channelDescriptionFirstPage.component"
import { stubTrue } from "lodash"

const DashboardContentEditor = props => {
  var parameter = props

  const history = useHistory()

  const hiddenFile = useRef()
  const { data, setImageUrl, imageName } = parameter
  const [HeightWidth, setHeightWidth] = useState({})
  //Additional States for handleing custom requests:
  const [isChannelAdded, setIsChannelAdded] = useState(false)
  const [isUnmounted, setIsUnmounted] = useState(true)
  const [rows1, setrows1] = useState([{ id: Math.random() }])
  const [item, setItem] = useState("")
  const [input, setInput] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)

  const [file, setFile] = useState(null)
  const [image_url, setImage_Url] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
  const [isImageThere, setImageThere] = useState(false)
  const [previewImage, setPreviewImage] = useState({ visible: false })
  const [iconThumbnail, setIconThumbnail] = useState(null)
  const [icon, setIcon] = useState([])
  const [image_storagePath, setStoragePath] = useState()
  const [thumbnail_storagePath, setThumbnailPath] = useState()
  const [HandleChannelState, setHandleChannelState] = useState({})
  const [showPreview, setShowPreview] = useState(false)

  const uploadReset = useRef()

  //const fileInput = useRef(null)

  const keyObservationManager = () => {
    var getKeyObservations = document.querySelectorAll(".ck-content")
    var myKeyObservationsList = [...getKeyObservations]

    var myKeyObservations = myKeyObservationsList.map(obs => obs.innerHTML)
    myKeyObservations.splice(0, 1)
    // console.log("myKeyObservations", myKeyObservations)
    return myKeyObservations
  }

  const keyObservationResetHandler = () => {
    var getKeyObservations = document.querySelectorAll(".ck-content")
    setrows1([{ id: Math.random(), content: "" }])

    getKeyObservations[1].childNodes[0].innerHTML = ""

    // uploadReset.current.value = ""
  }

  // console.log("ROWS...", rows1)
  

  const handleChange = e => {
    const file = e.target.files[0]

    if (file) {
      var u = URL.createObjectURL(file)
      var img = new Image()
      img.onload = function () {
        var IMAGE_HEIGHT = img.height
        var IMAGE_WIDTH = img.width
        // HEIGHT > WIDTH ? console.log('vertical Image') : console.log('horizontal image')

        // console.log("Width: "+ IMAGE_WIDTH + " " + "Height: " + IMAGE_HEIGHT);
        // console.log(file)

        setHeightWidth({
          myheight: IMAGE_HEIGHT,
          mywidth: IMAGE_WIDTH,
        })
      }
      img.src = u

      setFile(file)
      setImage_Url(u)
      setOpenCrop(true)
      setImageThere(false)
      dispatch(handleChangesHappend(true))
      e.target.value = ""
    }
  }

  //   console.log(image_url)

  function handleAddRowNested() {
    if (rows1.length < 10) {
      // const modifiedRows = [...rows1]
      // modifiedRows.push({ id: modifiedRows.length + 1 })
      setrows1(e => [...e, { id: Math.random(), content: "" }])
    }
  }

  function handleRemoveRow(id) {
    // console.log()
    // console.log("rows1 before...", rows1)

    // console.log("deleting id...", id)
    if (rows1.length > 1) {
      // var modifiedRows = [...rows1]
      // modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      // setIcon(icon.filter((icon, i) => i !== id - 1 ))
      // setIconThumbnail(iconThumbnail.filter((icon, i) => i !== id - 1 ))
      // keyObservationManager()

      setrows1(rows1.filter(row => row.id !== id))

      // console.log("rows1 after...", rows1)
    }
  }
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsChannelAdded(false)
  //     dispatch(ClearAddChannel())
  //     validationForAddChannel.values.channelName = ""

  //     validationForAddChannel.values.channelDescriptionTitle = ""
  //     validationForAddChannel.values.channelQuote = ""

  //   }, 6000)
  // }, [isChannelAdded])

  const dispatch = useDispatch()
  //get channel and tactics:
  const { channelData } = useSelector(state => ({
    channelData: state.GetChannelData.channelData,
  }))
  const { addChannelData, isDataEdited, isThereChanges } = useSelector(
    state => ({
      addChannelData: state.AddChannelReducer.addChannelData,
      isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
      isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    })
  )

  useEffect(() => {
    if (isUnmounted) {
      dispatch(getChannelData())
    }

    // console.log(dispatch(getChannelData()))
    return () => {
      setIsUnmounted(false)
    }
  }, [dispatch])

  //  console.log("Channel Data...", channelData)

  const checkChannelUniqueName = () => {
    var isNameAvailable = channelData.map(channel => channel.channelName)
    var isIndexAvailable = isNameAvailable.indexOf(
      validationForAddChannel.values.channelName
    )
    // console.log("isNameAvail...",isNameAvailable)
    if (
      isIndexAvailable >= 0 ||
      validationForAddChannel.values.channelName === ""
    ) {
      return true
    } else {
      return false
    }
    // return IsNameAvailable.indexOf(validation.values.scorecardName)
  }
  const handleCancel = () => {
    validationForAddChannel.resetForm()

    setItem("")
    keyObservationResetHandler()
    setIconThumbnail(null)
    setImageThere(false)
  }

  // if(handleCancel){
  //   dispatch(handleChangesHappend(false))
  // }

  // useEffect(() => {
  //   if(handleCancel){
  //     dispatch(handleUnsavedChanges(false))
  //   }
  // }, [handleCancel])
  // useEffect(() => {
  //   if (data.file) {

  //     createThumbnail("/contentEditor/createChannel/thumbnails", imageName , data.file, setIconThumbnail, false, "PNG")
  //   }
  // }, [data.file])

  // useEffect(() => {
  //   if (image) {
  //     upload()
  //   }
  // }, [image])

  const validationForAddChannel = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      channelName: "",
      channelDescriptionTitle: "",
      channelDescription: "",
      channelQuote: "",
    },
    validationSchema: Yup.object({
      channelName: Yup.string().required("Please enter channel name"),
      //   channelDescription: Yup.string().required("Please enter description"),
      channelDescriptionTitle: Yup.string().required(
        "Please enter description title"
      ),
      channelQuote: Yup.string().required("Please enter channel quote"),
    }),

    onSubmit: values => {
      var date = new Date()
      var user = JSON.parse(localStorage.getItem("authUser"))
      // console.log("values", {
      //   channelName: values.channelName,
      //   channelDescriptionTitle: values.channelDescriptionTitle,
      //   channelDescription: values.channelDescription,
      //   channelQuote: values.channelQuote,
      //   keyObservations: values.keyObservations,
      //   createdOn: date,
      //   lastUpdatedOn: date,
      // })

      var myChannelData = {
        channelName: values.channelName,
        channelDescriptionTitle: values.channelDescriptionTitle,
        channelDescription: item,
        channelQuote: values.channelQuote,
        Image_URL: image_url,
        createdOn: date,
        createdBy: user.email,
        creatorId: user.uid,
        lastUpdatedOn: date,
        isDisabled: true,
        keyObservations: keyObservationManager(),
      }

      // console.log(myChannelData)
      if (!checkChannelUniqueName()) {
        setHandleChannelState({
          channelName: values.channelName,
          tactics: []
        })
        setIsChannelAdded(true)
        dispatch(AddNewChannel(myChannelData))
        // console.log("Channel Added")
      }
      dispatch(handleChangesHappend(false))

      // values.Image_URL = setImage_Url(null)
      // values.channelName = ""
      // values.channelDescription = setItem("")
      // values.channelDescriptionTitle = ""
      // values.channelQuote = ""
      // // values.keyObservations = setrows1({id:1})
      // keyObservationResetHandler()
      // dispatch(handleChangesHappend(false))
    },
    onReset: () => {
      dispatch(handleChangesHappend(false))

    },
  })

  // console.log(isThereChanges)
  //validation for tactics
  // const validation = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
  //     tacticName: "",
  //     channelSelect: "",
  //   },
  //   validationSchema: Yup.object({
  //     channelName: Yup.string().required("Please enter Channel Name"),
  //     tacticName: Yup.string().required("Please Enter Tactic Name"),
  //   }),
  //   onSubmit: values => {
  //     // console.log("values", values)
  //   },
  // })
  //   const preview =() =>{
  // frame.src=URL.createObjectURL(event.target.files[0]);
  //   }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Create Channel</title>
        </MetaTags>
        {/*  */}
        <Container fluid={true}>
          {/* <Breadcrumbs title="Content Editor" breadcrumbItem="Tactics Details" /> */}
          {/* <FormValidation /> */}
          <Row>
            <Col xl="12" style={{ margin: "auto" }}>
              <Card>
                {previewImage.visible && (
                  <ImagePreview
                    ImageUrl={image_url}
                    TogglePreview={() => {
                      setPreviewImage(e => ({
                        ...e,
                        visible: false,
                      }))
                    }}
                  />
                )}
                <CardBody>
                  {addChannelData.status === 200 && (
                    <SweetAlert
                      // title="Channel created successfully"
                      success
                      style={{
                        height: "255px",
                        width: "368px",
                      }}
                      confirmBtnBsStyle="primary"
                      onConfirm={() => {
                        // console.log(addChannelData)
                        if (channelData[0]) {
                          dispatch(changeChannelData([
                            ...channelData,
                            {
                              ...HandleChannelState,
                              channelId: addChannelData.id
                            }
                          ]))
                        } else {
                          dispatch(changeChannelData([
                            {
                              ...HandleChannelState,
                              channelId: addChannelData.id
                            }
                          ]))
                        }
                        handleCancel()
                        // console.log([
                        //   ...channelData,
                        //   {
                        //     ...HandleChannelState,
                        //     channelId: addChannelData.id
                        //   }
                        // ])
                        // validation.values.Image_URL = setImage_Url(null)
                        // validation.values.channelName = ""
                        // validation.values.channelDescription = setItem("")
                        // validation.values.channelDescriptionTitle = ""
                        // validation.values.channelQuote = ""
                        // values.keyObservations = setrows1({id:1})
                        // keyObservationResetHandler()
                        setsuccess_msg(false)
                        setIsChannelAdded(false)

                        dispatch(ClearAddChannel())
                        // dispatch(handleChangesHappend(false))
                        // history.push("/")
                      }}
                    >
                      {CONTENT_EDITOR.CREATE_CHANNEL.SUCCESS}
                    </SweetAlert>
                  )}
                  {addChannelData.status === 403 && (
                    <SweetAlert
                      // title="Failed to create channel"
                      error
                      confirmBtnBsStyle="primary"
                      style={{
                        height: "255px",
                        width: "368px",
                      }}
                      onConfirm={() => {
                        setsuccess_msg(false)
                        setIsChannelAdded(false)
                        // keyObservationResetHandler()
                        dispatch(ClearAddChannel())
                      }}
                    >
                      {CONTENT_EDITOR.CREATE_CHANNEL.FAILED}
                    </SweetAlert>
                  )}
                  <Form
                    className="needs-validation outer-repeater"
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validationForAddChannel.handleSubmit()
                      // validationForAddChannel.resetForm()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Channel Name
                          </Label>
                          <Input
                            name="channelName"
                            placeholder="channel name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={e => {
                              validationForAddChannel.handleChange(e)
                              dispatch(handleChangesHappend(true))
                            }}
                            onBlur={validationForAddChannel.handleBlur}
                            value={
                              validationForAddChannel.values.channelName || ""
                            }
                            invalid={
                              validationForAddChannel.touched.channelName &&
                                checkChannelUniqueName()
                                ? true
                                : false
                            }
                          />
                          {validationForAddChannel.touched.channelName &&
                            checkChannelUniqueName() ? (
                            <FormFeedback type="invalid">
                              {checkChannelUniqueName() &&
                                validationForAddChannel.values.channelName !== ""
                                ? "Channel name must be unique"
                                : validationForAddChannel.errors.channelName}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="8">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Channel Description Title
                          </Label>
                          <Input
                            name="channelDescriptionTitle"
                            placeholder="channel description title"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={e => {
                              validationForAddChannel.handleChange(e)
                              dispatch(handleChangesHappend(true))
                            }}
                            onBlur={validationForAddChannel.handleBlur}
                            value={
                              validationForAddChannel.values
                                .channelDescriptionTitle || ""
                            }
                            invalid={
                              validationForAddChannel.touched
                                .channelDescriptionTitle &&
                                validationForAddChannel.errors
                                  .channelDescriptionTitle
                                ? true
                                : false
                            }
                          />
                          {validationForAddChannel.touched
                            .channelDescriptionTitle &&
                            validationForAddChannel.errors
                              .channelDescriptionTitle ? (
                            <FormFeedback type="invalid">
                              {
                                validationForAddChannel.errors
                                  .channelDescriptionTitle
                              }
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Channel Quote
                          </Label>
                          <Input
                            name="channelQuote"
                            placeholder="channel quote"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={e => {
                              validationForAddChannel.handleChange(e)
                              dispatch(handleChangesHappend(true))
                            }}
                            onBlur={validationForAddChannel.handleBlur}
                            value={
                              validationForAddChannel.values.channelQuote || ""
                            }
                            invalid={
                              validationForAddChannel.touched.channelQuote &&
                                validationForAddChannel.errors.channelQuote
                                ? true
                                : false
                            }
                          />
                          {validationForAddChannel.touched.channelQuote &&
                            validationForAddChannel.errors.channelQuote ? (
                            <FormFeedback type="invalid">
                              {validationForAddChannel.errors.channelQuote}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <label htmlFor="input">Upload Image</label>
                        <Row>
                          <Col md="8">
                            <input
                              id="input"
                              type="file"
                              name="Image_URL"
                              accept="image/*"
                              className="form-control form-control-md inputFile"
                              style={{ display: "none" }}
                              onChange={handleChange}
                            />
                            <Button
                              color="primary"
                              onClick={() => {
                                var inputFile = document.querySelector("#input")
                                inputFile.click()
                              }}
                              style={{
                                position: "absolute",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <i
                                className="mdi mdi-image"
                                style={{ marginRight: "5px", fontSize: "15px" }}
                              ></i>
                              Choose Image
                            </Button>{" "}
                            <br />
                          </Col>



                          <Col md="2">    
                            {isImageThere && !iconThumbnail ? (
                              <Spinner />
                            ) : (
                              <ImageThumbnail
                                onClick={() => {
                                  setPreviewImage({
                                    visible: true,
                                  })
                                  // setImageThere(false)
                                }}
                                imgeUrl={iconThumbnail}
                              />
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2"></Col>
                      <Col md="8">
                        {openCrop && (
                          <CropEasy
                            image_url={image_url}
                            imageName={file.name}
                            image_storagePath={CLOUD_STORAGE.CHANNEL.IMAGE}
                            thumbnail_storagePath={
                              CLOUD_STORAGE.CHANNEL.THUMBNAIL
                            }
                            setOpenCrop={setOpenCrop}
                            setImageUrl={setImage_Url}
                            setFile={setFile}
                            setIconThumbnail={setIconThumbnail}
                            ImageHeightWidth={HeightWidth}
                            setImageThere={setImageThere}
                            ImageType="CHANNEL"
                          />
                        )}
                      </Col>
                    </Row>
                   

                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Channel Description
                          </Label>
                          {/* <Input
                                                    name="channelDescription"
                                                    placeholder="Channel Description"
                                                    type="textarea"
                                                    className="form-control"
                                                    id="validationCustom01"
                                                    onChange={validationForAddChannel.handleChange}
                                                    onBlur={validationForAddChannel.handleBlur}
                                                    value={validationForAddChannel.values.channelDescription || ""}
                                                    invalid={
                                                    validationForAddChannel.touched.channelDescription && validationForAddChannel.errors.channelDescription ? true : false
                                                    }
                                                    />
                                                    {validationForAddChannel.touched.channelDescription && validationForAddChannel.errors.channelDescription ? (
                                                    <FormFeedback type="invalid">{validationForAddChannel.errors.channelDescription}</FormFeedback>
                                                    ) : null} */}

                          {/* <Form method="post"> */}
                          <CKEditor
                            editor={ClassicEditor}
                            // id = "validationCustom01"
                            id="content"
                            name="channelDescription"
                            data={item}
                            config={{
                              removePlugins: [
                                "EasyImage",
                                "ImageUpload",
                                "MediaEmbed",
                                "Essentials",
                                "Table",
                                "Table Toolbar",
                                "CkFinderUploadAdapter",
                                "CKFinder",
                                "Autoformat",
                                "Link",
                                "BlockQuote",
                              ],
                            }}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              //console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, data) => {
                              setItem(data.getData())
                              if (data.getData() !== "")
                                dispatch(handleChangesHappend(true))
                            }}

                          //onBlur = {validationForAddChannel.handleBlur}
                          //    invalid = {validationForAddChannel.data.channelDescription && validationForAddChannel.errors.channelDescription ? true : false}
                          />
                          {showPreview && (
                            <Container>
                              <Row>
                                <Card>
                                  <CardBody>
                                    <ChannelDescriptionFirstPage
                                      DESCRIPTION={
                                        item || "Channel Description here"
                                      }
                                      QUOTE={
                                        validationForAddChannel.values
                                          .channelQuote || "Channel Quote here"
                                      }
                                      IMAGEPATH={image_url || "Image here"}
                                      CHANNELNAME={
                                        validationForAddChannel.values
                                          .channelName || "Channel Name here"
                                      }
                                      CHANNELTITLE={
                                        validationForAddChannel.values
                                          .channelDescriptionTitle ||
                                        "Channel Title here"
                                      }
                                    />
                                  </CardBody>
                                </Card>
                              </Row>
                            </Container>
                          )}

                          <br></br>
                          <Row>
                            <Col md="10"></Col>
                            <Col md="2">
                              <Button
                                block
                                color="primary"
                                className="mt-1"
                                onClick={() => {
                                  setShowPreview(e => !e)
                                }}
                              >
                                {showPreview ? "Back" : "Preview"}
                              </Button>
                            </Col>
                          </Row>

                          {/* {validationForAddChannel.touched.channelDescription && validationForAddChannel.errors.channelDescription ? (
                                                    <FormFeedback type="invalid">{validationForAddChannel.errors.channelDescription}</FormFeedback>
                                                    ) : null}  */}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        {/* repeater */}
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="inner-repeater mb-4">
                              <Label>Key Observations </Label>
                              <table
                                style={{ width: "100%" }}
                                className="keyObservation"
                              >
                                <tbody>
                                  {rows1.map((formRow, key) => (
                                    <tr key={key}>
                                      <td>
                                        <Row className="mb-2">
                                          <Col md="11" style={{cursor:'text'}}>
                                            {/* <Input
                                                                                    // id = {`keyObservation_${++key}`}
                                                                                    type="textarea"
                                                                                    className="inner form-control"
                                                                                    placeholder = {`key observation - ${++key}`}
                                                                                    maxLength = "250"
                                                                                    // onChange={ (e) => {
                                                                                        // var allKeys = document.querySelectorAll(".keyObservation textarea")
                                                                                        // console.log(allKeys)
                                                                                        // keyObservationManager()
                                                                                    // } }
                                                                                    /> */}
                                            <CKEditor
                                              //  placeholder = {`key obervation - ${++key}`}
                                              id={formRow.id}
                                              editor={ClassicEditor}
                                              data={formRow.content}
                                              config={{
                                                removePlugins: [
                                                  "EasyImage",
                                                  "ImageUpload",
                                                  "MediaEmbed",
                                                  "Essentials",
                                                  "Table",
                                                  "Table Toolbar",
                                                  "CkFinderUploadAdapter",
                                                  "CKFinder",
                                                  "Autoformat",
                                                  "Link",
                                                  "BlockQuote",
                                                ],
                                                uiColor: "#9AB8F3",
                                              }}
                                              onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                //console.log('Editor is ready to use!', editor);
                                              }}
                                              onChange={(e, data) => {
                                                // const data = editor.getData();
                                                // validationForAddChannel.handleChange
                                                // const data1 = data.getData();
                                                // console.log(data1);
                                                // var allkeys = document.querySelectorAll(".ck-content")
                                                // console.log(allkeys[1].innerHTML)
                                                setrows1(e =>
                                                  e.map(rw => {
                                                    if (rw.id === formRow.id)
                                                      return {
                                                        id: formRow.id,
                                                        content: data.getData()
                                                          ? data.getData()
                                                          : formRow.content,
                                                      }
                                                    else return rw
                                                  })
                                                )

                                                keyObservationManager()
                                                if (data.getData() !== "") {
                                                  dispatch(
                                                    handleChangesHappend(true)
                                                  )

                                                }

                                              }}
                                            />
                                          </Col>
                                          <Col md="1">
                                            {/* <Button
                                              color="primary"
                                              className="btn-block inner"
                                              id="unknown-btn"
                                              style={{ width: "100%" }}
                                              disabled={
                                                formRow.id > 1 ? false : true
                                              }
                                              onClick={e => {
                                                handleRemoveRow(formRow.id)
                                              }}
                                            >
                                              {" "}
                                              Delete{" "}
                                            </Button> */}
                                            {rows1.length > 1 ? (
                                              <i
                                                className="mdi mdi-trash-can d-block font-size-24"
                                                style={{
                                                  color: "#FF6060",
                                                }}
                                                onClick={e => {
                                                  handleRemoveRow(formRow.id)
                                                  dispatch(
                                                    handleChangesHappend(true)
                                                  )
                                                }}
                                              ></i>
                                            ) : (
                                              true
                                            )}
                                          </Col>
                                        </Row>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <Button
                                disabled={rows1.length < 10 ? false : true}
                                onClick={() => {
                                  handleAddRowNested()
                                  dispatch(handleChangesHappend(true))
                                }}
                                color="primary"
                                className="mt-1"
                              >
                                Add New
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col md="8"></Col>
                      <Col md="2">
                        <FormGroup className="mb-3">
                          <Button
                            color="danger"
                            onClick={e => {
                              //validationForAddChannel.resetForm(e)

                              handleCancel()
                              // dispatch(handleChangesHappend(false))
                            }}
                            block
                          >
                            Cancel
                          </Button>
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className="mb-3">
                          <Button
                            color="primary"
                            type="submit"
                            disabled={isChannelAdded}
                            block
                          >
                            {isChannelAdded ? "" : "Create"}
                            {isChannelAdded ? (
                              <Spinner type="border" size="sm" />
                            ) : null}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export default DashboardContentEditor
