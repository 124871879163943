import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
  Modal,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import RadarChart from "pages/AccountUser/chart/chart"

import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"

import {
  getScorecardDetail,
  createScorecardReport,
  clearCreateScorecardReport,
  changeScorecardDetail,
  updateScorecardDetail,
  updateScorecardDetailClear,
  getScorecardDetailClear,
} from "store/actions"
import { getChannelScore } from "commonFunctions/GetChannelScore/getChannelScore"

const scoringSummary = () => {
  const [channelAddedToList, setChannelAddedToList] = useState([])
  const [channelRemovedFromList, setChannelRemovedFromList] = useState([])
  const [channelListUpdate, setIsChannelListUpdate] = useState(false)
  const [isReportCreated, setIsReportCreated] = useState(false)
  const [is_user_exist, set_is_user_exist] = useState(false)
  const [is_report_create, set_is_report_create] = useState(false)

  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const params = useParams()
  const history = useHistory()

  const style_1 = {
    borderRadius: "2px",
    boxShadow: "0 0.75rem 1.5rem rgb(18 38 63 / 3%)",
    overflow: "hidden",
    margin: "10px 0px",
  }

  const dispatch = useDispatch()
  const {
    scorecardDetail,
    scorecardReportCreateResponse,
    updatedScorecardDetail
  } = useSelector(
    state => ({
      scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
      scorecardReportCreateResponse:
        state.ScorecardReport_Reducer.scorecardReportCreateResponse,
      updatedScorecardDetail:
        state.GetScorecardsListReducer.updatedScorecardDetail,
    })
  )

  useEffect(() => {
    dispatch(getScorecardDetail(params.scorecardId))

    return () => {
      dispatch(getScorecardDetailClear())
    }
  }, [dispatch])

  const channelClassifier = (data, level) => {
    if (scorecardDetail.selectedChannels) {
      var channelName =
        (channelListUpdate &&
          channelAddedToList.map(channel => {
            var channelScore = getChannelScore(channel)

            if (channelScore <= data && channelScore > data - level) {
              return channel.channelName
            }
          })) ||
        scorecardDetail.selectedChannels.map(channel => {
          var channelScore = getChannelScore(channel)

          if (channelScore <= data && channelScore > data - level) {
            return channel.channelName
          }
        })
    }
    return (
      (channelName && channelName.filter(channel => channel !== undefined)) ||
      []
    )
  }

  // console.log("Here...")

  const addChannelToList = data => {
    var channelList =
      scorecardDetail.selectedChannels &&
      scorecardDetail.selectedChannels.filter(
        channel => channel.channelId === data
      )

    setChannelAddedToList(e => [...e, ...channelList])

    if (channelRemovedFromList[0])
      setChannelRemovedFromList(
        channelRemovedFromList.filter(channel => channel.channelId !== data)
      )
  }

  const removeChannelFromList = data => {
    setIsChannelListUpdate(true)
    var channelList =
      scorecardDetail.selectedChannels &&
      scorecardDetail.selectedChannels.filter(
        channel => channel.channelId === data
      )

    setChannelRemovedFromList(e => [...e, ...channelList])

    if (channelAddedToList[0])
      setChannelAddedToList(
        channelAddedToList.filter(channel => channel.channelId !== data)
      )
    else
      setChannelAddedToList(
        scorecardDetail.selectedChannels &&
        scorecardDetail.selectedChannels.filter(
          channel => channel.channelId !== data
        )
      )
  }

  const handleCreateReport = () => {

    if (channelListUpdate) {
      var scorecardReportData = {
        ...scorecardDetail,
        selectedChannels: [...channelAddedToList],

        isReportCreated: true,
        status: "Report Only",
      }


      window.localStorage.setItem("selectedChannelsForEditReport",
        JSON.stringify(
          channelAddedToList
            .map((channel) => (
              {
                channelId: channel.channelId,
                channelName: channel.channelName,
                tactics: channel.tactics.map(tactic => ({
                  tacticName: tactic.tacticName,
                  tacticId: tactic.tacticId
                }))
              }
            ))
        ))


      dispatch(createScorecardReport(params.scorecardId, scorecardReportData))
      dispatch(updateScorecardDetail(params.scorecardId, {
        ...scorecardReportData,
        activeUser: {
          userName: JSON.parse(localStorage.getItem("authUser")).displayName,
          userId: JSON.parse(localStorage.getItem("authUser")).uid,
          isActive: true
        },
        lastUpdatedOn: {
          _miliseconds: 0,
          _seconds: new Date().getTime() / 1000,
        }
      }))
    } else {

      window.localStorage.setItem("selectedChannelsForEditReport",
        JSON.stringify(scorecardDetail.selectedChannels
          .map((channel) => (
            {
              channelId: channel.channelId,
              channelName: channel.channelName,
              tactics: channel.tactics.map(tactic => ({
                tacticName: tactic.tacticName,
                tacticId: tactic.tacticId
              }))
            }
          ))))

      dispatch(
        createScorecardReport(params.scorecardId, {
          ...scorecardDetail,
          isReportCreated: true,
          status: "Report Only",
        })
      )

      dispatch(
        updateScorecardDetail(params.scorecardId, {
          ...scorecardDetail,
          isReportCreated: true,
          status: "Report Only",
          lastUpdatedOn: {
            _miliseconds: 0,
            _seconds: new Date().getTime() / 1000,
          },
          activeUser: {
            userName: JSON.parse(localStorage.getItem("authUser")).displayName,
            userId: JSON.parse(localStorage.getItem("authUser")).uid,
            isActive: true
          }
        })
      )
    }
  }

  useEffect(() => {
    if (is_report_create && !scorecardDetail.activeUser) {
      // dispatch(
      //   updateScorecardDetail(
      //     params.scorecardId,
      //     {
      //       activeUser: {
      //         userName: JSON.parse(
      //           localStorage.getItem(
      //             "authUser"
      //           )
      //         ).displayName,
      //         userId: JSON.parse(
      //           localStorage.getItem(
      //             "authUser"
      //           )
      //         ).uid,
      //         isActive: true,
      //       },
      //     }
      //   )
      // )

      handleCreateReport()

    } else if (is_report_create && scorecardDetail.activeUser) {
      set_is_report_create(false)
      // alert("user exist.")
      set_is_user_exist(true)
    }
  }, [scorecardDetail])

  useEffect(() => {
    if (isReportCreated && updatedScorecardDetail.status === 200) {
      history.push(
        `/edit/scorecard-report/${params.scorecardId}/cover-page`
      )
    }
    //   // if (is_take_a_survey && updatedScorecardDetail.status === 200) {

    //   //   var channelId =
    //   //     scorecardDetail
    //   //       .selectedChannels[0].channelId
    //   //   var tacticId =
    //   //     scorecardDetail
    //   //       .selectedChannels[0].tactics[0]
    //   //       .tacticId
    //   //   history.push(
    //   //     `/take-a-survey/${params.scorecardId}/${channelId}/${tacticId}/${tacticId}`
    //   //   )
    //   // }
  }, [updatedScorecardDetail])

  return (
    <>
      <div className="page-content" style={{ marginBottom: 5 }}>
        <MetaTags>
          <title> Mambo -Scoring Summary</title>
        </MetaTags>
        <Container fluid={true}>
          {scorecardReportCreateResponse.status === 201 && (
            <SweetAlert
              style={{
                height: "255px",
                width: "368px",
              }}
              // title="Report created successfully!"
              success
              confirmBtnBsStyle="primary"
              onConfirm={() => {
                dispatch(
                  changeScorecardDetail({
                    ...scorecardDetail,
                    isReportCreated: true,
                  })
                )
                setIsReportCreated(false)
                dispatch(clearCreateScorecardReport())
                dispatch(updateScorecardDetailClear())
                setmodal_backdrop(false)
                set_is_report_create(false)
              }}
            >
              Report created successfully
            </SweetAlert>
          )}
          {scorecardReportCreateResponse.status === 403 && (
            <SweetAlert
              style={{
                height: "255px",
                width: "368px",
              }}
              // title="Failed to create report!"
              error
              confirmBtnBsStyle="primary"
              onConfirm={() => {
                setIsReportCreated(false)
                dispatch(clearCreateScorecardReport())
                dispatch(updateScorecardDetailClear())
              }}
            >
              Failed to create report
            </SweetAlert>
          )}
          {!isReportCreated &&
            <Modal
              isOpen={modal_backdrop}
              toggle={() => {
                tog_backdrop()
              }}
              centered

              style={{ width: '320px' }}
            >
              <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: '50px', paddingTop: '10px' }}>
                <i className="dripicons-warning" style={{ display: 'flex', fontSize: '70px', height: '10px', width: '120px', color: 'red', }}></i>

              </div>
              <div className="modal-body" style={{ display: "flex", justifyContent: "center", fontSize: "17px", marginLeft: "20px", marginTop: '50px' }}>
                <p className="modal-header" style={{ lineHeight: '25px', textAlign: 'center' }}>
                  After generating this report , <br />
                  you can not make any changes
                </p>
              </div>
              {/* <div className="modal-body" style = {{display:"flex" , justifyContent:"center" , fontSize:"20px", marginLeft:"20px" }}>
             <h5 >
             After generating this report , 
             you can not make any changes
             </h5>
           </div> */}
              <div className="modal-body" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: '-20px' }}>
                <button
                  type="button"
                  className="btn btn-light"
                  style={{ width: "120px" }}
                  onClick={() => {
                    setmodal_backdrop(false)


                  }}
                >
                  Stay on page
                </button>
                <button type="button" className="btn btn-primary" style={{ width: "100px" }}
                  onClick={() => {
                    setIsReportCreated(true)
                    set_is_report_create(true)
                    dispatch(getScorecardDetail(params.scorecardId))

                  }}>
                  Proceed
                </button>
              </div>
            </Modal>

          }

          {is_user_exist && (
            <SweetAlert
              title=""
              warning
              confirmBtnBsStyle="primary"
              style={{
                height: "255px",
                width: "368px",
              }}
              onConfirm={() => {
                set_is_user_exist(false)
              }}
            >
              {
                scorecardDetail.activeUser
                &&
                scorecardDetail.activeUser.userName
              } is already working on it.
            </SweetAlert>
          )}

          <Row>
            <Row className="m-auto">
              <Col className="col-12">
                <Card style={{ borderRadius: "2px" }}>
                  <CardBody>
                    <Row>
                      <Col sm="6">
                        <CardTitle
                          className="h4"
                          style={{ color: "", fontSize: "18px" }}
                        >
                          Modern Marketing Score
                        </CardTitle>
                      </Col>
                      <Col sm="6"></Col>
                    </Row>
                    <Row>
                      <CardBody>
                        <div className="table-responsive" style={style_1}>
                          <Table className="table mb-0">
                            <thead className="table-light">
                              <tr style={{ textAlign: "center" }}>
                                <th
                                  style={{
                                    backgroundColor: "#3c57a3",
                                    color: "white",
                                  }}
                                >
                                  Channels
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#3c57a3",
                                    color: "white",
                                  }}
                                >
                                  Channel score in %
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#3c57a3",
                                    color: "white",
                                  }}
                                >
                                  Selected channels are viewed on report
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {scorecardDetail.selectedChannels &&
                                [...scorecardDetail.selectedChannels]
                                  .sort(
                                    (a, b) =>
                                      getChannelScore(b) - getChannelScore(a)
                                  )
                                  .map(channel => (
                                    <tr
                                      style={{ textAlign: "center" }}
                                      key={channel.channelId}
                                    >
                                      <td style={{ textAlign: "left" }}>
                                        {channel.channelName}
                                      </td>
                                      <td>{getChannelScore(channel)}%</td>
                                      <td>
                                        <Input
                                          type="checkbox"
                                          value={channel.channelId}
                                          onChange={e => {
                                            if (e.target.checked) {
                                              addChannelToList(e.target.value)
                                            } else {
                                              removeChannelFromList(
                                                e.target.value
                                              )
                                            }
                                          }}
                                          defaultChecked
                                        />
                                      </td>
                                    </tr>
                                  ))}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                      <Row>
                        <CardTitle
                          className="h4"
                          style={{ color: "", fontSize: "18px" }}
                        >
                          Levels Of Maturity
                        </CardTitle>
                        <CardBody>
                          <Row>
                            {channelClassifier(100, 20)[0] && (
                              <Col md="4">
                                <div
                                  className="table-responsive"
                                  style={style_1}
                                >
                                  <Table className="table mb-0">
                                    <thead className="table-light">
                                      <tr>
                                        <th
                                          style={{
                                            backgroundColor: "#3c57a3",
                                            color: "white",
                                            textAlign: "center",
                                          }}
                                        >
                                          Mature
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(channelClassifier(100, 20)[0] &&
                                        channelClassifier(100, 20).map(
                                          (channel, i) => (
                                            <tr key={i}>
                                              <td>
                                                <li>{channel}</li>
                                              </td>
                                            </tr>
                                          )
                                        )) || (
                                          <tr>
                                            <td>N.A.</td>
                                          </tr>
                                        )}
                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                            )}
                            {channelClassifier(79, 20)[0] && (
                              <Col md="4">
                                <div
                                  className="table-responsive"
                                  style={style_1}
                                >
                                  <Table className="table mb-0">
                                    <thead className="table-light">
                                      <tr>
                                        <th
                                          style={{
                                            backgroundColor: "#3c57a3",
                                            color: "white",
                                            textAlign: "center",
                                          }}
                                        >
                                          Advanced
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(channelClassifier(79, 20)[0] &&
                                        channelClassifier(79, 20).map(
                                          (channel, i) => (
                                            <tr key={i}>
                                              <td>
                                                <li>{channel}</li>
                                              </td>
                                            </tr>
                                          )
                                        )) || (
                                          <tr>
                                            <td>N.A.</td>
                                          </tr>
                                        )}
                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                            )}
                            {channelClassifier(59, 20)[0] && (
                              <Col md="4">
                                <div
                                  className="table-responsive"
                                  style={style_1}
                                >
                                  <Table className="table mb-0">
                                    <thead className="table-light">
                                      <tr>
                                        <th
                                          style={{
                                            backgroundColor: "#3c57a3",
                                            color: "white",
                                            textAlign: "center",
                                          }}
                                        >
                                          Intermediate
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(channelClassifier(59, 20)[0] &&
                                        channelClassifier(59, 20).map(
                                          (channel, i) => (
                                            <tr key={i}>
                                              <td>
                                                <li>{channel}</li>
                                              </td>
                                            </tr>
                                          )
                                        )) || (
                                          <tr>
                                            <td>N.A.</td>
                                          </tr>
                                        )}
                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                            )}
                            {channelClassifier(39, 40)[0] && (
                              <Col md="4">
                                <div
                                  className="table-responsive"
                                  style={style_1}
                                >
                                  <Table className="table mb-0">
                                    <thead className="table-light">
                                      <tr>
                                        <th
                                          style={{
                                            backgroundColor: "#3c57a3",
                                            color: "white",
                                            textAlign: "center",
                                          }}
                                        >
                                          Beginner & Early
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(channelClassifier(39, 40)[0] &&
                                        channelClassifier(39, 40).map(
                                          (channel, i) => (
                                            <tr key={i}>
                                              <td>
                                                <li>{channel}</li>
                                              </td>
                                            </tr>
                                          )
                                        )) || (
                                          <tr>
                                            <td>N.A.</td>
                                          </tr>
                                        )}
                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                            )}
                          </Row>
                        </CardBody>
                      </Row>

                      <Row>
                        <CardTitle
                          className="h4"
                          style={{ color: "", fontSize: "18px" }}
                        >
                          Channels Analysis
                        </CardTitle>
                        <Row>
                          <Col md="12">
                            {/* <Card> */}
                            <CardBody>
                              {channelListUpdate ? (
                                <RadarChart
                                  DATA={
                                    channelAddedToList[0] && {
                                      selectedChannels: [...channelAddedToList],
                                    }
                                  }
                                />
                              ) : (
                                <RadarChart
                                  DATA={
                                    scorecardDetail.selectedChannels &&
                                    scorecardDetail
                                  }
                                />
                              )}
                            </CardBody>
                            {/* </Card> */}
                          </Col>
                        </Row>
                      </Row>

                      <Row>
                        <Col sm="9"></Col>
                        <Col sm="3">
                          {scorecardDetail.id && (
                            <FormGroup className="m-0">
                              {scorecardDetail.id &&
                                scorecardDetail.isReportCreated ? (
                                <Button
                                  disabled={
                                    scorecardDetail.activeUser
                                    &&
                                    scorecardDetail.activeUser
                                  }
                                  color="primary"
                                  onClick={() => {
                                    history.push(
                                      `/edit/scorecard-report/${params.scorecardId}/cover-page`
                                    )
                                  }}
                                  block
                                >
                                  Go To Edit Report
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  type="submit"
                                  block
                                  disabled={isReportCreated}
                                  onClick={() => {
                                    // handleCreateReport()
                                    setmodal_backdrop(true)
                                  }}
                                >
                                  {isReportCreated ? "" : "Create Report"}
                                  {isReportCreated ? (
                                    <Spinner
                                      // style={{ width: "0.8rem", height: "0.8rem" }}
                                      type="border"
                                      size="sm"
                                    />
                                  ) : null}
                                </Button>
                              )}
                              {scorecardDetail.activeUser &&
                                scorecardDetail.activeUser.userId !==
                                JSON.parse(localStorage.getItem("authUser"))
                                  .uid && (
                                  <span
                                    style={{
                                      marginTop: "10px",
                                      textAlign: "center",
                                      color: "red",
                                      fontSize: "10px",
                                      float: "right",
                                      padding: "0px 12px",
                                    }}
                                  >
                                    {scorecardDetail.activeUser &&
                                      scorecardDetail.activeUser.userName}{" "}
                                    is working...
                                  </span>
                                )}
                            </FormGroup>
                          )}
                        </Col>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default scoringSummary
