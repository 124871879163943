import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom"
import { HandleWindowInActivity, updateScorecardDetail, HandleIsUserExit , HandleSessionTimeOut } from "store/actions";

const UniversalTimerComponent = () => {
    const [seconds, setSeconds] = useState(0)
    const [minutes, setMinutes] = useState(2)
    const history = useHistory()
    const params = useParams()
    const dispatch = useDispatch()
    useEffect(() => {
        if (minutes >= 0) {
            const myInterval = setInterval(() => {
                if (seconds === 0 && minutes > 0) {
                    setMinutes(min => min - 1)
                    setSeconds(59)
                }
                if (seconds > 0) {
                    setSeconds(sec => sec - 1)
                }
                clearInterval(myInterval)
            }, 1000)
        }
        if (seconds === 0 && minutes === 0) {
           
            dispatch(HandleSessionTimeOut(false))
           
            history.push("/")
            dispatch(HandleIsUserExit(true))
        }
    }, [seconds])

    return (
        <div>
            <p style={{
                color: "red",
                marginLeft: "6px",
            }} >
                0{minutes} : {seconds < 10 ? `0${seconds}` : seconds}
            </p>
        </div>
    );
}

export default UniversalTimerComponent;