import {
  IS_COACHING_STARTED,
  IS_UPDATE_SELF_REPORT_SCORECARD_COACHING_STARTED
} from "./actionTypes";

const INIT_STATE = {
  isCoachingStartedForAssessment: false,
  isCoachingStartedForUpdateSelfReportScorecard: false
};

const CoachingPageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case IS_COACHING_STARTED:
      return {
        ...state,
        isCoachingStartedForAssessment: action.payload,
      };

    case IS_UPDATE_SELF_REPORT_SCORECARD_COACHING_STARTED:
      return {
        ...state,
        isCoachingStartedForUpdateSelfReportScorecard: action.payload
      }

    default:
      return state;
  }
};

export default CoachingPageReducer;