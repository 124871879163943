import { color, fontSize } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from "react-html-parser"


import {
  Table,
  Container, Row, Col,
  Alert,
} from "reactstrap";

import "../config/style.config.css"
import { REPORT, REPORT_PAGE } from '../config/style.config';


const KeyObservations = (props) => {

  const PROPS = props;
  const { Key_observations, ChannelData} = PROPS

  return (
    <>
      <Col xs="12" className="print-content p-0 m-0 ">
        <div style={{ ...REPORT_PAGE }}>

          <Row className='g-0 m-0 p-0'>

            <Col xs="7" >
              <div style={{
                width: "108%",
                height: REPORT_PAGE.height,
                overflow:'hidden'
                // border: "1px solid grey"
              }} >

                <Row className='g-0 m-0 p-0'>
                  <Col xs="12" >

                    <div style={{
                      // margin: "auto",
                      width: "100%",
                      height: "fit-content",
                      // padding: "30px 0 0 30px",
                      padding: "30px 0 10px 40px",
                      position: "relative",
                      textTransform: "uppercase",
                      // marginBottom: '30px',
                      // border:'1px solid black'
                    }} >

                      <h1 style={{
                        fontSize: REPORT.FONT_SIZE.XLARGE,
                        fontFamily: REPORT.FONT_FACE.HEADING,
                        color: '#0D0F11',
                        fontWeight: 600,
                        letterSpacing:'2px',
                        // paddingBottom: "8px",
                        paddingBottom: "10px",
                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                      }}>
                        {`KEY OBSERVATIONS ON ${ChannelData && ChannelData.channelName}`.toUpperCase()}
                      </h1>

                    </div>

                  </Col>
                  <Col xs="12" >
                    <div style={{
                      width: "100%",
                      height: "920px",
                      // border: "1px solid grey", 
                      fontSize: REPORT.FONT_SIZE.XSMALL,
                      lineHeight:'1.15', 
                      overflow: "hidden",
                      padding: "0 0 0 40px",
                      marginTop: '-25px'
                    }}
                      className="--key-observations --container"
                    >
                      <div
                        style={{
                          marginTop: "15px",
                          width: "96%",
                          padding: "4px",
                          overflow: "hidden",
                          height: "860px",
                          // border:'1px solid black',
                          fontSize: REPORT.FONT_SIZE.XSMALL,
                          fontFamily: REPORT.FONT_FACE.PARAGRAPH,
                          // margin:'15px auto 4px 20px'
                          color:'#0D0F11'
                        }}
                        className="SavedKeyObservations"
                      >
                        {ReactHtmlParser(Key_observations && Key_observations.contents)}
                      </div>
                    </div>

                  </Col>
                </Row>
              </div>
            </Col>

            <Col xs="5" style={{ marginTop: '130px' }}>
              <div
                className='ImageUploadContainer'
                style={{

                  // width: "80%",
                  width: "60%",
                  height: "265px",
                  // border: "1px solid grey",
                  backgroundImage: Object.keys(Key_observations ? Key_observations : {}).includes("bgImage") ?
                    "url(" + Key_observations.bgImage[0] + ")" : "",
                  // backgroundColor: "#f0f0f0",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  // margin: '0 40px 0 35px',
                  margin: '0 40px 0 70px',
                }} >
                <div className='container--toggle'
                  style={{
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "rgba(0, 0, 0, .3)"

                  }}
                >

                  <span

                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      // border: "1px solid red",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "all 1s ease-in-out"
                    }}
                  >
                    <input
                      type="file"
                      id="upload-button"
                      name="Image_URL"
                      accept="image/*"
                      style={{ display: "none" }}
                    // onChange={handleChange}
                    />
                  </span>
                </div>
              </div>
              <div
                className='ImageUploadContainer'
                style={{

                  // width: "80%",
                  width: "60%",
                  height: "265px",
                  // border: "1px solid grey",
                  backgroundImage: Object.keys(Key_observations ? Key_observations : {}).includes("bgImage") ?
                    "url(" + Key_observations.bgImage[1] + ")" : " ",
                  // backgroundColor: "#f0f0f0",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  // margin: '20px 40px 0 35px',
                  margin: '20px 40px 0 70px',
                }} >
                <div className='container--toggle'
                  style={{
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "rgba(0, 0, 0, .3)"

                  }}
                >

                  <span

                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      // border: "1px solid red",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "all 1s ease-in-out"
                    }}
                  >
                  </span>
                </div>
              </div>
              <div
                className='ImageUploadContainer'
                style={{

                  // width: "80%",
                  width: "60%",
                  height: "265px",
                  // border: "1px solid grey",
                  backgroundImage: Object.keys(Key_observations ? Key_observations : {}).includes("bgImage") ?
                    "url(" + Key_observations.bgImage[2] + ")" : " ",
                  // backgroundColor: "#f0f0f0",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  // margin: '20px 40px 0 35px',
                  margin: '20px 40px 0 70px',
                }} >
                <div className='container--toggle'
                  style={{
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "rgba(0, 0, 0, .3)"

                  }}
                >

                  <span

                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      // border: "1px solid red",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "all 1s ease-in-out"
                    }}
                  >
                  </span>
                </div>
              </div>
            </Col>
            <Col xs="12" style={{
              // border: '1px solid black'
              position: "absolute",
              bottom: 0,
              zIndex: 100,
            }}>
              <div style={{
                width: '100%',
                fontSize: REPORT.FONT_SIZE.MEDIUM,
                fontFamily: REPORT.FONT_FACE.HEADING,
                letterSpacing:'2px',
                padding: '5px 5px 5px 40px',
                backgroundColor: REPORT.COLOR.FOOTER,
                opacity: 0.9,
                fontWeight: 600,
                color: 'white'
              }}>{`${ChannelData && ChannelData.channelName} - KEY OBSERVATIONS`.toUpperCase()}</div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
}

export default KeyObservations;