import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { UPDATE_ACCOUNT_DETAILS , UPDATE_EDITOR_DETAILS} from "./actionTypes"
import {
    
  updateAccountSuccess,
  updateAccountFail,

  updateEditorSuccess,
  updateEditorFail
} from "./actions"

//Include Both Helper File with needed methods
import { updateAccountData , updateEditorData } from "helpers/fakebackend_helper"

function* updateAccountDetails({ id, payload: data }) {
  try {
    const response = yield call(updateAccountData, id, data)
    yield put(updateAccountSuccess(response))
  } catch (error) {
    yield put(updateAccountFail(error))
  }
}

function* updateEditorDetails({ id, payload: data }) {
  try {
    const response = yield call(updateEditorData, id, data)
    yield put(updateEditorSuccess(response))
  } catch (error) {
    yield put(updateEditorFail(error))
  }
}



function* updateAccountSaga() {
  yield takeEvery(UPDATE_ACCOUNT_DETAILS, updateAccountDetails)
  yield takeEvery(UPDATE_EDITOR_DETAILS, updateEditorDetails)

}


export default updateAccountSaga
