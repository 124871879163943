import {
  IS_BUY_CREDIT_MENU_TOGGLE
} from "./actionTypes";

const INIT_STATE = {
  isBuyCreditMenuToggle: false
};

const BuyCreditMenuReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case IS_BUY_CREDIT_MENU_TOGGLE:
      return {
        ...state,
        isBuyCreditMenuToggle: action.payload,
      };

    default:
      return state;
  }
};

export default BuyCreditMenuReducer;