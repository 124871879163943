import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
  Spinner,
  Button
} from "reactstrap";
import { withRouter, useLocation, useHistory, useParams } from "react-router-dom";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction,
  HandleWindowInActivity,
  handleUnsavedChanges,
  handleChangesHappend,
  GetRoutes,
  handleCloseReportEditing,
  updateScorecardDetail
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../CommonForBoth/RightSidebar";

//redux
import { useSelector, useDispatch } from "react-redux";
import TimerComponent from "components/SessionTimeOut";
import { fontSize } from "@mui/system";
import endAssessmentIcon from "../../assets/images/NewPopupIcons/FailedIcon.png"

const Layout = props => {
  const [is_editing_closed, set_is_editing_closed] = useState(false)
  const history = useHistory()

  const parameter = props
  const { handleSaveButton } = parameter
  const dispatch = useDispatch();
  const params = useParams()

  const location = useLocation();

  const [timer, setTimer] = useState({
    minutes: 2,
    seconds: 0
  })

  // const [modal_standard, setmodal_standard] = useState(false);

  function tog_standard() {
    // setmodal_standard(!modal_standard);
    dispatch(HandleWindowInActivity(!windowInActivity))
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_backdrop() {
    dispatch(handleUnsavedChanges(!isDataEdited))
    removeBodyCss();
  }

  // handle close edit report model toggle:
  function tog_backdrop_close_editing() {
    dispatch(handleCloseReportEditing(!exitFromEditReport))
    removeBodyCss();
  }




  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
    windowInActivity,
    isDataEdited,
    isThereChanges,
    getRoutes,
    exitFromEditReport,
    updatedScorecardDetail
  } = useSelector(state => ({
    isPreloader: state.Layout.isPreloader,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    windowInActivity: state.ScorecardReport_Reducer.windowInActivity,
    isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    getRoutes: state.ScorecardReport_Reducer.getRoutes,
    exitFromEditReport: state.ScorecardReport_Reducer.exitFromEditReport,
    updatedScorecardDetail: state.GetScorecardsListReducer.updatedScorecardDetail,


  }));

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  //hides right sidebar on body click
  const hideRightbar = (event) => {
    var rightbar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(showRightSidebarAction(false));
    }
  };

  /*
  layout  settings
  */
  useEffect(() => {
    //init body click event fot toggle rightbar
    document.body.addEventListener("click", hideRightbar, true);

    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }, [isPreloader]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout("vertical"));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
    }
  }, [leftSideBarThemeImage, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);

  // useEffect(() => {
  //   console.log("Location Changed")


  // }, [location])

  //  useEffect(() =>{
  //    dispatch(handleChangesHappend(true))
  //  } , [dispatch])

  // close editing:
  useEffect(() => {
    if (is_editing_closed && updatedScorecardDetail.status === 200) {
      tog_backdrop_close_editing()
      history.push(getRoutes ? getRoutes : "/")
    }
  }, [updatedScorecardDetail])

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper" >
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content" style={{
          overflow: "auto"
        }} >
          {
            windowInActivity &&
            <Modal
              isOpen={windowInActivity}
              toggle={() => {
                tog_standard();
              }}
              centered
              style={{width:'290px', height:'270px'}}
            >
              {/* <div className="modal-header" >
                <div style={{
                  display: "flex",
                  flexDirection: "row"
                }} >
                  <div>

                    <h6 className="modal-title mt-0" id="myModalLabel">
                      Session Time Out In:
                    </h6>
                  </div>
                  <TimerComponent />
                </div>
              </div> */}
              {/* <div
                className="modal-body">
                <h5
                  style={{ textAlign: "center" }}
                >
                  Are you still working on this? If not, please save and logout.
                </h5>

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_standard();
                  }}
                  className="btn btn-primary "
                  data-dismiss="modal"
                >
                  Yes
                </button>
              </div> */}
              <Row className="mt-1 mb-1">
                <Col md="2"></Col>
                <Col md="8" className="text-center mt-4 font-size-18 fw-bold" 
                  style={{
                    color:'#0D0F11',
                    borderBottom:'1px solid #0D0F11'  
                  }}>
                    Session Time Out In: 
                  <p className="fw-bold font-size-14"><TimerComponent /></p>
                </Col>
                <Col md="2"></Col>
              </Row>
              <Row className="modal-body">
                <Col md="12" className="text-center mb-3"
                  style={{
                    color:'#0D0F11',
                    fontWeight:600,
                    fontSize:'20px',
                    // lineHeight:'28px',
                  }}
                >
                    Are you still working on this?
                  <p className="my-0 p-1 text-dark fw-normal font-size-14">
                    If not, please save and logout.
                  </p>
                </Col>
                <Col md="12">
                  <Button block size="sm" className="my-2 font-size-14"
                    type="button"
                    style={{
                      background:'#0D0F11',
                      border:'#0D0F11'
                    }}
                    onClick={() => {
                      tog_standard();
                    }}
                  >
                    Ok
                  </Button>
                </Col>
              </Row>
            </Modal>
          }

          
          {isDataEdited &&
            <Modal
              isOpen={isDataEdited}
              toggle={() => {
                tog_backdrop();
              }}
              centered
              style={{width:'290px', height:'270px'}}
            >
              <Row>
                <Col md="12" className="text-center mt-4">
                  <img src={endAssessmentIcon}
                    style={{
                      height:'75px', 
                      width:'75px'
                  }}
                  />
                </Col>
              </Row>
              <Row className="modal-body">
                <Col md="12" className="text-center mb-3"
                  style={{
                    color:'#0D0F11',
                    fontWeight:600,
                    fontSize:'20px',
                    // lineHeight:'28px',
                  }}
                >
                  You have unsaved changes.
                  <p className="my-0 p-0 text-dark fw-normal font-size-14">
                    Save your changes before choosing another page to view/edit.
                  </p>
                </Col>
                <Col md="12">
                  <Button block size="sm" className="my-2"
                    type="button"
                    disabled={is_editing_closed}
                    style={{
                      background:'#0D0F11',
                      border:'#0D0F11'
                    }}
                    onClick={() => {
                      dispatch(handleUnsavedChanges(false))
                      dispatch(handleChangesHappend(false))
                      history.push(getRoutes)
                    }}
                  >
                    Leave
                  </Button>
                </Col>
                <Col md="12" className="mb-2">
                  <Button block size="sm" className="my-1"
                    type="button"
                    style={{
                      background:'#E7EAEE',
                      border:'#E7EAEE',
                    }}
                    onClick={() => {
                      dispatch(handleUnsavedChanges(false))
                      dispatch(handleChangesHappend(true))
                    }}
                  >
                    <p className="my-0 p-0 text-dark">Stay on page</p>
                  </Button>
                </Col>    
              </Row>
            </Modal>
          }

          {/* close edit report model */}
          {exitFromEditReport &&
            <Modal
              isOpen={exitFromEditReport}
              toggle={() => {
                tog_backdrop_close_editing();
              }}
              centered
              style={{width:'270px', height:'270px'}}
            >
              <Row>
                <Col md="12" className="text-center mt-4">
                  <img src={endAssessmentIcon}
                    style={{
                      height:'80px', 
                      width:'80px'
                  }}
                  />
                </Col>
              </Row>
              <Row className="modal-body">
                <Col md="12" className="text-center mb-3"
                  style={{
                    color:'#0D0F11',
                    fontWeight:600,
                    fontSize:'20px',
                    lineHeight:'28px',
                  }}
                >
                  {
                    location.pathname.includes("/take-a-survey") ?
                      "Do you really want to exit this Assessment?"
                      :
                      "Do you really want to close editing?"
                  }
                </Col>
                <Col md="12">
                  <Button block size="sm" className="my-2"
                    type="button"
                    disabled={is_editing_closed}
                    style={{
                      background:'#0D0F11',
                      border:'#0D0F11'
                    }}
                    onClick={() => {
                      set_is_editing_closed(true)
                      dispatch(updateScorecardDetail(params.scorecardId, {
                        activeUser: null
                      }))
                    }}
                  >
                    {is_editing_closed ? "" : "Yes"}
                    {is_editing_closed ? ( 
                      <Spinner type="border" size="sm"/>
                    ) : null}
                  </Button>
                </Col>
                <Col md="12" className="mb-2">
                  <Button block size="sm" className="my-1"
                    type="button"
                    style={{
                      background:'#E7EAEE',
                      border:'#E7EAEE',
                      color:'#0D0F11'
                    }}
                    onClick={() => {
                      tog_backdrop_close_editing()
                      set_is_editing_closed(false)
                    }}
                  >
                    <p className="my-0 p-0 text-dark">No</p>
                  </Button>
                </Col>
              </Row>
            </Modal>
          }

          {props.children}
        </div>
        <Footer />
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default withRouter(Layout);
