import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
} from "reactstrap"
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  changeScorecardDetail,
  getAccountUserData,
  getTacticInfoClear,
  updateTacticDetails,
} from "store/actions"
import {
  Form,
  Input,
  InputGroup,
  Label,
  NavItem,
  NavLink,
  Modal,
  Button,
  TabContent,
  TabPane,
  FormFeedback,
} from "reactstrap"

import SweetAlert from "react-bootstrap-sweetalert"
import firebase from "firebase/compat"

//validtions
// Formik validation
import * as Yup from "yup"
import { useFormik, yupToFormErrors } from "formik"
import classnames from "classnames"
import { Link } from "react-router-dom"
import {
  getScorecardDetail,
  getTacticInfo,
  updateScorecardDetail,
  updateScorecardDetailClear,
  sendEmailNotificationToAccountOwner,
  getAccountDetails,
} from "store/actions"
import { getChannelScore } from "commonFunctions/GetChannelScore/getChannelScore"
import RadarChart from "pages/AccountUser/chart/chart"
import {
  // getReportTemplate,
  getScorecardReport,
} from "store/actions"
import { indexOf } from "lodash"
import "./TakeASurvey.css"
import updateSuccessIcon from "../../../assets/images/NewPopupIcons/Scorecard created.png"
import updateFailedIcon from "../../../assets/images/NewPopupIcons/FailedIcon.png"
import ThankYouIcon from "../../../assets/images/NewPopupIcons/ThankYouIcon.png"

const TakeASurvey = () => {
  //states:
  const [evaluation, setEvaluation] = useState({})
  const [attemptedEvaluation, setAttemptedEvaluation] = useState("")
  const [updateEvaluation, setUpdateEvaluation] = useState(false)
  const [alertMessage, setAlertMessage] = useState({
    success: "",
    failed: "",
  })
  const [updateLocalState, setUpdateLocalState] = useState({})
  const [initialTacticId, setInitialTacticId] = useState(null)
  const [clearUpdateState, setIsclearUpdateState] = useState(false)
  const [IS_SURVEY_CLOSE, SET_IS_SURVEY_CLOSE] = useState(false)
  const [checkIsTacticAttended, setCheckIsTackticAttended] = useState(false)
  const [is_survey_exit, set_is_survey_exit] = useState(false)
  // State for thank you message
  const [displayThankYouMessage, setDisplayThankYouMessage] = useState(false)
  // State to set logged in user details
  const [loggedInUserDetails, setLoggedInUserDetails] = useState({})
  // Local state for scorecard details
  const [localStateForScorecardDetails, setLocalStateForScorecardDetails] = useState(null)
  // Account Owner Details State
  const [accountOwnerDetails, setAccountOwnerDetails] = useState(null)

  //hooks
  const history = useHistory()
  const params = useParams()

  //redux
  const dispatch = useDispatch()
  const {
    scorecardDetail,
    GetTacticInfo,
    updatedScorecardDetail,
    updateTactic,
    isCoachingStartedForAssessment,
    accountDetails
  } = useSelector(state => ({
    scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
    GetTacticInfo: state.AddTacticReducer.GetTacticInfo,
    updatedScorecardDetail:
      state.GetScorecardsListReducer.updatedScorecardDetail,
    updateTactic: state.UpdateTacticDetailsReducer.updateTactic,
    isCoachingStartedForAssessment: state.CoachingPageReducer.isCoachingStartedForAssessment,
    accountDetails: state.SuperAdmin.accountDetails,
  }))

  //lifecycles
  useEffect(() => {
    dispatch(getScorecardDetail(params.scorecardId))
    // dispatch(getTacticInfo(params.channelId, params.tacticId))
  }, [dispatch])

  useEffect(() => {
    dispatch(getTacticInfo(params.channelId, params.tacticId))
    setAttemptedEvaluation(getAnswer())
    setEvaluation({})
    if (Object.keys(scorecardDetail).includes("id")) {
      setLocalStateForScorecardDetails(scorecardDetail)
      var Initial_tactic_id =
        scorecardDetail.selectedChannels[0].tactics[0].tacticId
      if (Initial_tactic_id !== params.tacticId) {
        setInitialTacticId(true)
      } else {
        setInitialTacticId(false)
      }
    }
  }, [dispatch, params.tacticId, scorecardDetail])

  // check for initial channel and tactics.
  // useEffect(() => {
  // }, [scorecardDetail])
  useEffect(() => {
    return () => dispatch(getTacticInfoClear())
  }, [dispatch])

  // Set firebase token claims
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user
          .getIdTokenResult()
          .then(token => {
            setLoggedInUserDetails(token.claims)
            // console.log(token.claims)
          })
          .catch(err => { })
      }
    })

    return () => {
      setLoggedInUserDetails(null)
    }
  }, [])
  //console.log("Updated tactic info...", updateTactic)

  //functions
  const getChannelName = () => {
    if (scorecardDetail.selectedChannels) {
      var channelName = scorecardDetail.selectedChannels.filter(
        channel => channel.channelId === params.channelId
      )
      return (channelName[0] && channelName[0].channelName) || ""
    }
    return 0
  }
  const getTacticName = () => {
    if (scorecardDetail.selectedChannels) {
      var channelDetail = scorecardDetail.selectedChannels.filter(
        channel => channel.channelId === params.channelId
      )
      var tacticName =
        channelDetail[0] &&
        channelDetail[0].tactics.filter(
          tactic => !tactic.isDisabled && tactic.tacticId === params.tacticId
        )[0].tacticName

      return tacticName
    }
    return 0
  }

  const getAnswer = () => {
    if (scorecardDetail.selectedChannels) {
      var channelDetail = scorecardDetail.selectedChannels.filter(
        channel => channel.channelId === params.channelId
      )
      var tacticName =
        channelDetail[0] &&
        channelDetail[0].tactics.filter(
          tactic => !tactic.isDisabled && tactic.tacticId === params.tacticId
        )[0].evaluation

      return tacticName
    }
    return 0
  }

  const ShowQuestionNumber = () => {
    if (scorecardDetail.selectedChannels) {
      var Channel = scorecardDetail.selectedChannels.filter(
        channel => channel.channelId === params.channelId
      )
      var TotalQuestionCount = Channel[0] && Channel[0].tactics.length
      var TacticArray = Channel[0] && Channel[0].tactics
      var Tactic =
        Channel[0] &&
        Channel[0].tactics.filter(tactic => tactic.tacticId === params.tacticId)

      return TotalQuestionCount
    }
    return 0
  }

  // console.log(ShowQuestionNumber())

  //validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {},

    validationSchema: Yup.object({}),

    onSubmit: values => {
      var updatedChannelsEvaluation = scorecardDetail.selectedChannels.map(
        channel => {
          if (channel.channelId === evaluation.channelId) {
            var tactic_1 = channel.tactics
              .filter(tactic => !tactic.isDisabled)
              .map(tactic => {
                if (tactic.tacticId === evaluation.tactics[0].tacticId) {
                  return evaluation.tactics[0]
                } else {
                  return tactic
                }
              })
            return {
              ...channel,
              tactics: [...tactic_1],
            }
          } else {
            return channel
          }
        }
      )

      // dispatch(changeScorecardDetail({
      //     ...scorecardDetail,
      //     selectedChannels: [...updatedChannelsEvaluation]
      // }))

      setUpdateLocalState({
        ...scorecardDetail,
        selectedChannels: [...updatedChannelsEvaluation],
      })

      var updatedChannelsEvaluationData = {
        lastUpdatedOn: {
          _miliseconds: 0,
          _seconds: new Date().getTime() / 1000,
        },
        selectedChannels: [...updatedChannelsEvaluation],
      }

      dispatch(
        updateScorecardDetail(params.scorecardId, updatedChannelsEvaluationData)
      )
    },

    onReset: () => { },
  })

  const checkIsSurveyInCompleted = () => {
    var channelsScoreList = !scorecardDetail.selectedChannels
      ? []
      : scorecardDetail.selectedChannels.map(channel => {
        return [
          ...channel.tactics
            .filter(tactic => !tactic.isDisabled)
            .map(tactic => tactic.tacticScore),
        ].includes(undefined)
      })

    return channelsScoreList.includes(true)
  }

  const [modal_backdrop, setmodal_backdrop] = useState(false)
  useEffect(() => {
    dispatch(getScorecardDetail(params.scorecardId))
  }, [dispatch])

  const GetChannelTacticId = () => {
    var channelList =
      Object.keys(scorecardDetail).includes("id") &&
      scorecardDetail.selectedChannels
    var currentChannelIndex =
      channelList[0] &&
      channelList.findIndex(channel => channel.channelId === params.channelId)
    var checkIfDefinedChannel =
      channelList[0] && channelList[currentChannelIndex + 1]
    var nextChannelTacticList = checkIfDefinedChannel
      ? checkIfDefinedChannel.tactics.filter(tactic => !tactic.isDisabled)
      : channelList[currentChannelIndex].tactics.filter(
        tactic => !tactic.isDisabled
      )

    var tacticList =
      channelList[0] &&
      channelList
        .filter(channel => channel.channelId === params.channelId)[0]
        .tactics.filter(tactic => !tactic.isDisabled)
    var currentTacticIndex =
      channelList[0] &&
      tacticList.findIndex(tactic => tactic.tacticId === params.tacticId)
    var checkIfDefinedTactic =
      channelList[0] && tacticList[currentTacticIndex + 1]
    if (checkIfDefinedTactic) {
      return {
        channelId: params.channelId,
        tacticId: checkIfDefinedTactic.tacticId,
      }
    } else if (!checkIfDefinedChannel && !checkIfDefinedTactic) {
      return {
        channelId: channelList[0].channelId,
        tacticId: channelList[0].tactics[0].tacticId,
      }
    } else {
      return {
        channelId: checkIfDefinedChannel.channelId,
        tacticId: nextChannelTacticList[0].tacticId,
      }
    }
  }

  // Function to send email to Account Owner
  const sendEmailNotification = () => {
    const emailData = {
      emailId: localStateForScorecardDetails.creatorEmail,
      scorecardName: localStateForScorecardDetails.scorecardName,
      completedBy: JSON.parse(localStorage.getItem("authUser")).displayName
    }
    dispatch(sendEmailNotificationToAccountOwner(emailData))
  }

  useEffect(() => {
    if (updatedScorecardDetail.status === 200 && checkIsTacticAttended) {
      if (
        !checkIsSurveyInCompleted() &&
        !evaluation.channelName &&
        IS_SURVEY_CLOSE
      ) {
        if (
          loggedInUserDetails.accountUser &&
          localStateForScorecardDetails.scorecardVersion === "Self Report"
        ) {
          setmodal_backdrop(false)
          setDisplayThankYouMessage(true)
          sendEmailNotification()
        } else {
          dispatch(updateScorecardDetailClear())
          history.push(`/viewScoringSummary/${params.scorecardId}`)
        }
      } else if (clearUpdateState) {
        dispatch(updateScorecardDetailClear())
        history.push("/")
      } else {
        dispatch(updateScorecardDetailClear())
        history.push(
          `/take-a-survey/${params.scorecardId}/${GetChannelTacticId().channelId
          }/${GetChannelTacticId().tacticId}/${GetChannelTacticId().tacticId}`
        )
      }
      dispatch(changeScorecardDetail(updateLocalState))
      setUpdateEvaluation(false)
      setEvaluation({})
      dispatch(updateScorecardDetailClear())
    }

    if (updatedScorecardDetail.status === 200 && is_survey_exit) {
      history.push(`/viewScoringSummary/${params.scorecardId}`)
    }
  }, [updatedScorecardDetail])

  // Functionality for Exit Survey
  useEffect(() => {
    if (displayThankYouMessage) {
      setTimeout(() => {
        setDisplayThankYouMessage(false)
        dispatch(updateScorecardDetailClear())
        history.push(`/viewScoringSummary/${params.scorecardId}`)
      }, 3000)
    }
  }, [displayThankYouMessage]);

  // console.log("GetTacticInfo",GetTacticInfo)

  const getCurrentTacticIndex = () => {
    if (scorecardDetail.selectedChannels) {
      var channelDetail = scorecardDetail.selectedChannels.filter(
        channel => channel.channelId === params.channelId
      )
      var tacticIndex =
        channelDetail[0] &&
        channelDetail[0].tactics.filter(tactic => !tactic.isDisabled).map(t => t.tacticName)
      return tacticIndex.indexOf(getTacticName()) + 1
    }
    return 0
  }

  const getAllTacticsLength = () => {
    if (scorecardDetail.selectedChannels) {
      var channelDetail = scorecardDetail.selectedChannels.filter(
        channel => channel.channelId === params.channelId
      )
      var tacticLength =
        channelDetail[0] &&
        channelDetail[0].tactics.filter(tactic => !tactic.isDisabled).map(t => t.tacticName)
      return tacticLength.length
    }
    return 0
  }

  // console.log("ACCOUNT DETAILS accountDetails...", accountDetails)

  // console.log("scorecardDetail...scorecardDetail...", scorecardDetail)

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Mambo-Assessment</title>
        </MetaTags>

        <Container fluid={true}>
          {updatedScorecardDetail.status === 403 && (
            <SweetAlert
              style={{ height: "270px", width: "270px" }}
              custom
              customIcon={
                <Row>
                  <Col md="12" className="mb-4">
                    <img
                      src={updateFailedIcon}
                      style={{
                        height: "80px",
                        width: "80px",
                      }}
                    />
                  </Col>
                  <Col
                    md="12"
                    className="text-center container-fluid"
                    style={{
                      color: "#0D0F11",
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "25px",
                    }}
                  >
                    Failed to evaluate tactic
                  </Col>
                </Row>
              }
              customButtons={
                <div className="container-fluid">
                  <Button
                    block
                    size="sm"
                    className=""
                    style={{
                      background: "#0D0F11",
                      border: "#0D0F11",
                    }}
                    onClick={() => {
                      setUpdateEvaluation(false)
                      setEvaluation({})
                      dispatch(updateScorecardDetailClear())
                      // setmodal_backdrop(false)
                    }}
                  >
                    Close
                  </Button>
                </div>
              }
            ></SweetAlert>
          )}

          {
            displayThankYouMessage && (
              <SweetAlert
                style={{ height: "270px", width: "270px" }}
                custom
                customIcon={
                  <Row>
                    <Col md="12" className="mb-4">
                      <img
                        src={ThankYouIcon}
                        style={{
                          height: "80px",
                          width: "80px",
                        }}
                      />
                    </Col>
                    <Col
                      md="12"
                      className="text-center container-fluid"
                      style={{
                        color: "#0D0F11",
                        fontWeight: 600,
                        fontSize: "24px",
                        lineHeight: 1.2,
                      }}
                    >
                      Thank You for Completing the Assessment!
                    </Col>
                  </Row>
                }
                customButtons={<div className="container-fluid"></div>}
              ></SweetAlert>
            )
          }

          {modal_backdrop && (
            <Modal isOpen={modal_backdrop} centered style={{ width: "320px" }}>
              <Row className="mt-2">
                <Col md="12" className="text-center mt-3">
                  <img src={updateFailedIcon} />
                </Col>
              </Row>

              <Row className="modal-body">
                <Col
                  md="12"
                  className="text-center mb-3"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "22px",
                    // lineHeight:'28px',
                  }}
                >
                  Are you sure you want to close the Assessment ?
                  <p className="my-0 p-0 text-dark fw-normal font-size-14">
                    Once closed, you cannot go back and edit Assessment
                    questions. Please refer to the Scoring Summary to ensure
                    that your answers and scores are accurate before closing the
                    Assessment.
                  </p>
                </Col>
                <Col md="12">
                  <Button
                    block
                    size="sm"
                    className="my-2"
                    type="button"
                    disabled={updateEvaluation}
                    style={{
                      background: "#0D0F11",
                      border: "#0D0F11",
                    }}
                    onClick={() => {
                      // state handled for all tactics are attended or not.
                      setCheckIsTackticAttended(true)
                      // Enabling spinner 17062022T2040
                      setUpdateEvaluation(true)
                      // Closing survey
                      SET_IS_SURVEY_CLOSE(true)
                      setEvaluation(true)
                      setAlertMessage({
                        success: "Assessment Closed Successfully!",
                        failed: "Failed to Close Assessment!",
                      })

                      var closeSurvey = {
                        lastUpdatedOn: {
                          _miliseconds: 0,
                          _seconds: new Date().getTime() / 1000,
                        },
                        isSurveyCompleted: true,
                        activeUser: null,
                      }

                      dispatch(
                        updateScorecardDetail(params.scorecardId, closeSurvey)
                      )

                      // history.push(`/scoringSummary/${params.scorecardId}`)
                    }}
                  >
                    {updateEvaluation ? "" : "Close Assessment"}
                    {updateEvaluation ? (
                      <Spinner type="border" size="sm" />
                    ) : null}
                  </Button>
                </Col>
                <Col md="12" className="mb-2">
                  <Button
                    block
                    size="sm"
                    className="my-1"
                    type="button"
                    style={{
                      background: "#E7EAEE",
                      border: "#E7EAEE",
                    }}
                    onClick={() => {
                      setmodal_backdrop(false)
                      setUpdateEvaluation(false)
                    }}
                  >
                    <p className="my-0 p-0 text-dark">Back to Assessment</p>
                  </Button>
                </Col>
              </Row>
            </Modal>
          )}

          {!Object.keys(GetTacticInfo).includes("id") ? (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                height: "400px",
              }}
            >
              <Spinner
                style={{
                  width: "60px",
                  height: "60px",
                  margin: "calc(200px - 30px)",
                }}
                role="grid"
                type="border"
                color="primary"
              />
            </div>
          ) : (
            <Row>
              <Col xl="12">
                <Card style={{ background: "", borderRadius: "2px" }}>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        setUpdateEvaluation(true)
                        setCheckIsTackticAttended(true)

                        if (
                          !checkIsSurveyInCompleted() &&
                          !evaluation.channelName
                        ) {
                          // SET_IS_SURVEY_CLOSE(true)
                          // setEvaluation(true)
                          // setAlertMessage({
                          //   success: "Survey closed successfully!",
                          //   failed: "Failed to close survey!",
                          // })
                          // var closeSurvey = {
                          //   lastUpdatedOn: {
                          //     _miliseconds: 0,
                          //     _seconds: new Date().getTime() / 1000,
                          //   },
                          //   isSurveyCompleted: true,
                          // }
                          // dispatch(
                          //   updateScorecardDetail(
                          //     params.scorecardId,
                          //     closeSurvey
                          //   )
                          // )
                        } else {
                          setAlertMessage({
                            success: "Tactic evaluated successfully!",
                            failed: "Failed to evaluate tactic",
                          })
                          validation.handleSubmit()
                        }
                        return false
                      }}
                    >
                      <div>
                        <Row className="mb-2">
                          <Col md="12">
                            {document.location.pathname.includes(
                              "/take-a-survey/"
                            ) && (
                                <h2
                                  className="fw-bold"
                                  style={{ color: "#0D0F11" }}
                                >
                                  {`${scorecardDetail.companyName} - ${scorecardDetail.scorecardName}`}
                                </h2>
                              )}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md="12"
                            className="font-size-16 mx-2"
                            style={{
                              color: "#0D0F11",
                              fontWeight: "bold",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <span className="mx-2">Channel: </span>
                              <span className="mx-2"
                                style={{
                                  fontWeight: "bold",
                                  color: "#05727B",
                                  // marginLeft: "2.4rem",
                                }}
                              > 
                                {scorecardDetail && getChannelName()}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "10px" }}>
                          <Col
                            md="12"
                            className="font-size-16 mx-2"
                            style={{
                              color: "#0D0F11",
                              fontWeight: "bold",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <span className="mx-2">Tactic: </span>
                              <div className="mx-4"
                                style={{
                                  fontWeight: "bold",
                                  color: "#0D0F11",
                                  // marginLeft: "2.4rem",
                                }}
                              > 
                                {scorecardDetail && getTacticName()}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <hr
                        style={{
                          color: "#424949",
                          height: "2px",
                          top: "10px",
                          background: "#1B2631",
                        }}
                      ></hr>
                      <Row className="mx-3">
                        <Col
                          md="2"
                          className="font-size-12"
                          style={{
                            backgroundColor: "#D0D5DD",
                            borderRadius: "10px",
                            border: "none",
                            height: "30px",
                            fontWeight: "bold",
                            color: "#64748B",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          Question {getCurrentTacticIndex()} /{" "}
                          {getAllTacticsLength()}
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <div>
                            <CardBody
                              style={{
                                borderRadius: "2px",
                                paddingBottom: "20px",
                              }}
                            >
                              <Row>
                                <Col
                                  md="12"
                                  style={{
                                    color: "#0D0F11",
                                    fontSize: "19px",
                                    fontWeight: "500",
                                    // marginLeft: "5px",
                                  }}
                                >
                                  {GetTacticInfo && GetTacticInfo.interrogation}
                                </Col>
                              </Row>

                              <Row>
                                {/* <Col md="2"></Col> */}
                                <Col md="12" className="">
                                  <div className="mt-2 tour-guide-survey-options">
                                    <div id="tacticEvaluation" role="group">
                                      <label htmlFor="exampleRadios1">
                                        <input
                                          // className="btn-check"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios1"
                                          value="option1"
                                          autoComplete="off"
                                          checked={
                                            GetTacticInfo.early
                                              ? GetTacticInfo.early
                                                .evaluation ===
                                              attemptedEvaluation
                                              : false
                                          }
                                          onSelect={
                                            GetTacticInfo.early
                                              ? GetTacticInfo.early
                                                .evaluation ===
                                              attemptedEvaluation
                                              : false
                                          }
                                          onClick={() => {
                                            setAttemptedEvaluation(
                                              GetTacticInfo.early.evaluation
                                            )
                                            setEvaluation({
                                              channelName: getChannelName(),
                                              channelId: params.channelId,
                                              channelScore: "",
                                              tactics: [
                                                {
                                                  tacticId: params.tacticId,
                                                  tacticName: getTacticName(),
                                                  interrogation:
                                                    GetTacticInfo.interrogation,
                                                  tacticScore:
                                                    GetTacticInfo.early.score,
                                                  evaluation:
                                                    GetTacticInfo.early
                                                      .evaluation,
                                                  recommendation:
                                                    GetTacticInfo.early
                                                      .recommendation,
                                                },
                                              ],
                                            })
                                          }}
                                          onChange={e => { }}
                                        />

                                        <span
                                          style={{
                                            border:
                                              "1px solid rgba(179, 179, 179, 0.7)",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {GetTacticInfo.early &&
                                            GetTacticInfo.early.evaluation}
                                        </span>
                                      </label>
                                    </div>
                                    <div id="tacticEvaluation" role="group">
                                      <label htmlFor="exampleRadios2">
                                        <input
                                          //  className="btn-check"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios2"
                                          value="option2"
                                          autoComplete="off"
                                          checked={
                                            GetTacticInfo.beginner
                                              ? GetTacticInfo.beginner
                                                .evaluation ===
                                              attemptedEvaluation
                                              : false
                                          }
                                          onSelect={
                                            GetTacticInfo.beginner
                                              ? GetTacticInfo.beginner
                                                .evaluation ===
                                              attemptedEvaluation
                                              : false
                                          }
                                          onClick={() => {
                                            setAttemptedEvaluation(
                                              GetTacticInfo.beginner.evaluation
                                            )
                                            setEvaluation({
                                              channelName: getChannelName(),
                                              channelId: params.channelId,
                                              channelScore: "",
                                              tactics: [
                                                {
                                                  tacticId: params.tacticId,
                                                  tacticName: getTacticName(),
                                                  interrogation:
                                                    GetTacticInfo.interrogation,
                                                  tacticScore:
                                                    GetTacticInfo.beginner
                                                      .score,
                                                  evaluation:
                                                    GetTacticInfo.beginner
                                                      .evaluation,
                                                  recommendation:
                                                    GetTacticInfo.beginner
                                                      .recommendation,
                                                },
                                              ],
                                            })
                                          }}
                                          onChange={e => { }}
                                        />

                                        <span
                                          style={{
                                            border:
                                              "1px solid rgba(179, 179, 179, 0.7)",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {GetTacticInfo.beginner &&
                                            GetTacticInfo.beginner.evaluation}
                                        </span>
                                      </label>
                                    </div>
                                    <div id="tacticEvaluation" role="group">
                                      <label htmlFor="exampleRadios3">
                                        <input
                                          //  className="btn-check"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios3"
                                          value="option3"
                                          autoComplete="off"
                                          checked={
                                            GetTacticInfo.intermediate
                                              ? GetTacticInfo.intermediate
                                                .evaluation ===
                                              attemptedEvaluation
                                              : false
                                          }
                                          onSelect={
                                            GetTacticInfo.intermediate
                                              ? GetTacticInfo.intermediate
                                                .evaluation ===
                                              attemptedEvaluation
                                              : false
                                          }
                                          onClick={() => {
                                            setAttemptedEvaluation(
                                              GetTacticInfo.intermediate
                                                .evaluation
                                            )
                                            setEvaluation({
                                              channelName: getChannelName(),
                                              channelId: params.channelId,
                                              channelScore: "",
                                              tactics: [
                                                {
                                                  tacticId: params.tacticId,
                                                  tacticName: getTacticName(),
                                                  interrogation:
                                                    GetTacticInfo.interrogation,
                                                  tacticScore:
                                                    GetTacticInfo.intermediate
                                                      .score,
                                                  evaluation:
                                                    GetTacticInfo.intermediate
                                                      .evaluation,
                                                  recommendation:
                                                    GetTacticInfo.intermediate
                                                      .recommendation,
                                                },
                                              ],
                                            })
                                          }}
                                          onChange={e => { }}
                                        />

                                        <span
                                          style={{
                                            border:
                                              "1px solid rgba(179, 179, 179, 0.7)",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {GetTacticInfo.intermediate &&
                                            GetTacticInfo.intermediate
                                              .evaluation}
                                        </span>
                                      </label>
                                    </div>
                                    <div id="tacticEvaluation" role="group">
                                      <label htmlFor="exampleRadios4">
                                        <input
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios4"
                                          value="option4"
                                          autoComplete="off"
                                          checked={
                                            GetTacticInfo.advanced
                                              ? GetTacticInfo.advanced
                                                .evaluation ===
                                              attemptedEvaluation
                                              : false
                                          }
                                          onSelect={
                                            GetTacticInfo.advanced
                                              ? GetTacticInfo.advanced
                                                .evaluation ===
                                              attemptedEvaluation
                                              : false
                                          }
                                          onClick={() => {
                                            setAttemptedEvaluation(
                                              GetTacticInfo.advanced.evaluation
                                            )
                                            setEvaluation({
                                              channelName: getChannelName(),
                                              channelId: params.channelId,
                                              channelScore: "",
                                              tactics: [
                                                {
                                                  tacticId: params.tacticId,
                                                  tacticName: getTacticName(),
                                                  interrogation:
                                                    GetTacticInfo.interrogation,
                                                  tacticScore:
                                                    GetTacticInfo.advanced
                                                      .score,
                                                  evaluation:
                                                    GetTacticInfo.advanced
                                                      .evaluation,
                                                  recommendation:
                                                    GetTacticInfo.advanced
                                                      .recommendation,
                                                },
                                              ],
                                            })
                                          }}
                                          onChange={e => { }}
                                        />
                                        <span
                                          style={{
                                            border:
                                              "1px solid rgba(179, 179, 179, 0.7)",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {GetTacticInfo.advanced &&
                                            GetTacticInfo.advanced.evaluation}
                                        </span>
                                      </label>
                                    </div>
                                    <div id="tacticEvaluation" role="group">
                                      <label htmlFor="exampleRadios5">
                                        <input
                                          // className="btn-check"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios5"
                                          value="option5"
                                          autoComplete="off"
                                          checked={
                                            GetTacticInfo.mature
                                              ? GetTacticInfo.mature
                                                .evaluation ===
                                              attemptedEvaluation
                                              : false
                                          }
                                          onSelect={
                                            GetTacticInfo.mature
                                              ? GetTacticInfo.mature
                                                .evaluation ===
                                              attemptedEvaluation
                                              : false
                                          }
                                          onClick={() => {
                                            setAttemptedEvaluation(
                                              GetTacticInfo.mature.evaluation
                                            )
                                            setEvaluation({
                                              channelName: getChannelName(),
                                              channelId: params.channelId,
                                              channelScore: "",
                                              tactics: [
                                                {
                                                  tacticId: params.tacticId,
                                                  tacticName: getTacticName(),
                                                  interrogation:
                                                    GetTacticInfo.interrogation,
                                                  tacticScore:
                                                    GetTacticInfo.mature.score,
                                                  evaluation:
                                                    GetTacticInfo.mature
                                                      .evaluation,
                                                  recommendation:
                                                    GetTacticInfo.mature
                                                      .recommendation,
                                                },
                                              ],
                                            })
                                          }}
                                          onChange={e => { }}
                                        />
                                        <span
                                          style={{
                                            border:
                                              "1px solid rgba(179, 179, 179, 0.7)",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {GetTacticInfo.mature &&
                                            GetTacticInfo.mature.evaluation}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              {isCoachingStartedForAssessment ? (
                                <Row
                                  style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    // justifyContent: "center",
                                  }}
                                >
                                  <Col
                                    md="2"
                                    className="tour-guide-close-assessment"
                                  >
                                    <FormGroup className="mb-0">
                                      <Button
                                        type="button"
                                        className="font-size-14"
                                        style={{
                                          background: "#0D0F11",
                                          border: "#0D0F11",
                                        }}
                                        size="md"
                                        block
                                      >
                                        Close Assessment
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                  <Col md="1" />
                                  <Col md="3"
                                    className="tour-guide-previous-question"
                                  >
                                    <FormGroup>
                                      <Button
                                        type="button"
                                        style={{
                                          background: "#0D0F11",
                                          border: "#0D0F11",
                                        }}
                                        block
                                        className="btn btn-secondary w-md"
                                      >
                                        <div
                                          className="d-flex justify-content-center"
                                          style={{ color: "#ffffff" }}
                                        >
                                          <i className="bx bx-left-arrow-alt fw-bold mt-1"></i>
                                          <span className="font-size-14 mx-2">
                                            {scorecardDetail && 
                                              scorecardDetail.scorecardVersion === "Pro" ? 
                                              "Last Question" : "Previous Question"
                                            }
                                          </span>
                                        </div>
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    md="2"
                                    className="tour-guide-next-question"
                                  >
                                    <FormGroup className="mb-0">
                                      <Button
                                        type="button"
                                        className="btn btn-primary w-md"
                                        block
                                        style={{
                                          background: "#05727B",
                                          border: "#05727B",
                                        }}
                                      >
                                        <div className="d-flex justify-content-center"
                                          style={{ color: "#ffffff" }}
                                        >
                                          <span className="font-size-14 mx-2">
                                            Next Question
                                          </span>
                                          <i className="bx bx-right-arrow-alt fw-bold mt-1"></i>
                                        </div>
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                  <Col md="2" />
                                  <Col
                                    md="2"
                                    className="tour-guide-save-and-exit"
                                  >
                                    <FormGroup className="mb-0 ">
                                      <Button
                                        className="btn btn-primary w-md"
                                        type="button"
                                        style={{
                                          background: "#CB6928",
                                          border: "#CB6928",
                                        }}
                                        block
                                      >
                                        <div
                                          className="d-flex justify-content-center"
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                            color: "#ffffff",
                                          }}
                                        >
                                          <span className="font-size-14">
                                            Save & Exit
                                          </span>
                                          <i className="mdi mdi-content-save fw-bold mx-2"></i>
                                        </div>
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              ) : (
                                <Row
                                  style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {!evaluation.channelName &&
                                    !checkIsSurveyInCompleted() ? (
                                    <>
                                      <Col md="2">
                                        <FormGroup className="mb-0">
                                          <Button
                                            type="button"
                                            className="font-size-14"
                                            style={{
                                              background: "#0D0F11",
                                              border: "#0D0F11",
                                            }}
                                            size="md"
                                            block
                                            onClick={() => {
                                              // handleCreateReport()
                                              setmodal_backdrop(true)
                                            }}
                                          >
                                            Close Assessment
                                            {/* {updateEvaluation
                                              ? ""
                                              : "Close Survey"}
                                              {updateEvaluation ? (
                                              <Spinner type="border" size="sm" />
                                              ) : null} */}
                                          </Button>
                                        </FormGroup>
                                      </Col>
                                      <Col md="5"></Col>
                                    </>
                                  ) : (
                                    <>
                                      {initialTacticId ? (
                                        <>
                                          <Col md="2"></Col>
                                          <Col md="3">
                                            <FormGroup>
                                              <Button
                                                type="button"
                                                style={{
                                                  background: "#0D0F11",
                                                  border: "#0D0F11",
                                                }}
                                                block
                                                className="btn btn-secondary w-md"
                                                onClick={() => {
                                                  history.goBack()
                                                }}
                                              >
                                                <div
                                                  className="d-flex justify-content-center"
                                                  style={{ color: "#ffffff" }}
                                                >
                                                  <i className="bx bx-left-arrow-alt fw-bold mt-1"></i>
                                                  <span className="font-size-14 mx-2">
                                                    {scorecardDetail && 
                                                      scorecardDetail.scorecardVersion === "Pro" ? 
                                                      "Last Question" : "Previous Question"
                                                    }
                                                  </span>
                                                </div>
                                              </Button>
                                            </FormGroup>
                                          </Col>
                                        </>
                                      ) : (
                                        <>
                                          {/* <Col md="1" xs="1"></Col> */}
                                          <Col md="4"></Col>
                                        </>
                                      )}
                                      <Col md="3">
                                        <FormGroup className="mb-0">
                                          {!evaluation.channelName ||
                                            updateEvaluation ? (
                                            <Button
                                              style={{
                                                background: "#05727B",
                                                border: "#05727B",
                                              }}
                                              // color="secondary"
                                              block
                                              type="button"
                                              className="btn btn-secondary w-md"
                                              onClick={() => {
                                                history.push(
                                                  `/take-a-survey/${params.scorecardId
                                                  }/${GetChannelTacticId()
                                                    .channelId
                                                  }/${GetChannelTacticId()
                                                    .tacticId
                                                  }/${GetChannelTacticId()
                                                    .tacticId
                                                  }`
                                                )
                                              }}
                                            >
                                              {updateEvaluation ? (
                                                ""
                                              ) : (
                                                <div
                                                  className="d-flex justify-content-center"
                                                  style={{ color: "#ffffff" }}
                                                >
                                                  <span className="font-size-14 mx-2">
                                                    Next Question
                                                  </span>
                                                  <i className="bx bx-right-arrow-alt fw-bold mt-1"></i>
                                                </div>
                                              )}

                                              {updateEvaluation ? (
                                                <Spinner
                                                  type="border"
                                                  size="sm"
                                                />
                                              ) : null}
                                            </Button>
                                          ) : (
                                            <Button
                                              type="submit"
                                              className="btn btn-primary w-md"
                                              block
                                              style={{
                                                background: "#05727B",
                                                border: "#05727B",
                                              }}
                                              disabled={
                                                !evaluation.channelName ||
                                                updateEvaluation
                                              }
                                            >
                                              {updateEvaluation ? (
                                                ""
                                              ) : (
                                                <div className="d-flex justify-content-center"
                                                  style={{ color: "#ffffff" }}
                                                >
                                                  <span className="font-size-14 mx-2">
                                                    Next Question
                                                  </span>
                                                  <i className="bx bx-right-arrow-alt fw-bold mt-1"></i>
                                                </div>
                                              )}
                                              {updateEvaluation ? (
                                                <Spinner
                                                  type="border"
                                                  size="sm"
                                                />
                                              ) : null}
                                            </Button>
                                          )}
                                        </FormGroup>
                                      </Col>
                                    </>
                                  )}
                                  <Col md="2"></Col>
                                  <Col md="2">
                                    <FormGroup className="mb-0 ">
                                      <Button
                                        disabled={is_survey_exit}
                                        className="btn btn-primary w-md"
                                        type="button"
                                        style={{
                                          background: "#CB6928",
                                          border: "#CB6928",
                                        }}
                                        block
                                        onClick={() => {
                                          setCheckIsTackticAttended(true)
                                          set_is_survey_exit(true)
                                          dispatch(
                                            updateScorecardDetail(
                                              params.scorecardId,
                                              {
                                                activeUser: null,
                                              }
                                            )
                                          )
                                          setIsclearUpdateState(true)
                                          // dispatch(updateScorecardDetailClear())
                                          // history.push("/")
                                        }}
                                      >
                                        {is_survey_exit ? (
                                          ""
                                        ) : (
                                          <div
                                            className="d-flex justify-content-center"
                                            style={{
                                              alignItems: "center",
                                              display: "flex",
                                              justifyContent: "center",
                                              color: "#ffffff",
                                            }}
                                          >
                                            <span className="font-size-14">
                                              Save & Exit
                                            </span>
                                            <i className="mdi mdi-content-save fw-bold mx-2"></i>
                                          </div>
                                        )}

                                        {is_survey_exit ? (
                                          <Spinner
                                            type="border"
                                            size="sm"
                                            // style={{ color: "#64748B" }}
                                          />
                                        ) : null}
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              )}

                              {!evaluation.channelName &&
                                !checkIsSurveyInCompleted() && (
                                  <Row style={{ marginTop: "-10px" }}>
                                    <Col md="12">
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Close Assessment to enable
                                        {scorecardDetail &&
                                          scorecardDetail.scorecardVersion ===
                                          "Pro"
                                          ? " Generate Report"
                                          : " Close and Submit"}
                                      </span>
                                    </Col>
                                  </Row>
                                )}
                            </CardBody>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  )
}
export default TakeASurvey
