import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { withRouter, Link, useHistory, useParams } from "react-router-dom"
import firebase from "firebase/compat"

// users
import user1 from "../../../assets/images/users/avatar-2.jpg"
import ProfileIconOnTop from "../../../assets/images/UserProfileTopBarIcon/Profile.png"
import { getAccountHolderUserDetails, GetRoutes, handleCloseReportEditing, updateScorecardDetail, toggleBuyCreditMenu } from "store/actions"
// import billingHistoryIcon from "../../../assets/images/billing_history.svg"
// import buyCreditsIcon from "../../../assets/images/Buy credits.svg"

import billingHistoryIcon from "../../../assets/images/UserProfileTopBarIcon/Billing History (1).svg"
import buyCreditsIcon from "../../../assets/images/UserProfileTopBarIcon/Buy credits (1).svg"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [data, setData] = useState({})
  const [username, setusername] = useState("Admin")
  const [isProfileLogout, setIsProfileLogout] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  // const { accountUserDetails } = useSelector(state => ({
  //   accountUserDetails: state.AccountHolderUsersReducer.accountUserDetails,
  // }));

  // console.log(accountUserDetails)
  const { updatedScorecardDetail } = useSelector(state => ({
    updatedScorecardDetail: state.GetScorecardsListReducer.updatedScorecardDetail,

  }))
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])


  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdTokenResult().then(token => {
          setData(token.claims)
        }).catch(err => {

        })
      }
    })

    return () => {
      setData(null)
    }
  }, [])

  const handleProfileNavigation = (path) => {
    var is_report_editing_path = location.pathname.includes("edit/scorecard-report")
    var is_generate_pdf_path = location.pathname.includes("/generate-pdf")
    var is_take_a_survey_path = location.pathname.includes("/take-a-survey")

    if (is_report_editing_path || is_generate_pdf_path || is_take_a_survey_path) {
      dispatch(GetRoutes(path))
      dispatch(handleCloseReportEditing(true))
    } else if (path === "Buy Credit") {
      dispatch(toggleBuyCreditMenu(true))
      history.push("/dashboard")
    } else {
      history.push(path)
    }
  }

  const handleProfileLogout = () => {
    var is_report_editing_path = location.pathname.includes("edit/scorecard-report")
    var is_generate_pdf_path = location.pathname.includes("/generate-pdf")
    var is_take_a_survey_path = location.pathname.includes("/take-a-survey")

    if (is_report_editing_path || is_generate_pdf_path || is_take_a_survey_path) {
      setIsProfileLogout(true)
      dispatch(updateScorecardDetail(params.scorecardId, {
        activeUser: null
      }))
    } else {
      history.push("/logout")
    }
  }

  useEffect(() => {
    if (isProfileLogout && updatedScorecardDetail.status === 200) {
      // tog_backdrop_close_editing()
      history.push("/logout")
    }
  }, [updatedScorecardDetail])

  // console.log("billingHistoryImage...", ProfileIconOnTop)

  // console.log("USER DATA...", data)

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block text-left"
        style={{ textAlign: 'right' }}
      >
        <DropdownToggle
          className="btn header-item mt-3 text-left"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user mt-1"
            src={ProfileIconOnTop}
            alt="Header Avatar"
          />
          <span className="d-none d-sm-inline-block me-1 fw-bold fs-6 text-left me-3 mx-3">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-sm-inline-block mx-1" />
          {/* <br /> */}
          <div style={{ width: '150px', zIndex: 10, margin: '-15px 0 0 17px', position: 'relative' }}>
            {
              data.superAdmin ?
                <span className="font-size-11">Super Admin</span>
                :
                data.accountUser ?
                  <span className="font-size-11">Account User</span>
                  :
                  data.contentEditor ?
                    <span className="font-size-11" style={{ marginLeft: '4px' }}>Content Editor</span>
                    :
                    data.admin ?
                      <span className="font-size-11" >Account Owner</span>
                      :
                      null
            }
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">

          <Link
            onClick={() => handleProfileNavigation("/profile")}
            to="#"
            className="dropdown-item" >
            {/* <i className="far fa-user font-size-16 align-middle me-1" />
            <span>{props.t("Profile")}{" "}</span> */}
            <div className="d-flex align-items-center justify-content-start"
              style={{ marginLeft: '-3px' }}
            >
              <i className="bx bx-user font-size-22 me-1" />
              <span>{props.t("Profile")}</span>
            </div>
          </Link>

          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1"/>
            {props.t("My Wallet")}
          </DropdownItem> */}

          {/* <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1"/>
            {props.t("Settings")}
          </DropdownItem> */}

          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1"/>
            {props.t("Lock screen")}
          </DropdownItem> */}

          <div className="dropdown-divider" />
          <Link
            onClick={() => {
              setMenu(!menu)
              handleProfileNavigation("/dashboard")
            }}
            to="#"
            className="dropdown-item"
          >
            {/* {
              isProfileLogout ?
                <Spinner
                  size="sm"
                  color='primary'
                  style={{
                    width: "15px",
                    height: "15px",
                    margin: "2px 8px 2px 2px",
                    position: "relative",
                    top: 4,
                  }}
                />
                : */}
            {/* <i className="fas fa-border-all font-size-16 align-middle me-1" /> */}
            {/* } */}
            {/* <span>
              {props.t("Dashboard")}
            </span> */}
            <div className="d-flex align-items-center justify-content-start"
              style={{ marginLeft: '-3px' }}
            >
              <i className="bx bx-grid-alt font-size-22 me-1" />
              <span>
                {props.t("Dashboard")}
              </span>
            </div>
          </Link>

          {data && data.admin &&
            <div>
              <div className="dropdown-divider" />
              <Link
                onClick={() => {
                  setMenu(!menu)
                  handleProfileNavigation("Buy Credit")
                }}
                to="#"
                className="dropdown-item" >
                {/* <i className="fas fa-money-check-alt font-size-16 align-middle me-1" /> */}
                {/* <img src={buyCreditsIcon} style={{ width: '20px', height: 'auto' }}></img> */}
                {/* <i className="bx bx-dollar-circle font-size-22 align-middle ms-0"/>
                <span>{props.t(" Buy Credits")}{" "}</span> */}
                <div className="d-flex align-items-center justify-content-start"
                  style={{ marginLeft: '-3px' }}
                >
                  <i className="bx bx-dollar-circle font-size-22 me-1" />
                  <span>
                    {props.t(" Buy Credits")}
                  </span>
                </div>
              </Link>

              <div className="dropdown-divider" />

              <Link
                onClick={() => handleProfileNavigation("/Transactions")}
                to="#"
                className="dropdown-item ms-0" >
                {/* <i className="fas fa-file-invoice-dollar font-size-16 align-middle me-1" /> */}
                {/* <div className="align-item-center justify-content-center"> */}
                {/* <img src={billingHistoryIcon}></img> */}
                {/* <i className="bx bx-receipt font-size-22 align-middle m-0 p-0" />
                <span>{props.t(" Billing History")}{" "}</span> */}
                {/* </div> */}
                <div className="d-flex align-items-center justify-content-start"
                  style={{ marginLeft: '-3px' }}
                >
                  <i className="bx bx-receipt font-size-22 me-1" />
                  <span>{props.t(" Billing History")}</span>
                </div>
              </Link>
            </div>
          }
          <div className="dropdown-divider" />
          <Link
            onClick={handleProfileLogout}
            to="#"
            className="dropdown-item"
          >
            {
              isProfileLogout ?
                <Spinner
                  size="sm"
                  color='primary'
                  style={{
                    width: "15px",
                    height: "15px",
                    margin: "2px 8px 2px 2px",
                    position: "relative",
                    top: 4,
                  }}
                />
                :
                <i className="fas fa-arrow-right font-size-16 align-middle me-1 " />
            }
            <span>
              {props.t("Logout")}
            </span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
