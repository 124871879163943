import { call, put, takeEvery } from "redux-saga/effects"

import {ArrangeChannelSequenceSuccess , ArrangeChannelSequenceFail} from "./actions"

import {ARRANGE_CHANNEL_SEQUENCE} from "./actionTypes"

import {ArrangeChannelSequence} from "../../helpers/fakebackend_helper"

function* onArrangeChannelSequence ({payload: data}){
    try{
        const response = yield call(ArrangeChannelSequence, data)
        yield put(ArrangeChannelSequenceSuccess(response))

    }
    catch(error){
        yield put(ArrangeChannelSequenceFail)

    }


}

function* ArrangeChannelSequenceSaga(){
    yield takeEvery(ARRANGE_CHANNEL_SEQUENCE, onArrangeChannelSequence)

}
export default ArrangeChannelSequenceSaga