import MetaTags from 'react-meta-tags';  // Added Meta Tag npm Package
import { Container, Spinner } from "reactstrap";
import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { getChannelData } from "../../store/actions";

import "./mypage.scss"
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
// import DatatableTables from 'pages/Tables/DatatableTables';
import DashboardAccountOwner from "pages/AccountOwner/Dashboard"
import SuperAdminTable from 'pages/Tables/SuperAdminTable';
import DashboardContentEditor from 'pages/ContentEditor/Dashboard';
import firebase from 'firebase/compat';
import AccountUser from 'pages/AccountUser/AccountUser';
import AccoutnUserDashboard from 'pages/AccountUser/Dashboard';
import New_Dashboard from 'pages/AccountOwner/New Dashboard/New_Dashboard';

import { IsAccountOwner } from 'store/actions';



const NewPage = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({})

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                user.getIdTokenResult().then(token => {
                    setData(token.claims)
                    dispatch(IsAccountOwner(token.claims.admin))
                }).catch(err => {

                })
            }
        })

        return () => {
            setData(null)
        }
    }, [])

    return (
        <>
            {
                data.superAdmin && <SuperAdminTable />
            }
            {
                data.contentEditor && <DashboardContentEditor />
            }
            {
                data.admin && <New_Dashboard />
            }
            {
                data.accountUser && <AccoutnUserDashboard />
            }
        </>
    );
}

export default NewPage;
