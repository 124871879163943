import { PAYMENT_SUCCESS, PAYMENT_SUCCESS_CLEAR, PAYMENT_SUCCESS_FAIL, PAYMENT_SUCCESS_SUCCESS } from "./actionTypes";

export const paymentSuccessDetail = (id) => ({
    type: PAYMENT_SUCCESS,
    id
});

//get scorecard 
export const paymentSuccessDetailSuccess = data => ({
    type: PAYMENT_SUCCESS_SUCCESS,
    payload: data,
});

export const paymentSuccessDetailFail = error => ({
    type: PAYMENT_SUCCESS_FAIL,
    payload: error,
})
export const paymentSuccessDetailClear = () => ({
    type: PAYMENT_SUCCESS_CLEAR,
});