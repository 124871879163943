import { color, fontSize } from '@mui/system';
import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';  // Added Meta Tag npm Package
import { Grid } from "@mui/material";

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import RadarChart from 'pages/AccountUser/chart/chart';
import { REPORT, REPORT_PAGE } from '../config_v0_1/style.config';


import {
  Table,
  Container, Row, Col,
  Alert,
  Card, CardBody, CardTitle, FormGroup, Spinner, Form, Input, Button
} from "reactstrap";
import { Opacity } from '@mui/icons-material';
import Footer from 'components/VerticalLayout/Footer';
import { useSelector, useDispatch } from "react-redux"


import {
  // getReportTemplate,
  getScorecardReport
} from "store/actions"
import { getChannelScore } from 'commonFunctions/GetChannelScore/getChannelScore';

const ScoreGlance = () => {

  const dispatch = useDispatch()

  const { scorecardReport_data } = useSelector(state => ({
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
  }))

  //  console.log("SCORECARD REPORT DATA... ",scorecardReport_data)

  const channelClassifier = (data, level) => {
    if (scorecardReport_data.selectedChannels && data !== 100) {
      var channelName =

        scorecardReport_data && [...scorecardReport_data.selectedChannels]
          .sort((a, b) => getChannelScore(b) - getChannelScore(a))
          .map(channel => {
            var channelScore = getChannelScore(channel)

            if (channelScore <= data && channelScore > (data - level)) {
              return channel.channelName
            }

          })
        ||
        [...scorecardReport_data.selectedChannels]
          .sort((a, b) => getChannelScore(b) - getChannelScore(a))
          .map(channel => {
            var channelScore = getChannelScore(channel)

            if (channelScore <= data && channelScore > (data - level)) {
              return channel.channelName
            }

          })
          return channelName && channelName.filter(channel => channel !== undefined) || []
    } else {
      var channel_Name =

        scorecardReport_data && [...scorecardReport_data.selectedChannels]
          .sort((a, b) => getChannelScore(b) - getChannelScore(a))
          .map(channel => {
            var channel_Score = getChannelScore(channel)

            if (channel_Score <= data && channel_Score >= (data - level)) {
              return channel.channelName
            }
          })
        ||
        [...scorecardReport_data.selectedChannels]
          .sort((a, b) => getChannelScore(b) - getChannelScore(a))
          .map(channel => {
            var channel_Score = getChannelScore(channel)

            if (channel_Score <= data && channel_Score >= (data - level)) {
              return channel.channelName
            }

          })
          return channel_Name && channel_Name.filter(channel => channel !== undefined) || []
    }
  }



  return (
    <>
      <Col xs="12" className="p-0 m-0 ">
        <div style={REPORT_PAGE}>
          <Row style={REPORT_PAGE} >
            <Col xs="12" >
              <div style={{ 
                width: "100%",
                height: REPORT_PAGE.height,
                // border: "1px solid grey"
                // position: "absolute"
              }} >

                <Row style={{ width: "816px" }} className="p-0 m-0" >
                  <Col xs="12" >
                    <div style={{
                      // margin: "auto",
                      // width: "90%",
                      width: "100%",
                      height: "100px",
                      padding: "30px 40px 0 30px",
                      position: "relative",
                      textTransform: "uppercase"
                    }} >

                      <h1 style={{
                        fontSize: REPORT.FONT_SIZE.XLARGE,
                        fontFamily:REPORT.FONT_FACE.HEADING,
                        letterSpacing:'2px',
                        paddingBottom:'10px',
                        fontWeight: 600,
                        color: '#0D0F11',
                        // paddingBottom: "8px",
                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                      }}>
                        SCORES AT A GLANCE
                      </h1>
                    </div>
                  </Col>
                </Row>
                <Row style={{ width: "816px" }} className="p-0 m-0" >
                  <Col xs="6">
                    <div style={{
                      width: '100%',
                      height: '305px',
                      // border:'1px solid black',
                      marginLeft: '70px',
                      fontSize: REPORT.FONT_SIZE.SMALL,
                      padding: '10px 100px 0 20px'
                    }}>

                      <Table className='table table-bordered mb-0' style={{
                        height: '130px',
                        marginTop: 'auto',
                        border: "1px solid #0D0F11",
                        fontWeight: 500,
                        fontSize: REPORT.FONT_SIZE.SMALL
                      }}>
                        <thead border style={{
                          backgroundColor: '#0D0F11',
                          color: 'white'
                        }}>
                          <tr>
                            <th style={{
                              padding: '0',
                              paddingLeft: '5px',
                              border: "2px solid #0D0F11",
                              textAlign:'center'
                            }}>Channels</th>
                            <th style={{
                              padding: '0',
                              paddingLeft: '5px',
                              border: "2px solid #0D0F11",
                            }}>Scores</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            scorecardReport_data.id && [...scorecardReport_data.selectedChannels]
                              .sort((a, b) => getChannelScore(b) - getChannelScore(a))
                              .map(channel => (
                                <tr key={channel.channelId} style={{color:'#0D0F11'}}>
                                  <td scope="row" style={{
                                    padding: '3px',
                                    border: "2px solid #0D0F11",
                                  }}>{channel.channelName}</td>
                                  <td style={{
                                    padding: '3px',
                                    border: "2px solid #0D0F11",
                                    textAlign:'center'
                                  }}>{Math.round(getChannelScore(channel))}%</td>
                                </tr>
                              ))
                          }
                        </tbody>
                      </Table>

                    </div>
                  </Col>
                  <Col xs="6">
                    <div style={{
                      width: '100%',
                      height: '230px',
                      paddingLeft: '50px',
                      paddingTop: '5px',
                      fontSize: '12px'
                    }}>
                      <p style={{
                        color: '#0D0F11',
                        fontSize: REPORT.FONT_SIZE.LARGE,
                        fontFamily: REPORT.FONT_FACE.HEADING,
                        margin: '0 10px 13px 0',
                        fontWeight: 600
                      }}>LEVELS OF MATURITY</p>

                      {
                        channelClassifier(100, 20)[0] &&
                        <>
                          <p style={{
                            color: '#0D0F11',
                            // fontSize:'8px',
                            marginTop:'-5px',
                            fontWeight: 600,
                            margin: 0
                          }}>Mature</p>
                          <ul style={{
                            fontWeight: 500
                          }}>
                            {
                              channelClassifier(100, 20)[0] && channelClassifier(100, 20).map((channel, i) => (
                                <li key={i} style={{color:'#0D0F11'}}>{channel}</li>
                              ))
                            }
                          </ul>
                        </>
                      }
                      {
                        channelClassifier(79, 20)[0] &&
                        <>
                          <p style={{
                            color: '#0D0F11',
                            // fontSize:'8px',
                            marginTop:'-5px',
                            fontWeight: 600,
                            margin: 0
                          }}>Advanced</p>
                          <ul style={{
                            fontWeight: 500
                          }}>
                            {
                              channelClassifier(79, 20)[0] && channelClassifier(79, 20).map((channel, i) => (
                                <li key={i} style={{color:'#0D0F11'}}>{channel}</li>
                              ))
                            }
                          </ul>
                        </>
                      }
                      {
                        channelClassifier(59, 20)[0] &&
                        <>
                          <p style={{
                            color: '#0D0F11',
                            // fontSize:'8px',
                            marginTop:'-5px',
                            fontWeight: 600,
                            margin: 0
                          }}>Intermediate</p>
                          <ul style={{
                            fontWeight: 500
                          }}>
                            {
                              channelClassifier(59, 20)[0] && channelClassifier(59, 20).map((channel, i) => (
                                <li key={i} style={{color:'#0D0F11'}}>{channel}</li>
                              ))
                            }
                          </ul>
                        </>
                      }
                      {
                        channelClassifier(39,40)[0] &&
                        <>
                          <p style={{
                            color: '#0D0F11',
                            // fontSize:'8px',
                            marginTop:'-5px',
                            fontWeight: 600,
                            margin: 0
                          }}>Early & Beginner</p>
                          <ul style={{
                            fontWeight: 500
                          }}>
                            {
                              channelClassifier(39, 40)[0] && channelClassifier(39, 40).map((channel, i) => (
                                <li key={i} style={{color:'#0D0F11'}}>{channel}</li>
                              ))
                            }
                          </ul>
                        </>
                      }
                    </div>
                  </Col>
                  {/* To adding space between the Row of Table and Spider Graph Div*/}
                </Row>
                  {/* <Col xs='12' style={{ height: '79px', border:'1px solid black' }}></Col> */}
                <Row style={{ width: "816px" }}>
                  <Col xs="12" style={{
                    // border: '1px solid black'
                  }} >
                    <div style={{
                      // width: '816px',
                      height: '510px',
                      // backgroundColor: '#f0e0c0',
                      marginTop: '140px',
                      // transform: "scale(1)"
                      // paddingTop: '1px',
                      // border: '1px solid black'
                    }}>
                      <div style={{
                        width: "700px",
                        height: "fit-content",
                        margin: "15px auto 0 auto",
                        padding:0,
                        // border:'1px solid black'
                      }} >
                        {scorecardReport_data.selectedChannels &&
                          <RadarChart DATA={scorecardReport_data} />

                        }
                      </div>
                    </div>
                  </Col>

                </Row>
              </div>
            </Col>
          </Row>

          <Col xs="12" style={{
            // border: '1px solid black'
            position: "absolute",
            bottom: 0,
            zIndex: 100,
          }}>
            <div style={{
               width: '100%',
               fontSize: REPORT.FONT_SIZE.MEDIUM,
               padding: '5px 5px 5px 40px',
               backgroundColor: REPORT.COLOR.FOOTER,
               fontFamily:REPORT.FONT_FACE.HEADING,
               letterSpacing:'2px',
               opacity: 0.9,
               fontWeight: 600,
               color: 'white'
            }}>
              SCORES AT A GLANCE</div>
          </Col>

        </div>
      </Col>
      
    </>
  );
}

export default ScoreGlance;