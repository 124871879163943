import { takeEvery, put, call,all,fork  } from "redux-saga/effects";

// Login Redux States
import {
    GET_SCORECARDS_LIST,
    CREATE_SCORECARD,
    GET_SCORECARDS_LIST_FOR_ACCOUNT_USER,
    GET_SCORECARD_DETAIL,
    UPDATE_SCORECARD_DETAIL,
    GET_THREE_SCORECARDS,
} from "./actionTypes"
import {
    getScorecardsListSuccess,
    getScorecardsListFail,
    createScorecardSuccess,
    createScorecardFail,
    getScorecardsListSuccessForAccountUser,
    getScorecardsListFailForAccountUser,
    getScorecardDetailSuccess,
    getScorecardDetailFail,
    updateScorecardDetailSuccess,
    updateScorecardDetailFail,

    getListOfThreeScorecardsSuccess,
    getListOfThreeScorecardsFail
} from "./actions"

//Axios get call
import { getScorecardList, createScorecard, getScorecardListOfAccountUser, getScorecardDetail, updateScorecardDetail, GetThreeScorecards } from "../../helpers/fakebackend_helper";

function* fetchScorecardsList({id}) {
  try {
    const response = yield call(getScorecardList, id)
    yield put(getScorecardsListSuccess(response))
  } catch (error) {
    yield put(getScorecardsListFail(error))

  }
}

function* fetchThreeScorecards({id}) {
  try {
    const response = yield call(GetThreeScorecards, id)
    yield put(getListOfThreeScorecardsSuccess(response))
  } catch (error) {
    yield put(getListOfThreeScorecardsFail(error))

  }
}

function* fetchScorecardsDetail({id}) {
  try {
    const response = yield call(getScorecardDetail, id)
    yield put(getScorecardDetailSuccess(response))
  } catch (error) {
    yield put(getScorecardDetailFail(error))

  }
}

function* fetchScorecardsListForAccountUser({id}) {
  try {
    const response = yield call(getScorecardListOfAccountUser, id)
    yield put(getScorecardsListSuccessForAccountUser(response))
  } catch (error) {
    yield put(getScorecardsListFailForAccountUser(error))

  }
}

// Create scorecard:
function* sendCreateScorecard({payload: data}) {
  try {
    const response = yield call(createScorecard, data)
    yield put(createScorecardSuccess(response))
  } catch (error) {
    yield put(createScorecardFail(error))

  }
}

// updateScorecard
function* sendUpdateScorecard({id, payload: data}) {
  try {
    const response = yield call(updateScorecardDetail, id, data)
    yield put(updateScorecardDetailSuccess(response))
  } catch (error) {
    yield put(updateScorecardDetailFail(error))

  }
}

export function* watchGetScorecardsList() {
  yield takeEvery(GET_SCORECARDS_LIST, fetchScorecardsList);
  yield takeEvery(GET_SCORECARD_DETAIL, fetchScorecardsDetail);
  yield takeEvery(CREATE_SCORECARD, sendCreateScorecard);
  yield takeEvery(GET_SCORECARDS_LIST_FOR_ACCOUNT_USER, fetchScorecardsListForAccountUser);
  yield takeEvery(UPDATE_SCORECARD_DETAIL, sendUpdateScorecard);

  yield takeEvery(GET_THREE_SCORECARDS, fetchThreeScorecards);
}
                                      
function* scorecardsListSaga() {
  yield all([fork(watchGetScorecardsList)]);
}
                                      
export default scorecardsListSaga;
