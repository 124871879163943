import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"

import { useHistory, useParams, withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import {
  handleUnsavedChanges,
  GetRoutes,
  handleCloseReportEditing,
  getAccountDetails as onGetAccountDetails,
  ClearAccountOwnerDetails,
} from "store/actions"

const SidebarContentForAccountOwner = props => {

  const params = useParams()
  const ref = useRef();
  const history = useHistory()
  const dispatch = useDispatch()
  // const [accountOwnerType, setAccountOwnerType] = useState(null)
  // Use ComponentDidMount and ComponentDidUpdate method smultaniously
  useEffect(() => {
    const pathName = props.location.pathname




    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  const { isDataEdited, isThereChanges, getRoutes, accountDetails, scorecardDetail, isCoachingStartedForUpdateSelfReportScorecard } =
    useSelector(state => ({
      isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
      isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
      getRoutes: state.ScorecardReport_Reducer.getRoutes,
      accountDetails: state.SuperAdmin.accountDetails,
      scorecardDetail: state.GetScorecardsListReducer.scorecardDetail,
      isCoachingStartedForUpdateSelfReportScorecard: state.CoachingPageReducer.isCoachingStartedForUpdateSelfReportScorecard,
    }))

  const handleSidebarNavigation = (goto_path) => {
    // var is_report_editing_path = location.pathname.includes("edit/scorecard-report")
    var is_generate_pdf_path = location.pathname.includes("/generate-pdf")

    if (is_generate_pdf_path) {
      dispatch(handleCloseReportEditing(true))
    } else {
      history.push(goto_path)
    }
  }

  // useEffect(() => {
  //   if (accountDetails && accountDetails.scorecardVersions) {
  //     const { scorecardVersions } = accountDetails;

  //     if (scorecardVersions.proScorecard === true && scorecardVersions.selfReportScorecard === true) {
  //       setAccountOwnerType("Both")
  //     } else if (scorecardVersions.proScorecard === true && scorecardVersions.selfReportScorecard === false) {
  //       setAccountOwnerType("Pro")
  //     } else if (scorecardVersions.proScorecard === false && scorecardVersions.selfReportScorecard === true) {
  //       setAccountOwnerType("Self Report")
  //     }
  //   } else {
  //     setAccountOwnerType("Old")
  //   }
  // }, [accountDetails])

  

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled tour-guide-sidebar-self-report-account-owner" id="side-menu">
            <li>
              <Link
                to={
                  location.pathname.includes("/generate-pdf")
                    ? {}
                    : "/dashboard"
                }
                onClick={() => {
                  dispatch(GetRoutes("/dashboard"))
                  handleSidebarNavigation("/dashboard")
                }}
              >
                <div className="d-flex align-items-center">
                  <div>
                    <i className="bx bx-home-circle"></i>
                  </div>
                  <div>
                    <span>{props.t("Home")}</span>
                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link
                to={
                  location.pathname.includes("/generate-pdf")
                    ? {}
                    : "/create/user"
                }
                onClick={() => {
                  dispatch(GetRoutes("/create/user"))
                  handleSidebarNavigation("/create/user")
                }}
              >


                <div className="d-flex align-items-center">
                  <div>
                    <i className="dripicons-plus"></i>
                  </div>
                  <div>
                    <span>{props.t("Create New User")}</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link
                to={
                  location.pathname.includes("/generate-pdf")
                    ? {}
                    : "/create/scorecard"
                }
                onClick={() => {
                  dispatch(GetRoutes("/create/scorecard"))
                  handleSidebarNavigation("/create/scorecard")
                }}
              >
                <div className="d-flex align-items-center">
                  <div>
                    <i className="dripicons-plus"></i>
                  </div>
                  <div>
                    <span>{props.t("Create New Pro Scorecard")}</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link
                to={
                  location.pathname.includes("/generate-pdf")
                    ? {}
                    : "/create/self-report/scorecard"
                }
                onClick={() => {
                  dispatch(GetRoutes("/create/self-report/scorecard"))
                  handleSidebarNavigation("/create/self-report/scorecard")
                }}
              >
                <div className="d-flex align-items-center">
                  <div>
                    <i className="dripicons-plus"></i>
                  </div>
                  <div>
                    <span>{props.t("Create New Self Report Scorecard")}</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link
                to={
                  location.pathname.includes("/generate-pdf")
                    ? {}
                    : "/view/scorecards"
                }
                onClick={() => handleSidebarNavigation("/view/scorecards")}
              >
                <div className="d-flex align-items-center">
                  <div>
                    <i className="mdi mdi-eye-outline"></i>
                  </div>
                  <div>
                    <span>{props.t("View Scorecards")}</span>
                  </div>
                </div>
              </Link>
            </li>
            {/* <li>
                <Link
                  to={
                    location.pathname.includes("/generate-pdf")
                      ? {}
                      : "/Transactions"
                  }
                  onClick={() => {
                    dispatch(GetRoutes("/Transactions"))
                    handleSidebarNavigation("/Transactions")
                  }}
                >
                  <i
                    className="fas fa-money-check-alt"
                    style={{ fontSize: "17px" }}
                  ></i>
                  <span>{props.t("Transactions")}</span>
                </Link>
              </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContentForAccountOwner.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContentForAccountOwner))
