import { color, fontSize } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from "react-html-parser"


import {
  Table,
  Container, Row, Col,
  Alert,
  Card, CardBody, CardTitle, FormGroup, Spinner, Form, Input, Button, Modal
} from "reactstrap";

import "../config/style.config.css"
import { REPORT, REPORT_PAGE } from '../config/style.config';


const ContentQuick = (props) => {
  const PROPS = props;
  const { Content_quick_wins, ChannelData } = PROPS

  return (
    <>
      <Col xs="12" className="print-content p-0 m-0 ">
        <div style={{ ...REPORT_PAGE }}>
          <Row className='g-0 m-0 p-0'>
            <Col xs="7" >
              <div style={{
                width: "92%",
                height: REPORT_PAGE.height,
                overflow:'hidden'
                // border: "1px solid grey"
              }} >

                <Row className='g-0 m-0 p-0'>
                  <Col xs="12" >

                    <div style={{
                      margin: "auto",
                      width: "100%",
                      height: "fit-content",
                      // padding: "30px 0 0 30px",
                      padding: "30px 0 10px 40px",
                      position: "relative",
                      textTransform: "uppercase",
                      // marginBottom: '10px',
                    }} >

                      <h2 style={{
                        fontFamily: REPORT.FONT_FACE.HEADING,
                        fontSize: REPORT.FONT_SIZE.XLARGE,
                        fontWeight: 700,
                        // paddingBottom: "8px",
                        paddingBottom: "10px",
                        letterSpacing:'2px',
                        color: '#0D0F11',
                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                      }}>
                        {`${ChannelData && ChannelData.channelName} Quick Wins`.toUpperCase()}
                      </h2>

                    </div>

                  </Col>
                  <Col xs="12" >

                    <div style={{
                      width: "100%",
                      height: "920px",
                      // border: "1px solid grey",  
                      overflow: "hidden",
                      // padding: "4px",
                      padding: "0 0 0 40px",
                      marginTop: '-20px',
                      fontSize: REPORT.FONT_SIZE.XSMALL,
                      lineHeight:'1.15',
                    }}
                    className="--content-quick-wins --container"
                    >
                      <div
                        style={{
                          width: "96%",
                          padding: "4px",
                          marginTop: '10px',
                          height: "860px",
                          // border: "1px solid red",
                          fontSize: REPORT.FONT_SIZE.XSMALL,
                          fontFamily: REPORT.FONT_FACE.PARAGRAPH,
                          overflow:'hidden',
                          color:'#0D0F11'
                          // margin:'15px auto 4px 20px',
                        }}
                        className="SavedContentQuickWins"
                      >
                        {ReactHtmlParser(Content_quick_wins && Content_quick_wins.contents)}
                      </div>
                    </div>

                  </Col>
                </Row>
              </div>
            </Col>

            <Col xs="5" style={{ marginTop: '130px' }}>
              <div style={{
                position: "absolute",
                top: 0,
                right: '20px',
                padding: 6,
                width: '80px'
              }}>

              </div>
              <div
                className='ImageUploadContainer'
                style={{

                  // width: "85%",
                  width: "80%",
                  height: "408px",
                  // border: "1px solid grey",
                  backgroundImage: Content_quick_wins ? "url(" + Content_quick_wins.bgImage[0] + ")" : " ",
                  // backgroundColor: "#f0f0f0",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  // margin: '0px 10px 20px 18px',
                  // margin: '0px 10px 20px 35px',
                  margin: '0px 10px 20px 0',

                }} >
                <div className='container--toggle'
                  style={{
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "rgba(0, 0, 0, .3)"

                  }}
                >
                  <span

                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      // border: "1px solid red",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "all 1s ease-in-out"
                    }}
                  >
                    <input
                      type="file"
                      id="upload-button"
                      name="Image_URL"
                      accept="image/*"
                      style={{ display: "none" }}
                    // onChange={handleChange}
                    />
                  </span>
                </div>
              </div>
              <div
                className='ImageUploadContainer'
                style={{

                  // width: "85%",
                  width: "80%",
                  height: "408px",
                  // border: "1px solid grey",
                  backgroundImage: Content_quick_wins ? "url(" + Content_quick_wins.bgImage[1] + ")" : " ",
                  // backgroundColor: "#f0f0f0",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  // margin: '40px 10px 20px 35px',
                  margin: '40px 10px 20px 0',

                }} >
                <div className='container--toggle'
                  style={{
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "rgba(0, 0, 0, .3)"

                  }}
                >

                  <span

                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      // border: "1px solid red",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "all 1s ease-in-out"
                    }}
                  >
                    <input
                      type="file"
                      id="upload-button"
                      name="Image_URL"
                      accept="image/*"
                      style={{ display: "none" }}
                    // onChange={handleChange}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xs="12" style={{
              // border: '1px solid black'
              position: "absolute",
              bottom: 0,
              zIndex: 100,
            }}>
              <div style={{
                width: '100%px',
                fontSize: REPORT.FONT_SIZE.MEDIUM,
                fontFamily: REPORT.FONT_FACE.HEADING,
                padding: '5px 5px 5px 40px',
                backgroundColor: '#F78978',
                opacity: 0.9,
                fontWeight: 600,
                letterSpacing:'2px',
                color: 'white'
              }}>{`${ChannelData && ChannelData.channelName} - Quick Wins`.toUpperCase()}</div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
}

export default ContentQuick;