import { takeEvery, put, call,all,fork  } from "redux-saga/effects";


import {GET_CONTENT_EDITOR_TABLE} from "./actionTypes";

import {getContentEditorDataSuccess, getContentEditorDataFail} from "./actions"
import {getContentEditorData} from "../../helpers/fakebackend_helper"

function* fetchDemoData() {
    try {
      const response = yield call(getContentEditorData)
      yield put(getContentEditorDataSuccess(response))
    } catch (error) {
      yield put( getContentEditorDataFail(error))
    }
  }
                                        
  export function* watchFetchDemoData() {
    yield takeEvery(GET_CONTENT_EDITOR_TABLE, fetchDemoData);
  }
                                        
  function* EditorList() {
    yield all([fork(watchFetchDemoData)]);
  }
                                        
  export default EditorList;
  