

import {
    ADD_ACCOUNT_HOLDER,
    ADD_ACCOUNT_HOLDER_SUCCESS,
    ADD_ACCOUNT_HOLDER_FAIL,
    CLEAR_ACCOUNT_HOLDER,

    // DELETE_ACCOUNT_HOLDER,
    // DELETE_ACCOUNT_HOLDER_SUCCESS,
    // DELETE_ACCOUNT_HOLDER_FAIL,

    
} from "./actionTypes"

export const addAccountHolder = (data) => {
    return {
      type: ADD_ACCOUNT_HOLDER,
      payload: data
      
    }
  }
  
  export const addAccountHolderSuccess = (data) => {
    return {
      type: ADD_ACCOUNT_HOLDER_SUCCESS,
      payload: data
    }
  }
  
  export const addAccountHolderFail = (error) => {
    return {
      type: ADD_ACCOUNT_HOLDER_FAIL,
      payload: error
    }
  }

  export const clearAccountHolder = () => {
    return{
      type: CLEAR_ACCOUNT_HOLDER
    }
  }


  // export const deleteAccountHolder = (user) => {
  //   return {
  //     type: DELETE_ACCOUNT_HOLDER,
  //     payload: userForgetPasswordSuccess
      
  //   }
  // }
  
  // export const deleteAccountHolderSuccess = (user) => {
  //   return {
  //     type: DELETE_ACCOUNT_HOLDER_SUCCESS,
  //     payload: user
      
  //   }
  // }
  
  // export const deleteAccountHolderFail = (error) => {
  //   return {
  //     type: DELETE_ACCOUNT_HOLDER_FAIL,
  //     payload: error
  //   }
  // }
