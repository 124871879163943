import {
    GET_CHANNEL_DETAILS,
    GET_CHANNEL_DETAILS_SUCCESS,
    GET_CHANNEL_DETAILS_FAIL,

    UPDATE_CHANNEL_DETAILS,
    UPDATE_CHANNEL_DETAILS_SUCCESS,
    UPDATE_CHANNEL_DETAILS_FAIL,
    CLEAR_CHANNEL_DETAILS,
    CLEAR_UPDATE_CHANNEL_DETAILS
} from "./actionTypes"

export const getChannelDetails = (id) => ({
    type:GET_CHANNEL_DETAILS,
    id,
});

export const getChannelDetailsSuccess = (data) => ({
    type:GET_CHANNEL_DETAILS_SUCCESS,
    payload:data,
});

export const getChannelDetailsFail = () => ({
    type:GET_CHANNEL_DETAILS_FAIL
});

export const updateChannelDetails = (id, data) =>({
    type:UPDATE_CHANNEL_DETAILS,
    id,
    payload:data
});

export const updateChannelDetailsSuccess = (data) => ({
    type:UPDATE_CHANNEL_DETAILS_SUCCESS,
    payload:data,
});

export const updateChannelDetailsFail = (error) => ({
    type:UPDATE_CHANNEL_DETAILS_FAIL,
    payload:error
});

export const clearChannelDetails = () => ({
    type:CLEAR_CHANNEL_DETAILS
});

export const clearUpdateChannelDetails = () => ({
    type: CLEAR_UPDATE_CHANNEL_DETAILS
});