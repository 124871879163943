import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  CLEAR_FORGET_PASSWORD_ERROR,
  CLEAR_FORGET_PASSWORD_SUCCESS_MESSAGE
} from "./actionTypes"

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history },
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}

export const clearForgetPasswordError = () => {
  return {
    type: CLEAR_FORGET_PASSWORD_ERROR
  }
}

export const clearForgetPasswordSuccessMessage = () => {
  return {
    type: CLEAR_FORGET_PASSWORD_SUCCESS_MESSAGE
  }
}