import React, { useEffect, useRef, useState } from "react"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import ReactHtmlParser from "react-html-parser"
import {
  handleUnsavedChanges,
  handleChangesHappend,
  getAllInstructionData,
} from "../../../../../../store/actions"

import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
  Modal,
  Tooltip,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import "../../../config/style.config.css"
import CropEasy from "pages/Crop/CropEasy"

import { REPORT, REPORT_PAGE } from "../../../config/style.config"
import { useParams } from "react-router-dom"
import { CLOUD_STORAGE } from "config/cloudStorage.config"

const ExecutiveSummary = props => {
  const dispatch = useDispatch()

  const params = useParams()
  const PROPS = props
  const {
    AddPage,
    RemovePage,
    IsPageAdded,
    HandlePageAdded,
    DATA_LENGTH,
    Index,
    GetReport,
    EXECUTIVE_SUMMARY,
    SAVE_EXECUTIVE_SUMMARY,
    SET_IMAGE_URL,
    HandleSaveBtn,
    IMAGE_URL,
    IS_SAVED,
    SET_IS_SAVED,
    DATA_AVAIL,
    REPORT_UPDATE
  } = PROPS

  const [isPageAdded, setIsPageAdded] = useState(false)
  const [HeightWidth, setHeightWidth] = useState({})
  const [openCrop, setOpenCrop] = useState(false)
  const [file, setFile] = useState(null)
  const [image_url, setImage_Url] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [croppedImage, setCroppedImage] = useState(null)
  const [IsImage, setIsImage] = useState(false)

  // Executive summary data:
  const [executiveData, setExecutiveData] = useState([])
  // const [IS_SAVED, SET_IS_SAVED] = useState(false)

  const [editExecutiveSummary, setEditExecutiveSummary] = useState({})
  const [saveExecutiveSummary, setSaveExecutiveSummary] = useState(null)
  //
  const [modal_center, setmodal_center] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)

  const [isImageThere, setImageThere] = useState(false)
  const [IsInEditMode, setIsInEditMode] = useState(false)

  // state for tooltip functionality over save button
  const [tooltipForSaveBtn, setTooltipForSaveBtn] = useState(false)
  const [tooltipForAddPageBtn, setTooltipForAddPageBtn] = useState(false)
  const [tooltipForApplyContentBtn, setTooltipForApplyContentBtn] =  useState(false)
  
  const [tooltipForResetPageBtn, setTooltipForResetPageBtn] = useState(false)
  const [tooltipForImageUpload , setTooltipForImageUpload]  =useState(false)
  const [tooltipForRemovePage , setTooltipForRemovePage]  =useState(false)
  const [tooltipForEditBtn , setTooltipForEditBtn] = useState(false)
  const [tooltipData, setTooltipData] = useState({})

  const { isDataEdited, isThereChanges, getInstructionData } = useSelector(
    state => ({
      getInstructionData: state.instructionPanelReducer.getInstructionData,
      isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
      isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    })
  )

  //   console.log("Executive summary", EXECUTIVE_SUMMARY.contents)

  useEffect(() => {
    if (EXECUTIVE_SUMMARY.contents) {
      setEditExecutiveSummary(EXECUTIVE_SUMMARY.contents)
      setSaveExecutiveSummary(EXECUTIVE_SUMMARY.contents)
      setExecutiveData(EXECUTIVE_SUMMARY.contents)
      if (DATA_LENGTH === 2 && Index === 0) {
        setIsPageAdded(true)
      }
    }
  }, [DATA_AVAIL])

  useEffect(() => {
    if (croppedImage) {
      SET_IMAGE_URL(croppedImage)
    }
  }, [croppedImage])

  useEffect(() => {
    if (IsImage) {
      setIsModalOpen(false)
      setImageThere(false)
      setIsImage(false)
    }
  }, [IMAGE_URL])


  useEffect(() => {
   if(REPORT_UPDATE.status === 200) {
    setTooltipForSaveBtn(false)
    setTooltipForAddPageBtn(false)
    setTooltipForApplyContentBtn(false)
    setTooltipForResetPageBtn(false)
    setTooltipForImageUpload(false)
    setTooltipForEditBtn(false)
    setTooltipForRemovePage(false)

   }
   else if(REPORT_UPDATE === 403) {
    setTooltipForSaveBtn(false)
    setTooltipForAddPageBtn(false)
    setTooltipForApplyContentBtn(false)
    setTooltipForResetPageBtn(false)
    setTooltipForImageUpload(false)
    setTooltipForEditBtn(false)
    setTooltipForRemovePage(false)

   }
  })

  // Image function.
  const handleChange = e => {
    const file = e.target.files[0]
    if (file) {
      var u = URL.createObjectURL(file)
      var img = new Image()
      img.onload = function () {
        var IMAGE_HEIGHT = img.height
        var IMAGE_WIDTH = img.width

        setHeightWidth({
          myheight: IMAGE_HEIGHT,
          mywidth: IMAGE_WIDTH,
        })
      }
      img.src = u
      setFile(file)
      setImage_Url(u)
      // SET_IMAGE_URL(u)
      setOpenCrop(true)
      setTimeout(() => setIsImage(true), 2000)
      dispatch(handleChangesHappend(true))
      e.target.value = ""
    }
  }

  // Apply button
  const listToPara = e => {
    var executive_data = ""
    executiveData.forEach(data => {
      executive_data += data
    })
    var executiveSummaaryContent = document.querySelectorAll(
      ".executiveSummaaryContent"
    )
    executiveSummaaryContent.forEach(checkBox => {
      checkBox.checked = false
    })
    setEditExecutiveSummary(executive_data)
    dispatch(handleChangesHappend(true))
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  // Save button handler
  const HandleSaveButton = () => {
    SET_IS_SAVED(true)
    if (saveExecutiveSummary) {
      setSaveExecutiveSummary(null)
      SET_IS_SAVED(false)
    } else {
      HandleSaveBtn()
    }
  }

  useEffect(() => {
    if (!Object.keys(getInstructionData).includes("infoForAccountUser")) {
      dispatch(getAllInstructionData())
    }
  }, [dispatch])

  useEffect(() => {
    if (getInstructionData.infoForAccountUser !== undefined) {
      var data = {
        infoForAddPageBtn: getInstructionData.infoForAccountUser
          .executiveSummary.infoForAddPageBtn
          ? getInstructionData.infoForAccountUser.executiveSummary
              .infoForAddPageBtn
          : "",
        infoForSaveBtn: getInstructionData.infoForAccountUser.executiveSummary
          .infoForSaveBtn
          ? getInstructionData.infoForAccountUser.executiveSummary
              .infoForSaveBtn
          : "",
        infoForApplyBtn: getInstructionData.infoForAccountUser.executiveSummary
          .infoForApplyBtn
          ? getInstructionData.infoForAccountUser.executiveSummary
              .infoForApplyBtn
          : "",
        infoForResetPageBtn: getInstructionData.infoForAccountUser
          .executiveSummary.infoForResetPageBtn
          ? getInstructionData.infoForAccountUser.executiveSummary
              .infoForResetPageBtn
          : "",
        infoForUploadImageBtn: getInstructionData.infoForAccountUser.executiveSummary.infoForUploadImageBtn  ? 
        getInstructionData.infoForAccountUser.executiveSummary.infoForUploadImageBtn : "",
        infoForRemovePageBtn:getInstructionData.infoForAccountUser.executiveSummary.infoForRemovePageBtn ? 
        getInstructionData.infoForAccountUser.executiveSummary.infoForRemovePageBtn : "",
        infoForEditBtn : getInstructionData.infoForAccountUser.executiveSummary.infoForEditBtn ? 
        getInstructionData.infoForAccountUser.executiveSummary.infoForEditBtn : " ",

      }
      setTooltipData({ ...data })
    }
  }, [getInstructionData])

  return (
    <>
      <Row>
        <Row>
          <Col md="3" className="mb-2">
            <Button
              id="TooltipForResetPageBtn"
              className="btn btn-primary"
              color="primary"
              onClick={() => {
                setEditExecutiveSummary(null)
                setSaveExecutiveSummary(null)
                setExecutiveData([""])
                SAVE_EXECUTIVE_SUMMARY("", Index)
                dispatch(handleChangesHappend(true))
              }}
            >
              Reset Page {Index === 0 ? "1" : "2"}
            </Button>
            <Tooltip
              style={{
                backgroundColor: "#FEF3C7",
                color: "#0D0F11",
                zIndex: 1,
              }}
              placement="right"
              isOpen={tooltipForResetPageBtn}
              target="TooltipForResetPageBtn"
              toggle={() => {
                setTooltipForResetPageBtn(!tooltipForResetPageBtn)
              }}
            >
              {tooltipData.infoForResetPageBtn}
            </Tooltip>
          </Col>
          <Col md="9"></Col>
        </Row>
        <Col md="8">
          <div style={REPORT_PAGE}>
            <Row className="g-0">
              <Col md="5">
                <div
                  className="ImageUploadContainer"
                  style={{
                    height: REPORT_PAGE.height,
                    width: "100%",
                    backgroundImage:
                      !openCrop && IMAGE_URL
                        ? "url(" + IMAGE_URL + ")"
                        : "url(" + EXECUTIVE_SUMMARY.bgImage + ")",
                    backgroundColor: "#f0f0f0",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  <div
                    className="container--toggle ContainerAnimation--FadeIn "
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, .3)",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        // border: "1px solid red",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "all 1s ease-in-out",
                      }}
                    >
                      <input
                        type="file"
                        id="upload-button"
                        name="Image_URL"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleChange}
                      />
                      {Index === 0 ? (
                        <>
                          <Button
                          size="sm"
                          color="primary"
                          id = "tooltipForUploadImage"
                          style={{
                            transform: "scale(1)",
                            width: "110px",
                            height: "28px",
                          }}
                          onClick={() => {
                            setIsModalOpen(true)
                            // if (!IsInEditMode){
                            setSaveExecutiveSummary(null)
                            // setIsInEditMode(true)
                            // }
                          }}
                        >
                          Upload Image
                        </Button>
                        <Tooltip
              style={{
                backgroundColor: "#FEF3C7",
                color: "#0D0F11",
                zIndex: 1,
              }}
              placement="right"
              isOpen={tooltipForImageUpload}
              target="tooltipForUploadImage"
              toggle={() => {
                setTooltipForImageUpload(!tooltipForImageUpload)
              }}
            >
              {tooltipData.infoForUploadImageBtn}
            </Tooltip>
                       
                        </>
                      
                      ) : (
                        <p></p>
                      )}
                    </span>
                  </div>
                </div>
              </Col>
              <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                style={{ marginTop: "250px", width: "650px" }}
              >
                {!isImageThere ? (
                  <>
                    {/* <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                // width:'100%',
                                                marginLeft: "5px",
                                            }}
                                            >
                                                <h5 style={{ margin: '15px 0 10px 140px' }}>Upload background image</h5>
                                                <i className="bx bx-x" style={{ fontSize: '25px', cursor: 'pointer', width: '40px', margin: '14px 0 10px 80px' }}
                                                    onClick={() => setIsModalOpen(false)}
                                                ></i>

                                            </div> */}

                    {/* <Button
                                                color="primary"
                                                onClick={() => {
                                                    var inputFile = document.querySelector("#upload-button")
                                                    inputFile.click()
                                                }}
                                                style={{

                                                    alignItems: "center",
                                                    margin: "0 0 20px 180px",
                                                    width: '150px'
                                                }}
                                            >
                                                <i
                                                    className="mdi mdi-image"
                                                    style={{ marginRight: "5px", fontSize: "15px" }}
                                                ></i>
                                                Choose Image
                                            </Button>{" "} */}

                    <Row className="modal-body">
                      <Col
                        md="12"
                        className="text-center font-size-18 mb-3"
                        style={{
                          color: "#0D0F11",
                          fontWeight: 600,
                        }}
                      >
                        Upload New Image
                        <button
                          style={{
                            marginTop: "-10px",
                            marginLeft: "-20px",
                          }}
                          type="button"
                          onClick={() => {
                            setIsModalOpen(false)
                          }}
                          className="close text-center font-size-14"
                          data-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </Col>
                      <Col md="4"></Col>
                      <Col md="4">
                        <Button
                          size="sm"
                          block
                          onClick={() => {
                            var inputFile =
                              document.querySelector("#upload-button")
                            inputFile.click()
                          }}
                          style={{
                            background: "#0D0F11",
                            border: "#0D0F11",
                          }}
                        >
                          <div className="d-flex justify-content-center">
                            <i className="mdi mdi-image fw-bold mx-2 font-size-14"></i>
                            <span className="font-size-14">Choose Image</span>
                          </div>
                        </Button>
                      </Col>
                      <Col md="4"></Col>
                    </Row>
                    <div
                      style={{
                        justifyContent: "center",
                        margin: "auto 10px auto 10px",
                      }}
                    >
                      {openCrop && (
                        <CropEasy
                          image_url={image_url}
                          imageName={file.name}
                          image_storagePath={
                            CLOUD_STORAGE.EDIT_SCORECARD_REPORT
                              .EXECUTIVE_SUMMARY.IMAGE
                          }
                          setOpenCrop={setOpenCrop}
                          setImageUrl={setCroppedImage}
                          setFile={setFile}
                          setImageThere={setImageThere}
                          ImageHeightWidth={HeightWidth}
                          ImageType="EXECUTIVE"
                        />
                      )}
                    </div>
                  </>
                ) : (
                  // <Col md="8" style={{
                  //     textAlign: 'center',
                  //     margin: '40px 0 40px 90px'
                  // }}>
                  //     <Spinner />
                  //     <br />
                  //     <br />
                  //     <p
                  //         style={{
                  //             color: '#292929',
                  //             fontSize: REPORT.FONT_SIZE.LARGE
                  //         }}
                  //     >
                  //         Please wait.  Image is being uploaded.</p>
                  // </Col>
                  <Row className="modal-body text-center">
                    <Col md="12" className="my-3 fw-bold font-size-18">
                      <p
                        style={{
                          color: "#0D0F11",
                        }}
                      >
                        Please wait. Image is being uploaded.
                      </p>
                    </Col>
                    <Col md="12">
                      <Spinner style={{ color: "#0D0F11" }} />
                    </Col>
                  </Row>
                )}
              </Modal>
              <Col md="7">
                <div
                  style={{
                    width: "100%",
                    height: REPORT_PAGE.height,
                    overflow: "hidden",
                  }}
                >
                  <Row>
                    <Col md="12">
                      <div
                        style={{
                          // margin: "auto",
                          width: "100%",
                          height: "100px",
                          padding: "30px 40px 0 40px",
                          position: "relative",
                          textTransform: "uppercase",
                          marginBottom: "50px",
                        }}
                      >
                        <h2
                          style={{
                            fontSize: REPORT.FONT_SIZE.XLARGE,
                            fontFamily: REPORT.FONT_FACE.HEADING,
                            fontWeight: 600,
                            color: "#0D0F11",
                            letterSpacing: "2px",
                            paddingBottom: "10px",
                            borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                          }}
                        >
                          Executive
                          <br />
                          Summary
                        </h2>
                        <div
                          style={{
                            position: "absolute",
                            top: 10,
                            right: "10px",
                            // padding: 6,
                            // width: "205px",
                            display: "flex",
                            // border:'1px solid black'
                          }}
                        >
                          {
                            IsPageAdded && !isPageAdded && (
                                <>
                                  <Button
                                color="primary"
                                size="sm"
                                id = "tooltipForRemovePage"
                                className="mx-2"
                                disabled={!IsPageAdded}
                                onClick={() => {
                                  setIsPageAdded(false)
                                  RemovePage()
                                  HandlePageAdded(false)
                                }}
                              >
                                Remove Page
                              </Button>

                              <Tooltip
                                  style={{
                                    backgroundColor: "#FEF3C7",
                                    color: "#0D0F11",
                                    zIndex: 1,
                                  }}
                                  placement="left"
                                  isOpen={tooltipForRemovePage}
                                  target="tooltipForRemovePage"
                                  toggle={() => {
                                    setTooltipForRemovePage(!tooltipForRemovePage)
                                  }}
                                >
                                  {tooltipData.infoForRemovePageBtn}
                                </Tooltip>
                                </> 
                                
                            
                            )
                            // :
                            // null
                          }
                          {!IsPageAdded ? (
                            <>
                              <Button
                                color="primary"
                                size="sm"
                                id="TooltipForAddPageBtn"
                                className="mx-2"
                                disabled={IsPageAdded}
                                onClick={() => {
                                  setIsPageAdded(true)
                                  AddPage()
                                  HandlePageAdded(true)
                                }}
                              >
                                Add Page
                              </Button>
                              <Tooltip
                                style={{
                                  backgroundColor: "#FEF3C7",
                                  color: "#0D0F11",
                                  zIndex: 1,
                                }}
                                placement="left"
                                isOpen={tooltipForAddPageBtn}
                                target="TooltipForAddPageBtn"
                                toggle={() => {
                                  setTooltipForAddPageBtn(!tooltipForAddPageBtn)
                                }}
                              >
                                {tooltipData.infoForAddPageBtn}
                              </Tooltip>
                            </>
                          ) : Index === 0 ? (
                            <Button
                              color="white"
                              size="sm"
                              style={{ visibility: "hidden" }}
                              className="mx-2 text-light"
                            >
                              Add NewPage
                            </Button>
                          ) : null}

                          {!saveExecutiveSummary ? (
                            <>
                              <Button
                                color="primary"
                                id="TooltipForSaveBtn"
                                size="sm"
                                style={{ padding: "4px 23px 4px 23px" }}
                                onClick={HandleSaveButton}
                                disabled={IS_SAVED}
                              >
                                {IS_SAVED ? "" : "Save"}
                                {IS_SAVED ? (
                                  <Spinner type="border" size="sm" />
                                ) : null}
                              </Button>
                              <Tooltip
                                style={{
                                  backgroundColor: "#FEF3C7",
                                  color: "#0D0F11",
                                  zIndex: 1,
                                }}
                                placement="right"
                                isOpen={tooltipForSaveBtn}
                                target="TooltipForSaveBtn"
                                toggle={() => {
                                  setTooltipForSaveBtn(!tooltipForSaveBtn)
                                }}
                              >
                                {tooltipData.infoForSaveBtn}
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <Button
                                color="primary"
                                size="sm"
                                id = "TooltipForEditBtn"
                                onClick={HandleSaveButton}
                                style={{ padding: "4px 25px 4px 25px" }}
                              >
                                Edit
                              </Button>
                              <Tooltip
                                style={{
                                  backgroundColor: "#FEF3C7",
                                  color: "#0D0F11",
                                  zIndex: 1,
                                }}
                                placement="right"
                                isOpen={tooltipForEditBtn}
                                target="TooltipForEditBtn"
                                toggle={() => {
                                  setTooltipForEditBtn(!tooltipForEditBtn)
                                }}
                              >
                                {tooltipData.infoForEditBtn}
                              </Tooltip>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="12">
                      <div
                        style={{
                          marginTop: "-20px",
                          width: "100%",
                          height: "920px",
                          // border: "1px solid grey",
                          fontSize: REPORT.FONT_SIZE.XSMALL,
                          lineHeight: "1.15",
                          overflow: "hidden",
                          padding: "0 25px 0 40px",
                        }}
                        className="--executive-summary --container"
                      >
                        {saveExecutiveSummary ? (
                          <div
                            style={{
                              marginTop: "10px",
                              width: "96%",
                              color: "#0D0F11",
                              padding: "4px",
                              overflow: "hidden",
                              height: "860px",
                              // border: "1px solid red",
                              fontSize: REPORT.FONT_SIZE.XSMALL,
                              fontFamily: REPORT.FONT_FACE.PARAGRAPH,
                              // marginTop: '8px',
                              // marginLeft: '20px',
                              // margin:'15px auto 4px auto'
                            }}
                            className="SavedExecutiveSummary"
                          >
                            {ReactHtmlParser(saveExecutiveSummary)}
                          </div>
                        ) : (
                          <CKEditor
                            editor={ClassicEditor}
                            config={{
                              removePlugins: [
                                "EasyImage",
                                "ImageUpload",
                                "MediaEmbed",
                                "Essentials",
                                "Table",
                                "Table Toolbar",
                                "CkFinderUploadAdapter",
                                "CKFinder",
                                "Autoformat",
                                "Link",
                                "BlockQuote",
                              ],
                              uiColor: "#9AB8F3",
                            }}
                            data={
                              editExecutiveSummary ? editExecutiveSummary : ""
                            }
                            // data="test"
                            onReady={editor => {
                              // listToPara()
                              // You can store the "editor" and use when it is needed.
                            }}
                            onChange={(e, data) => {
                              setEditExecutiveSummary(data.getData())
                              setExecutiveData(data.getData())
                              SAVE_EXECUTIVE_SUMMARY(data.getData(), Index)
                              dispatch(handleChangesHappend(true))
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                md="12"
                style={{
                  // border: '1px solid black'
                  position: "absolute",
                  bottom: 0,
                  zIndex: 100,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: REPORT.COLOR.FOOTER,
                    opacity: 0.9,
                    fontWeight: 600,
                    letterSpacing: "2px",
                    fontFamily: REPORT.FONT_FACE.HEADING,
                    padding: "5px 5px 5px 40px",
                    color: "white",
                    fontSize: REPORT.FONT_SIZE.MEDIUM,
                  }}
                >
                  EXECUTIVE SUMMARY
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        {/* Select list */}

        {!saveExecutiveSummary && (
          <Col md="4">
            <div
              style={{
                width: "100%",
                float: "right",
                height: REPORT_PAGE.height,
                // transform: "scale(.8) translate(40px, -132px) ",
                // border: "1px solid red",
                boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, .1)",
              }}
            >
              <Row>
                <Col md="12">
                  <div
                    style={{
                      width: "100%",
                      height: "36px",
                      padding: "4px",
                      // border: "1px solid grey",
                      boxShadow: "0px 4px 10px -2px rgba(0, 0, 0, .1)",
                    }}
                  >
                    <Row className="g-0">
                      <Col md="8"></Col>
                      <Col md="4">
                        <Button
                          size="sm"
                          id="TooltipForApplyContentBtn"
                          color="primary"
                          block
                          onClick={listToPara}
                        >
                          Insert Selected Content
                        </Button>
                        <Tooltip
                          style={{
                            backgroundColor: "#FEF3C7",
                            color: "#0D0F11",
                            zIndex: 1,
                          }}
                          placement="right"
                          isOpen={tooltipForApplyContentBtn}
                          target="TooltipForApplyContentBtn"
                          toggle={() => {
                            setTooltipForApplyContentBtn(
                              !tooltipForApplyContentBtn
                            )
                          }}
                        >
                          {tooltipData.infoForApplyBtn}
                        </Tooltip>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md="12">
                  <div>
                    <ul
                      style={{
                        height: "1020px",
                        // border:'1px solid black',
                        fontSize: REPORT.FONT_SIZE.XSMALL,
                        fontFamily: REPORT.FONT_FACE.PARAGRAPH,
                        padding: "10px",
                        overflow: "auto",
                        listStyleType: "none",
                        color: "#0D0F11",
                      }}
                    >
                      {GetReport.executiveSummary &&
                        GetReport.executiveSummary.contents.map((data, i) => (
                          <li
                            key={i}
                            style={{
                              marginBottom: "12px",
                            }}
                          >
                            <Input
                              className="executiveSummaaryContent"
                              type="checkbox"
                              value={data}
                              style={{
                                width: "17px",
                                height: "16px",
                                margin: "-1px 0 5px 0",
                                textAlign: "left",
                              }}
                              onChange={e => {
                                e.target.checked &&
                                  setExecutiveData(x => [...x, e.target.value])
                              }}
                            />
                            &nbsp;&nbsp;
                            {data.replace(/(<([^>]+)>)/gi, "")}
                          </li>
                        ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>
    </>
  )
}

export default ExecutiveSummary
