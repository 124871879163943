import {
  NEW_DASHBOAD_SCORECOUNT_SUCCESS,
  NEW_DASHBOAD_SCORECOUNT_FAIL,
  CLEAR_NEW_DASHBOARD_SCORECOUNT
} from "./actionTypes"

const INIT_STATE = {
  getScorecardData: [],
}

const NewDashbordScorecountReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NEW_DASHBOAD_SCORECOUNT_SUCCESS:
      return {
        ...state,
        getScorecardData:action.payload,
      }

    case NEW_DASHBOAD_SCORECOUNT_FAIL:
        return{
            ...state,
            getScorecardData:action.payload
        }

    case CLEAR_NEW_DASHBOARD_SCORECOUNT:
        return{
            getScorecardData:[]
        }

        default:
            return state
  }
}

export default NewDashbordScorecountReducer
