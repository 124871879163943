import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux"
import ReactHtmlParser from "react-html-parser"

import {
  Table,
  Container, Row, Col,
  Alert,
  Card, CardBody, CardTitle, FormGroup, Spinner, Form, Input, Button, Modal
} from "reactstrap";



import "../config/style.config.css"
import { REPORT, REPORT_PAGE } from '../config/style.config';

const KeyTakeaway = (props) => {
  const PROPS = props;
  const { Key_takeaways } = PROPS

  return (
    <>
      <Col  xs="12" className="print-content p-0 m-0 ">
        <div style={{...REPORT_PAGE}} >

          <Row className='g-0 m-0 p-0'>
            <Col xs="7" >
              <div style={{
                width: "100%",
                height: REPORT_PAGE.height,
                overflow:'hidden',
                // border: "1px solid grey"
              }} >

                <Row className='g-0 m-0 p-0'>
                  <Col xs="12" >

                    <div style={{
                      margin: "auto",
                      // width: "90%",
                      width: "100%",
                      height: "100px",
                      // padding: "30px 0 25px 8px",
                      padding: "30px 40px 0 40px",
                      position: "relative",
                      textTransform: "uppercase",
                      marginBottom:'40px',
                    }} >

                      <h2 style={{
                        fontSize: REPORT.FONT_SIZE.XLARGE,
                        fontFamily: REPORT.FONT_FACE.HEADING,
                        fontWeight:600,
                        // paddingBottom: "8px",
                        paddingBottom: "10px",
                        color:'#0D0F11',
                        letterSpacing:'2px',
                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                      }}>
                        KEY <span style={{
                          fontFamily: REPORT.FONT_FACE.HEADING,
                          letterSpacing:'2px',
                        }}><br/>TAKEAWAYS</span>
                      </h2>
                    </div>
                  </Col>
                  <Col xs="12" >
                    <div style={{
                      width: "100%",
                      height: "920px",
                      marginTop:'-20px',
                      // border: "1px solid grey",  
                      overflow: "hidden",
                      // padding: "4px",
                      padding: "0 25px 0 40px",
                      fontSize: REPORT.FONT_SIZE.XSMALL,
                      lineHeight:'1.15'
                      // fontFamily: REPORT.FONT_FACE.HEADING,
                      }} 
                      className="--key-takeaways --container"
                      >
                      <div
                        style={{
                          width: "96%",
                          padding: "4px",
                          marginTop:'20px',
                          height:'860px',
                          // border: "1px solid red",
                          fontSize: REPORT.FONT_SIZE.XSMALL,
                          fontFamily: REPORT.FONT_FACE.PARAGRAPH,
                          overflow:'hidden',
                          color:'#0D0F11'
                          // margin:'20px auto 4px 20px',
                          }}
                          className="SavedKeyTakeaways"
                        >
                        {ReactHtmlParser(Key_takeaways && Key_takeaways.contents)}
                      </div>
                    </div>

                  </Col>
                </Row>
              </div>
            </Col>

            <Col xs="5" >
              <div
                className='ImageUploadContainer'
                style={{
                  
                  width: "100%",
                  height: REPORT_PAGE.height,
                  // border: "1px solid grey",
                  backgroundImage: `url(${Key_takeaways ? Key_takeaways.bgImage : ""})`,
                  // backgroundColor: "#f0f0f0",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",

                }} >
                <div className='container--toggle'
                  style={{
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "rgba(0, 0, 0, .3)"
                    
                  }}
                >

                  <span
                  
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      // border: "1px solid red",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "all 1s ease-in-out"
                    }}
                  >
                      <input
                      type="file"
                      id="upload-button"
                      name="Image_URL"
                      accept="image/*"
                      style={{ display: "none" }}
                      // onChange={handleChange}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xs="12" style={{
              // border: '1px solid black'
              position: "absolute",
              bottom: 0,
              zIndex: 100,
              }}>
                <div style={{
                  width: '100%',
                  fontSize: REPORT.FONT_SIZE.MEDIUM,
                  fontFamily: REPORT.FONT_FACE.HEADING,
                  letterSpacing:'2px',
                  padding:'5px 5px 5px 40px',
                  backgroundColor: REPORT.COLOR.FOOTER,
                  opacity: 0.9,
                  fontWeight: 600,
                  color: 'white'
                }}>KEY TAKEAWAYS</div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
}

export default KeyTakeaway;