
import { Link, withRouter, useParams, useHistory } from "react-router-dom";
import React, { useState } from "react";
import MetaTags from "react-meta-tags";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { addAccountHolder, clearAccountHolder, getAccountData, updateAccountDataState } from "../../../store/actions"
import SweetAlert from 'react-bootstrap-sweetalert';

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { SUPER_ADMIN } from "config/AlertMessage.config";

import { Country, State } from "country-state-city"
import { Select } from "@mui/material";



const AccountHolderForm = (props) => {
  const [countries, setCountries] = useState(null)
  const [HandleAccountDetails, setHandleAccountDetails] = useState({})
  const dispatch = useDispatch();
  const history = useHistory();
  // Scorecard version for Account Owner
  const [scorecardVersions, setScorecardVersions] = useState({
    proScorecard: false,
    selfReportScorecard: false,
  })
  const [ErrorsForScorecardVersion, setErrorsForScorecardVersion] = useState(false)
  //const params = useParams()
  // const { accountDetails } = useSelector(state => ({
  //   accountDetails: state.SuperAdmin.accountDetails,
  // }));

  const [isAccountAdd, setIsAccountAdd] = useState(false);
  //const [isEdit, setIsEdit] = useState(false);


  const [success_msg, setsuccess_msg] = useState(false)
  useEffect(() => {
    setCountries(Country.getAllCountries())
  }, [])
  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setIsAccountAdd(false)
  //     //setIsEdit(false)
  //     dispatch(clearAccountHolder());

  //     // validation.values.accountname =""
  //     // validation.values.contactname =""
  //     // validation.values.email = ""
  //     // validation.values.password = ""
  //     // validation.values.street = ""
  //     // validation.values.city = ""
  //     // validation.values.state = ""
  //     // validation.values.zip = ""
  //     // validation.values.phone = ""
  //     // //validation.values.role = Role
  //     // validation.values.credit = ""
  //     // validation.values.validity = ""
  //   },5000)
  // },[isAccountAdd] )

  const { addAccountUser, accountData } = useSelector(state => ({
    addAccountUser: state.createAccountReducer.addAccountUser,
    accountData: state.SuperAdmin.accountData,

  }))


  // --------------Account owners list---------------------
  useEffect(() => {
    !accountData[0] && dispatch(getAccountData());
  }, [dispatch]);
  // ------------------------------------------------------

  // console.log("ACCOUNT DATA...",accountData)


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {

      accountname: '',
      contactname: '',
      email: '',
      // password:'',
      countryCode: '1' || countries && countries[0].phonecode,
      street: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      //role:Role,
      credit: '',

      isdisable: false
    },


    validationSchema: Yup.object({
      accountname: Yup.string().label('Account').required("Please enter account name"),
      contactname: Yup.string().required("Please enter account owner name"),
      email: Yup.string().email('Please enter valid email ').required('Please enter email'),

      // password: Yup.string().min(6, "Password must be at least 6 characters").required("Please enter password"),
      // password1: Yup.string().when("password", {
      //   is: val => (val && val.length > 0 ? true : false),
      //   then: Yup.string().oneOf(
      //     [Yup.ref("password")],
      //     "Both password need to be the same"), }),
      countryCode: Yup.string().required("Plase select country code"),
      street: Yup.string().required("Please enter street"),
      city: Yup.string().required("Please enter city"),
      state: Yup.string().required("Please enter state"),
      zip: Yup.string().required("Please enter zipcode"),
      phone: Yup.string().max(10, "Contact number must be atleast 10 digits").min(10, "Contact number must be atleast 10 digits").required("Please enter contact number"),
      //role: Yup.string().required("Please Specify Role"),
      credit: Yup.string().matches(/^[+]?([0-9]+(?:[\][0-9]*)?|\[0-9]+)(?:[eE][+-]?[0-9]+)?$/, "Credit must be a positive number without decimal").required("Please enter complimentary credits"),

      // .matches(
      //   /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g,
      //         "Invalid phone number"
      //       )

    }),

    onSubmit: (values) => {
      var date = new Date()
      var user = JSON.parse(localStorage.getItem("authUser"))

      if (scorecardVersions.proScorecard || scorecardVersions.selfReportScorecard) {
        const addAccountData = {
          // id: values.id,
          accountName: values.accountname,
          contactName: values.contactname,
          contactEmail: values.email,
          // password:values.password,
          // password1:values.password1,
          countryCode: values.countryCode,
          street: values.street,
          city: values.city,
          state: values.state,
          zip: values.zip,
          phone: values.phone,
          //role:values.Role,
          credit: parseInt(values.credit),
  
          createdOn: date,
          // createdBy: created_by,
          createdBy: user.email,
          creatorId: user.uid,
          isDisabled: values.isdisable,
          scorecardVersions: scorecardVersions
        }
        setIsAccountAdd(true)
        // console.log(addAccountData)
        setHandleAccountDetails(addAccountData)
        dispatch(addAccountHolder(addAccountData))
      } else {
        setErrorsForScorecardVersion(true)
      }
      //  


    },
    onReset: () => {
      var allCheckbox = document.querySelectorAll(".scorecard-versions")
      allCheckbox.forEach(checkbox => {
        checkbox.checked = false
      })
      setScorecardVersions({
        proScorecard: false,
        selfReportScorecard: false
      })
      setErrorsForScorecardVersion(false)
    }

  });


  useEffect(() => {

    var countries_ = [...Country.getAllCountries()]
      .map(country => {
        if (country.isoCode === "CA") {
          return {
            ...country,
            phonecode: "001"
          }
        } else
          if (country.isoCode === "UM") {
            return {
              ...country,
              phonecode: "01"
            }
          } else {
            return country;
          }
      })

    // console.log(countries_)
    setCountries(countries_)
  }, [])

  // Function to handle change for scorecard version type
  const handleScorecardType = (event) => {
    const { name, checked } = event.target;
    setScorecardVersions((e) => ({
      ...e,
      [name]: checked
    }))
    setErrorsForScorecardVersion(false)
  } 

  // if(addAccountUser[0]  && addAccountUser[0].status === 200){
  //   setTimeout(() => {

  //     validation.values.accountname =""
  //     validation.values.contactname =""
  //     validation.values.email = ""
  //     validation.values.password = ""
  //     validation.values.street = ""
  //     validation.values.city = ""
  //     validation.values.state = ""
  //     validation.values.zip = ""
  //     validation.values.phone = ""
  //     //validation.values.role = Role
  //     validation.values.credit = ""


  //       dispatch(clearAccountHolder()) 
  //       setIsAccountAdd(false)
  //       history.push("/dashboard")
  //    }, 2000)
  // }

  // if(addAccountUser[0] && addAccountUser[0].status === 403){
  //   setTimeout(() => {


  //       dispatch(clearAccountHolder()) 
  //       setIsAccountAdd(false)



  //    }, 4000)
  // }

  // console.log(addAccountUser)
  return (

    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Mambo - Create Account</title>
        </MetaTags>
        {/* <Row>
                    <Col xl = "10" style={{margin: "auto"}} >
                <Container fluid={true}>
                    <h4 className="card-title" style={{textTransform: "uppercase"}}>Create: Account</h4>
                </Container>
                    </Col>
                </Row> */}

        <Container fluid={true}>

          {
            addAccountUser[0] && addAccountUser[0].status === 200 &&
            <SweetAlert
              // title="Account added successfully!"
              success
              confirmBtnBsStyle='primary'
              style={{
                height: '255px',
                width: '368px'
              }}
              onConfirm={() => {
                if (accountData[0]) {
                  dispatch(updateAccountDataState([...accountData, {
                    ...HandleAccountDetails,
                    id: addAccountUser[0].id
                  }]))
                } else {
                  dispatch(updateAccountDataState([{
                    ...HandleAccountDetails,
                    id: addAccountUser[0].id
                  }]))
                }
                setIsAccountAdd(false)
                dispatch(clearAccountHolder())
                history.push("/dashboard")
              }}
            >
              {SUPER_ADMIN.CREATE_ACCOUNT.SUCCESS}
            </SweetAlert>
          }

          {
            addAccountUser[0] && addAccountUser[0].status === 409 &&
            <SweetAlert
              // title="Email is already exist!"
              warning
              confirmBtnBsStyle='primary'
              style={{
                height: '255px',
                width: '368px'
              }}
              onConfirm={() => {
                setsuccess_msg(false)
                setIsAccountAdd(false)
                dispatch(clearAccountHolder())
                // history.push("/create-account-holder")
              }}
            >{SUPER_ADMIN.CREATE_ACCOUNT.EXISTING_MAIL}
            </SweetAlert>
          }

          {
            addAccountUser[0] && addAccountUser[0].status === 403 &&
            <SweetAlert
              // title="Failed to update "
              error
              confirmBtnBsStyle="primary"
              style={{
                height: '255px',
                width: '368px'
              }}
              onConfirm={() => {
                setsuccess_msg(false)
                setIsAccountAdd(false)
                dispatch(clearAccountHolder())
                // setIsAccountUpdate(true)

              }}
            >
              {SUPER_ADMIN.CREATE_ACCOUNT.FAILED}
            </SweetAlert>
          }
          <Row>
            <Col xl="12" style={{ margin: "auto" }}>
              <Card>
                <CardBody>

                  <Form className="needs-validation"
                    // autoComplete="new-off"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Account Name</Label>
                          <Input
                            name="accountname"
                            autoComplete="off"
                            placeholder="enter account name"
                            type="text"
                            size="50"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.accountname || ""}
                            invalid={
                              validation.touched.accountname && validation.errors.accountname ? true : false
                            }
                          />
                          {validation.touched.accountname && validation.errors.accountname ? (
                            <FormFeedback type="invalid">{validation.errors.accountname}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Account Owner Name</Label>
                          <Input
                            name="contactname"
                            placeholder="enter account owner name"
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contactname || ""}
                            invalid={
                              validation.touched.contactname && validation.errors.contactname ? true : false
                            }
                          />


                          {validation.touched.contactname && validation.errors.contactname ? (
                            <FormFeedback type="invalid">{validation.errors.contactname}</FormFeedback>
                          ) : null}

                        </FormGroup>
                      </Col>
                      <Col md="4">
                        {/* <FormGroup className="mb-3">
                        <Label htmlFor="validationCustom05">Password</Label>
                        <Input
                          autoComplete="off"
                          name="password"
                          placeholder="enter password"
                          type="password"
                          className="form-control"
                          id="validationCustom05"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </FormGroup> */}
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Email</Label>
                          <Input
                            name="email"
                            autoComplete="off"
                            placeholder="enter email"
                            type="email"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />


                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}

                        </FormGroup>
                      </Col>
                      <Col md="2"></Col>
                    </Row>

                    <Row>

                      <Col md="4">
                        {/* <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">Email</Label>
                                <Input
                                  name="email"
                                  autoComplete="off"
                                  placeholder="enter email"
                                  type="email"
                                  className="form-control"
                                  id="validationCustom01"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email && validation.errors.email ? true : false
                                  }
                                />
                                  
                                  
                                {validation.touched.email && validation.errors.email ? (
                                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                ) : null}
                              
                              </FormGroup> */}
                        <FormGroup className="mb-3">

                          <Label htmlFor="validationCustom02">City</Label>
                          <Input

                            name="city"
                            autoComplete="off"
                            placeholder="enter city"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.city || ""}
                            invalid={
                              validation.touched.city && validation.errors.city ? true : false
                            }
                          />
                          {validation.touched.city && validation.errors.city ? (
                            <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                          ) : null}
                        </FormGroup>

                      </Col>
                      <Col md="4" >
                        <Row>
                          <Col md="5" >
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Phone</Label>
                              {/* <Label htmlFor="validationCustom01">Country Code</Label> */}
                              <Input type="select"
                                className="form-control"
                                name="countryCode"
                                value={validation.values.countryCode}
                                onChange={validation.handleChange}
                              >
                                {countries &&
                                  countries.map(country => (
                                    <option
                                      key={country.name}
                                      value={country.phonecode}
                                    >
                                      {country.flag}
                                      &nbsp;&nbsp;{country.isoCode}
                                      &nbsp;&nbsp;({country.phonecode})
                                    </option>
                                  ))}
                              </Input>
                              {/* <select
                                className="form-control"
                                name="countryCode"
                                value={validation.values.countryCode}
                                onChange={validation.handleChange}>
                                {
                                  countries && countries.map(country => (
                                    <option key={country.name} value={country.phonecode} >
                                      {country.phonecode.includes("+") ? country.phonecode : "+" + country.phonecode}
                                    </option>
                                  ))
                                }
                              </select> */}
                              {/* <Input
                                      name="userName"
                                      placeholder="User Name"
                                      type="text"
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.userName || ""}
                                      invalid={
                                      validation.touched.userName && validation.errors.userName ? true : false
                                            }
                                              /> */}
                              {validation.touched.countryCode && validation.errors.countryCode ? (
                                <FormFeedback type="invalid">{validation.errors.countryCode}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col md="7" >
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">&nbsp;</Label>
                              <Input
                                name="phone"
                                autoComplete="off"
                                min="1"
                                placeholder="phone"
                                type="number"
                                style={{
                                  MozAppearance: "none"
                                }}
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone && validation.errors.phone ? true : false
                                }
                              />
                              {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">

                          <Label htmlFor="validationCustom02">Street</Label>
                          <Input
                            name="street"
                            autoComplete="off"
                            placeholder="enter street name"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.street || ""}
                            invalid={
                              validation.touched.street && validation.errors.street ? true : false
                            }
                          />
                          {validation.touched.street && validation.errors.street ? (
                            <FormFeedback type="invalid">{validation.errors.street}</FormFeedback>
                          ) : null}
                        </FormGroup>

                      </Col>


                    </Row>

                    <Row>
                      <Col md="4">
                        {/* <FormGroup className="mb-3">
                      
                        <Label htmlFor="validationCustom02">City</Label>
                        <Input
                        
                          name="city"
                          autoComplete="off"
                          placeholder="enter city"
                          type="text"
                          className="form-control"
                          id="validationCustom02"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.city || ""}
                          invalid={
                            validation.touched.city && validation.errors.city ? true : false
                          }
                        />
                        {validation.touched.city && validation.errors.city ? (
                          <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                        ) : null}
                      </FormGroup> */}
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">State</Label>
                          <Input
                            name="state"
                            autoComplete="off"
                            placeholder="enter state"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.state || ""}
                            invalid={
                              validation.touched.state && validation.errors.state ? true : false
                            }
                          />
                          {validation.touched.state && validation.errors.state ? (
                            <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        {/* <FormGroup className="mb-3">
                        <Label htmlFor="validationCustom02">State</Label>
                        <Input
                          name="state"
                          autoComplete="off"
                          placeholder="enter state"
                          type="text"
                          className="form-control"
                          id="validationCustom02"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.state || ""}
                          invalid={
                            validation.touched.state && validation.errors.state ? true : false
                          }
                        />
                        {validation.touched.state && validation.errors.state ? (
                          <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                        ) : null}
                      </FormGroup> */}
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Zipcode</Label>
                          <Input
                            name="zip"
                            autoComplete="off"
                            placeholder="enter zipcode"
                            type="number"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.zip || ""}
                            invalid={
                              validation.touched.zip && validation.errors.zip ? true : false
                            }
                          />
                          {validation.touched.zip && validation.errors.zip ? (
                            <FormFeedback type="invalid">{validation.errors.zip}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        {/* <FormGroup className="mb-3">
                        <Label htmlFor="validationCustom02">Zipcode</Label>
                        <Input
                          name="zip"
                          autoComplete="off"
                          placeholder="enter zipcode"
                          type="number"
                          className="form-control"
                          id="validationCustom02"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.zip || ""}
                          invalid={
                            validation.touched.zip && validation.errors.zip ? true : false
                          }
                        />
                        {validation.touched.zip && validation.errors.zip ? (
                          <FormFeedback type="invalid">{validation.errors.zip}</FormFeedback>
                        ) : null}
                      </FormGroup> */}
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom04">Complimentary Credits</Label>
                          <Input
                            name="credit"
                            placeholder="enter complimentary credits"
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            id="validationCustom04"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.credit || ""}
                            invalid={
                              validation.touched.credit && validation.errors.credit ? true : false
                            }
                          />
                          {validation.touched.credit && validation.errors.credit ? (
                            <FormFeedback type="invalid">{validation.errors.credit}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>


                    </Row>





                    {/* <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="validationCustom03">Role</Label>
                        <Input
                          name="role"
                          
                          type="text"
                          className="form-control"
                          id="validationCustom03"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.role || ""}
                          invalid={
                            validation.touched.role && validation.errors.role ? true : false
                          }
                        />
                        {validation.touched.role  && validation.errors.role ? (
                          <FormFeedback type="invalid">{validation.errors.role}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col> */}


                    {/* <Row>
                  
                  
                    <Col md="3">
                      <FormGroup className="mb-3">
                        <Label htmlFor="validationCustom04">Complimentary Credits</Label>
                        <Input
                          name="credit"
                          placeholder="enter complimentary credits"
                          type="number"
                          autoComplete="off"
                          className="form-control"
                          id="validationCustom04"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.credit || ""}
                          invalid={
                            validation.touched.credit && validation.errors.credit ? true : false
                          }
                        />
                        {validation.touched.credit && validation.errors.credit ? (
                          <FormFeedback type="invalid">{validation.errors.credit}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                   
                    </Row> */}






                    <Row >

                      <Col md="6">
                        <Label className="my-0 py-0">Select Scorecard Type</Label>
                        {ErrorsForScorecardVersion ? (
                          <span className="text-danger ms-2" style={{
                            fontSize: '11px',
                            letterSpacing: 0.1
                          }}>
                            (Please select at least one type of scorecard)
                          </span>
                        ) : null}
                        <div className="d-flex align-items-center gap-4">
                          <div className="d-flex align-items-center">
                            <Input 
                              type="checkbox"
                              style={{
                                width: '23px',
                                height: '20px'
                              }}
                              name="proScorecard"
                              className="scorecard-versions"
                              id="proScorecard"
                              defaultChecked={scorecardVersions.proScorecard}
                              onClick={handleScorecardType}
                            />
                            <div className="ms-2 pt-2">
                              <label htmlFor="proScorecard">Pro Scorecard</label>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <Input 
                              type="checkbox"
                              style={{
                                width: '23px',
                                height: '20px'
                              }}
                              name="selfReportScorecard"
                              className="scorecard-versions"
                              id="selfReportScorecard"
                              defaultChecked={scorecardVersions.selfReportScorecard}
                              onClick={handleScorecardType}
                            />
                            <div className="ms-2 pt-2">
                              <label htmlFor="selfReportScorecard">Self Report Scorecard</label>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="2" />
                      <Col md="2">
                        <FormGroup className="mb-3">
                          <Label className="my-0 py-0" style={{visibility: "hidden"}}>Cancel</Label>
                          <Button color="danger" onClick={(e) => {
                            validation.resetForm()
                          }}
                            block >
                            Cancel
                          </Button>
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className="mb-3">
                          <Label className="my-0 py-0" style={{visibility: "hidden"}}>Create</Label>
                          <Button color="primary" type="submit"
                            disabled={isAccountAdd}
                            block >
                            {isAccountAdd ? "" : "Create"}
                            {isAccountAdd ?
                              <Spinner
                                // style={{ width: "0.8rem", height: "0.8rem" }}
                                type="border"
                                size="sm"
                              />
                              :
                              null
                            }
                          </Button>
                        </FormGroup>
                      </Col>

                    </Row>


                  </Form>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default AccountHolderForm








