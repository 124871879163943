import {

    UPDATE_ACCOUNT_DETAILS_SUCCESS,
    UPDATE_ACCOUNT_DETAILS_FAIL,
    CLEAR_ACCOUNT,

    UPDATE_EDITOR_DETAILS_SUCCESS,
    UPDATE_EDITOR_DETAILS_FAIL,
    CLEAR_EDITOR
    
  } from "./actionTypes"
  
  const INIT_STATE = {
    updateAccount: [],
    updateEditor:[],
    error: {},
  }
  
  const UpdateAccountReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

      case UPDATE_ACCOUNT_DETAILS_SUCCESS:
        return {
          ...state,
          updateAccount: action.payload,
        }
  
      case UPDATE_ACCOUNT_DETAILS_FAIL:
        return {
          ...state,
          error: action.payload,
        }

        case CLEAR_ACCOUNT:
        return {
          ...state,
          updateAccount: [],
        }

      case UPDATE_EDITOR_DETAILS_SUCCESS:
        return{
          ...state,
          updateEditor:action.payload,

        }
      case UPDATE_EDITOR_DETAILS_FAIL:
        return{
          ...state,
          error:action.payload,
        }

      case CLEAR_EDITOR:
        return{
          ...state,
          updateEditor:[]
        }

      default:
        return state
    }
  }
  
  export default UpdateAccountReducer
  