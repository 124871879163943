import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import "../../config/style.config.js"
import "../../config/style.config.css"
import { REPORT, REPORT_PAGE } from "../../config/style.config"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// import ReactHtmlParser from "react-html-parser"
import ReactHtmlParser from "react-html-parser"

import "../../config/style.config.css"
import {
    Table,
    Container,
   
 
    Alert,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Spinner,
    Form,
    Input,
    Button,
    Modal,
    Tooltip,
  } from "reactstrap"

import {
  handleUnsavedChanges,
  handleChangesHappend,
  getAllInstructionData,
} from "store/actions"
import { useSelector, useDispatch } from "react-redux"

const PAFCard = props => {
  const [CardColor, setCardColor] = useState([])
  const PROPS = props
  const dispatch = useDispatch()
  const {
    Index,
    Title,
    Icon,
    Description,
    HandleEditCard,
    EnteredData,
    EditMode,
  } = PROPS

  const [isEdit, setisEdit] = useState(false)

  const [tooltipForSaveBtn, setTooltipForSaveBtn] = useState(false)
  const [tooltipForAddPageBtn, setTooltipForAddPageBtn] = useState(false)
  const [tooltipForApplyContentBtn, setTooltipForApplyContentBtn] =
    useState(false)

  const [tooltipForResetPageBtn, setTooltipForResetPageBtn] = useState(false)
  const [tooltipForImageUpload, setTooltipForImageUpload] = useState(false)
  const [tooltipForRemovePage, setTooltipForRemovePage] = useState(false)
  const [tooltipForEditBtn, setTooltipForEditBtn] = useState(false)
  const [tooltipData, setTooltipData] = useState({})

  const { isDataEdited, isThereChanges, getInstructionData } = useSelector(
    state => ({
      isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
      isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
      getInstructionData: state.instructionPanelReducer.getInstructionData,
    })
  )

  useEffect(() => {
    setCardColor([
      REPORT.COLOR.PRIORITY_1,
      REPORT.COLOR.PRIORITY_2,
      REPORT.COLOR.PRIORITY_3,
      REPORT.COLOR.PRIORITY_4,
    ])
  }, [PROPS.Index])

 
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: REPORT.PRIORITY_CARDS_HEIGHT.MAIN,
          margin: "10px 10px 20px 7px",
          backgroundColor: CardColor[0] ? CardColor[Index] : "#fff",
        }}
      >
        <Row>
          <Col md="12">
            <div
              style={{
                width: "100%",
                height: REPORT.PRIORITY_CARDS_HEIGHT.DIV_ONE,
                // border:'1px solid black'
              }}
            >
              <img
                src={Icon}
                style={{
                  margin: "17px 0 0 143px",
                  height: "80px",
                }}
              />
            </div>
          </Col>
          <Col md="12">
            <div
              style={{
                width: "100%",
                height: REPORT.PRIORITY_CARDS_HEIGHT.DIV_TWO,
                fontFamily: REPORT.FONT_FACE.PARAGRAPH,
                lineHeight: "23px",
                fontSize: "20px",
                fontWeight: 600,
                padding: "4px 4px 0 4px",
                textAlign: "center",
                color: "#0D0F11",
                // marginTop:'5px',
                // border: '1px solid black'
              }}
            >
              <p style={{ marginTop: "20px" }}>{Title}</p>
            </div>
          </Col>
          <Col md="12">
            <div
              style={{
                // border: '1px solid black',
                padding: "0 18px 5px 18px",
                textAlign: "center",
                color: "#0D0F11",
                // fontSize: REPORT.FONT_SIZE.SMALL,
                Width: "100%",
                Height: REPORT.PRIORITY_CARDS_HEIGHT.DIV_THREE,
                lineHeight: "1.15",
                fontWeight: 500,
                resize: "vertical",
              }}
              className="--PAF-Card"
            >
              {EditMode ? (
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: CardColor[0] ? CardColor[Index] : "#fff",
                    border: "none",
                    resize: "none",
                    width: "100%",
                    overflowY: "hidden",
                    height: REPORT.PRIORITY_CARDS_HEIGHT.DIV_THREE,
                    fontFamily: REPORT.FONT_FACE.PARAGRAPH,
                    fontSize: REPORT.FONT_SIZE.MEDIUM,
                    padding: "-5px 7px 0 7px",
                    marginTop: "25px",
                    color: "#0D0F11",
                    // border: '1px solid black'
                  }}
                  className="SavedPAFCard"
                >
                  {ReactHtmlParser(Description && Description)}
                </div>
              ) : (
                <div className="paf-container" style={{ marginTop: "5px" }}>
                  {/* <button style={{ fontSize: "18px", cursor: "pointer", color: "blue", textAlign: "right" }}>Save</button> */}
                  <CKEditor
                    editor={ClassicEditor}
                    // onReady={(editor) => {
                    //     editor.execute("heading", { value: "heading3" });
                    // }}
                    config={{
                      removePlugins: [
                        "EasyImage",
                        "ImageUpload",
                        "MediaEmbed",
                        "Essentials",
                        "Table",
                        "Table Toolbar",
                        "CkFinderUploadAdapter",
                        "CKFinder",
                        "Autoformat",
                        "Link",
                        "BlockQuote",
                      ],
                      uiColor: "#9AB8F3",
                    }}
                    data={Description}
                    onChange={(e, data) => {
                      var CkData = data.getData()
                      EnteredData(CkData)
                      dispatch(handleChangesHappend(true))
                    }}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PAFCard
