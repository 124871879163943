import {
  GET_CHANNEL_DATA,
  GET_CHANNEL_DATA_SUCCESS,
  GET_CHANNEL_DATA_FAIL,
  CHANGE_CHANNEL_DATA,
  UPDATE_CHANNEL_DATA_STATE,
  CLEAR_UPDATE_CHANNEL_DATA_STATE,
} from "./actionTypes"

export const getChannelData = () => ({
  type: GET_CHANNEL_DATA,
})

export const getChannelDataSuccess = data => ({
  type: GET_CHANNEL_DATA_SUCCESS,
  payload: data,
})

export const getChannelDataFail = error => ({
  type: GET_CHANNEL_DATA_FAIL,
  payload: error,
})

export const changeChannelData = data => ({
  type: CHANGE_CHANNEL_DATA,
  payload: data,
})

export const updateChannelDataState = data => ({
  type: UPDATE_CHANNEL_DATA_STATE,
  payload: data,
})

export const ClearUpdateChannelDataState = () => ({
  type:CLEAR_UPDATE_CHANNEL_DATA_STATE
})
