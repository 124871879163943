import { takeEvery, put, call,all,fork  } from "redux-saga/effects";

import {addEditorDataSuccess, addEditorDataFail, getEditorDataSuccess, getEditordataFail} from "./actions";

import {ADD_EDITOR_DATA,GET_EDITOR_DATA} from "./actionTypes"

import {addEditorData, getEditorData} from "../../helpers/fakebackend_helper"


function* onAddEditorData ({payload: item}){
    try{
        const response = yield call(addEditorData , item)
        yield put(addEditorDataSuccess(response))
    }
    catch(error){
        yield put(addEditorDataFail , (error))

    }

}

function* onGetEditorData({id}){
    try{
        const response = yield call(getEditorData, id)
        yield put(getEditorDataSuccess(response))
    }
    catch(error){
        yield put(getEditordataFail, (error))
    }

}

function* addEditorDataSaga(){
    yield takeEvery(ADD_EDITOR_DATA, onAddEditorData ),
    yield takeEvery(GET_EDITOR_DATA, onGetEditorData)

}
export default addEditorDataSaga