import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import ReactDragListView from "react-drag-listview/lib/index.js"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  FormGroup,
  Spinner,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import { useEffect, useRef } from "react"

import {
  getChannelData,
  changeChannelData,
  ArrangeChannelSequence,
  ClearArrangeChannelSequence,
} from "store/actions"
import SweetAlert from "react-bootstrap-sweetalert"

import NotFound from "components/Common/NotFound/NotFound.component"
import { CONTENT_EDITOR, NETWORK_ERROR } from "config/AlertMessage.config"

//Import Breadcrumb

const ArrangeChannel = () => {
  const dispatch = useDispatch()

  const [isChannelDataChanged, setChannelDataChanged] = useState([])
  const [isChannelsequenceChanged, setChannelSequenceChanged] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const [isDataAvail, setIsDataAvail] = useState(true)

  var { channelData, chnagedChannelData } = useSelector(state => ({
    channelData: state.GetChannelData.channelData,
    chnagedChannelData: state.ArrangeChannelSequenceReducer.chnagedChannelData,
  }))

  useEffect(() => {
    !channelData[0] && dispatch(getChannelData())

    // var search_bar = document.querySelector("#search-bar-0")
    // search_bar.setAttribute("autoComplete", "off")
  }, [dispatch, channelData])

  useEffect(() => {
    // var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)
    if (channelData.status === 403) {
      setIsDataAvail(false)
    }
  })

  useEffect(() =>{
    if(channelData){
      SetData(channelData)
    }
  }, [channelData])

  

  function compareName(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a.channelName.toUpperCase()
    const name2 = b.channelName.toUpperCase()

    let comparison = 0

    if (name1 > name2) {
      comparison = 1
    } else if (name1 < name2) {
      comparison = -1
    }
    return comparison
  }

  // const ChannelSequenceData =
  //   channelData[0] && channelData[0] ? channelData : []

  //console.log("My channel data is here.....",ChannelSequenceData.sort(compareName))

  const [Data, SetData] = useState([])
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...Data]

      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      SetData(modifiedData)
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  // console.log("Data", Data)

  const onSubmit = () => {
    setSuccessMsg(true)
    setChannelSequenceChanged(true)
    // console.log("Data submitted")
    //    if(data){
    //     channelData = [...data]
    //    }

    const ChangedData = {
      data: [...Data],
    }
    setChannelDataChanged(ChangedData)
    dispatch(ArrangeChannelSequence(ChangedData))
    // console.log(channelData)
  }

  //console.log("channels...." , channelData)

  //console.log(isChannelDataChanged.data)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Arrange Channel Sequence</title>
        </MetaTags>
        <div className="container-fluid">
          {chnagedChannelData.status === 200 && (
            <SweetAlert
              // title="Scorecard created successfully!"
              success
              confirmBtnBsStyle="primary"
              onConfirm={() => {
                dispatch(changeChannelData(isChannelDataChanged.data))
                setSuccessMsg(false)
                setChannelSequenceChanged(false)
                dispatch(ClearArrangeChannelSequence())
              }}
            >
              Channel sequence updated successfully.
            </SweetAlert>
          )}
          {chnagedChannelData.status === 403 && (
            <SweetAlert
              // title="Failed to create scorecard!"
              error
              confirmBtnBsStyle="primary"
              onConfirm={() => {
                dispatch(changeChannelData(isChannelDataChanged.data))
                setSuccessMsg(false)
                setChannelSequenceChanged(false)
                dispatch(ClearArrangeChannelSequence())
              }}
            >
              Failed to update Channel sequence.
            </SweetAlert>
          )}

          <Row>
            <Col md={12}>
              <Card>
                {!channelData[0] ? ( //  && !accountDetails[0]
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      height: "400px",
                    }}
                  >
                    {!isDataAvail ? (
                      <>
                        {channelData.status === 404 ? (
                          <NotFound
                            Message={CONTENT_EDITOR.ARRANGE_CHANNEL_SEQUENCE}
                            Icon={() => (
                              <i
                                className="bx bxs-id-card"
                                style={{ borderBottom: "1px solid #000" }}
                              ></i>
                            )}
                            Url="/dashboard"
                            IsButton
                            btnText="Create Channel"
                          />
                        ) : (
                          <NotFound
                            Message={NETWORK_ERROR}
                            Icon={() => (
                              <i
                                className="bx bx-error"
                                style={{
                                  borderBottom: "1px solid #000",
                                  fontSize: "40px",
                                }}
                              ></i>
                            )}
                            IsButton
                            btnText="Reload"
                            Reload
                          />
                        )}
                      </>
                    ) : (
                      <Spinner
                        style={{
                          width: "60px",
                          height: "60px",
                          margin: "calc(200px - 100px)",
                          color: "#05727B",
                        }}
                        role="grid"
                        type="border"
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <CardBody>
                      <h5>
                        <b>Note:</b> Hold a channel & drag up or down to change sequence.
                      </h5>

                      <div
                        className="table-responsive"
                        style={{ overflowY: "revert", overflowX: "hidden" }}
                      >
                        <ReactDragListView {...dragProps}>
                          <Table className="table mb-0">
                            <thead style = {{fontSize:'15px'}}>
                              <tr>
                                <th >Channel Order</th>
                                <th>Channel Name</th>
                              </tr>
                            </thead>
                            <tbody style={{ background: "#F7FBFB" }}>
                              {(Data || []).map((item, index) => (
                                <tr
                                  style={{ color: "#0D0F11", fontSize: "14px" }}
                                  key={index}
                                >
                                  <th scope="row" className="drag-pointer" style={{paddingLeft:'60px'}}>
                                    {index + 1}
                                  </th>

                                  <td>{item.channelName}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          &nbsp;
                          <Row>
                            <Col md="9"></Col>
                            <Col md="3">
                              {" "}
                              <FormGroup className="mb-3">
                                <Button
                                  onClick={() => onSubmit()}
                                  color = "primary"
                                  type="submit"
                                  disabled={isChannelsequenceChanged}
                                  block
                                >
                                  {isChannelsequenceChanged ? "" : "Save Channel Sequence"}
                                  {isChannelsequenceChanged ? (
                                    <Spinner
                                      // style={{ width: "0.8rem", height: "0.8rem" }}
                                      type="border"
                                      size="sm"
                                    />
                                  ) : null}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </ReactDragListView>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ArrangeChannel
