import {
    GET_EDITOR_DETAILS_SUCCESS,
    GET_EDITOR_DETAILS_FAIL,
    CLEAR_CONTENT_EDITOR_DETAILS,
    UPDATE_CONTENT_EDITOR_DETAILS_STATE
} from "./actionTypes"


const INIT_STATE = {
    editorDetails: []
};

const EditorDetails = (state = INIT_STATE, action) => {

    switch (action.type) {

        case GET_EDITOR_DETAILS_SUCCESS:
            return {
                ...state,
                editorDetails: action.payload,

            };

        case GET_EDITOR_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload,

            };
        case CLEAR_CONTENT_EDITOR_DETAILS: {
            return {
                ...state,
                editorDetails: {}
            }
        }

        case UPDATE_CONTENT_EDITOR_DETAILS_STATE: {
            return {
                ...state,
                editorDetails: action.payload
            }
        }

        default:
            return {
                ...state,
            }

    }



}

export default EditorDetails 