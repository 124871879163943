import { color, width } from "@mui/system"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Grid, imageListItemClasses } from "@mui/material"
import CropEasy from "../../../Crop/CropEasy"
import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
  Modal,
  Label,
  FormFeedback,
} from "reactstrap"
import { REPORT } from "../Report/config/style.config"
import moment from "moment"

//SweetAlert


const PageFirst = (props) => {

  const PROPS = props;
  const { Cover_page } = PROPS

  return (
    <>
      <Col xs="12" className="p-0 m-0 ">
        <div
          style={{
            height: "1056px",
            width: "816px",
            transform: "scale(1)",
            backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${Cover_page ? Cover_page.cover.bgImage 
              :
              ""
              })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            // border:'1px solid black'
          }}
        >
          <CardBody>

            <div>
              <Row className="p-0 m-0">
                {" "}
                <Col xs="2">
                  <FormGroup className="mb-3">

                  </FormGroup>
                </Col>
                <Col xs="9"></Col>
                <Col xs="1">
                  <div>
                    <label
                      type="button"
                      id="more"
                      onClick={() => {
                        tog_backdrop()
                      }}
                    >
                      {/* <span
                          className="fas fa-pencil-alt"
                          style={{ fontSize: "20px", color: "white" }}
                          onClick={() => {
                            setIsModalOpen(true)
                          }}
                        ></span> */}
                    </label>

                    <input
                      type="file"
                      id="upload-button"
                      name="Image_URL"
                      accept="image/*"
                      style={{ display: "none" }}
                    // onChange={handleChange}
                    />
                    <br />
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <div
                style={{
                  marginLeft: 50,
                  height: "245px",
                  width: "554px",
                  backgroundColor: "rgba(52, 52, 52, 0.8)",
                  padding: 10,
                  marginTop: 28
                }}
              >
                <div style={{paddingLeft:'5px', fontFamily:REPORT.FONT_FACE.HEADING}}>
                  <Col xs="12">
                    <h4 style={{ color: "white", fontSize: "60px", fontWeight:700, fontFamily:REPORT.FONT_FACE.HEADING, lineHeight:.8 }}>
                      Modern
                    </h4>
                  </Col>
                  <Col sm="12">
                    <h4 style={{ color: "white", fontSize: "60px", fontWeight:700, fontFamily:REPORT.FONT_FACE.HEADING, lineHeight:.8 }}>
                      Marketing
                    </h4>
                  </Col>
                  <Col sm="12">
                    <h4 style={{ color: "white", fontSize: "60px", fontWeight:700, fontFamily:REPORT.FONT_FACE.HEADING, lineHeight:.8 }}>
                      {" "}
                      Scorecard<sup style={{fontSize:'25px', fontFamily:REPORT.FONT_FACE.HEADING}}>TM</sup>
                    </h4>
                  </Col>
                  <Col xs="12">
                  &nbsp;<lable
                      style={{
                        color: "white",
                        borderBottom: "3px solid #FF6060",
                        fontSize:'28px',
                        fontWeight:'normal',
                        fontFamily:REPORT.FONT_FACE.PARAGRAPH
                      }}
                    >
                      {`${Cover_page &&
                        Cover_page.companyName}`.toUpperCase()}
                    </lable>
                  </Col>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 348, marginLeft: 50, }}>
              <div
                style={{
                  height: "415px",
                  width: "305px",
                  backgroundColor: "#FF6060",
                  opacity: 0.8,
                  padding: "20px 10px 10px 20px",
                }}
              >
                <div>
                  <label
                    style={{
                      color: "white",
                      borderBottom: "3px solid white",
                    }}
                  >
                    PREPARED BY:
                  </label>

                  {
                    Cover_page && 
                    Cover_page.scorecardVersion === "Self Report" ? (
                      <>
                        <div>
                          <label
                            style={{
                              color: "white",
                            }}
                          >
                            {`Created On: ${Cover_page && Cover_page.reportGenerationDate ?
                              moment(Cover_page.reportGenerationDate * 1000)
                              .format("DD/MM/YYYY") :
                              moment(Cover_page.createdOn._seconds * 1000)
                              .format("DD/MM/YYYY")
                            }`}
                          </label>
                        </div>
                        <div>
                          <label
                            style={{
                              color: "white",
                            }}
                          >
                            Status: Completed
                          </label>
                        </div>
                      </>
                    ) : null
                  }

                  <div style={{ color: "white" }}>
                    {Cover_page && Cover_page.consultantList && Cover_page.consultantList[0] &&
                      Cover_page.consultantList.filter(data => data.accountUserId !==  "" ).map((user, i) => (
                        <div key={i} style={{marginBottom:'15px'}}>
                          <p
                            style={{
                              display: "flex",
                              backgroundColor: "#2D3033",
                              width: "265px",
                              height: "45px",
                              fontSize: "25px",
                              alignItems: "center",
                              paddingLeft: "10px",
                            }}
                          >
                            {user.accountUserContactName}
                          </p>
                          <p
                            style={{
                              margin: "-13px 0 0 10px",
                              fontSize: "18px",
                              padding:0
                            }}
                          >
                            {" "}
                            {user.designation}
                          </p>
                          {/* <p
                            style={{
                              margin: "5px 0 4px 10px",
                              fontSize: "13px",
                              padding:0
                            }}
                          >
                            {" "}
                            {user.address}
                          </p> */}
                        </div>
                      ))}

                  </div>

                  
                </div>
              </div>
            </div>
          
          </CardBody>
        </div>
      </Col>
      {/* </Container>
      </div> */}
    </>
  )
}

export default PageFirst
