import React, { useEffect } from "react"
//import "../../AccountUser/Report/ChannelTacticEvaluated/component/BubbleChart/Bubble.style.css"
import "./Tactic_Preview.css"

const BubbleChart = (props) => {

    const PROPS = props

    const {TACTIC_NAME} = PROPS

    useEffect(() => {
        // if (TACTIC_NAME) {
        //     document.querySelectorAll(".span").forEach(el => el.remove())
        //     DrawBubbleChart(Length, Tactics.map(tactic => tactic.tacticName))
        // }

        let el = document.querySelector("#parentdiv")

        // if (TACTIC_NAME.length <=14){
          
        //    el.classList.add("center-top")


        // }
        // else if(TACTIC_NAME.length <=29 && TACTIC_NAME.length >=14){
           
        //     el.classList.add("center-text")

        // }
        // else{
        //     el.classList.add("center-text1")
        // }
            
            

        
        // var htmlEle = document.getElementsByClassName("div2")
        // var text = Object.values(htmlEle).map((ele) => ele)
        // var newVal = text.filter(
        //     el => el.childNodes[0].textContent.length <= 14 ? el.classList.add("center-top"):
        //     el.childNodes[0].textContent.length >= 29 ? el.classList.add("center-text"): "")
    }, [ TACTIC_NAME])

    return(
        <div className="Bubble-container" style = {{display:'flex' , justifyContent:'center', margin:'-150px -10px'}}>
        <div id="parentdiv" style={{
            position: "relative",
            width: "200px",
            height: "200px",
            backgroundColor: "transparent",
            borderRadius: "150px",
            
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            
            
            
            
           
        }} >
            <div className="div2">
                <span>
                {TACTIC_NAME}
                </span>

            </div>
            
        </div>
    </div >

    );
}
export default BubbleChart;