import React from 'react'
import { color, fontSize } from "@mui/system"
import  { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Grid } from "@mui/material"
// import img from "../../../../assets/images/reportScorcard/executiveCardimg.PNG"
// import { CKEditor } from "@ckeditor/ckeditor5-react"
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// // import {mainDivStyle} from './pageFifth.cssConfig'
import { REPORT_PAGE, REPORT, RECOMMENDATIONS } from "../config/style.config"
import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
} from "reactstrap"
import { Opacity } from "@mui/icons-material"
import Footer from "components/VerticalLayout/Footer"
// import {
//   getScorecardReport,
//   UpdateScorecardReport,
//   ClearUpdateScorecardReport,
// } from "store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams, useHistory } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"

const ChildRecommendationPage = (props) => {
    const parameter = props
    const {
        
        CHANNEL_DATA,
        DATA_AVAIL,

        RECOMMENDATION_DATA
      
      



    } = parameter

    const params = useParams()

    const [isPageAdded, setIsPageAdded] = useState(false)
    const [editTacticRecommendationData , setEditTacticRecommendationData] = useState([])

    const [editRecommendation, setEditRecommendation] = useState({})
    const [saveRecommendation, setSaveRecommendation] = useState(null)
    
    // useEffect(() => {
    //     if(DATA_AVAIL.recommendation){
    //         setEditRecommendation(DATA_AVAIL.recommendation)
    //         setSaveRecommendation(DATA_AVAIL.recommendation)
    //         setEditTacticRecommendationData(DATA_AVAIL.recommendation)
    //         if(Data_Length > 5 && Index === 0){
    //             setIsPageAdded(true)
    //         }
    //     }

    // }, [DATA_AVAIL])

  return (
    <>
      <Col xs="12" className="p-0 m-0" style={REPORT_PAGE}>
        <div style={REPORT_PAGE}>
          <Row style={REPORT_PAGE}>
            <Col xs="12">
              <div style={{
                width: "100%",
                height: REPORT_PAGE.height,
                // width: REPORT_PAGE.width,
                // border:'1px solid black'
              }}>
                <Row>
                  <Col xs="12">
                    <div
                      style={{
                        width: "100%",
                        padding: "30px 40px 0 40px",
                        // border:'1px solid black'
                      }}>
                        <h2
                          style={{
                            fontSize: REPORT.FONT_SIZE.XLARGE,
                            fontWeight: 600,
                            color:'#0D0F11',
                            letterSpacing:'2px',
                            paddingBottom:'10px',
                            fontFamily: REPORT.FONT_FACE.HEADING,
                            borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                            // paddingBottom: "8px",
                          }}
                        >
                          {`${CHANNEL_DATA && CHANNEL_DATA.channelName} RECOMMENDATIONS`.toUpperCase()}
                        </h2>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div
                      style={{
                        width: "100%",
                        overflow: "hidden",
                        // padding:'30px',
                        padding:'15px 40px 0 40px',
                        // border:'1px solid black'
                      }}>
                        <Table className="table table-bordered mb-0">
                          <thead
                              style={{
                                backgroundColor: "#0D0F11",
                                color: "white",
                              }}>
                              <tr>
                                <th
                                  style={{
                                    fontSize: "15px",
                                    textAlign: "center",
                                    padding: "0",
                                    width:'20%',
                                    border: "1px solid #0D0F11",
                                  }}
                                >
                                  Tactic
                                </th>
                                <th
                                  style={{
                                    fontSize: "15px",
                                    textAlign: "center",
                                    padding: "0",
                                    width:'100%',
                                    border: "1px solid #0D0F11",
                                  }}
                                >
                                  Recommendations
                                </th>
                              </tr>
                            </thead>
                          {RECOMMENDATION_DATA.tactics.filter(tactic => tactic.recommendation !== "")
                          .map((tactic, tacticId) => {
                            return (
                              <tbody key={tacticId} style={{border: "1px solid #0D0F11",}}>
                                <tr
                                  style={{
                                    height: "110px",
                                    overflow:'hidden',
                                    border: "1px solid #0D0F11",
                                  }}>
                                  <th style={{...RECOMMENDATIONS, textAlign:'center', fontSize:'14px', border: "1px solid #0D0F11", verticalAlign:'middle', color:'#0D0F11'}}>
                                    {
                                    tactic.tacticName
                                    }
                                  </th>
                                  <td style={{...RECOMMENDATIONS, border: "1px solid #0D0F11", fontSize:'14px',lineHeight:'1.15', verticalAlign:'middle', color:'#0D0F11'}}>
                                    {
                                      tactic.recommendation
                                    }
                                  </td>
                                </tr>
                              </tbody>
                              )
                          })}
                        </Table>
                      
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Col
            xs="12"
            style={{
              // border: '1px solid black',
              position: "absolute",
              bottom: 0,
              zIndex: 100,
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: REPORT.COLOR.FOOTER,
                opacity: 0.9,
                fontWeight: 600,
                letterSpacing:'2px',
                color: "white",
                padding:'5px 5px 5px 40px',
                fontSize: REPORT.FONT_SIZE.MEDIUM,
                fontFamily: REPORT.FONT_FACE.HEADING,
              }}
            >
              
              {`${CHANNEL_DATA && CHANNEL_DATA.channelName} - RECOMMENDATIONS`.toUpperCase()}
              
            </div>
          </Col>

        </div>
      </Col>
    </>
  )
}

export default ChildRecommendationPage