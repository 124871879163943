import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags'
import "./New_Dashboard.css"
import Users from "../New Dashboard/Users"
import CreditBox from "./CreditBox"
import ScorecardList from "./ScorecardList"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Spinner,
    Table,
    Button,
    FormGroup,
    Container,
    Modal
  
  } from "reactstrap"

  import AddCredit from "./AddCredit/AddCredit"



  const New_Dashboard = () => {
  
   

    const [modal_backdrop, setmodal_backdrop] = useState(false)



    function tog_backdrop() {
      setmodal_backdrop(!modal_backdrop)
      removeBodyCss()
    }
  
    function removeBodyCss() {
      document.body.classList.add("no_padding")
    }

    return(
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Homepage</title>
        </MetaTags>
        <Container fluid>
          <Users/>
          <ScorecardList/>
        </Container>
      </div>
    </React.Fragment>

    )

  }
  export default New_Dashboard