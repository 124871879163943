import React, { useEffect } from "react"
import "./Bubble.style.css"

const BubbleChart = (props) => {
    const PROPS = props
    const { Tactics, Length, Id } = PROPS;
    const DrawBubbleChart = (n, data) => {
        var div = 360 / n;
        var radius = 240;
        var parentdiv = document.getElementById(Id);
        var offsetToParentCenter = parseInt(parentdiv.offsetWidth / 2); //assumes parent is square
        var offsetToChildCenter = 20;
        var totalOffset = offsetToParentCenter - offsetToChildCenter;
        for (var i = 1; i <= n; ++i) {
            var childdiv = document.createElement('div');
            var childSpan = document.createElement('span');
            childdiv.className = 'div2';
            childSpan.innerHTML = data[i - 1]
            childdiv.style.position = 'absolute';
            var y = Math.sin((div * i) * (Math.PI / 180)) * radius;
            var x = Math.cos((div * i) * (Math.PI / 180)) * radius;
            childdiv.style.top = (y + totalOffset).toString() + "px";
            childdiv.style.left = (x + totalOffset).toString() + "px";
            parentdiv.appendChild(childdiv);
            childdiv.appendChild(childSpan)
        }
    } 

    useEffect(() => {
        if (Tactics) {
            document.querySelectorAll(".span").forEach(el => el.remove())
            DrawBubbleChart(Length, Tactics.map(tactic => tactic.tacticName))
        }
        // var htmlEle = document.getElementsByClassName("div2")
        // var text = Object.values(htmlEle).map((ele) => ele)
        // var newVal = text.filter(el => 
        //     el.childNodes[0].textContent.length <= 14 ? 
        //     el.classList.add("text-top") : 
        //     el.childNodes[0].textContent.length >= 29 ? 
        //     el.classList.add("span-center"): "")
    }, [Length, Tactics])

    return (
        <div className="Bubble-container">
            <div id={Id} style={{
                position: "relative",
                width: "200px",
                height: "200px",
                backgroundColor: "transparent",
                borderRadius: "150px",
                margin: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "200px",
            }} >
                {PROPS.children}
            </div>
        </div >
    );
}

export default BubbleChart;