import {
  UPDATE_INSTRUCTION,
  UPDATE_INSTRUCTION_SUCCESS,
  UPDATE_INSTRUCTION_FAIL,
  UPDATE_INSTRUCTION_CLEAR,
} from "./actionTypes"


// To add new instuction 
export const updateInstruction = (data) => {
    return {
        type: UPDATE_INSTRUCTION,
        payload: data
    }
}
export const updateInstructionSuccess = (data) => {
    return {
        type: UPDATE_INSTRUCTION_SUCCESS,
        payload: data
    }
}
export const updateInstructionFail = (error) => {
    return {
        type: UPDATE_INSTRUCTION_FAIL,
        payload: error
    }
}
export const clearupdateInstruction = () => {
    return {
        type: UPDATE_INSTRUCTION_CLEAR
    }
}