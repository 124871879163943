export const GET_SCORECARD_REPORT = "GET_SCORECARD_REPORT"
export const GET_SCORECARD_REPORT_SUCCESS = "GET_SCORECARD_REPORT_SUCCESS"
export const GET_SCORECARD_REPORT_FAIL = "GET_SCORECARD_REPORT_FAIL"
export const CLEAR_GET_SCORECARD_REPORT = "CLEAR_GET_SCORECARD_REPORT"
export const UPDATE_GET_SCORECARD_REPORT = "UPDATE_GET_SCORECARD_REPORT"



export const CREATE_SCORECARD_REPORT = "CREATE_SCORECARD_REPORT"
export const CREATE_SCORECARD_REPORT_SUCCESS = "CREATE_SCORECARD_REPORT_SUCCESS"
export const CREATE_SCORECARD_REPORT_FAIL = "CREATE_SCORECARD_REPORT_FAIL"
export const CLEAR_CREATE_SCORECARD_REPORT = "CLEAR_CREATE_SCORECARD_REPORT"


export const UPDATE_SCORECARD_REPORT = "UPDATE_SCORECARD_REPORT"
export const UPDATE_SCORECARD_REPORT_SUCCESS = "UPDATE_SCORECARD_REPORT_SUCCESS"
export const UPDATE_SCORECARD_REPORT_FAIL = "UPDATE_SCORECARD_REPORT_FAIL"
export const CLEAR_UPDATE_SCORECARD_REPORT = "CLEAR_UPDATE_SCORECARD_REPORT"

// Window In-Activity handler:

export const SET_WINDOW_INACTIVITY = "SET_WINDOW_INACTIVITY"

// handle close editing:
export const CLOSE_REPORT_EDITING = "CLOSE_REPORT_EDITING"

//Unsaved Chnages Alert:

export const UNSAVED_CHANGES = "UNSAVED_CHANGES"

export const CHNAGES_HAPPEND = "CHNAGES_HAPPEND"

export const GET_ROUTES = "GET_ROUTES"


// session Time out handler: 
export const SET_SESSION_TIMEOUT = "SET_SESSION_TIMEOUT"

// user exit after 20 min inactivity from Report Editing or Take A Survey page
export const IS_USER_EXITED = "IS_USER_EXITED"