import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap";
import '../../config_v0_1/style.config.js'
import "../../config_v0_1/style.config.css"
import ReactHtmlParser from "react-html-parser"
import { REPORT, REPORT_PAGE } from '../../config_v0_1/style.config';
const PAFCard = (props) => {
    const [CardColor, setCardColor] = useState([])
    const PROPS = props;
    const { Index, Title, Icon, Description } = PROPS;

    useEffect(() => {
        setCardColor([
            REPORT.COLOR.PRIORITY_1,
            REPORT.COLOR.PRIORITY_2,
            REPORT.COLOR.PRIORITY_3,
            REPORT.COLOR.PRIORITY_4,
        ])
    }, [PROPS.Index])
    return (
        <div>
            <div style={{
                width: '100%',
                height: REPORT.PRIORITY_CARDS_HEIGHT.MAIN,
                margin: '10px 10px 20px 7px',
                backgroundColor: CardColor[0] ? CardColor[Index] : "#fff",
            }}>
                <Row>
                    <Col md="12">
                        <div style={{
                            width: '100%',
                            height: REPORT.PRIORITY_CARDS_HEIGHT.DIV_ONE,
                        }}>
                            <img src={Icon} style={{
                                margin: '17px 0 0 143px',
                                height: '80px'
                            }} />
                        </div> 
                    </Col> 
                    <Col md="12">
                        <div style={{
                            width: '100%',
                            height: REPORT.PRIORITY_CARDS_HEIGHT.DIV_TWO,
                            lineHeight: '23px',
                            fontSize: '20px',
                            fontFamily:REPORT.FONT_FACE.PARAGRAPH,
                            fontWeight: 600,
                            padding: '4px 4px 0 4px',
                            textAlign:'center',
                            color:'#0D0F11'
                            // border: '1px solid black'
                        }}>
                            <p style={{marginTop:'20px'}}>{Title}</p>
                        </div>
                    </Col>
                    <Col md="12">
                        <div style={{
                            // border: '1px solid black',
                            padding: '0 18px 5px 18px',
                            textAlign: 'center',
                            // fontSize: REPORT.FONT_SIZE.XSMALL,
                            Width: '100%',
                            Height: REPORT.PRIORITY_CARDS_HEIGHT.DIV_THREE,
                            fontWeight: 500,
                            lineHeight:'1.15',
                            resize: 'vertical'
                        }}
                        className="--PAF-Card"
                        >
                            <div
                                style={{
                                    textAlign: 'center',
                                    backgroundColor: CardColor[0] ? CardColor[Index] : "#fff",
                                    border: 'none',
                                    resize: 'none',
                                    width: '100%',
                                    overflowY: 'hidden',
                                    // border: '1px solid black',
                                    height: REPORT.PRIORITY_CARDS_HEIGHT.DIV_THREE,
                                    fontFamily:REPORT.FONT_FACE.PARAGRAPH,
                                    fontSize:REPORT.FONT_SIZE.MEDIUM,
                                    padding:'-5px 7px 0 7px',
                                    marginTop:'25px',
                                    color:"#0D0F11"
                                }}
                                className="SavedPAFCard"
                                >

                                {ReactHtmlParser(Description && Description)}
                                {/* (.replace(/(<([^>]+)>)/gi, "") */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default PAFCard;