import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import { Row, Col, Card, CardBody, CardTitle, ButtonDropdown, Button, Spinner } from "reactstrap"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import NotFound from "components/Common/NotFound/NotFound.component";
import { SUPER_ADMIN } from "config/AlertMessage.config";

const products = [
  { promocode: "BF2021", discount: 1, user: 10, validity: 0, action: <div style={{ textAlign: "center" }}><i className="bx bx-show" style={{ fontSize: 20, color: "#3D57A3" }}></i></div> },
  { promocode: "22BVN2", discount: 5, user: 5, validity: 2, action: <div style={{ textAlign: "center" }}><i className="bx bx-show" style={{ fontSize: 20, color: "#3D57A3" }}></i></div> },
  { promocode: "WE109E", discount: 20, user: 9, validity: 5, action: <div style={{ textAlign: "center" }}><i className="bx bx-show" style={{ fontSize: 20, color: "#3D57A3" }}></i></div> },
  { promocode: "PRO209", discount: 50, user: 35, validity: 15, action: <div style={{ textAlign: "center" }}><i className="bx bx-show" style={{ fontSize: 20, color: "#3D57A3" }}></i></div> },
  { promocode: "2022MK", discount: 100, user: 20, validity: 35, action: <div style={{ textAlign: "center" }}><i className="bx bx-show" style={{ fontSize: 20, color: "#3D57A3" }}></i></div> },
]

const columns = [
  {
    dataField: "promocode",
    text: "Promo Code",
  },
  {
    dataField: "discount",
    text: "Discount Applicable(%)",
  },
  {
    dataField: "user",
    text: "No. of Unique Users",
  },
  {
    dataField: "validity",
    text: "Validity",
  },
  {
    dataField: "action",
    text: "Availed By"

  },


]

const CreateCoupon = () => {
  const [isDataAvail, setIsDataAvail] = useState(true)
  useEffect(() => {
    var WaitForData = setTimeout(() => setIsDataAvail(false), 5000)
    // if (scorecardsList[0]) {
    //   var search_bar = document.querySelector("#search-bar-0")
    //   search_bar.setAttribute("autoComplete", "off")
    //   clearTimeout(WaitForData)
    // }

  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Create Coupon</title>
        </MetaTags>
        <div className="container-fluid">

          <div style={
            {
              width: "100%",
              textAlign: "center",
              height: "400px"
            }
          } >
            {
              !isDataAvail ?
                <NotFound Message={SUPER_ADMIN.EMPTY_COUPON} Icon={() => (
                  <i className="mdi mdi-ticket-percent"
                  style={{
                      borderBottom: "1px solid #000"
                    }}
                  ></i>
                )} 
                btnText = "Create Coupon" 
                IsButton
                />
                :
                <Spinner
                  style={{
                    width: "60px", height: "60px",
                    margin: "calc(200px - 30px)",

                  }}
                  role="grid"
                  type="border"
                  color="primary"
                />
            }
          </div>
          {/* <Row>
            <Col>
              <Card>
                <CardBody style ={{textAlign:"center"}}>
                  <CardTitle></CardTitle>

                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="id"
                      data={products}
                      columns={columns}
                      
                      
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Button className="mb-3" style = {{background:'#3D57A3',color:'white', float:'right',width:"120px" }} >Create</Button> */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateCoupon
