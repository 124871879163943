import { React, useCallback, useState, useEffect } from "react"
import {
  DialogActions,
  DialogContent,
  Dialog,
  Typography,
  Button,
  Slider,
} from "@mui/material"
import Cropper from "react-easy-crop"
import { Box, width } from "@mui/system"
import { Cancel } from "@mui/icons-material"
import CropIcon from "@mui/icons-material/Crop"
import getCroppedImg from "./utils/CropImage"
import { getOrientation } from "get-orientation/browser"
import images from "assets/images"
import { imageUploadHandler } from "../../commonFunctions/UploadImage/uploadImage"
import ImagePreview from "../../components/Common/ImagePreview/ImagePreview.component"
import { Row, Col, Card, CardBody, FormGroup, Container } from "reactstrap"
import ImageThumbnail from "components/Common/ImageThumbnail/ImageThumbnail.component"
import { createThumbnail } from "commonFunctions/CreateThumbnails/createThumbnail"
import { flip, round } from "lodash"
import { REPORT } from "config/report.config"
import { Switch } from "react-router-dom"

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
}

const CropEasy = props => {
  var para = props
  // console.log("PARAMS...", props)
  const {
    file,
    url,
    openCrop,
    image_url,
    setImageUrl,
    setFile,
    setOpenCrop,
    imageName,
    setIconThumbnail,
    ImageHeightWidth,
    ImageType,
    image_storagePath,
    thumbnail_storagePath,
    isImageThere,
    setImageThere
  } = para

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState("")

  const [rotation, setRotation] = useState(0)
  // const [imageSrc, setImageSrc] = useState(null);

  const [data, setData] = useState(null)
  const [page, setPage] = useState([])

  const cropComplate = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  const [cropperHeight, setHeight] = useState()
  const [cropperWidth, setWidth] = useState()

  const cropImage = async () => {
    try {
      const data = await getCroppedImg(image_url, croppedAreaPixels)
      var image_name = imageName.split(".")[0] + (Math.random()).toString().split(".")[1] + "." + imageName.split(".")[1]

      imageUploadHandler(
        image_storagePath,
        image_name,
        data.file,
        setImageUrl,
        () => setFile(null),
      )

      thumbnail_storagePath && createThumbnail(
        thumbnail_storagePath,
        image_name,
        data.file,
        setIconThumbnail,
        false,
        "jpeg/png",
      )

      setOpenCrop(false)
      //setCroppedImage(croppedImage)
      // console.log(file.name)
      // console.log(data)
    } catch (error) {
      // console.log(error)
    }
  }
  // useEffect(() =>{
  //   if(data.file){

  //   }
  // })

  //
  // useEffect(() => {
  //   if (data.file) {
  //     imageUploadHandler("/ContentEditor/images/", imageName,  data.file, setImageUrl,  () => setFile(null))
  //     createThumbnail(  "/ContentEditor/Thumbnail/", imageName, data.file, setIconThumbnail, false, "jpeg")
  //   }
  // }, [data.file])

  const getImageHeightWidth = ImageType => {
    switch (ImageType) {
      case "BACKGROUND":
        return {
          height: REPORT.IMAGE.BACKGROUND.HEIGHT,
          width: REPORT.IMAGE.BACKGROUND.WIDTH,
        }
      case "EXECUTIVE":
        return {
          height: REPORT.IMAGE.EXECUTIVE.HEIGHT,
          width: REPORT.IMAGE.EXECUTIVE.WIDTH,
        }
      case "CHANNEL":
        return {
          height: REPORT.IMAGE.CHANNEL.HEIGHT,
          width: REPORT.IMAGE.CHANNEL.WIDTH,
        }

      case "PRIORITY":
        return {
          height: REPORT.IMAGE.PRIORITY.HEIGHT,
          width: REPORT.IMAGE.PRIORITY.WIDTH,
        }

      case "KEY_OBSERVATIONS":
        return {
          height: REPORT.IMAGE.KEY_OBSERVATIONS.HEIGHT,
          width: REPORT.IMAGE.KEY_OBSERVATIONS.WIDTH,
        }

      case "QUICK_WINS":
        return {
          height: REPORT.IMAGE.QUICK_WINS.HEIGHT,
          width: REPORT.IMAGE.QUICK_WINS.WIDTH,
        }

      case "KEY_TAKEAWAYS":
        return {
          height: REPORT.IMAGE.KEY_TAKEAWAYS.HEIGHT,
          width: REPORT.IMAGE.KEY_TAKEAWAYS.WIDTH,
        }

      case "REPORT":
        return {
          height: REPORT.IMAGE.REPORT.HEIGHT,
          width: REPORT.IMAGE.REPORT.WIDTH,
        }
    }
  }

  useEffect(() => {
    var myWidthHeight = getImageHeightWidth(ImageType)
    setHeight(myWidthHeight.height)
    setWidth(myWidthHeight.width)
    // console.log("Container Height" + myWidthHeight.height)
    // console.log("Container Width" + myWidthHeight.width)
  }, [])

  // console.log(
  //   "Image Hieght : " +
  //   ImageHeightWidth.myheight +
  //   "  " +
  //   "Image Width : " +
  //   ImageHeightWidth.mywidth
  // )

  return (
    <div>
      <Container fluid={true}>
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col
                  md="8"
                  style={{
                    height: cropperHeight,
                    width: cropperWidth,
                    // marginTop: "5px",
                    position: "relative",
                    margin: "auto",
                  }}
                >
                  {/* {ImageHeightWidth.myheight < ImageHeightWidth.mywidth} */}

                  <Cropper
                    image={image_url}
                    crop={crop}
                    zoom={zoom}
                    //  height={cropperHeight}
                    //  width={cropperWidth}
                    aspect={cropperWidth / cropperHeight}
                    // aspect = {4/3}
                    objectFit="vertical-cover"
                    restrictPosition="true"
                    //  cropShape="rect"

                    onZoomChange={setZoom}
                    onCropChange={setCrop}
                    onCropComplete={cropComplate}
                  />

                  {/* {ImageHeightWidth.myheight === ImageHeightWidth.mywidth && (
                 <Cropper
                   image={image_url}
                   crop={crop}
                   zoom={zoom}
                   // cropShape = 'rect'
                   aspect={1}
                    objectFit = 'contain'
                   restrictPosition="false"
                   onZoomChange={setZoom}
                   onCropChange={setCrop}
                   onCropComplete={cropComplate}
                 />
                )}
               {ImageHeightWidth.myheight > ImageHeightWidth.mywidth && (
                 <Cropper
                   image={image_url}
                   crop={crop}
                   zoom={zoom}
                   // cropShape = 'rect'
                   aspect={cropperHeight/cropperWidth}
                    objectFit = 'contain'
                   restrictPosition="false"
                   onZoomChange={setZoom}
                   onCropChange={setCrop}
                   onCropComplete={cropComplate}
                 />
               )}  */}
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Typography>Zoom: {zoomPercent(zoom)}</Typography>
                  <Slider
                    // orientation="vertical"
                    valueLabelDisplay="auto"
                    valueLabelFormat={zoomPercent}
                    min={0.35}
                    max={2}
                    step={0.05}
                    value={zoom}
                    onChange={(e, zoom) => setZoom(zoom)}
                    classes={{ container: "slider" }}
                  ></Slider>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Row>
                    <Col md="4">
                      <Button
                        variant="outlined"
                        startIcon={<Cancel />}
                        onClick={() => {
                          setOpenCrop(false)


                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col md="2"></Col>
                    <Col md="6" style={{ textAlign: 'right' }}>

                      <Button
                        variant="contained"
                        startIcon={<CropIcon />}
                        onClick={() => {
                          cropImage()
                          // console.log(cropImage())
                          setOpenCrop(true)
                          setImageThere(true)
                          // setIconThumbnail && setIconThumbnail(null)
                        }}
                      >
                        Crop & Upload
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </Container>

      <br />
    </div>
  )

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener("load", () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }
}

export default CropEasy

const zoomPercent = value => {
  return `${Math.round(value * 100)}%`
}
