import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter, useParams, useHistory } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Label,
  FormFeedback,
  Button,
  UncontrolledAlert,
} from "reactstrap"
import { isEmpty, map } from "lodash"
import * as Yup from "yup"
import { Formik, useFormik } from "formik" //Import Breadcrumb
import Switch from "react-switch"
import SweetAlert from "react-bootstrap-sweetalert"
import { contentEditorLimit } from "config/UserCreationLimit_config"

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb";
//Import Image
//import logo from "../../assets/images/logo-dark.png";
import {
  getEditordataFail,
  getEditorDetails as onGetEditorDetails,
  getContentEditorData,
  ClearContentEditorDetails,
  updateContentEditorDetailsState,
  clearContentEdiotosDataState,
} from "store/actions"

import { updateEditorData, clearUpdateEditor } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import { SUPER_ADMIN } from "config/AlertMessage.config"

const EditorDetail = props => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [success_msg, setsuccess_msg] = useState(false)
  const [isEditorAdd, setIsEditorAdd] = useState(false)
  const [isEditorUpdate, setIsEditorUpdate] = useState(false)
  const [isEditorDisabled, setIsEditorDisabled] = useState(false)
  const [limitExceed, setLimitExceed] = useState(false)
  const [HandleContentEditorDetails, setContentEditorDetails] = useState({})

  const { editorDetails, updateEditor, editorData } = useSelector(state => ({
    editorDetails: state.EditorDetails.editorDetails,
    updateEditor: state.UpdateAccountReducer.updateEditor,
    editorData: state.EditorListReducer.editorData,
  }))

  //   const {
  //     match: { params },
  //   } = props;

  const ContentCreatorsLimit = () => {
    return editorData.filter(editor => editor.isDisabled === false).length
  }

  // console.log(ContentCreatorsLimit())
  //   console.log("All editors data...", editorData)
  // console.log("Selected Editor...", editorDetails) // single editor

  useEffect(() => {
    if (params && params.userId) {
      dispatch(onGetEditorDetails(params.userId))
    }

    dispatch(getContentEditorData())

    return () => {
      dispatch(ClearContentEditorDetails())
      dispatch(clearContentEdiotosDataState())
    }
  }, [dispatch])

  useEffect(() => {
    setIsEditorDisabled(!editorDetails.isDisabled)

    // return () => dispatch(clearContentEdiotosDataState())
  }, [editorDetails])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      userName: (editorDetails && editorDetails.contactName) || "",
      contactEmail: (editorDetails && editorDetails.contactEmail) || "",
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please enter user name"),
      contactEmail: Yup.string()
        .email("Please enter valid email !")
        .required("Please enter contact  email"),
    }),

    onSubmit: values => {
      var date = new Date()
      var user = JSON.parse(localStorage.getItem("authUser"))

      const myEditorData = {
        contactName: values.userName,
        contactEmail: values.contactEmail,
        createdOn: date,
        createdBy: user.email,
        creatorId: user.uid,

        isDisabled: !isEditorDisabled,
      }
      setContentEditorDetails(myEditorData)
      setIsEditorAdd(true)
      dispatch(updateEditorData(params.userId, myEditorData))
    },
  })

  // if(updateEditor[0] && updateEditor[0].status === 200){
  //   setTimeout(()=>{
  //     validation.values.userName = ""
  //     validation.values.contactEmail = ""

  //     dispatch(clearUpdateEditor())
  //     setIsEditorAdd(false)
  //     setTimeout(() => {
  //       history.push("/dashboard-content-editor-list")
  //   }, 2000)

  //   }, 2000)

  // }

  // if(updateEditor[0] && updateEditor[0].status !== 200){
  //   setTimeout(() => {

  //       dispatch(clearUpdateEditor())
  //       setIsEditorAdd(false)

  //    }, 2000)
  // }

  //console.log(updateEditor.isDisabled)
  // console.log("Detail...",editorDetails)

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          marginRight: "34px",
        }}
      >
        {" "}
        Disabled
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 14,
          color: "#fff",
          marginLeft: "34px",
        }}
      >
        {" "}
        Enabled
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mambo - Content Editor Details</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Row>
              <Col className="col-12">
                {!Object.keys(editorDetails).includes("id") ? (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      height: "400px",
                    }}
                  >
                    <Spinner
                      style={{
                        width: "60px",
                        height: "60px",
                        margin: "calc(200px - 30px)",
                      }}
                      role="grid"
                      type="border"
                      color="primary"
                    />
                  </div>
                ) : (
                  <Card>
                    <CardBody>
                      {/* { updateEditor [0] && updateEditor[0].status === 200 &&
                            <Alert color="success" role="alert" className='alert-error' >
                                Content Editor updated successfully !
                            </Alert>
                            }
                            {updateEditor[0] && updateEditor[0].status === 403 &&
                            <Alert color="danger" role="alert" className='alert-error' >
                                Failed to update  !
                            </Alert>
                        } */}
                      {updateEditor[0] && updateEditor[0].status === 200 && (
                        <SweetAlert
                          style={{
                            height: "255px",
                            width: "368px",
                          }}
                          // title="Updated successfully"
                          success
                          confirmBtnBsStyle="primary"
                          onConfirm={() => {
                            dispatch(updateContentEditorDetailsState({
                              ...HandleContentEditorDetails,
                              id: params.userId
                            }))
                            setsuccess_msg(false)
                            dispatch(clearUpdateEditor())
                            setIsEditorAdd(false)

                            // history.push("/dashboard-content-editor-list")
                          }}
                        >
                          {SUPER_ADMIN.UPDATE_CONTENT_EDITOR.SUCCESS}
                        </SweetAlert>
                      )}

                      {updateEditor[0] && updateEditor[0].status === 403 && (
                        <SweetAlert
                          // title="Failed to update "
                          error
                          confirmBtnBsStyle="primary"
                          style={{
                            height: "255px",
                            width: "368px",
                          }}
                          onConfirm={() => {
                            setsuccess_msg(false)
                            setIsEditorAdd(false)
                            dispatch(clearUpdateEditor())
                            setIsEditorUpdate(true)
                          }}
                        >
                          {SUPER_ADMIN.UPDATE_CONTENT_EDITOR.FAILED}
                        </SweetAlert>
                      )}
                      <Form
                        className="needs-validation outer-repeater"
                        autoComplete="off"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col md="10">
                            <CardTitle
                              className="h4"
                              style={{ fontSize: "20px", fontWeight: 500 }}
                            >
                              <p>
                                <i className="mdi mdi-account-circle"></i>{" "}
                                &nbsp;
                                {(isEditorUpdate &&
                                  validation.values.userName) ||
                                  (editorDetails && editorDetails.contactName ? editorDetails.contactName : editorDetails.userName)}
                              </p>
                            </CardTitle>
                          </Col>

                          <Col md="1">
                            {
                              isEditorUpdate ? (
                                <i
                                  className="bx bx-undo text-primary"
                                  style={{
                                    fontSize: "30px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setIsEditorUpdate(false)}
                                ></i>
                              ) : (
                                <i
                                  className="fas fa-pencil-alt text-light"
                                  style={{
                                    textAlign: 'center',
                                    fontSize: "12px",
                                    width: '28px',
                                    height: '24px',
                                    cursor: "pointer",
                                    borderRadius: '2px',
                                    background: '#3D57A3',
                                    padding: '6px'
                                  }}
                                  onClick={() => setIsEditorUpdate(true)}
                                ></i>
                              )
                              // <></>
                            }
                          </Col>
                          {/* <Col md = "1">
                            <i className = "fas fa-trash-alt text-danger" style={{ fontSize: "14px", cursor: "pointer" }}  ></i>

                        </Col> */}
                        </Row>
                        <Row>
                          <Col md="12">
                            <Row>
                              <Col md="6">
                                {isEditorUpdate && (
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">
                                      User Name
                                    </Label>
                                    <Input
                                      name="userName"
                                      placeholder="user name"
                                      type="text"
                                      disabled={isEditorAdd}
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.userName || ""}
                                      invalid={
                                        validation.touched.userName &&
                                          validation.errors.userName
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.userName &&
                                      validation.errors.userName ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.userName}
                                      </FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                {!isEditorUpdate ? (
                                  <p style={{ fontSize: "14px", marginTop: -15 }}>
                                    <i className="mdi mdi-email" style={{ fontSize: "18px", paddingRight: '7px' }}></i>
                                    &nbsp;
                                    {editorDetails &&
                                      editorDetails.contactEmail}
                                  </p>
                                ) : (
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">
                                      Email
                                    </Label>
                                    <Input
                                      name="contactEmail"
                                      placeholder="Contact Email"
                                      type="text"
                                      disabled={isEditorAdd}
                                      className="form-control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.contactEmail || ""
                                      }
                                      invalid={
                                        validation.touched.contactEmail &&
                                          validation.errors.contactEmail
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.contactEmail &&
                                      validation.errors.contactEmail ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.contactEmail}
                                      </FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                )}
                              </Col>
                            </Row>

                            <Row>
                              <Col md="4">
                                {
                                  isEditorUpdate && (
                                    <>
                                      <div
                                        style={{
                                          padding: "6px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            marginRight: "8px",
                                          }}
                                        >
                                          {" "}
                                          User Status:{" "}
                                        </span>
                                        <Switch
                                          uncheckedIcon={<Offsymbol />}
                                          checkedIcon={<OnSymbol />}
                                          className="me-2 mb-sm-8 mb-4"
                                          onColor="#3d57a3"
                                          width={100}
                                          onChange={e => {
                                            // console.log(
                                            //   "Editors limit...",
                                            //   contentEditorLimit
                                            // )
                                            if (
                                              editorDetails.isDisabled ===
                                              true &&
                                              ContentCreatorsLimit() <
                                              contentEditorLimit
                                            ) {
                                              setIsEditorDisabled(p => !p)
                                            } else if (
                                              editorDetails.isDisabled === false
                                            ) {
                                              setIsEditorDisabled(p => !p)
                                            } else {
                                              setLimitExceed(true)
                                            }
                                          }}
                                          checked={isEditorDisabled}
                                        />
                                      </div>
                                    </>
                                  )
                                  // isAccountUpdate &&
                                  // <div className="form-check form-switch form-switch-lg mb-3">

                                  //     <input
                                  //     type="checkbox"
                                  //     className="form-check-input"
                                  //     id="customSwitchsizelg"
                                  //     //uncheckedIcon = {true}
                                  //     defaultChecked = { !accountDetails.isDisabled}
                                  //     onChange = {(e) => setIsAccountDisabled(!e.target.checked) }
                                  //     />
                                  //     <label
                                  //     className="form-check-label"
                                  //     htmlFor="customSwitchsizelg"
                                  //     >
                                  //                 Enable Account?
                                  //     </label>

                                  // </div>
                                }
                              </Col>
                              <Col md="2">
                                {
                                  isEditorUpdate && (
                                    <FormGroup className="mb-3">
                                      <Button
                                        color="primary"
                                        type="submit"
                                        disabled={isEditorAdd ? true : false}
                                        block
                                      >
                                        {isEditorAdd ? "" : "Update"}
                                        {isEditorAdd ? (
                                          <Spinner type="border" size="sm" />
                                        ) : null}
                                      </Button>
                                    </FormGroup>
                                  )
                                  // <FormGroup className="mb-3">
                                  //     <Button color="primary" type="submit"
                                  //     disabled = { isEditorAdd }
                                  //     block >
                                  //     { isEditorAdd ? "" : "Update"}
                                  //     {isEditorAdd?
                                  //     <Spinner
                                  //     // style={{ width: "0.8rem", height: "0.8rem" }}
                                  //     type="border"
                                  //     size = "sm"
                                  //     />
                                  //     :
                                  //     null
                                  //     }
                                  //     </Button>
                                  // </FormGroup>
                                }
                              </Col>
                              {limitExceed && (
                                <UncontrolledAlert
                                  color="danger"
                                  onClick={() => setLimitExceed(false)}
                                >
                                  {
                                    <strong>
                                      A maximum of 32 Content Editors is allowed. Disable any Content Editor to enable new.
                                    </strong>
                                  }
                                </UncontrolledAlert>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EditorDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(EditorDetail)
