import Compressor from "react-image-file-resizer"
import { imageUploadHandler } from "../UploadImage/uploadImage";
export const createThumbnail = (pathRef, imageName, imageData, setIconThumbnail, nullImageData, imageFormate) => {
    Compressor.imageFileResizer(
        imageData, // the file from input
        120, // width
        120, // height
        imageFormate, // compress format WEBP, JPEG, PNG
        80, // quality
        0, // rotation
        (uri) => {
            //   console.log(uri);
            // You upload logic goes here
            imageUploadHandler(pathRef, imageName, uri, setIconThumbnail, nullImageData)
        },
        "blob" // blob or base64 default base64
    );
}