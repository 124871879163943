import { generateFirebaseToken } from "./handleFirebaseToken"

export default function authHeader() {
  const obj_main = JSON.parse(localStorage.getItem("authUser"))
  var obj = JSON.parse(localStorage.getItem("access_token"))

  if (obj && obj.access_token) {
    generateFirebaseToken()
    return { Authorization: `Bearer ${obj.access_token}` }
  }
  else if (obj_main && obj_main.stsTokenManager.accessToken) {
    generateFirebaseToken()
    return { Authorization: `Bearer ${obj_main.stsTokenManager.accessToken}` }
    // return { Authorization: `Bearer ${obj.access_token}` }
  } else {
    return {}
  }
}
