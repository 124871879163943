import firebase from "firebase/compat"
import "firebase/storage"
import Compressor from "react-image-file-resizer"

export const imageUploadHandler = (pathRef, imageName, imageData, sendImageUrl, nullImageData) => {

    // console.log("setReportImage...", setReportImage)

    Compressor.imageFileResizer(
        imageData, // the file from input
        720, // width
        932, // height
        // imageFormate, // compress format WEBP, JPEG, PNG
        "WEBP",
        65, // quality
        0, // rotation
        (uri) => {
            //   console.log(uri);
            // You upload logic goes here
            // imageUploadHandler(pathRef, imageName, uri, setIconThumbnail, nullImageData)
            firebase
                .storage()
                .ref(`${pathRef}${imageName}`)
                .put(uri)
                .on(
                    "state_changed",
                    snap => {
                        console.log(snap)
                    },
                    err => {
                        console.log(err)
                    },
                    () => {
                        firebase
                            .storage()
                            .ref(pathRef)
                            .child(imageName)
                            .getDownloadURL()
                            .then(res => {
                                // console.log("Image URL...", res)
                                // setReportImage(res)
                                // setIcon(e => [...e, res])

                                sendImageUrl(res)
                                if (nullImageData) {
                                    nullImageData()
                                }
                                // setImageData(null)
                                // setImgName(e => e + 1)
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    }
                )
        },
        "blob" // blob or base64 default base64
    );



    // firebase
    //     .storage()
    //     .ref(`${pathRef}${imageName}`)
    //     .put(imageData)
    //     .on(
    //         "state_changed",
    //         snap => {
    //             console.log(snap)
    //         },
    //         err => {
    //             console.log(err)
    //         },
    //         () => {
    //             firebase
    //                 .storage()
    //                 .ref(pathRef)
    //                 .child(imageName)
    //                 .getDownloadURL()
    //                 .then(res => {
    //                     console.log(res)
    //                     // setIcon(e => [...e, res])

    //                     sendImageUrl(res)
    //                     if (nullImageData){
    //                         nullImageData()
    //                     }
    //                     // setImageData(null)
    //                     // setImgName(e => e + 1)
    //                 })
    //                 .catch(err => {
    //                     console.log(err)
    //                 })
    //         }
    //     )
};