import { color, fontSize, height } from "@mui/system"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Grid } from "@mui/material"
import img from "../../../../assets/images/reportScorcard/previewimg.png"
import cap from "../../../../assets/images/reportScorcard/capture.png"
import ReactHtmlParser from "react-html-parser"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import "../config/style.config.js"
import "../config/style.config.css"
import { REPORT, REPORT_PAGE } from "../config/style.config"
import PAFCard from "./component/PAFCard.component.jsx"

import {
  Table,
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
  Form,
  Input,
  Button,
  Tooltip,
} from "reactstrap"
import { Opacity } from "@mui/icons-material"
import Footer from "components/VerticalLayout/Footer"
import { executiveSummary } from "../ExecutiveSummary/data"
import { useSelector, useDispatch } from "react-redux"

import {
  getReportTemplate,
  UpdateScorecardReport,
  ClearUpdateScorecardReport,
  getScorecardReport,
  updateGetScorecardReport,
  handleUnsavedChanges,
  handleChangesHappend,
  updateScorecardDetail,
  getAllInstructionData,
} from "store/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import { useParams } from "react-router-dom"
import { ACCOUNT_USER } from "config/AlertMessage.config"
import updateSuccessIcon from "../../../../assets/images/NewPopupIcons/Scorecard created.png"
import updateFailedIcon from "../../../../assets/images/NewPopupIcons/FailedIcon.png"

const PriorityAreaOfFocus = () => {
  const params = useParams()
  const [image, setImage] = useState({ preview: "", raw: "" })
  const [test, setTest] = useState(null)
  const [editPriorityAreaOfFocus, setEditPriorityAreaOfFocus] = useState([])
  const [isSaved, setIsSaved] = useState(false)

  const [savePriorityArea, setSavePriorityArea] = useState({})

  const [priorityArea, setPriorityArea] = useState([])
  const [handlePriority, setHandlePriority] = useState([])

  // const [editCardData, setEditCardData] = useState([])
  const [enteredData, setEnteredData] = useState([])
  // const [edit, isEdit]

  // Tooltip functionality over save button of PAF Card
  const [tooltip, setTooltip] = useState(false)

  const [tooltipForSaveBtn, setTooltipForSaveBtn] = useState(false)
  const [tooltipForAddPageBtn, setTooltipForAddPageBtn] = useState(false)
  const [tooltipForInsertBtn, setTooltipForInsertBtn] =
    useState(false)

  const [tooltipForResetPageBtn, setTooltipForResetPageBtn] = useState(false)
  const [tooltipForImageUpload, setTooltipForImageUpload] = useState(false)
  const [tooltipForRemovePage, setTooltipForRemovePage] = useState(false)
  const [tooltipForEditBtn, setTooltipForEditBtn] = useState(false)
  const [tooltipData, setTooltipData] = useState({})

  const dispatch = useDispatch()

  const {
    getReport,
    scorecardReportUpdate,
    scorecardReport_data,
    isDataEdited,
    isThereChanges,
    getInstructionData,
  } = useSelector(state => ({
    getReport: state.createReportReducer.getReport,
    scorecardReportUpdate: state.ScorecardReport_Reducer.scorecardReportUpdate,
    scorecardReport_data: state.ScorecardReport_Reducer.scorecardReport_data,
    isDataEdited: state.ScorecardReport_Reducer.isDataEdited,
    isThereChanges: state.ScorecardReport_Reducer.isThereChanges,
    getInstructionData: state.instructionPanelReducer.getInstructionData,
  }))

  useEffect(() => {
    dispatch(getReportTemplate())
    if (scorecardReport_data.id !== params.scorecardId) {
      dispatch(getScorecardReport(params.scorecardId))
    }
    // dispatch(getScorecardReport(params.scorecardId || "UufUpT9yB9k5x7nMSwh7"))
  }, [dispatch])

  //   const ckRef = useRef()
  useEffect(() => {
    if (scorecardReport_data && scorecardReport_data.priorityAreaOfFocus) {
      var data = scorecardReport_data.priorityAreaOfFocus
      setEditPriorityAreaOfFocus(data)
      setHandlePriority(data)
    }
    // console.log("Priority area focus data.... ",editPriorityAreaOfFocus)
  }, [scorecardReport_data])

  useEffect(() => {
    if (!Object.keys(getInstructionData).includes("infoForAccountUser")) {
      dispatch(getAllInstructionData())
    }
  }, [dispatch])

  useEffect(() => {
    if (getInstructionData.infoForAccountUser !== undefined) {
      var data = {
        infoForInsertBtn: getInstructionData.infoForAccountUser.Priority_Areas
          .infoForInsertBtn
          ? getInstructionData.infoForAccountUser.Priority_Areas
              .infoForInsertBtn
          : "",
        infoForEditBtn: getInstructionData.infoForAccountUser.Priority_Areas
          .infoForEditBtn
          ? getInstructionData.infoForAccountUser.Priority_Areas.infoForEditBtn
          : "",
        infoForApplyBtn: getInstructionData.infoForAccountUser.Priority_Areas
          .infoForApplyBtn
          ? getInstructionData.infoForAccountUser.Priority_Areas.infoForApplyBtn
          : "",
        infoForResetPageBtn: getInstructionData.infoForAccountUser
          .Priority_Areas.infoForResetPageBtn
          ? getInstructionData.infoForAccountUser.Priority_Areas
              .infoForResetPageBtn
          : "",
        infoForSaveBtn: getInstructionData.infoForAccountUser.Priority_Areas
          .infoForSaveBtn
          ? getInstructionData.infoForAccountUser.Priority_Areas.infoForSaveBtn
          : "",
      }
      setTooltipData({ ...data })
    }
  }, [getInstructionData])

  const priorityApplyHandler = () => {
    var priorityContents = document.querySelectorAll(".priorityAreaContent")
    priorityContents.forEach(checkBox => {
      checkBox.checked = false
    })
    setEditPriorityAreaOfFocus(handlePriority)
    setPriorityArea([])
    dispatch(handleChangesHappend(true))

    //
  }

  const HandleSaveButton = () => {
    setIsSaved(true)
    if (savePriorityArea) {
      setSavePriorityArea(null)
      setIsSaved(false)
    } else {
      var data = {
        priorityAreaOfFocus: [...editPriorityAreaOfFocus],
        lastUpdatedHandleSaveButtonOn: {
          _miliseconds: 0,
          _seconds: new Date().getTime() / 1000,
        },
      }
      dispatch(
        updateScorecardDetail(params.scorecardId, {
          lastUpdatedOn: {
            _miliseconds: 0,
            _seconds: new Date().getTime() / 1000,
          },
        })
      )
      dispatch(UpdateScorecardReport(params.scorecardId, data))
      dispatch(handleChangesHappend(false))
    }
  }
  // console.log(editPriorityAreaOfFocus)

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Mambo -Report</title>
        </MetaTags>
        {/* {
          scorecardReportUpdate.status === 200 &&
          <SweetAlert
            style={{
              position: "absolute",
              top: 170,
              height: "255px", width: "368px"
            }}
            // title="Priority areas of focus saved successfully!"
            success
            confirmBtnBsStyle="primary"

            onConfirm={() => {
              dispatch(updateGetScorecardReport({
                ...scorecardReport_data,
                priorityAreaOfFocus: [
                  ...editPriorityAreaOfFocus
                ],
                lastUpdatedHandleSaveButtonOn: {
                  _miliseconds: 0,
                  _seconds: new Date().getTime() / 1000,
                },
              }))
              setIsSaved(false)
              setSavePriorityArea(editPriorityAreaOfFocus || " ")
              dispatch(ClearUpdateScorecardReport())
            }
            }
          >{ACCOUNT_USER.EDIT_REPORT.SUCCESS}
          </SweetAlert>
        } */}

        {scorecardReportUpdate.status === 200 && (
          <SweetAlert
            style={{ height: "270px", width: "270px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img
                    src={updateSuccessIcon}
                    style={{
                      height: "80px",
                      width: "85px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid mb-2"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "25px",
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.SUCCESS}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                <Button
                  block
                  size="sm"
                  className="mt-2"
                  style={{
                    background: "#0D0F11",
                    border: "#0D0F11",
                  }}
                  onClick={() => {
                    dispatch(
                      updateGetScorecardReport({
                        ...scorecardReport_data,
                        priorityAreaOfFocus: [...editPriorityAreaOfFocus],
                        lastUpdatedHandleSaveButtonOn: {
                          _miliseconds: 0,
                          _seconds: new Date().getTime() / 1000,
                        },
                      })
                    )
                    setIsSaved(false)
                    setTooltipForEditBtn(false)
                    setTooltipForResetPageBtn(false)
                    setTooltipForSaveBtn(false)
                    setSavePriorityArea(editPriorityAreaOfFocus || " ")
                    dispatch(ClearUpdateScorecardReport())
                  }}
                >
                  Close
                </Button>
              </div>
            }
          ></SweetAlert>
        )}

        {/* {
          scorecardReportUpdate.status === 403 &&

          <SweetAlert
            // title="Failed to save priority areas of focus!"
            error
            confirmBtnBsStyle="primary"
            style={{
              position: "absolute",
              top: 170,
              height: "255px", width: "368px"
            }}
            onConfirm={() => {

              setIsSaved(false)
              dispatch(ClearUpdateScorecardReport())

            }}
          >{ACCOUNT_USER.EDIT_REPORT.FAILED}
          </SweetAlert>

        } */}

        {scorecardReportUpdate.status === 403 && (
          <SweetAlert
            style={{ height: "270px", width: "270px" }}
            custom
            customIcon={
              <Row>
                <Col md="12" className="mb-4">
                  <img
                    src={updateFailedIcon}
                    style={{
                      height: "80px",
                      width: "80px",
                    }}
                  />
                </Col>
                <Col
                  md="12"
                  className="text-center container-fluid"
                  style={{
                    color: "#0D0F11",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "25px",
                  }}
                >
                  {ACCOUNT_USER.EDIT_REPORT.FAILED}
                </Col>
              </Row>
            }
            customButtons={
              <div className="container-fluid">
                <Button
                  block
                  size="sm"
                  className=""
                  style={{
                    background: "#0D0F11",
                    border: "#0D0F11",
                  }}
                  onClick={() => {
                    setIsSaved(false)
                    setTooltipForEditBtn(false)
                    setTooltipForResetPageBtn(false)
                    setTooltipForSaveBtn(false)
                    dispatch(ClearUpdateScorecardReport())
                  }}
                >
                  Close
                </Button>
              </div>
            }
          ></SweetAlert>
        )}

        <Container
          fluid={true}
          style={{
            transform: "scale(.8) translate(-130px, -132px) ",
            width: "1400px",
          }}
        >
          <Row>
            <Col className="col-12 m-auto ">
              <Card>
                <CardBody>
                  <Row>
                    <Row>
                      <Col md="3" className="mb-2">
                        <Button
                          className="btn btn-primary"
                          id="TooltipForResetPageBtn"
                          color="primary"
                          onClick={() => {
                            setEditPriorityAreaOfFocus([])
                            setSavePriorityArea(savePriorityArea ? null : {})
                            // setPriorityArea([])
                            dispatch(handleChangesHappend(true))
                          }}
                        >
                          Reset Page
                        </Button>
                        <Tooltip
                          style={{
                            backgroundColor: "#FEF3C7",
                            color: "#0D0F11",
                            zIndex: 1,
                          }}
                          placement="right"
                          isOpen={tooltipForResetPageBtn}
                          target="TooltipForResetPageBtn"
                          toggle={() => {
                            setTooltipForResetPageBtn(!tooltipForResetPageBtn)
                          }}
                        >
                          {tooltipData.infoForResetPageBtn}
                        </Tooltip>
                      </Col>
                      <Col md="9"></Col>
                    </Row>
                    <Col md="8">
                      <div
                        style={{
                          ...REPORT_PAGE,
                        }}
                      >
                        <Row className="g-0">
                          <Col md="12">
                            <div
                              style={{
                                width: "100%",
                                height: REPORT_PAGE.height,
                                // border: "1px solid grey",
                              }}
                            >
                              <Row>
                                <Col md="12">
                                  <div
                                    style={{
                                      width: "100%",
                                      margin: "auto",
                                      // width: "90%",
                                      height: "120px",
                                      // padding: "30px 0 25px 8px",
                                      padding: "30px 40px 0 40px",
                                      position: "relative",
                                      textTransform: "uppercase",
                                      marginBottom: "30px",
                                      // border: "1px solid grey",
                                      // padding: "15px 0 10px 30px",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontSize: REPORT.FONT_SIZE.XLARGE,
                                        fontWeight: 600,
                                        color: "#0D0F11",
                                        fontFamily: REPORT.FONT_FACE.HEADING,
                                        // paddingBottom: "8px",
                                        paddingBottom: "10px",
                                        letterSpacing: "2px",
                                        borderBottom: `4px solid ${REPORT.COLOR.FOOTER}`,
                                      }}
                                    >
                                      PRIORITY
                                      <span
                                        style={{
                                          fontFamily: REPORT.FONT_FACE.HEADING,
                                        }}
                                      >
                                        <br></br>AREAS OF FOCUS
                                      </span>
                                    </h2>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 10,
                                        padding: 6,
                                        width: "80px",
                                        // marginRight:'25px'
                                      }}
                                    >
                                      {!savePriorityArea ? (
                                        <>
                                          <Button
                                            color="primary"
                                            size="sm"
                                            id="TooltipSave"
                                            block
                                            onClick={HandleSaveButton}
                                            disabled={isSaved}
                                          >
                                            {isSaved ? "" : "Save"}
                                            {isSaved ? (
                                              <Spinner
                                                type="border"
                                                size="sm"
                                              />
                                            ) : null}
                                          </Button>
                                          <Tooltip
                                            style={{
                                              backgroundColor: "#FEF3C7",
                                              color: "#0D0F11",
                                              zIndex: 1,
                                            }}
                                            placement="left"
                                            isOpen={tooltipForSaveBtn}
                                            target="TooltipSave"
                                            toggle={() => {
                                              setTooltipForSaveBtn(
                                                !tooltipForSaveBtn
                                              )
                                            }}
                                          >
                                            {tooltipData.infoForSaveBtn}
                                          </Tooltip>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            color="primary"
                                            id="TooltipEdit"
                                            size="sm"
                                            block
                                            onClick={HandleSaveButton}
                                          >
                                            Edit
                                          </Button>
                                          <Tooltip
                                            style={{
                                              backgroundColor: "#FEF3C7",
                                              color: "#0D0F11",
                                              zIndex: 1,
                                            }}
                                            placement="left"
                                            isOpen={tooltipForEditBtn}
                                            target="TooltipEdit"
                                            toggle={() => {
                                              setTooltipForEditBtn(
                                                !tooltipForEditBtn
                                              )
                                            }}
                                          >
                                            {tooltipData.infoForEditBtn}
                                          </Tooltip>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              {/* Main Row for cards */}
                              <Row
                                style={{
                                  padding: "0 50px 22px 40px",
                                  color: "#0D0F11",
                                }}
                              >
                                {editPriorityAreaOfFocus[0]
                                  ? editPriorityAreaOfFocus &&
                                    editPriorityAreaOfFocus.map(
                                      (priority, i) => (
                                        <Col md="6" key={i}>
                                          <PAFCard
                                            Index={i}
                                            Title={priority.title}
                                            Icon={priority.icon}
                                            Description={priority.description}
                                            EnteredData={e => {
                                              // setEnteredData([e, ...enteredData])

                                              setEditPriorityAreaOfFocus(x => {
                                                var PriorityData = [...x]
                                                // console.log("PriorityData",PriorityData)
                                                PriorityData[i] = {
                                                  ...PriorityData[i],
                                                  description: e,
                                                }
                                                return PriorityData
                                              })
                                            }} // HandleEditCard = {(e) => setEditCardData(p => [...p, e])}
                                            EditMode={
                                              savePriorityArea ? true : false
                                            }
                                          />
                                        </Col>
                                      )
                                    )
                                  : [{}, {}, {}, {}].map((priority, i) => (
                                      <Col md="6" key={i}>
                                        <PAFCard
                                          Index={i}
                                          Title={priority.title}
                                          Icon={cap}
                                          EditMode={
                                            savePriorityArea ? true : false
                                          }
                                          EnteredData={e => {
                                            setEnteredData([e, ...enteredData])
                                          }}
                                          // HandleEditCard = {(e) => setEditCardData(p => [...p, e])}                       // ????
                                        />
                                      </Col>
                                    ))}
                              </Row>
                              <Col
                                md="12"
                                style={{
                                  // border: '1px solid black'
                                  position: "absolute",
                                  bottom: 0,
                                  zIndex: 100,
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    padding: "5px",
                                    fontSize: REPORT.FONT_SIZE.MEDIUM,
                                    paddingLeft: "40px",
                                    backgroundColor: REPORT.COLOR.FOOTER,
                                    opacity: 0.9,
                                    fontFamily: REPORT.FONT_FACE.HEADING,
                                    fontWeight: 600,
                                    letterSpacing: "2px",
                                    color: "white",
                                  }}
                                >
                                  PRIORITY AREAS OF FOCUS
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    {/* Select list */}
                    {!savePriorityArea && (
                      <Col md="4">
                        <Row>
                          <Col md="3"></Col>
                          <Col md="9">
                            <div
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                margin: "0 0 20px 0px",
                              }}
                            >
                              <img
                                src={img}
                                style={{
                                  height: "90px",
                                  width: "85px",
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <h5 style={{ color: "#0D0F11" }}>
                          Please Select Points From Below:
                        </h5>
                        <div
                          style={{
                            width: "100%",
                            height: "913px",
                            float: "right",
                            // border: "1px solid grey",
                            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, .1)",
                          }}
                        >
                          <Row>
                            <Col md="12">
                              <div
                                style={{
                                  width: "100%",
                                  height: "36px",
                                  padding: "4px",
                                  // border: "1px solid grey",
                                  boxShadow:
                                    "0px 4px 10px -2px rgba(0, 0, 0, .1)",
                                }}
                              >
                                <Row className="g-0">
                                  <Col md="8"></Col>
                                  <Col md="4">
                                    <Button
                                      size="sm"
                                      id = "TooltipInsert"
                                      color="primary"
                                      block
                                      onClick={priorityApplyHandler}
                                    >
                                      Insert Selected Content
                                    </Button>
                                    <Tooltip
                                      style={{
                                        backgroundColor: "#FEF3C7",
                                        color: "#0D0F11",
                                        zIndex: 1,
                                      }}
                                      placement="left"
                                      isOpen={tooltipForInsertBtn}
                                      target="TooltipInsert"
                                      toggle={() => {
                                        setTooltipForInsertBtn(!tooltipForInsertBtn)
                                      }}
                                    >
                                      {tooltipData.infoForInsertBtn}
                                    </Tooltip>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col md="12">
                              <div
                                style={{
                                  height: "877px",
                                  fontSize: REPORT.FONT_SIZE.SMALL,
                                  overflow: "auto",
                                  listStyleType: "none",
                                  overflowX: "hidden",
                                  // border:'1px solid black'
                                }}
                              >
                                <ul
                                  style={{
                                    listStyleType: "none",
                                    padding: "8px",
                                  }}
                                >
                                  {getReport.priorityAreaOfFocus &&
                                    getReport.priorityAreaOfFocus.map(
                                      (data, i) => (
                                        <li
                                          key={i}
                                          style={{
                                            marginBottom: "12px",
                                            paddingBottom: "5px",
                                            boxShadow:
                                              "0px 0px 15px 0px rgba(0, 0, 0, .1)",
                                          }}
                                        >
                                          <div>
                                            <Input
                                              className="priorityAreaContent"
                                              type="checkbox"
                                              value={data.description}
                                              style={{
                                                width: "17px",
                                                height: "16px",
                                                textAlign: "left",
                                                margin: "12px 5px 0 10px",
                                              }}
                                              onChange={e => {
                                                if (e.target.checked) {
                                                  if (
                                                    priorityArea.length <= 3
                                                  ) {
                                                    setPriorityArea(x => [
                                                      ...x,
                                                      {
                                                        id: i,
                                                        ...data,
                                                      },
                                                    ])
                                                    if (
                                                      handlePriority.length <= 3
                                                    ) {
                                                      setHandlePriority(x => [
                                                        ...x,
                                                        {
                                                          id: i,
                                                          ...data,
                                                        },
                                                      ])
                                                    } else if (
                                                      priorityArea.length < 1
                                                    ) {
                                                      setHandlePriority([
                                                        ...priorityArea,
                                                        {
                                                          id: i,
                                                          ...data,
                                                        },
                                                      ])
                                                    }
                                                  } else {
                                                    e.target.checked = false
                                                  }
                                                } else {
                                                  setPriorityArea(x =>
                                                    x.filter(
                                                      data_e => data_e.id != i
                                                    )
                                                  )
                                                }
                                              }}
                                            />
                                          </div>
                                          &nbsp;&nbsp;
                                          <p
                                            style={{
                                              fontSize: "17px",
                                              fontWeight: 600,
                                              textAlign: "left",
                                              margin: "-37px 0 5px 35px",
                                              color: "#0D0F11",
                                            }}
                                          >
                                            {data.title}
                                          </p>
                                          {!data.title && <br></br>}
                                          <p
                                            style={{
                                              paddingLeft: "12px",
                                              textAlign: "left",
                                              color: "#0D0F11",
                                            }}
                                          >
                                            {data.description
                                              ? data.description.replace(
                                                  /<\/?[^>]+(>|$)/g,
                                                  ""
                                                )
                                              : ""}
                                          </p>
                                        </li>
                                      )
                                    )}
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default PriorityAreaOfFocus
