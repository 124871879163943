import { takeEvery, put, call,all,fork  } from "redux-saga/effects";

// Login Redux States
import {
    GET_DEMO_DATA,
} from "./actionTypes"
import {
    getDemoDataSuccess,
    getDemoDataFail,
} from "./actions"

//Axios get call
import { getAccountUserData } from "../../helpers/fakebackend_helper";

function* fetchAccountUserData() {
  try {
    const response = yield call(getAccountUserData)
    yield put(getDemoDataSuccess(response))
  } catch (error) {
    yield put(getDemoDataFail(error))

  }
}
                                      
export function* watchFetchDemoData() {
  yield takeEvery(GET_DEMO_DATA, fetchAccountUserData);
}
                                      
function* accountUserSaga() {
  yield all([fork(watchFetchDemoData)]);
}
                                      
export default accountUserSaga;
