import { Chart } from "chart.js"
import { getChannelScore } from "commonFunctions/GetChannelScore/getChannelScore"
import React from "react"
import { Radar } from "react-chartjs-2"

const RadarChart = (props) => {
  const myProps = props
  const { DATA } = myProps

  Chart.defaults.global.defaultFontColor = "rgba(0, 0, 0, 1)"
  Chart.defaults.scale.gridLines.color = "rgba(127, 126, 126 ,1)";
  Chart.defaults.scale.gridLines.lineWidth = 2;
  
  Chart.defaults.global.tooltips.enabled = false
  Chart.defaults.global.legend.display = false
  // Chart.defaults.global.defaultFontStyle = 'bold'
  Chart.defaults.global.devicePixelRatio = 2

  var channelScore = DATA && [...DATA.selectedChannels]
    // .sort((a, b) => getChannelScore(b) - getChannelScore(a))
    .map(channel => {
      return getChannelScore(channel)
    }) || []
  var channelName = DATA && [...DATA.selectedChannels]
    // .sort((a, b) => getChannelScore(b) - getChannelScore(a))
    .map((channel, id) => {
      return channel.channelName
        

      
    }) || []

  const data = {
    labels: [
      ...channelName,
    ],
    datasets: [
      {
        label: "Channels",
        backgroundColor: "rgba(107, 185, 183, 0)",
        borderColor: "#3c57a3",
        borderWidth:4,
        pointBackgroundColor: "#3c57a3",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#34c38f",
        pointBorderColor: "rgba(0, 153, 247,1)",
        data: [...channelScore],
      },
      {
        label: "",
        backgroundColor: "rgba(52, 195, 143, 0)",
        borderColor: "rgba(52, 195, 143, 0)",
        pointBackgroundColor: "rgba(52, 195, 143, 0)",
        pointBorderColor: "rgba(52, 195, 143, 0)",
        pointHoverBackgroundColor: "rgba(52, 195, 143, 0)",
        pointHoverBorderColor: "rgba(52, 195, 143, 0)",
        data: [0, 20, 40, 60, 80, 100],
      },
    ],
  }

  const datasetKeyProvider = () => {
    return btoa(Math.random()).substring(0, 12)
  }
  return (
    <Radar 
      width={500} 
      height={330} 
      data={data} 
      datasetKeyProvider={datasetKeyProvider}
      options={{
        scale: {
          pointLabels: {
            fontSize: 13
          }
        },
        // maintainAspectRatio: false
      }}
    />
  )
  
}

export default RadarChart
