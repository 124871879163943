import React, { useEffect, useState } from 'react';
import {
  Table,
  Container, Row, Col,
  Alert,
  Card, CardBody, CardTitle, FormGroup, Spinner, Form, Input, Button, Modal
} from "reactstrap";
import { REPORT, REPORT_PAGE } from "../config_v0_1/style.config"
const TableContent = (props) => {
  const PROPS = props;
  const { Table_of_content } = PROPS

  return (
    <>
      <div style={REPORT_PAGE}>
        <Card style={{
          height: '1056px', width: '816px', transform: "scale(1)",
          backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${Table_of_content ?
              Table_of_content.tableOfContents.bgImage
              :
              ""} )`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}>
          <Row className="p-0 m-0">
            <Col xs='2'>
            </Col>
            <Col xs='9'></Col><Col xs='1'>
              <div>
                <input
                  type="file"
                  id="uploadImage"
                  name="Image_URL"
                  accept="image/*"
                  style={{ display: "none" }}
                  // onChange={handleChange}
                />
                <br />
              </div>
            </Col>
          </Row>
          <CardBody style={{ marginTop: 65, marginLeft: 60, fontFamily:REPORT.FONT_FACE.HEADING }}>
            <div><h1 style={{ color: 'white', fontSize: '45px',fontFamily:REPORT.FONT_FACE.HEADING }} >TABLE OF </h1>
              <label style={{ color: 'white', fontSize: '45px', borderBottom: "3px solid #FF6060",fontFamily:REPORT.FONT_FACE.HEADING }} >  CONTENTS</label>
            </div>

            <div style={{ marginTop: 35 }}>
              <p style={{ color: 'white', fontSize: '30px', fontWeight: '-100px',fontFamily:REPORT.FONT_FACE.HEADING }}>

                <label style={{ backgroundColor: 'rgba(235, 76, 39, 0.8)', width: '60px', border: '1px', padding: '10px', margin: '10px', textAlign: "center", }}>1</label>
                &nbsp; Scorecard Methodology</p>

              <p style={{ color: 'white', fontSize: '30px', marginTop: 15, fontFamily:REPORT.FONT_FACE.HEADING }}>
                <label style={{ backgroundColor: 'rgba(235, 76, 39, 0.8)', width: '60px', border: '1px', padding: '10px', margin: '10px', textAlign: "center", }}>2</label>
                &nbsp; Executive Summary</p>

              <p style={{ color: 'white', fontSize: '30px', marginTop: 15, fontFamily:REPORT.FONT_FACE.HEADING }}>
                <label style={{ backgroundColor: 'rgba(235, 76, 39, 0.8)', width: '60px', border: '1px', padding: '10px', margin: '10px', textAlign: "center", }}>3</label>
                &nbsp;Priority Areas of Focus</p>

              <p style={{ color: 'white', fontSize: '30px', marginTop: 15, fontFamily:REPORT.FONT_FACE.HEADING }}>
                <label style={{ backgroundColor: 'rgba(235, 76, 39, 0.8)', width: '60px', border: '1px', padding: '10px', margin: '10px', textAlign: "center", }}>4</label>
                &nbsp; Scores at a Glance</p>

              <p style={{ color: 'white', fontSize: '30px', marginTop: 15, fontFamily:REPORT.FONT_FACE.HEADING }}>
                <label style={{ backgroundColor: 'rgba(235, 76, 39, 0.8)', width: '60px', border: '1px', padding: '10px', margin: '10px', textAlign: "center", }}>5</label>
                &nbsp;Deep Dive into Your Channels</p>

              <p style={{ color: 'white', fontSize: '30px', marginTop: 15, fontFamily:REPORT.FONT_FACE.HEADING }}>
                <label style={{ backgroundColor: 'rgba(235, 76, 39, 0.8)', width: '60px', border: '1px', padding: '10px', margin: '10px', textAlign: "center", }}>6</label>
                &nbsp;Key Takeaways</p>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default TableContent