import React from "react"
import { Redirect, useHistory } from "react-router-dom"

// firebase
// import firebase from "firebase/compat"
// import "firebase/auth"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"





////Report
import pageReport from "pages/AccountUser/Report"
import TableOfContent from "pages/AccountUser/Report/TableOfContent/TableOfContent.page"
import LevelsOfMaturity from "pages/AccountUser/Report/LevelsOfMaturity/LevelsOfMaturity.page"
import ExecutiveSummaryPage from "pages/AccountUser/Report/ExecutiveSummary/ExecutiveSummary.page"
import PriorityAreaOfFocus from "pages/AccountUser/Report/PriorityAreaOfFocus"
import ScoreAtGlance from "pages/AccountUser/Report/ScoreAtGlance/ScoreAtGlance.page"
import KeyTakeawaysPage from "pages/AccountUser/Report/KeyTakeawaysPage/KeyTakeaways.page"
import CompanyInformation from "pages/AccountUser/Report/CompanyInformation/CompanyInformation.page"
// import  ParentRecommendation from "pages/AccountUser/Report/ChannelRecommendation/ParentRecommendation"
// import PageThirteen from "pages/AccountUser/Report/pageThirteen"
// ------------------------
import ScorecardMethodology from "pages/AccountUser/Report/ScorecardMethodology/ScorecardMehodology.page"
import ContentQuickWins from "pages/AccountUser/Report/ContentQuickWins/ContentQuickWins.page"
import KeyObservation from "pages/AccountUser/Report/KeyObservation/KeyObservation.page"
import WhatYouAreDoingWell from "pages/AccountUser/Report/WhatYouAreDoingWell/WhatYouAreDoingWell.page"
import ChannelRecommendation from "pages/AccountUser/Report/ChannelRecommendation/ChannelRecommendation.page"
import ChannelFirstPage from "pages/AccountUser/Report/ChannelFirstPage/ChannelFirstPage.page"
import ExecutiveSummaryPDFPage from "pages/PDFCreations/ExecutiveSummaryPDF/ExecutiveSummaryPage"
// ----------------------------
/////Accountuser page
import ViewUserData from "pages/AccountUser/ViewUserData"
// import Survey from "pages/AccountUser/takeSurvey/Survey"
// import contentMix from "pages/AccountUser/takeSurvey/contentMix"
// import personas from "pages/AccountUser/takeSurvey/personas"
// import brandGuidelines from "pages/AccountUser/takeSurvey/brandGuidelines"
// import keyMesseges from "pages/AccountUser/takeSurvey/keyMesseges"
// import people from "pages/AccountUser/takeSurvey/people"
// import planingTools from "pages/AccountUser/takeSurvey/planingTools"
// import contentAuditing from "pages/AccountUser/takeSurvey/contentAuditing"
// import Planning from "pages/AccountUser/takeSurvey/processPlaning"/

import ScorecardDetails from "pages/AccountOwner/ScorecardDetails"

import ChannelDescriptionFirstPage from "pages/ContentEditor/ChannelDescriptionFirstPage/component/channelDescriptionFirstPage.component"

// Dashboard

import Dashboard from "../pages/SuperAdmin/Dashboard/index"


// import Dashboard from "../pages/Dashboard/index"
import DatatableTables from "pages/Tables/DatatableTables"
//Content Editor Dashboard:
import DashboardContentEditor from "../pages/ContentEditor/Dashboard/index"
import DashboardChannelAndTactics from "../pages/ContentEditor/Dashboard/Channel_And_Tactics"

///ContentEditor|ViewEdit Channel
import ViewChannel from "pages/ContentEditor/viewChannel"



import FormValidations from "pages/ContentEditor/UpdateTactic"
//myPage
import MyPage from "../pages/myPage/index"

//Update tactic details
import Update_tactic from "pages/ContentEditor/UpdateTactic/Update_tactic"
//import DatatableTables from "pages/Tables/DatatableTables",

import SuperAdminTable from "pages/Tables/SuperAdminTable"

//import ContentEditorList
import ContentEditorList from "pages/Tables/ContentEditorList"


/////import sidebar accountholder transactions
import Transactions from "pages/AccountOwner/Transctions/Transactions"
/////import sidebaar accountholder credits
import Credits from "pages/AccountOwner/Credits/Credits"
///import sidebar for accountholder NewScoreCard
import NewScorecard from "pages/AccountOwner/CreateScorecard/NewScorecard"


// Instruction Panel Path
import InstructionForExecutiveSummary from "pages/ContentEditor/WriteInstructionForAllPages/writeInstructionForAccountUser/instructionForExecutiveSummary"
import InstructionForCoverPage from "pages/ContentEditor/WriteInstructionForAllPages/writeInstructionForAccountUser/instructionForCoverPage"
import InstructionForTableOfContents from "pages/ContentEditor/WriteInstructionForAllPages/writeInstructionForAccountUser/instructionForTableOfContent"
import Priority_Areas_Instruction from "pages/ContentEditor/WriteInstructionForAllPages/writeInstructionForAccountUser/Priority_Areas_Instruction"
import Key_Takeaways from "pages/ContentEditor/WriteInstructionForAllPages/Key_Takeaways"
import Observations from "pages/ContentEditor/WriteInstructionForAllPages/Observations"
import WhatYouAreDoing from "pages/ContentEditor/WriteInstructionForAllPages/WhatYouAreDoing"
import QuickWins from "pages/ContentEditor/WriteInstructionForAllPages/QuickWins"
import Recommendation from "pages/ContentEditor/WriteInstructionForAllPages/Recommendation"


//import Sidebar CreateUser Form
import CreateContentEditor from "pages/SuperAdmin/Forms/CreateContentEditor"

//Individual account details
import AccountDetail from "pages/SuperAdmin/AccountDetails/AccountDetail"

//SUPER-ADMIN
import EditorDetail from "pages/SuperAdmin/ContentEditorDetails/EditorDetail"



import AccountHolderForm from "pages/SuperAdmin/Forms/AccountHolderForm"

// import Sidebar Purchase History
//import LatestTransaction from "pages/PurchaseHistory/LatestTransaction"

//Demo Sidebar Transaction
import DemoTransaction from "pages/SuperAdmin/PurchaseHistory/DemoTransaction"

//Footer terms and conditions
import Terms from "pages/SuperAdmin/TermsConditions/Terms"

//Footer privacy policy
import Privacy from "pages/SuperAdmin/TermsConditions/Privacy"



//import Sidebar Manage Price
import ManagePrice from "pages/SuperAdmin/ManagePrice/ManagePrice"

//import Sidebar Create Coupon
import CreateCoupon from "pages/SuperAdmin/Coupon/CreateCoupon"

//Sidebar Contact US
import ContactUs from "pages/SuperAdmin/ContactUS/ContactUs"

//import Sidebar Help
import Help from "pages/SuperAdmin/Help/Help"

//var history = useHistory()

//SUPER ADMIN - Update Account Form
//import AccountUpdateForm from "pages/SuperAdmin/Forms/AccountUpdateForm" 

//
//import UpdateForm from "pages/SuperAdmin/Forms/UpdateForm"



//Create Account User:
import CreateAccountUser from "pages/AccountOwner/CreateAccountUser"
import ViewUsersDetails from "pages/AccountOwner/ViewUsersDetails"
import AccountHolderScorecardList from "pages/AccountOwner/ViewScorecard"
import CreateTactic from "pages/ContentEditor/CreateTactic"


/////Report ContentEditor
import Report from "pages/ContentEditor/Report"
import Summary from "pages/ContentEditor/Report/Summary"
import PriorityArea from "pages/ContentEditor/Report/PriorityArea"
import ContentQuick from "pages/ContentEditor/Report/ContentQuick"
import KeyTakeaways from "pages/ContentEditor/Report/KeyTakeaways"
import CreateScorecard from "pages/AccountOwner/CreateScorecard"
// import processPlaning from "pages/AccountUser/takeSurvey/processPlaning"
import TakeASurvey from "pages/AccountUser/TakeASurvey"

// const isVerified = checkRole()
// console.log(isVerified)

// const superAdminRoutes = [{path: "/dashboard", component: SuperAdminTable}]

//CK Editor testing
// import Editor from "pages/SuperAdmin/CkEditorTesting/Editor"


///chart
import RadarChart from "pages/AccountUser/chart/chart"


///Report | AccountUser Scoring Summary
import scoringSummary from "pages/AccountUser/scoringSummary"
import viewScoringSummary from "pages/AccountOwner/ViewScorecardSummary(New)/"

////Generate PDF
// import generatePDF from "pages/AccountUser/generatePDF/GeneratePdf"
import GeneratePDF from "pages/AccountUser/generatePDF/GeneratePdf"
import ScorecardMethododlogy from "pages/AccountUser/Report/ScorecardMethodology/ScorecardMehodology.page"
import ChannelTacticEvaluated from "pages/AccountUser/Report/ChannelTacticEvaluated/ChannelTacticEvaluated.page"
import SuccessPayment from "pages/Stripe/Success.page"
import CancelPayment from "pages/Stripe/Cancel.page"

//Account Owner - Checkout Page Failed Message
import FailedMessage from "components/Common/StripeSuccessMessage/FailedMessage"
import ArrowChart from "pages/BubbleChartPoc/BubbleChart/BubbleChart.componet"
import ArrangeChannel from "pages/ContentEditor/ArrangeChannel/ArrangeChannel"

//Account - owner - New Dashboard
import New_Dashboard from "pages/AccountOwner/New Dashboard/New_Dashboard"
import DashboardAccountOwner from "pages/AccountOwner/Dashboard"



const ContentEditor = [
  { path: "/create-tactic", component: CreateTactic },
  {path: "/Arrange-Sequence" , component: ArrangeChannel}

]



const authProtectedRoutes = [

  ...ContentEditor,
 
  // { path: "/dashboard-ah", component: DatatableTables },
  // {path: "/dashboard", component: SuperAdminTable},
  { path: "/create/user", component: CreateAccountUser },
  { path: "/users/:userId", component: ViewUsersDetails },

  { path: "/dashboard", component: MyPage },

  // {path: "/edit-details/:channelId/tactics/:tacticId" , component:FormValidations},
  { path: "/tactic-details/:channelId/tactics/:tacticId", component: Update_tactic },

  { path: "/arrow-chart", component: ArrowChart },

  // For i-button POC component
  { path: "/instructionForAccountUser/executiveSummary", component: InstructionForExecutiveSummary },
  { path: "/instructionForAccountUser/coverPage", component: InstructionForCoverPage },
  { path: "/instructionForAccountUser/tableOfContents", component: InstructionForTableOfContents },
  {path:"/instructionForAccountUser/priorityArea" , component: Priority_Areas_Instruction},
  {path:"/instructionForAccountUser/keyTakeaways" , component:Key_Takeaways},
  {path:"/instructionForAccountUser/keyObservations", component:Observations},
  {path:"/instructionForAccountUser/doingWell" ,component:WhatYouAreDoing},
  {path:"/instructionForAccountUser/quickWins", component:QuickWins},
  {path:"/instructionForAccountUser/recommendation", component:Recommendation},

  // ...isVerified,
  //  {path: "/dashboard", component: MyPage},
  //...isVerified,

  // create account user:
  // {path: "/scoringSummary", component: RadarChart},

  // //profile
  { path: "/profile", component: UserProfile },

  //ContentEditor List
  { path: "/dashboard-content-editor-list", component: ContentEditorList },

  //Sidebar CreateUser Form
  // { path: "/create-account-holder", component: AccountHolderForm },

  // create account owner route: v0.02
  { path: "/create/account-owner", component: AccountHolderForm },

  //{path:"/update-account-holder/:userId", component: UpdateForm},
  // { path: "/create-content-editor", component: CreateContentEditor },
  // create content editor route: v0.02
  { path: "/create/content-editor", component: CreateContentEditor },

  ////Create New ScoreCard-viewuserdata
  // { path: "/create/scorecard", component: ViewUserData },

 
 //Account Owner - Checkout Page Failed Message
 {path:"/cancel", component:FailedMessage},



  //////Create Survey Accountuser
  // { path: "/create/Survey", component: Survey },
  // { path: "/create/Content", component: contentMix },
  // { path: "/create/Personas", component: personas },
  // { path: "/create/Brand", component: brandGuidelines },
  // { path: "/create/Key", component: keyMesseges },
  // { path: "/create/people", component: people },
  // { path: "/create/planingTools", component: planingTools },
  // { path: "/create/contentAuditing", component: contentAuditing },
  // { path: "/create/planning", component: Planning },
  ///Report Path
  { path: "/Report", component: Report },
  { path: "/Summary", component: Summary },
  { path: "/PriorityArea", component: PriorityArea },
  { path: "/ContentQuick", component: ContentQuick },
  { path: "/KeyTakeaways", component: KeyTakeaways },


  //path Report Scoring Summary


  { path: "/scoringSummary/:scorecardId", component: scoringSummary },
  { path: "/viewScoringSummary/:scorecardId", component: viewScoringSummary},

  { path: "/channelPreview", component: ChannelDescriptionFirstPage },

  //// ContentEditor| ViewChannel

  // { path: `/channel-details/:channelId`, component: ViewChannel },
  // { path: "/channel-details//:channelId", component: ViewChannel },
  // ...channelRoutes,/
  // ...tacticRoutes,
  //Sidebar Purchase History
  {path: "/new-dashboard" , component: New_Dashboard},
  {path:"/users" , component: DashboardAccountOwner},

  { path: "/purchase-history", component: DemoTransaction },

  //Sidebar Manage Price
  { path: "/manage-price", component: ManagePrice },

  { path: "/create-coupon", component: CreateCoupon },

  //Sidebar Contact Us
  { path: "/contact-us", component: ContactUs },

  { path: "/view/scorecards", component: AccountHolderScorecardList },

  //ScoreCardDetails
  { path: "/ScorecardDetails", component: ScorecardDetails },
  //Sidebar Help
  { path: "/help", component: Help },

  //Footer terms and conditions
  { path: "/terms-conditions", component: Terms },
  { path: "/privacy-policy", component: Privacy },

  //Account-Details
  { path: "/accountowner/:userId", component: AccountDetail },
  //Content Editor Details

  { path: "/contenteditor/:userId", component: EditorDetail },

  //SUPER ADMIN -Account Update Form



  ////path for transactions Accountholder
  { path: "/Transactions", component: Transactions },
  ////path for Credits Accountholder
  { path: "/Credits", component: Credits },
  ///path for NewScorecard Accountholder
  { path: "/NewScorecard", component: CreateScorecard },

  // Create scorecard v.0.02
  { path: "/create/scorecard", component: CreateScorecard },
  { path: "/scorecard/update/:scorecardId", component: CreateScorecard },
  { path: "/view/scorecard/:scorecardId", component: ScorecardDetails },

  // -------------- Create Scorecard for Only for Self Report ---------------
  { path: "/create/self-report/scorecard", component: CreateScorecard },
  { path: "/scorecard/self-report/update/:scorecardId", component: CreateScorecard },
  // { path: "/take-a-survey/:scorecardId/:channelId/:tacticId", component: TakeASurvey },
  //CKEditor Testing
  //  {path: "/Editor-Testing" , component:Editor},
  //  {path:"/Editor-Testing/:userId", component : Editor},

  // {path: "/mypage", component: MyPage},

  //Content Editor:
  // {path: "/content-editor-dashboard", component: DashboardContentEditor},
  // {path: "/content-editor/create-channel-tactics", component: FormValidations },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },



  // Stripe payment routes.
  { path: "/success", component: SuccessPayment },
  { path: "/cancel", component: CancelPayment },



  ///report
  // { path: "/page-report/:scorecardId", component: pageReport },

  // Report page v0.01
  // { path: "/page-report", component: pageReport },
  // { path: "/TableOfContent", component: TableOfContent },
  // { path: "/ScorecardMethodology", component: ScorecardMethodology },
  // { path: "/LevelsOfMaturity", component: LevelsOfMaturity },
  // { path: "/ExecutiveSummaryPage", component: ExecutiveSummaryPage },
  // { path: "/pageSix", component: PageSix },
  // { path: "/ScoreAtGlance", component: ScoreAtGlance },
  // { path: "/KeyTakeawaysPage", component: KeyTakeawaysPage },
  // { path: "/ContentQuickWins", component: ContentQuickWins },
  // { path: "/KeyObservation", component: KeyObservation },
  // { path: "/WhatYouAreDoingWell", component: WhatYouAreDoingWell },
  // { path: "/ChannelRecommendation/:scorecardId", component: ChannelRecommendation },
  // { path: "/pageThirteen", component: PageThirteen },
  // { path: "/ChannelRecommendation", component: ChannelRecommendation },
  // { path: "/ChannelTacticEvaluated", component: ChannelTacticEvaluated },
  // { path: "/ChannelFirstPage", component: ChannelFirstPage },
  // { path: "/demoPDFPage", component: ExecutiveSummaryPDFPage },


  // Report page v0.02
  { path: "/edit/scorecard-report/:scorecardId/cover-page", component: pageReport },
  { path: "/edit/scorecard-report/:scorecardId/table-of-content", component: TableOfContent },
  { path: "/edit/scorecard-report/:scorecardId/scorecard-methodology", component: ScorecardMethododlogy },
  { path: "/edit/scorecard-report/:scorecardId/level-of-maturity-defined", component: LevelsOfMaturity },
  { path: "/edit/scorecard-report/:scorecardId/executive-summary", component: ExecutiveSummaryPage },
  { path: "/edit/scorecard-report/:scorecardId/priority-area-of-focus", component: PriorityAreaOfFocus },
  { path: "/edit/scorecard-report/:scorecardId/score-at-glance", component: ScoreAtGlance },
  { path: "/edit/scorecard-report/:scorecardId/key-takeaways", component: KeyTakeawaysPage },
  { path: "/edit/scorecard-report/:scorecardId/company-information", component: CompanyInformation },
  // Channel nested pages:
  // { path: "/edit/scorecard-report/:scorecardId/channels/:channelId", component: ChannelFirstPage },
  // { path: "/edit/scorecard-report/:scorecardId/channels/:channelId/tactic-evaluated", component: ChannelTacticEvaluated },
  // { path: "/edit/scorecard-report/:scorecardId/channels/:channelId/key-observations", component: KeyObservation },
  // { path: "/edit/scorecard-report/:scorecardId/channels/:channelId/doing-well", component: WhatYouAreDoingWell },
  // { path: "/edit/scorecard-report/:scorecardId/channels/:channelId/quick-wins", component: ContentQuickWins },
  // { path: "/edit/scorecard-report/:scorecardId/channels/:channelId/recommendations", component: ChannelRecommendation },


  ///Genrate pdf v0.01
  // { path: "/generatePDF", component: GeneratePDF },

  ///Genrate pdf v0.02
  { path: "/generate-pdf/:scorecardId", component: GeneratePDF },
]



const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register }, 
 
]

export { publicRoutes, authProtectedRoutes }
